/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
//component
import SchoolHistory from "./SchoolHistory";
//image
import addSchoolImage from "../../img/sub/btn_plus_blue.png";

function School({
	varSaveMode,
	curResumeIndex,
	setSchoolIndex,
	setPopSchool,
	schoolLevelList,
	setSchoolLevelList,
	resumeSchoolList,
	setResumeSchoolList,
	setPerfection, varPerfection
}) {
	const user_idx = localStorage.getItem("mem_idx");
	// const [varNumber, setNumber] = useState(1);
	const [varAPI, setAPI] = useState(false);
	const newSchoolData = {
		idx: resumeSchoolList.length + 1,
		reh_idx: 0,
		reh_level: 0,
		reh_levelname: "",
		reh_name: "",
		reh_major: "",
		reh_sdate: null,
		reh_edate: null,
		reh_state: -2,
		reh_status: 0
	};
	/* const [varNewSchoolData, setNewSchoolData] = useState(newSchoolData); */

	useEffect(() => {

        setAPI(false);
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        params.append("re_mode", varSaveMode);
        params.append("re_idx", curResumeIndex);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_schools`, params).then((res) => {
          if (res.data.retvalue === 1) {
            const schools = res.data.schoolLevels.map((data, index) => (
              <option key={"phl" + index} value={data.vel_idx}>
                {data.vel_name}
              </option>
            ));
            setSchoolLevelList(schools);
            if (curResumeIndex > 0 && res.data.results?.length > 0) {
              const data = res.data.results.map((data, i) => ({
                idx: i + 1,
                reh_idx: data.reh_idx,
                reh_level: data.reh_level,
                reh_name: data.reh_name,
                reh_major: data.reh_major,
                reh_sdate: data.reh_sdate,
                reh_edate: data.reh_edate,
                reh_state: data.reh_state,
                reh_status : data.reh_status
              }));
              //setNumber(data.length + 1);
             // setResumeSchoolList(data);

							if(data.length > 0){
									const completed = varPerfection.completed +25;
									const slasses = 'diagram step' + parseInt(completed / 25);
					
									setPerfection({...varPerfection,
										education: 1,
										classes: slasses,
										completed: completed
									})
							}
						} else {
						//  setResumeSchoolList([]);
						}
					} else {
					 // setResumeSchoolList([]);
						//window.alert('검색된 정보가 없습니다.');
					}
				});
				setAPI(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curResumeIndex]);

	useEffect(()=>{

		if(resumeSchoolList.length > 0){
			if(varPerfection.education !== 1){
				const completed = varPerfection.completed +25;
				const slasses = 'diagram step' + parseInt(completed / 25);

				setPerfection({...varPerfection,
					education: 1,
					classes: slasses,
					completed: completed
				})
			}

		}else{
			if(varPerfection.education === 1){
				const completed = varPerfection.completed -25;
				const slasses = 'diagram step' + parseInt(completed / 25);

				setPerfection({...varPerfection,
					education: 0,
					classes: slasses,
					completed: completed
				})
			}
		}

	}, [resumeSchoolList]);

  const onHandlerAddSchoolHistory = (e) => {
    e.preventDefault();
    // if (!window.confirm("학력을 추가합니까?")) return;
    let copyData = { ...newSchoolData };
   // copyData.idx = varNumber;
    /*     setNewSchoolData({...varNewSchoolData,reh_idx: varNumber}); */
    setResumeSchoolList(resumeSchoolList.concat(copyData));
    setSchoolIndex(resumeSchoolList.length + 1);
    //setNumber((prevNumber) => prevNumber + 1);
    setPopSchool("popup active");

	};


	return !varAPI?null: (
		<section className="section school">
      <div className="title_area">
			  <h3 className="title">학력</h3>
        <button
					className="btn txt"
					onClick={(e) => onHandlerAddSchoolHistory(e)}
				>
					<img src={addSchoolImage} alt="" />
					추가
				</button>
      </div>
			{resumeSchoolList
				? resumeSchoolList.map((school, index) => (
					<SchoolHistory
						key={"scharray_" + index}
						index={index}
						school={school}
						schoolLevelList={schoolLevelList}
						resumeSchoolList={resumeSchoolList}
						setResumeSchoolList={setResumeSchoolList}
					/>
				))
				: null}
		</section>
	);
}

export default School;
