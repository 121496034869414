/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CONFIG from '../../configs/configs'

import Paging from '../../components/Layout/Paging'
import { resetNavSubMenu, mobileWrapSearchTalent } from '../Common/Common'

import CertificationCareerView from "./CertificationCareer";
import AddCareerView from "./AddCareer";
//css

const InterestC = ({ setTabPage, setResumeIndex, onViewResumePage, onCallChating, ResetUsingPass }) => {
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx');
  const mem_sub = localStorage.getItem('mem_sub');
  //loading 유무
  const [varLoadingFinshed, setLoadingFinshed] = useState(1);
  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  //서버의 저장폴더
  const [varResumeInterestLists, setResumeInterestLists] = useState([]);
  // const [varFilterResumeInterestLists, setFilterResumeInterestLists] = useState([]);
  const [varCompanyMembers, setCompanyMembers] = useState([]);

  //제안보낸 직원번호 - 필터링
  const [varCompanyMemberIndex, setCompanyMemberIndex] = useState(0);

  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //최초 모든 이력서 가져오기
  const getResumeInterestLists = async () => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      params.append("mode", 0);
      params.append("varCompanyMemberIndex", varCompanyMemberIndex)
      params.append("varPageSize", varPageSize);
      params.append("varPageNum", varPageNum);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_interest_employ`, params).then((res) => {
        if (res.data.retvalue === 1) {
          console.log(res.data);
          //관심인재목록
          const list = res.data.interests.map((e) => {
            const rec_company_name = e.rec_company ? e.rec_company : e.rac_company ? e.rac_company : '';
            const rec_department_name = e.rec_department ? e.rec_department : e.rac_department ? e.rac_department : '';
            const rec_position_name = e.rec_position ? e.rec_position : e.rac_position ? e.rac_position : '';
            return {
              ...e,
              rec_company_name: rec_company_name.split(',')[0],
              rec_department_name: rec_department_name.split(',')[0],
              rec_position_name: rec_position_name.split(',')[0]

            };
          });
          setResumeInterestLists(list); //원본                
          setTotalCount(res.data.count[0].tcount);
          //관심인재목록 - 기업직원에 따른 필터링
          // setFilterResumeInterestLists(res.data.interests);
          //기업내 직원들
          setCompanyMembers(res.data.members);
        } else {
          setResumeInterestLists([]);
          setTotalCount(0);
          // setFilterResumeInterestLists([]);
          setCompanyMembers([]);
        }
        setLoadingFinshed(0);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    setTabPage(2);
    resetNavSubMenu(2, 2);
    mobileWrapSearchTalent();
    getResumeInterestLists();
    ResetUsingPass();
    document.getElementById('sub_wrap').className = 'page_interested_people';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varCompanyMemberIndex]);


  //직원선택시 -- 우측부 이력서 재표시
  const onChangeCompanyMember = (e) => {
    const com_idx = e.target.value;
    setCompanyMemberIndex(com_idx);
    setPageNum(1)
  }
  const getDataPerPage = () => {
    //   //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varResumeInterestLists.length === 0) {
      return;
    }
  };
  // //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varCompanyMemberIndex]);


  //관심인재 추가/삭제
  const onSetInterestEmploy = (e, mie_idx, resumeIdx) => {
    if (!window.confirm('관심 인재리스트에서 삭제하시겠습니까?')) {
      return false;
    }
    setResumeIndex(resumeIdx);
    let iconStar = e.currentTarget;
    console.log("iconStar ", iconStar);
    // if(iconStar.classList.contains('active')) { //삭제
    //   iconStar.classList.remove('active');
    const param = new URLSearchParams();
    param.append("mci_mcm_idx", mcm_idx);
    param.append("mci_mem_idx", mem_idx);
    param.append("mci_re_idx", resumeIdx);
    param.append("mem_sub", mem_sub);

    axios
      .post(`${CONFIG.SERVER_HOST}/_cancel_interest_talent`, param)
      .then((res) => {
        if (res.data.retvalue === 1) {
          getResumeInterestLists();
        } else {
          alert(res.data.message)
        }
        console.log("res.data ")
      })

    // } else {  //추가
    //   iconStar.classList.add('active');
    // }
    //database 에 반영--------
  }






  return (
    varLoadingFinshed === 1 ? null :
      <>
        <div className="list_top">
          <form className="search_wrap">
            {mem_idx === mem_sub ?
              <div className="wrap">
                <select className="style_select" onChange={(e) => onChangeCompanyMember(e)} defaultValue={0}>
                  <option value={0}>전체</option>
                  {varCompanyMembers.map((members, i) => (
                    <option key={'mem_' + i} value={members.cmm_mem_idx}>{members.mem_id}</option>
                  ))}
                </select>
              </div>
              : null
            }
          </form>
        </div>
        <section className="interestedPeople_list center_wrap">
          <ul className="table table_list01  with_bookmark">
            <li className="tr">
              <article className="th row_name">후보자명</article>
              <article className="th row_total">총 경력</article>
              <article className="th row_career">직장</article>
              <article className="th row_edu">학력</article>
              <article className="th row_state">관리</article>
            </li>
            {varResumeInterestLists.map((resumes, index) => (
              <li className="tr" key={'inter_' + index}>
                <article className="td row_name">
                  <div className="pic_name_wrap">
                    {resumes.mem_photo === 0 ? <figure></figure>
                      : <figure style={{ backgroundImage: `url(${imageFoler + resumes.mem_ufile})` }}></figure>
                    }
                    <b>{resumes.mem_name}</b>
                  </div>
                  {/* {resumes.mie_idx===null||resumes.mie_status===0
                    ?<i className="btn_bookmark" onClick={(e)=>onSetInterestEmploy(e,resumes.mie_idx,resumes.re_idx)}></i> 
                    : */}
                  <i className="btn_bookmark active" onClick={(e) => onSetInterestEmploy(e, resumes.mie_idx, resumes.re_idx)}></i>
                  {/* } */}
                </article>
                <div className="m_colgroup group1">
                  <article className="td row_total m_group txt_blue">
                    {/* {resetCareerForSearching(resumes.re_career_year)} */}
                    {
                      !resumes?.certification_experience_year && !resumes?.certification_experience_year ?
                        <AddCareerView highLookup={resumes} />
                        :
                        <CertificationCareerView highLookup={resumes} />
                    }
                  </article>
                  <article className="td row_career m_group">

                  {resumes.rec_company_name ?
                      <><b>{resumes?.rec_company_name}{resumes?.rec_department_name ? ` / ${resumes?.rec_department_name}` : ""}{resumes?.rec_position_name ? ` / ${resumes?.rec_position_name}` : ""}</b>
                      </>
                      : "경력 없음"
                    }
                  </article>
                  <article className="td row_edu m_group">
                    {resumes.reh_name === null ? "학력 미입력" : (
                      <>
                        {resumes.reh_name}&nbsp;{resumes.reh_major}&nbsp;
                        {resumes.reh_state === 1 ? '졸업' : resumes.reh_state === 2 ? "졸업예정" : resumes.reh_state === 3 ? "재학중" : resumes.reh_state === 4 ? "중퇴" : resumes.reh_state === 5 ? "수료" : resumes.reh_state === 6 ? "휴학" : ""}
                      </>
                    )}
                  </article>
                  <article className="td row_state m_group">
                    <button className="btn line gray" onClick={(e) => onViewResumePage(e, resumes.re_idx, 2)}>이력서 보기<span className="nanumGothic">&gt;</span></button>
                    <button className="btn btn_chat" onClick={() => onCallChating(resumes.mem_idx, resumes.re_idx)}>1:1 채팅 <span className="nanumGothic">&gt;</span></button>
                  </article>
                </div>
              </li>
            ))}
          </ul>
        </section>
        <div className="paging">
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </>
  )
}

export default InterestC
