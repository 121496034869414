/* eslint-disable no-unused-vars */
// export default Header;
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";
import $ from "jquery";
import { socket, SOCKET_EVENT } from "../Chat/socket";

//css
import "css/Common.css";
import "css/Head.css";
import "css/Main.css";
import "css/Sub.css";

//component
import Nav from "./Nav";
import LoginMenu from "./LoginMenu";
import { removeLocalstorageItem, googleSignOut } from "../Common/Common";
import MainAppDownPopup from "components/Main/MainAppDownPopup";

//이미지
import MainLogoIcon from "img/common/logo.png";
import gnb_myheding_m from "img/common/gnb_myheding_m.png";
import gnb_myheding_m_small from "img/common/gnb_myheding_m_small.png";// import gnb_search from "img/common/gnb_search.png";
import mainButtonBar from "img/common/btn_bar.png";


const Header = ({ NumberReset, setNumberReset }) => {
  const uselocation = useLocation();
  const Navigator = useNavigate();
  const memMode = Number(localStorage.getItem("mem_mode"));
  //앱 팝업창 관리 스테이트
  const [appPopupShow, setAppPopupShow] = useState(false);


  let companyMode = false;
  if (memMode === 1 || memMode === 2) {
    companyMode = true;
  }
  const [varSearchValues, setSearchValues] = useState("");
  const [varCompanyMode] = useState(companyMode);
  const [varPersonal, setPersonal] = useState({});

  const [varIsLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("memlogin") &&
      localStorage.getItem("mem_idx") > 0
    ) {
      setIsLogin(true);
      try {
        const params = new URLSearchParams();
        params.append("user_idx", localStorage.getItem("mem_idx"));
        params.append("mem_mode", memMode);
        axios
          .post(`${CONFIG.SERVER_HOST}/_get_user_information`, params)
          .then((res) => {
            if (res.data.retvalue === 1) {
              const datas = res.data.results;
              setPersonal(datas);
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("memlogin")]);

  //앱 백그라운드시 소켓연결 해제 
  useEffect(() => {
    function handleEvent(message) {
      var event_data = JSON?.parse(message.data);
      if (event_data.type === 'chat_background') {
        socket.disconnect();
      }
    }
    document.addEventListener("message", handleEvent);
    return () =>
      document.removeEventListener("message", handleEvent);
  }, []);

  useEffect(() => {
    const _Member_withdrawal_check = () => {
      const params = new URLSearchParams();
      params.append("user_idx", localStorage.getItem("mem_idx"));

      axios
        .post(`${CONFIG.SERVER_HOST}/_Member_withdrawal_check`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            if (res.data.results === 0) {
              //console.log('탈퇴한 회원입니다.', localStorage.getItem("mem_idx"));
              localStorage.clear();
              Navigator('/');
            }
          }
        });
    };

    if (localStorage.getItem("mem_idx") !== null) {
      _Member_withdrawal_check()
    }
  });


  useEffect(() => {
    const uAgent = navigator.userAgent.toLocaleLowerCase();

    if (uAgent.match(/iPhone|iPod|Android/i)) {
      if (uAgent.search("heding") === -1) {
        // 처리 내용
        console.log(sessionStorage.getItem('appChk'));
        if (Boolean(sessionStorage.getItem('appChk')) !== true) {
          setAppPopupShow(true);
        }

      }
    }

  }, []);

  // //안드로이드용 뒤로가기 기능
  // useEffect(() => {
  //   function handleEvent(message) {
  //     // console.log(message.data);
  //     var event_data = JSON?.parse(message.data);
  //     //alert(event_data.type);
  //     if (event_data.type === 'back') {
  //       //alert(window.history.state.idx);
  //       if (Number(window.history.state.idx) > 1) {
  //         //Navigator(-1);
  //         window.history.go(-1);

  //       } else {
  //         const back = true;
  //         const params = {
  //           back
  //         };
  //         const paramsToString = JSON.stringify(params);
  //         window.ReactNativeWebView.postMessage(paramsToString);
  //       }
  //     }

  //   }
  //   document.addEventListener("message", handleEvent);

  //   return () =>
  //     document.removeEventListener("message", handleEvent);
  // }, []);


  //헤드헌터 로그인 시 navbar색 변경
  const setBodyHunter = () => {
    const bodyItem = document.querySelector("body");
    if (memMode === 2) {
      if (!bodyItem.classList.contains("head_hunter_body"))
        bodyItem.classList.add("head_hunter_body");
    } else {
      if (bodyItem.classList.contains("head_hunter_body"))
        bodyItem.classList.remove("head_hunter_body");
    }
  };

  //화면크기
  useEffect(() => {
    setBodyHunter();

    if ($(window).width() < 1204) {
      $("#head .nav").each(function () {
        var MinusHeight =
          $(this).siblings("#head .mobile_content").outerHeight(true) || 0;
        $(this).css({ height: "calc(100vh - " + MinusHeight + "px)" });
      }); //삼선메뉴 높이
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenMenuUser = (e) => {
    e.preventDefault();
    $("#head .nav_wrap").show(); //열기
    $("#head .nav").each(function () {
      //nav 높이
      var MinusHeight =
        $(this).siblings("#head .mobile_content").outerHeight(true) || 0;
      $(this).css({ height: "calc(100vh - " + MinusHeight + "px)" });
    });
  };

  const LogoutHandler = () => {
    if (localStorage.getItem("memlogin")) {
      if (!window.confirm("로그아웃하시겠습니까?")) {
        return false;
      }

      removeLocalstorageItem();
      googleSignOut();
      /* alert("로그아웃 되었습니다."); */
    } else {
      localStorage.clear();
    }
    setIsLogin(false);
    Navigator("/Login");
  };

  //배너
  //   const onCloseBannerHandler = () => {
  //     setIsOpen(false);
  //     sessionStorage.setItem("banner", false);
  //   };

  /*   const Timestamp = new Date();
  const Tomorrow = new Date(Timestamp.setDate(Timestamp.getDate() + 1)); */

  //   const onDayCloseBannerHandler = () => {
  //     localStorage.setItem("onCloseBannerHandler", Timestamp);
  //     sessionStorage.setItem("banner", false);
  //     setIsOpen(false);
  //   };

  //   useEffect(() => {
  //     const banner = sessionStorage.getItem("banner");
  //     if (banner === "true") {
  //       setIsOpen(true);
  //     } else if (
  //       banner === "false" &&
  //       localStorage.getItem("onCloseBannerHandler")
  //     ) {
  //       setIsOpen(false);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  /*   useEffect(() => {
    if (Timestamp === Tomorrow) {
      localStorage.removeItem("onCloseBannerHandler");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }); */

  //통합검색
  const onChangeSearchStringHandler = (e) => {
    let sString = e.currentTarget.value;
    setSearchValues(sString);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  }

  const handleOnSearch = () => {
    if (varSearchValues === "") {
      alert('검색어를 입력해 주세요.');
      return;
    }
    setTimeout(function () {
      //2022-11-28 KSR
      //Navigator(`/MyRecruit/MainList/1/1/0`, { state: { em_headhunter: 0, sString: varSearchValues, sHeadSearch: true } })
      Navigator(`/MyRecruit/MainList2/-1/1/20/1?shString=${varSearchValues}`);
    }, 100);
    setTimeout(function () {
      setSearchValues('');
    }, 200);
  }

  useEffect(() => {
    if (uselocation.state?.sHeadSearch) {
      setSearchValues('');
    }
  }, [uselocation]);



  return (
    <>
      <aside id="ribbon" className="center_wrap">
        {/* {localStorage.getItem("onCloseBannerHandler") === undefined ||
            localStorage.getItem("onCloseBannerHandler") === null ? (
            <BannerTop
                mode={0}
                onCloseBannerHandler={onCloseBannerHandler}
                varIsOpen={varIsOpen}
                onDayCloseBannerHandler={onDayCloseBannerHandler}
          />
        ) : null} */}
        <h1 className="logo">
          <Link to={"/main"}>
            <img src={MainLogoIcon} alt="" />
          </Link>
        </h1>
        <LoginMenu varIsLogin={varIsLogin} LogoutHandler={LogoutHandler} NumberReset={NumberReset}
          setNumberReset={setNumberReset} />
        {varIsLogin ? (
          ""
        ) : (
          <Link to={"/JoinUs"} className="link">
            회원가입
          </Link>
        )}
        <Link to={varIsLogin ? "/MyHeding/MyHeding1" : "/Login"} className="btn_my">
          <img className="view_pc" src={gnb_myheding_m} alt="" />
          <img className="view_m" src={gnb_myheding_m_small} alt="" />
        </Link>
        {varCompanyMode ? (
          <Link to="/Company" className="button">
            헤딩 기업서비스
          </Link>
        ) : (
          ""
        )}
        <button onClick={(e) => onOpenMenuUser(e)} className="btn_bar">
          <img src={mainButtonBar} alt="" />
        </button>
      </aside>
      {/* 상단메뉴부분 */}
      <header id="head">
        <div className="center_wrap">

          {/* 상단메뉴 */}
          <Nav
            varIsLogin={varIsLogin}
            varPersonal={varPersonal}
            varCompanyMode={varCompanyMode}
            LogoutHandler={LogoutHandler}
          />
          {/* 우측검색 */}
          <form onSubmit={(e) => onSubmit(e)}>
            <fieldset className="search_area">
              {/* <select>
                <option>통합검색</option>
              </select> */}
              <input
                type="text"
                name="searchString"
                placeholder="직무/지역/회사를 입력하세요"
                value={varSearchValues}
                onChange={(e) => onChangeSearchStringHandler(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleOnSearch()
                  }
                }}
              />
              <button style={{ cursor: "pointer" }} className="button" onClick={(e) => handleOnSearch()}>{``}</button>
            </fieldset>
          </form>
        </div>
      </header>
      <MainAppDownPopup setAppPopupShow={setAppPopupShow} appPopupShow={appPopupShow} />
    </>
  );
};
export default Header;