/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import axios from 'axios';
//import CONFIG from 'configs/configs';


/*채용 지원 현황*/
function MyRecommendTop({ varTabs,setTabs, varRecommendCounter, varRecommendApplyCounter, onExecuteSearchingHandler }) {
    //const user_idx = localStorage.getItem('mem_idx');
    /*
   const [varRecommendData, setRecommendData] = useState({
        send_recommend_num: 0,
        send_recommend_apply: 0
    });
    */


    /* 2022-11-11 집계 수 맞지 않아 목록 가져올때(상위 comp) 같이 가져옴.
    useEffect(() => {
        //console.log('user_idx',user_idx);
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_share`,params).then((res) => {
          console.log(res.data)
            if(res.data.retvalue === 1) {
                //console.log(res.data.tcount);
                if(varTabs === 0) {
                    setRecommendData({
                        send_recommend_num: res.data.tcount,
                        send_recommend_apply: res.data.scount
                    });
                } else {
                    setRecommendData({
                        send_recommend_num: res.data.rcount,
                        send_recommend_apply: res.data.pcount
                    });
                }
            } else {
                window.alert('검색된 정보가 없습니다.');
            }
        });  
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[varTabs]); 
    */

    const onSetTabs = (e,index) => {
        e.preventDefault();
        setTabs(index);
    }

  return (
    <section className="tab01_wrap">
        <ul className="tab01">
            <li className={varTabs===0?"active":""}><a onClick={(e)=>onSetTabs(e,0)}>지인 추천 현황</a></li>
            <li className={varTabs===1?"active":""}><a onClick={(e)=>onSetTabs(e,1)}>추천 받은 현황</a></li>
        </ul>
        <article className="tab01_contents" style={{'marginTop':'20px'}}>
            <ul className="state_wrap">
                <li className="state_box">
                    <h4 className="title">{varTabs === 0?"추천자 수":"추천 받은 공고 수"}</h4>
                    <p className="num"><button onClick={(e) => onExecuteSearchingHandler(e)} name="recommend" style={{color:"#3d7df2", fontSize:"18px", cursor:"pointer"}}>{varRecommendCounter}</button></p>
                </li>
                <li className="state_box">
                    <h4 className="title">{varTabs === 0?"지원자 수":"지원한 공고 수"}</h4>
                    <p className="num" ><button onClick={(e) => onExecuteSearchingHandler(e)} name="apply" style={{color:"#3d7df2", fontSize:"18px", cursor:"pointer"}}>{varRecommendApplyCounter}</button></p>
                </li>
            </ul>
        </article>
    </section>
  );
}

export default MyRecommendTop;
