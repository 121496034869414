/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import { addThousandPoint } from '../../../components/Common/Common'
import Paging from '../../../components/Layout/Paging'

function VoucherList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    //페이지-------------------------------------------------------------------------------------------
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    //목록표시부-------------------------------------------------------------------------------------------
    const [varVoucherList, setVoucherList] = useState([]);
    const [varCheckedList, setCheckedList] = useState([]);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function readVoucherList(cPage) {
        var sendData = {};

        Object.assign(sendData,{currentPage:cPage});
        Object.assign(sendData,{pageSize:varPageSize});

        console.log(sendData);
        const params = new URLSearchParams();
        params.append("sendData",JSON.stringify(sendData));
        axios.post(`${CONFIG.SERVER_HOST}/voucherlist`,params).then((res) => {
            console.log(res.data);
            if(res.data.retvalue === 1) {
                setTotalCount(res.data.totalcount);
                //console.log(res.data);
                const data = res.data.results.map((data,i)=>(                
                    {  
                        vo_idx: data.vo_idx,
                        order: res.data.totalcount-(varPageNum-1)*varPageSize - i,
                        vo_name:data.vo_name,
                        vo_terms:data.vo_terms+" 일",
                        vo_sending:data.vo_sending+" 건",
                        vo_price:addThousandPoint(data.vo_price)+" 원",
                        vo_amount:addThousandPoint(data.vo_amount)+" 원",
                        vo_discount:data.vo_discount+" %",
                        vo_index:data.vo_index
                    }
                ))
                setVoucherList(data);
            } else {
                window.alert(res.data.message);
            }
        });
    }

    //페이지가 바뀌면
    useEffect(() => {
        readVoucherList(varPageNum);
    },[varPageNum]);
    
    //전체 선택
    const onCheckedAll = (e) => {
        if (e.currentTarget.checked) {
            const checkedListArray = [];
            varVoucherList.forEach((voucher) => checkedListArray.push(voucher));
            setCheckedList(checkedListArray);
        } else {
            setCheckedList([]);
        }
    }
    //개별선택 
    const onCheckedElement = (e, voucher) => {
        if (e.currentTarget.checked) {
            setCheckedList([...varCheckedList, voucher]);
        } else {
            setCheckedList(varCheckedList.filter((el) => el !== voucher));
        }
    }
    //선택 항목 삭제
    const deleteVoucherList = (e) =>{
        const delitems = varCheckedList.length;
        if(delitems === 0) {
            window.alert('삭제할 정보가 선택되어 있지 않습니다.'); return;
        }
        if(!window.confirm(delitems+'개의 선택된 정보를 삭제합니까?')) return;

        //console.log(varCheckedList);
        const params = new URLSearchParams();
        params.append("delitems",JSON.stringify(varCheckedList));
        axios.post(`${CONFIG.SERVER_HOST}/voucher_delete`,params).then((res) => {
            if(res.data.retvalue === 1) {
                //readVoucherList(currentPage);              
            } else {
                window.alert('오류가 발생했습니다.');
            }
        });
    }

    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="using_pass"/>
        <Menu menuType="using_pass" menu="voucherlist"/>
        <div id="contents" className="ad_contents">
            <div className="tit">이용권 등록
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>이용권 구매관리<img src={arrImage} alt=""/>이용권 등록
                </div>
            </div>
            <div className="content">     
                <div className="main-box2">
                    <div className="btn-right">
                        <Link to="/admin/voucherdetail/0" className="btn">등록</Link>
                        <button onClick={deleteVoucherList} className="btn">삭제</button>
                    </div>
                </div>                
                <div className="main-box ">       
                    <table className="list">
                        <colgroup>
                            <col width="4%"></col>
                            <col width="8%"></col>
                            <col width="20%"></col>
                            <col width="10%"></col>
                            <col width="10%"></col>
                            <col width="10%"></col>
                            <col width="10%"></col>
                            <col width="6%"></col>
                            <col width="6%"></col>
                            <col width="8%"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="board-list">
                                <input type="checkbox" onChange={(e)=>onCheckedAll(e)} id="deleteItems"
                                checked={varCheckedList.length===0?false:varCheckedList.length===varVoucherList.length?true:false} />
                                </th>
                                <th>번호</th>
                                <th>이용권명</th>
                                <th>이용기간</th>
                                <th>제안발송</th>
                                <th>표준가격</th>
                                <th>할인가격</th>
                                <th>할인률</th>
                                <th>노출순서</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {varVoucherList.map((voucher,i) => (
                                <tr key={voucher.vo_idx}>
                                    <td className="board-list">
                                        <input type="checkbox" 
                                        id={"deleteItem"+i}
                                        onChange={(e)=>onCheckedElement(e,voucher)} 
                                        checked={varCheckedList.includes(voucher)?true:false}/>
                                    </td>
                                    <td><p>{voucher.order}</p></td>
                                    <td className="ellipsis"><p>{voucher.vo_name}</p></td>
                                    <td><p>{voucher.vo_terms}</p></td>
                                    <td><p>{voucher.vo_sending}</p></td>
                                    <td className="ellipsis"><p>{voucher.vo_price}</p></td>
                                    <td className="ellipsis"><p>{voucher.vo_amount}</p></td>
                                    <td><p>{voucher.vo_discount}</p></td>
                                    <td><p>{voucher.vo_index}</p></td>
                                    <td><Link to={`/admin/voucherdetail/${voucher.vo_idx}`} className="btn line black">상세</Link></td>
                                </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
                <div>
                <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                </div>
            </div>        
        </div>
        </React.Fragment>
    )
}

export default VoucherList;