import React from "react";

//컴포넌트
import HeaderCompany from '../../components/Layout/HeaderCompany';
import NoticeList from 'components/Board/NoticeList';
import BannerBottom from 'components/Banner/BannerBottom';
import Footer from '../../components/Layout/Footer';



function ComNotice() {

  return (
    <div>
       <HeaderCompany />
      <div className="center_wrap page_notice">
        <NoticeList />
      </div>
      <BannerBottom />
      <Footer />
    </div>
  );
}

export default ComNotice;