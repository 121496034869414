import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { Link } from "react-router-dom";

//components
import Paging from "../Layout/Paging";
import { ChangeMoneyUnit } from "components/Common/Common";
import won from "img/main/won.png";

//이미지
const companyImg = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`;

/*채용 지원 현황*/
function MyBookmarkList({ varTabs, varEmployList, setEmployList }) {
  const user_idx = localStorage.getItem("mem_idx");

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(12);
  const [varPageNum, setPageNum] = useState(1);
  //검색옵션
  const [varSortMode, setSortMode] = useState(1);
  console.log("Sort ", varSortMode);

  const [varPageEmployList, setPageEmployList] = useState([]);

  const onSelectEmployTypeHandler = (e) => {
    setSortMode(Number(e.currentTarget.value));
  };

  const readEmployList = () => {
    //console.log(varTabs);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("mode", varTabs);
    params.append("postingValue", varSortMode);
    axios
      .post(`${CONFIG.SERVER_HOST}/_get_my_interest_employ`, params)
      .then((res) => {
        // console.log("111111111111111 ",res.data.results);
        if (res.data.retvalue === 1) {
          setTotalCount(res.data.results.length);
          console.log(res.data.results);
          setEmployList(res.data.results); //원본 - 전체
          //setPageEmployList(res.data.results); //필터링 배열
          //setPageNum(1);
          //getDataPerPage();
        }
      });
  };

  useEffect(() => {
    readEmployList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSortMode, varTabs]);

  useEffect(() => {
    setSortMode(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabs]);

  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    setTotalCount(varEmployList.length);
    if (varPageNum === 0 || varEmployList.length === 0) {
      setPageEmployList([]);
      return;
    }
    var copyData = [...varEmployList];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    console.log(copyData);
    setPageEmployList(copyData);
  };

  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varEmployList, varSortMode]);

  //관심기업 등록/해제
  const onSelectEmployInterestHandler = (e, em_idx, mie_status) => {
    const ichecked = mie_status === 0 || mie_status === null ? 1 : 0;
    console.log("value", em_idx, user_idx, mie_status, ichecked);
    const param = new URLSearchParams();
    param.append("user_idx", user_idx);
    param.append("em_idx", em_idx);
    param.append("checked", ichecked);
    axios
      .post(`${CONFIG.SERVER_HOST}/_update_my_interest_employ`, param)
      .then((res) => {
        alert(res.data.message);
        if (res.data.retvalue === 1) {

          console.log(varEmployList);

          if (varTabs === 0) {
            let copyData = [...varEmployList];
            const NewArr = copyData?.filter((element) => element.em_idx !== em_idx);
            console.log(NewArr);
            setEmployList(NewArr);
          } else if (varTabs === 1) {
            const finsIndex = varEmployList.findIndex(
              (element) => element.em_idx === em_idx
            );
            let copyData = [...varEmployList];
            if (res.data.insertMode === 1) {
              const idx = res.data.insert_id;
              copyData[finsIndex] = {
                ...copyData[finsIndex],
                mie_em_idx: em_idx,
                mie_idx: idx,
                mie_mem_idx: user_idx,
                mie_status: ichecked,
              };
            } else {
              copyData[finsIndex] = {
                ...copyData[finsIndex],
                mie_status: ichecked,
              };
            }
            console.log(finsIndex, copyData);
            setEmployList(copyData);
          }
        }
      });
  };


  return varPageEmployList === undefined || varPageEmployList === null ? null : (
    <>
      <section className="list_top">
        <select className="select_style division" value={varSortMode} onChange={(e) => onSelectEmployTypeHandler(e)}>
          <option value="1">진행중인 공고</option>
          <option value="2">마감된 공고</option>
        </select>
      </section>
      <section className="recruit_card_wrap" style={{ marginTop: "20px" }}>
        {varPageEmployList?.map((jobPosting, index) => (
          <article className="recruit_card" key={"posting_" + index}>
            <div className={(jobPosting.mie_idx === null || jobPosting.mie_status === 0) ? "btn_bookmark m_resize" : "btn_bookmark m_resize active"}
              onClick={(e) => onSelectEmployInterestHandler(e, jobPosting.em_idx, jobPosting.mie_status)}></div>
            <Link to={`/MyRecruit/MainView/${jobPosting.em_idx}/${jobPosting.em_headhunter}`} className="link_wrapper">
              <div className="image_wrap">
                {(function () {
                  if (jobPosting.em_image === 1 && jobPosting.em_type !== 2) {
                    return <img src={`${CONFIG.SERVER_HOST}/em_image/${jobPosting.em_ufile}`} alt="" />
                  } else {
                    if (jobPosting.mcm_image === 0) {
                      return <img src={`${companyImg}`} alt="" />
                    } else if (jobPosting.em_type === 2 || jobPosting.mcm_image === 1) {
                      return <img src={`${CONFIG.SERVER_HOST}/mcm_image/${jobPosting.mcm_ufile}`} alt="" />
                    }
                  }
                })()}
              </div>
              {/* 10일까지만 new 표시 */}
              {/* {(varTabs === 0 && remainDays(jobPosting?.mie_rdate) < 10)||(varTabs === 1 && remainDays(jobPosting?.mle_rdate) < 10)?
                            <div className="newicon"><img src={IconNew} alt="" /></div>:null
                        } */}
              <div className="content_wrap">
                <div className="type_wrap">
                  <span className="type">
                    {jobPosting.em_type === 1 ? "공개채용" : ""}
                    {jobPosting.em_type === 2 ? "비공개채용" : ""}
                    {jobPosting.em_type === 3 ? "실시간 채용관" : ""}
                    {jobPosting.em_type === 4 ? "헤드헌터 전용관" : ""}
                  </span>
                  <span className="new_badge"></span>
                </div>
                <h1 className="title">{jobPosting?.em_title}</h1>
                <h2 className="recruit_info">
                  <span className="company">
                    {jobPosting.em_type === 2 ? jobPosting.mcm_name : jobPosting.com_name}
                  </span>
                  &nbsp;ㆍ&nbsp;{jobPosting.vr_name}&nbsp;ㆍ&nbsp;
                    {jobPosting.em_careers === 0 ? "경력 무관" : 
                      jobPosting.em_careers === 1 ? "신입" : 
                      `경력 ${jobPosting.em_career_year}년`}
                </h2>
                {/* <span>{jobPosting?.vr_name}</span><span>{jobPosting?.em_careers === 0?"경력무관":jobPosting?.em_careers === 1 ?"신입":"경력"}</span> */}
                  <div className="compensation">
                  {jobPosting.em_type === 3 ? null :
                  <>
                    <div className="left_wrap">
                      <img className="view_pc" src={won} alt="원" />총 보상금
                    </div>
                    <div className="right_wrap">
                      {jobPosting.em_type === 4 ? ChangeMoneyUnit(jobPosting?.emh_reward_recommend / 10000) : ChangeMoneyUnit(jobPosting?.em_reward_recommend / 10000)}
                    </div>
                  </>
                  }
                  </div>
              </div>
            </Link>
          </article>
        ))}
      </section>
      <Paging
        totalCount={varTotalCount}
        pageSize={varPageSize}
        curPage={varPageNum}
        setPage={setPageNum}
      />
    </>
  );
}

export default MyBookmarkList;
