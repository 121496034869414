import React, { useEffect } from 'react';

//컴포넌트
import HRWrite from 'components/My/HRWrite';
import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding9({ getSubItem }) {
  useEffect(() => {
    getSubItem(9);
    resetNavSubMenuForHeding(9);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_my_hr_pic">
        <HRWrite/>
    </div>
  )
}

export default MyHeding9