/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";

import iconhome from "../../img/icon-home.png";
import arrImage from "../../img/arr.png";
// import ConsultList from "../board/ConsultList";


function BannerDetail() {
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varIsIdx, setIsIdx] = useState(0);
  const { banner_idx } = useParams();
  const today = new Date().toISOString().substr(0, 10).replace("T", "");

  const [varName, setName] = useState("");
  const [varMode, setMode] = useState("");
  const [varFile, setFile] = useState("");
  const [link, setLink] = useState('');

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //varNewChkFile
  const [varNewChkFile, setNewChkFile] = useState(false);
  const onNameHandler = (e) => {
    const {name, value} = e.target;
    console.log("name ",name, "value ",value)
    setName(value);
    // console.log("varName ",varName)
  };

  const onFileHandler = (e) => {
    setNewChkFile(true);
    setFile(e.target.files[0]);
  };

  const onModeChangeHandler = (e) => {
    setMode(e.target.value);
  };

  const onLinkHandler = e => {
    setLink(e.target.value);
  }


  const onSubmitHandler = async(e) => {
    e.preventDefault();
    if (!varFile) {
      alert("파일을 선택해주세요.");
    } else if (!varName) {
      alert("배너 제목을 입력해주세요.");
    } else if (!varMode) {
      alert("모드를 선택해주세요.");
    } else {
      let fileNameChk = false;
      const uploadFile = varFile;
      const formData = new FormData();
      formData.append("banner", uploadFile);

      const file = await axios.post(`${CONFIG.SERVER_HOST}/banner_upload`, formData).then((res) => {
        fileNameChk = true;
        return res.data.fileName;
      });

      if(fileNameChk === true){
        const param = new URLSearchParams();

        param.append("banner_title", varName);
        param.append("banner_ufile", file);
        param.append("banner_rdate", today);
        param.append("banner_mode", varMode);
        param.append("banner_link", link);

        await axios.post(`${CONFIG.SERVER_HOST}/banner_create`, param).then((res) => {
            console.log(res);
            if (res.status === 200) {
              alert("등록에 성공하였습니다.");
              navigate("/admin/banner");
            }
        });
      }
    }
  };

  const onUpdateHandler = async(e) => {
    e.preventDefault();
    const uploadFile = varFile;
    const formData = new FormData();
    formData.append("banner", uploadFile);

    if (varNewChkFile === true) {
      await axios.post(`${CONFIG.SERVER_HOST}/banner_upload`, formData).then((res) => {
          const param = new URLSearchParams();
          param.append("banner_title", varName);
          param.append("banner_ufile", res.data.fileName);
          param.append("banner_mode", varMode);
          param.append("banner_idx", varIsIdx);
          param.append("banner_link", link);
          
          axios.post(`${CONFIG.SERVER_HOST}/banner_update`, param).then((res) => {
            if (res.status === 200) {
              alert("수정에 성공하였습니다.");
              navigate("/admin/banner");
            }
          });
        });
    } else{
      const param = new URLSearchParams();
      param.append("banner_title", varName);
      param.append("banner_ufile", varFile);
      param.append("banner_mode", varMode);
      param.append("banner_idx", varIsIdx);
      param.append("banner_link", link);

      await axios.post(`${CONFIG.SERVER_HOST}/banner_update`, param).then((res) => {
        if (res.status === 200) {
          alert("수정에 성공하였습니다.");
          navigate("/admin/banner");
        }
      });
    }
  };

  useEffect(() => {
    setIsIdx(banner_idx);
    if (banner_idx > 0) {
      const param = new URLSearchParams();
      param.append("banner_idx", banner_idx);
      axios.post(`${CONFIG.SERVER_HOST}/banner_r`, param).then((res) => {
          setName(res.data[0]?.banner_title);
          setMode(String(res.data[0]?.banner_mode));
          setFile(res.data[0]?.banner_ufile);
          setLink(res.data[0]?.banner_link)
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="banner" />
      <div id="contents" className="ad_contents" encType="multipart/form-data">
        <div className="tit">
          배너 관리
          <div className="path">
            <img src={iconhome} alt=""/>HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt=""/> 디자인 관리<img src={arrImage} alt=""/>배너 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">배너 관리</div> */}
          <div className="main-box">
              <table className="write">
                <tr>
                  <th>이미지</th>
                  <td>
                    {/* {varFile.name} */}
                    {varNewChkFile === false ? varFile : ''} &nbsp;
                    <input type="file" onChange={(e)=>onFileHandler(e)} accept={"image/*"} />
                    <b class="size_guide">(1260 x 234 px 사이즈로 등록해주세요.)</b>
                  </td>
                </tr>
                <tr>
                  <th>배너 제목</th>
                  <td>
                    <input type="text" value={varName} onChange={(e)=>onNameHandler(e)} />
                  </td>
                </tr>
                <tr>
                  <th>링크URL</th>
                  <td>
                    <input type="text" value={link} onChange={(e) => onLinkHandler(e)} placeholder="https://" />
                  </td>
                </tr>
                <tr>
                  <th>배너 모드</th>
                  <td>
                    <div className="write-radio">
                      <input
                        type="radio"
                        label="0"
                        name="mode"
                        id="normal"
                        className="chk1"
                        value="0"
                        onChange={(e)=>onModeChangeHandler(e)}
                        checked={varMode === "0"}
                      />
                      <label htmlFor="normal">일반</label>
                      <input
                        type="radio"
                        label="1"
                        name="mode"
                        id="company"
                        className="chk1"
                        value="1"
                        onChange={(e)=>onModeChangeHandler(e)}
                        checked={varMode === "1"}
                      />
                      <label htmlFor="company">기업</label>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
          <div className="btn-center">
          <button className="btn line">
            <Link to="/admin/banner" style={{ color: "#fff" }}>목록</Link>
          </button>
            {banner_idx === "0" ? (
              <button className="btn" onClick={(e)=>onSubmitHandler(e)}>등록</button>
            ) : (
              <button className="btn" onClick={(e)=>onUpdateHandler(e)}>수정</button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BannerDetail;
