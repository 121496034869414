import React from "react";


//components
import Header from "./Header";

//css
import "css/guide.css";

//이미지
import guide0201 from "img/sub/guide0201.jpg";
import guide0202 from "img/sub/guide0202.jpg";
import guide0203 from "img/sub/guide0203.jpg";
import guide0204 from "img/sub/guide0204.jpg";
import guide0205 from "img/sub/guide0205.jpg";
import guide0206 from "img/sub/guide0206.jpg";
import guide0207 from "img/sub/guide0207.jpg";
import guide0201_m from "img/sub/guide0201_m.jpg";
import guide0202_m from "img/sub/guide0202_m.jpg";
import guide0203_m from "img/sub/guide0203_m.jpg";
import guide0204_m from "img/sub/guide0204_m.jpg";
import guide0205_m from "img/sub/guide0205_m.jpg";
import guide0206_m from "img/sub/guide0206_m.jpg";
import guide0207_m from "img/sub/guide0207_m.jpg";
import BannerBottom from "components/Banner/BannerBottom";
import Footer from "./Footer";

function GuideCompany() {

	return (
		<>
		<Header/>
			<div className="center_wrap page_guide">
        <h2 className="pg_tit">사용자 가이드</h2>
        <ul className="tab01 blue">
         <li>
            <a href="Guide">일반회원</a>
          </li>
          <li className="active">
            <a href="GuideCompany">기업회원</a>
          </li>
          <li>
            <a href="GuideHeadHunter">헤드헌터/서치펌</a>
          </li>
        </ul>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">회원가입</p>
            <div className="txt">① 사업자등록번호, 회사명, 대표자명, 회사 주소를 입력 후 사업자등록증을 첨부합니다.<br/>
              ② 담당자 정보 작성 후 헤딩 관리자의 승인이 완료되면, 가입 완료 처리됩니다.<br/>
              가입 승인은 1~2일 (영업일 기준) 소요됩니다.<br/>
              <a href="/JoinUs/Company" className="button">회원가입 하러가기</a>
            </div>
          </article>
          <article className="img_area">
            <img src={guide0201} alt="회원가입" className="pc"/>
            <img src={guide0201_m} alt="회원가입" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">서브 계정 관리</p>
            <div className="txt">회사 메인 계정으로 다수의 인원을 관리해야 한다면 서브 계정 관리 시스템을 이용해 보세요.<br/>
등록된 서브 계정들의 공고 등록, 제안 내역, 이용권 사용내역 등을 메인 계정에서 확인할 수 있습니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0202} alt="서브 계정 관리" className="pc"/>
            <img src={guide0202_m} alt="서브 계정 관리" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">공고등록 </p>
            <div className="txt">① 공고 등록 메뉴 클릭 후 공고 등록을 시작합니다.<br/>
② 채용 공고에 필요한 정보 기입 후 채용 구분을 선택합니다.<br/>
(공개, 비공개, 파견/계약/알바, 헤드헌터 전용관 중 선택)<br/><br/>

헤드헌터 전용관은 전문 헤드헌터의 인재 추천을 받을 수 있는 채널로 공개, 비공개 채용보다 추천자의 직무적합도와 합격률을 높일 수 있으며, 프리미엄 기업회원에게 해당 서비스 제공됩니다.<br/>
※ 헤드헌터 전용관 사용 희망 시 heding@heding.co.kr로 문의 바랍니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0203} alt="공고등록" className="pc"/>
            <img src={guide0203_m} alt="공고등록" className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">공고 상태 관리 </p>
            <div className="txt">① 공고/지원자 관리에서 진행 중인 공고를 조기 마감 시킬 수 있습니다.<br/>
②  마감 및 진행 중인 공고를 복사하여 쉽고 빠르게 새로운 공고를 작성하실 수 있습니다.<br/>
            </div>
          </article>
          <article className="img_area">
            <img src={guide0204} alt="공고 상태 관리 " className="pc"/>
            <img src={guide0204_m} alt="공고 상태 관리 " className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">공고 지원자관리 </p>
            <div className="txt">공고/지원자 관리에서 채용 진행 중인 공고의 지원자 관리 화면을 조회할 수 있으며,
엑셀 다운로드 버튼을 이용해 전체 지원자 현황을 다운로드할 수 있습니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0205} alt="공고 지원자관리 " className="pc"/>
            <img src={guide0205_m} alt="공고 지원자관리 " className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">지원자 관리 상세 </p>
            <div className="txt">상단의 공고/지원자 관리 - 지원자 관리 상세에서 지원자들을 조회할 수 있으며,
지원자들의 심사 상태를 변경하시면 지원자들에게 개별 앱 PUSH가 발송됩니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0206} alt="지원자 관리 상세 " className="pc"/>
            <img src={guide0206_m} alt="지원자 관리 상세 " className="mobile"/>
          </article>
        </section>
        <section className="guide_section">
          <article className="info_area">
            <p className="sub_tit">기업회원서비스</p>
            <p className="tit">이용권 구매 방법</p>
            <div className="txt">인재 DB 검색을 위해서 이용권 구매가 필요합니다.<br/>
① 이용권 구매에서 기간 및 검색 건 수 별로 이용권 구매가 가능합니다.
            </div>
          </article>
          <article className="img_area">
            <img src={guide0207} alt="이용권 구매 방법" className="pc"/>
            <img src={guide0207_m} alt="이용권 구매 방법" className="mobile"/>
          </article>
        </section>
			</div>
			<BannerBottom/>
			<Footer/>
		</>

	)
}

export default GuideCompany;