import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import CareerIncomeViewC from '../../components/Career/CareerIncomeViewC'
//css

const CareerIncomeView = () => {
  const memlogin = localStorage.getItem('memlogin');
  const navigator = useNavigate();
  const location = useLocation();
  const [varIncomeMode, setIncomeMode] = useState(0);

    //회원정보 불러오기
  useEffect(() => {
    if(memlogin === undefined || memlogin === null || memlogin === false ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/Login");
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (location !== null && location.state !== null) {
      console.log('location', location.state.callMode);
      setIncomeMode(location.state.callMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="container">
      <CareerIncomeViewC varIncomeMode={varIncomeMode} />
    </div>
  )
}

export default CareerIncomeView
