import React, { useEffect,useState }from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';

//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/MyResume.css';

//components
import ResumeLists from '../../components/Resume/ResumeLists';
import ResumeInfo from '../../components/Resume/ResumeInfo';
import { resetNavSubMenu, resetNavMenu } from '../../components/Common/Common';

/*이력서 목록*/
function ResumeList({ varShowResume,setShowResume,PageFolder }) {
  const user_idx = localStorage.getItem('mem_idx');
  const memlogin = localStorage.getItem('memlogin');
  const navigator = useNavigate();

  const [varUserName,setUserName] = useState('');

  useEffect(() => {
    if(memlogin === undefined || memlogin === null || memlogin === false ){
      navigator("/Login");
    }
    resetNavMenu(1);
    resetNavSubMenu(1,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[memlogin, navigator]);

  const onShowResumeHandler= (e) => {
    const ichecked = Number(e.currentTarget.checked);
    const chk = e.currentTarget.checked;
    // setShowResume(e.currentTarget.checked);
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("re_show", ichecked);
    console.log(user_idx,ichecked);
    axios.post(`${CONFIG.SERVER_HOST}/_show_myfix_resume`, params).then((res) => {
      // alert(res.data.message);
      if(res.data.retvalue === 1){//기능 원래 없었음
        setShowResume(chk);
        }else if(res.data.retvalue === 2){
          //ShowMessage(res.data.message);
          setShowResume(chk);
        }else{
          ShowMessage(res.data.message);
        }
    });
  }

  const ShowMessage =(message) =>{
    setTimeout( ()=>{alert(message) }, 200);
  }

  return (
    <div id="sub_wrap" className="center_wrap page_myresume_list">
        <ResumeLists setShowResume={setShowResume} PageFolder={PageFolder} setUserName={setUserName}/>
        <div className="btn_bottom_area">
            <Link to={`${PageFolder}ResumeView/${0}`} className="btn">이력서 작성하기</Link>
        </div>
        <div className="myresume_setting">
          <p className="text">대표 이력서가 인재 검색하기에 노출되는 것을 동의합니다.</p> 
          <div className="setting">
              <input type="checkbox" id="btnToggle1" checked={varShowResume} onChange={(e)=>onShowResumeHandler(e)}/>
          </div>
        </div>
        <ResumeInfo varUserName={varUserName}/>
        <div className="restricted_setting">
          <p className="title_field">열람 제한 기업 설정</p>
          <div className="wrap">
            <p className="text">
            열람 제한 기업 설정 시 해당 기업에서 <span className="point">{varUserName}</span>님이 검색되지 않습니다.<br/>
            <span className="point">{varUserName}</span>님의 이력서 등록 여부 확인이 불가능하며, 이력서 및 이직 희망 여부를 확인할 수 없습니다. <br/>
            현재 재직중인 기업에 이직 희망 여부를 알게 하고 싶지 않을 때 사용할 수 있습니다.
            </p>
            <div className="btn_area"><Link className="btn" to={'/MyResume/ResumeLimit'} state={{userName: varUserName}}>열람 제한 기업 지정하기</Link></div>
            
          </div>
        </div>
    </div>
  );
}

export default ResumeList;
