/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs"; 
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import { addThousandPoint } from "components/Common/Common";

function ExchangeInfo1({ history }) {
  const navigator = useNavigate();
  const location = useLocation();
  const adminlogin = localStorage.getItem('adminlogin');
  const { user_idx } = useParams();
  const mh_idx = location.state;
  
  const [varUserData, setUserData] = useState({});
  const [noExchange, setNoExchange] = useState("");
  const [mhType, setMhType] = useState("1");
  const [exchangeDate, setExchangeDate] = useState("");
  const [currentHedingMoney, setCurrentHedingMoney] = useState(0);
  const [userAccount, setUserAccount] = useState("");
  const [varCommissionMoney, setCommissionMoney] = useState(0);
  const [varTaxMoney, setTaxMoney] = useState(0);
  const [varRealExchangeMoney,setRealExchangeMoney] = useState(0);
  const [requestMoney,setRequestMoney] = useState(0);
  const [prevMoney,setPrevMoney] = useState(0);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //회원정보 가져오기
  useEffect(async() => { 
    const params = new URLSearchParams();
    params.append('mem_idx', user_idx);
    params.append('mh_idx', mh_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_exchangeinfo_detail`, params).then((res) => {
      if(res.data.retvalue === 1) {
        let datas = res.data.results[0];
        setUserData(datas); 
        setMhType(datas.mh_status)
        setNoExchange(datas.mh_no_exchange)
        setExchangeDate(datas.mh_pdate)
        setUserAccount(res.data.accounts[0])
        setCommissionMoney(datas.mh_commission)
        setTaxMoney(datas.mh_tax)
        setRealExchangeMoney(datas.mh_exchange)
        // setRequestMoney(datas.mh_paid)
        setRequestMoney(datas.mh_exchange+datas.mh_tax+datas.mh_commission)
        setPrevMoney(datas.mh_paid);
      }
    });
  }, [user_idx]);


  //환전불가 사유
  const onChangeHandler = e => {
    setNoExchange(e.target.value);
  }

  //셀렉트박스
  const onSelectHandler = e => {
    const {value} = e.target;
    setMhType(value);
  
    //환전취소일 때
    if(Number(value) === -1){
      setCommissionMoney(0);
      setTaxMoney(0);
      setRealExchangeMoney(0);
      setRequestMoney(0);
    }else{
        const params = new URLSearchParams();
        params.append('mem_idx', user_idx);
        params.append('mh_idx', mh_idx);
        axios.post(`${CONFIG.SERVER_HOST}/admin_exchangeinfo_detail`, params).then((res) => {
          if(res.data.retvalue === 1) {
            let datas = res.data.results[0];
            setUserData(datas); 
            // setMhType(datas.mh_status)
            setNoExchange(datas.mh_no_exchange)
            setExchangeDate(datas.mh_pdate)
            setUserAccount(res.data.accounts[0])
            setCommissionMoney(datas.mh_commission)
            setTaxMoney(datas.mh_tax)
            setRealExchangeMoney(datas.mh_exchange)
            setRequestMoney(datas.mh_exchange+datas.mh_tax+datas.mh_commission)
          }
        });
    }
  }
  
  //환전예정일 선택
  const exchangeDateHandler = (e) => {
    setExchangeDate(e.target.value)
  }

  //환전불가 사유
  const onExchangeUpdateHandler =()=>{
      let sendData;
      if(Number(mhType) === -1){
        sendData = {
          'mem_idx': user_idx,
          'mh_idx': mh_idx,
          'mh_status': mhType,
          'mh_no_exchange': noExchange,
          'mh_pdate': exchangeDate,
          'mh_paid': prevMoney,
          'mh_balance': currentHedingMoney,
          'mh_commission': varCommissionMoney,
          'mh_tax': varTaxMoney,
          'mh_exchange': varRealExchangeMoney
        }
      }else{
        sendData = {
          'mem_idx': user_idx,
          'mh_idx': mh_idx,
          'mh_status': mhType,
          'mh_no_exchange': noExchange,
          'mh_pdate': exchangeDate,
          'mh_paid': currentHedingMoney,
          'mh_balance': currentHedingMoney-(varCommissionMoney+varTaxMoney+varRealExchangeMoney),
          'mh_commission': varCommissionMoney,
          'mh_tax': varTaxMoney,
          'mh_exchange': varRealExchangeMoney
        }
      }

      axios.post(`${CONFIG.SERVER_HOST}/update_exchangeinfo_detail`, sendData, CONFIG.header).then((res) => {
        if(res.data.retvalue === 1) {
          alert(res.data.message)
        }else{
          alert(res.data.message)
        }
      });
  }

    //보유중인 헤딩머니 가져오기
    useEffect(async() => {
      const params = new URLSearchParams();
      params.append("mem_idx", user_idx);
  
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_heding_money`, params)
        .then((res) => {
          if(res.data.retvalue === 1){
            setCurrentHedingMoney(res.data.results[0].mh_paid)
          }
        });
    },[])
    
    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="users"/>
            <Menu menuType="users" menu="exchangeList"/>
            <div id="contents" className="ad_contents">
              <div className="tit">환전관리 상세
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>환전관리<img src={arrImage} alt=""/>환전관리 상세
                </div>
              </div>
                <div className="content">
                    <div className="tit">회원정보</div>
                    {/* 내부 메뉴 */}
                    <div className="main-box">
                      <table className="write"> 
                        <colgroup>
                        <col style={{width:"15%"}}/>
                        <col style={{width:"35%"}}/>
                        <col style={{width:"15%"}}/> 
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>아이디</th>
                            <td style={{ textAlign: "left" }}>{varUserData.mem_id}</td> 
                            <th>이름</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData.mem_name}
                            </td>
                          </tr>  
                          <tr>
                            <th>휴대폰</th>
                            <td style={{ textAlign: "left" }}>{varUserData.mem_mobile}
                            </td>
                            <th>회원유형</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData.mem_mode === 0
                                ? "일반회원"
                                : varUserData.mem_mode === 1
                                ? "기업회원"
                                : varUserData.mem_mode === 2
                                ? "헤드헌터" : ""}
                            </td>
                          </tr> 
                      
                          <tr>
                            <th>은행명</th>
                            <td style={{ textAlign: "left" }}>{userAccount?.bl_name === undefined ? "-" : userAccount?.bl_name}</td>
                            <th>계좌번호</th>
                            <td style={{ textAlign: "left" }}>{userAccount?.mc_account === undefined ? "-" : userAccount?.mc_account}</td>
                          </tr>
                          <tr>
                            <th>주민등록번호</th>
                            <td style={{ textAlign: "left" }}>{varUserData?.mem_nid === undefined ? "-" : varUserData?.mem_nid}</td>
                          </tr>
                          {varUserData.mem_mode === 0 ? "" : 
                          <tr>
                            <th>회사명</th>
                            <td style={{ textAlign: "left" }}>{varUserData.mcm_name}</td>
                          </tr> 
                          }
                        </tbody>
                      </table>
                    </div> 
                    <div className="tit">환전정보</div>
                    {/* 내부 메뉴 */}
                    <div className="main-box">
                      <table className="write"> 
                        <colgroup>
                        <col style={{width:"15%"}}/>
                        </colgroup>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <th>상태</th>
                            <td style={{ textAlign: "left" }}>
                              <select style={{width:"180px"}} value={mhType} onChange={(e)=>onSelectHandler(e)}> 
                                <option value="1">환전신청</option>
                                <option value="2">승인완료</option>
                                <option value="3">환전완료</option>
                                <option value="-1">환전취소</option>
                              </select>
                            </td> 
                          </tr>
                          <tr>
                            <th>보유중인 헤딩머니</th>
                            <td style={{ textAlign: "left" }}>
                             {addThousandPoint(currentHedingMoney) + " 원"}
                            </td>
                          </tr>  
                          <tr>
                            <th>환전요청금액</th>
                            <td style={{ textAlign: "left" }}> 
                              {addThousandPoint(requestMoney) + " 원"}
                            </td>
                          </tr>
                          <tr>
                            <th>환전수수료</th>
                            <td style={{ textAlign: "left" }}>
                              {(mhType === -1 ? 0 : addThousandPoint(varCommissionMoney)) + " 원"}
                              {/* {addThousandPoint(varCommissionMoney) + " 원"} */}
                              <span style={{marginLeft:"1%", color:"#d02928"}}>(10%)</span>
                            </td>
                          </tr> 
                          <tr>
                            <th>소득세/주민세</th>
                            <td style={{ textAlign: "left" }}>
                              {(mhType === -1 ? 0 :addThousandPoint(varTaxMoney)) + " 원"}
                              <span style={{marginLeft:"1%", color:"#d02928"}}>(3.3%)</span>
                            </td>
                          </tr> 
                          <tr>
                            <th>실 환전금액</th>
                            <td style={{ textAlign: "left" }}> 
                              {(mhType === -1 ? 0 :addThousandPoint(varRealExchangeMoney)) + " 원"}
                            </td>
                          </tr>
                          <tr>
                            <th>환전 예정일</th>
                            <td style={{ textAlign: "left" }}> 
                            <input type="date"  name=""  id="datePicker" value={exchangeDate || ""} onChange={exchangeDateHandler}/>
                            </td>
                          </tr> 
                          <tr>
                            <th>환전불가 사유</th>
                            <td style={{ textAlign: "left" }}> 
                              <textarea value={noExchange || ""} onChange={(e)=>onChangeHandler(e)}
                              />
                            </td>
                          </tr> 
                        </tbody>
                      </table>
                    </div> 
                    <div className="btn-center">
                    <button onClick={onExchangeUpdateHandler}>수정</button>
                    <button className="btn-danger"><Link to={"/admin/exchangelist"} style={{ color: "#fff" }}>목록</Link></button>
                  </div>                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExchangeInfo1;