/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';

//components
import CompanyVoucherUsedSearch from "./CompanyVoucherUsedSearch";
import Paging from '../Layout/Paging'
import { ChangeDateString } from '../Common/Common'

/* 이용권 내역 */
function CompanyVoucherUsedList() {
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx');
  const mem_sub = localStorage.getItem('mem_sub');

  //페이지
  const [varTotalCount,setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum,setPageNum] = useState(1);

  //직원목록
  const [varMemberList,setMemberList] = useState([]);
  //표시목록 - 이용권사용목록
  const [varPageVoucherList,setPageVoucherList] = useState([]);

  //검색조건항목 - 이용자
  const [varUseSearchIdx, setuseSearchIdx] = useState(0);
  //검색조건항목 - 검색일자
  const [varUseSearchDate, setuseSearchDate] = useState({
    sdate:null,
    edate:null
  });

  //구매내역
  const getVoucherUsingList = async () => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      params.append("mem_sub", mem_sub);
      params.append("pageSize", varPageSize);
      params.append("pageNum", varPageNum);
      params.append("subIdx", varUseSearchIdx);
      params.append('sdate', varUseSearchDate.sdate === '' || varUseSearchDate.sdate === null ? '' : varUseSearchDate.sdate);
      params.append('edate', varUseSearchDate.edate === '' || varUseSearchDate.edate === null ? '' :varUseSearchDate.edate);

      await axios.post(`${CONFIG.SERVER_HOST}/voucherUsingList`, params).then((res) => {
          if (res.data?.retvalue === 1) {
            console.log(res.data);
            setMemberList(res.data?.members);
            setTotalCount(res.data?.count);
            setPageVoucherList(res.data?.usingList);
          } else {
            alert(res.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVoucherUsingList();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varUseSearchIdx ]);

  //검색시작의 경우
  const onSearchHandler =(e) =>{
    setPageNum(1);
    setPageVoucherList([]);
    getVoucherUsingList();
  }

  return (
      <>
        <CompanyVoucherUsedSearch varMemberList={varMemberList} onSearchHandler={onSearchHandler} varUseSearchDate={varUseSearchDate} 
                          setuseSearchDate={setuseSearchDate} varUseSearchIdx={varUseSearchIdx} setuseSearchIdx={setuseSearchIdx}/>
        <section className="support_list_area">
            <ul className="table table_list01">
                <li className="tr">
                    <p className="th row_people">후보자 명</p>
                    <p className="th row_user">사용자</p>
                    <p className="th row_date">사용일자</p>
                    {/* <p className="th row_type">이용권 종류</p> */}
                    {/* <p className="th row_remain">잔여 개수</p> */}
                </li>
                {varTotalCount !== 0 ? varPageVoucherList.map((data,index) => (
                <li key={'used_'+index} className="tr">
                    <p className="td row_people">{data?.emp_name}<span className="m_th">{data?.mem_id}</span></p>
                    <p className="td row_user">{data?.mem_id}</p>
                    <p className="td row_date"><span className="m_th">사용일자 :&nbsp;</span>{ChangeDateString(data?.csh_rdate)}</p>
                    {/* <p className="td row_type"><span className="m_th">이용권 종류 :&nbsp;</span>인재검색 100+</p> */}
                    {/* <p className="td row_remain"><span className="m_th">이용권 남은 수 :&nbsp;</span>99</p> */}
                </li>
                ))
                :
                <li className="tr">
                  <p className="td no_data">이용권 사용내역이 없습니다</p>
                </li>
              }
            </ul>
        </section>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </>
  );
}

export default CompanyVoucherUsedList;
