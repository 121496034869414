import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../configs/configs";
import axios from "axios";

import JoinCompanyInfo from "./JoinCompanyInfo";
import JoinManager from "./JoinManager";
import Term from "./Term";
import { isPassword, getIPData, AppLoginSuccess } from "../Common/Common";

const JoinHeadHunterC2 = () => {
  const [varConfirms, setConfirms] = useState({
    checkID: false,
    checkNickname: false,
    checkEmail: false
  })
  const [varFile, setFile] = useState("");
  const [varInputs2, setInputs2] = useState({
    comName: "",
    ceoName: "",
    taxId: "",
    // taxUfile: "",
    zipcode: "",
    address1: "",
    address2: "",
  });

  const [varInputs, setInputs] = useState({
    mode: 2,
    hunter_type: 1,
    id: "",
    pwd: "",
    pwdCheck: "",
    nickname: "",
    name: "",
    gender: 0,
    mobile: "",
    certification: false,
    email1: "",
    email2: "",
    condition: 1,
    privacy: 1,
    selfagree: 1,
    expiration: 0,
    rdate: "",
    ip: "",
    userAgent: 0,
    pushagree: 1
  });

  const navigator = useNavigate();

  //최초 - IP주소 획득
  useEffect(() => {
    getIPData(varInputs, setInputs, 'ip');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler = async () => {
    try {
      if (!varInputs2.taxId) {
        alert("사업자등록번호를 입력해주세요.");
      } else if (!varInputs2.taxId) {
        alert("사업자등록번호 형식에 맞지 않습니다.");
      } else if (!varInputs2.comName) {
        alert("회사명을 입력해주세요.");
      } else if (!varInputs2.ceoName) {
        alert("대표자명을 입력해주세요.");
      } else if (!varInputs2.address1) {
        alert("회사주소를 입력해주세요.");
      } else if (!varFile) {
        alert("사업자등록증을 첨부해주세요.");
      } else if (!varInputs.id) {
        alert("아이디를 입력해주세요.");
      } else if (!varConfirms.checkID) {
        alert("아이디 중복확인을 해주세요.");
      } else if (!varInputs.pwd) {
        alert("비밀번호를 입력해주세요.");
      } else if (!isPassword(varInputs.pwd)) {
        alert("비밀번호 형식에 맞지 않습니다. \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.");
      } else if (!varInputs.pwdCheck) {
        alert("비밀번호 확인 위해 한 번 더 입력해주세요.");
      } else if (varInputs.pwd !== varInputs.pwdCheck) {
        alert("두 비밀번호가 다릅니다.");
      } else if (!varInputs.name) {
        alert("이름을 입력해주세요.");
      } else if (varInputs.name.length <= 1) {
        alert("이름을 2자리 이상 입력해주세요");
      } else if (!varInputs.email1 || !varInputs.email2) {
        alert("이메일을 입력해주세요.");
      } else if (!varConfirms.checkEmail) {
        alert("이메일 중복확인을 해주세요.");
      } else if (!varInputs.mobile) {
        alert("휴대폰번호를 입력해주세요.");
      } else if (!varInputs.certification) {
        alert("휴대폰인증을 해주세요");
      } else if (varInputs.condition !== 1 || varInputs.privacy !== 1 || varInputs.selfagree !== 1) {
        alert("약관에 모두 동의해주세요.");
      } else {

        saveCompanyMember();
      }
    } catch (error) {
      alert("헤드헌터 등록에 실패하였습니다.");
      console.log(error);
    }
  };

  //서버 데이타베이스에 기업회원 등록
  const saveCompanyMember = async () => {

    //사업자등록증 첨부파일 업로드
    const formData = new FormData();
    formData.append("tax_file", varFile);
    await axios.post(`${CONFIG.SERVER_HOST}/tax_file`, formData).then((res) => {
      console.log(res.data.fileName);
      //서버에 등록된 파일명 재설정
      const savedFileName = res.data.fileName;

      const params = new URLSearchParams();
      params.append("varInputs", JSON.stringify(varInputs));
      params.append("varInputs2", JSON.stringify(varInputs2));
      params.append("taxUfile", savedFileName);
      params.append("pushagree", varInputs.pushagree);
      axios.post(`${CONFIG.SERVER_HOST}/join_company`, params).then((res) => {
        if (res.data.retvalue === 1) {
          const datas = res.data;
          AppLoginSuccess(datas.mem_idx);
          localStorage.setItem("memlogin", true);
          localStorage.setItem("mem_id", varInputs.id);
          localStorage.setItem("mem_idx", datas.com_idx);
          localStorage.setItem("mem_nickname", varInputs2.comName);
          localStorage.setItem("mem_mode", 2); //0:일반/1:기업/2:헤드헌터
          localStorage.setItem("mem_hunter_type", 1); //0: 기업-일반/1:기업 프리미엄 or 헤드헌터 일반/2:헤드헌터-프리미엄
          localStorage.setItem("mcm_type", 0);
          localStorage.setItem("mcm_idx", datas.mcm_idx);
          localStorage.setItem("mcm_name", varInputs2.comName);
          localStorage.setItem("mem_sub", datas.com_idx);
          localStorage.setItem("com_idx", datas.com_idx);
          localStorage.setItem("mem_approval", 0);
          navigator("/Member/JoinCompanyOK/22");
        } else {
          alert(res.data.message);
        }
      });
    });


  }

  return (
    <>
      <div className="form_top">
        <h3 className="title_field">기업정보</h3>
        <p className="must_input">
          * 아래의 내용은 전부 필수로 입력하셔야 합니다.
        </p>
      </div>
      <JoinCompanyInfo varInputs2={varInputs2} setInputs2={setInputs2} setFile={setFile} varFile={varFile} />
      <div className="form_top">
        <h3 className="title_field">담당자 정보</h3>
        <p className="must_input">
          * 아래의 내용은 전부 필수로 입력하셔야 합니다.
        </p>
      </div>
      <JoinManager varInputs={varInputs} setInputs={setInputs} varConfirms={varConfirms} setConfirms={setConfirms} varSnsInfo={{ sns_id: '', sns_type: '' }} />
      <Term varInputs={varInputs} setInputs={setInputs} onSubmitHandler={onSubmitHandler} />
    </>
  );
};

export default JoinHeadHunterC2;
