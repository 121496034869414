import React, { useEffect } from "react";
import FAQList from "../Board/FAQList";
import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding8({ getSubItem }) {

  useEffect(() => {
    getSubItem(8);
    resetNavSubMenuForHeding(8);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_my_faq">
      <FAQList/>
    </div>
  );
}

export default MyHeding8;
