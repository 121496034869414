/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import CONFIG from '../../../configs/configs';
import { changeMobileFormat, ChangeMoneyUnitNoText, split_1, split_2, numberToKorean2, em_titleLength, getMonthDiff, getMonthDiff2, monthDiff2 } from '../../../components/Common/Common'
import Paging from '../../../components/Layout/Paging'
import moment from 'moment';

function UserResumeDetail() {
    //const locations = useLocation();

    const { re_idx } = useParams();

    //re_idx

    useEffect(() => {
        if (re_idx !== undefined && re_idx !== null) {
            console.log(re_idx);
            /* setResumeIndex(locations.state.re_idx); */
            getResumeDetailData(re_idx);
        }
    }, [re_idx]);

    useEffect(() => {
        axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type`).then((res) => {
            setVariableEmployType(res.data);
        });
        axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
            setLanguageLevel(res.data);
        });
    }, []);
    const lastYear = new Date().getFullYear() - 1;
    const [varLanguageLevel, setLanguageLevel] = useState([]);
    const [variableEmployType, setVariableEmployType] = useState([]);

    const [ResumeData, setResumeData] = useState({}); // 이력서 정보
    const [ResumeEducation, setResumeEducation] = useState([]); // 학력
    const [ResumeCareer, setResumeCareer] = useState([]); // 경력
    const [ResumeCareerAdd, setResumeCareerAdd] = useState([])// 추가 경력
    const [ResumePortfolio, setResumePortfolio] = useState([]); // 포트폴리오
    const [ResumeLanguage, setResumeLanguage] = useState([]) // 어학
    const [ResumeCetificate, setResumeCertificate] = useState([]) //자격증
    const [ResumeAward, setResumeAward] = useState([]) // 수상 이력
    const [varEmployApply, setEmployApply] = useState([]);

    const [varPagelist, setPagelist] = useState([]);

    //페이징
    const [varTotalCount, setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum, setPageNum] = useState(1);


    const [ced, setCED] = useState({
        certification_experience_year: '',
        certification_experience_date: ''
    });

    const [aed, setAED] = useState({
        additional_career_year: '',
        additional_career_date: ''
    });

    const getResumeDetailData = (re_idx) => {
        if (re_idx !== null && re_idx > 0) {
            const params = new URLSearchParams();
            params.append('re_idx', re_idx);
            axios.post(`${CONFIG.SERVER_HOST}/_getResumeDetail`, params).then((res) => {
                if (res.data.retvalue === 1) {
                    console.log(res, "asdf");
                    setResumeData(res.data.resumeDetails[0]); // 이력서 정보
                    setResumeEducation(res.data.educations); // 학력

                    setResumeCareer(res.data.careers); // 경력
                    console.log(res.data.careers);
                    setResumeCareerAdd(res.data.addCareers); // 추가 경력
                    setResumePortfolio(res.data.portfolios); // 포트폴리오
                    setResumeLanguage(res.data.language); // 어학
                    setResumeCertificate(res.data.certificate) // 자격증
                    setResumeAward(res.data.awards) // 수상이력

                    setTotalCount(res.data.employs.length);
                    setEmployApply(res.data.employs);
                }
            });
        }
    }

    //페이지별 표시
    const getPageLists = () => {
        const startNum = (varPageNum - 1) * varPageSize;
        const endNum = startNum + varPageSize;
        const copyData = varEmployApply.slice(startNum, endNum);
        setPagelist(copyData);
    }

    useEffect(() => {
        getPageLists();
    }, [varPageNum, varEmployApply]);
    console.log(varEmployApply)

    //언어레벨 세팅 함수
    const getLanguageCode = (level) => {
        const LanguageLevel = varLanguageLevel.filter((language) => language.lal_code === Number(level));
        return LanguageLevel[0]?.lal_name;
    }
    // 고용형태 세팅 함수
    const text = (re_employ_hope) => {
        const textList = variableEmployType.filter((el) => el.emt_idx === re_employ_hope);
        // console.log(textList);
        return !textList[0]?.emt_name ? '-' : textList[0]?.emt_name;
    }
    // 사이트 링크 클릭.
    const handleSiteListClick = () => {
        //새탭에서 열기
        window.open(`${ResumeData.re_site}`);
    }


    // const fileDown =(file_name)=>{
    //     //file_downfile_url

    //     const params = new URLSearchParams();
    //     params.append('file_name', file_name);
    //     params.append('file_url', 'resume');
    //     axios.get(`${CONFIG.SERVER_HOST}/file_down?file_name=${file_name}&file_url=resume`, params).then((res) => {
    //         console.log(res);
    //     });
    // }

    useEffect(() => {
        console.log('1111111');
        if (ResumeCareer.length > 0) {
            console.log('2222222');
            if (!ResumeData.certification_experience_year && !ResumeData.certification_experience_date) {
                monthDiff_1(ResumeCareer);
            }
        }
    }, [ResumeCareer]);


    useEffect(() => {
        if (ResumeCareerAdd.length > 0) {
            if (!ResumeData.additional_career_year && !ResumeData.additional_career_date) {
                console.log('33333333');
                monthDiff_2(ResumeCareerAdd);
            }
        }
    }, [ResumeCareerAdd]);


    const monthDiff_1 = (arr) => {
        let monthArr = [];
        let total = 0;
        arr.map(item => {
            if (item.rec_sdate !== null) {
                const data1 = item.rec_sdate.split('-');
                const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
                const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

                console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
                total += t1;
            }

        });
        const quotient = parseInt(total / 12); //몫
        const remainder = total % 12; //나머지
        monthArr.push(quotient, remainder)
        let strCareer = ``;
        if (Number(monthArr[0]) > 0) {
            strCareer = `${monthArr[0]}년`;
        };
        if (Number(monthArr[1]) > 0) {
            strCareer += ` ${monthArr[1]}개월`;
        };

        console.log(quotient + "." + remainder);
        setCED({
            certification_experience_year: quotient,
            certification_experience_date: remainder
        });
    };

    const monthDiff_2 = (arr) => {
        let monthArr = [];
        let total = 0;
        arr.map(item => {
            if (item.rac_sdate !== null) {
                const data1 = item.rac_sdate.split('-');
                const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
                const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

                console.log('t1 : ', item.rac_sdate, item.rac_edate, t1);
                total += t1;
            }

        });
        const quotient = parseInt(total / 12); //몫
        const remainder = total % 12; //나머지
        monthArr.push(quotient, remainder)

        let strCareer = ``;
        if (Number(monthArr[0]) > 0) {
            strCareer = `${monthArr[0]}년`;
        };
        if (Number(monthArr[1]) > 0) {
            strCareer += ` ${monthArr[1]}개월`;
        };

        console.log(quotient + "." + remainder);
        setAED({
            additional_career_year: quotient,
            additional_career_date: remainder
        });

    };


    return (
        <React.Fragment>
            <p style={{ marginTop: "20px" }} className="table_title">- 회원정보</p>
            <div className="main-box" >
                <table className="write">
                    <thead></thead>
                    <tbody >
                        {ResumeData?.re_idx ?
                            <>
                                <tr>
                                    <th>이름</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.mem_name}</td>
                                </tr>
                                <tr>
                                    <th>이력서 사진</th>
                                    <td style={{ textAlign: "left" }}> <a href={`${CONFIG.SERVER_HOST}/file_down3?file_name=${ResumeData.re_ufile}&file_url=resume`} download >{ResumeData.re_ufile}</a></td>
                                </tr>
                                <tr>
                                    <th>성별</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.mem_gender === 1 ? '여' : '남'}</td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.re_birthday}</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td style={{ textAlign: "left" }}>{changeMobileFormat(ResumeData.mem_mobile)}</td>
                                </tr>
                                <tr>
                                    <th>근로소득[{ResumeData.re_income_lyear === null ? lastYear : ResumeData.re_income_lyear}]</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.re_income_last === 0 ? "-" : ChangeMoneyUnitNoText(ResumeData.re_income_last)}</td>
                                </tr>
                                <tr>
                                    <th>현재 연봉</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.re_income_this === 0 ? "-" : ChangeMoneyUnitNoText(ResumeData.re_income_this)}</td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.mem_email}</td>
                                </tr>
                            </>
                            : ""
                        }
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 학력</p>
            <div className="main-box" >
                <table className="write">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>학력</th>
                            <th style={{ textAlign: "center" }}>학교명</th>
                            <th style={{ textAlign: "center" }}>전공</th>
                            <th style={{ textAlign: "center" }}>입학년월</th>
                            <th style={{ textAlign: "center" }}>졸업년월</th>
                            <th style={{ textAlign: "center" }}>졸업상태</th>
                        </tr>
                    </thead>
                    <tbody >
                        {ResumeEducation.map((education, i) => (
                            <tr key={'edu_' + i}>
                                <td style={{ textAlign: "center" }}>{education.reh_level === 0 ? "고등학교" : education.reh_level === 2 ? "대학교(2~3년제)" : education.reh_level === 3 ? "대학교(4년제)" : education.reh_level === 10 ? "대학원 석사" : "대학원 박사"} </td>
                                <td style={{ textAlign: "center" }}>{education.reh_name}</td>
                                <td style={{ textAlign: "center" }}>{education.reh_major} {education?.reh_level === 10 ? CONFIG.Master : education?.reh_level === 11 ? CONFIG.Doc : ''}</td>
                                <td style={{ textAlign: "center" }}>{education.reh_sdate}</td>
                                <td style={{ textAlign: "center" }}>{education.reh_edate}</td>
                                <td style={{ textAlign: "center" }}>{education.reh_status === -2 ? "-" : (education.reh_name === null || education.reh_major === null) ? "-" : education.reh_state === 1 ? "졸업" : education.reh_state === 2 ? "졸업예정" : education?.reh_state === 3 ? " 재학중" : education?.reh_state === 4 ? " 중퇴" : education?.reh_state === 5 ? " 수료중" : education?.reh_state === 6 ? " 휴학" : "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 인증 경력</p>
            <div className="main-box" >
                <table className="write">
                    <tbody >
                        <tr>
                            <th>총 경력</th>
                            {/* <td style={{ textAlign: "left" }}>
                                {split_1(ResumeData.re_career_year)}년&nbsp;
                                {split_2(ResumeData.re_career_year)}개월
                            </td> */}
                            {ced.certification_experience_year || ced.certification_experience_date ?
                                <td style={{ textAlign: "left" }}>
                                    {`${ced.certification_experience_year} 년`}&nbsp;
                                    {`${ced.certification_experience_date}개월`}
                                </td>
                                :
                                ResumeData.certification_experience_year || ResumeData.certification_experience_date ?
                                    <td style={{ textAlign: "left" }}>
                                        {`${ResumeData.certification_experience_year} 년`}&nbsp;
                                        {`${ResumeData.certification_experience_date}개월`}
                                    </td>
                                    :
                                    null
                            }

                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="main-box" >
                <table className="write">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>회사명</th>
                            <th style={{ textAlign: "center" }}>부서명</th>
                            <th style={{ textAlign: "center" }}>직책</th>
                            <th style={{ textAlign: "center" }}>입사일</th>
                            <th style={{ textAlign: "center" }}>퇴직일</th>
                            <th style={{ textAlign: "center" }}>상태</th>
                        </tr>
                    </thead>
                    <tbody >
                        {ResumeCareer.length > 0 && ResumeCareer.map((career, i) => {
                            const recDetail = [];
                            let tmpLoop = [1, 2, 3, 4, 5];
                            const tempCareer = Object.assign({}, career);
                            tmpLoop.forEach(function (value) {
                                if (!_.isEmpty(tempCareer[`rec_note${value}`])) {
                                    recDetail.push({
                                        note: tempCareer[`rec_note${value}`],
                                        detail: tempCareer[`rec_detail${value}`],
                                    });
                                }
                            });
                            return (
                                <>
                                    <tr key={'career_' + i}>
                                        <td style={{ textAlign: "center" }}>{career.rec_company}</td>
                                        <td style={{ textAlign: "center" }}>{career.rec_department}</td>
                                        <td style={{ textAlign: "center" }}>{career.rec_position}</td>
                                        <td style={{ textAlign: "center" }}>{career.rec_sdate}</td>
                                        <td style={{ textAlign: "center" }}>{career.rec_edate}</td>
                                        <td style={{ textAlign: "center" }}>{career.rec_state === 1 ? '재직중' : '퇴직'}</td>
                                    </tr>
                                    {recDetail.map((element, ii) => {
                                        return (
                                            <tr key={'carb_' + ii} >
                                                <td style={{ textAlign: "right", padding: "5px 20px", height: "20px" }}>업무</td>
                                                <td colSpan={6}>- {element.note}</td>
                                            </tr>
                                        )

                                    })}


                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 추가 경력</p>
            <div className="main-box" >
                <table className="write">
                    <tbody >
                        <tr>
                            <th>총 경력</th>
                            {/* <td style={{ textAlign: "left" }}>
                                {split_1(ResumeData.re_careeradd_year)}년&nbsp;
                                {split_2(ResumeData.re_careeradd_year)}개월
                            </td> */}
                            {aed.additional_career_year || aed.additional_career_date ?
                                <td style={{ textAlign: "left" }}>
                                    {`${aed.additional_career_year} 년`}&nbsp;
                                    {`${aed.additional_career_date}개월`}
                                </td>
                                :
                                ResumeData.additional_career_year || ResumeData.additional_career_date ?
                                    <td style={{ textAlign: "left" }}>
                                        {`${ResumeData.additional_career_year} 년`}&nbsp;
                                        {`${ResumeData.additional_career_date}개월`}
                                    </td>
                                    :
                                    null
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="main-box" >
                <table className="write">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>회사명</th>
                            <th style={{ textAlign: "center" }}>부서명</th>
                            <th style={{ textAlign: "center" }}>직책</th>
                            <th style={{ textAlign: "center" }}>입사일</th>
                            <th style={{ textAlign: "center" }}>퇴직일</th>
                            <th style={{ textAlign: "center" }}>상태</th>
                        </tr>
                    </thead>
                    <tbody >
                        {ResumeCareerAdd.map((career, i) => {
                            const recDetail = [];
                            let tmpLoop = [1, 2, 3, 4, 5];
                            const tempCareer = Object.assign({}, career);
                            tmpLoop.forEach(function (value) {
                                if (!_.isEmpty(tempCareer[`rac_note${value}`])) {
                                    recDetail.push({
                                        note: tempCareer[`rac_note${value}`],
                                        detail: tempCareer[`rac_detail${value}`],
                                    });
                                }
                            });
                            return (
                                <>
                                    <tr key={'career_' + i}>
                                        <td style={{ textAlign: "center" }}>{career.rac_company}</td>
                                        <td style={{ textAlign: "center" }}>{career.rac_department}</td>
                                        <td style={{ textAlign: "center" }}>{career.rac_position}</td>
                                        <td style={{ textAlign: "center" }}>{career.rac_sdate}</td>
                                        <td style={{ textAlign: "center" }}>{career.rac_edate}</td>
                                        <td style={{ textAlign: "center" }}>{career.rac_state === 1 ? '재직중' : '퇴직'}</td>
                                    </tr>
                                    {recDetail.map((element, ii) => {
                                        return (
                                            <tr key={'carb_' + ii} >
                                                <td style={{ textAlign: "right", padding: "5px 20px", height: "20px" }}>업무</td>
                                                <td colSpan={6}>- {element.note}</td>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 어학</p>
            <div className="main-box" >
                <table className="write">
                    <tbody >
                        {ResumeLanguage.map((language, i) => (
                            <tr key={'edu_' + i}>
                                <th >언어</th>
                                <td >{language.language_name}</td>
                                <th >레벨</th>
                                <td >{getLanguageCode(language.language_level)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 자격증</p>
            <div className="main-box" >
                <table className="write">
                    {/* <thead>
                        <tr>

                        </tr>
                    </thead> */}
                    <tbody >
                        {ResumeCetificate.map((certificate, i) => (
                            <tr key={'edu_' + i}>
                                <th >자격증</th>
                                <td >{certificate.rc_name}</td>
                                <th >발급기관</th>
                                <td >{certificate.rc_issuer}</td>
                                <th >발급일</th>
                                <td >{certificate.rc_rdate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 수상이력</p>
            <div className="main-box" >
                <table className="write">
                    <tbody >
                        {ResumeAward.map((award, i) => (
                            <tr key={'edu_' + i}>
                                <th>수상내용</th>
                                <td >{award.ra_comment}</td>
                                <th>수여기관</th>
                                <td >{award.ra_issuer}</td>
                                <th >수여일</th>
                                <td >{award.ra_rdate.slice(0, 10)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 경력 기술서</p>
            <div className="main-box text pd20" dangerouslySetInnerHTML={{ __html: ResumeData?.re_career_description }}>
            </div>
            <p className="table_title">- 자기 소개서</p>
            <div className="main-box text pd20" dangerouslySetInnerHTML={{ __html: ResumeData?.re_comment }}>
            </div>
            <p className="table_title">- 포트폴리오</p>
            <div className="main-box" >
                <table className="write">
                    <tbody >
                        {ResumePortfolio.map((portfolio, i) => (
                            <tr key={"port_" + i}>
                                <th>첨부파일</th>
                                <td style={{ textAlign: "left" }}>
                                    <a href={`${CONFIG.SERVER_HOST}/file_down3?file_name=${portfolio.rcp_ufile}&file_url=port_folio`} download >{portfolio.rcp_ufile}</a>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <th>사이트링크</th>
                            <td style={{ textAlign: "left" }}>
                                <a onClick={() => handleSiteListClick()} style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer' }}>{ResumeData.re_site}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 근무 희망 조건</p>
            <div className="main-box" >
                <table className="write">
                    <thead>
                    </thead>
                    <tbody >
                        {ResumeData?.re_idx ?
                            <>
                                <tr>
                                    <th>고용형태</th>
                                    <td style={{ textAlign: "left" }}>{text(ResumeData.re_employ_hope)}</td>
                                </tr>
                                <tr>
                                    <th>연봉</th>
                                    <td style={{ textAlign: "left" }}>
                                        {ResumeData.re_after_meet === 1 ? '면접 후 결정' : numberToKorean2(ResumeData.re_income_hope) + "원"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>희망근무지</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.re_place_hope ? ResumeData.re_place_hope.replaceAll(/,/g, ', ') : "선택안함"}</td>
                                </tr>
                                <tr>
                                    <th>희망직무</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.re_task_hope ? ResumeData.re_task_hope.replaceAll(/,/g, ', ') : "선택안함"}</td>
                                </tr>
                                <tr>
                                    <th>관심기업</th>
                                    <td style={{ textAlign: "left" }}>{ResumeData.re_company_hope ? ResumeData.re_company_hope.replaceAll(/,/g, ', ') : "선택안함"}</td>
                                </tr>
                            </>
                            : ""
                        }
                    </tbody>
                </table>
            </div>
            <p className="table_title">- 지원 공고</p>
            <div className="main-box" >
                <table className="write">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>번호</th>
                            <th style={{ textAlign: "center" }}>채용제목</th>
                            <th style={{ textAlign: "center" }}>회사명</th>
                            <th style={{ textAlign: "center" }}>직무</th>
                            <th style={{ textAlign: "center" }}>보상금</th>
                            <th style={{ textAlign: "center" }}>지원일자</th>
                            <th style={{ textAlign: "center" }}>진행상태</th>
                            <th style={{ textAlign: "center" }}>채용 상세</th>
                        </tr>
                    </thead>
                    <tbody >
                        {varPagelist.map((employ, i) => (
                            <>
                                <tr key={'emp_' + i}>
                                    <td style={{ textAlign: "center" }}>{varTotalCount - (varPageNum - 1) * varPageSize - i}</td>
                                    <td style={{ textAlign: "center" }}>{em_titleLength(employ.em_title, 17, '...')}</td>
                                    <td style={{ textAlign: "center" }}>{employ.mcm_name}</td>
                                    <td style={{ textAlign: "center" }}>{em_titleLength(employ.em_tasks_info, 8, "...")}</td>
                                    <td style={{ textAlign: "center" }}>{ChangeMoneyUnitNoText(employ.em_reward_applicant)}</td>
                                    <td style={{ textAlign: "center" }}>{employ.ea_rdate}</td>
                                    <td style={{ textAlign: "center" }}>{employ.ems_name}</td>
                                    <td style={{ textAlign: "center" }}><Link to={`/admin/announcement_modify/${employ.ea_em_idx}`}>상세</Link></td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
                <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
            <div className="btn-center">
                <button className="btn-danger" ><Link to="/admin/userlist" style={{ color: "#fff" }}>목록</Link></button>
            </div>
        </React.Fragment>
    )

}

export default UserResumeDetail;