import { useState, useCallback, useEffect, useContext, useRef } from "react";

import ChatMsgItem from "./ChatMsgItem";

//makeMessage
import { SocketContext, SOCKET_EVENT } from "../Chat/socket";

function ChatMsg({ myNickname, crs_status, yourImg }) {

	const [messages, setMessages] = useState([]);
	const chatWindow = useRef(null);
	const socket = useContext(SocketContext);

	const moveScrollToReceiveMessage = useCallback(() => {
		if (chatWindow.current) {
			chatWindow.current.scrollTo({
				top: chatWindow.current.scrollHeight,
				behavior: "smooth",
			});
		}
	}, []);

	/** SOCKET EV : UPDATE_MESSAGE(메시지 수신) */
	const handleUpdateMessage = useCallback(pongData => {
		//console.log("handleUpdateMessage:", pongData);
		
		//2022-10-07 수신 대상이 '전체' 또는 내꺼(join 일 경우, 초반 메시지 전체)..
		if(pongData.to === 0 || pongData.to === parseInt(myNickname)){
			setMessages(messages => [...messages, pongData]);
			moveScrollToReceiveMessage();
		}
	},[moveScrollToReceiveMessage,myNickname]);

	/** SOCKET EV : JOIN_ROOM(채팅장 입장) */
	const handleJoinRoom = useCallback(pongData => {
		//console.log("handleJoinRoom");

		//2022-10-07 내가 join한 경우만 비우자.
		if(pongData.nickname === parseInt(myNickname)){
			setMessages([]);
		}
	},[myNickname]);

	/* JOIN_ROOM */
	useEffect(() => {
		socket.on(SOCKET_EVENT.JOIN_ROOM, handleJoinRoom);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, handleJoinRoom]);

	/* UPDATE_MESSAGE */
	useEffect(() => {
		socket.on(SOCKET_EVENT.UPDATE_MESSAGE, handleUpdateMessage);

		return () => {
			socket.off(SOCKET_EVENT.UPDATE_MESSAGE, handleUpdateMessage);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, handleUpdateMessage]);

	// useEffect(() => {
	// 	scrollToBottom();
	// }, [messages]);

	// const scrollToBottom = () => {
	// 	if (chatWindow.current) {
	// 		chatWindow.current.scrollTop = chatWindow.current.scrollHeight;
	// 	}`
	// };

	return (
		<div className="wrap scroll" ref={chatWindow}>
			{messages.map((message, index) => {
				return <ChatMsgItem key={index} myNickname={myNickname} message={message} crs_status={crs_status} yourImg={yourImg} />;
			})}
		</div>
	);
}

export default ChatMsg;