import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import CONFIG from "../../configs/configs";
import { Certification, changeMobileFormat } from "../Common/Common";
import axios from "axios";

const FindPWC = () => {
  const navigate = useNavigate();

  //인증
  const [varAuth, setAuth] = useState({
    mobile: false,
  });

  const [varInputs, setInputs] = useState({
    id: "",
    mobile: "",
  });

  //입력정보 저장
  const onChangeInputHandler = (e) => {
    const { value, name } = e.currentTarget;
    setInputs({ ...varInputs, [name]: value });
  };
 
  //패스워드찾기
  const onSubmitHandler = async (e) => {
    if (!varInputs.id) {
      alert("아이디를 입력해주세요.");
    } else if ( varAuth.mobile === false) {
      alert("휴대폰 인증을 해주세요.");
    } else {
      const param = new URLSearchParams();
      param.append("mem_id", varInputs.id);
      param.append("mem_mobile", varInputs.mobile);
      //서버 데이타베이스로 등록
      await axios.post(`${CONFIG.SERVER_HOST}/findPw`, param).then((res) => {
        console.log(res.data);
        if (res.data.retvalue === 0) {
          alert(res.data.message);
        } else {
          localStorage.setItem("found_id", varInputs.id);
          navigate("/Member/EditPW");
        }
      });
    }
  };

  //핸드폰 인증
  const onMobileCertification = (e) => {
      Certification(varInputs,setInputs,varAuth, setAuth,0);

    }

  return (
    <div id="sub_wrap" className="center_wrap_member">
      <h2 className="sub_content_title">비밀번호 찾기</h2>
      <p className="sub_content_text">
        본인인증 회원가입 시 등록했던 정보를 입력하세요.
      </p>
      <div className="box member_form">
        <p className="title_field">아이디</p>
        <input
          type="text"
          name="id"
          placeholder="아이디를 입력해주세요"
          onChange={(e) => onChangeInputHandler(e)}
          value={varInputs.id}
        />
        <p className="title_field">휴대폰 번호</p>
        <div className="input_wrap">
          <input
            type="text"
            name="mobile"
            onChange={(e) => onChangeInputHandler(e)}
            value={changeMobileFormat(varInputs.mobile)}
            readOnly
          />
          <div onClick={(e) => onMobileCertification(e)} className="btn white">
            휴대폰본인인증
          </div>
        </div>
        <div className="bottom_btn_area">
          <div onClick={(e) => onSubmitHandler(e)} className="btn">
            확인
          </div>
          <Link to={"/main"} className="btn gray">
            메인으로
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FindPWC;
