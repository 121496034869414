import React, {useState, useEffect, useCallback} from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import CONFIG from "../../configs/configs";
//import LoadingAni from 'components/Common/Loading';


function RequireAuth({ chkLevel, chkDepth, children }) {
  const [Acccheck, setAcccheck] = useState(0);
  const [Loading, setLoading] = useState(true);
  const [Reurl, setReurl] = useState(null);

  const uNavigate = useNavigate();

  //let sessionStorage = window.sessionStorage;
  let mem_idx = localStorage.getItem("mem_idx");
  let mem_mode = localStorage.getItem("mem_mode");
  let mem_hunter_type = localStorage.getItem("mem_hunter_type");

  //let re_url = "/Login";
  let uri_path = window.location.pathname;
  //RUI 판별
  if(chkDepth > 0){//일부경로
    console.log("일부경로");
    let splitURL = uri_path.split('/', chkDepth+1);
    uri_path = splitURL.join('/')
  }

  console.log("uri_path",uri_path);

  const mainApi = useCallback( async () => {
    setLoading(true); // api 호출 전에 true로 변경하여 로딩화면 띄우기
    try {
      const params = new URLSearchParams();
      params.append("acc_uri", uri_path);
      params.append("mem_idx", mem_idx);  //2022-08-18 기업 승인여부 확인 위해 추가
      params.append("mem_mode", mem_mode);
      params.append("mem_type", mem_hunter_type);
      const response = await axios.post(`${CONFIG.SERVER_HOST}/access_page`, params);

      const body = await response.data;
      //console.log('body', body);
      if(body.retvalue===0){
        console.log("이전 페이지", useLocation.pathname);
        alert(body.message);
        uNavigate(-1);//history.back(-1);
      }
      setAcccheck(body.retvalue);
      setLoading(false); // api 호출 완료 됐을 때 false로 변경하려 로딩화면 숨김처리
      return body;
    } catch (error) {
      window.alert(error);
    }
  },[mem_hunter_type,mem_mode,mem_idx,uri_path,uNavigate,setAcccheck,setLoading]);
  
  useEffect(() => {
    //if(chkLevel===0){//기본 로그인 체크만
    if (!mem_idx || Number(mem_idx)===0) {
      localStorage.setItem("re_url", uri_path);
      setReurl("/Login");
      alert("로그인 후 이용 가능합니다.");
      setLoading(false);
    }else if(chkLevel===1){//페이지 권한 체크
      console.log("페이지 권한");
      console.log("document.referrer", document.referrer);
      mainApi()
        .then(res => {
          console.log("res.retvalue",res.retvalue);
        })
        .catch(err => console.log(err));
    }
   
  }, [chkLevel,mem_idx,mainApi,uri_path]);

  useEffect(() => {
    console.log("Acccheck",Acccheck);
  },[Acccheck]);

  useEffect(() => {
    console.log("Loading",Loading);
  },[Loading]);

  if(Loading===true){
    return(<></>);
  }else{
    if(Acccheck===1){//인증성공
      return children;
    }else{//이증실패
      if(Reurl === null){//인증실패 - redirection 없음
        return(<></>);
      }else{//인증실패 - redirection 있음
        return <Navigate to={Reurl} />;
      }
    }
  }




}

export default RequireAuth;