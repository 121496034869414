/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Paging from '../../../components/Layout/Paging'
import { ChangeMoneyUnit } from "components/Common/Common.js";

function Headhuntet_Recruitment_Support({ setCategory }) {
  const { mem_idx, mode } = useParams();
  console.log(mode)

  const [varRecruitment, setRecruitment] = useState([]);
  const [varPagelist, setPagelist] = useState([]);

  //페이징
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  // const [varCounts, setCounts] = useState({
  //   unread: 0,
  //   review: 0,
  //   documents: 0,
  //   interview: 0,
  //   passed: 0,
  //   joined: 0,
  //   failed: 0,
  //   earlyout: 0
  // });

  const [varCountRecommend, setCountRecommend] = useState(0);
  const [varCountApply, setCountApply] = useState(0);

  useEffect(async () => {

    console.log('user_idx :', mem_idx);
    if (Number(mode) === 2) {
      setCategory(2);
    } else {
      setCategory(4);
    }
    if (mem_idx > 0) {
      const params = new URLSearchParams();
      params.append('mem_idx', mem_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/headhunter_apply_list`, params).then((res) => {
        console.log(res.data)
        if (res.data.retvalue === 1) {
          var datas = [];
          // if (Number(mode) === 2) {
          //   datas = res.data.results1;
          // } else {
          datas = res.data.results2;
          // }

          console.log(datas);
          setTotalCount(datas.length);
          setRecruitment(datas);

          // if (Number(mode) === 2) { //프리랜서
          //   setCountering(datas);
          // } else {
          setCountRecommend(res.data.tcounts);
          setCountApply(res.data.acounts);
          // }
        }
      });
    }
  }, []);

  //진행사항 카운팅
  // const setCountering = (datas) => {
  //   const unread = datas.filter(recruitment => recruitment.ea_checking === 0);
  //   const review = datas.filter(recruitment => recruitment.ea_checking === 1);
  //   const documents = datas.filter(recruitment => recruitment.ea_checking === 2);
  //   const interview = datas.filter(recruitment => recruitment.ea_checking === 3);
  //   const passed = datas.filter(recruitment => recruitment.ea_checking === 4);
  //   const joined = datas.filter(recruitment => recruitment.ea_checking === 5);
  //   const failed = datas.filter(recruitment => recruitment.ea_checking === -2);
  //   const earlyout = datas.filter(recruitment => recruitment.ea_checking === -3);
  //   setCounts({
  //     unread: unread.length, review: review.length, documents: documents.length, interview: interview.length,
  //     passed: passed.length, joined: joined.length, failed: failed.length, earlyout: earlyout.length
  //   });
  // }

  //페이지별 표시
  const getPageLists = () => {
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    const copyData = varRecruitment.slice(startNum, endNum);
    setPagelist(copyData);
  }

  useEffect(() => {
    getPageLists();
  }, [varPageNum, varRecruitment]);

  return (
    <React.Fragment>
      <p className="table_title" style={{ marginTop: "20px" }}>- 채용 추천 현황</p>
      <div className="main-box" >
        {/* {Number(mode) === 2 ?
          <table className="list">
            <thead>
              <tr>
                <th>미열람</th><th>검토중</th><th>서류합격</th><th>면접합격</th><th>최종합격</th><th>불합격</th><th>입사</th><th>조기퇴사</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{varCounts.unread}</td>
                <td>{varCounts.review}</td>
                <td>{varCounts.documents}</td>
                <td>{varCounts.interview}</td>
                <td>{varCounts.passed}</td>
                <td>{varCounts.failed}</td>
                <td>{varCounts.joined}</td>
                <td>{varCounts.earlyout}</td>
              </tr>
            </tbody>
          </table>
          : */}
        <table className="list">
          <thead>
            <tr>
              <th>추천 발송 수</th><th>회사 지원한 수</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{varCountRecommend}</td><td>{varCountApply}</td>
            </tr>
          </tbody>
        </table>
        {/* } */}
      </div>
      <div className="main-box ">
        <table className="list">
          <colgroup>
            <col style={{ width: "8%" }} />
            <col style={{ width: "16%" }} />
            <col style={{ width: "12%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "8%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>채용제목</th>
              <th>회사명</th>
              <th>직무</th>
              <th>보상금</th>
              <th>추천인</th>
              <th>후보자</th>
              <th>추천일자</th>
              <th>진행일자</th>
              <th>진행상태</th>
            </tr>
          </thead>
          <tbody>
            {varPagelist.map((resume, i) => (
              <tr key={i}>
                <td>{varTotalCount - (varPageNum - 1) * varPageSize - i}</td>
                <td className="ellipsis">{resume.em_title}</td>
                <td className="ellipsis">{resume.mcm_name}</td>
                <td className="ellipsis">{resume.em_tasks_info}</td>
                <td>{ChangeMoneyUnit(resume.em_reward_applicant / 10000)}</td>
                <td>{resume.er_mem_id}</td>
                <td className="ellipsis">{resume.er_name}</td>
                <td className="ellipsis">{resume.er_rdate === null || resume.er_rdate === "" ? "-" : resume.er_rdate.substr(0, 10)}</td>
                <td className="ellipsis">{resume.ea_rdate === null || resume.ea_rdate === "" ? "-" : resume.ea_rdate.substr(0, 10)}</td>
                <td>{resume.ems_name === null ? "-" : resume.ems_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
      </div>
    </React.Fragment>
  )

}

export default Headhuntet_Recruitment_Support;