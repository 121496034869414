/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from 'configs/configs';
import { unescapeHtml } from '../Common/Common';
import { useNavigate } from "react-router-dom";

//이미지
const defaultCompanyLogo = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`;

function PopEditProfile({ varPopup, onSetPopup, varSelectOffer, onChangeSuggestStatus, jobPosting }) {
    const navigator = useNavigate();
    const imageFolder = `${CONFIG.SERVER_HOST}/mcm_image/`;
    const [varOfferData, setOfferData] = useState({});
    useEffect(() => {
        if (varSelectOffer > 0) {
            const params = new URLSearchParams();
            params.append("crs_idx", varSelectOffer);
            axios.post(`${CONFIG.SERVER_HOST}/_get_employ_offer`, params).then((res) => {
                console.log(res.data);
                if (res.data.retvalue === 1) {
                    const result = res.data.results;
                    setOfferData(result);
                    jobPosting.current.hostMobile = result.mem_mobile;
                    jobPosting.current.title = result.crs_em_title;
                    jobPosting.current.task = result.crs_em_tasks;
                } else {
                    window.alert(res.data.message);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [varSelectOffer, varOfferData?.mcm_image]);


    //1:1 채팅
    const onCallChating = async () => {
        try {
            const params = new URLSearchParams();
            params.append('mem_idx', localStorage.getItem('mem_idx')); //메세지를 받을 상대방의 idx
            params.append('corp_mem_idx', varOfferData.crs_mem_idx); //기업 idx
            params.append('send_idx', localStorage.getItem('mem_idx')); //메시지 보내는 회원의 idx
            params.append('re_idx', varOfferData.crs_re_idx);
            params.append('com_idx', varOfferData.crs_mcm_idx); //기업회원의 회사명

            //채팅방 개설 api
            await axios.post(`${CONFIG.SERVER_HOST}/set_chatroom`, params)
                .then((res) => {
                    // console.log(res);
                    if (res.data.retvalue === 1) {
                        navigator(`/MyHeding/MyHeding4/${res.data.room_no}`);
                    } else {
                        alert(res.data.message);
                    }
                });

        } catch (error) {
            console.log(error);
        }
    } 

    return (varSelectOffer <= 0 ? null :
        <div className={varPopup ? "popup active" : "popup"}>
            <div className="popup_wrap popup01">
                <section className="title_wrap">
                    <h1>이직 제안</h1>
                </section>
                <section className="content_wrap">
                    <ul className="view_area">
                        <li className="profile_area">
                            <div className="profile_img"><img src={varOfferData?.mcm_image === 1 ? imageFolder + varOfferData?.mcm_ufile : defaultCompanyLogo} alt="" /></div>
                            <article className="profile_info">
                                <p className="id">{varOfferData?.mem_id}</p>
                                <p className="company_name">{varOfferData?.mcm_name}</p>
                            </article>
                        </li>
                        <li>
                            <p className="title_field">제안내용</p>
                            <p className="info_field">{varOfferData?.crs_comment}</p>
                        </li>
                        <li>
                            <p className="title_field">업무내용</p>
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                <article style={{ marginTop: '15px', width: '100%' }}>
                                    <p className="info_field">{` [채용포지션] :  ${unescapeHtml(`${varOfferData?.mcm_name}${varOfferData?.em_title === null ? "" : "_" + varOfferData?.em_title}`)}`}</p>
                                    <p className="info_field" style={{marginTop:'10px'}}>{`공고내 주요업무`}</p>
                                    <p className="info_field" style={{marginLeft:'10px', height: '100px', overflow:'auto', scrollbarWidth: 'none'}}>{`${unescapeHtml(varOfferData?.em_main_task)}`}</p>
                                    <p className="info_field" style={{marginTop:'10px'}}>{`우대사항`}</p>
                                    <p className="info_field" style={{marginLeft:'10px'}}>{`${unescapeHtml(varOfferData?.em_preference)}`}</p>
                                </article>
                            </div>
                        </li>
                        <li>
                            <p className="title_field">담당자 정보</p>
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                <article style={{ marginTop: '15%', width: '100%' }}>
                                    <p className="mem_name">{`담당자 :  ${unescapeHtml(varOfferData?.mem_name)}`}</p>
                                    <p className="mem_mobile">{`연락처 :  ${unescapeHtml(varOfferData?.mem_mobile)}`}</p>
                                    <p className="mem_email">{`이메일 :  ${unescapeHtml(varOfferData?.mem_email)}`}</p>
                                    <div class="Btns">
                                        <button type="button" onClick={() => onCallChating()} style={{ marginTop: '10px', backgroundColor: '#0089ff', color: 'white', width: '35%', height: '25px' }}>{`문의하기`}</button>
                                    </div>

                                </article>
                            </div>

                        </li>

                    </ul>
                </section>
                <section className="button_wrap">
                    <p className="point_text">※ 제안 수락하면 기업 담당자에게 회원님의 연락처가 공개됩니다.</p>
                    <div className="big_button_wrap two">
                        <button className="btn white" onClick={(e) => onChangeSuggestStatus(e, 0)}>제안 거절</button>
                        <button className="btn" onClick={(e) => onChangeSuggestStatus(e, 2)}>제안 수락</button>
                    </div>
                    <div className="close_button_wrap">
                        <button onClick={(e) => {
                            setOfferData({});
                            onSetPopup(e, false);
                            
                            
                            }}>닫기 X</button>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PopEditProfile;
