import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//컴포넌트
import { resetNavSubMenu } from "../../components/Common/Common";
import MyApplyTop from 'components/My/MyApplyTop';
import MyApplyList from 'components/My/MyApplyList';

function MyHeding2({ getSubItem }) {
  const location = useLocation();
  const mem_mode = Number(localStorage.getItem('mem_mode'));
  const [varSearchData, setSearchData] = useState({
    status: 100,
    sdate: null,
    edate: null,
    searchoption: "all",
    searchstring: "",
  });

  const applyValue = location.state;

  useEffect(() => {
    getSubItem(2);
    if(mem_mode=== 0){
      resetNavSubMenu(4,1);
    }else{
      resetNavSubMenu(2,1);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_apply">
      <MyApplyTop varSearchData={varSearchData} setSearchData={setSearchData} applyValue={applyValue}/>
      <MyApplyList varSearchData={varSearchData} setSearchData={setSearchData}/> 
    </div>
  );
}

export default MyHeding2;