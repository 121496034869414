/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
//데이트피커
import "react-datepicker/dist/react-datepicker.css";
import { ChangeSearchingDateFormat } from '../Common/Common';

// import search from "img/sub/search.png";
import CustomDatePicker from "components/Common/CustomDatePicker";


function MyRecommendSearch({ varSearchData, setSearchData, readRecommendList }) {
  //문자형 날짜를 date형으로 변경
  const [varStartDate, setStartDate] = useState(varSearchData.sdate === null ? null : new Date(varSearchData.sdate));
  const [varEndDate, setEndDate] = useState(varSearchData.edate === null ? null : new Date(varSearchData.edate));

  //검색조건 변경
  const onSearchOptionHandler = (e) => {
    setSearchData({ ...varSearchData, searchoption: e.currentTarget.value });
  }
  const onSearchStringHandler = (e) => {
    setSearchData({ ...varSearchData, searchstring: e.currentTarget.value });
  }
  const onExecuteSearchingHandler = () => {
    //filteringRecommendData();
    readRecommendList();
  }
  const onChangeDateHandler = (ino, date) => {
    let dbDate = ChangeSearchingDateFormat(date);
    if (ino === 0) {
      setStartDate(date);
      setSearchData({ ...varSearchData, sdate: dbDate });
    } else {
      setEndDate(date);
      setSearchData({ ...varSearchData, edate: dbDate });
    }
  }
  //console.log(varEndDate);

  return (
    <div className="list_top">
      <div className="search_wrap">
        <div className="wrap">
          <p className="title_field">추천일자</p>
          <CustomDatePicker
            data={varSearchData}
            varStartDate={varStartDate}
            varEndDate={varEndDate}
            onChangeDateHandler={onChangeDateHandler}
          />
        </div>
        <fieldset className="list_search_area">
          <select className="style_select" value={varSearchData?.searchoption} onChange={(e) => onSearchOptionHandler(e)}>
            <option value={"all"}>전체</option>
            <option value={"em_title"}>채용제목</option>
            <option value={"mcm_name"}>회사명</option>
            <option value={"vwd_name"}>직무</option>
            <option value={"rc.er_name"}>후보자명</option>
          </select>
          <div className="search">
            <input type="text" value={varSearchData?.searchstring} onChange={(e) => onSearchStringHandler(e)} onKeyDown={(e) => { if (e.key === 'Enter') onExecuteSearchingHandler(e) }} placeholder="검색어를 입력해주세요" />
            {/* <button className="btn_search"><img src={search} alt="search" /></button> */}
          </div>
          <button onClick={() => onExecuteSearchingHandler()} className="btn">조회</button>
        </fieldset>
      </div>
    </div>
  );
}

export default MyRecommendSearch;
