/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs"; 
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import { ChangeMoneyUnit } from '../../../components/Common/Common'

function AccumulateInfo({ history }) {
  const navigator = useNavigate();
  const location = useLocation();
  const adminlogin = localStorage.getItem('adminlogin');
  const mh_idx = location.state;
  const { user_idx } = useParams();
  const [varUserData, setUserData] = useState({});

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //회원정보 가져오기
  useEffect(async () => { 
    if (user_idx > 0) {
        const params = new URLSearchParams();
        params.append('mem_idx', user_idx);
        params.append('mh_idx', mh_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/admin_accumulateinfo_detail`, params).then((res) => {
          if(res.data.retvalue === 1) {
            setUserData(res.data.results[0]); 
          }
        });
    }
  }, [user_idx]);

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="users"/>
            <Menu menuType="users" menu="accumulateList"/>
            <div id="contents" className="ad_contents">
              <div className="tit">적립내역 상세
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>적립내역<img src={arrImage} alt=""/>적립내역 상세
                </div>
              </div>
                <div className="content">
                    <div className="tit">회원정보</div>
                    {/* 내부 메뉴 */}
                    <div className="main-box">
                      <table className="write"> 
                        <colgroup>
                        <col style={{width:"15%"}}/>
                        <col style={{width:""}}/>
                        {/* <col style={{width:"15%"}}/> */}
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>아이디</th>
                            <td style={{ textAlign: "left" }}>{varUserData?.mem_id}</td> 
                          </tr>
                          <tr>
                            <th>이름</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData?.mem_name}
                            </td>
                          </tr>  
                          {/* <tr>
                            <th>닉네임</th>
                            <td  style={{ textAlign: "left" }}>{varUserData.mem_nickname}
                            </td>
                          </tr> */}
                          <tr>
                            <th>휴대폰</th>
                            <td style={{ textAlign: "left" }}>{varUserData?.mem_mobile}</td>
                          </tr> 
                          {varUserData?.mem_mode === 0 ? "" : <tr>
                            <th>회사명</th>
                            <td style={{ textAlign: "left" }}>{varUserData?.mcm_name}
                            </td>
                          </tr>}
                          <tr>
                            <th>회원유형</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData?.mem_mode === 0
                                ? "일반회원"
                                : varUserData?.mem_mode === 1
                                ? "기업"
                                : varUserData?.mem_mode === 2 
                                ? "헤드헌터" : "-"
                                }
                            </td>
                          </tr>
                          <tr>
                            <th >구분</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData?.vhs_name}
                            </td>
                          </tr>
                          <tr>
                            <th>적립금액</th>
                            <td style={{ textAlign: "left" }}>
                            {ChangeMoneyUnit(varUserData?.mh_paid / 10000)}
                            </td>
                          </tr>
                          {/* 헤딩머니 기능 완료 후 다시 수정 */}
                          {/* <tr>
                            <th>후보자</th>
                            <td style={{ textAlign: "left" }}>
                            </td>
                            <th>닉네임</th>
                            <td style={{ textAlign: "left" }}></td>
                          </tr> */}
                          {/* <tr>
                            <th>입사일</th>
                            <td style={{ textAlign: "left" }}> 
                            </td>
                          </tr> */}
                          <tr>
                            <th>적립 일자</th>
                            <td style={{ textAlign: "left" }}>{varUserData?.mh_rdate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                    <div className="btn-center">
                      <button className="btn-danger"><Link to={"/admin/accumulatelist"} style={{ color: "#fff" }}>목록</Link></button>
                  </div>                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccumulateInfo;