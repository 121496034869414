//css

//components
import Header from "../../components/Layout/HeaderCompany";
import SubVisual from "../../components/Layout/SubVisual";
import Footer from "../../components/Layout/Footer";
import Message from "../../components/My/Message";
import BannerBottom from "components/Banner/BannerBottom";
//이미지

function PageMessage() {
  return (
    <div>
      <Header />
      <SubVisual PageTitle="메시지" className="company4" />
      <div id="SubWrap">
        <div className="CenterWrap">
          <Message />
        </div>
      </div>
      <BannerBottom />
      <Footer />
    </div>
  );
}

export default PageMessage;
