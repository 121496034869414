/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CONFIG from 'configs/configs'
import QRCode from 'qrcode'
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { changeMobileFormat, ChangeDateString, numberToKorean2, ChangeMoneyUnitNoText, ChangeDateMonth, onDownloadFile, onDownloadFilePDF, isMobile, onDownloadUrl, split_1, split_2, split_2_2, getMonthDiff,getMonthDiff2, monthDiff2, checkElapsedDay, save_downloads_count } from '../Common/Common'
import qrImage from "../../img/sub/qr.jpg";
import profileImage from '../../img/sub/profile.gif';

import MainLogoIcon from "img/common/logo_pdf.png";
import moment from 'moment';
import Loading from 'components/Common/Loading';

//2022-11-23 PDF 변환 외부 API - KSR
import ConvertApi from 'convertapi-js';

const resumeInitializ = {
	languageStudy: [],
	resume: [],
	resumeAdditionalCareer: [],
	resumeAwards: [],
	resumeCertificate: [],
	resumeCertificationCareer: [],
	resumeEducation: [],
	resumePerfection: [],
	resumePortfolio: [],
	employment_preference: []
};

const toDay = moment(new Date()).format('YYYY.MM_DD');
const toDay2 = moment(new Date()).format('YYYY.MM.DD');
const toDay3 = moment(new Date()).format('YYYY-MM-DD');
const file_path = `/pdf/${moment(new Date()).format('YYYYMM')}`;


const ResumeIssue = ({ curResumeIndex, varQRURLAdress, type }) => {

	const navigate = useNavigate();
	const [resumeData, setResumeData] = useState(resumeInitializ);
	const lastYear = new Date().getFullYear() - 1;
	// const imgPortfolioFolder = `${CONFIG.SERVER_HOST}/port_folio/`; //서버의 포트폴리오 저장 폴더
	const [varQRCode, setQRCode] = useState(qrImage);

	const [varLanguageLevel, setLanguageLevel] = useState([]);
	// const mobile = isMobile();
	const mem_name = localStorage.getItem('mem_name');
	//고용형태 조회
	const [variableEmployType, setVariableEmployType] = useState([]);
	const [varWon, setWon] = useState();
	const [Load, setLoad] = useState(false);
	const [LoadText, setLoadText] = useState('다운로드 준비중입니다.');
	const [ced, setCED]  = useState({
		certification_experience_year :'',
		certification_experience_date :''
	});

	const [aed, setAED]  = useState({
		additional_career_year :'',
		additional_career_date :''
	});

	useEffect(() => {
		axios.post(`${CONFIG.SERVER_HOST}/variable_employ_type`).then((res) => {
			setVariableEmployType(res.data);
		});
		axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
			setLanguageLevel(res.data);
		});
	}, []);

	// 이력서 데이터 가지고 오기.
	useEffect(() => {

		// 이력서 정보 설정.
		const funcSetResumeData = async () => {
			const mem_idx = localStorage.getItem("mem_idx");
			if (!mem_idx && type === 'web') {
				alert("로그인 후 이용 가능합니다.");
				navigate('/Login');
				return;
			};
			// if (_.isEmpty(mem_idx) && type === 'web') {
			// 	alert(`처리중 문제가 발생했습니다.`);
			// 	navigate('/MyResume');
			// 	return;
			// }
			if (type === 'qr') {
				if (!isNaN(curResumeIndex)) {
					alert(`처리중 문제가 발생했습니다.`);
					navigate('/MyResume');
					return;
				}
			}

			await axios.get(`${CONFIG.SERVER_HOST}/resume/${!isNaN(curResumeIndex) ? mem_idx : 0}/${curResumeIndex}/detail/${type}`, {})
				.then((response) => {
					const { data } = response;
					console.log(data);
					setResumeData(data);
				}).catch((err) => {
					const { message } = err.response.data;
					if (_.isEmpty(message)) {
						alert(`처리중 문제가 발생했습니다.`);
					} else {
						alert(message);
					}

					navigate('/MyResume');
					return;
				});

		}

		// qrcode 설정.
		const funcQrData = () => {
			if (type === 'web') {
				if (varQRURLAdress) {
					QRCode.toDataURL(varQRURLAdress).then((data) => {
						setQRCode(data);
					});
				}

			}
		}

		if (type === 'web') {
			if (_.isEmpty(curResumeIndex) && typeof curResumeIndex === 'number' && curResumeIndex > 0) {
				funcSetResumeData();
				funcQrData();
			}
		} else {
			if (curResumeIndex) {
				if (type === 'qr' && isNaN(curResumeIndex)) {
					funcSetResumeData();
					funcQrData();
				} else if (type === 'qr' && !isNaN(curResumeIndex)) {
					alert(`처리중 문제가 발생했습니다.`);
					navigate('/MyResume');
					return;
				}
			}
		}

	}, [curResumeIndex, navigate, varQRURLAdress]);

	useEffect(() => {
		// console.log(typeof resumeData.resume.re_income_hope);
		// console.log(resumeData.resume.re_income_hope);
		if (resumeData.resume.re_income_hope > 0) {
			const won = numberToKorean2(resumeData.resume.re_income_hope);
			setWon(won.indexOf('원') === -1 ? `${!won ? 0 : won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : `${won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
		} else {
			setWon('-');
		}

	}, [resumeData]);

	// 사이트 링크 클릭.
	const handleSiteListClick = () => {
		//window.location.href = resumeData.resume.re_site;
		//새탭에서 열기
		window.open(`${resumeData.resume.re_site}`);
	}

	// // 포트 폴리오 파일 클릭
	// const handlePortfolioFileClick = async (index) => {

	// 	if (_.isEmpty(resumeData.resumePortfolio[index].rcp_ufile)) {
	// 		return;
	// 	}

	// 	await axios({
	// 		url: `${imgPortfolioFolder}${resumeData.resumePortfolio[index].rcp_ufile}}`,
	// 		method: 'GET',
	// 		responseType: 'blob', // important
	// 	}).then((response) => {
	// 		const url = window.URL.createObjectURL(new Blob([response.data]));
	// 		const link = document.createElement('a');
	// 		link.href = url;
	// 		link.setAttribute('download', 'file.pdf');
	// 		document.body.appendChild(link);
	// 		link.click();
	// 	});
	// }

	//2022-11-23 PDF 다운로드 외부 API 사용 - KSR
	const handleClickPdfDownload2 = async () => {

		const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
		const params = convertApi.createParams();
		const pdfUri = `${CONFIG.PdfBaseUri}/${resumeData.resume?.re_code}/web`;
		//const pdfUri = `http://183.98.83.30:3000/PdfView/${resumeData.resume?.re_code}/web`;
		params.add('url', pdfUri);
		params.add('ConversionDelay', '3');
		params.add('MarginTop', '15');
		params.add('MarginBottom', '15');
		params.add('MarginRight', '0');
		params.add('MarginLeft', '0');
		params.add('ViewportWidth', '1400');
		params.add('ShowElements', '.myresume_view');

		console.log("pdfUri", pdfUri);

		setLoad(true);
		console.log("PDF생성을 시작합니다.");
		convertApi
			.convert('web', 'pdf', params)
			.then((result) => {
				const url = result.files[0].Url;
				console.log("PDF URL", url);
				if (!window.ReactNativeWebView) {
					onDownloadUrl(url, `이력서_${resumeData.resume.mem_name}_${toDay}.pdf`);
				} else {
					//앱으로 전달
					const file_name = `이력서_${resumeData.resume.mem_name}_${toDay}.pdf`;
					const file_url = url;
					const params = {
						file_name,
						file_url
					};
					const paramsToString = JSON.stringify(params);
					window.ReactNativeWebView.postMessage(paramsToString);
				}
				//다운로드 카운트 저장
				save_downloads_count('resume');
			})
			.catch((err) => {
				alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
				console.log("PDF error", err);
				setLoad(false);
			})
			.finally(() => {
				setLoad(false);
				//document.documentElement.style.cursor = 'default'
			})
	}

//2024-06-01 동료평판(PDF) 다운로드 외부 API 사용 - KSR
const handleClickRepuDnPdf = async () => {
	console.log('handleClickRepuDnPdf');
	const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
	const params = convertApi.createParams();
	const pdfUri = `${CONFIG.PdfReputation1Uri}/${resumeData.resume.mem_idx}`;
	params.add('url', pdfUri);
	params.add('ConversionDelay', '3');
	params.add('MarginTop', '15');
	params.add('MarginBottom', '15');
	params.add('MarginRight', '0');
	params.add('MarginLeft', '0');
	params.add('ViewportWidth', '1400');
	//params.add('ShowElements', '.container');

	console.log("pdfUri", pdfUri);

	setLoad(true);
	console.log("PDF생성을 시작합니다.");
	convertApi
		.convert('web', 'pdf', params)
		.then((result) => {
			const url = result.files[0].Url;
			console.log("PDF URL", url);
			if (!window.ReactNativeWebView) {
				onDownloadUrl(url, `동료평판_${resumeData.resume.mem_name}_${toDay}.pdf`);
			} else {
				//앱으로 전달
				const file_name = `동료평판_${resumeData.resume.mem_name}_${toDay}.pdf`;
				const file_url = url;
				const params = {
					file_name,
					file_url
				};
				const paramsToString = JSON.stringify(params);
				window.ReactNativeWebView.postMessage(paramsToString);
			}
			//다운로드 카운트 저장
			save_downloads_count('resume');
		})
		.catch((err) => {
			alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
			console.log("PDF error", err);
			setLoad(false);
		})
		.finally(() => {
			setLoad(false);
			//document.documentElement.style.cursor = 'default'
		})
}

//2024-06-02 지인평판(PDF) 다운로드 외부 API 사용 - KSR
const handleClickRepuDnPdf2 = async () => {
	console.log('handleClickRepuDnPdf');
	const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
	const params = convertApi.createParams();
	const pdfUri = `${CONFIG.PdfReputation2Uri}/${resumeData.resume.mem_idx}`;
	params.add('url', pdfUri);
	params.add('ConversionDelay', '6');
	params.add('MarginTop', '15');
	params.add('MarginBottom', '15');
	params.add('MarginRight', '0');
	params.add('MarginLeft', '0');
	params.add('ViewportWidth', '1400');
	//params.add('ShowElements', '.container');

	console.log("pdfUri", pdfUri);

	setLoad(true);
	console.log("PDF생성을 시작합니다.");
	convertApi
		.convert('web', 'pdf', params)
		.then((result) => {
			const url = result.files[0].Url;
			console.log("PDF URL", url);
			if (!window.ReactNativeWebView) {
				onDownloadUrl(url, `지인평판_${resumeData.resume.mem_name}_${toDay}.pdf`);
			} else {
				//앱으로 전달
				const file_name = `지인평판_${resumeData.resume.mem_name}_${toDay}.pdf`;
				const file_url = url;
				const params = {
					file_name,
					file_url
				};
				const paramsToString = JSON.stringify(params);
				window.ReactNativeWebView.postMessage(paramsToString);
			}
			//다운로드 카운트 저장
			save_downloads_count('resume');
		})
		.catch((err) => {
			alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
			console.log("PDF error", err);
			setLoad(false);
		})
		.finally(() => {
			setLoad(false);
			//document.documentElement.style.cursor = 'default'
		})
}

	//언어레벨 세팅 함수
	const getLanguageCode = (level) => {
		const LanguageLevel = varLanguageLevel.filter((language) => language.lal_code === Number(level));
		return LanguageLevel[0]?.lal_name;
	}

	const text = (re_employ_hope) => {
		const textList = variableEmployType.filter((el) => el.emt_idx === re_employ_hope);
		// console.log(textList);
		return !textList[0]?.emt_name ? '-' : textList[0]?.emt_name;
	}

	//다운로드 로딩화면 문구 세팅 시작
	let count = 0;
	useEffect(() => {
		if (Load) {
			const tick = setInterval(() => {
				if (Load === true) {
					if (count === CONFIG.DownLoadTextArray.length) {
						count = 0;
					}
					setLoadText(CONFIG.DownLoadTextArray[count]);
					count++;
				}
			}, 2000);

			return () => clearInterval(tick);
		} else {
			count = 0;
		}
		return undefined;
	}, [Load]);
	//다운로드 로딩화면 문구 세팅 끝

	useEffect(()=>{
		console.log('00000');
		if(resumeData.resume){
			console.log('1111111');
			if(resumeData.resumeCertificationCareer.length > 0){
				console.log('2222222');
				//if(!resumeData?.resume.certification_experience_year && !resumeData?.resume.certification_experience_date){
					monthDiff_1(resumeData.resumeCertificationCareer);
				//}
			}
			if(resumeData.resumeAdditionalCareer.length > 0){
				if(!resumeData?.resume.additional_career_year && !resumeData?.resume.additional_career_date){
					console.log('33333333');
					monthDiff_2(resumeData.resumeAdditionalCareer);
				}
			}
			
		}
	}, [resumeData.resume]);
	

	const monthDiff_1 = (arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rec_sdate !== null){
				const data1 = item.rec_sdate.split('-');
				const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));
				const t2 = checkElapsedDay(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));
				console.log('t2 :', t2);
        console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
			setCED({
				certification_experience_year : quotient,
				certification_experience_date : remainder
			});
  };

	const monthDiff_2 = (arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rac_sdate !== null){
				const data1 = item.rac_sdate.split('-');
				const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        console.log('t1 : ', item.rac_sdate, item.rac_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
		setAED({
			additional_career_year : quotient,
			additional_career_date : remainder
		});

  };


	return (
		<>
			{Load === true ? <Loading text={LoadText} /> : null}
			<div id="sub_wrap" className="page_myresume_view">
				<div className="pdf-area center_wrap">
					{/* 2022-11-23 PDF 다운로드 외부 API 사용 - KSR
					<a className="btn upperline" onClick={() => handleClickPdfDownload()}>PDF 다운받기</a>
					*/}
					<button className="btn upperline" onClick={() => handleClickPdfDownload2()}>이력서 다운받기</button>
					<button className="btn upperline" onClick={() => handleClickRepuDnPdf()}>동료평판 다운받기</button>
					<button className="btn upperline" onClick={() => handleClickRepuDnPdf2()}>지인평판 다운받기</button>
				</div>
				<div id="resume_page" className="myresume_view center_wrap">
					<section className="resume_title">
						<h2 className="title_field">{resumeData.resume.re_title}</h2>
						{type === 'web' ?
							<p className="qr"><img className="qr-image" src={varQRCode} alt="" /></p>
							:
							null
						}
					</section>
					<section className="profile_area">
						{resumeData.resume.re_photo === 0 || resumeData.resume.re_ufile === undefined ?
							null
							:
							<article className="profile_img" >
								<img src={(`${CONFIG.SERVER_HOST}/resume/${resumeData.resume.re_ufile}`)} alt="이력서 대표사진" />
							</article>
						}

						{/* ▼▼▼ 작은 이미지 확인용 */}
						{/* <article className="profile_img" style={{backgroundImage:"url(https://picsum.photos/100/100/?image=58)"}}>
						</article> */}
						<div className="right_wrap">
							<p className="name_field">{resumeData.resume.mem_name}</p>
							<article className="info_area">
								<ul>
									<li><span className="title_field">생년월일</span>{ChangeDateString(resumeData.resume.re_birthday)}</li>
									<li><span className="title_field">이메일</span>{resumeData.resume.re_email}</li>
									<li><span className="title_field">근로소득[{resumeData.resume.re_income_lyear === null ? lastYear : resumeData.resume.re_income_lyear}]</span>&nbsp;{
										resumeData.resume.re_income_last === 0 ? "-" : ChangeMoneyUnitNoText(resumeData.resume.re_income_last)}
									</li>
								</ul>
								<ul>
									<li><span className="title_field">성별</span>{resumeData.resume.re_gender === 0 ? "남성" : "여성"}</li>
									<li><span className="title_field">연락처</span>{changeMobileFormat(resumeData.resume.mem_mobile)}</li>
									<li><span className="title_field">현재연봉</span>&nbsp;{resumeData.resume.re_income_this === 0 ? "-" : ChangeMoneyUnitNoText(resumeData.resume.re_income_this)}</li>
								</ul>
							</article>
						</div>
						<p className="tip_field c_red">※ 근로소득은 국세청 원천징수영수증 기준으로 인증된 금액입니다.</p>
					</section>
					{resumeData.resumeEducation.length > 0 ?
						<section className="section school">
							<h3 className="title_area">학력</h3>
							{resumeData.resumeEducation.map((education, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field">{education?.reh_name} <span className="part_field">{education?.reh_major}  {education?.reh_level === 10 ? CONFIG.Master : education?.reh_level === 11 ? CONFIG.Doc : ''}</span></p>
									<p className="date_field">{education?.reh_state === -2 ? "" : ChangeDateMonth(education?.reh_sdate, 1)}{education?.reh_edate === null ? ""
										: " ~ " + ChangeDateMonth(education?.reh_edate, 1)}
										{education?.reh_state === -2 ? "" :
											((education?.reh_name === null || education?.reh_major === null) ? "" : education?.reh_state === 1 ? " (졸업)" : education?.reh_state === 2 ? " (졸업예정)" : education?.reh_state === 3 ? " (재학중)" : education?.reh_state === 4 ? " (중퇴)" : education?.reh_state === 5 ? " (수료중)" : education?.reh_state === 6 ? " (휴학)" : "")
										}
									</p>
								</article>
							))}
						</section>
						: null}
					{resumeData.resumeCertificationCareer.length ?
						<section className="section sec_career">
							<p className="tip_field c_red">
								※ 인증 경력은 국민건강보험공단 자격득실확인서 기준으로 인증된 경력입니다.
							</p>
							{/* {resumeData?.re_career_year ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {split_1(resumeData?.re_career_year)}년{" "}{split_2_2(resumeData?.re_career_year, resumeData.resume.re_decimal_data1) === undefined ? "" : split_2_2(resumeData?.re_career_year, resumeData.resume.re_decimal_data1) + "개월"}
                </p>
								:
								null
							} */}
							{
							ced.certification_experience_year || ced.certification_experience_date ?
							<p className="align_end c_red total_career_text">
								총 경력 : {`${ced.certification_experience_year} 년 `}{`${ced.certification_experience_date}개월`}
							</p>
							:
							resumeData?.resume.certification_experience_year || resumeData?.resume.certification_experience_date ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {`${resumeData?.resume.certification_experience_year} 년 `}{`${resumeData?.resume.certification_experience_date}개월`}
                </p>
								:
								null
							}
							<h3 className="title_area">인증 경력</h3>
							{resumeData.resumeCertificationCareer.length > 0 && resumeData.resumeCertificationCareer.map((career, index) => {
								const recDetail = [];
								let tmpLoop = [1, 2, 3, 4, 5];
								const tempCareer = Object.assign({}, career);
								tmpLoop.forEach(function (value) {
									if (!_.isEmpty(tempCareer[`rec_note${value}`])) {
										recDetail.push({
											note: tempCareer[`rec_note${value}`],
											detail: tempCareer[`rec_detail${value}`],
										});
									}
								});
								return (
									<article className="info_area" key={"career_" + index}>
										<p className="title_field">
											{career?.rec_company}
											<span className="part_field">
												{!career?.rec_department || career?.rec_department === 'null' ? '' : career?.rec_department} {!career?.rec_position || career?.rec_position === 'null' ? '' : career?.rec_position}
											</span>
										</p>
										<p className="date_field">
											<span className="left_wrap">
												{ChangeDateMonth(career?.rec_sdate, 1) + ' ~ ' + ChangeDateMonth(career?.rec_edate, 1)}
												{career?.rec_state === 1 ? " 재직중" : ""}
											</span>
											{career?.rec_sdate ?
												<span className="align_end">
													{getMonthDiff2(career?.rec_sdate, career?.rec_state === 1 ? toDay3 : career?.rec_edate)}
												</span>
												:
												null
											}
										</p>

										{recDetail.map((elements, ii) => {
											return (elements.note === null || elements.note === '' ? null :
												<div key={"careerdes_" + index + "_" + ii} className="career_detail">
													<p className="subtitle_field text-lines">{elements.note}</p>
													{/* <p className="text">{elements.detail===null||elements.detail===''?'':'(업무 상세 설명)'+elements.detail}</p> */}
												</div>
											)
										})}
									</article>
								);
							})}
						</section>
						: null}
					{resumeData.resumeAdditionalCareer.length ?
						<section className="section">
							<h3 className="title_area">추가 경력</h3>
              {/* {resumeData?.re_careeradd_year ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {split_1(resumeData?.re_careeradd_year)}년{" "}{split_2_2(resumeData?.re_careeradd_year, resumeData.resume.re_decimal_data2) === undefined ? "" : split_2_2(resumeData?.re_careeradd_year, resumeData.resume.re_decimal_data2) + "개월"}
                </p>
								:
								null
							} */}
							{
							aed.additional_career_year || aed.additional_career_date ?
								<p className="align_end c_red total_career_text">
									총 경력 : {`${aed.additional_career_year} 년 `}{`${aed.additional_career_date}개월`}
								</p>
							:
							resumeData?.resume.additional_career_year || resumeData?.resume.additional_career_date ?
                <p className="align_end c_red total_career_text">
                  총 경력 : {`${resumeData?.resume.additional_career_year} 년 `}{`${resumeData?.resume.additional_career_date}개월`}
                </p>
								:
								null
							}
							{resumeData.resumeAdditionalCareer.map((career, index) => {
								const recDetail = [];
								let tmpLoop = [1, 2, 3, 4, 5];
								const tempCareer = Object.assign({}, career);
								tmpLoop.forEach(function (value) {
									if (!_.isEmpty(tempCareer[`rac_note${value}`])) {
										recDetail.push({
											note: tempCareer[`rac_note${value}`],
											detail: tempCareer[`rac_detail${value}`],
										});
									}
								});
								return (
									<article className="info_area" key={"careeradd_" + index}>
										<p className="title_field">{career?.rac_company}<span className="part_field">{career?.rac_department + ' ' + career?.rac_position}</span></p>
										<p className="date_field">
											<span className="left_wrap">
												{ChangeDateMonth(career?.rac_sdate, 1) + `${career?.rac_edate ? ` ~ ` : ``}` + ChangeDateMonth(career?.rac_edate, 1)}
												{career?.rec_state === 1 ? " 재직중" : ""}
											</span>
											{career?.rac_sdate ?
												<span className="align_end">
													{getMonthDiff(career?.rac_sdate, career?.rac_state === 1 ? toDay2 : career?.rac_edate)}
												</span>
												:
												null
											}
										</p>
										{recDetail.map((elements, ii) => {
											return (elements.note === null || elements.note === '' ? null :
												<div className="career_detail" key={"careeradddes_" + index + "_" + ii}>
													<p className="subtitle_field text-lines">{elements.note}</p>
													{/* <p className="text">{elements.detail===null||elements.detail===''?'':'(업무 상세 설명)'+elements.detail}</p> */}
												</div>
											)
										})}
									</article>
								)
							})}
						</section>
						: null}
					{resumeData.languageStudy.length ?
						<section className="section">
							<h3 className="title_area">어학</h3>
							{resumeData.languageStudy.map((study, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field">{study.language_name} <span className="part_field">{getLanguageCode(study.language_level)}</span></p>
								</article>
							))}
						</section>
						: null}
					{resumeData.resumeCertificate.length ?
						<section className="section">
							<h3 className="title_area">자격증</h3>
							{resumeData.resumeCertificate.map((element, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field">{element.rc_name} <span className="part_field">{element.rc_issuer}</span></p>
									<p className="date_field">{element?.rc_gdate === null ? "" : ChangeDateMonth(element?.rc_gdate, 1)}
									</p>
								</article>
							))}
						</section>
						: null}
					{resumeData.resumeAwards.length ?
						<section className="section">
							<h3 className="title_area">수상이력</h3>
							{resumeData.resumeAwards.map((element, index) => (
								<article className="info_area" key={'edu_' + index}>
									{console.log("11 ", element.ra_comment)}
									<p className="title_field">{element.ra_comment} <span className="part_field">{element.ra_issuer === "NULL" || element.ra_issuer === null ? "" : element.ra_issuer}</span></p>
									<p className="date_field">{element?.ra_gdate === null ? "" : ChangeDateMonth(element?.ra_gdate, 1)}
									</p>
								</article>
							))}
						</section>
						: null}


					{resumeData.resume.re_career_description ?
						<section className="section">
							<h3 className="title_area">경력기술서</h3>
							<article className="info_area text-lines ql-editor">
								<div dangerouslySetInnerHTML={{ __html: resumeData.resume?.re_career_description }}></div>
							</article>
						</section>
						: null}

					{resumeData.resume.re_comment ?
						<section className="section">
							<h3 className="title_area">자기소개서</h3>
							<article className="info_area text-lines ql-editor">
								<div dangerouslySetInnerHTML={{ __html: resumeData.resume?.re_comment }}></div>
							</article>
						</section>
						: null}
					{resumeData.resumePortfolio.length || resumeData.resume.re_site ?
						<section className="section portfolio">
							<h3 className="title_area">포트폴리오</h3>
							<article className="info_area">
								{resumeData.resumePortfolio.map((portfolio, index) => (
								<p key={"port_" + index} onClick={() => onDownloadFile('port_folio', portfolio.rcp_ufile)}>첨부파일 : {portfolio.rcp_ufile}</p>
								))}
								<p>사이트 링크<a onClick={() => handleSiteListClick()} style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer' }}>{resumeData.resume.re_site}</a></p>
							</article>
						</section>
						: null}
					{/* <section className="section work_condition">
						<h3 className="title_area">근무희망조건</h3>
						<article className="info_area">
							<p><span className="title_field2">고용형태</span> : &nbsp;{text(resumeData.resume.re_employ_hope)}</p>
							<p><span className="title_field2">희망연봉</span> : &nbsp;{resumeData.resume.re_after_meet === 1 ? "면접후 결정" : varWon}</p>
							<p><span className="title_field2">희망 근무지</span> : &nbsp;{resumeData.resume?.re_place_hope ? resumeData.resume?.re_place_hope?.replaceAll(/,/g, ', ') : "-"}</p>
							<p><span className="title_field2">희망 직종</span> : &nbsp;{resumeData.resume?.re_task_hope ? resumeData.resume?.re_task_hope?.replaceAll(/,/g, ', ') : "-"} </p>
							<p><span className="title_field2">관심 기업</span> : &nbsp;{resumeData.resume?.re_company_hope ? resumeData.resume?.re_company_hope?.replaceAll(/,/g, ', ') : "-"}</p>
						</article>
					</section> */}
					{resumeData.employment_preference?.length ?
						<section className="section">
							<h3 className="title_area">취업우대사항</h3>
							{resumeData.employment_preference.map((element, index) => (
								<article className="info_area" key={'edu_' + index}>
									<p className="title_field"> {element.vcep_content} <span className="part_field">{element.content}</span></p>
									{/* <p className="date_field">{element?.ra_gdate === null ? "" : ChangeDateMonth(element?.ra_gdate, 1)}
								</p> */}
								</article>
							))}
						</section>
						: null}
				</div>
			</div>
		</>
	)
}

export default ResumeIssue