import React, { useEffect } from 'react'

import axios from 'axios'
import CONFIG from '../../configs/configs'
//components
import { remainDays } from '../Common/Common'

/*메인(기업)>사용중인 이용권*/
function UseVoucher({ varCompanyVoucher,setCompanyVoucher }) {
  const mcm_idx = Number(localStorage.getItem('mcm_idx'));
  //console.log(varCompanyVoucher);
  //회사정보 읽기
  const getCompanyVoucher = async () => {
    try {
      //console.log(mcm_idx);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      //2022-10-30 API변경 기존(합산관리) -> 주문서 단위 관리 - KSR
      //await axios.post(`${CONFIG.SERVER_HOST}/_get_company_voucher`,params).then((res) => {
      await axios.post(`${CONFIG.SERVER_HOST}/voucherRemainList2`,params).then((res) => {
        //console.log(res.data.results);
        if(res.data.retvalue === 1) {
          const datas = res.data.results;
          //console.log(datas);
          setCompanyVoucher(datas);
        } else {
          setCompanyVoucher({ mcc_sending:0, mcc_edate:0 });
        }
      });
    } catch (error) {
        console.log(error);
    }
  }
  useEffect(() => {
    getCompanyVoucher();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return (
    <li className="remain_wrap">
      <div><i className="gray_icon remain01"></i>잔여 제안<b>{varCompanyVoucher.mcc_sending+" 건"}</b></div>
      <div><i className="gray_icon remain02"></i>잔여 이용기간<b>{remainDays(varCompanyVoucher.mcc_edate)+" 일"}</b></div>
    </li>
  );
}

export default UseVoucher;
