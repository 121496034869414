/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react"; // basic
import SwiperCore, { Navigation } from "swiper";
import axios from 'axios'
import CONFIG from 'configs/configs'

//이미지
//import nextButtonImage from "img/sub/btn_next2.png"
// import visual01 from "img/main/visual01.jpg"
// import visual01m from "img/main/visual01_m.jpg"

//css
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'


/*메인>상단배너*/
function MainVisual() {
    const [Loding, setLoading] = useState(false);
    const slideFolder = `${CONFIG.SERVER_HOST}/images/`;
    const [varSlides,setSlides] = useState([]);

    const [swiper, setSwiper] = useState(null);// eslint-disable-line no-unused-vars
    const [mainImageIndex, setMainImageIndex] = useState(0);
    SwiperCore.use([Navigation]);

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const swiperParams = {
        navigation: {prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current},
        onBeforeInit: (swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.activeIndex = mainImageIndex;
            swiper.navigation.update();
        },
        loop: true,
        autoplay:{delay: 3000, disableOnInteraction: false},
        onSwiper:setSwiper,
        onSlideChange: (e) => setMainImageIndex(e.activeIndex)
    }

    const getMainSlider = async () => {
      try {
        setLoading(true);
        await axios.post(`${CONFIG.SERVER_HOST}/_get_main_slider`).then((res) => {
          console.log(res.data);
          if (res.data.retvalue === 1) {
            const datas = res.data.results;
            const sliders = datas.filter(element => element.slide_ufile !== "");
            //채용공고전체
            setSlides(sliders);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      getMainSlider();
      return () => setLoading(false); // cleanup function을 이용
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return Loding ? null : (
    <section className="main_visual center_wrap">
        <Swiper {...swiperParams} ref={setSwiper}>
            <div className="swiper main_visual_slider">  {/* swipper-container */}
                <ul className="swiper-wrapper">
                  {varSlides.map((slide,ii) => (
                    <SwiperSlide className="swiper-slide" key={'slide_'+ii}>
                        {!slide.slide_link.includes("http") || !slide.slide_link.includes("https") ?  
                        <Link className="button" to={slide.slide_link} state={{ em_headhunter: 0, em_type: 1, sString:"" }}>
                          <img className="view_pc" src={/*visual01*/slideFolder+slide.slide_ufile} alt="visual01"/>
                          <img className="view_m" src={/*visual01m*/slideFolder+slide.slide_mfile} alt="visual01m"/>
                        </Link> 
                        :  
                        <a href={slide.slide_link === "" ? "/main" : slide.slide_link}>
                          <img className="view_pc" src={/*visual01*/slideFolder+slide.slide_ufile} alt="visual01"/>
                          <img className="view_m" src={/*visual01m*/slideFolder+slide.slide_mfile} alt="visual01m"/>
                        </a>}      
                    </SwiperSlide>
                  ))}
                </ul>
            </div>
            <div className="swiper-button-prev" ref={navigationPrevRef}></div>
            <div className="swiper-button-next" ref={navigationNextRef}></div>
        </Swiper>
    </section>
  );
}

export default MainVisual;
