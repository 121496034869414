/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import $ from 'jquery';
import { chkOS } from "../Common/Common";

function ResumeAside({ curResumeIndex, varPerfection, onSaveResume, InsertIndex, onUpdateResume, loadPage, loadPage2 }) {

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); //clean up
    };
  }, []);

  const handleScroll = () => {
    $(window).scroll(function () {
      let scrollTop = $(this).scrollTop();
      let divTop = !document.querySelector(".aside") ? 0:document.querySelector(".aside").offsetTop;
      let fullTop = scrollTop-divTop;

      if (scrollTop < divTop) {
        scrollTop = divTop;
      }
      else if (0 > fullTop) {
        scrollTop = fullTop;
      }

      // let duration = 500;
      $('.fly').stop().animate({ top: scrollTop}, 1000); 
    });
  };
  //이력서 저장 버튼
  const onSaveResumeHandler = (e) => {
    e.preventDefault();
    if (curResumeIndex === 0) {
      if (InsertIndex === 0) {
        onSaveResume(1)
      } else {
        onUpdateResume(1)
      }
    } else {
      onUpdateResume(1)
    }
  }
  
  // const oo = chkOS();
  // console.log('oo :', oo);
  return (
    <aside className="aside">
      <div className={chkOS() === true ? 'fly' : ''}>
        <section className="state_area">
          <div className="title_field">이력서 완성도</div>
          <div className={varPerfection.classes}><span className="per">{varPerfection.completed}%</span></div>
        </section>
        <div className="Top">
          <ul className="stat_chek">
            <li>인적사항<input type="checkbox" checked={varPerfection.personal ? true : false} readOnly /></li>
            <li>학력<input type="checkbox" checked={varPerfection.education ? true : false} readOnly /></li>
            <li>경력<input type="checkbox" checked={varPerfection.career ? true : false} readOnly /></li>
            <li>자기소개서<input type="checkbox" checked={varPerfection.selfdoc ? true : false} readOnly /></li>
          </ul>
        </div>

        {/* <Link className="btn" to={`/MyResume/ResumeView/${curResumeIndex}`} onClick={
        () => {
          if (curResumeIndex === 0) {
            if (InsertIndex === 0) {
              onSaveResume(0)
            } else {
              onUpdateResume(0)
            }
          } else {
            onUpdateResume(0)
          }
        }
      }>임시저장</Link> */}
        {/* <Link className="btn orange" to={`/MyResume/ResumeView/${curResumeIndex}`} onClick={
        () => {
            if (curResumeIndex === 0) {
              if (InsertIndex === 0) {
                onSaveResume(1)
              } else {
                onUpdateResume(1)
              }
            } else {
            onUpdateResume(1)
            }
        }
      }>이력서 저장</Link> */}
      {loadPage||loadPage2 ?
        <button className="btn gray">이력서 저장</button>
        :
        <button className="btn orange" onClick={(e) => onSaveResumeHandler(e)}>이력서 저장</button>
      }
      </div>
    </aside>
  );
}

export default ResumeAside;
