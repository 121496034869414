/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import CONFIG from '../../configs/configs'
import axios from 'axios'

import Paging from '../../components/Layout/Paging'
import { resetNavSubMenu, mobileWrapSearchTalent } from '../Common/Common'
import CertificationCareerView from "./CertificationCareer";
import AddCareerView from "./AddCareer";

const LatestC = ({ setTabPage, onViewResumePage, onCallChating }) => {
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx');
  const com_idx = localStorage.getItem("com_idx");
  const mem_sub = localStorage.getItem('mem_sub');
  //loading 유무
  const [varLoadingFinshed, setLoadingFinshed] = useState(1);
  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;
  const [varResumeLookupLists, setResumeLookupLists] = useState([]);
  // const [varFilterResumeLookupLists, setFilterResumeLookupLists] = useState([]);
  const [varCompanyMembers, setCompanyMembers] = useState([]);

  //제안보낸 직원번호 - 필터링
  const [varCompanyMemberIndex, setCompanyMemberIndex] = useState(0);

  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //최초 모든 이력서 가져오기
  const getResumeLookupLists = async () => {
    try {
      console.log('lastest');
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      params.append("com_idx", com_idx);
      params.append("varCompanyMemberIndex", varCompanyMemberIndex)
      params.append("varPageSize", varPageSize);
      params.append("varPageNum", varPageNum);
      /* params.append("mode", 0); */
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_recent_employ`, params).then((res) => {
        console.log(res);
        if (res.data.retvalue === 1) {
          console.log(res.data.count[0].tcount);
          //관심인재목록
          // setResumeLookupLists(res.data.lookups); //원본       
          const list = res.data.lookups.map((e) => {
            const rec_company_name = e.rec_company ? e.rec_company : e.rac_company ? e.rac_company : '';
            const rec_department_name = e.rec_department ? e.rec_department : e.rac_department ? e.rac_department : '';
            const rec_position_name = e.rec_position ? e.rec_position : e.rac_position ? e.rac_position : '';
            return {
              ...e,
              rec_company_name: rec_company_name.split(',')[0],
              rec_department_name: rec_department_name.split(',')[0],
              rec_position_name: rec_position_name.split(',')[0]

            };
          });    
          
          console.log(list);
          setResumeLookupLists(list); //원본
          setTotalCount(res.data.count[0].tcount);
          //관심인재목록 - 기업직원에 따른 필터링
          // setFilterResumeLookupLists(res.data.lookups);
          //기업내 직원들
          setCompanyMembers(res.data.members);
        } else {
          setResumeLookupLists([]);
          setTotalCount(0);
          // setFilterResumeLookupLists([]);
          setCompanyMembers([]);
        }
        setLoadingFinshed(0);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    setTabPage(3);
    resetNavSubMenu(2, 3);
    getResumeLookupLists();
    // setPageNum(1)
    mobileWrapSearchTalent();
    document.getElementById('sub_wrap').className = 'page_interested_people recently';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varCompanyMemberIndex]);

  //직원선택시 -- 우측부 이력서 재표시
  const onChangeCompanyMember = (e) => {
    const com_idx = e.currentTarget.value;
    setCompanyMemberIndex(com_idx);
    setPageNum(1)
  }

  const getDataPerPage = () => {
    //   //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varResumeLookupLists.length === 0) {
      return;
    }
  };
  // //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varCompanyMemberIndex]);

  return (
    varLoadingFinshed === 1 ? null :
      <>
        <div className="list_top">
          <form className="search_wrap">
            {mem_idx === mem_sub ?
              <div className="wrap">
                <select className="style_select" onChange={(e) => onChangeCompanyMember(e)} defaultValue={0}>
                  <option value={0}>전체</option>
                  {varCompanyMembers.map((members, i) => (
                    <option key={'mem_' + i} value={members.cmm_mem_idx}>{members.mem_id}</option>
                  ))}
                </select>
              </div>
              : null
            }
          </form>
        </div>
        <section className="interestedPeople_list center_wrap">
          <ul className="table table_list01">
            <li className="tr">
              <article className="th row_name">후보자명</article>
              <article className="th row_total">총 경력</article>
              <article className="th row_career">직장</article>
              <article className="th row_edu">학력</article>
              <article className="th row_state">관리</article>
            </li>
            {varResumeLookupLists.map((resumes, index) => (
              <li className="tr" key={'irl_' + index}>
                <article className="td row_name">
                  <div className="pic_name_wrap">
                    {resumes.mem_photo === 0 ? <figure></figure>
                      : <figure style={{ backgroundImage: `url(${imageFoler + resumes.mem_ufile})` }}></figure>
                    }
                    <b>{resumes.mem_name}</b>
                  </div>
                </article>
                <div className="m_colgroup group1">
                  <article className="td row_total m_group txt_blue">
                    {
                      !resumes?.certification_experience_year && !resumes?.certification_experience_year ?
                        <AddCareerView highLookup={resumes} />
                        :
                        <CertificationCareerView highLookup={resumes} />
                    }
                    {/* {resetCareerForSearching(resumes.re_career_year)} */}

                  </article>
                  <article className="td row_career m_group">
                    {resumes.rec_company_name ?
                      <><b>{resumes.rec_company_name}</b>&nbsp;
                      </>
                      : "-"
                    }
                    {resumes.rec_department_name ? `/ ${resumes.rec_department_name} ${resumes.rec_position_name ? resumes.rec_position_name:``}` : ""}
                  </article>
                  <article className="td row_edu m_group">
                    {resumes.reh_name === null ? "학력 미입력" : (
                      <>
                        {resumes.reh_name}&nbsp;{resumes.reh_major}&nbsp;
                        {resumes.reh_state === 1 ? '졸업' : resumes.reh_state === 2 ? "졸업예정" : resumes.reh_state === 3 ? "재학중" : resumes.reh_state === 4 ? "중퇴" : resumes.reh_state === 5 ? "수료" : resumes.reh_state === 6 ? "휴학" : ""}
                      </>
                    )}
                  </article>
                  <article className="td row_state m_group">
                    <button className="btn line gray" onClick={(e) => onViewResumePage(e, resumes.re_idx, 3)}>이력서 보기<span className="nanumGothic">&gt;</span></button>
                    <button className="btn btn_chat" onClick={() => onCallChating(resumes.mem_idx, resumes.re_idx)}>1:1 채팅 <span className="nanumGothic">&gt;</span></button>
                  </article>
                </div>
              </li>
            ))}
          </ul>
        </section>
        <div className="paging">
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </div>
      </>
  )
}

export default LatestC
