import React, { useState,useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import axios from 'axios'
import CONFIG from '../../configs/configs'
//css

//components
import { resetNavMenu } from "../../components/Common/Common";
import HeaderCompany from '../../components/Layout/HeaderCompany';
/* import SubVisual from '../../components/Layout/SubVisual'; */
import Footer from '../../components/Layout/Footer';
import VoucherBuy from "../Voucher/VoucherBuy";
import VoucherPay from "../Voucher/VoucherPay";
import BannerBottom from "components/Banner/BannerBottom";
//이미지

/*이용권 내역*/
function ComVoucher() {
  const mcm_idx = localStorage.getItem('mcm_idx');

  //이용권 보유현황
  const [varCompanyVoucher,setCompanyVoucher] = useState({
    mem_id:'',
    mcc_sending:0,
    mcc_edate:0
  });
  //이용권 종류
  const [varVoucherList,setVoucherList] = useState([]);
  //약관 저장 객체
  const [varConditionData, setConditionData] = useState({});

  //회사이용권정보 읽기
  const getCompanyVoucher = async () => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      console.log('_get_company_voucher2 !!!!!');
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_voucher2`,params).then((res) => {
          if(res.data.retvalue === 1) {
            setVoucherList(res.data.vouchers);
            setConditionData(res.data.conditions);
            console.log(mcm_idx,res.data);
            if(res.data.havings.length === 0) {  //이용권 보유현황 내역이 없으면 추가
              axios.post(`${CONFIG.SERVER_HOST}/_new_company_voucher`,params).then((res) => {
                setCompanyVoucher(res.data.havings[0]);
              });
            } else {
              setCompanyVoucher(res.data.havings[0]);
            }
          } else {
            setCompanyVoucher({
              mem_id:'',
              mcc_sending:0,
              mcc_edate:0
            });
            setVoucherList([]);
          }
      });
    } catch (error) {
        console.log(error);
    }
  }

  //화면의 크기가 변경되는 경우 실행
  const mobileWrap = () => {
    if (window.innerWidth < 890) {
    } else {
      
    }
  }

  useEffect(() => {
    resetNavMenu(4);
    window.addEventListener('resize',mobileWrap());
    getCompanyVoucher();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
      <div>
        <HeaderCompany />
       {/*  <SubVisual PageTitle="이용권 구매" SubVisual_className={"company5"} /> */}
        <div id="SubWrap">
            <div className="CenterWrap">
            <Routes>
                <Route path="/" element={<VoucherBuy varCompanyVoucher={varCompanyVoucher} varVoucherList={varVoucherList}/>} /> 
                <Route path="/VoucherBuy" element={<VoucherBuy varCompanyVoucher={varCompanyVoucher} varVoucherList={varVoucherList}/>} /> 
                <Route path="/VoucherPay" element={<VoucherPay varCompanyVoucher={varCompanyVoucher} 
                            varConditionData={varConditionData} setConditionData={setConditionData}
                            varVoucherList={varVoucherList} setVoucherList={setVoucherList} />} /> 
              </Routes>
            </div>
        </div>
        <BannerBottom />
        <Footer/>
      </div>
  );
}

export default ComVoucher;
