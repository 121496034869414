/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import { changeMobileFormat } from '../../../components/Common/Common';
import Paging from '../../../components/Layout/Paging';

function Headhunter_Sub_Account({ setCategory }) {
    const { mem_idx } = useParams();
    
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);
    
    const [varMemberlist, setMemberlist] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    useEffect(async () => {
        setCategory(2);
        const params = new URLSearchParams();
        params.append('mem_idx', mem_idx);
        axios.post(`${CONFIG.SERVER_HOST}/head_sub_account`, params).then((res) => {
          console.log(res.data)
            if(res.data.retvalue === 1) {
                const datas = res.data.results;
                setTotalCount(datas.length);
                setMemberlist(datas);
            }
        });
    }, []);

    //페이지별 표시 - 결제내역
    const getPageLists = () => {
        const startNum = (varPageNum-1)*varPageSize;
        const endNum = startNum + varPageSize;
        const copyData = varMemberlist.slice(startNum,endNum);
        setPagelist(copyData);
    }

    useEffect(() => {
        getPageLists();
    },[varPageNum,varMemberlist]);

    return (
        <React.Fragment>
            <p className="table_title" style={{marginTop:"20px"}}>- 서브계정 정보</p>
            <div className="main-box ">
                <input type="hidden" name="typ" value="" />
                <input type="hidden" name="delchk" value="0" />
                <table className="list">
                    <colgroup>
                        <col style={{width:"8%"}} />
                        <col style={{width:"12%"}} />
                        <col style={{width:"12%"}} />
                        <col style={{width:"12%"}} />
                        <col style={{width:"16%"}} />
                        <col style={{width:"16%"}} />
                        <col style={{width:"16%"}} />
                        <col style={{width:"8%"}} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>아이디</th>
                            <th>이름</th>
                            <th>닉네임</th>
                            <th>휴대폰</th>
                            <th>최근 접속일</th>
                            <th>계정 추가일</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                        {varPagelist.map((user, i) => (
                            <tr key={i}>
                                <td><p>{varTotalCount-(varPageNum-1)*varPageSize-i}</p></td>
                                <td className="ellipsis"><p>{user.mem_id}</p></td>
                                <td className="ellipsis"><p>{user.mem_name}</p></td>
                                <td className="ellipsis"><p>{user.mcm_name}</p></td>
                                <td className="ellipsis"><p>{changeMobileFormat(user.mem_mobile)}</p></td>
                                <td className="ellipsis"><p>{user.mem_ldate===null?"":user.mem_ldate.substr(0,10)}</p></td>
                                <td className="ellipsis"><p>{user.mem_rdate===null?"":user.mem_rdate.substr(0,10)}</p></td>
                                <td>
                                    <Link className="btn line black" to={'../headhunter_sub_dtail'} state={{submem_idx:user.mem_idx}}>상세</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                </div>
            </div>
        </React.Fragment>
    )

}

export default Headhunter_Sub_Account;