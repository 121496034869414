/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import paginate from '../paging/Paginate';
import Pagination from '../paging/Pagination';

function Company() {
/*     const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜
    const [search, setSearch] = useState("none");
    const [searchInput, setSearchInput] = useState("") */

    const [companylist, setCompanylist] = useState([]);
    const [inputs, setInputs] = useState({
        company: ''
    });
    
    useEffect(() => {
      axios.post(`${CONFIG.SERVER_HOST}/company`).then((res) => {
        console.log(res);
        const data = res.data.map((data, i) => (
          {
              order: i + 1,
              mcm_idx: data.mcm_idx,
              mcm_name: data.mcm_name,
          }
        ))
        setCompanylist(companylist.concat(data));
      });
    }, []);

    useEffect(() => {
        console.log('effect');
        return () => {
          console.log('cleanup');
        };
    }, [inputs]);

    const Companylist = companylist.filter((company) => {
      return companylist;
    });

    //페이징
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(5);

    const onPgaeChange = (num) => {
        console.log("num", num);
        setCurrentPage(num);
    }
    const handleMovePage = (s, e) => {
        setStart(s)
        setEnd(e)
    }
    const pagedList = paginate(Companylist, currentPage, pageSize);

    const addCompany = () => {
      setCompanylist(companylist => [...companylist, '']);
    }

    const delete_company = (idx) => {
      const confirm= window.confirm(`삭제하시겠습니까?`) //리액트에서는 window. 붙여야함
      if(confirm === true){
        const param = new URLSearchParams();
        param.append("mcm_idx", idx);

        axios.post(`${CONFIG.SERVER_HOST}/delete_company`, param).then((res) => {
          window.location.replace("/admin/search")
        });
      }
    }

    const save_company =(i)=>{
      const confirm= window.confirm(`저장하시겠습니까?`) //리액트에서는 window. 붙여야함
      if(confirm === true){
        const param = new URLSearchParams();
        param.append("com_idx", 1);
        param.append("mcm_name", inputs.company);

        axios.post(`${CONFIG.SERVER_HOST}/add_company`, param).then((res) => {
            window.location.replace("/admin/search")
        });
      }
    }

    const onChange = (e) => {
        const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
        setInputs({
          ...inputs, // 기존의 input 객체를 복사한 뒤
          [name]: value // name 키를 가진 값을 value 로 설정
        });
    };

    return (
      <React.Fragment>
          <Titlebar />
          <Tabbar tabType="search" />
          <Menu menuType="search" menu="search" />
          <div id="contents" className="ad_contents">
            <div className="tit">검색 관리
              <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>검색 관리<img src={arrImage} alt=""/>검색 관리<img src={arrImage} alt=""/>회사명 관리
              </div>
            </div>
            <div className="content">
              <div className="main-box">
                <input type="hidden" name="typ" value="" />
                <input type="hidden" name="delchk" value="0" />
                <table className="list">
                  <thead>
                    <th>회사명</th>
                    <th style={{ width: "20%" }}>채용상세</th>
                  </thead>
                  <tbody>
                    {pagedList.map((company, i) => (
                      <tr key={i}>
                        <td>
                          {company.mcm_idx > 0 ?
                            <p>{company.mcm_name}</p>
                            :
                            <input id={`company_${i}`} name="company" onChange={onChange}/>
                            }
                        </td>
                        <td>
                            {company.mcm_idx > 0 ?
                            <button onClick={() => { delete_company(company.mcm_idx) }}>삭제</button>
                              :
                              <button onClick={() => { save_company(i) }}>저장</button>
                            }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="main-box2">
                  <div className="btn-right">
                      <button className="btn-danger" onClick={() => addCompany()}>추가</button>
                  </div>
              </div>
              <div>
                  <Pagination
                      itemsCount={Companylist.length}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPgaeChange={onPgaeChange}
                      start={start}
                      end={end}
                      handleMovePage={handleMovePage}
                  />
              </div>
            </div>
          </div>
      </React.Fragment>
    )
}

export default Company;