/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import CONFIG from "../../../configs/configs";
import axios from "axios";

import { changeMobileFormat } from "../../../components/Common/Common";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";

import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";

import "admin/css/board.css";


function HrIntroDetail() {
  const { board_idx } = useParams();
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');

  //심사상태--------------------------------------------------------------------------------------------
  const [varHrIntroDetailIdx, setVarHrIntroDetailIdx] = useState(0);
  const [varHrIntroDetailCompany, setVarHrIntroDetailCompany] = useState("");
  const [varHrIntroDetailName, setVarHrIntroDetailName] = useState("");
  const [varHrIntroDetailMobile, setVarHrIntroDetailMobile] = useState("");
  const [varHrIntroDetailDate, setVarHrIntroDetailDate] = useState("");
  const [varHrIntroDetailComment, setVarHrIntroDetailComment] = useState("");
  const [varConsultStatus, setConsultStatus] = useState(1);

  //내용입력시
  const handleHrIntroDetailComment = (e) => {
    setVarHrIntroDetailComment(e.currentTarget.value);
  };

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //최초에 실행
  useEffect(() => {
    setVarHrIntroDetailIdx(board_idx);
    //컨설팅 상세
    const params = new URLSearchParams();
    params.append("board_idx", board_idx);
    axios.post(`${CONFIG.SERVER_HOST}/hrintro_detail`, params).then((res) => {
      console.log(res.data.results[0]);
      const datas = res.data.results[0];
      setVarHrIntroDetailCompany(datas.board_company);
      setVarHrIntroDetailName(datas.board_name);
      setVarHrIntroDetailMobile(
        datas.board_mobile === "" ? "" : changeMobileFormat(datas.board_mobile)
      );
      setVarHrIntroDetailDate(datas.board_rdate.substr(0, 10));
      setVarHrIntroDetailComment(datas.board_comment);
      setConsultStatus(datas.board_state)
    });
  }, []);

  //저장하기
  const updateHrIntroDetail = () => {
    const params = new URLSearchParams();
    params.append("board_idx", varHrIntroDetailIdx);
    params.append("board_comment", varHrIntroDetailComment);
    params.append("board_state", varConsultStatus);
    axios.post(`${CONFIG.SERVER_HOST}/hrintro_update`, params).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1)
      navigate('/admin/hrintrolist')
    });
  };

  //진행상태 변경
  const consultingStatusChange = (e) => {
    const { value } = e.target;
    setConsultStatus(value);
  };

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="hrintrodetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          HR 담당자 소개 관리 상세
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            게시판 관리
            <img src={arrImage} alt="" />
            HR 담당자 소개 관리 상세
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <thead></thead>
              <tbody>
                <tr>
                  <th>회사명</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varHrIntroDetailCompany}
                      style={{
                        width: "90%",
                        pointerEvents: "none",
                        backgroundColor: "#eee",
                      }}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>담당자 이름</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varHrIntroDetailName}
                      style={{
                        width: "200px",
                        pointerEvents: "none",
                        backgroundColor: "#eee",
                      }}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varHrIntroDetailMobile}
                      style={{
                        width: "250px",
                        pointerEvents: "none",
                        backgroundColor: "#eee",
                      }}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>신청일자</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varHrIntroDetailDate}
                      style={{
                        width: "150px",
                        pointerEvents: "none",
                        backgroundColor: "#eee",
                      }}
                      readOnly
                    />
                  </td>
                </tr>
                <tr> 
                  <th>진행상태</th>
                  <td colSpan={3} style={{ textAlign: "left" }}>
                    <select
                      value={varConsultStatus}
                      onChange={consultingStatusChange}
                      className="short_select"
                    >
                      <option value={"1"}>상담 대기</option>
                      <option value={"2"}>상담중</option>
                      <option value={"3"}>상담 완료</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>메모</th>
                  <td className="write-textarea">
                    <textarea
                      value={varHrIntroDetailComment === "null" ? "" : varHrIntroDetailComment}
                      onChange={handleHrIntroDetailComment}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/hrintrolist" className="btn">
                취소
              </Link>
              <button onClick={updateHrIntroDetail} className="btn line">
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default HrIntroDetail;
