import React from "react";
import { Link } from "react-router-dom";

import OKIcon from "img/sub/icon_ok.jpg";

const FindIDOKC = ({ getId }) => {
  function replaceAt(input, index, character) {
    console.log("input :", input);
    console.log("index :", index);
    console.log("character :", character);
    return (
      input.substr(0, index) +
      character +
      input.substr(index + character.length)
    );
  }
  const change_name = replaceAt(getId, 2, "***");
  return (
    <>
      <main className="center_wrap_member">
        <h2 className="sub_content_title">아이디 찾기</h2>
        <section className="box member_form">
          <article className="notice_area">
            <img src={OKIcon} alt="" />
            <div className="oktxt">
              <p className="title_field">아이디 찾기에 성공하셨습니다.</p>

              <p className="text_field">
                회원님의 아이디는 <span className="point">{change_name}</span>
                입니다.
              </p>
            </div>
          </article>
          <article className="bottom_btn_area">
            <Link to="/Login" className="btn">
              로그인
            </Link>
            <Link to="/Member/FindPW" className="btn gray">
              비밀번호 찾기
            </Link>
          </article>
        </section>
      </main>
    </>
  );
};

export default FindIDOKC;
