/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams,Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Paging from '../../../components/Layout/Paging';
import { remainDays,addThousandPoint } from '../../../components/Common/Common'

function Voucher_Order_List({setCategory}) {
    const { mcm_idx} = useParams();

    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    const [varVoucherlist, setVoucherlist] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    const [varTotalCount2,setTotalCount2] = useState(0);
    const [varPageSize2] = useState(10);
    const [varPageNum2,setPageNum2] = useState(1);

    const [varVoucherlist2, setVoucherlist2] = useState([]);
    const [varPagelist2, setPagelist2] = useState([]);

    const [varCurrentRemaining,setCurrentRemaining] = useState({rmNumber:0, rmDays:0});

    useEffect(async () => {
      setCategory(2);
      if(mcm_idx > 0) {
        const params = new URLSearchParams();
        params.append('mcm_idx', mcm_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/voucher_orders_list`, params).then((res) => {
          if(res.data.retvalue === 1) {
            const datas = res.data.orders;
            console.log(datas);
            setTotalCount(datas.length);
            setVoucherlist(datas);

            const datau = res.data.usings;
            console.log(datau);
            setTotalCount2(datau.length);
            setVoucherlist2(datau);

            const datac = res.data.currents;
            console.log(datac);
            setCurrentRemaining({rmNumber:datac.mcc_sending, rmDays:remainDays(datac.mcc_edate)});
          }
        }); 
      }  
    }, []);

    //페이지별 표시 - 결제내역
    const getPageLists = () => {
      const startNum = (varPageNum-1)*varPageSize;
      const endNum = startNum + varPageSize;
      const copyData = varVoucherlist.slice(startNum,endNum);
      setPagelist(copyData);
    }

    useEffect(() => {
      getPageLists();
    },[varPageNum,varVoucherlist]);

    //페이지별 표시 - 사용내역
    const getPageLists2 = () => {
      const startNum2 = (varPageNum2-1)*varPageSize2;
      const endNum2 = startNum2 + varPageSize2;
      const copyData2 = varVoucherlist2.slice(startNum2,endNum2);
      setPagelist2(copyData2);
    }

    useEffect(() => {
      getPageLists2();
    },[varPageNum2,varVoucherlist2]);

    return (
        <React.Fragment>
          <p className="table_title" style={{marginTop:"20px"}}>- 이용권 사용내역</p>
          <div className="main-box" >
              <table className="write">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>제안 발송 남은 수</th>
                      <td>{varCurrentRemaining.rmNumber+" 건"}</td>
                      <th>남은 이용기간</th>
                      <td>{varCurrentRemaining.rmDays+" 일"}</td>
                    </tr>
                  </tbody>
              </table>
            </div>
            <div className="main-box ">
              <table className="list">
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>채용공고</th>
                    <th>후보자</th>
                    <th>사용자</th>
                    <th>사용일시</th>
                  </tr>
                </thead>
                <tbody>
                {varPagelist2.map((usings, i) => (
                  <tr key={'usings'+i}>
                      <td>{varTotalCount2-(varPageNum2-1)*varPageSize2-i}</td>
                      <td className="ellipsis">{usings.crs_em_idx===0?usings.crs_em_title:usings.em_title}</td>
                      <td className="ellipsis">{usings.mem_name}</td>
                      <td className="ellipsis">{usings.mem_id}</td>
                      <td className="ellipsis">{usings.crs_rdate}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            <div>
            <Paging totalCount={varTotalCount2} pageSize={varPageSize2} curPage={varPageNum2} setPage={setPageNum2} />
            </div>
          <p className="table_title" style={{marginTop:"20px"}}>- 이용권 결제내역</p>
          <div className="main-box ">
            <table className="list">
              <colgroup>
                <col style={{width:"8%"}} />
                <col style={{width:"34%"}} />
                <col style={{width:"12%"}} />
                <col style={{width:"12%"}} />
                <col style={{width:"14%"}} />
                <col style={{width:"12%"}} />
                <col style={{width:"8%"}} />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>이용권 종류</th>
                  <th>결제방식</th>
                  <th>가격</th>
                  <th>구매일자</th>
                  <th>상태</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
              {varPagelist.map((resume, i) => (
                <tr key={i}>
                    <td>{varTotalCount-(varPageNum-1)*varPageSize-i}</td>
                    <td className="ellipsis">{resume.vo_name}</td>
                    <td>{resume.or_pay_method === 0?"무통장":"카드"}</td>
                    <td>{addThousandPoint(resume.or_price)}</td>
                    <td className="ellipsis">{resume.or_rdate}</td>
                    <td>
                        {resume.or_status === 0 ?"결제대기"
                        :resume.or_status === 1 ?"결제완료"
                        :resume.or_status === 2 ?"사용중"
                        :resume.or_status === 3 ?"환불요청"
                        :resume.or_status === 4 ?"환불완료"
                        :resume.or_status === -1 ?"사용완료":""}
                    </td>
                    <td>
                        {resume.or_status === 0 ?<Link className="btn line black" to={`/admin/paymentdetail/${resume.or_idx}`}>상세</Link>
                        :resume.or_status === 1 ?<Link className="btn line black" to={`/admin/paymentdetail/${resume.or_idx}`}>상세</Link>
                        :resume.or_status === 2 ?<Link className="btn line black" to={`/admin/paymentdetail/${resume.or_idx}`}>상세</Link>
                        :resume.or_status === 3 ?<Link className="btn line black" to={`/refunddetail/${resume.or_idx}`}>상세</Link>
                        :resume.or_status === 4 ?<Link className="btn line black" to={`/refunddetail/${resume.or_idx}`}>상세</Link>
                        :resume.or_status === -1 ?<Link className="btn line black" to={`/paymentdetail/${resume.or_idx}`}>상세</Link>:""}
                        </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div>
          <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
        </React.Fragment>
    )
}

export default Voucher_Order_List;