import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
//import moment from "moment";

//css
import "css/Board.css";

//컴포넌트
import { resetNavSubMenu } from '../Common/Common';
import MyRecommendTop from 'components/My/MyRecommendTop';
import MyRecommendSearch from './MyRecommendSearch';
import MyRecommendList from 'components/My/MyRecommendList';
import Paging from 'components/Layout/Paging';

function MyHeding5({ getSubItem }) {

  //2022-11-07 다른쪽에서 추천 현황쪽을 넘어오는게 있을 얘기 해주세요 - KSR
  //const location = useLocation();
  //const applyValue = location.state;
  const [varTabs, setTabs] = useState(0);
  const user_idx = localStorage.getItem('mem_idx');
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  //목록 Data
  const [varRecommendList, setRecommendList] = useState([]);

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);  //전체 레코드 수(페이징 전)
  const [varPageSize] = useState(10);                 //페이지당 레코드 수
  const [varPageNum, setPageNum] = useState(1);        //현재 페이지

  const [varRecommendCounter, setRecommendCounter] = useState(0);//추천인 수
  const [varRecommendApplyCounter, setRecommendApplyCounter] = useState(0);//지원자 수


  //검색 관련
  const [varSearchData, setSearchData] = useState({
    sdate: null,
    edate: null,
    searchoption: "all",
    searchstring: null
  });

  useEffect(() => {
    getSubItem(5);
    if (mem_mode === 0) {
      resetNavSubMenu(4, 4);
    } else {
      resetNavSubMenu(2, 4);
    }
    setPageNum(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabs]);

  useEffect(() => {
    setRecommendList([]);
  }, [varTabs]);


  //목록 호출(API)
  const readRecommendList = () => {
    const params = new URLSearchParams();
    //기본
    params.append("user_idx", user_idx);
    params.append("pageMode", varTabs);
    //페이징
    params.append("pageUnit", varPageSize);   //페이지당 레코드 수
    params.append("pageNum", varPageNum);     //현재 페이지
    //검색
    params.append("stDate", varSearchData.sdate);    //시작일
    params.append("edDate", varSearchData.edate);      //종료일
    params.append("shOpt", varSearchData.searchoption);  //검색옵션
    params.append("shStr", varSearchData.searchstring);  //검색어

    axios.post(`${CONFIG.SERVER_HOST}/_get_employ_recommend_list`, params).then((res) => {
      console.log("_get_employ_recommend_list:", res.data)
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        setRecommendCounter(res.data.rcount);       //추천인 수
        setRecommendApplyCounter(res.data.pcount);  //지원자 수

        setRecommendList(res.data.candidates);
      }
    });
  }
  console.log(varRecommendList)

  useEffect(() => {
    readRecommendList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabs, varPageNum]);

  //공고 클릭
  const onExecuteSearchingHandler = (e) => {

  };


  return (
    <div className="container page_recommend">
      <MyRecommendTop varTabs={varTabs} setTabs={setTabs} varRecommendCounter={varRecommendCounter} varRecommendApplyCounter={varRecommendApplyCounter} onExecuteSearchingHandler={onExecuteSearchingHandler} />

      {/* 2022-11-07 검색 root 로 빼냄 - KSR */}
      <MyRecommendSearch varTabs={varTabs} setPageNum={setPageNum} varSearchData={varSearchData} setSearchData={setSearchData} readRecommendList={readRecommendList} />

      <MyRecommendList varTabs={varTabs} varRecommendList={varRecommendList} />
      <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
    </div>
  );
}

export default MyHeding5;
