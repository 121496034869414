/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CONFIG from '../../../configs/configs'
import axios from 'axios'
import moment from 'moment'

import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'

function PopupRegister() {
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varIsIdx, setIsIdx] = useState(0);
  const { pop_idx } = useParams();
  const today = new Date().toISOString().substr(0, 10).replace('T', '');
  const [varName, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [varTop, setTop] = useState(0);
  const [varLeft, setLeft] = useState(0);
  const [varWidth, setWidth] = useState(0);
  const [varHeight, setHeight] = useState(0);
  const [varType, setType] = useState(0);
  const [varUse, setUse] = useState(0);
  const [varContent, setContent] = useState('');

  const onNameHandler = e => {
    setName(e.currentTarget.value);
  }

  const onContentHandler = e => {
    setContent(e.currentTarget.value)
  }

  const onTypeHandler = e => {
    setType(e.currentTarget.value)
  }

  const onUseHandler = e => {
    setUse(e.currentTarget.value);
  }

  const handleStartDate = e => {
    setStartDate(e.currentTarget.value);
  }
  const handleEndDate = e => {
    setEndDate(e.currentTarget.value);
  }

  const onLeftHandler = e => {
    setLeft(e.currentTarget.value);
  }

  const onTopHandler = e => {
    setTop(e.currentTarget.value);
  }
  const onWidthHandler = e => {
    setWidth(e.currentTarget.value);
  }
  const onHeightHandler = e => {
    setHeight(e.currentTarget.value);
  }

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmitHandler = e => {
    e.preventDefault()
    const param = new URLSearchParams();
    param.append('pop_name', varName);
    param.append('pop_sdate', startDate);
    param.append('pop_edate', endDate);
    param.append('pop_top', varTop);
    param.append('pop_left', varLeft);
    param.append('pop_width', varWidth);
    param.append('pop_height', varHeight);
    param.append('pop_type', varType);
    param.append('pop_comment', varContent);
    param.append('pop_use', varUse);
    param.append('pop_rdate', today);

    if (!varName) {
      alert('이름을 입력해주세요.');
    } else if (!startDate || !endDate) {
      alert('게시기간을 입력해주세요.');
    } else if (!varTop && !varLeft && !varWidth && !varHeight) {
      alert('위치 및 크기를 입력해주세요.');
    } else if (!varContent) {
      alert('내용을 입력해주세요.');
    } else {
      axios.post(`${CONFIG.SERVER_HOST}/popup_create`, param).then(res => {
        if (res.status === 200 && param !== '') {
          alert('등록에 성공하였습니다.');
          navigate('/admin/popup');
        } else {
          alert('등록에 실패하였습니다.');
        }
      })
    }
  }

  const onUpdateHandler = e => {
    e.preventDefault()
    const param = new URLSearchParams();
    param.append('pop_name', varName);
    param.append('pop_sdate', moment(startDate).format(CONFIG.formatStr));
    param.append('pop_edate', moment(endDate).format(CONFIG.formatStr));
    param.append('pop_top', varTop);
    param.append('pop_left', varLeft);
    param.append('pop_width', varWidth);
    param.append('pop_height', varHeight);
    param.append('pop_type', varType);
    param.append('pop_comment', varContent);
    param.append('pop_use', varUse);
    param.append('pop_idx', varIsIdx);

    axios.post(`${CONFIG.SERVER_HOST}/popup_update`, param).then(res => {
      console.log('res:' + res);
      if (res.status === 200) {
        alert('수정에 성공하였습니다.');
        navigate('/admin/popup');
      } else {
        alert('수정에 실패하였습니다.');
      }
    })
  }

  useEffect(() => {
    setIsIdx(pop_idx);
    if (pop_idx > 0) {
      axios.post(`${CONFIG.SERVER_HOST}/popup_r?idx=${pop_idx}`).then(res => {
        console.log(res.data);
        setName(res.data[0].pop_name);
        setStartDate(res.data[0].pop_sdate);
        setEndDate(res.data[0].pop_edate);
        setTop(res.data[0].pop_top);
        setLeft(res.data[0].pop_left);
        setWidth(res.data[0].pop_width);
        setHeight(res.data[0].pop_height);
        setType(res.data[0].pop_type);
        setContent(res.data[0].pop_comment);
        setUse(res.data[0].pop_use);
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="popup" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          팝업 관리
          <div className="path">
            <img src={iconhome} alt=""/>HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt=""/>디자인 관리<img src={arrImage} alt=""/>팝업 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">팝업 관리</div> */}
          <div className="main-box">
              <table className="write">
                <tr>
                  <th>팝업명</th>
                  <td><input type="text" value={varName} onChange={(e)=>onNameHandler(e)} maxLength="30" /></td>
                </tr>
                <tr>
                  <th>게시기간</th>
                  <td>
                    <div className="day-box">
                      <input type="date" name="startdate"
                        value={moment(startDate).format(CONFIG.formatStr)}
                        min={today}
                        onChange={(e)=>handleStartDate(e)}
                        id="datePicker"
                      />
                      ~
                      <input type="date" name="enddate"
                        value={moment(endDate).format(CONFIG.formatStr)}
                        min={today}
                        onChange={(e)=>handleEndDate(e)}
                        id="datePicker2"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>위치 및 크기 설정</th>
                  <td>
                    <div className="size-box">
                      <div style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        상단 간격
                        <input type="number" dir="rtl" value={varTop} onChange={(e)=>onTopHandler(e)} 
                                maxLength="30" style={{width:"150px",lineHeight:"36px",height:"40px",margin:"0px 10px",
                                display:"inline-block"}}/>
                        px
                      </div>
                      <div style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        왼쪽 간격
                        <input
                          type="number"
                          dir="rtl"
                          value={varLeft}
                          onChange={(e)=>onLeftHandler(e)}
                          style={{width:"150px",lineHeight:"36px",height:"40px",margin:"0px 10px",display:"inline-block"}}
                          maxLength="30"
                        />
                        px
                      </div>
                      <div style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        팝업 높이
                        <input
                          type="number"
                          dir="rtl"
                          value={varHeight}
                          onChange={(e)=>onHeightHandler(e)}
                          style={{width:"150px",lineHeight:"36px",height:"40px",margin:"0px 10px",display:"inline-block"}}
                          maxLength="30"
                        />
                        px
                      </div>
                      <div style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        팝업 너비
                        <input
                          type="number"
                          dir="rtl"
                          value={varWidth}
                          onChange={(e)=>onWidthHandler(e)}
                          style={{width:"150px",lineHeight:"36px",height:"40px",margin:"0px 10px",display:"inline-block"}}
                          maxLength="30"
                        />
                        px
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>팝업형식</th>
                  <td>
                    <select style={{width:'120px'}} name="type" value={varType} onChange={(e)=>onTypeHandler(e)}>
                      <option value="0">일반</option>
                      <option value="1">레이어</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>사용여부</th>
                  <td>
                    <select style={{width:'120px'}} name="use" value={varUse} onChange={(e)=>onUseHandler(e)}>
                      <option value="0">Y</option>
                      <option value="1">N</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>내용</th>
                  <td className="write-textarea">
                    <textarea
                      style={{resize: 'none',overflowY: 'scroll'}}
                      className="tfScroll"
                      value={varContent}
                      onChange={(e)=>onContentHandler(e)}
                    />
                  </td>
                </tr>
              </table>
          </div>
          <div className="btn-center">
            <Link className="btn line" to="/admin/popup" style={{ color: '#fff' }}>목록</Link>
            {pop_idx === '0' ? (
              <button className="btn" onClick={(e)=>onSubmitHandler(e)}>등록</button>
            ) : (
              <button className="btn" onClick={(e)=>onUpdateHandler(e)}>수정</button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PopupRegister
