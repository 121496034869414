/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "configs/configs";
import { Link } from "react-router-dom";

//components
import MyTalkSearch from "./MyTalkSearch";
import Paging from "components/Layout/Paging";
import { em_titleLength, isReactNative } from "components/Common/Common";

/*채용 지원 현황*/
function MyTalkList({
  setSelectOffer,
  onSetPopup,
  varReloading,
  setReloading,
}) {

  //모바일 체크
  const mobile = isReactNative();

  // 글제목 ... 처리 시작 수 설정
  const textNum = mobile ? 20 : 24;
  const user_idx = localStorage.getItem("mem_idx");
  //const cDate = new Date();
  const [varSearchData, setSearchData] = useState({
    sdate: null,
    edate: null,
    searchoption: "",
    searchstring: "",
  });
  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  // 작성목록
  const [varWriteList, setWriteList] = useState([]);
  const [varFilteredWriteList, setFilteredWriteLists] = useState([]);

  const readOfferList = () => {
    if (varReloading) {
      const sendData = {
        "PageSize": varPageSize,
        "PageNum": varPageNum,
        "mem_idx": user_idx,
      }
      axios
        .post(`${CONFIG.SERVER_HOST}/_my_talk_list`, sendData, CONFIG.header)
        .then((res) => {
          console.log(res.data);
          if (res.data.retValue === 1) {
            const datas = res.data.results;
            setTotalCount(datas.length);
            setWriteList(datas); //원본 - 전체
            setFilteredWriteLists(datas); //필터링 배열
            setReloading(false);
          }
        });
    }
  };

  useEffect(() => {
    readOfferList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varReloading]);

  //필터링- 검색버튼 클릭시
  const filteringWriteData = () => {
    var copyData = [...varWriteList];
    //console.log(copyData);
    //지원일자
    if (varSearchData.sdate !== null) {
      copyData = copyData.filter(
        (element) => element.mwt_rdate.slice(0, 10) >= varSearchData.sdate
      );
    }
    if (varSearchData.edate !== null) {
      copyData = copyData.filter(
        (element) => element.mwt_rdate.slice(0, 10) <= varSearchData.edate
      );
    }
    //검색부분
    if (varSearchData.searchstring !== "") {
      if (varSearchData.searchoption === "mwt_title") {
        copyData = copyData.filter((element) =>
          element.mwt_title.toLowerCase().includes(varSearchData.searchstring.toLowerCase())
        );
      } else if (varSearchData.searchoption === "mwt_comment") {
        copyData = copyData.filter((element) =>
          element.mwt_comment.toLowerCase().includes(varSearchData.searchstring.toLowerCase())
        );
      } else {
        copyData = copyData.filter(
          (element) =>
            element.mwt_title.toLowerCase().includes(varSearchData.searchstring.toLowerCase()) || element.mwt_comment.toLowerCase().includes(varSearchData.searchstring.toLowerCase())
        );
      }
    }
    //필터링된 전체 페이지
    //console.log(copyData);
    setTotalCount(copyData.length);
    setFilteredWriteLists(copyData);
    setPageNum(1);
    //getDataPerPage(copyData);
  };

  useEffect(() => {
    filteringWriteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearchData, varWriteList]);

  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varFilteredWriteList.length === 0) {
      return;
    }
  };
  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varFilteredWriteList]);


  // 게시글 삭제
  const deleteBoard = (idx) => {
    if (!window.confirm("선택한 게시글을 삭제하시겠습니까?")) return;

    const params = new URLSearchParams();
    params.append("mwt_idx", idx);
    axios.post(`${CONFIG.SERVER_HOST}/talk_delete`, params).then((res) => {
      if (res.data.retValue === 1) {
        setReloading(true);
      } else {
        window.alert(res.data.message);
      }
    });
  };

  return (
    <>
      <MyTalkSearch
        varSearchData={varSearchData}
        setSearchData={setSearchData}
        filteringWriteData={filteringWriteData}
      />
      {/*pc리스트*/}
      <table className="table_list01 table_my_talk_list">
        <colgroup>
          <col style={{ width: "8%" }} />
          <col style={{ width: "30%" }} />
          <col />
          <col />
          <col style={{ width: "8%" }} />
          <col style={{ width: "8%" }} />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th className="th_num">번호</th>
            <th className="th_title">제목</th>
            <th>작성자</th>
            <th className="th_date">작성일</th>
            <th>조회수</th>
            <th>추천수</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {varFilteredWriteList && varFilteredWriteList.map((e, i) => (
            <tr key={i}>
              <td className="td_num">{i + 1}</td>
              <td className="td_title ellipsis with_btn two_btn">{e.mwt_title === "" ? "-" : em_titleLength(e.mwt_title, textNum, '...')}<span className="re">[{e.replys}]</span></td>
              <td className="td_info"><span className="field_tit">작성자 : </span>{e.mem_type === 0 ? e.mem_id : e.mem_nickname}</td>
              <td className="td_info"><span className="field_tit">작성일 : </span>{e.mwt_rdate}</td>
              <td className="td_info"><span className="field_tit">조회수 : </span>{e.mwt_hits}</td>
              <td className="td_info last"><span className="field_tit">추천수 : </span>{e.recommend}</td>
              <td className="td_button">
                <div className="td_button_wrap">
                  <Link to={`/MyTalk/TalkUpdate`} state={{ mwt_idx: e.mwt_idx, before: `myheding` }} className="button off mr">수정</Link>|
                  <button className="button off ml" onClick={() => deleteBoard(e.mwt_idx)}>삭제</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="box_out_button_area one">
        <Link to={`/MyTalk/TalkWrite`} state={{ mwt_idx: 0 }} className="btn" > 등록하기</Link>
      </div>
      <Paging
        totalCount={varTotalCount}
        pageSize={varPageSize}
        curPage={varPageNum}
        setPage={setPageNum}
      />
    </>
  );
}

export default MyTalkList;