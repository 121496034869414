/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
//image
import deleteSchoolImage2 from "../../img/sub/btn_delete02.png";
//datepicker
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios';
import CONFIG from 'configs/configs'


function SchoolHistory({
  key,
  index,
  language,
  languageStudyList,
  setLanguageStudyList
}) {


  const [varLanguageLevel, setLanguageLevel] = useState([]);

  const handleSelectLevel = (e) => {
    const finsIndex = languageStudyList.findIndex(element => element.language_code === language.language_code);
    let copyData = [...languageStudyList];
    copyData[finsIndex] = { ...copyData[finsIndex], language_level: Number(e.currentTarget.value) };
    setLanguageStudyList(copyData);
  }

  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/var_language_level`).then((res) => {
      setLanguageLevel(res.data);
    });
  }, []);

  //어학삭제
  const onRemoveHandler = (e) => {
    e.preventDefault();
    if (!window.confirm("선택한 어학을 삭제합니까?")) return;
    setLanguageStudyList(
      languageStudyList.filter(
        (element) => element.language_code !== language.language_code
      )
    );
  };

  return (
    <div className="input_area" key={"resch_" + index}>
      <button className="btn_del" onClick={(e) => onRemoveHandler(e)} style={{cursor:"pointer"}}>
        <img src={deleteSchoolImage2} alt="" />
      </button>
      <input
        type="text"
        className={"input_part"+(language.language_name ? ' bg_on' : '')}
        name="language_name"
        value={language.language_name}
        readOnly
      />
      {/* 2022-09-22 KHJ: select 박스로 변경 요청 */}
      <select className={`input_part3 ${language.language_level > 0 ? `bg_on`: ``}`} name="language_level" onChange={handleSelectLevel} value={language.language_level}>
        <option value={0}>언어 레벨을 선택해주세요.</option>
        {varLanguageLevel.length > 0 ? varLanguageLevel.map((data, i) => (
          <option key={"workd" + i} value={Number(data.lal_code)} >{data.lal_name}</option>
        )) : null}
      </select>
    </div>
  );
}

export default SchoolHistory;
