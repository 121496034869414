import React, { useState,useEffect }from "react";
import { useLocation, Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from 'configs/configs';

//컴포넌트
import { changeMobileFormat,ChangeMemberTypeString,addThousandPoint,MaskingCivilCode,ChangeDateString } from '../Common/Common';

/*채용 지원 현황*/
function MyMoneyChangeView({ getSubItem,backPage }) { 
    const location = useLocation();
    const [varMHExchange,setMHExchange] = useState({});
    var mh_idx = 0;
    if(location !== null && location.state !== null) {
        mh_idx = location.state.mh_idx;
    }
    //최초 - 헤딩머니 입출금내역 가져오기
    const getHedingMoneyExchange = async () => {
        try {
            console.log('mh_idx',mh_idx);
            const params = new URLSearchParams();
            params.append("mh_idx", mh_idx);
            await axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_exchange`,params).then((res) => {
                if(res.data.retvalue === 1) {
                    console.log(res.data.results);
                    if(res.data.results.length > 0) {
                        console.log(res.data.results[0].mem_nid);
                        setMHExchange(res.data.results[0]);
                    }
                } else {
                    setMHExchange({});
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
      getSubItem(backPage);
      getHedingMoneyExchange();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

  return (
    <div className="container">
        <section className="box box_content">
            <h3 className="my_title">환전계좌정보</h3>
            <ul className="table_view02">
                <li className="full">
                    <div className="title_field">은행명</div>
                    <div className="info_field">{varMHExchange.bl_name}</div>
                </li>
                <li>
                    <div className="title_field">계좌번호</div>
                    <div className="info_field">{varMHExchange.mh_account==null?varMHExchange.mc_account:varMHExchange.mh_account}</div>
                </li>
                <li>
                    <div className="title_field">예금주</div>
                    <div className="info_field">{varMHExchange.mh_owner==null?varMHExchange.mc_owner:varMHExchange.mh_owner}</div>
                </li>
                <li className="full">
                    <div className="title_field">주민번호</div>
                    <div className="info_field">{MaskingCivilCode(varMHExchange.mem_nid)}</div>
                </li>
            </ul>
            <div className="view_top">
                <h3 className="my_title">환전신청정보</h3>
                <p className="tip_field">※ 월 말일까지 환전 신청한 내역이 익월 10일에 입금됩니다. </p>
            </div>
            <ul className="table_view02">
                <li className="full">
                    <div className="title_field">신청자</div>
                    <div className="info_field">{varMHExchange.mem_name}</div>
                </li>
                <li className="full">
                    <div className="title_field">닉네임</div>
                    <div className="info_field">{varMHExchange.mem_nickname}</div>
                </li>
                <li className="full">
                    <div className="title_field">연락처</div>
                    <div className="info_field">{changeMobileFormat(varMHExchange.mem_mobile)}</div>
                </li>
                <li className="full">
                    <div className="title_field">회원구분</div>
                    <div className="info_field">{ChangeMemberTypeString(varMHExchange.mem_mode,varMHExchange.mem_hunter_type)}</div>
                </li>
                <li>
                    <div className="title_field">회사명</div>
                    <div className="info_field">HEDING</div>   {/* 환전의 경우 - HEDING에서 지급 */}
                </li>
                <li>
                    <div className="title_field">구분</div>
                    <div className="info_field">환전 <span className="point_text">&nbsp;({varMHExchange.mh_status===1?"환전신청" : varMHExchange.mh_status === 2 ? "승인완료" : varMHExchange.mh_status === 3 ? "환전완료" : varMHExchange.mh_status === -1 ? "환전취소" : "-"})</span></div>
                </li>
                <li>
                    <div className="title_field">환전요청금액</div>
                    <div className="info_field">{addThousandPoint(Math.abs(varMHExchange.mh_paid))+" 원"}</div>
                </li>
                <li>
                    <div className="title_field">환전 예정일</div>
                    <div className="info_field">{varMHExchange.mh_pdate===null?"":ChangeDateString(varMHExchange.mh_pdate)}</div>
                </li>
                <li>
                    <div className="title_field">환전 수수료</div>
                    <div className="info_field">{addThousandPoint(varMHExchange.mh_commission)+" 원"}<span className="point_text">&nbsp;(10%)</span></div>
                </li>
                <li>
                    <div className="title_field">소득세/주민세</div>
                    <div className="info_field">{addThousandPoint(varMHExchange.mh_tax)+" 원"}<span className="point_text">&nbsp;(3.3%)</span></div>
                </li>
                <li className="full">
                    <div className="title_field">실 환전금액</div>
                    <div className="info_field">{addThousandPoint(Math.abs(varMHExchange.mh_exchange))+" 원"}</div>
                </li>
            </ul>
        </section>
        <div className="box_out_button_area one">
            <Link to={"../MyHeding"+backPage} className="btn white">목록으로</Link>
        </div>
    </div>
  );
}

export default MyMoneyChangeView;
