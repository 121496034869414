/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DaumPostcode from "react-daum-postcode";

// 이미지
import closeImage from "img/common/btn_close_white.png";

const Postcode = ({ varIsOpenPost, onCompletePostHandler, setIsOpenPost }) => {
  return (
      <div className="poplayerCompanySearch">
        <div className="consbox" style={{ zIndex: 999 }}>
          <div className="btnclose" onClick={() => setIsOpenPost(false)}>
            <img src={closeImage} alt="" />
          </div>
          <DaumPostcode
            style={{ width: "100%", height: "500px" }}
            jsOptions={{ animation: varIsOpenPost }}
            autoClose={true}
            onComplete={(e) => onCompletePostHandler(e)}
          />
        </div>
      </div>
  );
};

export default Postcode;
