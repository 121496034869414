/* eslint-disable array-callback-return */
// import React, { useState, useEffect }from "react";
// import { useNavigate } from "react-router-dom";
// import axios from 'axios';
// import CONFIG from '../../../configs/configs';

// import iconhome from '../../img/icon-home.png';
// import arrImage from '../../img/arr.png';

// import Titlebar from '../inc/Titlebar';
// import Tabbar from '../inc/Tabbar';
// import Menu from '../inc/Menu';
// import {send_push} from '../../../components/Common/Common';


// function Push (){
//   const navigator = useNavigate();
//   const adminlogin = localStorage.getItem('adminlogin');
//     const [push, setPush] = useState("");

//     useEffect(() => {
//       if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
//         alert("로그인 후 이용 가능합니다.");
//         navigator("/admin/login");
//       } // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     const handlePush =(e)=>{
//       setPush(e.target.value);
//     }

//     const sendPush =()=>{
//       const title ='전체 발송'
//       send_push(title, push);

//       const param = new URLSearchParams();
//       param.append("push_title", title);
//       param.append("push_content", push);
//       axios.post(`${CONFIG.SERVER_HOST}/setPush`, param).then((res) => {
//         // console.log(res);
//       });
//     }

//     return(
//       <React.Fragment>
//         <Titlebar/>
//         <Tabbar tabType="pref"/>
//         <Menu menuType="pref" menu="auto"/>
//         <div id="contents" className="ad_contents">
//           <div className="tit">자동 PUSH관리
//             <div className="path">
//               <img src={iconhome} alt=""/>HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt="" />PUSH 관리<img src={arrImage} alt=""/>자동 PUSH관리
//             </div>
//           </div>
//           <div className="content">
//             {/* <div className="tit">PUSH</div> */}
//             <div className="main-box">
//               <table className="write">
//                 <thead></thead>
//                 <tbody>
//                   <tr>
//                     <th>맴버십 유형</th>
//                     <td colSpan="3">
//                       <><input type="radio" name="type" id="all" value="all" defaultChecked="true"/> <label htmlFor="all">전체회원</label></>
//                     </td>
//                   </tr>
//                   <tr>
//                     <th>PUSH 내용</th>
//                     <td colSpan="3">
//                       <textarea type="text" name="text" value={push} onChange={(e)=>handlePush(e)} maxLength="30"/>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//             <div className="btn-center">
//               <button className="btn line">취소</button>
//               <button className="btn" onClick={()=>sendPush()}>저장</button>
//             </div>
//           </div>
//         </div> 
//       </React.Fragment>
//     )
// }

// export default Push;
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/configs';
// import moment from 'moment';

import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

function Push() {
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [push, setPush] = useState([]);

    useEffect(() => {
        axios.post(`${CONFIG.SERVER_HOST}/getPushTemple`).then((res) => {
          console.log(res.data);
          setPush(res.data)
        });
    }, [])

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    


    const handleUpdate = (e) => {
      const {value, checked} = e.target;
      const sendData = {
        'idx': value,
        'status': checked===true ? 'Y':'N'
      }
      axios.post(`${CONFIG.SERVER_HOST}/PushTempleYN`, sendData, CONFIG.header).then((res) => {
        if(res){
          setPush(push.map(item => (item.idx === Number(value))
          ? {...item, status : checked===true ? 'Y':'N'} 
          : item))
        }
      });
      

    }

    console.log(push);

    return (
        <React.Fragment>
          <Titlebar />
          <Tabbar tabType="pref" />
          <Menu menuType="pref" menu="auto" />
          <div id="contents" className="ad_contents">
            <div className="tit">자동 PUSH관리
              <div className="path">
                <img src={iconhome} alt=""/>
                  HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>PUSH 관리<img src={arrImage} alt=""/>자동 PUSH관리
              </div>
            </div>
            <div className="content">
              <input type="hidden" name="typ" value="" />
              <input type="hidden" name="delchk" value="0" />
              <div className="main-box">
                <table className="list">
                  <colgroup>
                    <col width="8%"></col>
                    <col width="10%"></col>
                    <col width="50%"></col>
                    <col width="10%"></col>
                    <col width="10%"></col>
                  </colgroup>
                  <thead>
                    <tr><th>번호</th><th>PUSH 발송 항목</th><th>내용</th><th>관리</th><th>사용여부</th></tr>
                  </thead>
                  <tbody>
                    {push.map((data, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td className="ellipsis">{data.title}</td>
                        <td>{data.body}</td>
                        <td><Link to={`/admin/push_detail/${data.idx}`}>[내용 수정]</Link></td>
                        {/* <td> 
                        <input name="ChkYN" type='checkbox' checked={checkList?.includes(i) ? true:false} onClick={(e) => setChk(e)} value={i} /> <label for="ChkYN">{data.status}</label></td> */}
                        <td> 
                        <input name="ChkYN" type='checkbox' checked={data.status === 'Y' ? true:false} onClick={(e) => handleUpdate(e)} value={data.idx} /> <label for="ChkYN">{data.status}</label></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
}

export default Push;