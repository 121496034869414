/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";

//components
import MyApplySearch from "./MyApplySearch";
import Paging from "../../components/Layout/Paging";
import { em_titleLength, ChangeMoneyUnit } from "../Common/Common";

/*채용 지원 현황*/
function MyApplyList({ varSearchData, setSearchData }) {
  const user_idx = localStorage.getItem("mem_idx");
  //const cDate = new Date();

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //지원목록
  const [varApplyList, setApplyList] = useState([]);
  const [varFilteredApplyList, setFilteredApplyList] = useState([]);
  const [varPageApplyList, setPageApplyList] = useState([]);

  const readApplyList = () => {
    const params = new URLSearchParams();
    params.append("mem_idx", user_idx);
    axios.post(`${CONFIG.SERVER_HOST}/employ_recommond_list`, params).then((res) => {
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const data = res.data.results.map((data, i) => ({
          links: `/MyRecruit/MainView/${data.ea_em_idx}/${data.em_headhunter}`,
          em_idx: data.ea_em_idx,
          idx: data.ea_idx,
          title: data.em_title,
          cname: data.com_name,
          tasks: data.em_tasks_info,
          reward: data.em_reward_applicant === 0 ? "-" : ChangeMoneyUnit(data.em_reward_applicant / 10000),
          recommend: data.er_mem_id == null ? "" : data.er_mem_id,
          rname: data.er_mem_id == null ? "" : data.mem_name,
          regdate: data.ea_rdate.substr(0, 10),
          stmode: data.ea_checking >= 0 && data.ea_checking <= 2 ? 1 : 0,
          ea_checking: data.ea_checking,
          er_name: data.er_name,
          status: data.ems_name,
        }));
        // console.log(data);
        setApplyList(data); //원본 - 전체
        //setFilteredApplyList(data); //필터링 배열
      }
    });
  };
  useEffect(() => {
    readApplyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //필터링- 검색버튼 클릭시
  const filteringApplyData = () => {
    var copyData = [...varApplyList];
    //진행상태
    if (varSearchData.status !== 100) {
      copyData = copyData.filter(
        (element) => element.ea_checking === varSearchData.status
      );
    }
    //지원일자
    if (varSearchData.sdate !== null) {
      copyData = copyData.filter(
        (element) => new Date(element.regdate) >= new Date(varSearchData.sdate)
      );
    }
    if (varSearchData.edate !== null) {
      copyData = copyData.filter(
        (element) => new Date(element.regdate) <= new Date(varSearchData.edate)
      );
    }

    //검색부분
    if (varSearchData.searchstring !== "") {
      if (varSearchData.searchoption === "all") {
        copyData = copyData.filter((element) =>
          element.title.includes(varSearchData.searchstring) || element.cname.includes(varSearchData.searchstring) ||
          element.rname.includes(varSearchData.searchstring)
        );
      } else {
        if (varSearchData.searchoption === "em_title") {
          copyData = copyData.filter((element) =>
            element.title.includes(varSearchData.searchstring)
          );
        } else if (varSearchData.searchoption === "com_name") {
          copyData = copyData.filter((element) =>
            element.cname.includes(varSearchData.searchstring)
          );
          console.log(copyData);
        } else if (varSearchData.searchoption === "er_name") {
          copyData = copyData.filter((element) =>
            element.rname.includes(varSearchData.searchstring)
          );
        }
      }
    }
    //필터링된 전체 페이지
    setTotalCount(copyData.length);
    setFilteredApplyList(copyData);
    setPageNum(1);
    //getDataPerPage(copyData);
  };
  useEffect(() => {
    filteringApplyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSearchData, varApplyList]);

  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varFilteredApplyList.length === 0) {
      setPageApplyList([]);
      return;
    }
    var copyData = [...varFilteredApplyList];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    //console.log(copyData);
    setPageApplyList(copyData);
  };
  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varFilteredApplyList]);

  const handleCancelApply = (e, ea_idx) => {
    e.preventDefault();
    //console.log('aaaa',ea_idx);
    if (!window.confirm("지원을 취소하시겠습니까?")) {
      return;
    }
    const params = new URLSearchParams();
    params.append("ea_idx", ea_idx);
    axios.post(`${CONFIG.SERVER_HOST}/_cancel_employ_apply`, params).then((res) => {
      if (res.data.retvalue === 1) {
        readApplyList();
      } else {
        window.alert("오류가 발생했습니다.");
      }
    });
  };

  return (
    <>
      <div className="list_top">
        <div className="search_wrap">
          <MyApplySearch varSearchData={varSearchData} setSearchData={setSearchData} />
        </div>
      </div>
      <table className="table_list01">
        <colgroup>
          <col width="28%"></col>
          <col width="14%"></col>
          <col width="12%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="10%"></col>
          <col width="10%"></col>
          <col width="10%"></col>
        </colgroup>
        <thead>
          <tr>
            <th className="th_title">채용제목</th>
            <th>회사명</th>
            <th>직무</th>
            <th>보상금</th>
            <th>추천인</th>
            <th className="th_date">지원일자</th>
            <th>진행상태</th>
            <th>지원취소</th>
          </tr>
        </thead>
        <tbody>
          {varTotalCount !== 0 ? varPageApplyList?.map((applys, i) => (
            <tr key={"applys_" + i} className="tr">
              <td className="td_title with_btn">
                <Link to={applys.links} state={{ em_idx: applys.em_idx }}>{applys?.title}</Link>
              </td>
              <td className="td_info">{em_titleLength(applys?.cname, 13, '...')}</td>
              <td className="td_info">{applys?.tasks === null || applys?.tasks === undefined || applys?.tasks === "" ? "-" : em_titleLength(applys?.tasks, 9, "...")}</td>
              <td className="td_info">{applys?.reward}</td>
              <td className="td_info">{applys?.rname ? applys?.rname : "-"}</td>
              <td className="td_info">{applys?.regdate}</td>
              <td className="td_info last"><span className="field_tit"></span>{applys?.status}</td>
              <td className="td_button">
                {applys?.stmode ? (
                  <button onClick={(e) => handleCancelApply(e, applys.idx)} className="button">지원취소</button>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))
            :
            <tr>
              <td style={{textAlign:"center"}} className="no_data" colSpan="8">불러올 데이터가 없습니다.</td>
            </tr>
          }
        </tbody>
      </table>
      <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
    </>
  );
}

export default MyApplyList;
