import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";

import HeaderCompany from '../../components/Layout/HeaderCompany';
import BannerBottom from 'components/Banner/BannerBottom';
import Footer from '../../components/Layout/Footer';

//css
import "css/Board.css";

function NoticeView() {
  const { board_idx } = useParams();

  const [boardData, setBoardData] = useState([]);

	const getNotice = async () => {
		try {
			const sendData = {
        "board_idx" : board_idx
      }
			await axios.post(`${CONFIG.SERVER_HOST}/board_detail`, sendData, CONFIG.header).then((res) => {
				console.log(res.data.results);
				if (res.data.retvalue === 1) {
          setBoardData(res.data.results)
				}else{
          setBoardData([])
        }
			});
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getNotice();
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  

	return (
		<>
      <HeaderCompany />
      <div className="center_wrap page_notice_view board_view">
        <div className="box" >
          <section className="view" >
            {boardData.map((el ,i) => (
              <>
                <article className="top" key={i}>
                  <div className="field_title">{el.board_title}</div>
                  <div className="field_info">
                    <p>등록일 : {el.board_rdate}</p>
                    <p>조회수 : {el.board_hits}</p>
                  </div>
                </article>
                <article className="content" style={{whiteSpace: "pre-wrap" }}>
                {el.board_comment}
                </article>
              </>
            ))}
          </section>
        </div>

        <div className="btn_bottom_area">
          <Link to="/Company/Notice" className="btn white">목록으로</Link>
        </div>
      </div>
      <BannerBottom />
      <Footer />
		</>
	);
}

export default NoticeView;
