/* eslint-disable no-use-before-define */
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import { ChangeMoneyUnit } from '../../../components/Common/Common'
import moment from "moment";

const ExcelDownloadButton = ({ startDate,
  endDate,
  startDate2,
  endDate2,
  option,
  resume,
  exposure,
  varSearchInput,
  type,
  tax,
  em_status,
  search,
  varSearch
}) => {
  // const data = [
  //   {
  //     id: 1,
  //     title: '집에 가고싶어요',
  //     content: '너무 졸려 가고싶어요.'
  //   }, {
  //     id: 2,
  //     title: '오늘은 뭐하지',
  //     content: '퇴근 하고 뭐할까??'
  //   }, {
  //     id: 3,
  //     title: '저녁은 어떤거로?',
  //     content: '저녁은 치킨인가 피자인가 고민이다.'
  //   }
  // ]

  const today = moment().format('YYYY-MM-DD');

  const setFileName = (type) => {
    switch (type) {
      case 1:
        return `일반회원 관리 ${today}`;
      case 2:
        return `기업회원 관리 ${today}`;
      case 3:
        return `헤드헌터 회원 관리 ${today}`;
      case 4:
        return `입사자 관리 ${today}`;
      case 5:
        return `보증기간 내 퇴사 관리 ${today}`;
      case 6:
        return `적립내역 ${today}`;
      case 7:
        return `환전관리 ${today}`;
      case 8:
        return `지원관리 ${today}`;
      case 9:
        return `추천관리 ${today}`;
      default:
        alert("엑셀 파일 다운 오류");
    }
  };

  const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelFileExtension = '.xlsx';
  const excelFileName = setFileName(type);



  const excelDownload = () => {

    const sendData = {
      'startDate': startDate,
      'endDate': endDate,
      'startDate2': startDate2,
      'endDate2': endDate2,
      'option': option,
      'resume': resume,
      'exposure': exposure,
      'varSearchInput': varSearchInput,
      'type': type,
      'tax': tax,
      'em_status': em_status,
      'search': search
    }
    axios.post(`${CONFIG.SERVER_HOST}/get_ExcelData`, sendData, CONFIG.header).then((res) => {

      console.log(res.data);
      if (res.data.results) {
        const excelData = res.data.results;
        let ws;

        if (type === 1) {
          ws = XLSX.utils.aoa_to_sheet([
            
            ['번호', '닉네임', '이름', '가입유형', '휴대폰', '이메일', '이력서 작성여부', '동료평판 개수', '인재검색 노출 여부', '최근접속일', '가입일']
          ]);
          // ws = XLSX.utils.aoa_to_sheet([[
          //   { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
          //   { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
          //   { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
          //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } }
          // ]]);
        } else if (type === 2) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '닉네임', '담당자명', '회사명', '승인상태', '헤딩머니', '최근접속일', '가입일']
          ]);
        } else if (type === 3) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '닉네임', '담당자명', '회사명', '승인상태', '회원등급', '헤딩머니', '최근접속일', '가입일']
          ]);
        } else if (type === 4) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '채용제목', '회사명', '총 보상금', '입사자', '입사 일자', '계산서 발행']
          ]);
        } else if (type === 5) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '채용제목', '회사명', '총 보상금', '입사자', '입사 일자', '퇴사 일자', '계산서 발행']
          ]);
        } else if (type === 6) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '아이디', '이름', '회원유형', '회사명', '구분', '금액', '적립 일자']
          ]);
        } else if (type === 7) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '아이디', '이름', '회사명', '회원유형', '환전 요청 금액', '환전일자', '상태']
          ]);
        } else if (type === 8) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '채용제목', '회사명', '총 보상금', '총 지원자', '미열람', '검토중', '서류합격', '면접합격', '최종합격', '불합격', '입사']
          ]);
        } else if (type === 9) {
          ws = XLSX.utils.aoa_to_sheet([
            ['번호', '채용제목', '총 보상금', '추천인 닉네임', '후보자명', '추천일', '지원 여부', '지원일']
          ]);
        }

        
        excelData.map((data) => {
          if (type === 1) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.mem_nickname,
                  data.mem_name,
                  isNaN(data.mem_type) ? data.mem_type : data.mem_type === 0 ? "일반" : data.mem_type === 1 ? "네이버" : data.mem_type === 2 ? "카카오" : data.mem_type === 3 ? "구글" : "IOS",
                  data.mem_mobile,
                  data.mem_email,
                  data.count_r > 0 ? 'O' : 'X',
                  data.count_p,
                  data.mem_resume === 1 ? 'Y' : 'N',
                  data.mem_ldate,
                  data.mem_rdate,
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 150 },
              { wpx: 200 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 200 },
              { wpx: 200 },
            ]
          } else if (type === 2) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.mem_nickname,
                  data.mem_name,
                  data.mcm_name,
                  data.mem_approval === 1 ? "승인" : "미승인",
                  data.mh_balance === null ? "0원" : `${data.mh_balance}원`,
                  data.mem_ldate,
                  data.mem_rdate
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 50 },
              { wpx: 200 },
              { wpx: 200 }
            ]
          } else if (type === 3) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.mem_nickname,
                  data.mem_name,
                  data.mcm_name,
                  data.mem_approval === 1 ? "승인" : "미승인",
                  data.mem_hunter_type === 1 ? "일반" : "프리미엄",
                  data.mh_balance === null ? "0원" : `${data.mh_balance}원`,
                  data.mem_ldate,
                  data.mem_rdate
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 50 },
              { wpx: 80 },
              { wpx: 200 },
              { wpx: 200 }
            ]
          } else if (type === 4) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.em_title,
                  data.com_name,
                  data.mem_mode === 4 ? `${ChangeMoneyUnit(data.emh_reward_recommend / 10000)}` : `${ChangeMoneyUnit(data.em_reward_recommend / 10000)}` ,
                  data.mem_name,
                  data.ea_idate === null ? "-" : data.ea_idate.substr(0,10),
                  data.tax_bill_YN === 'Y' ? "발행" : "미발행",
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 100 },
              { wpx: 80 }
            ]
          } else if (type === 5) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.em_title,
                  data.com_name,
                  data.mem_mode === 4 ? `${ChangeMoneyUnit(data.emh_reward_recommend / 10000)}` : `${ChangeMoneyUnit(data.em_reward_recommend / 10000)}` ,
                  data.mem_name,
                  data.ea_idate === null ? "-" : data.ea_idate.substr(0,10),
                  data.ea_edate === null ? "-" : data.ea_edate?.substr(0,10),
                  data.tax_bill_YN === 'Y' ? "발행" : "미발행",
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 80 }
            ]
          } else if (type === 6) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.mem_id,
                  data.mem_name,
                  isNaN(data.mem_mode) ? data.mem_mode : data.mem_mode === 0 ? "일반" : data.mem_mode === 1 ? "기업" : data.mem_mode === 2 ? "헤드헌터" : "-" ,
                  data.mcm_name ? data.mcm_name : "-",
                  data.vhs_name,
                  ChangeMoneyUnit(data.mh_paid / 10000),
                  data.mh_rdate===null?"":data.mh_rdate.substr(0,10)
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 80 }
            ]
          } else if (type === 7) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.mem_id,
                  data.mem_name,
                  data.mcm_name,
                  isNaN(data.mem_mode) ? data.mem_mode : data.mem_mode === 0 ? "일반" : data.mem_mode === 1 ? "기업" : data.mem_mode === 2 ? "헤드헌터" : "-",
                  ChangeMoneyUnit(Math.abs(data.mh_paid / 10000)),
                  data.mh_rdate===null?"":data.mh_rdate.substr(0,10),
                  isNaN(data.mh_status) ? data.mh_status : data.mh_status === 1 ? "환전신청" : data.mh_status === 2 ? "승인완료" : data.mh_status === 3 ? "환전완료" : data.mh_status === -1 ? "환전취소" : "-"
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 150 },
              { wpx: 60 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 80 }
            ]
          } else if (type === 8) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.em_title,
                  data.mcm_name,
                  ChangeMoneyUnit(data.em_reward_recommend/10000),
                  data._total,
                  data._unread,
                  data._under_review,
                  data._pass_the_documents,
                  data._interview_pass,
                  data._final_pass,
                  data._fail,
                  data._join
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 50 },
              { wpx: 50 },
              { wpx: 50 },
              { wpx: 50 },
              { wpx: 50 },
              { wpx: 50 },
              { wpx: 50 },
              { wpx: 50 }
            ]
          } else if (type === 9) {
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  data.ROWNUM,
                  data.em_title,
                  ChangeMoneyUnit(data.em_reward_recommend/10000),
                  data.mem_nickname,
                  data.er_name,
                  data.er_rdate,
                  data.ea_rdate === null ? "미지원" : "지원",
                  data.ea_rdate === null ? "-" : data.ea_rdate
                ]
              ],
              { origin: -1 }
            );
            ws['!cols'] = [ // 행 사이즈
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 50 },
              { wpx: 150 }
            ]
          }

          return false;
        });

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelFile = new Blob([excelButter], { type: excelFileType });
        FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
      }
    });

  }

  return (
    <button className="btn" onClick={() => excelDownload()}>엑셀 다운로드</button>
  );
};

export default ExcelDownloadButton;