/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import axios from "axios";
import CONFIG from "configs/configs";
//image
import resetInitImage from "../../img/sub/icon_reset.png";
import { useNavigate, createSearchParams } from "react-router-dom";
//createSearchParams

const SearchLeft = ({param1, param3, param4}) => {
  const navigate = useNavigate();
  const [searchCategoryParams, setSearchCategoryParams] = useState({});
  const [searchString, setSearchString] = useState({
    step1: '',
    step2: '',
    step3: '',
    step4: '',
  });

  //검색결과 초기화 여부 스테이트
  const [Initialization, setInitialization] = useState(false);
  const [searchCategory, setSearchCategory] = useState({
    workCategory: [],
    workCategoryDetail: [],
    region: [],
    educationLevel: [],
    employType: [],
    lauguageCode: [],
    lauguageLevel: [],
    searchCategoryResult: [],
  });

  const [selectCategory, setSelectCategory] = useState({
    workCategory: {
      idx: 0,
      name: ''
    },
    workCategoryDetail: [],
    career: {
      normal: [],
      direct: []
    },
    region: [],
    educationLevel: [],
    employType: [],
    lauguageCode: [],
    lauguageLevel: [],
    age: {
      normal: [],
      direct: []
    },
    re_gender: []
  });

  // 선택된 세부 직무 리스트.
  const [workCategoryDetail, setWorkCategoryDetail] = useState([]);

  // 상단 텝
  const [tabStep, setTabStep] = useState('step1');

  // 경력 직접입력
  const [inputCareerDirect, setInputCareerDirect] = useState({ start: null, end: null });

  // 나이 직접입력
  const [inputAgeDirect, setInputAgeDirect] = useState({ start: null, end: null });

  // 외국어 언어 레벨 선택 값
  const [selectLanguageLevel, setSelectLanguageLevel] = useState({
    top_idx: 0,
    top_name: '',
    idx: 0,
    name: ''
  });

  // 선택 리스트
  const [categorySelectList, setCategorySelectList] = useState([]);

  // 자동 완성 팝업
  const [autoTextShow, setAutoTextShow] = useState(false);
  // 자동 완성 리스트.
  const [autoTextList, setAutoTextList] = useState([]);
  const [onMove, setOnMove] = useState(false);

  const clickAutoText = useRef('');

  useEffect(()=>{
    if(param1 !== null){
      setSearchString({
        step1: '',
        step2: '',
        step3: '',
        step4: '',
      })
    }
  }, [param1]);

  useEffect(()=>{
    if(param3 !== null){
      setCategorySelectList(JSON.parse(param3))
    }else{
       handleClickResetButton();
    }
  }, [param3]);
  useEffect(()=>{
    if(param4){
      setSelectCategory(JSON.parse(param4))
    }
  }, [param4]);

  const handleAddCategorySelectList = (element) => {
    setCategorySelectList(oldArray => [...oldArray, element]);
  }

  // 상단 텝 클릭
  const handleClickTab = (step) => {
    setTabStep(step);
  }

  // 검색 카테고리 가지고 오기.
  const getSearchCategory = async () => {
    await axios.get(`${CONFIG.SERVER_HOST}/_get_search_category`)
      .then((res) => {
        setSearchCategory(res.data);
      })
      .catch((err) => {
        console.debug(err);
      });
  }

  // 직무 선택
  const onClickWorkCategory = (index, name) => {
    // 다시 선택은 제거.
    if (selectCategory.workCategory.idx === index && selectCategory.workCategory.name === name) {
      setSelectCategory((prev) => ({
        ...prev,
        workCategory: {
          idx: 0,
          name: ''
        }
      }));
      return;
    }
    setSelectCategory((prev) => ({
      ...prev,
      workCategory: {
        idx: index,
        name: name
      }
    }));
  }

  // 직무 세부 선택
  const onClickWorkCategoryDetail = useCallback((index, name) => {
    // 다시 선택은 제거.
    var tempList = selectCategory.workCategoryDetail;
    const chIndex = tempList.findIndex((el) => el.idx === index);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        workCategoryDetail: tempList.filter((el) => el.idx !== index)
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step1' && el.index === index);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
    tempList.push({
      top_idx: selectCategory.workCategory.idx,
      top_name: selectCategory.workCategory.name,
      idx: index,
      name: name,
    });
    setSelectCategory((prev) => ({
      ...prev,
      workCategoryDetail: tempList
    }));

    handleAddCategorySelectList({
      step: 'step1',
      index: index,
      name: `${selectCategory.workCategory.name} > ${name}`
    });

  }, [categorySelectList, selectCategory.workCategory.idx, selectCategory.workCategory.name, selectCategory.workCategoryDetail]);

  // 경력 선택.
  const onCLickCareer = useCallback((start, end) => {
    const tmpStr = start === 0 && end === 0 ? `신입` : `경력 > ${start} ~ ${end}년`
    var tempList = selectCategory.career.normal;
    const chIndex = tempList.findIndex((el) => el.start === start && el.end === end);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        career: {
          normal: tempList.filter((el) => (el.start !== start && el.end !== end)),
          direct: prev.career.direct
        }
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step2' && el.ck === 'normal' && el.start === start && el.end === end);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }

    tempList.push({
      start: start,
      end: end,
    });
    setSelectCategory((prev) => ({
      ...prev,
      career: {
        normal: tempList,
        direct: prev.career.direct
      }
    }));
    setInputCareerDirect({ start: null, end: null });

    handleAddCategorySelectList({
      step: 'step2',
      ck: 'normal',
      start: start,
      end: end,
      name: `${tmpStr}`
    });
  }, [categorySelectList, selectCategory.career.normal]);

  // 경력 선택 직접입력.
  const onCLickCareerDirect = () => {
    setInputCareerDirect({ start: '', end: '' });
  }

  // 경력 직접 입력
  const handleOnChangeCareerDirect = (e) => {
    const { name, value } = e.target;
    const tempName = name.replace('career', '');
    setInputCareerDirect(prev => ({
      ...prev,
      [tempName]: value
    }));
  }

  // 경력 직접 입력 추가 버튼
  const handleClickCareerAddButton = () => {
    if (!inputCareerDirect.start || !inputCareerDirect.end) {
      alert('경력을 정확하게 입력해 주세요.');
      return;
    }
    if (Number(inputCareerDirect.start) >= Number(inputCareerDirect.end)) {
      alert('경력을 정확하게 입력해 주세요.');
      return;
    }
    var tempList = selectCategory.career.direct;

    tempList.push({
      start: Number(inputCareerDirect.start),
      end: Number(inputCareerDirect.end),
    });
    setSelectCategory((prev) => ({
      ...prev,
      career: {
        normal: prev.career.normal,
        direct: tempList
      }
    }));
    setInputCareerDirect({ start: '', end: '' });

    const tmpStr = inputCareerDirect.start === 0 && inputCareerDirect.end === 0 ? `신입` : `경력 > ${inputCareerDirect.start} ~ ${inputCareerDirect.end}년`
    handleAddCategorySelectList({
      step: 'step2',
      ck: 'direct',
      start: Number(inputCareerDirect.start),
      end: Number(inputCareerDirect.end),
      name: `${tmpStr}`
    });
  }

  // 근무지역 클릭
  const handleClickRegion = (index, name) => {
    var tempList = selectCategory.region;
    const chIndex = tempList.findIndex((el) => el.idx === index);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        region: tempList.filter((el) => (el.idx !== index)),
      }));

      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step3' && el.index === index);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
    tempList.push({
      idx: index,
      name: name,
    });
    setSelectCategory((prev) => ({
      ...prev,
      region: tempList
    }));

    handleAddCategorySelectList({
      step: 'step3',
      index: index,
      name: `근무지역 > ${name}`
    });
  }

  // 학력 클릭
  const handleClickEducationLevel = (index, name) => {
    var tempList = selectCategory.educationLevel;
    const chIndex = tempList.findIndex((el) => el.idx === index);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        educationLevel: tempList.filter((el) => (el.idx !== index)),
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step4' && el.index === index);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
    tempList.push({
      idx: index,
      name: name,
    });
    setSelectCategory((prev) => ({
      ...prev,
      educationLevel: tempList
    }));

    handleAddCategorySelectList({
      step: 'step4',
      index: index,
      name: `학력 > ${name}`
    });
  }

  // 나이 클릭
  const handleClickAgeClick = (start, end) => {
    var tempList = selectCategory.age.normal;
    const chIndex = tempList.findIndex((el) => el.start === start && el.end === end);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        age: {
          normal: tempList.filter((el) => (el.start !== start && el.end !== end)),
          direct: prev.age.direct
        }
      }));

      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step5' && el.ck === 'normal' && el.start === start && el.end === end);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }

    tempList.push({
      start: start,
      end: end,
    });

    setSelectCategory((prev) => ({
      ...prev,
      age: {
        normal: tempList,
        direct: prev.age.direct
      }
    }));

    setInputAgeDirect({ start: null, end: null });

    handleAddCategorySelectList({
      step: 'step5',
      ck: 'normal',
      start: start,
      end: end,
      name: `${start} ~ ${end}세`
    });
  }

  // 나이 직접 입력 onchange
  const handleChangeAgeInput = (e) => {
    const { name, value } = e.target;
    const tempName = name.replace('age', '');
    setInputAgeDirect(prev => ({
      ...prev,
      [tempName]: value
    }));
  }

  // 나이 직접입력 클릭
  const onClickAgeDirect = () => {
    setInputAgeDirect({ start: '', end: '' });
  }

  // 나이 직접 입력 추가 버튼 클릭
  const handleClickAgeAddButton = () => {
    var tempList = selectCategory.age.direct;
    tempList.push({
      start: Number(inputAgeDirect.start),
      end: Number(inputAgeDirect.end),
    });
    setSelectCategory((prev) => ({
      ...prev,
      age: {
        normal: prev.age.normal,
        direct: tempList
      }
    }));
    setInputAgeDirect({ start: '', end: '' });

    handleAddCategorySelectList({
      step: 'step5',
      ck: 'direct',
      start: Number(inputAgeDirect.start),
      end: Number(inputAgeDirect.end),
      name: `${inputAgeDirect.start} ~ ${inputAgeDirect.end}세`
    });
  }

  // 성별 클릭
  const onClickReGender = (index, name) => {
    var tempList = selectCategory.re_gender;
    const chIndex = tempList.findIndex((el) => el.idx === index);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        re_gender: tempList.filter((el) => el.idx !== index)
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step6' && el.index === index);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
    tempList.push({
      idx: index,
      name: name,
    });
    setSelectCategory((prev) => ({
      ...prev,
      re_gender: tempList
    }));

    handleAddCategorySelectList({
      step: 'step6',
      index: index,
      name: `${name}`
    });
  }

  // 고용형태 클릭
  const onCLickRmployType = (index, name) => {
    var tempList = selectCategory.employType;
    const chIndex = tempList.findIndex((el) => el.idx === index);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        employType: tempList.filter((el) => el.idx !== index)
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step7' && el.index === index);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
    tempList.push({
      idx: index,
      name: name,
    });
    setSelectCategory((prev) => ({
      ...prev,
      employType: tempList
    }));

    handleAddCategorySelectList({
      step: 'step7',
      index: index,
      name: `${name}`
    });
  }

  // 외국어 클릭
  const onCLickLauguageCode = (index, name) => {
    setSelectLanguageLevel({
      top_idx: Number(index),
      top_name: name,
      idx: 0,
      name: ''
    });
  }

  // 외국어 레벨 선택
  const handleOnChangeLauguageLevel = (e) => {
    setSelectLanguageLevel((prev) => ({
      ...prev,
      idx: Number(e.target.value),
      name: e.target.selectedOptions[0].text
    }));
  }

  // 외국어 추가 버튼
  const onClickLanguageLevelAddButton = () => {
    if (Number(selectLanguageLevel.idx) === 0) {
      alert(`언어 레벨을 선택해 주세요.`);
      return;
    }
    var tempList = selectCategory.lauguageLevel;
    const chIndex = tempList.findIndex((el) => el.top_idx === selectLanguageLevel.top_idx);
    if (chIndex >= 0) {
      return;
    }
    tempList.push({
      top_idx: selectLanguageLevel.top_idx,
      top_name: selectLanguageLevel.top_name,
      idx: selectLanguageLevel.idx,
      name: selectLanguageLevel.name
    });
    setSelectCategory((prev) => ({
      ...prev,
      lauguageLevel: tempList
    }));

    handleAddCategorySelectList({
      step: 'step8',
      index: selectLanguageLevel.top_idx,
      name: `${selectLanguageLevel.top_name}`
    });
  }

  // 선택 아이템 삭제 클릭
  const handleCategorySelectRemove = (e) => {
    const removeCategory = categorySelectList[e];
    switch (categorySelectList[e].step) {
      case 'step1': // 직무
        onClickWorkCategoryDetail(removeCategory.index, removeCategory.name);
        break;
      case 'step2': // 경력
        removeStep2(removeCategory);
        break;
      case 'step3': // 근무지역
        handleClickRegion(removeCategory.index, removeCategory.name);
        break;
      case 'step4': // 학력
        handleClickEducationLevel(removeCategory.index, removeCategory.name);
        break;
      case 'step5': // 나이
        removeStep5(removeCategory);
        break;
      case 'step6': // 성별
        onClickReGender(removeCategory.index, removeCategory.name);
        break;
      case 'step7': // 고용형태
        onCLickRmployType(removeCategory.index, removeCategory.name);
        break;
      case 'step8': // 외국어
        removeStep8(removeCategory);
        break;
      case 'step9': // 검색어
        removeStep9(removeCategory, e);
        break;
      case 'text0': // 검색어
        removeText(removeCategory, e);
        break;
      case 'text1': // 검색어
        removeText(removeCategory, e);
        break;
      case 'text2': // 검색어
        removeText(removeCategory, e);
        break;
      case 'text3': // 검색어
        removeText(removeCategory, e);
        break;
      default:
    }
  }

  //removeStep9

  // 경력 리스트 삭제 처리
  const removeStep2 = (element) => {
    const { ck, start, end } = element;
    if (ck === "normal") {
      onCLickCareer(start, end)
      return;
    }

    var tempList = selectCategory.career.direct;
    const chIndex = tempList.findIndex((el) => el.start === Number(start) && el.end === Number(end));
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        career: {
          normal: prev.career.normal,
          direct: tempList.filter((el) => (el.start !== Number(start) && el.end !== Number(end))),
        }
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step2' && el.ck === 'direct' && el.start === Number(start) && el.end === Number(end));
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
  }

  // 나이 삭제 처리.
  const removeStep5 = (element) => {
    const { ck, start, end } = element;
    if (ck === "normal") {
      handleClickAgeClick(Number(start), Number(end))
      return;
    }

    var tempList = selectCategory.age.direct;
    const chIndex = tempList.findIndex((el) => el.start === Number(start) && el.end === Number(end));
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        age: {
          normal: prev.age.normal,
          direct: tempList.filter((el) => (el.step === 'step5' && el.ch === 'direct' && el.start !== Number(start) && el.end !== Number(end))),
        }
      }));
      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step5' && el.ck === 'direct' && el.start === start && el.end === end);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
  }

  // 외국어 삭제 처리.
  const removeStep8 = (element) => {
    const { index } = element;
    var tempList = selectCategory.lauguageLevel;
    const chIndex = tempList.findIndex((el) => el.top_idx === index);
    if (chIndex >= 0) {
      setSelectCategory((prev) => ({
        ...prev,
        lauguageLevel: tempList.filter((el) => el.top_idx !== index),
      }));

      const chIndex2 = categorySelectList.findIndex((el) => el.step === 'step8' && el.index === index);
      if (chIndex2 >= 0) {
        categorySelectList.splice(chIndex2, 1);
        setCategorySelectList(categorySelectList);
      }
      return;
    }
  }

    //Step9 삭제처리
    const removeStep9 = (element, num) => {
      const { index } = element;

      // const chIndex2 = categorySelectList.filter((el) => (el.step !== step && el.index !== index));
      // setCategorySelectList(chIndex2);

      // searchString.map((el) => console.log(el))

      // if(index === 1){
      //   setSearchString({...searchString, step1:''})
      // }else if(index === 2){
      //   setSearchString({...searchString, step2:''})
      // }else if(index === 3){
      //   setSearchString({...searchString, step3:''})
      // }else if(index === 4){
      //   setSearchString({...searchString, step4:''})
      // }

      const chIndex2 = categorySelectList.filter((el, i) => i !== num);
      setCategorySelectList(chIndex2);
      return;
    }

    const removeText = (element, num) => {
      const { index } = element;

      // const chIndex2 = categorySelectList.filter((el) => (el.step !== step && el.index !== index));
      // setCategorySelectList(chIndex2);

      // searchString.map((el) => console.log(el))

      // if(index === 1){
      //   setSearchString({...searchString, step1:''})
      // }else if(index === 2){
      //   setSearchString({...searchString, step2:''})
      // }else if(index === 3){
      //   setSearchString({...searchString, step3:''})
      // }else if(index === 4){
      //   setSearchString({...searchString, step4:''})
      // }

      const chIndex2 = categorySelectList.filter((el, i) => i !== num);
      setCategorySelectList(chIndex2);
      return;
    }

  // 초기화 버튼 클릭.
  const handleClickResetButton = () => {
    setSelectCategory({
      workCategory: {
        idx: 0,
        name: ''
      },
      workCategoryDetail: [],
      career: {
        normal: [],
        direct: []
      },
      region: [],
      educationLevel: [],
      employType: [],
      lauguageCode: [],
      lauguageLevel: [],
      age: {
        normal: [],
        direct: []
      },
      re_gender: []
    });
    setSearchCategoryParams({});
    setWorkCategoryDetail([]);
    setTabStep('step1');
    setInputCareerDirect({ start: null, end: null });
    setInputAgeDirect({ start: null, end: null });
    setSelectLanguageLevel({
      top_idx: 0,
      top_name: '',
      idx: 0,
      name: ''
    });
    setCategorySelectList([]);

    /**setSearchString 초기화 */
    setSearchString({
      step1: '',
      step2: '',
      step3: '',
      step4: '',
    });

    /**초기화 스테이트 true */
    if(param3){
      setInitialization(true);
    }
  };

  // 자동 완성 클릭.
  const handleClickAutoText = (text) => {
    handleSearchStringOnChange({
      target: {
        name: 'step1',
        value: text
      }
    });

    setAutoTextList([]);
    setAutoTextShow(false);
    clickAutoText.current = text
  }

  // 최초 로딩.
  useEffect(() => {
    getSearchCategory();
  }, []);

  // 직무 선택 되었을때
  useEffect(() => {
    if (selectCategory.workCategory) {
      setWorkCategoryDetail(searchCategory.workCategoryDetail.filter((e) => e.vwc_idx === selectCategory.workCategory.idx));
    }
  }, [searchCategory.workCategoryDetail, selectCategory.workCategory]);

  // 상위에 선택 카테고리 전달.
  useEffect(() => {
    setSearchCategoryParams(selectCategory);
  }, [selectCategory]);

  useEffect(() => {
    if (clickAutoText.current === searchString.step1) {
      return;
    }
    const changeString = searchString.step1;
    const searchCategoryResult = searchCategory.searchCategoryResult;

    if (changeString.length === 0) {
      setAutoTextShow(false);
      setAutoTextList([]);
      return;
    }

    const tempAutoTextList = searchCategoryResult.filter((el) => {
      return el.showtext.includes(changeString.toUpperCase());
    })

    if (tempAutoTextList.length > 0) {
      setAutoTextList(tempAutoTextList);
      setAutoTextShow(true);
    } else {
      setAutoTextShow(false);
    }
  }, [searchCategory.searchCategoryResult, searchString.step1]);


  //검색 클릭함수
  const onSearcdClick =(e)=>{
    const dataArr = [searchString.step1, searchString.step2, searchString.step3, searchString.step4];
    dataArr.map((el, i)=>{
      if(el !== ''){
        const chkCT = categorySelectList.filter((item) => item?.originStep === `step${i+1}`);
        if(chkCT.length <= 0){
          const step = `text${i}`;
          setCategorySelectList(oldArray => [...oldArray, {step : step, index : i,  name: `${setTitle(i)}${el}`, originName : el, originStep : `step${i+1}` }]);
        }else{
          setCategorySelectList(categorySelectList.map(
            item => item?.originStep === `step${i+1}`
            ? {...item, name: `${setTitle(i)}${el}`, originName : el}
            : item
          ))
        }
      }
    }).filter(element => element);
    
    setOnMove(true);
  };

//전체보기
const onSearcdAll =(e)=>{
  handleClickResetButton();
  setOnMove(true);
}

  const setTitle = (category) => {
    switch (category) {
      case 0:
        return "검색어 > ";
      case 1:
        return "이직 희망 회사 > ";
      case 2:
        return "경력 회사 > ";
      case 3:
        return "자격증 > ";
      default:
    }
  };

  const setStep = (step) => {
    switch (step) {
      case 0:
        return "step1";
      case 1:
        return "step2";
      case 2:
        return "step3";
      case 3:
        return "step4";
      default:
    }
  }
  const moveUrl =() =>{
    setOnMove(false);
    //handleClickResetButton();
    //검색조건 오브젝트 만들기

    let obj = {};

    categorySelectList.forEach((element, index) => {
      if(String(element.step).includes('text')){
        obj[element?.originStep] = element?.originName;
      }
    });


      navigate({
        pathname: "/Company/ComResource/SearchMain",
        search: createSearchParams({
            param1: `${JSON.stringify(obj)}`,
            param2: `${JSON.stringify(searchCategoryParams)}`,
            param3: `${JSON.stringify(categorySelectList)}`,
            param4: `${JSON.stringify(selectCategory)}`,
            param5: `1`,
        }).toString()
      });
  }


  const setArr = (strObj) =>{
    let strArr = Object.values(strObj);
    strArr.map((el, i)=>{
      if(el){
        const chIndex = categorySelectList.findIndex((dl) => dl.step === 'step9' && dl.name === el );
        if(chIndex === -1){
          setCategorySelectList(oldArray => [...oldArray, {step : 'step9', index : i+1,  name:el}]);
        }
      }
    });
    setOnMove(true);
  };

  useEffect(()=>{
    if(onMove){
      moveUrl();
    }
    
  }, [onMove]);

  const handleSearchStringOnChange = (e) => {
    const {name, value} = e.target;
    setSearchString((prev) => ({
      ...prev,
      [name] : value
    }));
  };


  useEffect(() =>{
    /**초기화 스테이트 true 인경우 재 검색 */
    if(Initialization){
      if(param3){
        setInitialization(false);
        navigate({
          pathname: "/Company/ComResource/SearchMain",
          search: createSearchParams({
              param1: `${JSON.stringify(searchString)}`,
              param2: `${JSON.stringify(searchCategoryParams)}`,
              param3: `${JSON.stringify(categorySelectList)}`,
              param4: `${JSON.stringify(selectCategory)}`,
              param5: `1`,
          }).toString()
        });
      }
    }
  }, [Initialization]);

  // console.log(categorySelectList);
  return (
    <>
      <section className="talent_search">
        <article className="search">
          <h3 className="title_field">최고의 전문가나 인재를 직접 찾아보세요!</h3>
          <div className="input_wrap">
            <div className="has_auto_text">
              <input type="text" placeholder="직무, 산업, 이력서 제목 및 내용을 입력하세요."
                name="step1"
                value={searchString.step1}
                onChange={(e) => handleSearchStringOnChange(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearcdClick()
                  }
                }}
              />
              {/* ▼▼▼ 22-09-26 검색어 자동완성 */}
              <div className={autoTextShow ? `auto_text_area active` : `auto_text_area`}>{/* active로 hide,show */}
                <div className="auto_text_wrap scroll">
                  {autoTextList.length > 0 && autoTextList.map((e, i) => {
                    const searchStr = searchString.step1.toUpperCase();
                    const str = e.showtext.replace(searchStr, `<span className="txt_blue">${searchStr}</span>`);
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} key={i} onClick={() => handleClickAutoText(e.text)}><div dangerouslySetInnerHTML={{ __html: str }}></div></a>
                    );
                  })}
                </div>
                <div className="auto_text_close_wrap">
                  <button type="button" onClick={() => {
                    setAutoTextList([]);
                    setAutoTextShow(false);
                  }}>닫기</button>
                </div>
              </div>
            </div>
            <input type="text" placeholder="이직 희망 회사명"
              name="step2"
              value={searchString.step2}
              onChange={(e) => handleSearchStringOnChange(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearcdClick()
                }
              }}
            />
            <input type="text" placeholder="경력회사명"
              name="step3"
              value={searchString.step3}
              onChange={(e) => handleSearchStringOnChange(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearcdClick()
                }
              }}
            />
            <input type="text" placeholder="자격증명"
              name="step4"
              value={searchString.step4}
              onChange={(e) => handleSearchStringOnChange(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearcdClick()
                }
              }}
            />
            <div className="btn" onClick={() => onSearcdClick()}>검색</div>
            <div className="btn" onClick={() => onSearcdAll()}>전체보기</div>
          </div>
        </article>
        <article className="search_detail">
          <ul className="choose_detail_list">
            <li className={tabStep === 'step1' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step1')}>직무</li>
            <li className={tabStep === 'step2' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step2')}>경력</li>
            <li className={tabStep === 'step3' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step3')}>근무지역</li>
            <li className={tabStep === 'step4' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step4')}>학력</li>
            <li className={tabStep === 'step5' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step5')}>나이</li>
            <li className={tabStep === 'step6' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step6')}>성별</li>
            <li className={tabStep === 'step7' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step7')}>고용형태</li>
            <li className={tabStep === 'step8' ? `detail_type active` : `detail_type`} onClick={() => handleClickTab('step8')}>외국어</li>
          </ul>
          <section className="choose_detail_area">
            {tabStep === 'step1' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll left">
                  {searchCategory.workCategory && searchCategory.workCategory.map((e, i) => {
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.workCategory.idx === e.vwc_idx ? `btn_detail_selector active` : `btn_detail_selector`} key={i} onClick={() => onClickWorkCategory(e.vwc_idx, e.vwc_name)}>{e.vwc_name}</a>
                    );
                  })}
                </div>
                <div className="scroll_wrap scroll right">
                  {workCategoryDetail && workCategoryDetail.map((e, i) => {
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.workCategoryDetail.findIndex((el) => el.idx === e.vwd_idx) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} key={i} onClick={() => onClickWorkCategoryDetail(e.vwd_idx, e.vwd_name)}>{e.vwd_name}</a>
                    );
                  })}
                </div>
              </article>
            }

            {/* 경력 */}
            {tabStep === 'step2' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll left">
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.career.normal.findIndex((el) => Number(el.start) === 0 && Number(el.end) === 0) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onCLickCareer(0, 0)}>신입</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.career.normal.findIndex((el) => Number(el.start) === 1 && Number(el.end) === 3) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onCLickCareer(1, 3)}>1 ~ 3년</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.career.normal.findIndex((el) => Number(el.start) === 3 && Number(el.end) === 5) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onCLickCareer(3, 5)}>3 ~ 5년</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.career.normal.findIndex((el) => Number(el.start) === 5 && Number(el.end) === 10) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onCLickCareer(5, 10)}>5 ~ 10년</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={inputCareerDirect.start !== null || inputCareerDirect.end !== null ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onCLickCareerDirect()}>직접입력</a>
                </div>
                {(inputCareerDirect.start !== null || inputCareerDirect.end !== null) &&
                  <div className="scroll_wrap scroll right has_input">
                    <div className="input_wrap">
                      <input type="text" name="careerstart" value={inputCareerDirect.start} onChange={(e) => handleOnChangeCareerDirect(e)} />&nbsp;~&nbsp;<input type="text" name="careerend" value={inputCareerDirect.end} onChange={(e) => handleOnChangeCareerDirect(e)} />&nbsp;년<a href={() => false} style={{ cursor: "pointer" }} className="btn blue" onClick={() => handleClickCareerAddButton()}>추가</a>
                    </div>
                  </div>
                }
              </article>
            }

            {/* 근무지역, 학력 */}
            {tabStep === 'step3' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll all">
                  {searchCategory.region && searchCategory.region.filter((e) => e.vr_code !== 0).map((e, i) => {
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.region.findIndex((el) => el.idx === e.vr_code) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} key={i} onClick={() => handleClickRegion(e.vr_code, e.vr_name)}>{e.vr_name}</a>
                    );
                  })
                  }
                </div>
              </article>
            }

            {/* 근무지역, 학력 */}
            {tabStep === 'step4' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll all">
                  {searchCategory.educationLevel && searchCategory.educationLevel.map((e, i) => {
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.educationLevel.findIndex((el) => el.idx === e.vel_idx) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} key={i} onClick={() => handleClickEducationLevel(e.vel_idx, e.vel_name)}>{e.vel_name}</a>
                    );
                  })}
                </div>
              </article>
            }

            {/* 나이 */}
            {tabStep === 'step5' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll left">
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.age.normal.findIndex((el) => Number(el.start) === 0 && Number(el.end) === 25) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => handleClickAgeClick(0, 25)}>~ 25세</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.age.normal.findIndex((el) => Number(el.start) === 26 && Number(el.end) === 30) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => handleClickAgeClick(26, 30)}>26 ~ 30세</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.age.normal.findIndex((el) => Number(el.start) === 31 && Number(el.end) === 35) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => handleClickAgeClick(31, 35)}>31 ~ 35세</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.age.normal.findIndex((el) => Number(el.start) === 36 && Number(el.end) === 40) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => handleClickAgeClick(36, 40)}>36 ~ 40세</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={inputAgeDirect.start !== null || inputAgeDirect.end !== null ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onClickAgeDirect()}>직접입력</a>
                </div>
                {(inputAgeDirect.start !== null || inputAgeDirect.end !== null) &&
                  <div className="scroll_wrap scroll right has_input">
                    <div className="input_wrap">
                      <input type="text" name="agestart" value={inputAgeDirect.start} onChange={(e) => handleChangeAgeInput(e)} />&nbsp;~&nbsp;<input type="text" name="ageend" value={inputAgeDirect.end} onChange={(e) => handleChangeAgeInput(e)} />&nbsp;세<a href={() => false} style={{ cursor: "pointer" }} className="btn blue" onClick={() => handleClickAgeAddButton()}>추가</a>
                    </div>
                  </div>
                }
              </article>
            }

            {/* 성별 */}
            {tabStep === 'step6' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll all">
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.re_gender.findIndex((el) => el.idx === 0) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onClickReGender(0, '남자')}>남자</a>
                  <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.re_gender.findIndex((el) => el.idx === 1) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} onClick={() => onClickReGender(1, '여자')}>여자</a>
                </div>
              </article>
            }

            {/* 고용형태 */}
            {tabStep === 'step7' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll all">
                  {searchCategory.employType && searchCategory.employType.map((e, i) => {
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} className={selectCategory.employType.findIndex((el) => el.idx === e.emt_idx) >= 0 ? `btn_detail_selector active` : `btn_detail_selector`} key={i} onClick={() => onCLickRmployType(e.emt_idx, e.emt_name)}>{e.emt_name}</a>
                    );
                  })}
                </div>
              </article>
            }
            {/* 외국어 */}
            {tabStep === 'step8' &&
              <article className="choose_detail_type">
                <div className="scroll_wrap scroll left">
                  {searchCategory.lauguageCode && searchCategory.lauguageCode.map((e, i) => {
                    return (
                      <a href={() => false} style={{ cursor: "pointer" }} className={selectLanguageLevel.top_idx === e.vlc_code ? `btn_detail_selector active` : `btn_detail_selector`} key={i} onClick={() => onCLickLauguageCode(e.vlc_code, e.vlc_name)}>{e.vlc_name}</a>
                    )
                  })}
                </div>
                <div className="scroll_wrap scroll right has_input">
                  <div className="input_wrap">
                    <select name="lauguageLevel" onChange={(e) => handleOnChangeLauguageLevel(e)} value={selectLanguageLevel.idx}>
                      <option value="0">선택</option>
                      {searchCategory.lauguageLevel && searchCategory.lauguageLevel.map((e, i) => {
                        return (
                          <option key={i} value={e.lal_idx}>{e.lal_name}</option>
                        )
                      })}
                    </select>
                    <a href={() => false} style={{ cursor: "pointer" }} className="btn blue" onClick={() => onClickLanguageLevelAddButton()}>추가</a>
                  </div>
                </div>
              </article>
            }
          </section>
          <section className="selected_area">
            <h1>선택한 검색조건</h1>
            <div className="selected_wrap">
              <section className="scroll">
                {categorySelectList && categorySelectList.map((el, i) => {
                  return (
                    <article className="selected" key={i}>{el.name}<a href={() => false} style={{ cursor: "pointer" }} className="btn_selected_delete" onClick={() => handleCategorySelectRemove(i)}> </a></article>
                  );
                })}
              </section>
              <a href={() => false} style={{ cursor: "pointer" }} className="btn_selected_reset" onClick={() => handleClickResetButton()}><img src={resetInitImage} alt="" />초기화</a>
            </div>


            <div className="btn_bottom_area talent_reset_mobile">
              <a href={() => false} style={{ cursor: "pointer" }} className="btn white" onClick={() => handleClickResetButton()}><img src={resetInitImage} alt="" />초기화</a>
            </div>
          </section>
        </article>
      </section>
    </>
  )
}

export default SearchLeft
