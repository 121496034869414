/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

//이미지
import recruit_search from "img/main/recruit_search.png";

/*메인>채용정보 상단*/
function RecruitmanListTop({
  varEmployType,
  setEmployType,
  onSearchingHandler,
  varSearchString,
  setSearchString,
  onInputSearchString,
}) {
  useEffect(() => {
    const MainButtonFeild = document.querySelector(".btn_field");
    const MainTabElements = MainButtonFeild.querySelectorAll(".button");
    //console.log(MainTabElements)
    MainTabElements[varEmployType].parentNode.classList.add("active");
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectEmployType = (e, ino) => {
    const MainButtonFeild = document.querySelector(".btn_field");
    const MainTabElements = MainButtonFeild.querySelectorAll(".button");
    for (var i = 0; i < MainTabElements.length; i++) {
      // console.log("==========="+MainTabElements[i].parentNode)
      if (MainTabElements[i].parentNode.classList.contains("active"))
        MainTabElements[i].parentNode.classList.remove("active");
    }
    MainTabElements[ino].parentNode.classList.add("active");
    setEmployType(ino);
  };

  return (
    <article className="search_area">
      <ul className="btn_field">
        <li className="active">
          <button className="button" onClick={(e) => onSelectEmployType(e, 0)}>
            전체
          </button>
        </li>
        <li>
          <button className="button" onClick={(e) => onSelectEmployType(e, 1)}>
            공개채용
          </button>
        </li>
        <li>
          <button className="button" onClick={(e) => onSelectEmployType(e, 2)}>
            비공개채용
          </button>
        </li>
        <li>
          <button className="button" onClick={(e) => onSelectEmployType(e, 3)}>
            실시간 채용관
          </button>
        </li>
      </ul>
      <div className="search">
        <button onClick={(e) => onSearchingHandler(e)}>
          <img
            className="view_pc"
            src={recruit_search}
            srcSet={`${require("img/main/recruit_search.png")} 1x, ${require("img/main/recruit_search@2x.png")} 2x`}
            alt="recruit_search"
          />
        </button>
        <input
          type="text"
          placeholder="검색어를 입력해주세요."
          value={varSearchString === undefined ? "" : varSearchString}
          onChange={(e) => onInputSearchString(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearchingHandler(e)
            }
          }}
        />
        {/* <Link  to={'/MyRecruit/MainList'} state={{sMode:0,sString:varSearchString}} className="Button"></Link> */}
      </div>
    </article>
  );
}

export default RecruitmanListTop;
