import React from "react";

//이미지
import icon_repu_pop_write from "img/sub/repu_pop_write.png";
// import { Link } from 'react-router-dom';

const PopAcqRepuStart = ({
  setAnonymousPop,
  setAnonymousYN
}) => {


  //확인
  const accept = (e) => {
    setAnonymousYN(true);
    setAnonymousPop(false);
  };
  //취소
  const cancel = (e) => {
    setAnonymousYN(false);
    setAnonymousPop(false);
  };

  return (
    <div className={"popup active"}>
      <div className="popup_wrap popup01 pop_repu">
        
        <section className="content_wrap">
          <article className="notice_area">
            <div className="icon">
              <img src={icon_repu_pop_write} alt="icon_pw" />
            </div>
            <p className="title_field">
             익명으로 진행하시겠습니까?
            </p>
          </article>
        </section>
        <section className="button_wrap">
          <div className="big_button_wrap two">
            {/* <Link to={"/main"} className="btn white">취소</Link> */}
            <button className="btn" onClick={accept}>
              확인
            </button>
            <button className="btn" onClick={cancel}>
              취소
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PopAcqRepuStart;
