/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../../configs/configs"; 
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';


function Withdrawal_Member_Detail() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const { mem_idx } = useParams();
  const [varUserData, setUserData] = useState({});

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //회원정보 호출
  useEffect(async () => {
    if (mem_idx > 0) {
      const params = new URLSearchParams();
      params.append("mem_idx", mem_idx);
      await axios
        .post(`${CONFIG.SERVER_HOST}/getUserDetail`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            if (res.data.members.length > 0) {
              const datam = res.data.members[0];
              //회원정보
              setUserData(datam);
            }
          }
        });
    }
  }, []);

    return (
        <>
            <Titlebar />
            <Tabbar tabType="users"/>
            <Menu menuType="users" menu="withdrawal_list"/>
            <div id="contents" className="ad_contents">
              <div className="tit">탈퇴 회원 관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>회원목록<img src={arrImage} alt=""/>탈퇴 회원 관리
                </div>
              </div>
                <div className="content">
                <p style={{ marginTop: "20px" }} className="table_title">- 회원 정보</p>
                    {/* 내부 메뉴 */}
                    <div className="main-box">
                      <table className="write"> 
                        <tbody>
                          <tr>
                            <th>아이디</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>{varUserData.mem_id}</td>
                          </tr>
                          <tr>
                            <th>이름</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>
                              {varUserData.mem_name}
                            </td>
                          </tr>
                          <tr>
                            <th>휴대폰</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>
                              {varUserData.mem_mobile}
                            </td>
                          </tr>
                          <tr>
                            <th>이메일</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>
                            {varUserData.mem_email}
                            </td>
                          </tr>
                          <tr>
                            <th>개인정보 유효기간 선택</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>
                              {varUserData.mem_expiration === 0 ? "회원탈퇴시" : "선택 안함"}
                            </td>
                          </tr>
                          <tr>
                            <th>회원유형</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>
                              {varUserData.mem_mode === 0 ? "일반회원" : varUserData.mem_mode === 1 ? "기업회원" : "헤드헌터 회원"}
                            </td>
                          </tr>
                          <tr>
                            <th>탈퇴사유</th>
                            <td colSpan={3} style={{ textAlign: "left" }}>
                              {varUserData.mem_reason}
                            </td>
                          </tr>
                          <tr>
                            <th>가입일자</th>
                            <td style={{ textAlign: "left" }}>
                              {varUserData.mem_rdate}
                            </td>
                            <th>탈퇴일</th>
                            <td style={{ textAlign: "left" }}>{varUserData.mem_edate}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                    <div className="btn-center">
                      <button className="btn-danger"><Link to={"/admin/withdrawal_member_list"} style={{ color: "#fff" }}>목록</Link></button>
                  </div>                    
                </div>
            </div>
        </>
    )
}

export default Withdrawal_Member_Detail;