/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { ChangeSearchingDateFormat } from '../Common/Common';

//이미지
// import search from "img/sub/search.png";
import CustomDatePicker from "components/Common/CustomDatePicker";

/*채용 지원 현황*/
function MyRecommendSearch({ varTabs, setPageNum, varSearchData, setSearchData, readRecommendList }) {

  //문자형 날짜를 date형으로 변경
  const [varStartDate, setStartDate] = useState(varSearchData.sdate === null ? null : new Date(varSearchData.sdate));
  const [varEndDate, setEndDate] = useState(varSearchData.edate === null ? null : new Date(varSearchData.edate));
  const [varSearchOption, setSearchOption] = useState(varSearchData.searchoption);
  const [varSearchString, setSearchString] = useState(varSearchData.searchstring);

  const onSearchOptionHandler = (e) => {
    setSearchOption(e.target.value);
    setSearchData({ ...varSearchData, searchoption: e.target.value });
  }
  const onSearchStringHandler = (e) => {
    setSearchString(e.target.value);
    setSearchData({ ...varSearchData, searchstring: e.target.value });
  }
  // 검색 ~
  const onExecuteSearchingHandler = () => {
    setPageNum(1);
    readRecommendList();
  }
  const onChangeDateHandler = (ino, date) => {
    let dbDate = ChangeSearchingDateFormat(date);
    if (ino === 0) {
      setStartDate(date);
      setSearchData({ ...varSearchData, sdate: dbDate });
    } else {
      setEndDate(date);
      setSearchData({ ...varSearchData, edate: dbDate });
    }
    /*
    console.log(date);
    if(date === null || date === "") {
      if(ino === 0) {
        setStartDate(null);
      } else {
          setEndDate(null);
      }
      return;
    }
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    const curDate = year + '-' + month  + '-' + day; 
    const ndate = new Date(curDate);
    //console.log(ndate);
    if(ino === 0) {
        setStartDate(ndate);
    } else {
        setEndDate(ndate);
    }
    */
  }

  useEffect(() => {
    setStartDate(null)
    setEndDate(null)
    setSearchOption("all")
    setSearchString("")
    setSearchData({
      sdate: null,
      edate: null,
      searchoption: "all",
      searchstring: ""
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabs, setSearchData])

  return (
    <>
      <div className="list_top">
        <div className="search_wrap">
          {/*권유일자*/}
          <div className="wrap">
            <p className="title_field">추천일자</p>
            <CustomDatePicker
              data={varSearchData}
              varStartDate={varStartDate}
              varEndDate={varEndDate}
              onChangeDateHandler={onChangeDateHandler}
            />
          </div>
          {/*검색*/}
          <fieldset className="list_search_area">
            <select className="style_select" value={varSearchOption} onChange={(e) => onSearchOptionHandler(e)}>
              <option value={"all"}>전체</option>
              <option value={"em_title"}>채용 제목</option>
              <option value={"mcm_name"}>회사명</option>
              <option value={varTabs === 0 ? "er_name" : "mem_name"}>{varTabs === 0 ? "후보자" : "추천인"}</option>
            </select>
            <div className="search">
              <input type="text" value={varSearchString} onChange={(e) => onSearchStringHandler(e)} placeholder="검색어를 입력해주세요" onKeyDown={(e) => { if (e.key === 'Enter') onExecuteSearchingHandler(e) }} />
              {/* <button className="btn_search"><img src={search} alt="" /></button> */}
            </div>
            <button onClick={() => onExecuteSearchingHandler()} className="btn">조회</button>
          </fieldset>

        </div>
      </div>
    </>
  );
}

export default MyRecommendSearch;
