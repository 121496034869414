/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { resetNavMenu,resetNavSubMenu } from "../../components/Common/Common";
import AnnouncementListC from "../../components/Announcement/AnnouncementListC";

const AnnouncementList = (/* { setPageTitle } */) => {
  useEffect(() => {
    /* setPageTitle("공고/지원자 관리"); */
    resetNavMenu(1);
    resetNavSubMenu(1,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="center_wrap company">
      <section className="page_announcement list">
        <h2 className="sub_content_title indent">공고/지원자 관리</h2>
        <ul className="tab01 blue col2">
          <li className="active">
            <a>공고관리</a>
          </li>
          <li>
            <Link
              to={"/Company/comAnnouncement/SupportList"}
              state={{ ComAnnouncement: 1 }}
            >
              지원자 관리
            </Link>
          </li>
        </ul>
        <AnnouncementListC />
      </section>
    </div>
  );
};

export default AnnouncementList;
