/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import _ from 'lodash';
import ico_prev from '../../../img/sub/ico_prev.png';
import ico_next from '../../../img/sub/ico_next.png';

function Pagination ({ itemsCount, pageSize, currentPage, onPgaeChange, start, end, handleMovePage}){

    const pageCount = Math.ceil(itemsCount / pageSize);
    if(pageCount === 1) return null;

    const pages = _.range(1, pageCount +1);

    return(

        <div  id="paging"> 
        <button  className="btn_prev" onClick={()=> {
            if(currentPage ===1) return alert('첫번째 페이지');
            if(currentPage % 10 === 1){
                const s= start -10;
                const e= end -10;
                handleMovePage(s, e);
            }
            onPgaeChange(currentPage -1 );
        }
        }> 
            <img src={ico_prev} alt=""/>
        </button>
        {pages.map (page => (
            <button  key={page} 
            className={page === currentPage ? "active" : ""} 
            style={{ cursor: "pointer", padding: 0}}
            onClick={()=> {
                if(currentPage % 5 === 0){
                    const s= start +5;
                    const e= end +5;
                    handleMovePage(s, e);
                }
                onPgaeChange(page)
            }               
            }
            >
              {page} 
            </button>
        ))}
         
        <button className="btn_next" onClick={()=> {
           if(currentPage ===pageCount) return alert('마지막 페이지');
            if(currentPage % 10 === 0){
                const s= start +10;
                const e= end +10;
                handleMovePage(s, e);
            }
            onPgaeChange(currentPage +1 );
        }
        }> 
            <img src={ico_next} alt=""/>
        </button>  
        </div>
    )
}

export default Pagination;