/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'


function Urllink() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [urlNum, setUrlNum] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [useMode, setUseMode] = useState(1);
  const [addUrl, setAddUrl] = useState(false);
  const [linkData, setLinkData] = useState([]);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  //url 리스트 가져오기
  useEffect(() => {
    axios.get(`${CONFIG.SERVER_HOST}/geturllink`).then((res) => {
      if (res.data.retvalue === 1) {
        const results = res.data.results;
        const data = results.map((data, i) => ({
          order: i + 1,
          link_idx: data.link_idx,
          link_num: data.link_num,
          link_rdate: data.link_rdate,
          link_url: data.link_url,
          link_status: data.link_status
        }));
        setLinkData([...data]);
        // setLinkData(results)
      } else {
        setLinkData([])
      }
    });
  }, [])


  //링크NUM
  const urlNameHandler = (e, idx) => {
    const { name, value } = e.target
    const finsIndex = linkData.findIndex((el) => el.link_idx === idx);
    let copyData = [...linkData]
    if (finsIndex === linkData.length + 1) {
      setUrlNum(Number(e.target.value));
    } else {
      copyData[finsIndex] = { ...copyData[finsIndex], [name]: Number(value) }
      setLinkData(copyData)
    }
  };

  //링크URL
  const urlLinkHandler = (e, idx) => {
    const { name, value } = e.target
    const finsIndex = linkData.findIndex((el) => el.link_idx === idx);
    let copyData = [...linkData]
    if (finsIndex === linkData.length + 1) {
      setUrlLink(e.target.value);
    } else {
      copyData[finsIndex] = { ...copyData[finsIndex], [name]: value }
      setLinkData(copyData)
    }

  };

  //사용유무
  const urlUseHandler = (e, idx) => {
    const { value } = e.target
    const finsIndex = linkData.findIndex((el) => el.link_idx === idx);
    let copyData = [...linkData]
    if (finsIndex === linkData.length + 1) {
      setUseMode(e.target.value);
    } else {
      copyData[finsIndex] = { ...copyData[finsIndex], link_status: Number(value) }
      setLinkData(copyData)
    }
  };

  const postUrlLink = (e) => {
    // 추가 => 저장 
    if (addUrl) {
      if (!urlNum) {
        alert("순서를 등록해주세요")
        return;
      } else if (!urlLink) {
        alert("링크URL을 입력해주세요")
        return;
      }

      const sendData = {
        'urlNum': urlNum,
        'urlLink': urlLink,
        'useMode': useMode,
      }

      axios.post(`${CONFIG.SERVER_HOST}/urllink`, sendData, CONFIG.header).then((res) => {
        if (res.data.retvalue === 1) {
          alert(res.data.message)
          setAddUrl(false);
        } else {
          alert(res.data.message)
        }
      });
    } else {
      // 수정 => 저장
      const sendData = {
        'link_data': linkData
      }

      axios.post(`${CONFIG.SERVER_HOST}/urllink_update`, sendData, CONFIG.header).then((res) => {
        if (res.data.value === 1) {
          alert(res.data.message)
        } else {
          alert(res.data.message)
        };
      })
    }
  }




  //계좌 추가
  const AddRow = () => {
    if (addUrl) {
      alert('추가한 URL을 먼저 저장하신 후에 다시 진행해주세요.');
      return false;
    }
    const todo = { order: linkData.length + 1, linkNum: 0, urlLink: "", useMode: 1, link_status: 1 };
    setLinkData(linkData.concat(todo));
    setAddUrl(true);
  }

  //계좌 삭제
  const deleteHandler = idx => {

    if (!window.confirm('삭제하시겠습니까?')) {
      return false;
    }
    // 추가 클릭해서 input 창 열고 저장 안 누르고 삭제할 경우 
    if (addUrl) {
      setLinkData(linkData.pop());
      setAddUrl(false)
    }
    if (idx > 0) {
      axios.post(`${CONFIG.SERVER_HOST}/deleteurllink?link_idx=${idx}`).then(res => {
        alert('삭제되었습니다.')
      });
    }
    setLinkData(linkData.filter(data => data.link_idx !== idx));
  }


  return (
    <>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="urllink" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          링크 관리
          <div className="path">
            <img src={iconhome} alt="" />HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />링크 관리<img src={arrImage} alt="" />링크 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="list">
              <colgroup>
                <col width="7%"></col>
                <col width="10%"></col>
                <col width="35%"></col>
                <col width="18%"></col>
                <col width="20%"></col>
                <col width="10%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>순서</th>
                  <th>링크URL</th>
                  <th>등록일</th>
                  <th>사용여부</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {linkData.map((el, i) => (
                  <tr key={i}>
                    <td><p>{el.order}</p></td>
                    <td><p><input type="text" name="link_num" value={el.link_num ? el.link_num : ""} onChange={(e) => urlNameHandler(e, el.link_idx)} /></p></td>
                    <td className="ellipsis"><p><input type="text" name="link_url" value={el.link_url ? el.link_url : ""} onChange={(e) => urlLinkHandler(e, el.link_idx)} /></p></td>
                    <td><p>{el.link_rdate}</p></td>
                    <td className="write-radio">
                      <input type="radio" id="use" name={`url_use${i}`} value={1} onChange={(e) => urlUseHandler(e, el.link_idx)} checked={el.link_status === 1 ? true : false} />
                      <label htmlFor="use">사용</label>
                      <input type="radio" id="unused" name={`url_use${i}`} value={0} onChange={(e) => urlUseHandler(e, el.link_idx)} checked={el.link_status === 0 ? true : false} />
                      <label htmlFor="unused">미사용</label>
                    </td>
                    <td>
                      <button className="btn line black" onClick={() => deleteHandler(el.link_idx)}>삭제</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="btn-center">
            <button className="btn" onClick={AddRow}>추가</button>
            <button onClick={postUrlLink}>저장</button>
          </div>
        </div>
      </div>
    </>
  )
}
export default Urllink;
