/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const AddCareer = ({ highLookup }) => {

  return (
    <>
      {
        highLookup?.additional_career_year && highLookup?.additional_career_date ?
          <>
            {highLookup?.additional_career_year ? `경력 ${highLookup?.additional_career_year}년 ` : ''}
            {highLookup?.additional_career_date ? `${!highLookup?.additional_career_year ? '경력 ' : ''}${highLookup?.additional_career_date} 개월` : null}
          </>
          :
          <>
            신입
          </>
      }
    </>
  );
};

export default AddCareer;
