import React from 'react'

/*마이페이지(기업회원)>메인계정*/
function CompanySubIDView({ varMemberInformation }) {
  return (
    <>
        <h3 className="my_title">메인 계정</h3>
        <ul className="table table_view01">
            <li className="tr">
                <div className="th">아이디</div>
                <div className="td txt_blue">{varMemberInformation?.mem_id}</div>
            </li>
        </ul>
    </>
  );
}

export default CompanySubIDView;
