/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../configs/configs";
import { blank_pattern, isPassword } from "../Common/Common";

const EditPWC = () => {
  const navigate = useNavigate();
  const found_id = localStorage.getItem("found_id");
  console.log("11111111111 ",found_id)

  const [varInputs, setInputs] = useState({ pwd: "", pwdCheck: "" });

  //입력창 변경시
const onChangeHandler = (e) => {
  const { value, name } = e.currentTarget;
  //console.log({ value, name });
 if (name === "pwd") {
    //setAuth({ ...varAuth, email: false });
    setInputs({ ...varInputs, pwd: value });
  } else {
    setInputs({ ...varInputs, [name]: value });
  }
};

//input창 실시간 체크
const onAfterChangeHandler = (e) => {
  const {name,value} = e.currentTarget;
  // console.log('name ', name, ' value ',value)
  if(value === ""){
      return false;
  }

  if(name === 'pwd'){
    if(!blank_pattern(value)){
      alert("비밀번호에 공백이 포함되어있습니다.  \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
      setInputs({...varInputs, [name]:""})
      e.currentTarget.focus();
    }else if(!isPassword(value)){
      alert("비밀번호 형식에 맞지 않습니다. \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
      setInputs({...varInputs, [name]:""})
      e.currentTarget.focus();
    }
  } else if(name === 'pwdCheck'){
      if(!blank_pattern(value)){
        alert("비밀번호 확인에 공백이 포함되어 있습니다. \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
        setInputs({...varInputs, [name]:""})
        e.currentTarget.focus();
      } else if (varInputs.pwd !== varInputs.pwdCheck) {
        alert(" 비밀번호가 일치하지 않습니다.");
        setInputs({...varInputs, [name]:""})
        e.currentTarget.focus();
      } else if(!isPassword(value)){
        alert("비밀번호 형식에 맞지 않습니다. \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.")
        setInputs({...varInputs, [name]:""})
        e.currentTarget.focus();
      }
  }
}


  const onChangePasswordHandler = async () => {
    try {
      if (!varInputs.pwd) {
        alert("새 비밀번호를 입력해주세요.");
      } else if (!isPassword(varInputs.pwd)) {
        alert("비밀번호 형식에 맞지 않습니다.");
      } else if (varInputs.pwd !== varInputs.pwdCheck && !varInputs.pwdCheck) {
        alert("두 비밀번호가 일치하지 않습니다.");
      } else {
        const param = new URLSearchParams();
        param.append("mem_id", found_id);
        param.append("mem_pwd", varInputs.pwd);
        await axios
          .post(`${CONFIG.SERVER_HOST}/foundId_changePw`, param)
          .then((res) => {
            alert(res.data.message);
            if (res.data.retvalue === 1) {
              localStorage.removeItem("found_id");
              navigate("/Login");
            }else{
              alert("비밀번호 변경에 실패하였습니다.")
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h2 className="sub_content_title">비밀번호 변경</h2>
      <p className="sub_content_text">
        새로운 비밀번호를 입력해서 비밀번호를 변경하세요.
      </p>
      <div className="box member_form">
        <div>
          <p className="title_field">새 비밀번호</p>
          <input
            type="password"
            name="pwd"
            placeholder="영문+숫자+특수문자 조합해서 8~16자리 입력해주세요."
            onChange={(e) => onChangeHandler(e)}
            onBlur={(e) => onAfterChangeHandler(e)}
            value={varInputs.pwd}
          />
          <p className="title_field">비밀번호 확인</p>
          <div className="input_wrap">
            <input
              type="password"
              name="pwdCheck"
              placeholder="비밀번호를 한번 더 입력해주세요"
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onAfterChangeHandler(e)}
              value={varInputs.pwdCheck}
            />
          </div>
        </div>
        <div className="bottom_btn_area">
          <button onClick={() => onChangePasswordHandler()} className="btn">
            비밀번호 변경
          </button>
          <Link to={"/main"} className="btn gray">
            메인으로
          </Link>
        </div>
      </div>
    </>
  );
};

export default EditPWC;
