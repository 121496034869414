// /* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from "react";
// import { Link, useParams, useNavigate } from 'react-router-dom';
// import iconhome from '../../img/icon-home.png';
// import Titlebar from '../inc/Titlebar';
// import Tabbar from '../inc/Tabbar';
// import Menu from '../inc/Menu';
// import arr from '../../img/arr.png';
// import axios from 'axios';
// import CONFIG from '../../../configs/configs';
// import Paging from '../../../components/Layout/Paging'
// import moment from 'moment';
// import { ChangeMoneyUnit } from "../../../components/Common/Common";

// function Announcement_List({ handleCategory }) {
//     const navigator = useNavigate();
//     const adminlogin = localStorage.getItem('adminlogin');
//     const { user_idx } = useParams();

//     const [varSearch, setSearch] = useState("none");
//     const [varSearchInput, setSearchInput] = useState("");
//     const [startDate, setStartDate] = useState(""); //date picker 날짜
//     const [endDate, setEndDate] = useState(""); //date picker 날짜

//     //페이징
//     const [varTotalCount, setTotalCount] = useState(0);
//     const [varPageSize] = useState(10);
//     const [varPageNum, setPageNum] = useState(1);

//     const [Recruitment, setRecruitment] = useState([]);
//     const [varFilterlist, setFilterlist] = useState([]);
//     const [varPagelist, setPagelist] = useState([]);

//     useEffect(() => {
//       if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
//         alert("로그인 후 이용 가능합니다.");
//         navigator("/admin/login");
//       } // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     useEffect(async () => {
//         const params = new URLSearchParams();
//         params.append("mem_idx", user_idx);
//         await axios.post(`${CONFIG.SERVER_HOST}/get_headhunter_employ_list`, params).then((res) => {
//             console.log(res.data);
//             if (res.data.retvalue > 0) {
//                 console.log(res);
//                 setTotalCount(res.data.totalcount);
//                 const data = res.data.results.map((data, i) => (
//                     {
//                         order: i + 1,
//                         em_idx: data.em_idx,
//                         em_title: data.em_title,
//                         mcm_name: data.com_name,
//                         emh_reward_applicant: data.emh_reward_applicant,
//                         emh_reward_recommend: data.emh_reward_recommend,
//                         mem_id: data.mem_id,
//                         mem_name: data.mem_name,
//                         em_status: data.em_status,
//                         em_rdate: data.em_rdate,
//                         vwc_name: data.vwc_name,
//                         vwd_name: data.vwd_name,
//                         em_type: data.em_type,
//                         vr_name: data.vr_name,
//                         em_tasks_info: data.tasks
//                     }
//                 ));
//                 setRecruitment(data);
//                 setFilterlist(data);
//             }
//         });
//     }, []);

//     //페이지별 표시
//     const getPageLists = () => {
//         const startNum = (varPageNum - 1) * varPageSize;
//         const endNum = startNum + varPageSize;
//         const copyData = varFilterlist.slice(startNum, endNum);
//         setPagelist(copyData);
//     }

//     useEffect(() => {
//         getPageLists();
//     }, [varPageNum, varFilterlist]);

//     const handlestartdate = (e) => {
//         setStartDate(e.target.value);
//     }
//     const handleenddate = (e) => {
//         setEndDate(e.target.value);
//     }

//     const handleType = (e) => {
//         setSearch(e.target.value);
//     };
//     const handleInput = (e) => {
//         setSearchInput(e.target.value);
//     };

//     //검색-초기화
//     const handleSearchClear = () => {
//         setStartDate("");
//         setEndDate("");
//         setSearch("");
//         setSearchInput("");
//         setPageNum(1);
//         setTotalCount(Recruitment.length);
//         setFilterlist(Recruitment);
//     }

//     const changeEmStatus = (element) => {
//       if(element.em_status === -1 ) element.em_status = "삭제";
//       if(element.em_status === 0 ) element.em_status = "대기중";
//       if(element.em_status === 1 ) element.em_status = "진행중";
//       if(element.em_status === 2 ) element.em_status = "마감";
//     }

//     const allValue = (element) => {
//       return(
//         element.em_title?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
//         element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
//         element.em_tasks_info?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
//         element.vr_name?.includes(varSearchInput) ||
//         element.em_status?.includes(varSearchInput)
//       )
//     }

//     //검색
//     const handleSearchExecute = () => {
//         let copyData = [...Recruitment];
//         if (startDate !== null && startDate !== "") {
//           copyData = copyData.filter(
//             (element) =>
//               moment(element.em_rdate).format(CONFIG.formatStr) >=
//               moment(startDate).format(CONFIG.formatStr)
//           );
//         }
//         if (endDate !== null && endDate !== "") {
//           copyData = copyData.filter(
//             (element) =>
//               moment(element.em_rdate).format(CONFIG.formatStr) <=
//               moment(endDate).format(CONFIG.formatStr)
//           );
//         }
//         if (varSearchInput !== "") {
//           if (varSearch === "title") {
//             copyData = copyData.filter((element) =>
//               element.em_title?.toUpperCase().includes(varSearchInput.toUpperCase())
//             );
//           } else if (varSearch === "mcm_name") {
//             copyData = copyData.filter((element) =>
//               element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase())
//             )
//           } else if (varSearch === "tasks") {
//             copyData = copyData.filter((element) =>
//               element.em_tasks_info?.toUpperCase().includes(varSearchInput.toUpperCase())
//             )
//           } else if (varSearch === "vr_name") {
//             copyData = copyData.filter((element) =>
//               element.vr_name?.includes(varSearchInput)
//             )
//           } else if (varSearch === "em_status") {
//             copyData = copyData.filter((element) => {
//             changeEmStatus(element);
//             element.em_status?.includes(varSearchInput)
//             return element.em_status?.includes(varSearchInput)
//           })
//           } else {
//             copyData = copyData.filter(
//               (element) => {
//               changeEmStatus(element);
//               return allValue(element)
//             });
//           }
//         }
//         console.log(copyData);
//         setTotalCount(copyData.length);
//         setFilterlist(copyData);
//         setPageNum(1);
//     }

//     return (
//         <React.Fragment>
//             <Titlebar />
//             <Tabbar tabType="announcement" />
//             <Menu menuType="announcement" menu="headhunter_dedicated_hall_list" />
//             <div id="contents" className="ad_contents">
//                 <div className="tit">헤드헌터전용관 관리
//                     <div className="path">
//                         <img src={iconhome} alt="" />
//                         HOME<img src={arr} alt="" />공고 관리<img src={arr} alt="" />헤드헌터전용관 관리
//                     </div>
//                 </div>
//                 <div className="content">
//                     <div className="main-box">
//                         <div className="search-box">
//                             <p>검색건수 : {varTotalCount}</p>
//                             <br />
//                             <div className="day-box">
//                                 <p>기간검색</p>
//                                 <input type="date" name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
//                                 ~
//                                 <input type="date" name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
//                             </div>
//                             <div className="f-right">
//                                 <select className="short_select" name="search" value={varSearch} onChange={handleType}>
//                                     <option value="none">전체</option>
//                                     <option value="title">채용제목</option>
//                                     <option value="mcm_name">회사명</option>
//                                     <option value="tasks">직무</option>
//                                     <option value="vr_name">지역</option>
//                                     <option value="em_status">상태</option>
//                                 </select>
//                                 <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{ width: "270px" }} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e)}}/>
//                                 <button onClick={handleSearchExecute} className="btn">검색</button>
//                                 <button onClick={handleSearchClear} className="btn black">초기화</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="main-box ">
//                         <form name="f" method="post">
//                             <input type="hidden" name="typ" value="" />
//                             <input type="hidden" name="delchk" value="0" />
//                             <table className="list">
//                                 <colgroup>
//                                     <col width="6%"></col>
//                                     <col width="24%"></col>
//                                     <col width="14%"></col>
//                                     {/* <col width="14%"></col> */}
//                                     <col width="8%"></col>
//                                     {/* <col width="8%"></col> */}
//                                     <col width="8%"></col>
//                                     <col width="10%"></col>
//                                     <col width="8%"></col>
//                                 </colgroup>
//                                 <thead>
//                                     <th>번호</th>
//                                     <th>채용제목</th>
//                                     <th>회사명</th>
//                                     {/* <th>직무</th> */}
//                                     <th>총 보상금</th>
//                                     {/* <th>지역</th> */}
//                                     <th>상태</th>
//                                     <th>등록일</th>
//                                     <th>채용 상세</th>
//                                 </thead>
//                                 <tbody>
//                                     {varPagelist.map((resume, i) => (
//                                         <tr key={i}>
//                                             <td>{varTotalCount - (varPageNum - 1) * varPageSize - i}</td>
//                                             <td className="ellipsis">{resume.em_title}</td>
//                                             <td className="ellipsis">{resume.mcm_name}</td>
//                                             {/* <td className="ellipsis">{resume.em_tasks_info === null ? "-" : resume.em_tasks_info}</td> */}
//                                             <td className="ellipsis">{ChangeMoneyUnit(resume.emh_reward_recommend / 10000)}</td>
//                                             {/* <td className="ellipsis">{resume.vr_name}</td> */}
//                                             <td>
//                                               {isNaN(resume.em_status) ? resume.em_status : resume.em_status === -1 ?  "삭제" : resume.em_status === 0 ? "대기중" : resume.em_status === 1 ? "진행중" : resume.em_status === 2 ? "마감" : ""}
//                                             </td>
//                                             <td>{resume.em_rdate === null ? "" : resume.em_rdate.substr(0, 10)}</td>
//                                             <td><Link to={`/admin/headhunter_dedicated_hall_modify/${resume.em_idx}`} className="btn line black">상세</Link></td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </form>
//                     </div>
//                     <div>
//                         <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     )

// }

// export default Announcement_List;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import iconhome from '../../img/icon-home.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arr from '../../img/arr.png';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Paging from '../../../components/Layout/Paging'
import { ChangeMoneyUnit } from "../../../components/Common/Common";

function Headhunter_Dedicated_Hall_List({ handleCategory }) {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const { user_idx } = useParams();
  const [varSearch, setSearch] = useState("all");
  const [varSearchInput, setSearchInput] = useState("");

  const [startDate, setStartDate] = useState(""); //date picker 날짜
  const [endDate, setEndDate] = useState(""); //date picker 날짜
  //페이징
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageNum, setPageNum] = useState(1);

  const [varPagelist, setPagelist] = useState([]);
  const [em_status, setEmStatus] = useState("");
  const [em_type, setEmType] = useState("");
  const [WC_list, setWC_list] = useState([]);
  const [CD_list, setCD_list] = useState([]);
  const [varEmtasks, setVarEmtask] = useState({
    value: '',
    text: '',
  });

  const [varEmtasksDetail, setVarEmtasksDetail] = useState({
    value: '',
    text: '',
  });
  const [selectChange, setSelectChange] = useState(false);
  const [startPageNum, setStartPageNum] = useState(1);
  const [endPageNum, setEndPageNum] = useState(10);
  const [varPageSize, setPageSize] = useState(10);
  const [varReset, setReset] = useState(false);

  useEffect(() => {
    if (adminlogin === undefined || adminlogin === null || adminlogin === "false") {
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserlist();

  }, []);

  const getUserlist = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/get_work_category`).then((res) => {
      if (res.data.length > 0) {
        setWC_list(res.data);
      }
    });

    // const params = new URLSearchParams();
    // params.append("mem_idx", user_idx);


    const sendData = {
      'mem_idx': user_idx,
      'startDate': startDate,
      'endDate': endDate,
      'em_type': em_type,
      'em_tasks': varEmtasks.value,
      'em_tasks_detail': varEmtasksDetail.value,
      'em_status': em_status,
      'varSearch': varSearch,
      'varSearchInput': varSearchInput,
      'pageSize': varPageSize,
      'startPageNum':startPageNum === 1 ? 0 : startPageNum,
      'endPageNum': endPageNum
    }
    await axios.post(`${CONFIG.SERVER_HOST}/get_headhunter_employ_list`, sendData).then((res) => {
      console.log('res.data', res.data);
      if (res.data.retvalue > 0) {
        setTotalCount(res.data.totalcount);
        const data = res.data.results.map((data, i) => (
          {
            order: data.rownum,
            em_idx: data.em_idx,
            em_title: data.em_title,
            mcm_name: data.com_name,
            em_tasks: data.em_tasks,
            em_reward_applicant: data.em_reward_applicant,
            em_reward_recommend: data.em_reward_recommend,
            mem_id: data.mem_id,
            mem_name: data.mem_name,
            em_status: data.em_status,
            em_rdate: data.em_rdate,
            vwc_name: data.vwc_name,
            vwd_name: data.vwd_name,
            em_type: data.em_type,
            vr_name: data.vr_name,
            tasks: data.tasks
          }
        ));
        setSelectChange(false);
        setPagelist(data);
        setReset(false);
      }
    });
  }

  const settingPageSize = (e) => {
    const { value } = e.target;
    setPageSize(Number(value));
    setSelectChange(true);
  }
  useEffect(() => {
    if (selectChange) {
      getUserlist();
      setPageNum(1);
    }
  }, [selectChange]);

  useEffect(() => {
    getUserlist();
  }, [startPageNum]);

  useEffect(() => {
    console.log(selectChange);
    if (selectChange === false) {
      setStartPageNum((varPageNum - 1) * varPageSize === 1 ? 10 : varPageSize * (varPageNum - 1));
      setEndPageNum((varPageNum - 1) * varPageSize === 1 ? varPageSize : varPageSize * varPageNum);
    }
  }, [varPageNum]);


  const handlestartdate = (e) => {
    setStartDate(e.target.value);
  }
  const handleenddate = (e) => {
    setEndDate(e.target.value);
  }

  const handleType = (e) => {
    setSearch(e.target.value);
  };
  const handleInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleEmType = (e) => {
    const { value } = e.target;
    setEmType(value);
  }

  //검색-초기화
  const handleSearchClear = () => {
    setStartDate("");
    setEndDate("");
    setSearch("");
    setSearchInput("");
    setPageNum(1);
    setReset(true);
  }
  useEffect(() => {
    if (varReset) {
      getUserlist();
    }
  }, [varReset]);
  //검색

  // const changeEmType = (element) => {
  //   if (element.em_type === 1) element.em_type = "공개채용";
  //   if (element.em_type === 2) element.em_type = "비공개채용";
  //   if (element.em_type === 3) element.em_type = "보상금 없는 채용";
  // }

  // const changeEmStatus = (element) => {
  //   if (element.em_status === -1) element.em_status = "삭제";
  //   if (element.em_status === 0) element.em_status = "대기중";
  //   if (element.em_status === 1) element.em_status = "진행중";
  //   if (element.em_status === 2) element.em_status = "마감";
  // }

  // const allValue = (element) => {
  //   return (
  //     element.em_title?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
  //     element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
  //     element.tasks?.includes(varSearchInput.toUpperCase()) ||
  //     element.vr_name?.includes(varSearchInput) ||
  //     element.em_type?.includes(varSearchInput) ||
  //     element.em_status?.includes(varSearchInput)
  //   )
  // }

  //검색
  const handleSearchExecute = () => {
    getUserlist();
    setPageNum(1);
    // let copyData = [...Recruitment];
    // if (startDate !== null && startDate !== "") {
    //   copyData = copyData.filter(
    //     (element) =>
    //       moment(element.em_rdate).format(CONFIG.formatStr) >=
    //       moment(startDate).format(CONFIG.formatStr)
    //   );
    // }
    // if (endDate !== null && endDate !== "") {
    //   copyData = copyData.filter(
    //     (element) =>
    //       moment(element.em_rdate).format(CONFIG.formatStr) <=
    //       moment(endDate).format(CONFIG.formatStr)
    //   );
    // }
    // if (varSearchInput !== "") {
    //   if (varSearch === "title") {
    //     copyData = copyData.filter((element) =>
    //       element.em_title?.toUpperCase().includes(varSearchInput.toUpperCase())
    //     );
    //   } else if (varSearch === "mcm_name") {
    //     copyData = copyData.filter((element) =>
    //       element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase())
    //     )
    //   } else if (varSearch === "tasks") {
    //     copyData = copyData.filter((element) =>
    //       element.tasks?.toUpperCase().includes(varSearchInput.toUpperCase())
    //     )
    //   } else if (varSearch === "vr_name") {
    //     copyData = copyData.filter((element) =>
    //       element.vr_name?.includes(varSearchInput)
    //     )
    //   } else if (varSearch === "em_type") {
    //     copyData = copyData.filter((element) => {
    //       changeEmType(element);
    //       element.em_type?.includes(varSearchInput)
    //       return element.em_type?.includes(varSearchInput)
    //     })
    //   } else if (varSearch === "em_status") {
    //     copyData = copyData.filter((element) => {
    //       changeEmStatus(element);
    //       element.em_status?.includes(varSearchInput)
    //       return element.em_status?.includes(varSearchInput)
    //     })
    //   } else {
    //     copyData = copyData.filter(
    //       (element) => {
    //         changeEmType(element);
    //         changeEmStatus(element);
    //         return allValue(element)
    //       });
    //   }
    // }

    // setTotalCount(copyData.length);
    // setFilterlist(copyData);
    // setPageNum(1);
  }

  const setStatus = (e) => {
    const { value } = e.target;
    setEmStatus(value);
  };


  const handleChange = async (e) => {
    const { value } = e.target;
    const category = Number(value);
    if (category > 0) {
      const param = new URLSearchParams();
      param.append("vwc_idx", category);
      await axios.post(`${CONFIG.SERVER_HOST}/get_category_detail`, param).then((res) => {
        setCD_list(res.data);
      });
    }
    // set_Announcement(prevState => ({
    //     ...prevState,
    //     [name]: value
    // }));

  }

  const onChangeEmTaskDetail = (e) => {
    setVarEmtasksDetail({
      "value": e.target.value,
      "text": e.target.selectedOptions[0].text
    });
  }
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="announcement" />
      <Menu menuType="announcement" menu="headhunter_dedicated_hall_list" />
      <div id="contents" className="ad_contents">
        <div className="tit">헤드헌터전용관 관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arr} alt="" />공고 관리<img src={arr} alt="" />헤드헌터전용관 관리
          </div>
        </div>
        <div className="content">

          <div className="main-box">
            <div className="search-box left">
              <div className="day-box">
                <p>등록일</p>
                <input type="date" name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                ~
                <input type="date" name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
              </div>

              <div className="new-line">
                <p>채용 구분 : </p>
                <select className="short_select" name="em_type" value={em_type} onChange={handleEmType}>
                  <option value="none">전체</option>
                  <option value="title">공개</option>
                  <option value="mcm_name">비공개</option>
                  <option value="tasks">파견/계약/알바</option>
                </select>
              </div>
              <div className='new-line'>
                <p>직무 : </p>

                <select className="short_select" name="em_tasks" id="em_tasks" value={varEmtasks.value} onChange={(e) => {
                  setVarEmtask({
                    value: e.target.value,
                    text: e.target.selectedOptions[0].text
                  });
                  handleChange(e);
                }}>
                  <option value={0}>선택</option>
                  {WC_list.map((category, i) => (
                    <option key={"vwc" + i} value={category.vwc_idx}>{category.vwc_name}</option>
                  ))}
                </select>

                <select className="short_select" name="em_tasks_detail" id="em_tasks_detail" value={varEmtasksDetail.value} onChange={onChangeEmTaskDetail} >
                  <option value={0}>선택</option>
                  {CD_list.map((detail, i) => (
                    <option key={"vwd" + i} value={detail.vwd_idx}>{detail.vwd_name}</option>
                  ))}
                </select>
              </div>
              <div className='new-line'>
                <p>상태 : </p>
                <select className="short_select" name="em_status" value={em_status} onChange={setStatus} >
                  <option value="">선택</option>
                  <option value="1">진행</option>
                  <option value="0">대기중</option>
                  <option value="2">마감</option>
                </select>
              </div>
            </div>
            <div className="search-box division_line">
              <div className="f-right">
                <select className="short_select" name="search" value={varSearch} onChange={handleType}>
                  <option value="all">전체</option>
                  <option value="title">채용제목</option>
                  <option value="mcm_name">회사명</option>
                </select>
                <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{ width: "270px" }} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e) }} />
                <button onClick={handleSearchExecute} className="btn">검색</button>
                <button onClick={handleSearchClear} className="btn black">초기화</button>
              </div>
            </div>
          </div>
              
          <div className="list_top">
            <p>검색건수 : {varTotalCount}
              <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                <option value="10">10개</option>
                <option value="30">30개</option>
                <option value="50">50개</option>
                <option value="100">100개</option>
              </select>
            </p>
            <div className="btn-right">
              {/* <button className="btn-danger"><Link to="/admin/announcement_register" style={{ color: "#fff", width: "100%" }}>등록</Link></button> */}
            </div>
          </div>
          <div className="main-box ">
            <input type="hidden" name="typ" value="" />
            <input type="hidden" name="delchk" value="0" />
            <table className="list announcement_list">
              <colgroup>
                <col width="6%"></col>
                <col width="22%"></col>
                <col width="10%"></col>
                {/* <col width="12%"></col> */}
                <col width="7%"></col>
                {/* <col width="8%"></col> */}
                <col width="10%"></col>
                <col width="7%"></col>
                <col width="10%"></col>
                <col width="8%"></col>
              </colgroup>
              <thead>
                <th>번호</th>
                <th>채용제목</th>
                <th>회사명</th>
                {/* <th>직무</th> */}
                <th>총 보상금</th>
                {/* <th>지역</th> */}
                <th>채용구분</th>
                <th>상태</th>
                <th>등록일</th>
                <th>채용 상세</th>
              </thead>
              <tbody>
                {varPagelist.map((resume, i) => (
                  <tr key={i}>
                    <td>{resume.order}</td>
                    <td className="ellipsis">{resume.em_title}</td>
                    <td className="ellipsis">{resume.mcm_name}</td>
                    {/* <td className="ellipsis">{resume.tasks === null ? "-" : resume.tasks}</td> */}
                    <td className="ellipsis">{ChangeMoneyUnit(resume.em_reward_recommend / 10000)}</td>
                    {/* <td className="ellipsis">{resume.vr_name}</td> */}
                    <td className="ellipsis">
                      {isNaN(resume.em_type) ? resume.em_type : resume.em_type === 1 ? "공개채용" : resume.em_type === 2 ? "비공개채용" : resume.em_type === 3 ? "보상금 없는 채용" : "헤드헌터 전용관"}
                    </td>
                    <td>
                      {isNaN(resume.em_status) ? resume.em_status : resume.em_status === -1 ? "삭제" : resume.em_status === 0 ? "대기중" : resume.em_status === 1 ? "진행중" : resume.em_status === 2 ? "마감" : ""}
                    </td>
                    <td>{resume.em_rdate === null ? "" : resume.em_rdate.substr(0, 10)}</td>
                    <td><Link to={`/admin/headhunter_dedicated_hall_modify/${resume.em_idx}`} className="btn line black">상세</Link></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
        </div>
      </div>


    </React.Fragment>
  )

}

export default Headhunter_Dedicated_Hall_List;