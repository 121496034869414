/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { em_titleLength } from "../Common/Common";

function PopupCandidate({
  varResumeIndex,
  varShowPopupCandidate,
  setShowPopupCandidate,
}) {
  const user_idx = localStorage.getItem("mem_idx");
  const mcm_idx = localStorage.getItem('mcm_idx');  //기업 번호
  const com_idx = localStorage.getItem('com_idx');  //기업대표 회원번호

  //let mem_mode = Number(localStorage.getItem("mem_mode"));
  //let mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));
  //채용공고목록
  const [varCompanyEmploys, setCompanyEmploys] = useState([]);
  //후보자 등록
  const [varEmployCandidate, setEmployCandidate] = useState(0);
  // const [SearchForm, setSearchForm] = useState(0)

  // 헤드헌터 확인(mem_mode =2, mem_hunter_type = 1(일반),2(프리미엄))
  /* 2022-11-14 헤드헌터 구분 없어져 추석 처리 - KSR
  let SearchForm = 0;
  if (mem_mode === 2 && mem_hunter_type >= 1) {
    SearchForm = 1
  }
  */

  //기업채용공고 가져오기
  const getCompanyEmploys = async () => {
    /*
    try {
      console.log("PopupCandidate");
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("SearchForm", SearchForm);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_employ_lists_company`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            setCompanyEmploys(res.data.results);
          }
        });
    } catch (error) {
      console.log(error);
    }
    */

    try {
      console.log("PopupCandidate");
      const params = new URLSearchParams();
      params.append("mem_idx", user_idx);
      params.append("mcm_idx", mcm_idx);
      params.append("com_idx", com_idx);
      params.append("re_idx", varResumeIndex);

      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_employ_lists_company2`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            setCompanyEmploys(res.data.results);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompanyEmploys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //후보자 등록할 채용공고 선택하기---------------------
  const onChangeEmployCandidate = (e) => {
    console.log(e.currentTarget.value);
    setEmployCandidate(e.currentTarget.value);
  };
  //후보자 등록하기---------------------
  const onRegsiterCandidate = (e) => {
    if (varEmployCandidate <= 0) {
      alert("후보자를 추천할 채용공고를 먼저 선택하십시오.");
      return false;
    }
    try {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("re_idx", varResumeIndex);
      params.append("mcm_idx", mcm_idx);
      params.append("em_idx", varEmployCandidate);
      axios
        .post(`${CONFIG.SERVER_HOST}/_update_employ_candidate`, params)
        .then((res) => {
          if (res.data.retvalue === 1) {
            setShowPopupCandidate("popup");
          } else {
            alert(res.data.message);
          }
          setEmployCandidate(0)
        });
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div id="popupCandidate" className={varShowPopupCandidate}>
      <div className="popup_wrap popup01">
        <section className="title_wrap">
          <h1>후보자 추가하기</h1>
        </section>
        <form className="content_wrap">
          <fieldset className="only_input">
            <div className="field">
              <select value={varEmployCandidate} onChange={(e) => onChangeEmployCandidate(e)}>
                <option value={0}>공고를 선택해주세요</option>
                {varCompanyEmploys.map((employItem, index) => (
                  <option key={"employ_" + index} value={employItem.em_idx}>
                    {employItem.em_title === "" ? "-" : em_titleLength(employItem.em_title, 35, '...')}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
        </form>
        <div className="button_wrap">
          <div className="big_button_wrap two">
            <a
              className="btn white"
              onClick={() => setShowPopupCandidate("popup")}
            >
              취소
            </a>
            <button className="btn" onClick={(e) => onRegsiterCandidate(e)}>
              후보자 추가
            </button>
          </div>
          <div className="close_button_wrap">
            <button onClick={() => setShowPopupCandidate("popup")}>닫기 X</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupCandidate;
