/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TermContent from './TermContent'

import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'


function Selfconfirm() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
    <>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="selfconfirm" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          본인확인 서비스
          <div className="path">
            <img src={iconhome} alt=""/>HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt=""/>약관 관리<img src={arrImage} alt=""/>본인확인 서비스
          </div>
        </div>
        <div className="content">
          <TermContent termType="selfconfirm"></TermContent> 
        </div>
      </div>
    </>
  )
}
export default Selfconfirm
