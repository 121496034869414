import { useEffect, useRef, useState } from "react";
import CONFIG from "configs/configs";
import axios from "axios";

import { resetFileForm,CheckLimitFileSize } from "../Common/Common";

/*마이페이지(기업회원)>프로필이미지*/
function Profile({ varCompanyInfo, setCompanyInfo }) {
  //이미지 선택관련
  const photoInput = useRef();
  //이미지 저장 변수
  const defaultCompanyLogo = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`;
  const [varPreview, setPreview] = useState(defaultCompanyLogo);
  useEffect(()=>{
    if(varCompanyInfo !== undefined && varCompanyInfo !== null) {
      if(varCompanyInfo.mcm_image === 1 && varCompanyInfo.mcm_ufile !== undefined) {
        setPreview(`${CONFIG.SERVER_HOST}/mcm_image/${varCompanyInfo.mcm_ufile}`);
      } else {
        setPreview(defaultCompanyLogo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCompanyInfo]);

  //이미지 선택으로
  const onSelectThumbnailHandler = () => {
    photoInput.current.value = "";
    photoInput.current.click();
  };

  //이미지 화면에 표시
  const onChangeThumbnailHandler = (e) => {
    const { files } = e.currentTarget;
    //파일을 선택한 후 파일 재선택을 위해 메뉴를 호출한 후 취소버튼을 누른 경우
    if(e.currentTarget.files.length === 0) {
      return false;
    }
    //만약을 위해 - 선택된 파일 없는 경우
    if(e.currentTarget.files === undefined || e.currentTarget.files === null) {
        alert('선택된 파일이 없습니다. 파일을 선택하세요.');
        return false;
    }

    //jpg, png 파일만 등록 가능하게
    //console.log(files[0].name?.split('.').reverse()[0]);
    //.toLowercase()
    const ext = files[0].name?.split('.').reverse()[0].toString().toLowerCase();
    console.log(ext);
    if((ext !== 'gif') && (ext !== 'jpeg') && (ext !== 'jpg') && (ext !== 'png')) {
      alert('GIF,JPEG,JPG,PNG 만 가능합니다.');
      return false;
    }

    //파일크기 점검---
    const selFile = e.currentTarget.files[0];
    if(!CheckLimitFileSize(selFile,200)) {
      //input 파일 초기화
      resetFileForm(document.getElementById("select_file"));
      alert("등록할 파일의 크기가 200KB를 초과합니다.");
      return false;
    }
    //파일이 존재하면
    if (selFile) {
      //화면에 표시
      setPreview(URL.createObjectURL(selFile));
      //서버로 전송
      uploadImageFile(selFile);
    }
  };

  //이미지 업로드/파일명 저장
  const uploadImageFile = (imageFile) => {
    console.log(imageFile);
    //이미지 서버로 파일을 업로드
    const formData = new FormData();
    formData.append("mcm_image", imageFile);
    axios.post(`${CONFIG.SERVER_HOST}/mcm_image`, formData).then((res) => {
      const newFile = res.data.fileName;
      if (newFile !== null) {
        console.log(newFile);
        setCompanyInfo({ ...varCompanyInfo, mcm_image: 1, mcm_ufile: newFile});
        //이미지명을 데이타베이스에 저장
        const param = new URLSearchParams();
        param.append("mcm_idx", varCompanyInfo.mcm_idx);
        param.append("filename", newFile);
        axios.post(`${CONFIG.SERVER_HOST}/company_img_update`, param).then((res) => {
          if(res.data.retvalue === 1){
            console.log(res.data.message);
          }else{
            alert(res.data.message);
          }
        });
      }
    });
  };

  //이미지 삭제
  const onDeleteThumbnailHandler = (e) => {
    if (!window.confirm("등록된 이미지를 삭제합니까?")) {
      return false;
    }
    //현재 보유한 회사로고
    const currentLogo = varCompanyInfo.mcm_ufile;
    //화면에 표시된 이미지 삭제
    setPreview(`${CONFIG.SERVER_HOST}/mcm_image/default_company.png`);
    // 회사 로고가 기본 이미지일 경우
    if (currentLogo === null) {
      alert("기본이미지는 삭제할 수 없습니다");
      return false;
    }

    //이미지명을 데이타베이스에 저장
    const param = new URLSearchParams();
    param.append("mcm_idx", varCompanyInfo.mcm_idx);
    axios.post(`${CONFIG.SERVER_HOST}/company_img_delete`,param).then((res) => {
      alert(res.data.message);
      if(res.data.retvalue === 1) {
        //객체정보저장에 변경요소 변경
        setCompanyInfo({...varCompanyInfo,mcm_image:0,mcm_ufile:""});
        //화면에 이미지 교체
        setPreview(defaultCompanyLogo);
        //서버에서 이미지 삭제하는 API - 검증요망
        axios.get(`${CONFIG.SERVER_HOST}/remove_mcm_image/${currentLogo}`).then((res) => {
          if(res.data.retvalue === 1){
            console.log(res.data)
          }else{
            alert(res.data.message);
          }
        });
      }
    });
  }

  return varCompanyInfo === null ? null : (
    <>
      <section className="sec01">
        <figure className="company_logo_image">
          <img src={varPreview} alt="preview" />
        </figure>
        <div className="wrap">
          <input type="file" id="select_file" accept={"image/*"} ref={photoInput} style={{ display: "none" }}
            onChange={(e) => onChangeThumbnailHandler(e)}
          />
          <button type="button" className="btn line" style={{ cursor: "pointer" }}
            onClick={(e)=>onSelectThumbnailHandler(e)}
          >로고 등록</button>
          <button type="button" className="btn line black" onClick={(e)=>onDeleteThumbnailHandler(e)}
          >삭제</button>
          <ul className="guide_text">
            <li>
              GIF,JPEG,JPG,PNG 파일형식으로, 200KB 용량 이내의 파일만
              등록가능합니다.
            </li>
            <li>로고 업로드 시 자동으로 최적화 되어 노출됩니다.</li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Profile;
