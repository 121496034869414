import React, { useEffect,useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";
import moment from "moment";

//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/MyTalk.css';

//components
/* import Tab from "../../components/Tab/TalkTab"; */
import TalkInfo from "../../components/Talk/TalkInfo";
import TalkReplys from "../../components/Talk/TalkReplys";
/* import TalkDirection from "../../components/Talk/TalkDirection"; */

//이미지
function TalkView({ varInitData,varTalkInfo,setTalkInfo }) {
  const mem_idx = localStorage.getItem("mem_idx");
  const location = useLocation();
  const navigator = useNavigate();

  const [varStartMode,setStartMode] = useState(false);
  const [varCurrentView,setCurrentView] = useState(-1);
  const [varTcount,setTcount] = useState(-1);

  //이직톡 상세보기
  const getData = async (mwt_idx) => {
    try {
      console.log(mwt_idx);
      const params = new URLSearchParams();
      params.append("mwt_idx", mwt_idx);
      params.append("mem_idx", mem_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_talk_information`,params).then((res) => {
        if(res.data.retvalue === 1 && res.data.results.length > 0) {
          console.log(res.data.tcount);
          setTcount(res.data.tcount);
          const datas = res.data.results[0];
          setTalkInfo({
            mwt_idx: datas.mwt_idx,
            mwt_title: datas.mwt_title,
            mem_nickname: datas.mem_nickname,
            mem_id: datas.mem_id,
            mem_type: datas.mem_type,
            mwt_comment:datas.mwt_comment, //댓글
            mwt_hits: datas.mwt_hits,
            mwt_recommend: datas.recommend,
            mwt_rdate: datas.mwt_rdate,
            mwt_mem_idx: datas.mem_idx
          });
          setStartMode(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //전달받은 이직톡 번호 저장
  useEffect(() => {
    if(location !== null && location.state !== null && !varStartMode) {
      const mwt_idx = location.state.mwt_idx;
      setTalkInfo({...varTalkInfo,mwt_idx:mwt_idx});
      setCurrentView(mwt_idx);
      //getData(mwt_idx);s
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if(varCurrentView >= 0) {
      getData(varCurrentView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentView]);

  //이직톡 삭제
  const onDeleteTalkHandler = (e) => {
    e.preventDefault();
    if(!window.confirm("삭제하시겠습니까?")) {
      return;
    }
    try {
      const params = new URLSearchParams();
      params.append("mwt_idx", varCurrentView);
      axios.post(`${CONFIG.SERVER_HOST}/_delete_talk`,params).then((res) => {
          alert(res.data.message);
          navigator("/MyTalk/TalkList");
      });
    } catch (error) {
      console.error();
    }
  };

  // 추천하기
  const handleTalkRecommend  = async () => {
    if(mem_idx) {
      const params = new URLSearchParams();
      params.append("mwt_idx", location.state.mwt_idx);
      params.append("mem_idx", mem_idx);
      await axios
      .post(`${CONFIG.SERVER_HOST}/_recommend_talk`, params, CONFIG.header)
      .then((res) => {

        console.log(res);
        alert(res.data.message);
        const { count, tvalue } = res.data;
        setTalkInfo({...varTalkInfo,mwt_recommend:count});
        setTcount(tvalue);
      })
      .catch((e) => {
        console.debug(e);
      });
    }
  }

  useEffect(() => {
    return () => {
      setTalkInfo({
        mwt_idx: 0,
        mwt_title: "",
        mem_nickname: "",
        mwt_comment: "",
        mwt_hits: 0,
        mwt_recommend: 0,
        mwt_rdate: "",
        mwt_mem_idx: 0
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="center_wrap page_mytalk_view board_view">
      <div className="box">
        <TalkInfo
          title={varTalkInfo.mwt_title}
          comment={varTalkInfo.mwt_comment}
          rdate={moment(varTalkInfo.mwt_rdate).format(CONFIG.formatStr)}
          writer={varTalkInfo.mem_type === 0 ? varTalkInfo.mem_id : varTalkInfo.mem_nickname}
          hits={varTalkInfo.mwt_hits}
          recommends={varTalkInfo.mwt_recommend}
          handleRecommend={handleTalkRecommend}
          varTcount={varTcount}
        />
        <TalkReplys varCurrentView={varCurrentView} setCurrentView={setCurrentView}/>

        {/* <TalkDirection varCurrentView={varCurrentView} setCurrentView={setCurrentView} varInitData={varInitData} /> */}
      </div>
      <div className="btn_bottom_area">
        <Link to="/MyTalk/TalkList" className="btn white">목록으로</Link>
        {varTalkInfo && Number(varTalkInfo.mwt_mem_idx) === Number(mem_idx) ? (
          <>
            <Link to={"/MyTalk/TalkWrite"} state={{mwt_idx:varTalkInfo.mwt_idx}} className="btn white">수정</Link>
            <div className="btn gray" onClick={(e)=>onDeleteTalkHandler(e)}>삭제</div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TalkView;
