/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import CONFIG from '../../configs/configs';
import download from "downloadjs";

//image
import deleteImage1 from "../../img/sub/btn_delete01.png";

function PortfolioTmpHistory({ index, portfolio, resumeTmpPortfolioList, setResumeTmpPortfolioList }) {

  //포트폴리오 삭제 ---> 저장된 파일목록에서 삭제표시로 지정
  const onRemoveHandler = (e,rcp_idx) => {
    e.preventDefault();
    if(!window.confirm('선택한 포트폴리오를 삭제합니까?')) return;
    const finsIndex = resumeTmpPortfolioList.findIndex(element => element.rcp_idx === rcp_idx);
    console.log(finsIndex);
    let copyData = [...resumeTmpPortfolioList];
    copyData[finsIndex]={...copyData[finsIndex],rcp_status:0};  //삭제표시
    console.log(copyData);
    setResumeTmpPortfolioList(copyData);
  }
  const onDownloadFile = async (e) => {
    e.preventDefault();
    const res = await fetch(`${CONFIG.SERVER_HOST}/resume_file_down?path=port_tmp_folio&file_name=${portfolio.rcp_ufile}`);
    const blob = await res.blob();
    download(blob, portfolio.rcp_ufile);
  }

  // console.log(portfolio);
  //삭제하기로 지정한 포트폴리오 파일을 화면에서 빼고 보여줌
  if (portfolio.rcp_status === 1) {
    return (
      <div className="portfolio_file" key={'pt'+index}>
        <button onClick={(e)=>onDownloadFile(e)} style={{fontSize:'15px'}}  download>{portfolio.rcp_ufile}</button>
        <button className="btn_del" onClick={(e)=>{onRemoveHandler(e,portfolio.rcp_idx)}}>
          <img src={deleteImage1} alt=""/>
        </button>
      </div>
    );
  } else {
    return <></>
  }
}

export default PortfolioTmpHistory;