/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import axios from "axios";
import CONFIG from "configs/configs";

import { resetNavSubMenuForHeding, CheckDuplicationObject, AppTopicSetting } from '../Common/Common'
import Leave from "html/User/Leave";
import ProfileC from "components/Company/Profile";
import ProfileInfo from "components/Company/ProfileInfo";

//image
import closeImage from "img/common/btn_close_white.png";

function MyHeding1({ getSubItem }) {

  //로컬스토리지에서 읽기
  const user_idx = Number(localStorage.getItem("mem_idx"));
  const mem_sub = Number(localStorage.getItem("mem_sub"));
  const mcm_idx = Number(localStorage.getItem("mcm_idx"));

  // const uri_path = window.location.pathname;
  // useEffect(() => {
  //   if (!mcm_idx || Number(mcm_idx)===0) {
  //     localStorage.setItem("re_url", uri_path);
  //     alert("로그인 후 이용 가능합니다.");
  //     uNavigate("/Login");
  //   }
  // },[mcm_idx, uri_path, uNavigate]);

  //정보 저장
  const [varCompanyInfo, setCompanyInfo] = useState({});
  //점검사항
  const [varAuth, setAuth] = useState({
    oldEmail: '',
    oldMobile: '',
    oldNickname: '',
    email: true,
    mobile: true,
    nickname: true
  });

  //회원탈퇴페이지
  const [isLeave, setIsLeave] = useState(false);
  //우편번호팝업창
  const [isOpenPost, setIsOpenPost] = useState(false);

  //사업자등록증
  const [varTexFile, setTexFile] = useState(null);          //파일

  //기업정보 가져오기
  const getCompanyInfo = async () => {
    try {
      console.log('mcm_idx', mcm_idx);
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_companyInfo`, params).then((res) => {
        if (res.data.retvalue === 1 && res.data.results.length > 0) {
          const datas = res.data.results[0];
          const new_datas = Object.assign(datas, { pwdCheck: '' });
          new_datas.mem_pwd = "";
          console.log(new_datas);
          setCompanyInfo(new_datas);
          setAuth({ ...varAuth, oldEmail: datas.mem_email, oldMobile: datas.mem_mobile, oldNickname: datas.mem_nickname });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  //회원탈퇴 페이지 열기/닫기
  const onSetIsLeave = (e, bpop) => {
    e.preventDefault();
    setIsLeave(bpop);
  }

  //최초 실행부
  useEffect(() => {
    //console.log('mcm_idx', mcm_idx);
    getSubItem(1);  //헤딩좌측메뉴 설정
    resetNavSubMenuForHeding(1);  //상단메뉴 - 2차메뉴 설정
    getCompanyInfo(); //기업정보읽기
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //기업 전체 정보 저장하기
  const onUpdateHandler = async () => {
    try {
      if (!varCompanyInfo.mcm_zipcode || !varCompanyInfo.mcm_address1) {
        alert("우편번호/주소를 선택해주세요.");
        //} else if (varCompanyInfo.mem_nickname === "") {
        //  alert("닉네임을 입력해주세요.");
      } else if (varCompanyInfo.mem_email === "") {
        alert("이메일을 입력해주세요.");
      } else if (varCompanyInfo.mem_mobile === "") {
        alert("휴대폰번호를 입력해주세요.");
        //} else if (!varAuth.nickname && varAuth.oldNickname !== varCompanyInfo.mem_nickname) {
        //  alert("닉네임 중복확인을 해주세요.");
      } else if (!varAuth.email && varAuth.oldEmail !== varCompanyInfo.mem_email) {
        alert("이메일 중복확인을 해주세요.");
      } else if (!varAuth.mobile && varAuth.oldMobile !== varCompanyInfo.mem_mobile) {
        alert("휴대폰 인증을 해주세요.");
      } else {
        if (!window.confirm('현재 내용을 저장합니까?')) return false;
        //사업자등록증 저장
        if (varTexFile !== null) {
          console.log(varTexFile.name);
          const formData = new FormData();
          formData.append("tax_file", varTexFile);
          axios.post(`${CONFIG.SERVER_HOST}/tax_file`, formData).then(async (res) => {
            console.log(res.data.fileName);
            InfoUpdate(res.data.fileName);  //서버에 생성된 파일명 전달
          });
          //InfoUpdate(varTexFile.name);
        } else {
          InfoUpdate('');
        }
      }
    } catch (error) {
      alert("등록 실패");
      console.log(error);
    }
  };

  //데이타베이스로 기업정보 저장하기
  const InfoUpdate = async (filename) => {
    try {
      
      const sendData = {
        'companyInfo': varCompanyInfo,
        'mcm_tax_file': filename
      }

      await axios.post(`${CONFIG.SERVER_HOST}/companyInfoUpdate`, sendData, CONFIG.header).then(async (res) => {
        alert(res.data.message);
        if (res.data.retvalue === 1) {
          document.getElementById('selected_file').value = filename;
          AppTopicSetting(varCompanyInfo?.push_YN);
        }
      });
    } catch (error) {
      alert("등록 실패");
      console.log(error);
    }
  }
  //입력하는 항목들 객체에 저장하기
  const onChangeHandler = (e) => {
    const { value, name } = e.currentTarget;
    if (name === "mem_nickname") {
      if (varAuth.nickname) {
        setAuth({ ...varAuth, nickname: false });
        CheckDuplicationObject('check_nickname', false);
      }
      if (varAuth.oldNickname === value) {
        setAuth({ ...varAuth, nickname: true });
        CheckDuplicationObject('check_nickname', true);
      }
    } else if (name === "push_YN") {
      setCompanyInfo({ ...varCompanyInfo, [name]: e.target.checked === true ? 'Y' : 'N' });
    } else {
      setCompanyInfo({ ...varCompanyInfo, [name]: value });
    }
  };

  //우편번호 & 주소1 가져오기
  const onCompletePost = (data) => {
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") extraAddr += data.bname;
      if (data.buildingName !== "") {
        extraAddr += extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    const zipcode = data.zonecode;
    //정보변경
    setCompanyInfo(varCompanyInfo => ({ ...varCompanyInfo, mcm_zipcode: zipcode, mcm_address1: fullAddr }));
    //창닫기
    setIsOpenPost(false);
    document.getElementById("mcm_address2").focus();
  };

  //우편번호 창 열기/닫기
  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
    //document.getElementById("mem_address1").focus();
  };

  //회원탈퇴 페이지 호출
  const onChangeModeHandler = () => {
    setIsLeave(!isLeave);
  };

  return isLeave ? (
    <Leave onSetIsLeave={onSetIsLeave} />
  ) : (
    <div className="container page_com_myheding01">
      <div>
        <section className="box box_content">
          <h3 className="my_title">정보관리</h3>
          {/* {user_idx === mem_sub ?  */}
          <ProfileC varCompanyInfo={varCompanyInfo} setCompanyInfo={setCompanyInfo} />
          {/* : null} */}
          <ProfileInfo
            varCompanyInfo={varCompanyInfo} setCompanyInfo={setCompanyInfo}
            varAuth={varAuth} setAuth={setAuth}
            onChangeHandler={onChangeHandler}
            onChangeOpenPost={onChangeOpenPost}
            onChangeModeHandler={onChangeModeHandler}
            setTexFile={setTexFile}
            varTexFile={varTexFile}
            user_idx={user_idx}
            mem_sub={mem_sub}
          />
          <div className="btn_bottom_area">
            <button className="btn" id="upateInformation" onClick={() => onUpdateHandler()}>수정완료</button>
            <button className="btn_leave" onClick={(e) => onSetIsLeave(e, true)}>회원탈퇴</button>
          </div>
          {/* 우편번호 팝업창 */}
          <div className="poplayerCompanySearch" style={isOpenPost ? { display: "block" } : { display: "none" }}>
            <div className="consbox" style={{ zIndex: 999 }}>
              <div className="btnclose" onClick={() => setIsOpenPost(false)}><img src={closeImage} alt="" /></div>
              <DaumPostcode
                style={{ width: "100%", height: "500px" }}
                jsOptions={{ animation: true }}
                autoClose={true}
                onComplete={onCompletePost}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MyHeding1;
