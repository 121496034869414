/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import moment from 'moment';
import Clock from "react-live-clock";

function Colck() {
  const setYoilStr = (day) => {
    switch (day) {
      case 0:
        return "일";
      case 1:
        return "월";
      case 2:
        return "화";
      case 3:
        return "수";
      case 4:
        return "목";
      case 5:
        return "금";
      case 6:
        return "토";
      default:
        alert("요일 표시 오류");
    }
  };
  return (
    <React.Fragment>
      <Clock
        format={`YYYY-MM-DD (${setYoilStr(moment().day())}) HH:mm:ss`}
        ticking={true}
        timezone={"Asia/Seoul"}
      />
    </React.Fragment>
  );
}

export default Colck;
