import React from "react";
import { Link } from "react-router-dom";

function Logo(props) {

  return (
    <>
      <h1 className="logo"><img src={props.LogoImg} alt="" /></h1>
      <Link to={localStorage.getItem('mcm_idx')===0?"/main":"/Company"} className="Banner">
      </Link>
    </>
  );
}

export default Logo;
