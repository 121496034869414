import React from "react";
import { changeMobileNumber, changeMobileFormat,cardSerialNumberFormatter,licenseNum } from '../Common/Common';

/*이용권구매>결제>수단*/
function VoucherPayCard({ varBankList,varPaymentInfo,setPaymentInfo }) {

  // console.log("varPaymentInfo", varPaymentInfo);
  //0:무통장, 1:카드
  const onChangePaymentMethod = (e,ino) => {
    if(ino===1) {//신용카드
      if(varPaymentInfo.or_amount < 1){
        alert("무료(0원) 이용권은 '무통장입금'을 이용해주세요.");
      }else{
        document.querySelector('.table_view01').style.display = "none";
        setPaymentInfo({...varPaymentInfo,or_pay_method:ino,bank_idx:0,or_receipt:0,or_deduction:0,or_deduction_val1:'',or_deduction_val2:''});
      }
    } else {//--- 무통장입금
        setPaymentInfo({...varPaymentInfo,or_pay_method:ino});
        document.querySelector('.table_view01').style.display = "";
    }
  }
  //은행선택
  const onChangeBankInfo = (e) => {
      const bank_idx = e.currentTarget.value;
      setPaymentInfo({...varPaymentInfo,bank_idx:bank_idx});
  }
  //결제정보
  const onChangeInput = (e) => {
      const {name,value} = e.currentTarget;
      let valueString = value;
      // console.log("name ",name,"value ",value);
      if (name === "or_deduction_val1") {
        valueString = changeMobileFormat(changeMobileNumber(value));
        setPaymentInfo({...varPaymentInfo,[name]:valueString});
      }else if(name === "or_deduction_val2"){
        valueString = cardSerialNumberFormatter(value);
        setPaymentInfo({...varPaymentInfo,[name]:valueString});
      }else if(name === "or_deduction_val3"){
        valueString = licenseNum(value);
        setPaymentInfo({...varPaymentInfo,[name]:valueString});
      }else if(name === "or_sender"){
        setPaymentInfo({...varPaymentInfo,[name]:value});
      }
  }
  //현금영수증
  const onSelectReceipt = (e,ino) => {
      if(ino === 0) { //신청안함
          document.getElementById('or_deduction_line').style.display = "none";
          document.getElementById('or_deduction_val_line').style.display = "none";
      } else if(ino === 1) {
          document.getElementById('or_deduction_line').style.display = "";
          document.getElementById('or_deduction_val_line').style.display = "none";
      } else {
          document.getElementById('or_deduction_line').style.display = "none";
          document.getElementById('or_deduction_val_line').style.display = "";
      }
      setPaymentInfo({...varPaymentInfo,or_receipt:ino, or_deduction_val1:"", or_deduction_val2:"",or_deduction_val3:""});  
  }
  const onChangeDeduction = (e) => {
      const ino = Number(e.currentTarget.value);
      setPaymentInfo({...varPaymentInfo,or_deduction:ino});
      if(ino === 0) {
        document.getElementById('or_deduction_val1').placeholder='핸드폰 번호를 입력 (-제외)';
        document.getElementById('or_deduction_val1').setAttribute('name', 'or_deduction_val1')
        setPaymentInfo({...varPaymentInfo,or_deduction_val1:"",or_deduction_val2:""});
      }
      else {
        document.getElementById('or_deduction_val1').placeholder='현금영수증 카드번호를 입력 (-제외)';
        document.getElementById('or_deduction_val1').setAttribute('name', 'or_deduction_val2')
        setPaymentInfo({...varPaymentInfo,or_deduction_val1:"",or_deduction_val2:""});
      }
  }

  return (
  <>
    <section className="payment_area">
      <article className="pay_type_select">
          <div className="radio_wrap2">
            <input type="radio" className="chk circle" name="select_payment" id="select_payment1" value="1" onChange={(e)=>onChangePaymentMethod(e,1)} checked={varPaymentInfo.or_pay_method===1?true:false}/>
            <label htmlFor="select_payment1">신용카드</label>
          </div>
          <div className="radio_wrap2">
            <input type="radio" className="chk circle" name="select_payment" id="select_payment2" value="0" onChange={(e)=>onChangePaymentMethod(e,0)} checked={varPaymentInfo.or_pay_method===0?true:false}/>
            <label htmlFor="select_payment2">무통장입금</label>
          </div>
      </article>
      <table className="table_view01"> {/* <!-- 신용카드 선택 시 보이지 않음 --> */}
        <thead></thead>
        <tbody>
          <tr>
            <th>입금계좌</th>
            <td>
              <select className="style_select" value={varPaymentInfo.bank_idx} onChange={(e)=>onChangeBankInfo(e)} >
              {varBankList.map((banks,index) => (
                  <option key={"bank_"+index} value={banks.bank_idx}>{banks.bank_account+'('+banks.bank_name+':'+banks.bank_owner+')'}</option>
              ))}
              </select>
            </td>
          </tr>
          <tr>
            <th>입금자명</th>
            <td><input type="text" name="or_sender" value={varPaymentInfo.or_sender} onChange={(e)=>onChangeInput(e)}/></td>
          </tr>
          <tr>
            <th>현금영수증 신청하기</th>
            <td className="cashreport_area">
              <div className="radio_wrap">
                <input type="radio" name="select_receipt" id="select_receipt01" className="chk circle" onChange={(e)=>onSelectReceipt(e,1)} defaultChecked={varPaymentInfo.or_receipt===1?true:false}/>
                <label htmlFor="select_receipt01">개인정보소득공제</label>
              </div>
              <div className="radio_wrap">
                <input type="radio" name="select_receipt" id="select_receipt02" className="chk circle" onChange={(e)=>onSelectReceipt(e,2)} defaultChecked={varPaymentInfo.or_receipt===2?true:false}/>
                <label htmlFor="select_receipt02">사업자 지출증빙</label>
              </div>
              <div className="radio_wrap">
                <input type="radio" name="select_receipt" id="select_receipt03" className="chk circle" onChange={(e)=>onSelectReceipt(e,0)} defaultChecked={varPaymentInfo.or_receipt===0?true:false}/>
                <label htmlFor="select_receipt03">신청안함</label>
              </div>
              {/* <!-- 개인정보소득공제--> */}
              <div className="cashreport1" id="or_deduction_line" style={varPaymentInfo.or_receipt===1?{display:'block'}:{display:'none'}}>
                <select className="style_select" onChange={(e)=>onChangeDeduction(e)}>
                  <option value="0">휴대폰 번호</option>
                  <option value="1">현금영수증 카드번호</option>
                </select><br/>
                <input type="text" name="or_deduction_val1" id="or_deduction_val1" placeholder="핸드폰번호를 입력 (-제외)"
                  value={(varPaymentInfo.or_deduction_val1===null||varPaymentInfo.or_deduction_val1===""?"":varPaymentInfo.or_deduction_val1) ||
                  (varPaymentInfo.or_deduction_val2===null||varPaymentInfo.or_deduction_val2===""?"":varPaymentInfo.or_deduction_val2)} 
                  onChange={(e)=>onChangeInput(e)}/>
              </div>
              {/* <!-- 사업자 지출증빙 --> */}
              <div className="cashreport2" id="or_deduction_val_line" style={varPaymentInfo.or_receipt===2?{display:'block'}:{display:'none'}}>
                <input type="text" maxlength='12' placeholder="사업자등록번호를 입력 (-제외)" name="or_deduction_val3" id="or_deduction_val3"
                  value={varPaymentInfo.or_deduction_val3===null||varPaymentInfo.or_deduction_val3===""?"":varPaymentInfo.or_deduction_val3} 
                  onChange={(e)=>onChangeInput(e)}/>
              </div>
              {/* <!-- 신청안함이면 없음 --> */}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    </>
  );
}

export default VoucherPayCard;
