import React from "react";
import FindPWC from "../../components/Member/FindPWC";

// css
import "css/Common.css";

const FindPW = () => {
  return <FindPWC />;
};

export default FindPW;
