/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";

import "../../css/Reputation.css";
import resumeSearchButton from "../../img/sub/icon_tip2.png";
import img_repu_main from "img/sub/repu_main.png";

import PopAcqRepuRequest from "components/Career/PopAcqRepuRequest"; //POP '친구가 보는 나' 요청하기
import Chart from "react-apexcharts";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { Link, useNavigate } from "react-router-dom";
import { reputationMatchResultList } from '../../global/global_career';
import PopRepuDel from './PopRepuDel';
import ArrList from "./ArrList";

import Loading from 'components/Common/Loading';
import { onDownloadUrl } from '../Common/Common';
import moment from 'moment';
import ConvertApi from 'convertapi-js'; //PDF 변환 외부 API - KSR

const AcqRepuR = () => {
  const memlogin = localStorage.getItem('memlogin');
  const navigator = useNavigate();
  const [enablePop, setEnablePop] = useState(false); //팝업사용 여부
  const [chartMy, setChartMy] = useState([{}]); // '내가 보는 나', 그래프 데이터
  const [chartFriend, setChartFriend] = useState([{}]); //'타인이 보는 나', 그래프 데이터
  const [options, setOptions] = useState({
    chart: {
      height: 200,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    title: {
      text: "",
    },
    stroke: {
      width: 1,
      //   colors: 'black'
    },
    fill: {
      opacity: 0.2,
      //   colors: ['#F44336', '#E91E63', '#9C27B0'] // rgb?
    },
    markers: {
      size: 5,
      //   colors: ['#ffd966', '#a9d08e', '#f4b084', '#00b0f0', '#c65911'],
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: ['#ffd966', '#a9d08e', '#f4b084', '#00b0f0', '#c65911'],
          fontSize: "15px",
          fontWeight: "700"
          //   fontFamily: 'Arial',
        }
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: 15,
      tickAmount: 3,
    },
    plotOptions: {
      radar: {
        polygons: {
          // strokeColors: 'purple',			
          fill: {
            // colors: ['yellow', 'green']				
          },
          // strokeWidth: 2,
          // connectorColors: 'black',
        }
      }
    },
    tooltip: {
      enabled: false,
    }
  }); // 그래프 옵션
  const [modifyDate, setModifyDate] = useState(""); // '내가 보는 나', 수정일자
  const [active, setActive] = useState("who_see_area_options"); // '타인이 보는 나', 클래스 명
  const [friends, setFriends] = useState([]); // '타인이 보는 나', 타인 목록
  const [selectedFriend, setSelectedFriend] = useState({ answerNo: "", name: "" }); // '타인이 보는 나', 선택된 타인
  const [matchPercentage, setMatchPercentage] = useState(0); // 타인과 나의 성향 일치도
  const [statusBarColor, setStatusBarColor] = useState("step1"); // 성향 일치도 progress bar color
  const [matchResult, setMatchResult] = useState(""); // 성향 일치도 결과 내용
  const [download, setDownload] = useState(false) // 지인평판 다운로드 동의여부
  const [delPopup, setDelPopup] = useState(false) // 친구가 보는나 삭제 팝업 여부
  const [NweselectedFriend, setNewSelectedFriend] = useState([]) // 친구가 보는나 삭제 팝업 여부

  const [Load, setLoad] = useState(false);
  const [LoadText, setLoadText] = useState('다운로드 준비중입니다.');

  const activeClassName = useCallback(() => {
    if (active === "who_see_area_options") {
      setActive((prev) => prev = "who_see_area_options active")
    } else {
      setActive((prev) => prev = "who_see_area_options")
    }
  }, [active]);

  const calcMatchPercentage = useCallback(() => {
    if (chartMy[0]?.data?.length && chartFriend[0]?.data?.length) {
      // const my = myChart[0].data;
      // 단위 %
      // 항목 별   max 20   min 4
      // 모든 항목 max 100  min 20
      let extraversionDiff = 0; // 외향성
      let hardworkingDiff = 0; // 성실성
      let opennessDiff = 0; // 개방성
      let friendlyDiff = 0; // 친화성
      let sensitivityDiff = 0; // 신경성

      extraversionDiff = Math.abs(chartMy[0].data[0] - chartFriend[0].data[0])
      hardworkingDiff = Math.abs(chartMy[0].data[1] - chartFriend[0].data[1])
      opennessDiff = Math.abs(chartMy[0].data[2] - chartFriend[0].data[2])
      friendlyDiff = Math.abs(chartMy[0].data[3] - chartFriend[0].data[3])
      sensitivityDiff = Math.abs(chartMy[0].data[4] - chartFriend[0].data[4])

      const extraversion = (10 - extraversionDiff) * 2;
      const hardworking = (10 - hardworkingDiff) * 2;
      const openness = Math.round((15 - opennessDiff) * (4 / 3));
      const friendly = Math.round((15 - friendlyDiff) * (4 / 3));
      const sensitivity = (10 - sensitivityDiff) * 2;

      const total = (extraversion + hardworking + openness + friendly + sensitivity);
      let className = "";
      if (total <= 25) {
        className = "step1"
      } else if (total <= 50) {
        className = "step2"
      } else if (total <= 75) {
        className = "step3"
      } else {
        className = "step4"
      }
      setStatusBarColor(className);
      setMatchPercentage(total);
      if (total <= reputationMatchResultList[0].key) {
        setMatchResult(reputationMatchResultList[0].value);
      } else if (total < reputationMatchResultList[1].key) {
        setMatchResult(reputationMatchResultList[1].value);
      } else if (total < reputationMatchResultList[2].key) {
        setMatchResult(reputationMatchResultList[2].value);
      } else if (total < reputationMatchResultList[3].key) {
        setMatchResult(reputationMatchResultList[3].value);
      } else if (total < reputationMatchResultList[4].key) {
        setMatchResult(reputationMatchResultList[4].value);
      } else {
        setMatchResult(reputationMatchResultList[5].value);
      }
    }
    else {
      setMatchPercentage(0);
    }
  }, [chartMy, chartFriend]);

  const setFriendGraph = useCallback(async (answerNo) => {
    const memIdx = localStorage.getItem('mem_idx'); // 357
    const params = new URLSearchParams([
      ['mem_idx', memIdx],
      ['myself', 'N'],
      ['answer_no', answerNo],
    ]);
    const result = await axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/graph`, { params });
    try {
      if (result.data?.retvalue) {
        const data = result.data.scores;
        //const scores = data?.map((item) => item.score);
        const scores = data?.map((item) => {
          if (item.score) {
            console.log(item.score);
            if (item.q_type === 3 || item.q_type === 4) {
              //150 * 10 / 100
              return (15 * (Number(`${item.score}`) / 15 * 100) / 100).toFixed();
            } else {
              return (15 * (Number(`${item.score}`) / 10 * 100) / 100).toFixed();
            }

          }
        }
        );

        setChartFriend((prev) => {
          return prev = [
            {
              name: "점수",
              data: scores,
            },
          ]
        });
      }
    } catch (err) {
      console.debug('Error  ', err);
    }
  }, []);

  const changeFriend = useCallback((e) => {
    setSelectedFriend(prev => { return { ...prev, answerNo: e.target.id, name: e.target.textContent } });
    setFriendGraph(e.target.id);
    setActive("who_see_area_options");
  }, [setFriendGraph]);

  const onToggle = useCallback((e) => {
    const setDownloadYN = async () => {
      const params = new URLSearchParams([
        ['mem_idx', localStorage.getItem('mem_idx')],
        ['download', e.target.checked ? 'Y' : 'N'],
      ]);
      const response = await axios.put(`${CONFIG.SERVER_HOST}/acquaintance-reputation/download`, params);
      if (response.data.retvalue === 1) {
        // do something when successful
      } else {
        // do something when it fails
      }
    }
    setDownloadYN();
    setDownload(e.target.checked);
  }, [])

  useEffect(() => {
    const setFriendList = () => {
      const memIdx = localStorage.getItem('mem_idx');
      const params = new URLSearchParams([
        ['mem_idx', memIdx]
      ]);
      axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/friends`, { params }).then((res) => {
        if (res.data?.retvalue) {
          const result = res.data.friends;
          setFriends(result);
          if (result.length) {
            const lastIndex = result.length - 1
            setSelectedFriend(prev => { return { ...prev, answerNo: result[lastIndex].anwser_no, name: result[lastIndex].anwser_writer, anonymous: result[lastIndex].anonymous } });
            setFriendGraph(result[lastIndex].anwser_no);
          }
        }
      }).catch((e) => {
        console.debug(e);
      });
    };

    const setMyGraph = () => {
      const memIdx = localStorage.getItem('mem_idx'); // 357
      const params = new URLSearchParams([
        ['mem_idx', memIdx],
        ['myself', 'Y'],
      ]);

      axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/graph`, { params }).then((res) => {
        if (res.data?.retvalue) {
          const result = res.data.scores;
          //const myGraphData = result?.map((item) => item.score);

          const myGraphData2 = result?.map((item) => {
            if (item.score) {
              console.log(item.score);
              if (item.q_type === 3 || item.q_type === 4) {
                //150 * 10 / 100
                return (15 * (Number(`${item.score}`) / 15 * 100) / 100).toFixed();
              } else {
                return (15 * (Number(`${item.score}`) / 10 * 100) / 100).toFixed();
              }

            }
          }
          );
          setChartMy(prev => prev = [
            {
              name: "점수",
              data: myGraphData2,
            },
          ]);
          setModifyDate((prev) => prev = result[0]?.date);
          setOptions((prev) => {
            return { ...prev, xaxis: { categories: result?.map((item) => item.label) } };
          });
        }
      }).catch((e) => {
        console.debug(e);
      });
    };
    setMyGraph();
    setFriendList();
  }, [setFriendGraph]);

  useEffect(() => {
    calcMatchPercentage();
  }, [calcMatchPercentage]);

  useEffect(() => {
    const getDownloadYN = async () => {
      const params = new URLSearchParams([
        ['mem_idx', localStorage.getItem('mem_idx')]
      ]);
      const response = await axios.get(`${CONFIG.SERVER_HOST}/acquaintance-reputation/download`, { params });
      if (response.data.retvalue === 1) {
        // console.debug(response.data);
        const downloadYN = response.data.result?.download_YN || 'N';
        setDownload(downloadYN === 'Y' ? true : false);
      }
    }
    getDownloadYN();
  }, [download])

  //회원정보 불러오기
  useEffect(() => {
    if (memlogin === undefined || memlogin === null || memlogin === false) {
      alert("로그인 후 이용 가능합니다.");
      navigator("/Login");
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openDelPop = () => {
    setDelPopup(true);
  }

  useEffect(() => {
    //dataList;
    console.log('배열 변화!!', friends);
    console.log('배열 변화!!', NweselectedFriend);
    console.log(Object.is(NweselectedFriend, friends)); // false
    setFriends(NweselectedFriend);
  }, [NweselectedFriend]);

  const toDay = moment(new Date()).format('YYYY.MM_DD');

  //2024-06-02 지인평판(PDF) 다운로드 외부 API 사용 - KSR
  const handleClickRepuDnPdf2 = async () => {
    console.log('handleClickRepuDnPdf');
    const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
    const params = convertApi.createParams();
    const pdfUri = `${CONFIG.PdfReputation2Uri}/${localStorage.getItem('mem_idx')}`;
    params.add('url', pdfUri);
    params.add('ConversionDelay', '6');
    params.add('MarginTop', '15');
    params.add('MarginBottom', '15');
    params.add('MarginRight', '0');
    params.add('MarginLeft', '0');
    params.add('ViewportWidth', '1400');
    //params.add('ShowElements', '.container');

    console.log("pdfUri", pdfUri);

    setLoad(true);
    setLoadText('지인평판 다운로드 준비중입니다...');
    console.log("PDF생성을 시작합니다.");
    convertApi
      .convert('web', 'pdf', params)
      .then((result) => {
        const url = result.files[0].Url;
        console.log("PDF URL", url);
        if (!window.ReactNativeWebView) {
          onDownloadUrl(url, `지인평판_${localStorage.getItem('mem_name')}_${toDay}.pdf`);
        } else {
          //앱으로 전달
          const file_name = `지인평판_${localStorage.getItem('mem_name')}_${toDay}.pdf`;
          const file_url = url;
          const params = {
            file_name,
            file_url
          };
          const paramsToString = JSON.stringify(params);
          window.ReactNativeWebView.postMessage(paramsToString);
        }
      })
      .catch((err) => {
        alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
        console.log("PDF error", err);
        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
        //document.documentElement.style.cursor = 'default'
      })
  }

  //const dataList = friends.map((item) => { return (<li key={item.anwser_no} id={item.anwser_no} onClick={changeFriend}>{item.anonymous ? item.anonymous : item.anwser_writer}</li>) });
  return (
    <>
      {Load === true ? <Loading text={LoadText} /> : null}
      {enablePop && <PopAcqRepuRequest setEnablePop={setEnablePop} /> /* 요청하기 팝업 */}

      {/**** 본 화면에 '지인에게 요청하기' 버튼이 없어서 임시로 둠 - KSR **********/}
      <section className="com_reputation com_repu_main p_acq_repu com_repu_gray_container center_wrap">
        <article className="repu_top_info_wrap">
          <img src={img_repu_main} alt="평판 이미지" />
          <p className="requtation_txt black">
            친구들이 보는 나는?
            <br />
            내가 보는 나와 얼마나 다른지 알 수 있습니다.
          </p>
          <h1 className="requtation_txt blue bold">친구들에게 테스트를 요청해보세요</h1>
          <div className="repu_go_btn_wrap">

            <Link to={'../acquaintance-reputation/submit'} className="btn">내가 보는 나 시작하기</Link>
            <button className="btn navy" onClick={() => { setEnablePop(true); }}>
              친구가 보는 나 요청하기
            </button>
          </div>
        </article>
      </section>
      {/*// 임시 - KSR **********************/}

      {friends?.length > 0 && (
        <div id="sub_wrap" className="page_myresume_view">
          <div className="pdf-area center_wrap">
          <button className="btn upperline" onClick={() => handleClickRepuDnPdf2()}>지인평판 다운받기</button>
          </div>
        </div>
      )}
      <section className="com_reputation com_repu_main p_acq_repu_r center_wrap">
        <article className="com_repu_gray_container">
          <section className="left_section">
            <div className="who_see_container">
              <div className="who_see_area">
                <span className="txt_blue">{selectedFriend?.name}</span>님이 보는 나<i className="icon_arrow" onClick={activeClassName}></i>
              </div>
              {/* select 옵션 - active 클래스 추가 시 show */}
              <ul className={active} >
                <ArrList friends={friends} changeFriend={changeFriend} />
              </ul>
            </div>
            <div className="acq_repu_graph">
              <Chart options={options} series={chartFriend} type="radar" height={350} />
            </div>
            {friends && friends.length > 0 ?
              <div>
                <button className="btn" onClick={() => openDelPop()}>친구가 보는 나 삭제하기</button>
              </div>
              :
              ``
            }

          </section>

          <section className="right_section">
            <div className="who_see_area">
              <span className="txt_blue">내가&nbsp;</span>보는 나<span className="update_date">수정일자: {modifyDate}</span>
            </div>
            <div className="acq_repu_graph">
              <Chart options={options} series={chartMy} type="radar" height={350} />
            </div>
          </section>
        </article>
        <article className="repu_compare_container">
          <div className="repu_compare_txt">
            <h1>
              내가 보는 나, 남이 보는 나 : <span>일치도</span>{matchPercentage}%
            </h1>
          </div>
          <article className={`resume_progress ${statusBarColor}`}>
            <div className="resume_progress_bar" style={{ maxWidth: `${matchPercentage}%` }}></div>
          </article>
        </article>
        <article className="repu_review_container">
          <div className="oneline_review">
            <h1>{matchResult}</h1>
            <Link className="btn" to="../career-reputation">동료 평판 진행하기</Link>
            <p>본 결과는 뉴캐슬 대학에서 개발한 성격5요인 모델 기반 약식 성격 검사(NPA, Newcastle Personality Assessor)기반으로 작성되었습니다.</p>
          </div>
        </article>
        <article className="repu_review_explain">
          <h1>상황별 설명</h1>
          <table>
            <colgroup>
              <col width="12%" />
              <col width="38%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
            <tr>
              <th>요인</th>
              <th>측정내용</th>
              <th>낮을 수록</th>
              <th>높을 수록</th>
            </tr>
            <tr>
              <td>외향성</td>
              <td>대인관계에서의 상호작용 정도와 강도를 측정. 즉, 활동수준, 자극에 대한 욕구, 즐거움 능력 등을 측정</td>
              <td>사람들과 어울리지 않고 조용함</td>
              <td>사람들과 잘 어울리며 열정적임</td>
            </tr>
            <tr>
              <td>친화성</td>
              <td>사고, 감정, 행동측면에서 동정심 부터 적대감까지의 연속선상에 개인의 대인관계 지향성을 측정</td>
              <td>비협조적이며 타인에게 적대적임</td>
              <td>공감능력이 뛰어나며, 다른 사람들을 잘 믿는편임</td>
            </tr>
            <tr>
              <td>성실성</td>
              <td>목표에 대한 자발적 행동에서의 조직, 끈기, 동기를 부여하는 정도를 측정</td>
              <td>충동적인 경향이 강하며 부주의함</td>
              <td>일처리에 있어 체계적이며 자발적인 경향이 있음</td>
            </tr>
            <tr>
              <td>신경성</td>
              <td>적응과 정서적 불안정을 측정, 심리적 스트레스 비현실적인 이상을 측정</td>
              <td>안정적인 정서를 지니고 있으며 감정적으로 차분함</td>
              <td>스트레스를 잘 받고 걱정을 많이 함</td>
            </tr>
            <tr>
              <td>개방성</td>
              <td>자신의 경험을 주도적으로 추구하고 평라하는지 여부를 측정</td>
              <td>실용적이며 보수적이고 전통적임</td>
              <td>창조적이며 독창적임</td>
            </tr>
          </table>
        </article>
        <article className="repu_tip_container">
          <div className="myresume_tip">
            <h3 className="title_area">
              <img src={resumeSearchButton} alt="" />
              지인 평판 노출 장점
            </h3>
            <ul className="list">
              <li>헤드헌터와 기업에게 나의 성향을 한번에 보여줘서 면접 시 성향을 어필할 수 있습니다.</li>
            </ul>
          </div>
          <div className="repu_setting_container">
            <p className="text">지인 평판이 기업회원과 헤드헌터에게 다운받아지는 것을 동의합니다.</p>
            <div className="setting">
              <input type="checkbox" id="btnToggle1" checked={download} onClick={onToggle} />
            </div>
          </div>
        </article>
      </section>
      <PopRepuDel 
        delPopup={delPopup} 
        setDelPopup={setDelPopup} 
        answer_no={selectedFriend?.answerNo} 
        name={selectedFriend?.name} 
        setNewSelectedFriend={setNewSelectedFriend} 
        friends={friends} 
        setSelectedFriend={setSelectedFriend} 
        setFriendGraph={setFriendGraph}
        setActive={setActive}
      />
    </>
  );
};

export default AcqRepuR;
