/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import moment from 'moment'; 

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import Paging from '../../../components/Layout/Paging'
import { ChangeMoneyUnit, /*changeEmMode*/ } from '../../../components/Common/Common'
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';
import AccumulateButton from 'admin/pages/board/AccumulateButton';
import Modal from 'react-modal';
import Popup from './Popup';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '1100px',
    height: '70vh'
  },
};


function AccumulateList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜
    const [varHmtype, setHmtype] = useState("");  //검색->헤딩머니 상태(적립,환전,회수) 2023-03-17 KSR
    const [varSearch, setSearch] = useState("none");
    const [varSearchInput, setSearchInput] = useState("");
    const [varPageSize, setPageSize] = useState(10);
    const [selectChange, setSelectChange] = useState(false);

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageNum,setPageNum] = useState(1);
    const [varFilterlist, setFilterlist] = useState([]); 

    const [modalOpen, setModalOpen] = useState(false);
    // const [searchText, setSearchText] = useState("");
    

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //최초 - 헤딩머니 입출금내역 가져오기
    const getHedingMoneyList = async (iMode) => {
      let sendData;
      try {
        if(iMode !== 1){
          sendData = {
            'currentPage': varPageNum,
            'pageSize': varPageSize,
            'sdate': startDate ? moment(startDate).format(CONFIG.formatStr) : "",
            'edate':startDate ? moment(endDate).format(CONFIG.formatStr) : "",
            'search_hmtype': varHmtype, //상태(적립,환전,회수) 2023-03-17 KSR
            'search_option': varSearch,
            'search_string': varSearchInput
          }
        }else{
          sendData = {
            'currentPage': varPageNum,
            'pageSize': varPageSize,
            'sdate': "",
            'edate': "",
            'search_hmtype': varHmtype, //상태(적립,환전,회수) 2023-03-17 KSR
            'search_option': "none",
            'search_string': ""
          }
        }
        
        const config = {"Content-Type": 'application/json'};
        await axios.post(`${CONFIG.SERVER_HOST}/AdminAccumulateList`,sendData, config).then((res) => {
            if(res.data.retvalue === 1) {
                setTotalCount(res.data.totalcount); 
                setFilterlist(res.data.results);
                if(selectChange){
                  setSelectChange(false);
                  setPageNum(1);
                }
            }
        });
      } catch (error) {
        console.log(error);
      }
    }

    const handleHmtype = (e) =>{//검색->상태
      setHmtype(e.currentTarget.value);
    };
    const handleType = (e) =>{//검색->구분
        setSearch(e.currentTarget.value);
    };
    const handleInput = (e) =>{//검색-검색어
        setSearchInput(e.currentTarget.value);
    };

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setSearch("none");
        setSearchInput("");
        setPageNum(1);
        getHedingMoneyList(1);
    }

    useEffect(() => {
      getHedingMoneyList();
    },[varPageNum]);
    
    useEffect(() => {
      getHedingMoneyList();
    },[varPageSize]);

    const handlestartdate =(e)=>{
        setStartDate(e.currentTarget.value);
    }
    const handleenddate =(e)=>{
        setEndDate(e.currentTarget.value);
    }
    const settingPageSize = (e) =>{
      const {value} = e.target;
      setPageSize(Number(value));
      setSelectChange(true);
    };

    const tyValue = (ty) =>{
      let tyStr = "";
      switch(ty){
        case 1:
          tyStr = "적립";
          break;
        case 2:
          tyStr = "환전";
          break;
        case 3:
          tyStr = "회수";
          break;
        default:
          tyStr = "-";
          break;
      }
      return tyStr;
    }



    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="users"/>
        <Menu menuType="users" menu="accumulateList"/>
        <div id="contents" className="ad_contents">
        <div className="tit">적립내역
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>헤딩머니<img src={arrImage} alt=""/>적립내역
            </div>
        </div>
        <div className="content">
            <div className="main-box">		
                <div className="search-box">
                    <div className="f-right">		
                      <div className="day-box">
                          <p>적립일자</p>
                          <input type="date"  name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                          ~ 
                          <input type="date"  name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                      </div>
                    </div> 
                    <div className="wd100">
                        상태&nbsp;:&nbsp;&nbsp;
                        <select style={{width:"120px"}} name="hmtype" value={varHmtype} onChange={handleHmtype}>
                          <option value="none">전체</option>
                          <option value="1">적립</option>
                          <option value="2">환전</option>
                          <option value="3">회수</option>
                        </select>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        검색조건&nbsp;:&nbsp;&nbsp;
                        <select style={{width:"160px"}} name="search" value={varSearch} onChange={handleType}> 
                            <option value="none">전체</option>
                            <option value="mem_id">아이디</option>
                            <option value="mem_name">이름</option>
                            {/* <option value="mem_mode">회원유형</option> */}
                            <option value="mcm_name">회사명</option>
                            {/* <option value="vhs_name">구분</option> */}
                        </select>
                        <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') getHedingMoneyList(e)}} />
                        <button onClick={getHedingMoneyList} className="btn">검색</button>
                        <button onClick={handleSearchClear} className="btn black">초기화</button>				
                    </div>
                </div>
            </div>
            <div className="list_top">
              <p>검색건수 : {varTotalCount}
                <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                    <option value="10">10개</option>
                    <option value="30">30개</option>
                    <option value="50">50개</option>
                    <option value="100">100개</option>
                </select>
              </p>
              <div className="btn_area">
                <ExcelButton 
                  startDate={startDate}
                  endDate={endDate}
                  option={varSearch}
                  varSearchInput={varSearchInput}
                  type={6}
                  />
                <AccumulateButton setModalOpen={setModalOpen}/>
              </div>
            </div>
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                      <col style={{width:"5%"}} />
                      <col style={{width:"15%"}} />
                      <col style={{width:"7%"}} />
                      <col style={{width:"7%"}}/>
                      <col style={{width:"20%"}}/>
                      <col style={{width:"25%"}}/>
                      <col style={{width:"12%"}}/>
                      <col style={{width:"12%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"8%"}}/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>닉네임</th>
                        <th>이름</th>
                        <th>회원유형</th>
                        <th>회사명</th>
                        <th>구분</th>
                        <th>상태</th>
                        <th>금액</th>
                        <th>적립 일자</th>
                        <th>환전 가능일</th>
                        <th>관리</th>
                      </tr>
                    </thead>
                    <tbody>
                    {varFilterlist?.map((item,i) => (
                      <tr key={i}>
                        {/* <td>{varTotalCount-(varPageNum-1)*varPageSize -i}</td> */}
                        <td>{item.ROWNUM}</td>
                        <td className="ellipsis">{item.mem_nickname}</td> 
                        <td className="ellipsis">{item.mem_name}</td> 
                        <td> 
                          {isNaN(item.mem_mode) ? item.mem_mode : item.mem_mode === 0 ? "일반" : item.mem_mode === 1 ? "기업" : item.mem_mode === 2 ? "헤드헌터" : "-"}
                        </td> 
                        <td className="ellipsis">{item.mcm_name ? item.mcm_name : "-"}</td> 
                        <td className="ellipsis">{item.vhs_name}</td> 
                        <td className="ellipsis">{tyValue(item.mh_type)}</td> 
                        <td className="ellipsis">{ChangeMoneyUnit(item.mh_paid / 10000)}</td>
                        <td className="ellipsis">{item.mh_rdate===null?"":item.mh_rdate.substr(0,10)}</td> 
                        <td className="ellipsis">{item.mh_pdate===null?"":item.mh_pdate.substr(0,10)}</td> 
                        <td>
                          <Link className="btn line black"to={`/admin/accumulateinfo/${item.mem_idx}`}state={item.mh_idx}>상세</Link>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>

    <Modal
        isOpen={modalOpen}
        style={customStyles}
        ariaHideApp={false}
      >
        <Popup setModalOpen={setModalOpen} getHedingMoneyList={getHedingMoneyList}/>
      </Modal>
    </React.Fragment>

    )

}

export default AccumulateList;