/////////////
//서브상단 비주얼+서브타이틀 
////////////
//css

function SubVisual(props) {
  return (
    <div id="SubVisual" className={props.SubVisual_className}>
      <div className="CenterWrap" >
        <h2>{props.PageTitle}</h2>
      </div>
    </div>
  );
}

export default SubVisual;
