/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/configs';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';


function Job_Register() {
    const [Mem_id, setMem_id] = useState("");
    const [Password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [Type, setType] = useState(0);
    const [NickName, setNickName] = useState("");
    const [UserName, setUserName] = useState("");
    const [UserGender, setUserGender] = useState("");
    const [UserPhone, setUserPhone] = useState("");
    const [Self_Authentication, setSelf_Authentication] = useState("");
    const [UserEmail, setUserEmail] = useState("");
    const [User_ID, setUser_ID] = useState(""); //주민
    const [UserPost, setUserPost] = useState("");
    const [UserAddress1, setUserAddress1] = useState("");
    const [UserAddress2, setUserAddress2] = useState("");
    const [UserPic, setUserPic] = useState("");
    const [UserFile, setUserFile] = useState("");
    const [UserPrivacy, setUserPrivacy] = useState("");
    const [Identification, setIdentification] = useState("");
    const [User_Sub, setUser_Sub] = useState("");
    const [UserTerms, setUserTerms] = useState("");
    const [UserState, setUserState] = useState("");

    const onMem_idHandler = (e) => {
        setMem_id(e.currentTarget.value);
    };
    const onPasswordHanlder = (e) => {
        setPassword(e.currentTarget.value);
    };
    const onConfirmPasswordHanlder = (e) => {
        setConfirmPassword(e.currentTarget.value);
    };
    const onNicknameHandler = (e) => {
        setNickName(e.currentTarget.value);
    };
    const onUserNameHandler = (e) => {
        setUserName(e.currentTarget.value);
    };
    const onUserGenderHandler = (e) => {
        setUserGender(e.currentTarget.value);
    };
    const onUserPhoneHandler = (e) => {
        setUserPhone(e.currentTarget.value);
    };
    const onSelf_AuthenticationHandler = (e) => {
        setSelf_Authentication(e.currentTarget.value);
    };
    const onUserEmailHandler = (e) => {
        setUserEmail(e.currentTarget.value);
    };
    const onUser_IDHandler = (e) => {
        setUser_ID(e.currentTarget.value);
    };
    const onUserPostHandler = (e) => {
        setUserPost(e.currentTarget.value);
    };
    const onUserAddress1Handler = (e) => {
        setUserAddress1(e.currentTarget.value);
    };
    const onUserAddress2Handler = (e) => {
        setUserAddress2(e.currentTarget.value);
    };
    const onUserPicHandler = (e) => {
        setUserPic(e.currentTarget.value);
    };
    const onUserFileHandler = (e) => {
        setUserFile(e.currentTarget.value);
    };
    const onUserPrivacyHandler = (e) => {
        setUserPrivacy(e.currentTarget.value);
    };
    const onIdentificationHandler = (e) => {
        setIdentification(e.currentTarget.value);
    };
    const onUser_SubHandler = (e) => {
        setUser_Sub(e.currentTarget.value);
    };
    const onUserTermsHandler = (e) => {
        setUserTerms(e.currentTarget.value);
    };
    const onUserStateHandler = (e) => {
        setUserState(e.currentTarget.value);
    };


    //중복체크
    const onCheckId = (e) => {
        e.preventDefault();
        if (Mem_id === "") {
            alert("아이디를 입력해주세요")

        } else {
            const params = new URLSearchParams();
            params.append("mem_id", Mem_id);
            axios.post(`${CONFIG.SERVER_HOST}/checkid`, params).then((res) => {
                console.log(res.data);
                if (res.data[0].count === 1) {
                    alert("중복된 아이디가 있습니다");
                } else {
                    alert("사용해도되는 아이디입니다");
                }

            });
        }
    }

    const handleType = (e) => {
        setType(e.target.value);

    };

    const onSubmitHandler = () => {
        const param = new URLSearchParams();
        param.append("Mem_id", Mem_id);
        param.append("Password", Password);
        param.append("Type", Type);
        param.append("NickName", NickName);
        param.append("UserName", UserName);
        param.append("UserGender", UserGender);
        param.append("UserPhone", UserPhone);
        param.append("Self_Authentication", Self_Authentication);
        param.append("UserEmail", UserEmail);
        param.append("User_ID", User_ID);
        param.append("UserPost", UserPost);
        param.append("UserAddress1", UserAddress1);
        param.append("UserAddress2", UserAddress2);
        param.append("UserPic", UserPic);
        param.append("UserFile", UserFile);
        param.append("UserPrivacy", UserPrivacy);
        param.append("Identification", Identification);
        param.append("User_Sub", User_Sub);
        param.append("UserTerms", UserTerms);
        param.append("UserState", UserState);

        axios.post(`${CONFIG.SERVER_HOST}/userinsert`, param).then((res) => {
            console.log(res);
            if (res.data === 2) {
                alert("회원정보가 수정되었습니다")
            }
        });
    }

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="users" />
            <Menu menuType="users" menu="freeuserList" />
            <div id="contents" className="ad_contents">
                <div className="tit">공고관리
                    <div className="path">
                        <img src={iconhome} alt=""/>
                        HOME<img src={arrImage} alt=""/>공고관리<img src={arrImage} alt=""/>채용 공고 상세
                    </div>
                </div>
                <div className="content">
                    <div className="tit">회원 상세</div>
                    <div className="main-box" style={{ width: '60%' }}>
                            <table className="write">
                                <tr>
                                    <th>아이디</th>
                                    <td>
                                        <input type="text" name="code" value={Mem_id} onChange={onMem_idHandler} maxLength="30" />
                                        <button className="btn-right" onClick={onCheckId}><button>중복체크</button></button>
                                    </td>

                                </tr>
                                <tr>
                                    <th>비밀번호</th>
                                    <td>
                                        <input type="password" name="code" value={Password} onChange={onPasswordHanlder} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호 확인</th>
                                    <td>
                                        <input type="password" name="code" value={confirmPassword} onChange={onConfirmPasswordHanlder} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>회원종류</th>
                                    <td>
                                        <select className="short_select" name="Type" value={Type} onChange={handleType}>
                                            <option value="0">일반</option>
                                            <option value="1" >기업</option>
                                            <option value="2" >헤드헌터-프리랜서</option>
                                            <option value="3" >헤드헌터-서치펌</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>닉네임</th>
                                    <td>
                                        <input type="text" name="NickName" value={NickName} onChange={onNicknameHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>회원명</th>
                                    <td>
                                        <input type="text" name="UserName" value={UserName} onChange={onUserNameHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>성별</th>
                                    <td>
                                        <input type="text" name="UserGender" value={UserGender} onChange={onUserGenderHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>핸드폰번호</th>
                                    <td>
                                        <input type="text" name="UserPhone" value={UserPhone} onChange={onUserPhoneHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>본인인증</th>
                                    <td>
                                        <input type="text" name="Self_Authentication" value={Self_Authentication} onChange={onSelf_AuthenticationHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td>
                                        <input type="text" name="UserEmail" value={UserEmail} onChange={onUserEmailHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>주민</th>
                                    <td>
                                        <input type="text" name="User_ID" value={User_ID} onChange={onUser_IDHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>우편번호</th>
                                    <td>
                                        <input type="text" name="UserPost" value={UserPost} onChange={onUserPostHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>주소1</th>
                                    <td>
                                        <input type="text" name="UserAddress1" value={UserAddress1} onChange={onUserAddress1Handler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>주소2</th>
                                    <td>
                                        <input type="text" name="UserAddress2" value={UserAddress2} onChange={onUserAddress2Handler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>이력서 사진유무</th>
                                    <td>
                                        <input type="text" name="UserPic" value={UserPic} onChange={onUserPicHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>사진파일명</th>
                                    <td>
                                        <input type="text" name="UserFile" value={UserFile} onChange={onUserFileHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>약관승인</th>
                                    <td>
                                        <input type="text" name="UserTerms" value={UserTerms} onChange={onUserTermsHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>개인정보취급</th>
                                    <td>
                                        <input type="text" name="UserPrivacy" value={UserPrivacy} onChange={onUserPrivacyHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>본인확인서비스</th>
                                    <td>
                                        <input type="text" name="Identification" value={Identification} onChange={onIdentificationHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>서브회원</th>
                                    <td>
                                        <input type="text" name="User_Sub" value={User_Sub} onChange={onUser_SubHandler} maxLength="30" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>회원상태</th>
                                    <td>
                                        <input type="text" name="UserState" value={UserState} onChange={onUserStateHandler} maxLength="30" />
                                    </td>
                                </tr>
                            </table>
                    </div>
                    <div className="btn-right">
                        <button className="btn-danger" onClick={onSubmitHandler}>등록</button>
                        <button className="btn-danger" ><Link to="/admin/userlist" style={{ color: "#fff" }}>목록</Link></button>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )

}

export default Job_Register;