/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import Paging from '../../../components/Layout/Paging'

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

function FaqList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    //목록표시부-------------------------------------------------------------------------------------------
    const [varFaqList, setFaqList] = useState([]);
    const [varCheckedList, setCheckedList] = useState([]);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function readFaqList(cPage) {
        var sendData = {};
        Object.assign(sendData,{currentPage:cPage});
        Object.assign(sendData,{pageSize:varPageSize});
        console.log(sendData);
        const params = new URLSearchParams();
        params.append("sendData",JSON.stringify(sendData));
        axios.post(`${CONFIG.SERVER_HOST}/faqlist`,params).then((res) => {
            //console.log(res.data);
            if(res.data.retvalue === 1) {
                setTotalCount(res.data.totalcount);
                const data = res.data.results.map((data,i)=>(                
                    {  
                        board_idx: data.board_idx,
                        order: res.data.totalcount-(varPageNum-1)*varPageSize - i,
                        vfs_idx:data.vfs_idx,
                        vfs_name:data.vfs_name,
                        board_question:data.board_question,
                        board_rdate:data.board_rdate
                    }
                ))
                setFaqList(data);
            } else {
                setTotalCount(0);
                setFaqList([]);
                window.alert(res.data.message);
            }
            
        });
    }

    //페이지가 바뀌면
    useEffect(() => {
        readFaqList(varPageNum);
    },[varPageNum]);

    //전체 선택
    const onCheckedAll = (e) => {
        if (e.currentTarget.checked) {
            const checkedListArray = [];
            varFaqList.forEach((board) => checkedListArray.push(board));
            setCheckedList(checkedListArray);
        } else {
            setCheckedList([]);
        }
    }
    //개별선택 
    const onCheckedElement = (e, board) => {
        if (e.currentTarget.checked) {
            setCheckedList([...varCheckedList, board]);
        } else {
            setCheckedList(varCheckedList.filter((el) => el !== board));
        }
    }
    
    //선택 항목 삭제
    const deleteFaqList = (e) =>{
        const delitems = varCheckedList.length;
        if(delitems === 0) {
            window.alert('삭제할 정보가 선택되어 있지 않습니다.'); return;
        }
        if(!window.confirm(delitems+'개의 선택된 정보를 삭제합니까?')) return;

        //console.log(varCheckedList);
        const params = new URLSearchParams();
        params.append("delitems",JSON.stringify(varCheckedList));
        axios.post(`${CONFIG.SERVER_HOST}/faq_delete`,params).then((res) => {
            if(res.data.retvalue === 1) {
                readFaqList(varPageNum);              
            } else {
                window.alert('오류가 발생했습니다.');
            }
        });
    }


    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="board"/>
        <Menu menuType="board" menu="faqlist"/>
        <div id="contents" className="ad_contents">
            <div className="tit">FAQ 관리 
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>FAQ 관리<img src={arrImage} alt=""/>FAQ 관리
                </div>
            </div>
            <div className="content">        
                <div className="main-box2">
                    <div className="btn-right">
                        <Link to="/admin/faqdetail/0" className="btn">등록</Link>
                        <button  onClick={deleteFaqList} className="btn">삭제</button>
                    </div>
                </div>                
                <div className="main-box ">       
                    <table className="list">
                        <colgroup>
                            <col style={{width:"4%"}} />
                            <col style={{width:"8%"}} />
                            <col style={{width:"14%"}} />
                            <col style={{width:"66%"}}/>
                            <col style={{width:"8%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="board-list">
                                <input type="checkbox" onChange={(e)=>onCheckedAll(e)} id="deleteItems"
                                checked={varCheckedList.length===0?false:varCheckedList.length===varFaqList.length?true:false} />
                                </th>
                                <th>번호</th>
                                <th>유형</th>
                                <th>질문</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {varFaqList.map((board,i) => (
                                <tr key={board.board_idx}>
                                    <td className="board-list">
                                        <input type="checkbox" 
                                        id={"deleteItem"+i}
                                        onChange={(e)=>onCheckedElement(e,board)} 
                                        checked={varCheckedList.includes(board)?true:false}/>
                                    </td>
                                    <td><p>{board.order}</p></td>
                                    <td><p>{board.vfs_name}</p></td>
                                    <td className="ellipsis text_left"><p>{board.board_question}</p></td>
                                    <td><Link to={`/admin/faqdetail/${board.board_idx}`} className="btn line black">상세</Link></td>
                                </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
                <div>
                    <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                </div>
            </div>        
        </div>
        </React.Fragment>
    )
}

export default FaqList;