/* eslint-disable jsx-a11y/anchor-is-valid */
//데이트피커
import React, { useState, useEffect } from "react";
import { ChangeSearchingDateFormat } from '../Common/Common';
//css
import "react-datepicker/dist/react-datepicker.css";
//이미지
// import search from "img/sub/search.png";
import CustomDatePicker from "components/Common/CustomDatePicker";

/*마이헤딩머니*/
function MyMoneySearch({ varHedingMoney, setHedingMoney }) {
  //항목별 검색변수값 설정
  const [varStartDate, setStartDate] = useState(varHedingMoney.sdate === null ? null : new Date(varHedingMoney.sdate));
  const [varEndDate, setEndDate] = useState(varHedingMoney.edate === null ? null : new Date(varHedingMoney.edate));
  const [varHMType, setHMType] = useState(varHedingMoney.type);
  const [varCompanyName, setCompanyName] = useState(varHedingMoney.company);

  //검색구분
  const onChangeHMType = (e) => {
    setHMType(Number(e.currentTarget.value));
  }
  //검색항목 회사명 저장
  const ChangeCompanyName = (e) => {
    setCompanyName(e.currentTarget.value);
  }
  //검색시작
  const onSearchListHandler = (e) => {
    // e.preventDefault();
    const newData = {
      type: varHMType,
      sdate: ChangeSearchingDateFormat(varStartDate),
      edate: ChangeSearchingDateFormat(varEndDate),
      company: varCompanyName
    }
    setHedingMoney(newData);
  }
  //검색기간 설정값 저장
  const onChangeDateHandler = (ino, date) => {
    if (date === "") date = null;
    if (ino === 0) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  }

  useEffect(() => {
    onSearchListHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varHMType]);

  return (
    <div className="list_top">
      <div className="search_wrap">
        {/*구분*/}
        <div className="wrap">
          <p className="title_field">구분</p>
          <select className="style_select" onChange={(e) => onChangeHMType(e)} defaultValue="0">
            <option value="0">전체</option>
            <option value="2">환전내역</option>
            <option value="1">적립내역</option>
          </select>
        </div>
        {/*지급일자*/}
        <div className="wrap">
          <p className="title_field">지급일자</p>
          <CustomDatePicker
            varStartDate={varStartDate}
            varEndDate={varEndDate}
            onChangeDateHandler={onChangeDateHandler}
          />
        </div>
        {/*검색*/}
        <div className="list_search_area">
          <p className="title_field"></p>
          <div className="search">
            <input type="text" placeholder="검색어를 입력하세요." value={varCompanyName} onChange={(e) => ChangeCompanyName(e)} onKeyDown={(e) => { if (e.key === 'Enter') onSearchListHandler(e) }} />
            {/* <button className="btn_search" onClick={(e) => onSearchListHandler(e)}><img src={search} alt="search" /></button> */}
          </div>
          <button className="btn" onClick={(e) => onSearchListHandler(e)}>조회</button>
        </div>
      </div>
    </div>
  );
}

export default MyMoneySearch;
