/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import CONFIG from "configs/configs";
//import Paging2 from "components/Layout/Paging2";
import { ChangeMoneyUnit } from "components/Common/Common";
// import MainLogoIcon from "img/common/logo.png";
import WonLogoIcon_pc from "img/main/won.png";
import WonLogoIcon_m from "img/main/won_m.png";

const defaultCompanyLogo = `${CONFIG.SERVER_HOST}/mcm_image/default_company.png`; // 디폴트 이미지.

const RecruitMainList2 = ({
  PageFolder,
  varWorkingList,
  varRegionList,
  varCareerList,
  varSearchString,
  cate,
  varEmployType,
  varSortMode,
  varExecuteSearch,
  setExecuteSearch,
  totalList,
  employSearchLists,
  getAxio
}) => {
  const user_idx = localStorage.getItem("mem_idx");
  const location = useLocation();

  const [varOriginLists, setOriginLists] = useState([]);
  const [, setFilterLists] = useState([]);

  const [pageTitle, setPageTitle] = useState('공개채용');

  //필터링 - 검색조건 변경시
  const filteringJobPosting = useCallback(async () => {
    var searchSelect = false;
    try {
      var filterLists = [...varOriginLists];

      var searchFilterLists = [];
      var tasksFilterLists = [];
      var regionFilterLists = [];
      var careerFilterLists = [];

      //검색어&검색옵션
      if (varSearchString !== undefined && varSearchString !== null && varSearchString !== "") {
        searchSelect = true;
        //전체
        searchFilterLists = filterLists.filter(
          (element) =>
            element.mcm_name.includes(varSearchString) || element.em_title.includes(varSearchString)
        );
      }

      //직무검색조건 변경시
      // 직무가 복수 변경 되면서 배열처리로 변경
      if (varWorkingList.length > 0) {
        searchSelect = true;
        tasksFilterLists = filterLists.filter((element) => {
          for (var i = 0; i < varWorkingList.length; i++) {
            var checks = false;
            const tempTasks = element.em_tasks_info ? element.em_tasks_info.split(', ') : [];
            const tasksList = tempTasks.map((e) => {
              return e.split('||');
            });

            for (var tmpi = 0; tmpi < tasksList.length; tmpi++) {
              if (Number(tasksList[tmpi][1]) === Number(varWorkingList[i].sid2)) {
                checks = true;
              }
            }

            if (checks) {
              return element;
            }
          }
        });
      }

      //지역검색조건 변경시
      const findIndex = varRegionList.findIndex(e => e.vr_code === 0);
      if (findIndex === -1 && varRegionList.length > 0) {
        searchSelect = true;
        if (!(varRegionList.length === 1 && varRegionList[0].vr_code === 0)) {
          regionFilterLists = filterLists.filter((element) => {
            for (var i = 0; i < varRegionList.length; i++) {
              if (element.em_places === varRegionList[i].vr_code) {
                return element;
              }
            }
          });
        }
      }
      //경력검색조건 변경시
      if (varCareerList.length > 0) {
        searchSelect = true;
        careerFilterLists = filterLists.filter((element) => {
          for (var i = 0; i < varCareerList.length; i++) {
            if (varCareerList[i].sid === 0) return filterLists;
            else if (element.em_careers === varCareerList[i].sid) {
              return element;
            }
          }
        });
      }

      var totalList = [];
      if (searchSelect === false) {
        totalList = varOriginLists;
      } else {
        totalList = [...new Set([
          ...searchFilterLists,
          ...tasksFilterLists,
          ...regionFilterLists,
          ...careerFilterLists,
        ])];
      }

      var sortList = [];
      if (varSortMode === 0) {
        sortList = [...totalList].sort((a, b) => {
          return new Date(b.em_rdate) - new Date(a.em_rdate);
        });
      } else {

        if (varEmployType === 4) {
          sortList = [...totalList].sort((a, b) => {
            return b.emh_reward_recommend - a.emh_reward_recommend;
          });
        } else {
          sortList = [...totalList].sort((a, b) => {
            return b.em_reward_recommend - a.em_reward_recommend;
          });
        }
      }
      //최종배열값 저장
      setFilterLists(sortList);
    } catch (error) {
      console.log(error);
    }
  }, [varCareerList, varEmployType, varOriginLists, varRegionList, varSearchString, varSortMode, varWorkingList]);

  useEffect(() => {
    filteringJobPosting();
  }, [filteringJobPosting]);

  //검색버튼 선택시
  useEffect(() => {
    if (varExecuteSearch === 1) {
      filteringJobPosting();
      setExecuteSearch(0); //검색버튼 초기화
    }
  }, [filteringJobPosting, setExecuteSearch, varExecuteSearch]);

  useEffect(() => {
    filteringJobPosting();
  }, [filteringJobPosting, location]);

  //관심채용공고등록
  const onSelectEmployInterestHandler = (e, em_idx) => {
    const ichecked = Number(e.target.checked);

    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("em_idx", em_idx);
    params.append("checked", ichecked);

    axios
      .post(`${CONFIG.SERVER_HOST}/_update_my_interest_employ`, params)
      .then((res) => {
        window.alert(res.data.message);
        if (res.data.retvalue === 1) {
          //원본배열
          const finsIndex = varOriginLists.findIndex(element => element.em_idx === em_idx);
          let copyData = [...varOriginLists];
          if (res.data.insertMode === 1) {
            copyData[finsIndex] = { ...copyData[finsIndex], mie_idx: res.data.insert_id, mie_status: ichecked };
          } else {
            copyData[finsIndex] = { ...copyData[finsIndex], mie_status: ichecked };
          }
          setOriginLists(copyData);
        }
      });
    getAxio();
  };

  useEffect(() => {
    if (totalList || Number(cate) === -1) {
      setPageTitle('전체 리스트');
      return;
    }
    if (Number(cate) === 1) {
      setPageTitle('공개채용');
      return;
    }

    if (Number(cate) === 2) {
      setPageTitle('비공개채용');
      return;
    }

    if (Number(cate) === 3) {
      setPageTitle('실시간 채용관');
      return;
    }

    if (Number(cate) === 4) {
      setPageTitle('헤드헌터 전용관');
      return;
    }
  }, [totalList, cate]);

  return (
    <>
      <article className="list_top">
        <h1 className="total_count">
          {pageTitle}
          {/* <span>(<span className="txt_blue">{employSearchListsTotalCount}</span>건)</span> */}
        </h1>
      </article>
      <section className="recruit_card_wrap">
        {/* <article className="recruit_card"> */}
        {employSearchLists.map((jobPosting, index) => (
          <article className="recruit_card" key={"jobposting_" + index}>
            <>
              {/* <div className="btn_bookmark m_resize"> */}
              <input
                type="checkbox"
                name="chk1"
                id={"agreechk" + index}
                className={
                  jobPosting.mie_status === 1
                    ? " btn_bookmark m_resize active"
                    : "btn_bookmark m_resize"
                }
                style={{ outline: "none" }}
                onChange={(e) => {
                  onSelectEmployInterestHandler(e, jobPosting.em_idx);
                }}
                checked={jobPosting.mie_status === 1 ? "checked" : ""}
              />
              {/* <i></i>
              <label htmlFor={"agreechk" + index}></label> */}
            </>
            {/* </div> */}
            <Link
              className="link_wrapper"
              to={`${PageFolder}MainView/${jobPosting.em_idx}/${jobPosting.em_headhunter}`}
            >
              <div className="image_wrap">
                {(function () {
                  if (jobPosting.em_image === 1 && jobPosting.em_type !== 2) {
                    return <img src={`${CONFIG.SERVER_HOST}/em_image/${jobPosting.em_ufile}`} alt="" />
                  } else {
                    if (jobPosting.mcm_image === 0) {
                      return <img src={`${defaultCompanyLogo}`} alt="" />
                    } else if (jobPosting.em_type === 2 || jobPosting.mcm_image === 1) {
                      return <img src={`${CONFIG.SERVER_HOST}/mcm_image/${jobPosting.mcm_ufile}`} alt="" />
                    }
                  }
                })()}
              </div>
              <div className="content_wrap">
                <div className="type_wrap">
                  <span className="type">
                    {jobPosting.em_type === 1 ? "공개채용" : ""}
                    {jobPosting.em_type === 2 ? "비공개채용" : ""}
                    {jobPosting.em_type === 3 ? "실시간 채용관" : ""}
                    {jobPosting.em_type === 4 ? "헤드헌터 전용관" : ""}
                  </span>
                  <span className="new_badge"></span>
                </div>
                <h1 className="title">{jobPosting.em_title}</h1>
                <h2 className="recruit_info" style={{ marginBottom: "17px" }}>
                  <span className="company">
                    {jobPosting.em_type === 2 ? jobPosting.mcm_name : jobPosting.com_name}
                  </span>
                  &nbsp;ㆍ&nbsp;{jobPosting.vr_name}&nbsp;ㆍ&nbsp;
                  {jobPosting.em_careers === 0 ? "경력 무관" :
                    jobPosting.em_careers === 1 ? "신입" :
                      `경력 ${jobPosting.em_career_year}년`}
                </h2>
                <div className="compensation">
                  {jobPosting.em_type === 3 ? null :
                    <>
                      <div className="left_wrap">
                        <img className="view_pc" src={WonLogoIcon_pc} alt="" />
                        <img
                          className="view_m"
                          src={WonLogoIcon_m}
                          //   srcset="
                          //   ../Img/Main/won_m.png    1x,
                          //   ../Img/Main/won_m@2x.png 2x
                          // "
                          alt=""
                        />
                        총 보상금
                      </div>
                      <div className="right_wrap">
                        {jobPosting.em_type === 4 ? ChangeMoneyUnit(jobPosting.emh_reward_recommend / 10000) : ChangeMoneyUnit(jobPosting.em_reward_recommend / 10000)}
                      </div>
                    </>
                  }
                </div>
              </div>
            </Link>
          </article>
        ))}
        {/* </article> */}
      </section>
    </>
  );
};
export default RecruitMainList2;
