/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

const AppleLoginButton = ({ auto_login, onAppleLogin }) => {
  const uAgent = navigator.userAgent.toLocaleLowerCase();
  /** 애플로그인 스크립트 index.html -> AppleLoginButton.js 로 변경, 로그인 버튼이 새로고침을 해야 출력이 되는 이슈 발생  */
  useEffect(() => {
    //애플로그인 스크립트 동적 호출 시작 
    const script = document.createElement("script");
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    console.log(auto_login);
    localStorage.setItem("apple_auto_login", auto_login);
  }, [auto_login]);

  // useEffect(() => {
  //   try {
  //     if (uAgent.search("heding")) {
  //       window.addEventListener("message", function (event) {

  //         if(event){
  //           var event_data = JSON?.parse(event?.data);
  //           console.log(event_data);
  //           if (event_data.type === "applelogin") {
  //             const sub = event_data.sub;
  //             const email = event_data.email;
  //             if (sub) {
  //               onAppleLogin(sub, 4, email);
  //             };
  //           }
  //         }
  //       });
  //     }
  //   } catch (e) {
  //     return false;
  //   }
  // }, []);


  const onMessageReceivedApple = (event) => {
    if(event){
      var event_data = JSON?.parse(event?.data);
      console.log(event_data);
      if (event_data.type === "applelogin") {
        const sub = event_data.sub;
        const email = event_data.email;
        if (sub) {
          onAppleLogin(sub, 4, email);
        };
      }
    }
  };

  //어플 본인인증 리턴값 처리 로직 시작
  useEffect(() => {

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    //안드로이드
    if (varUA.search("heding")) {
      document.addEventListener("message", onMessageReceivedApple);
    } 
    return () => {
      if (varUA.search("heding")) {
        document.removeEventListener("message", onMessageReceivedApple);
      }
    }
  }, [onMessageReceivedApple]);
  //어플 본인인증 리턴값 처리 로직 끝



  return (
    <>
      <div id="appleid-signin" className="signin-button btn apple" data-mode="left-align"
        data-type="sign-in"
        data-color="black"
        data-border="false"
        data-border-radius="0"
        data-width="210"
        data-height="55"
        data-logo-size="small"
        data-logo-position="24"
        data-label-position="74"
        fontSize="10"></div>
    </>
  );
};

export default AppleLoginButton;
