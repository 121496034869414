/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import arr from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";
import CONFIG from "../../../configs/configs";
import axios from "axios";

import "admin/css/board.css";
import "../../../css/Common.css";

function FaqDetail() {
  const { board_idx } = useParams();
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  /*   //오늘날짜
  let today = new Date();   
  let year = today.getFullYear();
  let month = ('0' + (today.getMonth() + 1)).slice(-2);
  let day = ('0' + today.getDate()).slice(-2);
  const curDate = year + '-' + month  + '-' + day; */

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  //심사상태--------------------------------------------------------------------------------------------
  const [varFaqDetailIdx, setVarFaqDetailIdx] = useState(0);
  const [varFaqDetailQuestion, setVarFaqDetailQuestion] = useState("");
  const [varFaqDetailAnswer, setVarFaqDetailAnswer] = useState("");
  const [varFaqDetailMode, setVarFaqDetailMode] = useState(1);

  const [varFaqSerious, setVarFaqSerious] = useState([]);

  //답변
  const handleFaqDetailAnswer = (e) => {
    setVarFaqDetailAnswer(e.target.value);
  };
  //질문
  const handleFaqDetailQuestion = (e) => {
    setVarFaqDetailQuestion(e.target.value);
  };
  //유형
  const handleFaqDetailSerious = (e) => {
    setVarFaqDetailMode(e.target.value);
  };

  
  //최초에 실행
  useEffect(() => {
    setVarFaqDetailIdx(board_idx);
    //컨설팅 신청 항목
    axios.post(`${CONFIG.SERVER_HOST}/faqseriouslist`).then((res) => {
      const vfss = res.data.results.map((vfs, i) => ({
        vfs_idx: vfs.vfs_idx,
        vfs_name: vfs.vfs_name,
      }));
      setVarFaqSerious(vfss);
    });
    //컨설팅 상세
    if (board_idx > 0) {
      const params = new URLSearchParams();
      params.append("board_idx", board_idx);
      axios.post(`${CONFIG.SERVER_HOST}/faq_detail`, params).then((res) => {
        console.log(res.data.results[0]);
        setVarFaqDetailQuestion(res.data.results[0].board_question);
        setVarFaqDetailAnswer(res.data.results[0].board_answer);
        setVarFaqDetailMode(res.data.results[0].vfs_idx);
      });
    }
  }, []);

  //저장하기
  const updateFaqDetail = () => {
    const params = new URLSearchParams();
    params.append("board_idx", varFaqDetailIdx);
    params.append("vfs_idx", varFaqDetailMode);
    params.append("board_question", varFaqDetailQuestion);
    params.append("board_answer", varFaqDetailAnswer);
    axios.post(`${CONFIG.SERVER_HOST}/faq_update`, params).then((res) => {
      if (res.data.retvalue === 1) document.location.href = "/admin/faqlist";
      else window.alert("오류가 발생했습니다.");
    });
  };

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="faqdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          FAQ 관리 등록/수정
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arr} alt="" />
            게시판 관리
            <img src={arr} alt="" />
            FAQ 관리 등록/수정
          </div>
        </div>
        <div className="content" >
          <div className="main-box" >
            <table className="write">
              <tbody>
                <tr>
                  <th>유형</th>
                  <td>
                    <select
                      onChange={handleFaqDetailSerious}
                      value={varFaqDetailMode}
                    >
                      {varFaqSerious.length > 0
                        ? varFaqSerious.map((data, i) => (
                            <option key={i} value={data.vfs_idx}>
                              {data.vfs_name}
                            </option>
                          ))
                        : null}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>질문내용</th>
                  <td className="write-input">
                    <input
                      type="text"
                      value={varFaqDetailQuestion}
                      onChange={handleFaqDetailQuestion}
                    />
                  </td>
                </tr>
                <tr>
                  <th>답변내용</th>
                  <td className="write-input">
                    <textarea
                      type="text"
                      className="textarea_h250"
                      value={varFaqDetailAnswer}
                      onChange={handleFaqDetailAnswer}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/faqlist" className="btn line">
                취소
              </Link>
              <button onClick={updateFaqDetail} className="btn">
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FaqDetail;
