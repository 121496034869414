/* eslint-disable array-callback-return */
import React, { useEffect, useState }from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';

/*채용 지원 현황*/
function MyApplyTop({ varSearchData, setSearchData, applyValue }) {
    const user_idx = localStorage.getItem('mem_idx');

    const [varBeforeViewing, setBeforeViewing] = useState(0);
    const [varDcoumentChecking, setDcoumentChecking] = useState(0);
    const [varDocumentPass, setDocumentPass] = useState(0);
    const [varMeetingPass, setMeetingPass] = useState(0);
    const [varLastSucess, setLastSucess] = useState(0);
    const [varFailedApply, setFailedApply] = useState(0);
    const [varEmployed, setEmployed] = useState(0);
    const [varRetiredEarly, setRetiredEarly] = useState(0);


    const onExecuteSearchingHandler = (e) => {
      const {name} = e.target;
      e.preventDefault(); 
      if(name === "beforeView"){
        setSearchData({...varSearchData,status:0});
      } else if(name === "checking"){
        setSearchData({...varSearchData,status:1});
      } else if(name === "documentpass"){
        setSearchData({...varSearchData,status:2});
      } else if(name === "meetingpass"){
        setSearchData({...varSearchData,status:3});
      } else if(name === "lastsuccess"){
        setSearchData({...varSearchData,status:4});
      } else if(name === "faildapply"){
        setSearchData({...varSearchData,status:-2});
      } else if(name === "employed"){
        setSearchData({...varSearchData,status:5});
      } else if(name === "retiredearly"){
        setSearchData({...varSearchData,status:-3});
      }
    };
   
    useEffect(() => {
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_apply`,params).then((res) => {
            if(res.data.retvalue === 1) {
                res.data.results?.map(data => {   
                    //console.log('count',data.ea_idx,data.ea_checking);             
                    if(data.ea_checking === 0) setBeforeViewing(prevCount => prevCount+1);
                    else if(data.ea_checking === 1) setDcoumentChecking(prevCount => prevCount+1);
                    else if(data.ea_checking === 2) setDocumentPass(prevCount => prevCount+1);
                    else if(data.ea_checking === 3) setMeetingPass(prevCount => prevCount+1);
                    else if(data.ea_checking === 4) setLastSucess(prevCount => prevCount+1);
                    else if(data.ea_checking === 5) setEmployed(prevCount => prevCount+1);
                    else if(data.ea_checking === -2) setFailedApply(prevCount => prevCount+1);
                    else if(data.ea_checking === -3) setRetiredEarly(prevCount => prevCount+1);
                });
            } else {
                window.alert('검색된 정보가 없습니다.');
            }
        });  
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    useEffect(() => {
      if(applyValue !== null){
        if(applyValue.status === 0){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === 1){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === 2){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === 3){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === 4){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === -2){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === 5){
          setSearchData({...varSearchData, status:applyValue.status})
        } else if(applyValue.status === -3){
          setSearchData({...varSearchData, status:applyValue.status})
        } 
      }
      // eslint-disable-next-line
    },[applyValue])


  return (
    <section className="box box_content">
        <h3 className="my_title">채용 지원 현황</h3>
        <ul className="state_wrap">
            <li className="state_box">
                <h4 className="title">미열람</h4>
                <button className="num point1" name="beforeView" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varBeforeViewing}</button>
            </li>
            <li className="state_box">
                <h4 className="title">검토중</h4>
                <button className="num" name="checking" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varDcoumentChecking}</button>
            </li>
            <li className="state_box">
                <h4 className="title">서류합격</h4>
                <button className="num" name="documentpass" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varDocumentPass}</button>
            </li>
            <li className="state_box">
                <h4 className="title">면접합격</h4>
                <button className="num" name="meetingpass" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varMeetingPass}</button>
            </li>
            <li className="state_box">
                <h4 className="title">최종합격</h4>
                <button className="num point2" name="lastsuccess" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varLastSucess}</button>
            </li>
            <li className="state_box">
                <h4 className="title">불합격</h4>
                <button className="num" name="faildapply" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varFailedApply}</button>
            </li>
            <li className="state_box">
                <h4 className="title">입사</h4>
                <button className="num" name="employed" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varEmployed}</button>
            </li>
            <li className="state_box">
                <h4 className="title">보증기간 내 퇴사</h4>
                <button className="num" name="retiredearly" style={{cursor:"pointer"}} onClick={(e) => onExecuteSearchingHandler(e)}>{varRetiredEarly}</button>
            </li>
        </ul>
    </section>
  );
}

export default MyApplyTop;
