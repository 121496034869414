/* eslint-disable default-case */
import React from 'react';
import CONFIG from 'configs/configs';

function MainAppDownPopup({ setAppPopupShow, appPopupShow }) {

	const moveStore = () => {

		const isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod');
		const isAndroid = navigator.userAgent.match('Android');

		const visitedAt = (new Date()).getTime(); // 방문 시간

		if (isAndroid) {
			window.location.href = CONFIG.Android_Deeplink;
		} else if (isiOS) {

			setTimeout(function () {
				if ((new Date()).getTime() - visitedAt < 2000) {
					window.location.href = CONFIG.Ios_ItunesId;
				}
			}, 500);
			setTimeout(function () {
				window.location.href = CONFIG.Iod_Deeplink;
			}, 0);

		}
	}

	const closePopup = async() => {
		sessionStorage.setItem('appChk', true);
		setAppPopupShow(false);
	}



	return (
		<div className={`popup pop_appdown ${appPopupShow ? `active` : ``}`}>
			<div className="popup_wrap">
				<section className="top">
					<div className="logo_wrap"></div>
					<h1>헤딩 앱에서<br />더 쉽고 편리하게!</h1>
				</section>
				<section className="bottom">
					<button className="btn_appdown" onClick={moveStore}>HEDING 앱으로 보기</button>
					<button className="btn_webview" onClick={() => { closePopup() }}>그냥 모바일 웹으로 볼게요</button>
				</section>
			</div>
		</div>
	);
}

export default MainAppDownPopup;
