/* eslint-disable import/no-anonymous-default-export */

const host = window.location.hostname;

let SERVER_HOST = "";
let SERVER_HOST_INCOME = "";
let SERVER_HOST_REPUTATION = "";
let SERVER_HOST_FILE = "";

//2022-12-09 toss API Key - KSR
let toss_key = "";
let toss_secret = "";

if(host.includes('www.heding.co.kr')){//----- 실서버(AWS)----------
  SERVER_HOST = "https://api.heding.co.kr:8007/api";
  SERVER_HOST_INCOME = "https://api.heding.co.kr:8007";
  SERVER_HOST_REPUTATION = "https://api.heding.co.kr:8007";
  SERVER_HOST_FILE = "https://api.heding.co.kr:8007";

  //toss API
  toss_key = "live_ck_GKNbdOvk5rkqknKYMjo3n07xlzmj";    //LIVE
  toss_secret = "live_sk_5mBZ1gQ4YVXLnzLnlxaVl2KPoqNb"  //LIVE
}else{//--------------------------------- 개발서버----------
  
  SERVER_HOST = "https://heading.awsome-app.kr:8007/api";
  SERVER_HOST_INCOME = "https://heading.awsome-app.kr:8007";
  SERVER_HOST_REPUTATION = "https://heading.awsome-app.kr:8007";
  SERVER_HOST_FILE = "https://heading.awsome-app.kr:8007";
  /*
  SERVER_HOST = "http://localhost:8007/api";
  SERVER_HOST_INCOME = "http://localhost:8007";
  SERVER_HOST_REPUTATION = "http://localhost:8007";
  SERVER_HOST_FILE = "http://localhost:8007";
  */

  //toss API
  toss_key = "test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq";    //TEST
  toss_secret = "test_sk_zXLkKEypNArWmo50nX3lmeaxYG5R"  //TEST
}

const arrData = {
  SERVER_HOST: SERVER_HOST,
  SERVER_HOST_INCOME: SERVER_HOST_INCOME,
  SERVER_HOST_REPUTATION: SERVER_HOST_REPUTATION,
  SERVER_HOST_FILE: SERVER_HOST_FILE,

  formatStr: "YYYY-MM-DD",
  formatStrOfKR: "YYYY년 MM월 DD일",
  DBFormatStr: "YYYY-MM-DD hh:mm:ss",
  img: "https://via.placeholder.com/900x150.jpg",
  img1: "https://picsum.photos/300/300/?image=100",
  m_redirect_url: `https://${window.location.hostname}`,

  //toss payments 관련
  successUrl: `https://${window.location.host}/TossPaySuccess`, //toss 결제 성공 URI
  failUrl: `https://${window.location.host}/TossPayFailed`,   //toss 결제 실패 URI
  tossClientKey: toss_key,
  tossSecretKey: toss_secret,

  subUrl: "https://geolocation-db.com/json/",
  inquiryUrl: "https://tally.so/r/wvYv03",
  kakaomapUrl: "https://dapi.kakao.com/v2/maps/sdk.js?appkey=ff6cb9d911b5ce588f8be861cb73ef15&libraries=services",
  schoolApiUrl: "https://open.neis.go.kr/hub/schoolInfo",
  schoolApiKey: "3df409516dfb48cdba7894bb7e280ab1",
  header: { "Content-Type": "application/json" },
  temporary_saveMessage: "현재 내용을 임시저장하시겠습니까?",
  saveMessage: "현재 내용을 저장하시겠습니까?",
  temporary_title: "임시저장",
  vr_code: 99, //이력서 희망근무지 해외 코드,
  schoolSearchApiURL: "https://www.career.go.kr/cnet/openapi/getOpenApi",
  schoolSearchApiKey: "404c11c2ac986268a611a3e8ed2c6723",
  searchCompany: {
    DEV_API_URL: "https://dev2.coocon.co.kr:8443/sol/gateway/data_wapi.jsp",
    PROD_API_URL: "https://sgw.coocon.co.kr/sol/gateway/data_wapi.jsp",
    API_KEY: "J1FX2aqUTpQwLcH0AhI6",
    API_ID: "0153",
  },
  selectMaxCount: 3,
  fileExt: "xlsx,docx,pdf,zip,hwp,ppt",
  fileExtOption: /(.*?)\.(xlsx|docx|pdf|zip|hwp|ppt)$/,
  fileSize: 50 * 1024 * 1024,
  fileShowSize: 50,
  totalReward: 1000000, // 공개, 비공개 합격자보상금
  DownLoadTextArray: ['다운로드 준비중입니다.', 'pdf 파일 생성중입니다.', '잠시만 기다려주세요', '약 10초정도 소요됩니다.'],
  QueryingTextArray: ['데이터를 조회중입니다.', '데이터를 저장중입니다.', '잠시만 기다려주세요'],
  URL: `https://${window.location.hostname}`,
  GoogleClientId: '744175601449-iet1mlunf8bvkmroklv4vi885ft5s8rj.apps.googleusercontent.com',
  GoogleClientId_test: '255399525176-iurk3lj1s37u0kfm8bpuspe2lmccvnec.apps.googleusercontent.com',
  Android_Deeplink: 'Intent://wakeHeding#Intent;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=callMyHeding;package=com.heding.app;end',
  Ios_ItunesId: 'https://itunes.apple.com/app/id6443644989',
  Iod_Deeplink: 'callMyHeding://default',
  GooGlePlayStore: 'https://play.google.com/store/apps/details?id=com.heding.app',
  AppStore: 'https://itunes.apple.com/app/id6443644989',
  PdfBaseUri: `https://${window.location.host}/PdfView`,  //외부 PDF API에서 PDF 생성할 Target Uri
  PdfBaseUri2: `https://${window.location.host}/PdfView2`,  //외부 PDF API에서 PDF 생성할 Target Uri

  PdfReputation1Uri: `https://${window.location.host}/Pdf/Reputation/Career`,  //동료평판
  //PdfReputation1Uri: `https://heading.awsome-app.kr/Pdf/Reputation/Career`,  //동료평판

  PdfReputation2Uri: `https://${window.location.host}/pdf/Reputation/friends`,  //지인평판
  //PdfReputation2Uri: `https://heading.awsome-app.kr/pdf/Reputation/friends`,  //지인평판


  PdfApiKey: 'WufNtWhgQioLI5ET', //PDF 외부 API Key(https://www.convertapi.com/)
  Master:'(석사)',
  Doc:'(박사)',
  AdminIdx: 114   //관리자페이지 댓글 작성 시 작성자 idx
}
export default arrData;

