/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
//functions
import { ChangeSearchingDateFormat} from "../Common/Common";

//datepickeer
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko'; // 한국어적용
registerLocale("ko", ko) // 한국어적용

function MyCareerHistory({ index, career, varMyCareerList, setMyCareerList }) {
  const [startDate, setStartDate] = useState(career.rec_sdate===null||career.rec_sdate===""?null:new Date(career.rec_sdate));
  const [endDate, setEndDate] = useState(career.rec_edate===null||career.rec_edate===""?null:new Date(career.rec_edate));

  //경력조합체
  const [careerData, setCareerData] = useState(career);

  //최초 호출시 한번????
  useEffect(() => {
    //console.log(career);
    if(career !== undefined && career.rec_company !== "" ) {
      setStartDate(career.rec_sdate===null?null:new Date(career.rec_sdate));
      setEndDate(career.rec_edate===null?null:new Date(career.rec_edate));
      
      //정보 변경을 위한 새로운 객체 생성
      setCareerData({ 
        rec_idx:career.rec_idx,
        rec_company:career.rec_company,
        rec_sdate:career.rec_sdate,
        rec_edate:career.rec_edate,
        rec_state:career.rec_state,
        rec_certification:1
      });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //입력창 변경
  const onChangeHandler = e => {
    var { value, name } = e.currentTarget;
    setCareerData({...careerData,[name]: value})
    setObjectList(name,value);
  }

  //날짜변경
  const onChangeDate = (i,date) => {
    const ndate = ChangeSearchingDateFormat(date); //날짜 변경----configs.js
    if(i === 0) {
      setStartDate(date);
      setCareerData({...careerData,rec_sdate: ndate});
      setObjectList('rec_sdate',ndate);
    } else {
      setEndDate(date);
      setCareerData({...careerData,rec_edate: ndate});
      setObjectList('rec_edate',ndate);
    }
  }
  //최상위 오브젝트 배열 값 조정
  const setObjectList = (name,value) =>{
    //console.log(varMyCareerList);
    const finsIndex = varMyCareerList.findIndex(element => element.rec_idx === careerData.rec_idx);
    //console.log(finsIndex);
    let copyData = [...varMyCareerList];
    copyData[finsIndex]={...copyData[finsIndex],[name]:value};
    setMyCareerList(copyData);
    console.log(copyData);
  }

  return (
    <form className="input_area" key={"rec_"+index}>
      <input type="text" className="input_title" name="rec_company" placeholder="회사명" value={careerData.rec_company} onChange={(e)=>onChangeHandler(e)} readOnly/>
      <DatePicker className="input_date datepicker" locale={ko} showMonthYearPicker /* withPortal */ 
                dateFormat="yyyy.MM" selected={startDate} selectStart onChange={(date) => onChangeDate(0,date)} readOnly
      />
      <DatePicker className="input_date datepicker" locale={ko} showMonthYearPicker minDate={startDate}
                dateFormat="yyyy.MM" selected={endDate} selectEnd onChange={(date) => onChangeDate(1,date)} readOnly
      />
      {/* <select className="input_state" name="rec_state" value={careerData.rec_state} onChange={(e)=>onChangeHandler(e)}>            
          <option value="0">퇴사</option>
          <option value="1">재직</option>
      </select> */}
      <input input type="text" className="input_state" name="rec_state" value={Number(careerData.rec_state) === 0 ? "퇴사" : "재직"} readOnly></input>
    </form>
  );
}

export default MyCareerHistory;