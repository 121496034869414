import React from "react";
import { remainDays } from '../Common/Common'
//css
//components
//이미지

/*이용권구매 상단*/
function VoucherTop({ varCompanyVoucher }) {
  const getId = localStorage.getItem("mem_id");
  console.log(varCompanyVoucher);
  return (
    varCompanyVoucher === null ? null :
    <section className="subtop_info_area">
      <ul className="center_wrap company">
        <li><i className="gray_icon voucher"></i><b>{getId}</b>님의 남은 이용권 상태입니다.</li>
        <li className="remain_wrap">
          <div><i className="gray_icon remain01"></i>잔여 제안<b>{varCompanyVoucher.mcc_sending+" 건"}</b></div>
          <div><i className="gray_icon remain02"></i>잔여 이용기간<b>{remainDays(varCompanyVoucher.mcc_edate)+" 일"}</b></div>
        </li>
      </ul>
    </section>
  );
}

export default VoucherTop;
