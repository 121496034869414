import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';
import ResumeIssue from '../../components/Resume/ResumeIssue';

//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/MyResume.css';
import '../../css/Davehan.css';

const ResumeDetail = () => {
  const navigate = useNavigate();
  const { re_idx, type } = useParams();
  const [curResumeIndex, setResumeIndex] = useState(0);
  const [varQRURLAdress,setrQRURLAdress] = useState('');
  const [re_code,setrRe_code] = useState('');

  useEffect(() => {
    const funSetLocationState = () => {
      if(re_idx !== null ) {
        if(!isNaN(re_idx)){
          setResumeIndex(Number(re_idx));
        }else{
          setrRe_code(re_idx);
        }
        
      } 

      const params = {
        re_idx : re_idx
      }
      
      // if(type === 'web'){
        if(isNaN(re_idx)){
          alert(`로그인 후 이용 가능합니다.`);
					navigate('/MyResume');
					return;
        }
        axios.post(`${CONFIG.SERVER_HOST}/hash_code`,params, CONFIG.header).then((res) => {
          setrQRURLAdress(`https://${window.location.hostname}/PdfView/${res.data.result[0].re_code}/qr`);
        });
      // }else{
      //   setrQRURLAdress(`https://${window.location.hostname}/MyResume/ResumeDetail/${re_idx}/qr`);
      // }


      
    }

    funSetLocationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [re_idx]);

  return (
    <ResumeIssue curResumeIndex={!isNaN(re_idx)?curResumeIndex:re_code} varQRURLAdress={varQRURLAdress} type={type}/>
  )
}

export default ResumeDetail
