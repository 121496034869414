/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import { changeMobileFormat,changeMobileNumber,addThousandPoint } from '../../../components/Common/Common';
import Paging from '../../../components/Layout/Paging';

function Headhunter_Sub_Dtail({setCategory}) {
    const location = useLocation();

    const [varMemberIndex,setMemberIndex] = useState(0);
    const [varUserData, setUserData] = useState({});
    const [varUserHmoney, setUserHmoney] = useState([]);
    const [varPagelist, setPagelist] = useState([]); //eslint-disable-line no-unused-vars
    const [bankData, setBankData] = useState([]);
    const [hedingMoney, setHedingMoney] = useState("");
    const [currentHedingMoney, setCurrentHedingMoney] = useState(0);
    const [hedingMoneyStatus, setHedingMoneyStatus] = useState(1);
    const [prevData, setPrevData] = useState({mem_email: ""});

    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    //회원고유번호 읽기
    useEffect( () => {
        //console.log(location);
        if(location !== undefined && location !== null) {
            setMemberIndex(location.state.submem_idx);
        }
    },[location]);

    useEffect(async () => {
        setCategory(2);
        if (varMemberIndex > 0) {
            console.log(varMemberIndex);
            const params = new URLSearchParams();
            params.append("mem_idx", varMemberIndex);
            await axios.post(`${CONFIG.SERVER_HOST}/getHeadSub_Member`,params).then((res) => {
                if(res.data.retvalue === 1) {
                    //회원정보
                    let datas = res.data?.members[0];
                    //datas.change_mem_pwd = "";
                    setUserData(datas);
                    setPrevData({
                      mem_email: datas.mem_email,
                    })
                    //헤딩머니목록
                    if(res.data?.hedingmoneys.length > 0) {
                    setTotalCount(res.data.hedingmoneys.length);
                    setUserHmoney(res.data.hedingmoneys);
                    }

                    //환전계좌정보
                    if(res.data.bankInfo.length > 0) {
                      setBankData(res.data.bankInfo)
                    }
                }
            });
        }
    }, [varMemberIndex]);

    //페이지별 표시 - 결제내역
    const getPageLists = () => {
        const startNum = (varPageNum-1)*varPageSize;
        const endNum = startNum + varPageSize;
        const copyData = varUserHmoney.slice(startNum,endNum);
        setPagelist(copyData);
    }

    useEffect(() => {
        getPageLists();
    },[varPageNum,varUserHmoney]);
    //회원정보 객체에 저장
    const handleChangeInfos = e => {
        const { name, value } = e.currentTarget;
        if(name === 'mem_mobile') {
            const values = changeMobileNumber(value);
            setUserData(prevState => ({...prevState, [name]: values}));
        } else {
            setUserData(prevState => ({...prevState, [name]: value}));
        }
    };
    //사용유무
    const onRadioChange = e => {
      const { name, value } = e.currentTarget;
      setUserData(prevState => ({...prevState, [name]: value}));
    }
    //닉네임 중복체크 함수
    const handleCheckDuplication = async (e) => {
      const params = new URLSearchParams();
      params.append("mem_idx", varUserData?.mem_idx);
      if (e.currentTarget.id === 'mem_nickname') {
          params.append("mem_nickname", varUserData?.mem_nickname);
      } else {
          params.append("mem_email", varUserData?.mem_email);
      }
      await axios.post(`${CONFIG.SERVER_HOST}/${e.currentTarget.id}`, params).then((res) => {
          alert(res.data.message);
      });
    };
    //서브계정 정보 저장
    const handleSubmit = async (e) => {
        e.preventDefault();

        const params = new URLSearchParams();

        params.append('mem_idx', varMemberIndex);
        params.append('mem_pwd', varUserData?.mem_pwd);
        params.append('mem_nickname', varUserData?.mem_nickname);
        params.append('cmm_department', varUserData?.cmm_department);
        params.append('cmm_position', varUserData?.cmm_position);
        params.append('mem_mobile', changeMobileNumber(varUserData?.mem_mobile));
        params.append('mem_email', varUserData?.mem_email);
        params.append('change_mem_pwd', varUserData?.change_mem_pwd);
        params.append('mem_status', varUserData?.mem_status);// 사용여부

        await axios.post(`${CONFIG.SERVER_HOST}/subuserUpdate`, params).then((res) => {
            alert(res.data.message);
        });
    }

    //헤딩머니 상태 변경
  const handleMhReason = (e) => {
    const { value } = e.target;
    setHedingMoneyStatus(value);
  };

  //헤딩머니 핸들러
  const handleHedingMoneyInfos = (e) => {
    const { value } = e.target;
    setHedingMoney(value);
  };


  const haneldMoneyChange = async() => {
    if (hedingMoney === "") {
      alert('금액을 입력해주세요');
      return false;
    }

    if(Number(hedingMoneyStatus) === 0){
      if (Number(currentHedingMoney) < Number(hedingMoney)) {
        alert('보유중인 헤딩머니보다 큰 금액입니다');
        return false;
      }
    }

    // if(bankData.length === 0){
    //   alert('등록된 계좌정보가 없습니다.');
    //   return false;
    // }

    const sendData = {
      "mem_idx": varMemberIndex,
      "mh_reason": hedingMoneyStatus,
      "mh_paid": hedingMoney,
      "mh_balance": varUserHmoney[0]?.mh_balance === undefined ? hedingMoney : (Number(hedingMoneyStatus) === 0 ? Number(currentHedingMoney)-Number(hedingMoney) : Number(currentHedingMoney)+Number(hedingMoney) ),
      "bl_idx": bankData[0]?.bl_idx === undefined ? "" : bankData[0]?.bl_idx,
      "mh_account": bankData[0]?.mc_account === undefined ? "" : bankData[0]?.mc_account,
      "mh_owner": bankData[0]?.mc_owner === undefined ? "" : bankData[0]?.mc_owner
    }

    await axios
      .post(`${CONFIG.SERVER_HOST}/insertHedingMoney`, sendData, CONFIG.header)
      .then((res) => {
        if(res.data.retvalue === 1){
          alert(res.data.message);
          setUserHmoney(res.data.results)
        }else{
          alert(res.data.message);
        }
      });
      setHedingMoney("");
      setHedingMoneyStatus(1);
  }
  
  //보유중인 헤딩머니 가져오기
  useEffect(async() => {
    const params = new URLSearchParams();
    params.append("mem_idx", varMemberIndex);

    await axios
      .post(`${CONFIG.SERVER_HOST}/_get_heding_money`, params)
      .then((res) => {
        if(res.data.retvalue === 1){
          setCurrentHedingMoney(res.data.results[0]?.mh_paid)
        }
      });
  },[varUserHmoney])


    return (
        <React.Fragment>
            <p className="table_title" style={{marginTop:"20px"}}>- 서브계정 정보</p>
            <div className="main-box" >
                <table className="write">
                    <thead></thead>
                    <tbody >
                        <tr>
                            <th>아이디</th>
                            <td style={{textAlign: "left"}}>{varUserData?.mem_id}</td>
                            <th>비밀번호</th>
                            <td>
                                <input type="password" name="change_mem_pwd" onChange={handleChangeInfos} value={varUserData?.change_mem_pwd||""} maxLength="30" className="wd200" />
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td colSpan={3} style={{textAlign: "left"}}>
                                <input type="text" name="mem_email" onChange={handleChangeInfos} value={varUserData?.mem_email||""} className="wd300" />
                                {prevData.mem_email !== varUserData.mem_email && <button className="btn" id="mem_email" onClick={handleCheckDuplication}>
                                중복체크
                              </button>}
                            </td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td colSpan={3} style={{textAlign: "left"}}>{varUserData?.mem_name||""}</td>
                        </tr>
                        <tr>
                            <th>휴대폰</th>
                            <td colSpan={3} style={{textAlign: "left"}}>
                                <input type='text' name='mem_mobile' onChange={handleChangeInfos} value={changeMobileFormat(changeMobileFormat(varUserData?.mem_mobile)||"")} className="wd200"/>
                            </td>
                        </tr>
                        {/* <tr>
                            <th>닉네임</th>
                            <td colSpan={3} style={{textAlign: "left"}}>
                                <input type='text' name='mem_nickname' onChange={handleChangeInfos} value={varUserData?.mem_nickname||""} className="wd200"/>
                                <button id="mem_nickname" onClick={handleCheckDuplication} className="btn line black">중복체크</button>
                            </td>
                        </tr> */}
                        <tr>
                            <th>부서</th>
                            <td colSpan={3} style={{textAlign: "left"}}>
                                <input type='text' name='cmm_department' onChange={handleChangeInfos} value={varUserData?.cmm_department||""} className="wd200"/>
                            </td>
                        </tr>
                        <tr>
                            <th>직책</th>
                            <td colSpan={3} style={{textAlign: "left"}}>
                                <input type='text' name='cmm_position' onChange={handleChangeInfos} value={varUserData?.cmm_position||""} className="wd200"/>
                            </td>
                        </tr>
                        
                        
                        <tr>
                            <th>사용여부</th>
                            <td colSpan={3} style={{textAlign: "left"}}><div className="write-radio">
                                {varUserData?.mem_privacy === 0 ? "회원 탈퇴시" : ""}
                                <input type='radio' name="mem_status" id="use" onChange={onRadioChange} value={1} checked={varUserData?.mem_status===1?true:false}/>
                                <label htmlFor="use">사용함</label>
                                <input type='radio' name="mem_status" id="no_use" onChange={onRadioChange} value={0} checked={varUserData?.mem_status===0?true:false}/>
                                <label htmlFor="no_use">사용 안함</label>
                            </div></td>
                        </tr>
                        <tr>
                            <th>최근 접속일</th>
                            <td style={{textAlign: "left"}}>{varUserData?.mem_ldate}</td>
                            <th>추가일자</th>
                            <td style={{textAlign: "left"}}>{varUserData?.mem_rdate}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="btn-center" style={{marginBottom:"3%"}}>
                <Link className="btn line" to={"../Headhunter_Sub_Account"}>목록</Link>
                <button className="btn" onClick={handleSubmit}>수정</button>
            </div>
            {bankData && bankData.map((data, i) => (
              <div key={i}>
                <p className="table_title">- 환전 계좌 정보</p>
                <div className="main-box" style={{marginBottom:"5%"}}>
                    <table className="write">
                        <thead></thead>
                        <tbody >
                            <tr>
                                <th>은행명</th>
                                <td style={{textAlign: "left"}} >{data?.bl_name}</td>
                            </tr>
                            <tr>
                                <th>계좌번호</th>
                                <td style={{textAlign: "left"}}>{data?.mc_account}</td>
                            </tr>
                            <tr>
                                <th>예금주</th>
                                <td colSpan={3} style={{textAlign: "left"}}>{data?.mc_owner}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
            ))}
            <div>
              <p className="table_title" >- 헤딩머니 정보</p>
              <div className="main-box">
                  <table className="write">
                    <tbody>
                      <tr>
                        <th>보유중인 헤딩머니</th>
                        <td colSpan={3} style={{ textAlign: "left" }}>
                          {addThousandPoint(currentHedingMoney) + " 원"}
                        </td>
                      </tr>
                      <tr> 
                        <th>헤딩머니 설정</th>
                        <td colSpan={3} style={{ textAlign: "left" }}>
                          <select
                            value={hedingMoneyStatus}
                            onChange={handleMhReason}
                            className="short_select"
                            style={{marginRight:"2%"}}
                          >
                            <option value={"0"}>환전</option>
                            <option value={"1"}>추천인 보상금</option>
                            <option value={"2"}>합격자 보상금</option>
                            <option value={"3"}>HR 담당자 소개 보상금</option>
                            <option value={"4"}>기타 적립금</option>
                            <option value={"5"}>보너스</option>
                          </select>
                          <input type="text" name="heding_money"
                            onChange={(e)=>handleHedingMoneyInfos(e)}
                            value={hedingMoney}
                            className="wd200"
                          />
                          <button className="btn" id="heding_money" onClick={haneldMoneyChange}>
                            머니 수정
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={varUserHmoney.length === 0 ? "main-box2" : "main-box"} style={{marginBottom:"5%"}}>
                <table className="write">
                {varUserHmoney.length !== 0 &&  
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>변동일시</th>
                      <th>변동내용</th>
                      <th>변동머니</th>
                      <th>남은머니</th>
                    </tr>
                  </thead>
                }
                {varUserHmoney && varUserHmoney.map((data, i) => (
                  <tbody key={"hmoney" + i}>
                    <tr>
                      <td>{varUserHmoney.length - i}</td>
                      <td>{data.mh_rdate}</td>
                      <td>
                        {data.vhs_name}{" "}
                        {data.mh_reason !== 0
                          ? ""
                          : data.mh_status === -1
                            ? " (환전취소)"
                            : data.mh_status === 1
                              ? " (환전신청)"
                              : data.mh_status === 2
                                ? " (환전승인완료)"
                                : data.mh_status === 3
                                  ? " (환전완료)"
                                  : ""}
                      </td>
                      <td>{data.vhs_name === "환전" ? "" : "+ " }{isNaN(String(data.mh_paid).substring(0,1)) ? String(data?.mh_paid)?.replace("-","- ") + " 원" : addThousandPoint(data.mh_paid) + " 원"}</td>
                      <td>{addThousandPoint(data.mh_balance) + " 원"}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        </React.Fragment>
    )

}

export default Headhunter_Sub_Dtail;