import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';
import CertificationCareerView from "../Resources/CertificationCareer";
import AddCareerView from "../Resources/AddCareer";

/*메인(기업)>새로운지원자*/
function NewApply() {
  const mcm_idx = Number(localStorage.getItem('mcm_idx'));
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  const [varApplyEmploy, setApplyEmploy] = useState([]);
  const [varTotalApply, setTotalApply] = useState(0);

  //새로운 지원자 
  const getCompanyApply = async () => {
    try {
      console.log("mcm_idx", mcm_idx);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_apply_employ`, params).then((res) => {
        console.log(res.data);
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          console.log(datas);
          // setApplyEmploy(datas);
          setApplyEmploy(datas.slice(0,4));
          setTotalApply(datas.length);
        } else {
          setApplyEmploy([]);
          setTotalApply(0);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCompanyApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(varApplyEmploy)

  return (
    <article>
      <hgroup className="sub_content_title_wrap spacebetween">
        <h1 className="sub_content_title indent">새로운 지원자<span className="txt_blue">{varTotalApply} 건</span></h1>
        <Link to={"/Company/ComAnnouncement/SupportList"} state={{ mcm_idx: mcm_idx }} className="btn line gray">전체보기<span className="nanumGothic">&gt;</span></Link>
      </hgroup>
      <div className="list_box">
        <ul className="talent_list">
          {varApplyEmploy.map((employApply, index) => (
            <li key={"apply_" + index} className="talent_card">
              <Link className="link_wrap" to={"/Company/comAnnouncement/ResumeView"} state={{ re_idx: employApply.ea_re_idx, em_idx: employApply.em_idx }}>
                <div className="pic_name_wrap">
                  {employApply.mem_photo === 0 ? <figure></figure>
                    : <figure style={{ backgroundImage: `url(${imageFoler + employApply.mem_ufile})` }}></figure>
                  }
                  <b className="ellipsis">{employApply.mem_name}</b>
                </div>
                <hgroup className="style2">
                  <h1 className="txt_blue ellipsis">
                    {/* {resetCareerForSearching(employApply.re_career_year)} */}
                    {
                      !employApply?.certification_experience_year && !employApply?.certification_experience_year ?
                        <AddCareerView highLookup={employApply} />
                        :
                        <CertificationCareerView highLookup={employApply} />
                    }
                  </h1>
                  {employApply.rec_company ?
                    <h2 className="txt_black ellipsis">{employApply.rec_company}</h2>
                    : ""
                  }

                  {!employApply.rec_department || employApply.rec_department === "null" ? "" :
                    <h3 className="ellipsis line3">{employApply.rec_department}&nbsp;{employApply.vps_name}</h3>
                  }

                  {employApply.reh_name ?
                    <h4 className="ellipsis">{employApply.reh_name}&nbsp;{employApply.reh_major}</h4>
                    : ""
                  }

                </hgroup>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
}

export default NewApply;
