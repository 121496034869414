/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

function Account() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varInitData, setInitData] = useState([]);
  const [varAdd, setAdd] = useState(false);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/account`).then(res => {
      console.log(res);
      const data = res.data.map((data, i) => ({
        order: i + 1,
        bank_idx: data.bank_idx,
        bank_name: data.bank_name,
        bank_account: data.bank_account,
        bank_owner: data.bank_owner,
      }));
      setInitData([...data]);
    });
    //console.log(varInitData);
  }, [])

  //계좌 삭제
  const deleteHandler = idx => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return false;
    }
    if (idx > 0) {
      axios.post(`${CONFIG.SERVER_HOST}/account_delete?bank_idx=${idx}`).then(res => {
        console.log('delete :: result :: ', res);
        alert('삭제되었습니다.')
      });
    } 
    setInitData(varInitData.filter(data => data.bank_idx !== idx));
  }

  //계좌 추가
  const AddRow = () => {
    if(varAdd) {
      alert('추가한 계좌를 먼저 저장하신 후에 다시 계좌추가를 진행하십시오.');
      return false;
    }
    const todo = {order:varInitData.length+1,bank_idx:0,bank_name:"",bank_account:"",bank_owner:""};
    setInitData(varInitData.concat(todo));
    setAdd(true);
  }
  //추가 계좌 저장
  const onSubmitHandler = e => {
    const finsIndex = varInitData.findIndex(element => element.bank_idx === 0);
    if(finsIndex === -1) {
      alert('추가된 계좌가 없습니다.');
      return false;
    }
    console.log(finsIndex);
    const newData = varInitData.filter(element => element.bank_idx === 0)[0];
    console.log(newData);
    if (newData.bank_name === "") {
      alert('은행명을 입력해주세요.');
    } else if (newData.bank_account === "") {
      alert('계좌번호를 입력해주세요.');
    } else if (newData.bank_owner === "") {
      alert('예금주를 입력해주세요.');
    } else {
      const params = new URLSearchParams();
      params.append("options", JSON.stringify(newData));
      axios.post(`${CONFIG.SERVER_HOST}/account_create`, params).then(res => {
        if (res.data.retvalue === 1) {
          //배열 재저장 - 걔좌 고유번호 지정
          let copyData = [...varInitData];
          copyData[finsIndex]={...copyData[finsIndex],bank_idx:res.data.bank_idx};
          setInitData(copyData);    
          //계좌추가버튼 활성화
          setAdd(false);
        }
        alert(res.data.message);
      })
    }
  }

  //입력값 배열로 저장후 화면에 표시
  const onChangeHandler = (e,bank_idx) => {
    if(bank_idx > 0) {  //추가된 계좌가 아니면 제외
      return false;
    }
    //입력내용 읽기
    const {name, value} = e.currentTarget;
    //배열에서 추가계좌 배열 찾기
    const finsIndex = varInitData.findIndex(element => element.bank_idx === 0);
    let copyData = [...varInitData];
    //입력값 변경
    copyData[finsIndex]={...copyData[finsIndex],[name]:value};
    //배열 제설정
    setInitData(copyData);
  }

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="bank" />
      <div id="contents" className="ad_contents">
        <div className="tit">계좌 관리
          <div className="path">
            <img src={iconhome} alt=""/>
            HOME<img src={arrImage} alt=""/>환경설정<img src={arrImage} alt="" />운영관리<img src={arrImage} alt=""/>계좌 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">계좌 관리</div> */}
          <div className="main-box">
            <table className="list">
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>번호</th>
                  <th style={{ width: '20%' }}>은행명</th>
                  <th style={{ width: '40%' }}>계좌번호</th>
                  <th style={{ width: '20%' }}>예금주</th>
                  <th style={{ width: '10%' }}>관리</th>
                </tr>
              </thead>
              <tbody>
                {varInitData.map((data, i) => (
                  <tr key={'account'+i}>
                    <td>{data.order}</td>
                    <td>
                      <input type="text" name="bank_name" value={data.bank_name}
                        onChange={(e)=>onChangeHandler(e,data.bank_idx)}
                        style={data.bank_idx>0?{pointerEvents:"none",backgroundColor:"#ddd"}:{}}
                      />
                    </td>
                    <td>
                      <input type="text" name="bank_account" value={data.bank_account}
                        onChange={(e)=>onChangeHandler(e,data.bank_idx)}
                        style={data.bank_idx>0?{pointerEvents:"none",backgroundColor:"#ddd"}:{}}
                      />
                    </td>
                    <td>
                      <input type="text" name="bank_owner" value={data.bank_owner}
                        onChange={(e)=>onChangeHandler(e,data.bank_idx)}
                        style={data.bank_idx>0?{pointerEvents:"none",backgroundColor:"#ddd"}:{}}
                      />
                    </td>
                    <td><button className="btn line black" onClick={()=>deleteHandler(data.bank_idx)}>삭제</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="btn-center">
            <button className="btn" onClick={()=>AddRow()}>추가</button>
            <button onClick={()=>onSubmitHandler()}>저장</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Account
