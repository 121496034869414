import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { resetNavMenu } from "../../components/Common/Common";
import SupportViewC from "../../components/Announcement/SupportViewC";
//css

const SupportView = (/* { setPageTitle } */) => {
  const location = useLocation();
  const [varEmpIndex, setEmpIndex] = useState(0);

  useEffect(() => {
    /* setPageTitle("공고/지원자 관리"); */
    resetNavMenu(1);
    if (location !== undefined && location.state !== undefined) {
      console.log(location.state.em_idx);
      setEmpIndex(location.state.em_idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return varEmpIndex === 0 ? null : (
    <div className="center_wrap company">
      <section className="page_announcement view_sec support">
        <h2 className="sub_content_title indent">공고/지원자 관리</h2>
        <ul className="tab01 blue col2">
          <li>
            <Link to={"/Company/ComAnnouncement/AnnouncementList"}>공고관리</Link>
          </li>
          <li className="active">
            <Link
              to={"/Company/ComAnnouncement/SupportList"}
              state={{ ComAnnouncement: 1 }}
              className="on"
            >지원자 관리</Link>
          </li>
        </ul>
        <SupportViewC em_idx={varEmpIndex} />
      </section>
    </div>
  );
};

export default SupportView;
