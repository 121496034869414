/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { blank_pattern, AppLoginSuccess} from "../Common/Common";

const LoginHeadHunterC = () => {
  const Navigator = useNavigate();
  const [varInputs, setInputs] = useState({ id: "", password: "", auto_login:1 });

  const onChangeInputHandler = (e) => {
    const { value, name } = e.currentTarget;
    setInputs({ ...varInputs, [name]: value});
  };

  const onCheckedHandler = (e,checked) => {
    const { value, name } = e.currentTarget;
    if(checked){
      setInputs({ ...varInputs, [name]: value, auto_login:1 });
    }else{
      setInputs({ ...varInputs, [name]: value, auto_login:0 });
    }
  }

  const onLoginHandler = async (e) => {
    try {
      if (!varInputs.id) {
        alert("아이디를 입력해주세요.");
        return;
      } else if (!blank_pattern(varInputs.id)) {
				alert("아이디에 공백이 있습니다.\n공백 없이 입력해주세요.");
				return;
			} else if (!varInputs.password) {
        alert("비밀번호를 입력해주세요.");
        return;
      } else if (!blank_pattern(varInputs.password)) {
				alert("비밀번호에 공백이 있습니다.\n공백 없이 입력해주세요.");
				return;
			} 

      const params = new URLSearchParams();
      params.append("mem_id", varInputs.id);
      params.append("mem_pwd", varInputs.password);
      params.append("mem_mode", 2);
      params.append("mem_type", 0);

      await axios.post(`${CONFIG.SERVER_HOST}/login`, params).then((res) => {
        if (res.data.retvalue === 1) {
          const datas = res.data.results;
          if (datas.mem_status === 0) {
            alert(
              "탈퇴한 회원입니다. 재가입하고자 하는 경우 heding@heding.co.kr 로 연락바랍니다."
            );
            return false;
          } else {
            AppLoginSuccess(datas.mem_idx);
            sessionStorage.setItem("isLogin", true)
            localStorage.setItem("memlogin", true);
            localStorage.setItem("mem_id", datas.mem_id);
            localStorage.setItem("mem_idx", datas.mem_idx);
            localStorage.setItem("mem_nickname", datas.mem_nickname);
            localStorage.setItem("mem_mode", 2); //0:일반/1:기업/2:헤드헌터
            localStorage.setItem("mem_hunter_type", datas.mem_hunter_type); //0:프리랜서/1:서치폼 (헤드헌터)
            localStorage.setItem("mem_name", datas.mem_name);
            localStorage.setItem("siteMode", 1);	//사이트 모드
            let nav_url;
            // 기업 헤더 구분값 변경(mem_hunter_type -> mem_mode)
            if (datas.mem_mode > 0) {
              //서치폼 (헤드헌터)
              localStorage.setItem("mcm_idx", datas.mcm_idx);
              localStorage.setItem("mcm_name", datas.mcm_name);
              localStorage.setItem("mem_sub", datas.mem_sub === 0 ? datas.mem_idx : datas.mem_sub);
              localStorage.setItem("com_idx", datas.com_idx);
              //Navigator("/Company");
              nav_url = "/Company";
            } else {
              localStorage.setItem("mcm_idx", 0);
              localStorage.setItem("mem_sub", datas.mem_idx);
              localStorage.setItem("com_idx", 0);
              //Navigator("/main");
              nav_url = "/";
            }
            localStorage.setItem("auto_login", varInputs.auto_login)
            //2022-08-20 로그인 후 페이지 이동 - KSR
            const re_rul = localStorage.getItem("re_url");
            localStorage.removeItem('re_url');
            const params2 = new URLSearchParams();
            params2.append("mem_idx", datas.mem_idx);
            axios.post(`${CONFIG.SERVER_HOST}/updateLastLogin`, params2).then((res) => {console.log(res)});
  
            if(re_rul===null || re_rul===undefined || re_rul===""){
              Navigator(nav_url);
            }else{
              Navigator(re_rul);              
            }

          }
        } else {
          alert(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="tab01_contents">
      <div className="login member_form">
        <p className="title_field">아이디</p>
        <input
          type="text"
          name="id"
          placeholder="아이디를 입력해주세요"
          value={varInputs.id}
          onChange={(e) => onChangeInputHandler(e)}
        />
        <p className="title_field">비밀번호</p>
        <input
          type="password"
          name="password"
          placeholder="비밀번호를 입력해주세요"
          value={varInputs.password}
          onChange={(e) => onChangeInputHandler(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onLoginHandler(e)
            }
          }}
        />
        <div className="auto_login"><input type="checkbox" name="auto_login" id="auto_login" className="chk" value={varInputs.auto_login} onChange={(e) => onCheckedHandler(e, e.target.checked)} checked={varInputs.auto_login === 1 ? true : false}/><label htmlFor="auto_login" >자동로그인</label></div>
        <div className="link_area">
          <Link to={"/JoinUs/HeadHunter"} state={"HeadHunter"} className="btn_join">
            아직 헤딩 회원이 아니신가요?
          </Link>
          <Link to={"/Member/FindID"} state={{mem_mode:2}} className="btn_find">
            아이디 찾기
          </Link>
          <span className="divisionBar"></span>
          <Link to={"/Member/FindPW"} className="btn_find">비밀번호 찾기</Link>
        </div>
        <div className="bottom_btn_area">
          <button className="btn" onClick={(e) => onLoginHandler(e)}>로그인</button>
        </div>
      </div>
    </section>
  );
};

export default LoginHeadHunterC;
