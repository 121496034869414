import React from "react";
import FindIDC from "../../components/Member/FindIDC";

// css
import "css/Common.css";

const FindID = () => {
  return <FindIDC />;
};

export default FindID;
