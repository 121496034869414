/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import moment from 'moment';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import paginate from '../paging/Paginate';
import Pagination from '../paging/Pagination';

function Job_Posting() {

    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜
    const [search, setSearch] = useState("none");
    const [searchInput, setSearchInput] = useState("")

    const [employlist, setEmploylist] = useState([]);

    useEffect(() => {
        axios.post(`${CONFIG.SERVER_HOST}/job_posting`).then((res) => {
            console.log(res);
            const data = res.data.map((data, i) => (
                {
                    order: i + 1,
                    em_idx: data.em_idx,
                    em_title: data.em_title,
                    mcm_name: data.mcm_name,
                    em_tasks: data.em_tasks,
                    em_reward_applicant: data.em_reward_applicant,
                    em_place: data.em_place,
                    em_careers: data.em_careers,
                    em_type: data.em_type,
                    em_status: data.em_status,
                    em_rdate: data.em_rdate,

                }
            ))
            setEmploylist(employlist.concat(data));
        });
    }, []);

    const handleType = (e) => {
        setSearch(e.target.value);

    };
    const handleInput = (e) => {
        setSearchInput(e.target.value);

    };
    //검색
    const handleSearchClear = () => {
        setStartDate("");
        setEndDate("");
        setSearch("");
        setSearchInput("");
    }

    const Employlist = employlist.filter((employ) => {
        if (startDate !== "" || searchInput !== "") {
            if (startDate !== "" && endDate === "") {
                return moment(employ.em_rdate).format(CONFIG.formatStr) === moment(startDate).format(CONFIG.formatStr);
            } else if (startDate !== "" && endDate !== "") {
                return moment(employ.em_rdate).format(CONFIG.formatStr) >= moment(startDate).format(CONFIG.formatStr) && moment(employ.mem_r_date).format(CONFIG.formatStr) <= moment(endDate).format(CONFIG.formatStr);
            } else if (search !== "") {
                if(search === "mcm_name") {
                    return employ.mcm_name.includes(searchInput);
                }else if (search === "em_tasks") {
                    return employ.em_tasks.includes(searchInput);
                }else if (search === "em_place") {
                    return employ.em_place.includes(searchInput);
                }else if (search === "em_careers") {
                    return employ.em_careers.includes(searchInput);
                }else if (search === "em_type") {
                    return employ.em_careers.includes(searchInput);
                }
            }
        } else {
            return employlist;
        }
        return employlist;
    });



    //페이징
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(5);

    const onPgaeChange = (num) => {
        console.log("num", num);
        setCurrentPage(num);
    }
    const handleMovePage = (s, e) => {
        setStart(s)
        setEnd(e)

    }
    const pagedList = paginate(Employlist, currentPage, pageSize);

    // const onChangeOpenDate = () => {
    //     setIsOpenDate(!isOpenDate);
    //   };
    const handlestartdate = (e) => {
        setStartDate(e.target.value);
        console.log("startDate", startDate);
    }
    const handleenddate = (e) => {
        setEndDate(e.target.value);
    }

    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="job_posting" />
            <Menu menuType="job_posting" menu="job_posting" />
            <div id="contents" className="ad_contents">
                <div className="tit">공고 관리
                    <div className="path">
                        <img src={iconhome} alt=""/>
                        HOME<img src={arrImage} alt=""/>공고 관리<img src={arrImage} alt=""/>공고 관리<img src={arrImage} alt=""/>채용 공고 관리
                    </div>
                </div>
                <div className="content">
                    <div className="main-box">
                        <div className="search-box">
                          <p>검색건수 : </p>
                          <br />
                          <div className="day-box">
                              <p>기간검색</p>
                              <input type="date" name="startdate" value={startDate} onChange={handlestartdate} readonly id="datePicker" />
                              ~
                              <input type="date" name="enddate" value={endDate} onChange={handleenddate} readonly id="datePicker2" />
                          </div>
                          <div className="f-middle">
                              <p>진행상태</p>
                              <select className="short_select" name="status" value={search} onChange={handleType}>
                                  <option value="all">전체</option>
                                  <option value="0" >대기중</option>
                                  <option value="1" >진행중</option>
                                  <option value="2" >마감</option>
                              </select>
                              <input type="text" name="strsearch" value={searchInput} onChange={handleInput} style={{ width: "270px" }} />
                          </div>
                          <div className="f-right">
                            <select className="short_select" name="search" value={search} onChange={handleType}>
                                <option value="all">전체</option>
                                <option value="mcm_name">회사명</option>
                                <option value="em_tasks">직무</option>
                                <option value="em_place">지역</option>
                                <option value="em_careers">경력</option>
                                <option value="em_type">채용구분</option>
                            </select>
                            <input type="text" name="strsearch" value={searchInput} onChange={handleInput} style={{ width: "270px" }} />
                            <button onClick={handleSearchClear} className="btn black" >초기화</button>
                          </div>
                        </div>
                    </div>
                    <div className="main-box2">
                        <div className="btn-right">
                            <button className="btn-danger"><Link to="/admin/job_register" style={{ color: "#fff", width: "100%" }}>등록</Link></button>
                        </div>
                    </div>
                    <div className="main-box">
                        <input type="hidden" name="typ" value="" />
                        <input type="hidden" name="delchk" value="0" />
                        <table className="list">
                            <thead>
                              <tr>
                                <th>번호</th>
                                <th>채용제목</th>
                                <th>회사명</th>
                                <th>직무</th>
                                <th>총 보상금</th>
                                <th>지역</th>
                                <th>경력</th>
                                <th>채용구분</th>
                                <th>진행 상태</th>
                                <th>등록일</th>
                                <th>채용상세</th>
                              </tr>
                            </thead>
                            <tbody>
                                {pagedList.map((employ, i) => (
                                    <tr key={i}>
                                        <td><p>{employ.order}</p></td>
                                        <td><p>{employ.em_title}</p></td>
                                        <td><p>{employ.mcm_name}</p></td>
                                        <td><p>{employ.em_tasks}</p></td>
                                        <td><p>{employ.em_reward_applicant}</p></td>
                                        <td><p>{employ.em_place}</p></td>
                                        <td><p>{employ.em_careers}</p></td>
                                        <td><p>{employ.em_type}</p></td>
                                        <td><p>{employ.em_status}</p></td>
                                        <td><p>{moment(employ.mem_rdate).format(CONFIG.formatStr)}</p></td>
                                        <td>
                                            <Link to=''><button>상세</button></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <Pagination
                            itemsCount={Employlist.length}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPgaeChange={onPgaeChange}
                            start={start}
                            end={end}
                            handleMovePage={handleMovePage}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}
     
export default Job_Posting;