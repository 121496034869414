/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";

const ArrList = ({friends, changeFriend}) => {

  //console.log('000000',friends);

  return (
    <>
      {friends.map((item) => { return (<li key={item.anwser_no} id={item.anwser_no} onClick={changeFriend}>{item.anonymous ? item.anonymous : item.anwser_writer}</li>) })}
    </>
  );
};

export default ArrList;
