/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect} from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
import { changeMobileFormat } from '../Common/Common'

//이미지
import ProfileImg from 'img/sub/profile.jpg';
import ButtonNaver from 'img/sub/btn_naver.jpg';
import ButtonKakao from 'img/sub/btn_kakao.jpg';
import ButtonGoogle from 'img/sub/btn_google.jpg';

function Profile({ onSetPopup,varMemberData,setMemberData }) {

  const Popupum = Number(localStorage.getItem('mem_type')) === 0 ? 3:1;
  const user_idx = Number(localStorage.getItem('mem_idx'));
  const photoPath = `${CONFIG.SERVER_HOST}/user_photo/`;
  const fileRef= useRef(null);
  const [varFileImage,setFileImage] = useState(ProfileImg);
  const [varEditInfo, setEditInfo] = useState({
    mem_email: varMemberData?.mem_email,
    mem_mobile: varMemberData?.mem_mobile,
    mem_name: varMemberData?.mem_name,
    mem_birthday: varMemberData?.mem_birthday,
    mem_gender: varMemberData?.mem_gender,
    mem_nickname: varMemberData?.mem_nickname,
    mem_type: varMemberData?.mem_type
  })

  //파일선택이미지 클릭시
  const onSelectPhotoHandler = (e) => {
    e.preventDefault();
    fileRef.current.click();
  }

  //회원 사진 파일 선택후 --> 1. 페이지에 표시, 2.서버로 저장, 3.데이타베이스 저장
  const onUploadPhotoHandler = (e) => {
    //console.log(e.currentTarget.files);
    //파일을 선택한 후 파일 재선택을 위해 메뉴를 호출한 후 취소버튼을 누른 경우
    if(e.currentTarget.files.length === 0) {
        return false;
    }
    //만약을 위해 - 선택된 파일 없는 경우
    if(e.currentTarget.files === undefined || e.currentTarget.files === null) {
        alert('선택된 파일이 없습니다. 파일을 선택하세요.');
        return false;
    }
    //jpg, png 파일만 등록 가능하게
    if((e.target.files[0].name.split('.').reverse()[0] !== 'jpg') && (e.target.files[0].name.split('.').reverse()[0] !== 'png')) {
      alert('jpg, png만 가능합니다.');
      return false;
    }

    //파일객체
    let file = e.currentTarget.files[0];
    //화면에 이미지 표시
    setFileImage(URL.createObjectURL(file));

    //서버로 저장---
    const formData = new FormData();
    formData.append('user_photo', file);
    axios.post(`${CONFIG.SERVER_HOST}/user_photo`,formData).then(res => {
      //서버로 저장된 파일명
      const savedFileName = res.data.fileName;   
      //데이타베이스 저장 
      const params = new URLSearchParams();
      params.append("filename", savedFileName);
      params.append("mem_idx", user_idx);
      axios.post(`${CONFIG.SERVER_HOST}/_upload_member_photo`,params).then(res => {
        if(res.data.retvalue === 1) {
          //변수 재설정 - 회원사진관련 정보
          setMemberData({...varMemberData,mem_photo:1,mem_ufile:savedFileName});
          setFileImage(photoPath+varMemberData?.mem_ufile);
        }else{
          alert(res.data.message);
        }
      });
    });
  };

  //회원정보 읽기
  const findProfile = async() => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/findProfile`, params).then((res) => {
      console.log(res.data)
      if (res.data.retvalue === 1) {
          setEditInfo(res.data.result);
      }else{
          alert("findProfile", res.data.message)
      }
    })
    .catch((err) => {
        console.log('comment-err ' + err)
    })
  }

  //회원정보가 변경되면
  useEffect(() => {
    if(varMemberData !== undefined && varMemberData?.mem_photo===1 && varMemberData?.mem_ufile !== undefined && varMemberData?.mem_ufile !== "") {
      setFileImage(photoPath+varMemberData?.mem_ufile);
    }
    findProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberData])

  
  return (
    <section className="my_profile">
        <article className="top_area">
            <h3 className="title"><span className="point">{varMemberData?.mem_name}</span>님의 프로필</h3>  
            <div className="btn_area"> 
              <button className="btn" onClick={(e)=>onSetPopup(e, Popupum)}>수정하기</button>
              {Number(localStorage.getItem('mem_type')) === 0 ? <button className="btn line black" onClick={(e)=>onSetPopup(e,2)}>비밀번호 변경</button>:null }
            </div>
        </article>
        {/*프로필이미지*/}
        <article className="info_wrap">
            <div className="img_area">
                <div className="profile_img">
                    <img src={varFileImage} alt="" />
                </div>
                <input type="file" ref={fileRef} hidden={true} name="member_file" accept="image/*" onChange={(e)=>onUploadPhotoHandler(e)} className="add_profile"/>
                <label htmlFor="add_profile" onClick={(e)=>onSelectPhotoHandler(e)}>
                프로필 사진 등록</label>     
            </div>
            <ul className="info_list">
                <li>
                    <p className="title_field">닉네임</p>
                    <p className="info_field">{varEditInfo?.mem_nickname}</p>
                </li>
                <li>
                    <p className="title_field">이름</p>
                    <p className="info_field">{varEditInfo?.mem_name}</p>
                </li>
                <li>
                    <p className="title_field">생년월일</p>
                    <p className="info_field">{varEditInfo?.mem_birthday}</p>
                </li>
                <li>
                    <p className="title_field">성별</p>
                    <p className="info_field">{varEditInfo?.mem_gender===0 ? "남" : "여"}</p>
                </li>
                <li>
                    <p className="title_field">이메일</p>
                    <p className="info_field">{varEditInfo?.mem_email}</p>
                </li>
                <li>
                    <p className="title_field">휴대폰번호</p>
                    <p className="info_field">{changeMobileFormat(varEditInfo?.mem_mobile)}</p>
                </li>
                { 0 !== varMemberData?.mem_type ? 
                <li>
                    <p className="title_field">SNS 인증</p>
                    <p className="info_field">
                        {varMemberData?.mem_type === 1 ? <button className="button"><img src={ButtonNaver} alt="" /></button> : null}
                        {varMemberData?.mem_type === 2 ? <button className="button"><img src={ButtonKakao} alt="" /></button> : null}
                        {varMemberData?.mem_type === 3 ? <button className="button"><img src={ButtonGoogle} alt="" /></button> : null}       
                    </p>
                </li>
                : null}
            </ul>
        </article>
        <p className="tip_field">※ 이미지 크기 : 157 x 200px (jpg, png만 가능)</p>
    </section>
  );
}

export default Profile;
