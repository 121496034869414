import React, { useEffect, useState, useCallback }from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
import {Link} from 'react-router-dom';
import {em_titleLength} from '../Common/Common';


  /*이력서*/
  function Rsum() {
    const user_idx = localStorage.getItem('mem_idx');
    //const [resumeList, setResumeList] = useState([]);
    const [sortResumeList, setSortResumeList] = useState([]);
    const [value, setValue] = useState(0);


    const getResumeList = useCallback(() => {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      axios.post(`${CONFIG.SERVER_HOST}/_resume_list`,params).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const data = res.data.results.map((data,i)=>({
              re_idx: data.re_idx,
              re_title:data.re_title,
              re_rdate:data.re_rdate.substr(0,10),
              re_perfection:data.re_perfection+" %",
              re_fix:data.re_fix
          }));
          //setResumeList(data);
          setSortResumeList(data.sort((a,b) => b.re_fix - a.re_fix))

          const chIndex = res.data.results.findIndex((el) => el.re_fix === 1);
          console.log("chIndex ",chIndex)
          if(chIndex > -1 && res.data.results[chIndex]?.re_idx > 0){//
            setValue(res.data.results[chIndex]?.re_idx);
          }else{
            setValue(0)
          }
        } else {
          //setResumeList([]);
          setSortResumeList([])
        }
      });
    }, [user_idx]);

    useEffect(() => {
      getResumeList();
    }, [getResumeList]);


    const onValueHandler = e => {
      setValue(e.currentTarget.value)
    }


    const onsetRepResumeHandler = async () => {
      const finsIndex2 = sortResumeList.findIndex(element => element?.re_fix === 1); //현재 대표 이력서 찾기
      //console.log(sortResumeList[finsIndex2]?.re_idx, value)


      //데이타베이스 적용
      const params = new URLSearchParams();
      params.append("re_idx1", Number(finsIndex2 === -1 ? 0 : sortResumeList[finsIndex2]?.re_idx));  //이전 대표 이력서
      params.append("re_idx2", Number(value));  //새로운 대표 이력서
      await axios.post(`${CONFIG.SERVER_HOST}/_set_representative_resumes`, params).then((res) => {
        if (res.data.retvalue === 1) { //수정 성공시 스테이트 변경
          getResumeList();
        } else {
          alert(res.data.message);
        }
      });
      }


  return (
    <section className="my_resume_area">
      <article className="top_area">
          <h3 className="title">이력서</h3>
            <div className="btn_area">
              <Link to="/MyResume" className="btn">이력서 관리</Link>
            </div>
      </article>
      <article className="main_resume_select_area">
        <div className="table_notice_badge">대표</div>
        <select value={value} onChange={(e)=>onValueHandler(e)}>
          <option value={-1}>선택</option>
        {sortResumeList?.length > 0 ?
          sortResumeList.map((resume,i) => (
            <option key={i} value={resume.re_idx}>{resume.re_title === "" ? "임시저장" : resume.re_title}</option>
          ))
          :
          <>
            <option className="Title">등록된 이력서가 없습니다.</option>
          </>
        }
        </select>
        <div className="btn_area">
          <a className="btn line" href={()=> false} onClick={(e) => onsetRepResumeHandler(e)}>대표 이력서 지정</a>
        </div>
      </article>
      <ul className="resume_list">
        {sortResumeList?.length > 0 ?
          sortResumeList.map((resume,i) => (
            <li key={'resume_'+resume?.re_idx}>
              <p className="title_field"><Link to={`/MyResume/ResumeView/${resume.re_idx}`} >{resume.re_title === "" ? "임시저장" : em_titleLength(resume.re_title, 25, '...')}</Link></p>
              <p className="date_field">{resume.re_rdate}</p>
              <p className="state_field ing">{resume.re_perfection}</p>
            </li>
          ))
          :
          <li>
              <p className="Title">등록된 이력서가 없습니다.</p>
          </li>
        }
      </ul>
    </section>
  );
}

export default Rsum;