/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useState } from "react";
import CONFIG from "../../configs/configs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoogleLoginBtn from "../Layout/GoogleLoginButton";
import KakaoLogin from "../Layout/KakaoLoginButton";
import NaverLoginButton from "../Layout/NaverLoginButton";
import AppleLoginButton from "../Layout/AppleLoginButton";
import loginVolt from "../../img/sub/login_volt.png";
import { AppLoginSuccess, blank_pattern } from "../Common/Common";


const LoginNormalC = () => {
	const navigator = useNavigate();
	const location = useLocation();
	const [varInput, setInputs] = useState({ id: "", password: "", auto_login: 1 });
	const [varSNSLogin] = useState(0);

	const onChangeInputHandler = (e) => {
		const { value, name } = e.currentTarget;
		setInputs({ ...varInput, [name]: value });
	};

	const onCheckedHandler = (e, checked) => {
		const { value, name } = e.currentTarget;
		if (checked) {
			setInputs({ ...varInput, [name]: value, auto_login: 1 });
		} else {
			setInputs({ ...varInput, [name]: value, auto_login: 0 });
		}
	}

	const onSnsLoginHandler = async (sns_id, sns_type, email) => {
		try {
			// const sns_id = sns_id;
			// const sns_type = localStorage.getItem("sns_type");
			// console.log("success", sns_id, sns_type);
			const params = new URLSearchParams();
			params.append("mem_id", sns_id);
			params.append("mem_type", sns_type);

			await axios.post(`${CONFIG.SERVER_HOST}/snsLogin`, params).then((res) => {
				if (res.data.retvalue === 1) {

					const data = res.data.results;
					AppLoginSuccess(data.mem_idx);
					sessionStorage.setItem("isLogin", true)
					localStorage.setItem("memlogin", true);
					localStorage.setItem("mem_id", data.mem_id);
					localStorage.setItem("mem_idx", data.mem_idx);
					localStorage.setItem("mem_nickname", data.mem_nickname);
					localStorage.setItem("mem_name", data.mem_name);
					localStorage.setItem("mem_mode", 0); //0:일반/1:기업/2:헤드헌터
					localStorage.setItem("mem_hunter_type", 0); //0:프리랜서/1:서치폼 (헤드헌터)
					localStorage.setItem("mem_type", data.mem_type); //0:일반/1:Naver/2:Kakao/3:Google
					localStorage.setItem("mcm_idx", 0);
					localStorage.setItem("mem_approval", data.mem_approval);
					localStorage.setItem("auto_login", varInput.auto_login)
					//2022-10-15 지인평한 관련 - KSR
					localStorage.setItem("repuMy_YN", data.refu_self_YN);	//자기자신 평판 여부
					localStorage.setItem("repuCode", data.refu_code);	//평판 코드
					localStorage.removeItem("sns_id");
					localStorage.removeItem("sns_type");
					localStorage.removeItem("new");

					// 동료평판, jskim 2022.11.02
					const reDirectURL = localStorage.getItem("re_url");
					localStorage.removeItem('re_url');

					//updateLastLogin
					const params2 = new URLSearchParams();
					params2.append("mem_idx", data.mem_idx);
					axios.post(`${CONFIG.SERVER_HOST}/updateLastLogin`, params2).then((res) => {console.log(res)});

					if (!reDirectURL) {
						navigator("/main");
					} else {
						if (location?.state?.reputation) {
							/** reputation
							 * 1. 미로그인 상태에서 동료평판 페이지 첫 접속
							 * 2. 동료 평판 페이지의 미로그인 상태일때 나오는 팝업창 버튼 클릭 후 로그인 페이지로 이동
							 * 3. 로그인 성공 후 다시 re-direct로 동료평판 페이지 이동한 경우, reputation: true
							 */
							// navigator(reDirectURL, { state: { reputation: true } });
							navigator(reDirectURL);
						}
						navigator(reDirectURL);
					}
				} else {
					//localStorage.setItem("new", true);
					navigator("/JoinUs/User", { state: { sns_id: sns_id, sns_type: sns_type, email: email } });
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const onLoginHandler = async (e) => {
		// console.log(varInput);
		try {
			if (!varInput.id) {
				alert("아이디를 입력해주세요.");
				return;
			} else if (!blank_pattern(varInput.id)) {
				alert("아이디에 공백이 있습니다.\n공백 없이 입력해주세요.");
				return;
			} else if (!varInput.password) {
				alert("비밀번호를 입력해주세요.");
				return;
			} else if (!blank_pattern(varInput.password)) {
				alert("비밀번호에 공백이 있습니다.\n공백 없이 입력해주세요.");
				return;
			}

			const params = new URLSearchParams();
			params.append("mem_id", varInput.id);
			params.append("mem_pwd", varInput.password);
			params.append("mem_mode", 0);
			params.append("mem_type", varSNSLogin); //0:일반/1:네이버/2:카카오/3:구글

			await axios.post(`${CONFIG.SERVER_HOST}/login`, params).then((res) => {
				if (res.data.retvalue === 1) {
					const datas = res.data.results;
					if (datas.mcm_status === 0) {
						alert(
							"탈퇴한 회원입니다. 재가입하고자 하는 경우 heding@heding.co.kr 로 연락바랍니다."
						);
						return false;
					} else if (datas.mem_idx === null) {
						alert("개인정보를 확인할 수 없습니다. 당사로 문의하세요.");
						return false;
					} else {
						sessionStorage.setItem("isLogin", true)
						AppLoginSuccess(datas.mem_idx);
						localStorage.setItem("memlogin", true);
						localStorage.setItem("mem_id", datas.mem_id);
						localStorage.setItem("mem_idx", datas.mem_idx);
						localStorage.setItem("mem_nickname", datas.mem_nickname);
						localStorage.setItem("mem_name", datas.mem_name);
						localStorage.setItem("mem_mode", 0); //0:일반/1:기업/2:헤드헌터
						localStorage.setItem("mem_hunter_type", 0); //0:프리랜서/1:서치폼 (헤드헌터)
						localStorage.setItem("mem_type", 0); //0:일반/1:Naver/2:Kakao/3:Google
						localStorage.setItem("mcm_idx", 0);
						localStorage.setItem("mem_sub", 0);
						localStorage.setItem("com_idx", 0);
						localStorage.setItem("mem_approval", datas.mem_approval);
						localStorage.setItem("auto_login", varInput.auto_login)
						// localStorage.setItem("auto_login", 1);
						//2022-10-15 지인평한 관련 - KSR
						localStorage.setItem("repuMy_YN", datas.refu_self_YN);	//자기자신 평판 여부
						localStorage.setItem("repuCode", datas.refu_code);	//평판 코드
						//2022-08-20 로그인 후 페이지 이동 - KSR
						const reDirectURL = localStorage.getItem("re_url");
						localStorage.removeItem('re_url');
						const params2 = new URLSearchParams();
						params2.append("mem_idx", datas.mem_idx);
						axios.post(`${CONFIG.SERVER_HOST}/updateLastLogin`, params2).then((res) => {console.log(res)});

						
						if (reDirectURL === null || reDirectURL === undefined || reDirectURL === "") {
							navigator("/main");
						} else {
							// 동료평판, jskim 2022.11.02
							if (location?.state?.reputation) {
								// navigator(reDirectURL, { state: { reputation: true } });
								navigator(reDirectURL);
							}
							navigator(reDirectURL);
						}
					}
				} else {
					alert(res.data.message);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
	// console.log(varInput.auto_login);
	return (
		<>
			<section className="tab01_contents">
				<article className="login_sns">
					<h3 className="title_field"><img src={loginVolt} alt="" /><span>3초</span> 만에 빠른 회원가입</h3>
					<NaverLoginButton onNaverLogin={onSnsLoginHandler} />
					<KakaoLogin onKakaoLogin={onSnsLoginHandler} />
					<GoogleLoginBtn auto_login={varInput.auto_login} onGoogleLogin={onSnsLoginHandler} />
					<AppleLoginButton auto_login={varInput.auto_login} onAppleLogin={onSnsLoginHandler} />

					

					{/* {window.ReactNativeWebView ?
						<AppleLoginButton />
					:null
					}  */}
					
					<div className="auto_login"><input type="checkbox" name="auto_login" id="auto_login" className="chk" value={varInput.auto_login} onChange={(e) => onCheckedHandler(e, e.target.checked)} checked={varInput.auto_login === 1 ? true : false} /><label htmlFor="auto_login" >자동로그인</label></div>
				</article>
				<div className="login member_form">
					<input type="checkbox" className="login_toggle_btn" id="loginToggleBtn" />
					<label htmlFor="loginToggleBtn">HEDING 사이트 계정으로 로그인하기<span className="arrow"></span></label>
					<div className="login_toggle_wrap">
						{/* <p className="title_field">아이디</p> */}
						<input
							type="text"
							name="id"
							placeholder="아이디를 입력해주세요"
							value={varInput.id}
							onChange={(e) => onChangeInputHandler(e)}
						/>
						{/* <p className="title_field">비밀번호</p> */}
						<input
							type="password"
							name="password"
							placeholder="비밀번호를 입력해주세요"
							value={varInput.password}
							onChange={(e) => onChangeInputHandler(e)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									onLoginHandler(e)
								}
							}}
						/>
						<div className="bottom_btn_area">
							<button className="btn" onClick={(e) => onLoginHandler(e)} >
								로그인
							</button>
						</div>
						<div className="link_area">
							<Link to={"/JoinUs/User"} className="btn_join">
								아직 헤딩 회원이 아니신가요?
							</Link>

							<Link to={"/Member/FindID"} state={{ mem_mode: 0 }} className="btn_find">
								아이디 찾기
							</Link>
							<span className="divisionBar"></span>
							<Link to={"/Member/FindPW"} className="btn_find">
								비밀번호 찾기
							</Link>
						</div>
					</div>
				</div>
			</section>

			{/* 자동로그인 체크시 팝업 : active 추가시 보임 */}
			<div className="popup">
				<article className="popup_wrap popup01 pop_auto_login" style={{ display: "block" }}>
					<div className="title_wrap">
						<figure style={{ backgroundImage: "url()" }}></figure><h1>강혜성(cocon)</h1>
					</div>
					<div className="content_wrap">
						<div className="agree_form">
							<div className="agree_all">
								<input type="checkbox" name="checkall" id="all_chk" className="chk" />
								<label htmlFor="all_chk">전체동의</label>
							</div>
							<ul className="agree_list">
								<li>
									<input type="checkbox" name="condition" id="agree00" className="chk agree" />
									<label htmlFor="agree00">사이트 이용약관 <span className="must_input">(필수)</span></label>
									<button className="button" style={{ cursor: "pointer" }}>자세히</button>
								</li>
								<li>
									<input type="checkbox" name="privacy" id="agree01" className="chk agree" />
									<label htmlFor="agree01">개인정보취급방침 <span className="must_input">(필수)</span></label>
									<button className="button" style={{ cursor: "pointer" }}>자세히</button>
								</li>
								<li>
									<input type="checkbox" name="selfagree" id="agree02" className="chk agree" />
									<label htmlFor="agree02">본인확인 서비스 고유식별정보 처리 동의<span className="must_input">(필수)</span></label>
									<button className="button" style={{ cursor: "pointer" }}>자세히</button>
								</li>
							</ul>
						</div>
					</div>
					<div className="button_wrap">
						<div className="big_button_wrap one">
							<button className="btn">
								동의하고 계속하기
							</button>
						</div>
						<div className="close_button_wrap">
							<button>닫기 X</button>
						</div>
					</div>
				</article>
			</div>
		</>
	);
};

export default LoginNormalC;
