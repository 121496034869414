/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./css/main.css";
// import "./css/board.css";
// import "./css/sub.css";
// import "./css/common.css";
// import "./admin/css/layout.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { removeLocalstorageItem } from "../src/components/Common/Common";

// admin
import AdminLogin from "./admin/pages/login/AdminLogin";
import AdminMain from 'admin/pages/admin/AdminMain';
import AdminAccount from "./admin/pages/admin/AdminAccount";
import SubAccount from "./admin/pages/admin/SubAccount";
import SubAccountRegister from "./admin/pages/admin/SubAccountRegister";
import UserList from "./admin/pages/user/UserList";
import UserRegister from "./admin/pages/user/UserRegister";
import UserInfo from "./admin/pages/user/UserInfo";
import UrlLink from "./admin/pages/link/Urllink";

import AccumulateList from "./admin/pages/user/AccumulateList";
import AccumulateInfo from "./admin/pages/user/AccumulateInfo";
import ExchangeList1 from "./admin/pages/user/ExchangeList1";
import ExchangeInfo1 from "./admin/pages/user/ExchangeInfo1";

import Push from "./admin/pages/admin/Push";
import PushDetail from "./admin/pages/admin/PushDetail";
import PushMenual from "./admin/pages/admin/Push_manual";
import PushList from "./admin/pages/admin/PushList";
import PushPersonal from "./admin/pages/admin/Push_personal";
import JobPosting from "./admin/pages/job_posting/Job_Posting";
import Company from "./admin/pages/search/Company";
import EmployVariables from "./admin/pages/variables/EmployVariables";
import ResumeVariables from "./admin/pages/variables/ResumeVariables";
import OtherVariables from "./admin/pages/variables/OtherVariables";
import BoardList from "./admin/pages/board/BoardList";
import BoardDetail from "./admin/pages/board/BoardDetail";
import ConsultList from "./admin/pages/board/ConsultList";
import ConsultDetail from "./admin/pages/board/ConsultDetail";
import HrIntroList from "./admin/pages/board/HrIntroList";
import HrIntroDetail from "./admin/pages/board/HrIntroDetail";
import FaqSeriousList from "./admin/pages/board/FaqSeriousList";
import FaqSeriousDetail from "./admin/pages/board/FaqSeriousDetail";
import PopUp from "./admin/pages/admin/PopUp";
import PopupRegister from "./admin/pages/admin/PopupRegister";

import Conditions from "./admin/pages/terms/TeConditions";
import Privacy from "./admin/pages/terms/TePrivacy";
import Personalterm from "./admin/pages/terms/TePersonalterm";
import Selfconfirm from "./admin/pages/terms/TeSelfconfirm";
import Agree_to_push_notifications from "./admin/pages/terms/Agree_to_push_notifications";

import MainSlide from "./admin/pages/admin/MainSlide";
import MainSlideDetail from "./admin/pages/admin/MainSlideDetail";
import Account from "./admin/pages/admin/Account";
import Reword from "./admin/pages/admin/Reword";
import CorporateMemberList from "./admin/pages/user/Corporate_Member_List";
import CorporateMemberInfo from "./admin/pages/user/Corporate_Member_Info";
import SubAcountDetail from "./admin/pages/user/Sub_Acount_Detail";
import HeadhunterList from "./admin/pages/user/Headhunter_List";
import HeadhunterFreeInfo from "./admin/pages/user/Headhunter_Free_Info";
import HeadhunterSubDtail from "./admin/pages/user/Headhunter_Sub_Dtail";
import WithdrawalMemberList from "./admin/pages/user/Withdrawal_Member_List";
import WithdrawalMemberDetail from "admin/pages/user/Withdrawal_Member_Detail";
import AnnouncementList from "./admin/pages/announcement/Announcement_List";
import AnnouncementRegister from "./admin/pages/announcement/Announcement_Register";

import SupportList from "./admin/pages/announcement/SupportList";
import SuggestionList from "./admin/pages/announcement/SuggestionList";
import SuggestionInfo from "./admin/pages/announcement/SuggestionInfo";

import FaqList from "./admin/pages/board/FaqList";
import FaqDetail from "./admin/pages/board/FaqDetail";
import TalkList from "./admin/pages/board/TalkList";
import TalkListDetail from "./admin/pages/board/TalkListDetail";
import ResumeTipList from "./admin/pages/board/ResumeTipList";
import ResumeTipListDetail from "./admin/pages/board/ResumeTipListDetail";
import PaymentList from "./admin/pages/using_pass/PaymentList";
import PaymentDetail from "./admin/pages/using_pass/PaymentDetail";
import RefundList from "./admin/pages/using_pass/RefundList";
import RefundDetail from "./admin/pages/using_pass/RefundDetail";
import VoucherList from "./admin/pages/using_pass/VoucherList";
import VoucherDetail from "./admin/pages/using_pass/VoucherDetail";
import AnnouncementModify from "./admin/pages/announcement/Announcement_Modify";
import HeadhunterDedicatedHallList from "./admin/pages/announcement/Headhunter_Dedicated_Hall_List";
import HeadhunterDedicatedHallModify from "./admin/pages/announcement/Headhunter_Dedicated_Hall_Modify";
import Banner from "./admin/pages/admin/Banner";
import BannerDetail from "./admin/pages/admin/BannerDetail";
import EmployeeList from "admin/pages/user/EmployeeList";
import EmployeeDetail from "admin/pages/user/EmployeeDetail";
import QuitMemberList from "admin/pages/user/QuitMemberList";
import QuitMemberDetail from "admin/pages/user/QuitMemberDetail";
import DashBoard from "admin/pages/dashboard/index";

import MyHeding from "./html/User/MyHeding";
import MyResume from "./html/User/MyResume";

/* import My from "./html/User/My"; */
import Leave from "./html/User/Leave";
import FAQ from "html/User/Faq";
import ComFaq from "html/Company/ComFaq";
import ComNotice from "html/Company/ComNotice";
import Notice from "html/User/Notice";
import NoticeView from "components/Board/NoticeView";
import ComNoticeView from "html/Company/ComNoticeView";
/* import MyApply from "./html/User/MyApply";
import MyOffer from "./html/User/MyOffer";
import MyMessage from "./html/User/MyMessage";
import MyRecommend from "./html/User/MyRecommend";
import MyBookmark from "./html/User/MyBookmark";
import MyMoney from "./html/User/MyMoney";
import MyMoneyDetail from "./html/User/MyMoneyDetail";
import MyMoneyChangeDetail from "./html/User/MyMoneyChangeDetail";
import MyNotice from "./html/User/MyNotice"; */
/* import FAQ from "./html/User/FAQ"; */
import MyCompany from "./html/Company/My";
/* import SubID from "./html/Company/SubID"; */
/* import Recommend from "./html/Company/Recommend"; */
import CompanyBookmark from "./html/Company/MyBookmark";

import PageMessage from "./html/Message/Message";

import MainCompany from "./html/Main/Company";
import Main from "./html/Main/Main";
import AppMain from "./html/Main/AppMain";

import LoginNormal from "./html/Member/LoginNormal";
import JoinNormal from "./html/Member/JoinNormal";
import MemberNormal from "./html/Member/MemberNormal";

import MyRecruit from "./html/User/MyRecruit";
import MyTalk from "./html/User/MyTalk";
import MyCareer from "./html/User/MyCareer";

import Reputation from "./html/Pdf/Reputation";

/* import Announcement from "./html/Company/Announcement";
import AnnouncementList from "./html/Company/AnnouncementList";
import AnnouncementWrite from "./html/Company/AnnouncementWrite";
import SupportList from "./html/Company/SupportList";
import SupportView from "./html/Company/SupportView"; */

//기업
import ComAnnouncement from "./html/Company/ComAnnouncement";
import ComResource from "./html/Company/ComResource";
import ComResource2 from "./html/Company/ComResource2";
import ComVoucher from "./html/Company/ComVoucher";
import ComHeding from "./html/Company/ComHeding";
import ComMessage from "./html/Company/ComMessage";

import ComRecruit from "./html/Company/ComRecruit";
import ComResume from "./html/Company/ComResume";

// import Search from './html/resources/Search'
// import SearchResult from './html/resources/SearchResult'
// import SearchView from './html/resources/SearchView'
// import Candidate from './html/resources/Candidate'
// import Interest from './html/resources/Interest'
// import Latest from './html/resources/Latest'

/* import AnnouncementModify from "./html/Company/AnnouncementModify";
import AnnouncementCopy_Write from "./html/Company/AnnouncementCopy_Write"; */
/* import Message from "./html/Company/Message_View"; */

/* import MyResource from "./html/resources/MyResource"; */

import ScrollTop from "./ScrollToTop";
//Message

//UserResumeDetail

import TillkoApi from "./components/Company/Tillko_Api";

import TossPaySuccess from "./html/TossPay/TossPaySuccess";
import TossPayFailed from "./html/TossPay/TossPayFailed";
import Personal from "./components/Layout/Personal";
import Agreement from "./components/Layout/Agreement";
import AboutUs from "./components/Layout/AboutUs";
import Guide from "./components/Layout/Guide";
import GuideCompany from "./components/Layout/GuideCompany";
import GuideHeadHunter from "./components/Layout/GuideHeadHunter";
import Email from "./components/Layout/Email";
import Auth from "./components/Layout/Auth";
import AppleCallback from "./components/Layout/AppleCallback";
import GoogleCallback from "./components/Layout/GoogleCallback";

//페이지 접근 권한 체크
import RequireAuth from "./components/Auth/RequireAuth";

/**공통 tilko 조회 페이지 */
import Common_Inquiry from "html/Member/Common_InquiryOK";

import PdfView from "./html/User/PdfView";
import PdfView2 from "./html/User/PdfView2";

import Update from "./components/CareerUpdate/update";

import AppToken from "./html/Member/Token";

function App() {
  //admin 로그인 상태관리

  const [isLoginAdmin, setIsLoginAdmin] = useState(false);
  const [isLoginUser, setIsLoginUser] = useState(false);
  const [preferences, setPreferences] = useState(0);
  const [member_management, setMember_management] = useState(0);
  const [notice_management, setNotice_management] = useState(0);
  const [board_management, setBoard_management] = useState(0);
  const [pass_management, setPass_management] = useState(0);
  const [search_management, setSearch_management] = useState(0);
  const [statistics, setStatistics] = useState(0);

  //2022-11-17 ios 앱 임시 리디렉션 - KSR
  if(window.location.hostname === "ios.heding-app.kr"){
    console.log("iOS~~~~~~~~~");
    window.location.href = "https://www.heding.co.kr";
  }

  /* const [isLoginCompany, setIsLoginCompany] = useState(false); */

  // const user = localStorage.getItem("mem_idx");

  useEffect(() => {
    if (localStorage.getItem("adminlogin") === "true") {
      if (Number(localStorage.getItem('type')) === 1) {
        setPreferences(Number(localStorage.getItem('preferences')));
        setMember_management(Number(localStorage.getItem('member_management')));
        setNotice_management(Number(localStorage.getItem('notice_management')));
        setBoard_management(Number(localStorage.getItem('board_management')));
        setPass_management(Number(localStorage.getItem('pass_management')));
        setSearch_management(Number(localStorage.getItem('search_management')));
        setStatistics(Number(localStorage.getItem('statistics')));
        setIsLoginAdmin(true);
      }else{
        setIsLoginAdmin(true);
      }

      
    } else {
      setIsLoginAdmin(false);
    }

    if (
      localStorage.getItem("mem_sub") === null ||
      localStorage.getItem("mem_sub") === undefined
    ) {
      if (Number(localStorage.getItem("mem_idx")) > 0) {
        setIsLoginUser(true);
      } else {
        setIsLoginUser(false);
      }
    } else {
      if (
        Number(localStorage.getItem("mem_sub")) === 0 ||
        Number(localStorage.getItem("mem_sub")) > 0
      ) {
        setIsLoginUser(true);
        //setIsLoginCompany(true);
      } else {
        setIsLoginUser(false);
        //setIsLoginCompany(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginAdmin, isLoginUser]);

  useEffect(() => {
    if (localStorage.getItem("closeBanner")) {
      sessionStorage.setItem("banner", false);
    } else {
      sessionStorage.setItem("banner", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const auto_login = Number(localStorage.getItem("auto_login"));
    const isLogin = sessionStorage.getItem("isLogin");
    if (auto_login === 0 && !isLogin) {
      removeLocalstorageItem();
    } else {
      // console.log("자동로그인 미설정")
    }
  }, [])


  return (
    // < isLogin={isLoginAdmin} >
    <>
      <Router>
        <ScrollTop />
        <Routes>
          {/* admin */}
          <Route path="/admin" element={ <AdminLogin />} />
          <Route path="/admin/:id" element={<AdminAccount />} />
          <Route path="/admin_main" element={<AdminMain />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/subadmin" element={<SubAccount />} />
          <Route path="/admin/subregister" element={<SubAccountRegister />} />
          <Route path="/admin/subupdate/:sub_idx/*" element={<SubAccountRegister />} />
          <Route path="/admin/userlist" element={<UserList />} />
          <Route path="/admin/register" element={<UserRegister />} />
          <Route path="/admin/userinfo/:user_idx/*" element={<UserInfo />} />

          <Route path="/admin/accumulatelist" element={<AccumulateList />} />
          <Route path="/admin/accumulateinfo/:user_idx/*" element={<AccumulateInfo />} />
          <Route path="/admin/exchangelist" element={<ExchangeList1 />} />
          <Route path="/admin/exchangeinfo/:user_idx/*" element={<ExchangeInfo1 />} />


          <Route path="/admin/push" element={<Push />} />
          <Route path="/admin/push_detail/:idx" element={<PushDetail />} />
          <Route path="/admin/Push_personal" element={<PushPersonal />} />
          <Route path="/admin/popup" element={<PopUp />} />
          <Route
            path="/admin/popup/register/:pop_idx"
            element={<PopupRegister />}
          />
          <Route path="/admin/banner" element={<Banner />} />
          <Route
            path="/admin/banner/detail/:banner_idx"
            element={<BannerDetail />}
          />
          <Route path="/admin/main_slide" element={<MainSlide />} />
          <Route
            path="/admin/main_slide/detail/:slide_idx"
            element={<MainSlideDetail />}
          />
          <Route path="/admin/push_manual" element={<PushMenual />} />
          <Route path="/admin/pushlist" element={<PushList />} />
          <Route path="/admin/account" element={<Account />} />
          <Route path="/admin/reword" element={<Reword />} />

          <Route path="/admin/condition" element={<Conditions />} />
          <Route path="/admin/privacy" element={<Privacy />} />
          <Route path="/admin/selfconfirm" element={<Selfconfirm />} />
          <Route path="/admin/personalterm" element={<Personalterm />} />

          <Route path="/admin/AgreeTopush" element={<Agree_to_push_notifications />} />

          <Route path="/admin/urllink" element={<UrlLink />} />

          <Route path="/admin/job_posting" element={<JobPosting />} />
          <Route path="/admin/search" element={<Company />} />
          <Route path="/admin/employvariables" element={<EmployVariables />} />
          <Route path="/admin/resumevariables" element={<ResumeVariables />} />
          <Route path="/admin/othervariables" element={<OtherVariables />} />
          <Route path="/admin/boardlist" element={<BoardList />} />
          <Route
            path="/admin/boarddetail/:board_idx"
            element={<BoardDetail />}
          />
          <Route path="/admin/consultlist" element={<ConsultList />} />
          <Route
            path="/admin/consultdetail/:board_idx"
            element={<ConsultDetail />}
          />
          <Route path="/admin/hrintrolist" element={<HrIntroList />} />
          <Route
            path="/admin/hrintrodetail/:board_idx"
            element={<HrIntroDetail />}
          />
          <Route path="/admin/faqseriouslist" element={<FaqSeriousList />} />
          <Route
            path="/admin/faqseriousdetail/:vfs_idx"
            element={<FaqSeriousDetail />}
          />
          <Route
            path="/admin/corporate_member_list"
            element={<CorporateMemberList />}
          />
          <Route
            path="/admin/corporate_member_info/:mcm_idx/:mem_idx/*"
            element={<CorporateMemberInfo />}
          />
          <Route
            path="/admin/sub_acount_detail/:user_idx"
            element={<SubAcountDetail />}
          />
          <Route path="/admin/headhunter_list" element={<HeadhunterList />} />
          <Route
            path="/admin/headhunter_free_info/:mcm_idx/:mem_idx/:mode/*"
            element={<HeadhunterFreeInfo />}
          />
          <Route
            path="/admin/headhunter_sub_dtail/:mcm_idx/:mem_idx/:mode/*"
            element={<HeadhunterSubDtail />}
          />
          <Route
            path="/admin/withdrawal_member_list"
            element={<WithdrawalMemberList />}
          />
          <Route
            path="/admin/withdrawal_member_detail/:mem_idx/:mode/*"
            element={<WithdrawalMemberDetail />}
          />
          <Route
            path="/admin/employee_list"
            element={<EmployeeList />}
          />
          <Route 
            path="/admin/employee_detail/:mem_idx/*" 
            element={<EmployeeDetail />}
          />
          <Route
            path="/admin/quit_member_list"
            element={<QuitMemberList />}
          />
          <Route 
            path="/admin/quit_member_detail/:mem_idx/*" 
            element={<QuitMemberDetail />}
          />
          <Route
            path="/admin/announcement_list"
            element={<AnnouncementList />}
          />
          <Route
            path="/admin/announcement_register"
            element={<AnnouncementRegister />}
          />
          <Route
            path="/admin/announcement_modify/:em_idx"
            element={<AnnouncementModify />}
          />
          <Route
            path="/admin/headhunter_dedicated_hall_list"
            element={<HeadhunterDedicatedHallList />}
          />
          <Route
            path="/admin/headhunter_dedicated_hall_modify/:em_idx"
            element={<HeadhunterDedicatedHallModify />}
          />

          <Route path="/admin/supportlist" element={<SupportList />} />
          <Route path="/admin/suggestionlist" element={<SuggestionList />} />
          <Route path="/admin/suggestioninfo/:user_idx/:em_idx" element={<SuggestionInfo />} />

          <Route path="/admin/faqlist" element={<FaqList />} />
          <Route path="/admin/faqdetail/:board_idx" element={<FaqDetail />} />
          <Route path="/admin/talklist" element={<TalkList />} />
          <Route path="/admin/talklistdetail/:board_idx" element={<TalkListDetail />} />
          <Route path="/admin/resumetiplist" element={<ResumeTipList />} />
          <Route path="/admin/resumetiplistdetail/:career_idx" element={<ResumeTipListDetail />} />
          <Route path="/admin/paymentlist" element={<PaymentList />} />
          <Route
            path="/admin/paymentdetail/:or_idx"
            element={<PaymentDetail />}
          />
          <Route path="/admin/refundlist" element={<RefundList />} />
          <Route
            path="/admin/refunddetail/:or_idx"
            element={<RefundDetail />}
          />
          <Route path="/admin/voucherlist" element={<VoucherList />} />
          <Route
            path="/admin/voucherdetail/:vo_idx"
            element={<VoucherDetail />}
          />
          <Route 
            path="/admin/dashboard/*"
            element={<DashBoard/>}
          />
          {/* admin */}
        </Routes>

        <Routes>

          {/* 이메일 연결페이지 */}
          <Route path="/Email" element={<Email />} />

          {/* 푸터 연결페이지들 */}
          <Route path="/Personal" element={<Personal />} />
          <Route path="/Agreement" element={<Agreement />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Guide" element={<Guide />} />
          <Route path="/GuideCompany" element={<GuideCompany />} />
          <Route path="/GuideHeadHunter" element={<GuideHeadHunter />} />
          

          {/* 이력서 */}
          <Route path="/MyResume/*" element={<MyResume />} />
          {/* 이직Talk */}
          <Route path="/MyTalk/*" element={<MyTalk />} />
          {/* 채용정보관 */}
          <Route path="/MyRecruit/*" element={<MyRecruit />} />
          {/* 커리어 */}
          <Route path="/MyCareer/*" element={<MyCareer />} />
          <Route path="/MyHeding/*" element={<MyHeding />} />
          <Route path="/html/User/Leave" element={<Leave />} />

          <Route path="/Pdf/Reputation/*" element={<Reputation />} />

          <Route path="/html/Message/Message" element={<PageMessage />} />

          {/* FAQ */}
          <Route path="/Faq/*" element={<FAQ />} />
          <Route path="/Company/Faq/*" element={<ComFaq />} />
          {/* 공지사항 */}
          <Route path="/Notice/*" element={<Notice />} />
          <Route path="/Company/Notice/*" element={<ComNotice />} />
          <Route path="/Notice/NoticeView/:board_idx" element={<NoticeView />} />
          <Route path="/Company/Notice/NoticeView/:board_idx" element={<ComNoticeView />} />

          <Route path="/" element={<AppMain />} />
          <Route path="/main" element={<Main />} />
          <Route path="/Company" element={<MainCompany />} />
          <Route path="/AppToken/:mem_idx/:Token" element={<AppToken />} />

          {/* web */}
          {/*회원가입 관련*/}

          <Route path="/Login/*" element={<LoginNormal />} />
          <Route path="/JoinUs/*" element={<JoinNormal />} />
          <Route path="/Member/*" element={<MemberNormal />} />

          {/* 공고/지원자관리(기업) */}

          {/* 기업 메뉴----------------- */}
          {/* 공고/지원자관리 */}
          {/*
          <Route path="/Company/ComAnnouncement/*" element={<ComAnnouncement />} />
          */}
          <Route path="/Company/ComAnnouncement/*" element={
              <RequireAuth chkLevel={1} chkDepth={3}>
                <ComAnnouncement  />
              </RequireAuth>
          } />

          {/* 인재DB(기업) */}
          {/*
          <Route path="/Company/ComResource/*" element={<ComResource />} /> 
          */}
           <Route path="/Company/ComResource/*" element={
              <RequireAuth chkLevel={1} chkDepth={3}>
                {/* <ComResource /> */}
                <ComResource2 />
              </RequireAuth>
          } />

          {/* 마이헤딩 */}
          <Route path="/Company/ComHeding/*" element={<ComHeding />} />
          {/* 이용권 */}
          {/* <Route path="/Company/ComVoucher/*" element={<ComVoucher />} /> */}
          <Route path="/Company/ComVoucher/*" element={
            <RequireAuth chkLevel={1} chkDepth={0}>
              <ComVoucher />
            </RequireAuth>
          } />

          {/* 메시지 */}
          {/* <Route path="/Company/ComMessage" element={<ComMessage />} /> */}
          <Route path="/Company/ComMessage" element={<RequireAuth chkLevel={1} chkDepth={0}><ComMessage /></RequireAuth>} />
          <Route path="/Company/ComMessage/:roomIdx" element={<ComMessage />} />
          {/* 채용공고 - 내용보기 */}
          <Route path="/Company/ComRecruit/*" element={<ComRecruit />} />
          <Route path="/Company/ComResume/*" element={<ComResume />} />

          <Route path="/html/Company/My" element={<MyCompany />} />
          <Route
            path="/html/Company/MyBookmark"
            element={<CompanyBookmark />}
          />

          {/**애플로그인 콜백페이지 */}
          <Route path="/auth/callback/apple" element={<AppleCallback />} />
          {/**구글로그인 콜백페이지 */}
          <Route path="/auth/callback/google" element={<GoogleCallback />} />
          {/* api test */}
          <Route path="/Tillko_Api" element={<TillkoApi />} />

          <Route path="/TossPaySuccess" element={<TossPaySuccess />} />
          <Route path="/TossPayFailed" element={<TossPayFailed />} />

          <Route path="/CareerCertificationInquiry" element={<Common_Inquiry />} />

          {/** 2022-11-22 이력서 Pdf 생성용 View. re_code 사용 */}
          <Route path="/PdfView/*" element={<PdfView />} />
          <Route path="/PdfView2/*" element={<PdfView2 />} />
          <Route path="/CareerUpdate" element={<Update />} />
        </Routes>
      </Router>
    </>
    // </>
  );
}

export default App;
