import React from "react";
import { Link } from "react-router-dom";
//css

//components
import { addThousandPoint } from "../Common/Common"
//이미지

/*이용권구매 목록*/
function VoucherList({ varVoucherList }) {

  return (
      <ul className="table table_list01">
        <li className="tr">
          <div className="th row_kind">이용권 종류</div>
          <div className="th row_date">이용기간</div>
          <div className="th row_send">제안 발송</div>
          <div className="th row_price">가격</div>
          <div className="th row_btn">&nbsp;</div>
        </li>
      {varVoucherList.map((voucher,index) => (
        <li className="tr" key={'voucher_'+index}>
          <div className="td row_kind">{voucher.vo_name}</div>
          <div className="td row_date"><span className="m_th">이용기간 :&nbsp;</span>{voucher.vo_terms+' 일'}</div>
          <div className="td row_send"><span className="m_th">제안 발송 :&nbsp;</span>{voucher.vo_sending+' 건'}</div>
          <div className="td row_price">
            <span className="price_align">
              <del>{addThousandPoint(voucher.vo_price)+" 원"}</del> → {addThousandPoint(voucher.vo_amount)+" 원"}
            </span>
            <strong>{addThousandPoint(voucher.vo_discount)+" %"} ↓</strong>
          </div>
          <div className="td row_btn">
            <Link to={"../VoucherPay"} state={{vo_idx:voucher.vo_idx}} className="btn_buy">구매하기 
              <span className="nanumGothic">&gt;</span>
            </Link>
          </div>
        </li>
      ))}
      </ul>
  );
}

export default VoucherList;
