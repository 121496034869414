import React, {useEffect, useState} from "react";
import axios from "axios";
import CONFIG from 'configs/configs'

//components
import Header from "./Header";
import Footer from "./Footer";
import BannerBottom from "components/Banner/BannerBottom";

function Personal() {
    const [varContent, setContent] = useState('');

    //개인정보 처리방침 읽어오기
    useEffect(() => {
        axios.post(`${CONFIG.SERVER_HOST}/_get_conditions`).then(res => {
            if (res.data.retvalue === 1) {
                setContent(res.data.results.privacy);
            }
        })
    }, [])    
    return(
        <>
            <Header/>
            <main className="center_wrap" >
              <h2 className="page_title">개인정보 취급방침</h2>
              <div dangerouslySetInnerHTML={{__html:varContent}} className="terms_area ql-editor"></div>
            </main>
            <BannerBottom/>
            <Footer/>
        </>
    )

}

export default Personal;