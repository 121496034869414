/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CONFIG from "configs/configs";
//이미지
//import ProfileImg from '../../img/sub/@offerer.jpg';
//import ProfileImg from 'img/sub/profile.jpg';

function ChatMsgItem({ myNickname, message, crs_status, yourImg }) {
    const { nickname, ownYN, type, content, user_idx, user_msg, corp_msg, imgURL, time } = message;

		// const ch =(text) =>{

		// 	let endIndex   = text.indexOf("님");
		// 	console.log(text.substring(endIndex));
		// }

		// ch(content);

	//메시지 형태에 따라 cotent 처리(일반, 공지, 이미지, 자동메시지 등)

	//const mem_mode = Number(localStorage.getItem('mem_mode')); //회원구분 0 : 일반회원 , 1: 기업회원 , 2: 

	/* 2022-11-01
	let youImgUrl = "";
	if(yourImg === undefined){
		youImgUrl = ProfileImg;
	}else{
		youImgUrl =  mem_mode===0 ?
		`${CONFIG.SERVER_HOST}/mcm_image/${yourImg}` :
		`${CONFIG.SERVER_HOST}/user_photo/${yourImg}`
		;
	}
	*/


	
	const returnHtml = (type, ownYN) =>{
		switch (type) {
			case "CHAT":	//일반 대화------------------------
				//if(ownYN){	//내꺼
				if(parseInt(nickname) === parseInt(myNickname)){	//내꺼
					return( 
						<div className="send">
							<div className="message">{content}</div>
							<div className="field_date">{time}</div>
						</div>
					);
				}else{	//상대방꺼
					return( 
						<div className="get">
							<div className="profile_img_area">
								<div className="profile_img"><img src={yourImg} alt="ProfileImg" /></div>
							</div>
							<div className="info_area">
                <div className="message">{content}</div>
                <div className="field_date">{time}</div>
							</div>
						</div>
					);
				}

			case "AUTO":	//자동 메시지 -------------------------
				const myContent = parseInt(user_idx) === parseInt(myNickname) ? user_msg : corp_msg;
				if(parseInt(nickname) === parseInt(myNickname)){	//내꺼
					return( 
						<div className="send">
							<div className="message">{myContent}</div>
							<div className="field_date">{time}</div>
						</div>
					);
				}else{	//상대방꺼
					return( 
						<div className="get">
							<div className="profile_img_area">
								<div className="profile_img"><img src={yourImg} alt="ProfileImg" /></div>
							</div>
							<div className="info_area">
                                <div className="message">{myContent}</div>
                                <div className="field_date">{time}</div>
							</div>
						</div>
					);
				}

			case "IMG":	//이미지 ---------------------------
				//if(ownYN){	//내꺼
				if(parseInt(nickname) === parseInt(myNickname)){	//내꺼
					return( 
						<div className="send">
                            <div className="img">
                                <a href={`${CONFIG.SERVER_HOST}/file_down?file_name=${imgURL}&file_url=chat`} download><img src={`${CONFIG.SERVER_HOST}/chat/${imgURL}`} alt="altImg" /></a>
                            </div>
							<div className="field_date">{time}</div>
						</div>
					);
				}else{	//상대방꺼
					return( 
						<div className="get">
							<div className="profile_img_area">
								<div className="profile_img"><img src={yourImg} alt="ProfileImg" /></div>
							</div>
							<div className="info_area">
								<div className="img">
								<a href={`${CONFIG.SERVER_HOST}/file_down?file_name=${imgURL}&file_url=chat`} download><img src={`${CONFIG.SERVER_HOST}/chat/${imgURL}`} alt="altImg" /></a>
                                </div>
								<div className="field_date">{time}</div>
							</div>
						</div>
					);
				}
				case "ERR":	//에러 ---------------------------
					return content;
			default:
				return;
		}
	}

  return (
		<div className="field_view">
			{returnHtml(type, ownYN)}
		</div>
	);
}

export default React.memo(ChatMsgItem);