import React, { useState, useEffect } from "react";
import axios from "axios";
import CONFIG from "configs/configs";

import { checkEmailString, changeMobileFormat, Certification } from '../Common/Common'
import PopEditPwd from 'components/My/PopEditPwd';

function ProfileInfoSub({ varCompanyInfo, setCompanyInfo, varAuth, setAuth, onChangeHandler }) {
  //이메일 설정
  const [varEmail1, setEmail1] = useState('');
  const [varEmail2, setEmail2] = useState('');
  const [selectEmailServer, setSelectEmailServer] = useState('');

  useEffect(() => {
    if (varCompanyInfo.mem_email !== "" && varCompanyInfo.mem_email !== null && varCompanyInfo.mem_email !== undefined) {
      const emailArray = varCompanyInfo.mem_email.split('@');
      setEmail1(emailArray[0]);
      setEmail2(emailArray[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCompanyInfo]);

  //이메일 중복검사
  const checkEmailHandler = () => {
    const mem_email = `${varEmail1}@${varEmail2}`;
    if (!checkEmailString(mem_email)) {
      alert("이메일 형식에 맞지않습니다.");
      return;
    }

    const params = new URLSearchParams();
    params.append("mem_email", mem_email);
    axios.post(`${CONFIG.SERVER_HOST}/checkEmail`, params).then((res) => {
      if (res.data.tf === true) {
        alert("사용가능한 이메일입니다.");
        setAuth({ ...varAuth, email: true });
        setCompanyInfo({ ...varCompanyInfo, mem_email: mem_email });
      } else {
        alert("다른 이메일을 입력해주세요.");
      }
    });
  };
  /*
  //닉네임 중복검사
  const checkNicknameHandler = () => {
    const params = new URLSearchParams();
    params.append("mem_nickname", varCompanyInfo?.mem_nickname);
    axios.post(`${CONFIG.SERVER_HOST}/checkNick`, params).then((res) => {
      console.log(res.data);
      if (res.data.tf === true) {
        alert("사용가능한 닉네임입니다.");
        setAuth({...varAuth,nickname: true});
      } else {
        alert("사용중인 닉네임 입니다. \n다른 닉네임을 입력해주세요.");
      }
    });
  };
  */

  //핸드폰 인증
  const onMobileCertification = (e) => {
    console.log('mobile');
    Certification(varCompanyInfo, setCompanyInfo, varAuth, setAuth, 1);
  }

  //이메일1
  const onChangeEmail1 = (email1) => {
    setEmail1(email1);
  };

  //이메일2
  const onChangeEmail2 = (email2) => {
    setEmail2(email2);
  }

  //메일서버선택
  const onChangeMailServerHandler = (emailServer) => {
    setSelectEmailServer(emailServer);
    setEmail2(emailServer);
  };

  useEffect(() => {
    setAuth((prev) => ({
      ...prev,
      email: prev.oldEmail === `${varEmail1}@${varEmail2}`
    }));
  }, [setAuth, varEmail1, varEmail2]);

  //팝업창
  const [varPopup, setPopup] = useState(0);

  //팝업창 번호
  const onSetPopup = (num) => {
    setPopup(num);
  }
  return (
    <>
      <section className="right_wrap">
        <h3 className="my_title">담당자정보</h3>
        <ul className="table table_view01">
          <li className="tr">
            <div className="th">아이디</div>
            <div className="td">
              <input type="text" className="read-only" value={localStorage.getItem("mem_id") || ''} readOnly />
            </div>
          </li>
          <li className="tr">
            <div className="th">비밀번호 변경</div>
            <div className="td">
              <button type="button" className="btn line" onClick={() => onSetPopup(2)}>비밀번호 변경</button>
            </div>
          </li>
          {/* <li className="tr">
                <div className="th">닉네임</div>
                <div className="td">
                <div className="input_wrap">
                <input name="mem_nickname" type="text" value={varCompanyInfo?.mem_nickname||''} onChange={(e) => onChangeHandler(e)} />
                    <button className="btn white hide" id="check_nickname" onClick={()=> checkNicknameHandler()}>중복확인</button>
                </div>
                </div>
            </li> */}
          <li className="tr">
            <div className="th">이름</div>
            <div className="td">
              <input name="mem_name" type="text" value={varCompanyInfo?.mem_name || ''} readOnly />
            </div>
          </li>
          <li className="tr">
            <div className="th">이메일</div>
            <div className="td">
              <div className="input_wrap email_field">
                <input type="text" name="email1" id="email1" value={varEmail1} onChange={(e) => onChangeEmail1(e.target.value)} />
                <span className="text">@</span>
                <input type="text" name="email2" id="email2" value={varEmail2} onChange={(e) => onChangeEmail2(e.target.value)} />
                <select name="" className="style_select" value={selectEmailServer} onChange={(e) => onChangeMailServerHandler(e.target.value)} >
                  <option value={""}>직접입력</option>
                  <option value={"naver.com"}>naver.com</option>
                  <option value={"gmail.com"}>gmail.com</option>
                  <option value={"hanmail.net"}>hanmail.net</option>
                  <option value={"hotmail.com"}>hotmail.com</option>
                  <option value={"icloud.com"}>icloud.com</option>
                </select>
                {varAuth.email === false &&
                  <button className="btn white" id="check_email" onClick={() => checkEmailHandler()}>중복확인</button>
                }
              </div>
            </div>
          </li>
          <li className="tr">
            <div className="th">휴대폰</div>
            <div className="td">
              <div className="input_wrap">
                <input name="mem_mobile" type="text" defaultValue={changeMobileFormat(varCompanyInfo?.mem_mobile)} readOnly />
                <button className="btn white" onClick={(e) => onMobileCertification(e)}>휴대폰 변경</button>
              </div>
            </div>
          </li>
          <li className="tr">
            <div className="th">부서</div>
            <div className="td">
              <div className="input_wrap">
                <input type="text" name="cmm_department" value={varCompanyInfo?.cmm_department === "null" ? "" : varCompanyInfo?.cmm_department} onChange={(e) => onChangeHandler(e)} />
              </div>
            </div>
          </li>
          <li className="tr">
            <div className="th">직책</div>
            <div className="td">
              <div className="input_wrap">
                <input type="text" name="cmm_position" value={varCompanyInfo?.cmm_position === "null" ? "" : varCompanyInfo?.cmm_position} onChange={(e) => onChangeHandler(e)} />
              </div>
            </div>
          </li>
          <li className="tr">
            <div className="th">푸시 알림</div>
            <div className="td">
              <div className="setting">
                <input type="checkbox" id="btnToggle1" name="push_YN" checked={varCompanyInfo?.push_YN === 'Y' ? true : false} onChange={(e) => onChangeHandler(e)} />
              </div>
            </div>
          </li>
        </ul>
      </section>
      <PopEditPwd varPopup={varPopup} onSetPopup={onSetPopup} setPopup={setPopup} />
    </>
  );
}

export default ProfileInfoSub;
