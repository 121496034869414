import { Link } from "react-router-dom";

function MyMenu(props) {
    
  return (
    <aside id="my_lnb">
      <p className="selected">{props.SelectedMy}</p>{/*클릭시 하단 div.Menu에 클래스 Active 추가*/}
      <ul className="menu">
        <li><a href="./My" className={props.Menu1}>정보 관리</a></li>
        <li><a href="./SubID" className={props.Menu2}>서브 계정 관리</a></li>
        <li><a href="./Recommend" className={props.Menu3}> 공고 현황</a></li>
        <li><a href="./Recommend" className={props.Menu3}>추천 공고 현황</a></li>
        <li><a href="./MyBookmark" className={props.Menu4}>관심 기업 관리</a></li>
        <li><a href="./MyVoucher" className={props.Menu5}>이용권 관리</a></li>
        <li><a href="/html/User/MyMoney" className={props.Menu6}>헤딩 머니</a></li>
        <li><a href="/html/User/MyNotice" className={props.Menu7}>알림내역</a></li>
        <li><Link to={"/MyHeding/MyHeding9"}>FAQ</Link></li>
      </ul>
    </aside>
  );
}

export default MyMenu;
