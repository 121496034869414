import React from "react";
import CareerC from 'components/Career/CareerC'
import { useParams } from "react-router-dom";
//css
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/Davehan.css';

const CareerCertification = () => {
  const { type, re_code } = useParams();

  return (
    <CareerC re_code={re_code} type={type} />
  )
}

export default CareerCertification
