/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import iconhome from '../../img/icon-home.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arr from '../../img/arr.png';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Clock from './Colck';
import moment from "moment";

function JobPostingList() {
  //const navigator = useNavigate();

  //getJobpostingCount
  const [jobpostingList, setJobpostingList] = useState([]);
  const [newCountTot, setnewCountTot] = useState(0);
  const [waitingTot, setwaitingTot] = useState(0);
  const [DateData, setDateData] = useState({
    startDate :'',
    endDate : ''
  });

  const [Counts, setCounts] = useState({
    today_new_notice : 0,
    wating_notice : 0
  });

  const timeout = () => {
    //현재 시간부터 자정까지 남은 시간 계산
    console.log(new Date())
    var a = moment().add(1,'days').hours('00').minutes('00').seconds('00');
    var b = moment();
    const time = a.diff(b) // 86400000

    console.log(a.diff(b));

    setTimeout(() => {
      reset();
    }, time);
  };

  useEffect(() => {
    timeout();
    return () => {
      // clear 해줌
      clearTimeout(timeout);
    };
  });

  useEffect(()=>{
    axios.post(`${CONFIG.SERVER_HOST}/getDashboardCount`, CONFIG.header).then((res) => {
      console.log(res);
      const { data } = res;
      setCounts({ ...Counts,
        today_new_notice : data.today_new_notice ,
        wating_notice : data.wating_notice  
      });
    });
  }, []);

  const getJobpostDate=(startDate, endDate)=>{
    const sendData = {
      'startDate': startDate,
      'endDate': endDate
    }
    axios.post(`${CONFIG.SERVER_HOST}/getJobpostingCount`, sendData, CONFIG.header).then((res) => {
      console.log(res);
      const { data } = res;
      setJobpostingList(data.jobposting_list);

      let newCnt_list = [];
      let WaitingCnt_list = [];

      for (var i = 0; i < data.jobposting_list.length; i++) { // 배열 arr의 모든 요소의 인덱스(index)를 출력함.
          if(data.jobposting_list[i]){
            newCnt_list.push(data.jobposting_list[i].newCnt);
            WaitingCnt_list.push(data.jobposting_list[i].WaitingCnt);
          }
      };

      setnewCountTot(newCnt_list.reduce(function add(sum, currValue) {
        return sum + currValue;
      }, 0));
      setwaitingTot(WaitingCnt_list.reduce(function add(sum, currValue) {
        return sum + currValue;
      }, 0));
    });

  }

  useEffect(()=>{
    getJobpostDate(DateData.startDate, DateData.endDate);
  }, []);

  const onChangeHandleler =(e)=>{
    const {name, value} = e.target;
    setDateData({ ...DateData, [name]: value });
  }
  const getSerch =()=>{
    
    if(!DateData.startDate){
      alert('시작 날짜를 선택해 주세요');
      return false;
    }else if(!DateData.endDate){
      alert('종료 날짜를 선택해 주세요');
      return false;
    }

    getJobpostDate(DateData.startDate, DateData.endDate);
  }

  const reset =() =>{
    setDateData({
      startDate : '',
      endDate : ''
    });
    getJobpostDate('', '');
  }



  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="dashboard" />
      <Menu menuType="dashboard" menu="job_posting_statistics" />
      <div id="contents" className="ad_contents">
        <div className="tit">채용공고 통계
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arr} alt="" />Dash board<img src={arr} alt="" />채용공고 통계
          </div>
        </div>
        <div className="content">
        <div className="date_sec">
            <span>Today :</span><span className="date"><Clock /></span>
          </div>
          <div className="main-box">
            <table className="list">
              <colgroup><col/></colgroup>
              <thead>
                <tr>
                  <th>오늘 신규 등록한 공고</th>
                  <th>대기중 공고</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{Counts.today_new_notice}</td>
                  <td>{Counts.wating_notice}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box">
            <div className="search-box">
              <div className="day-box">
                <p>검색기간</p>
                <input type="date" name="startDate" id="datePicker" value={DateData.startDate} onChange={onChangeHandleler}/>~<input type="date" name="endDate" id="datePicker2" value={DateData.endDate} onChange={onChangeHandleler}/>
              </div>
              <div className="f-right">
                <button className="btn" onClick={()=>getSerch()}>검색</button>
                <button className="btn black" onClick={()=> reset()}>초기화</button>
              </div>
            </div>
          </div>
          
          <div className="main-box">
          <table className="list">
              <colgroup><col width="15%"/><col/><col/></colgroup>
              <thead>
                <tr>
                  <th>일자</th>
                  <th>신규 등록 공고</th>
                  <th>대기중 공고</th>
                </tr>
              </thead>
            </table>
            <div className="scrollbox">
            <table className="list">
              <colgroup><col width="15%"/><col/><col/></colgroup>
              <tbody >
                {
                jobpostingList &&
                jobpostingList.map((el)=>
                  <tr>
                  <td>{el.date}</td>
                  <td>{el.newCnt}</td>
                  <td>{el.WaitingCnt}</td>
                </tr>
                )
                }
              </tbody>
            </table>
            </div>
            <table className="list">
            <colgroup><col width="15%"/><col/><col/></colgroup>
              <tfoot className="total">
                <tr>
                  <td>합계</td>
                  <td>{newCountTot}</td>
                  <td>{waitingTot}</td>
                </tr>
              </tfoot>
            </table>
            
          </div>
        </div>
      </div>


    </React.Fragment>
  )

}

export default JobPostingList;