import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import LoginNormalC from "../../components/Member/LoginNormalC";
import LoginCompanyC from "../../components/Member/LoginCompanyC";
import LoginHeadHunterC from "../../components/Member/LoginHeadHunterC";
//css
import "css/Common.css";
import "css/Login.css";
import BannerBottom from "components/Banner/BannerBottom";

const LoginNormal = () => {
  const location = useLocation();
  const pathName = location.pathname.split('/');
  const pageMode = pathName[2] === undefined ? "User" : pathName[2];
  const [varType, setType] = useState("");

  //페이지가 변경되면 - 탭을 위해
  useEffect(() => {
    setType(pageMode);
  }, [pageMode]);

  return (
    <>
      <Header />
      <main id="sub_wrap" className="center_wrap_member page_login">
        <h2 className="sub_content_title">로그인</h2>
        <div className="tab01_wrap">
          <ul className="tab01">
            <li className={varType === "User" || varType === null || varType === "" ? "active" : ""}>
              <Link to={`/Login`}>일반회원</Link>
            </li>
            <li className={varType === "Company" ? "active" : ""}>
              <Link to={`/Login/Company`}>기업회원</Link>
            </li>
            <li className={varType === "HeadHunter" ? "active" : ""}>
              <Link to={`/Login/HeadHunter`}>헤드헌터 회원</Link>
            </li>
          </ul>

          <Routes>
            <Route path="/" element={<LoginNormalC />} />
            <Route path="User" element={<LoginNormalC />} />
            <Route path="Company" element={<LoginCompanyC />} />
            <Route path="HeadHunter" element={<LoginHeadHunterC />} />
          </Routes>
        </div>
      </main>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default LoginNormal;
