/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/*global kakao*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { unescapeHtml, changeTextArea } from "../Common/Common";

const RecruitViewPage = ({ varEmployInfo }) => {

  //지도 만들기
  const setLocation = () => {
    var getLocationValue = false;
    var slocationX = 33.450701;
    var slocationY = 126.570667;

    // 2022-11-15 공고 공개(등) -> 비공개, 비공개 -> 공개(등) 수정 시 지도 마크 위치 변경      ===> 오류 발생시 주석 해제
    // if (
    //   varEmployInfo.em_location !== undefined &&
    //   varEmployInfo.em_location !== null &&
    //   varEmployInfo.em_location !== ""
    // ) {
    //   console.log(varEmployInfo.em_location);
    //   const slocations = varEmployInfo.em_location.split(",");
    //   slocationX = Number(slocations[0]);
    //   slocationY = Number(slocations[1]);
    //   getLocationValue = true;
    // } else if (
    //   varEmployInfo.mcm_location !== undefined &&
    //   varEmployInfo.mcm_location !== null &&
    //   varEmployInfo.mcm_location !== ""
    // ) {
    //   console.log(varEmployInfo.mcm_location);
    //   const slocations = varEmployInfo.mcm_location.split(",");
    //   slocationX = Number(slocations[0]);
    //   slocationY = Number(slocations[1]);
    //   console.log(slocationX, slocationY);
    //   getLocationValue = true;
    //   //회사좌표저장
    //   updateLocationCompany(slocationY, slocationX);
    // }


    //카카오 맵 스크립트 동적 호출 시작
    const script = document.createElement("script");
    script.src = CONFIG.kakaomapUrl;
    document.head.appendChild(script);

    script.onload = () => {
      kakao.maps.load(() => {
        // var mapContainer = document.getElementById("map"); // 지도를 표시할 div
        var container = document.getElementById("map");
        var options = {
          center: new kakao.maps.LatLng(slocationX, slocationY),
          level: 3,
        };
        var map = new kakao.maps.Map(container, options);
        // 지도를 생성합니다
        // var map = new kakao.maps.Map(mapContainer, mapOption);
        var zoomControl = new kakao.maps.ZoomControl();
        map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

        if (!getLocationValue) {
          var geocoder = new kakao.maps.services.Geocoder();
          var em_address = varEmployInfo.em_address1;
          if (varEmployInfo.em_type === 2) {
            em_address = varEmployInfo.mcm_address1;
          }

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(em_address, function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              console.log(coords);
              // 결과값으로 받은 위치를 마커로 표시합니다
              var marker = new kakao.maps.Marker({
                map: map,
                position: coords,
              });

              // 인포윈도우로 장소에 대한 설명을 표시합니다
              var infowindow = new kakao.maps.InfoWindow({
                content: `<div style="width:150px;text-align:center;padding:6px 0;">${varEmployInfo.em_type === 2 ? varEmployInfo.mcm_name : varEmployInfo.com_name}</div>`,
              });
              infowindow.open(map, marker);
              console.log(result[0].x, result[0].y);
              // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
              map.setCenter(coords);

              //회사좌표저장
              const params = new URLSearchParams();
              params.append("mcm_idx", varEmployInfo.mcm_idx);
              params.append("em_idx", varEmployInfo.em_idx);
              params.append("xpos", result[0].x);
              params.append("ypos", result[0].y);

              axios
                .post(`${CONFIG.SERVER_HOST}/_update_location`, params)
                .then((res) => {
                  console.log(res.data.message);
                });
            }
          });
        } else {
          var coords2 = new kakao.maps.LatLng(slocationX, slocationY);
          console.log(coords2);
          var marker2 = new kakao.maps.Marker({
            map: map,
            position: coords2,
          });
          // 비공개 채용일 경우 헤드헌터 회사명으로
          var infowindow = new kakao.maps.InfoWindow({
            content: `<div style="width:150px;text-align:center;padding:6px 0;">${varEmployInfo.em_type === 2 ? varEmployInfo.mcm_name : varEmployInfo.com_name}</div>`,
          });
          infowindow.open(map, marker2);
        }
      });
    };
    // const kakao = window["kakao"];
    // var mapContainer = document.getElementById("map"); // 지도를 표시할 div
    // var mapOption = {
    //   center: new kakao.maps.LatLng(slocationX, slocationY), // 지도의 중심좌표
    //   level: 3, // 지도의 확대 레벨
    // };

    // // 지도를 생성합니다
    // var map = new kakao.maps.Map(mapContainer, mapOption);
    // var zoomControl = new kakao.maps.ZoomControl();
    // map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    // if (!getLocationValue) {
    //   var geocoder = new kakao.maps.services.Geocoder();
    //   var em_address = varEmployInfo.em_address1;

    //   // 주소로 좌표를 검색합니다
    //   geocoder.addressSearch(em_address, function (result, status) {
    //     // 정상적으로 검색이 완료됐으면
    //     if (status === kakao.maps.services.Status.OK) {
    //       var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
    //       console.log(coords);
    //       // 결과값으로 받은 위치를 마커로 표시합니다
    //       var marker = new kakao.maps.Marker({
    //         map: map,
    //         position: coords,
    //       });

    //       // 인포윈도우로 장소에 대한 설명을 표시합니다
    //       var infowindow = new kakao.maps.InfoWindow({
    //         content: `<div style="width:150px;text-align:center;padding:6px 0;">${varEmployInfo.mcm_name}</div>`,
    //       });
    //       infowindow.open(map, marker);
    //       console.log(result[0].x, result[0].y);
    //       // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
    //       map.setCenter(coords);

    //       //회사좌표저장
    //       const params = new URLSearchParams();
    //       params.append("mcm_idx", varEmployInfo.mcm_idx);
    //       params.append("em_idx", varEmployInfo.em_idx);
    //       params.append("xpos", result[0].x);
    //       params.append("ypos", result[0].y);

    //       axios.post(`${CONFIG.SERVER_HOST}/_update_location`, params).then((res) => {
    //         console.log(res.data.message);
    //       });
    //     }
    //   });
    // } else {
    //   var coords2 = new kakao.maps.LatLng(slocationX, slocationY);
    //   console.log(coords2);
    //   var marker2 = new kakao.maps.Marker({
    //     map: map,
    //     position: coords2,
    //   });
    //   var infowindow = new kakao.maps.InfoWindow({
    //     content: `<div style="width:150px;text-align:center;padding:6px 0;">${varEmployInfo.mcm_name}</div>`,
    //   });
    //   infowindow.open(map, marker2);
    // }
  };

  // useEffect(() => {
  //   if(varCurrentPage === 5) {
  //     setLocation();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [varCurrentPage]);

  //전달된 정보 읽기
  useEffect(() => {
    if (varEmployInfo.em_location !== undefined) {
      setLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varEmployInfo]);

  //서버로부터 정보가 넘어오기전이면
  if (varEmployInfo === undefined || varEmployInfo.length === 0) {
    return null;
  }

  //위치정보 저장
  const updateLocationCompany = (xpos, ypos) => {
    const params = new URLSearchParams();
    params.append("mcm_idx", varEmployInfo.mcm_idx);
    params.append("em_idx", varEmployInfo.em_idx);
    params.append("xpos", xpos);
    params.append("ypos", ypos);
    console.log(varEmployInfo.em_idx, xpos, ypos);
    axios.post(`${CONFIG.SERVER_HOST}/_update_location`, params).then((res) => {
      console.log(res.data.message);
    });
  }
  console.log(varEmployInfo);

  return varEmployInfo === undefined ||
    varEmployInfo === null ||
    varEmployInfo.length === 0 ? null : (
    <div className="tab01_wrap">
      <ul className="tab01_contents">
        <li
          className="recruitview01"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <h2>{varEmployInfo.em_intro === "" ? "" : "회사 소개"}</h2>
          <ul>
            <li>{unescapeHtml(varEmployInfo.em_intro)}</li>
          </ul>

        </li>
        <li
          className="recruitview03"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <h2>{varEmployInfo.em_main_task === "" ? "" : "주요 업무"}</h2>
          <ul>
            <li>{unescapeHtml(varEmployInfo.em_main_task)}</li>
          </ul>
        </li>
        <li
          className="recruitview04"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <h2>{varEmployInfo.em_qualifications === "" ? "" : "자격 요건"}</h2>
          <ul>
            <li>{unescapeHtml(varEmployInfo.em_qualifications)}</li>
          </ul>
        </li>
        <li
          className="recruitview05"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <h2>{varEmployInfo.em_preference === "" ? "" : "우대 사항"}</h2>
          <ul>
            <li>{unescapeHtml(varEmployInfo.em_preference)}</li>
          </ul>
        </li>
        <li
          className="recruitview06"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <h2>{varEmployInfo.em_welfare === "" ? "" : "혜택 및 복지"}</h2>
          <ul>
            <li>{unescapeHtml(varEmployInfo.em_welfare)}</li>
          </ul>
        </li>
        {varEmployInfo.em_others === "" ? null :
          <li
            className="recruitview07"
            style={{ whiteSpace: "pre-wrap" }}
          >
            <h2>{varEmployInfo.em_others === "" ? "" : "기타 안내사항"}</h2>
            <ul>
              <li> {unescapeHtml(varEmployInfo.em_others)}</li>
            </ul>
          </li>
        }
        <li className="recruitview08" style={{ whiteSpace: "pre-wrap" }}>
          <h2>{varEmployInfo.em_location === undefined ? "" : "회사 위치"}</h2>
          <div id="map" style={{ width: "100%", height: "400px" }}></div>
          <h1 className="location_text">
            {/* 비공개 채용일 경우 헤드헌터 회사 주소로 */}
            {varEmployInfo.em_type === 2 ? (varEmployInfo.mcm_address1 + " " + varEmployInfo.mcm_address2) : (varEmployInfo.em_address1 + " " + varEmployInfo.em_address2)}
          </h1>
        </li>
      </ul>
    </div>
  );
};

export default RecruitViewPage;
