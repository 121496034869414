/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from 'configs/configs';
//css

//components
import CompanySuggestionSearch from './CompanySuggestionSearch';
import Paging from '../Layout/Paging'
import { ChangeDateString, mobileWrapSearchTalent, resetCareerForSearching } from '../Common/Common';
import CertificationCareerView from "../Resources/CertificationCareer";
import AddCareerView from "../Resources/AddCareer";

function CompanyRecommendList({ varRecommendList, setRecommendList }) {
  let navigate = useNavigate();
  //const user_idx = localStorage.getItem('mem_idx');
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx');
  const mem_sub = localStorage.getItem('mem_sub');
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  //검색 변수값 저장 객체
  const [varSearchData, setSearchData] = useState({
    recommender: 0,
    sdate: null,
    edate: null,
    searchoption: "all",
    searchstring: ""
  });

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varChatRoom, setChatRoom] = useState(
    {
      user_mem_idx: 0,
      resume_idx: 0
    }
  );

  //필터링 & 페이징
  const [varPageRecommendList, setPageRecommendList] = useState([]);

  //추천관련 정보 읽기
  function readRecommendList() {
    const params = new URLSearchParams();
    params.append("mcm_idx", mcm_idx);
    params.append("mem_idx", mem_idx);
    params.append("mem_sub", mem_sub);
    params.append("pageSize", varPageSize);
    params.append("pageNum", varPageNum);
    params.append("sdate", varSearchData.sdate === null || varSearchData.sdate === "" ? '' : varSearchData.sdate);
    params.append("edate", varSearchData.edate === null || varSearchData.edate === "" ? '' : varSearchData.edate);
    params.append("searchoption", varSearchData.searchoption);
    params.append("searchstring", varSearchData.searchstring);
    axios.post(`${CONFIG.SERVER_HOST}/_get_company_suggestion_list`, params).then((res) => {
      console.log(res.data)
      if (res.data.retvalue === 1) {
        //전체 검색수
        setTotalCount(res.data.count);
        //검색된 표시할 정보
        //2023-04-22 수락/거절 일시(crs_up_date) 추가 - KSR
        const data = res.data.results.map((data, i) => (
          {
            index: data.crs_idx,
            user_mem_idx: data.mem_idx,
            re_idx: data.crs_re_idx,
            name: data.mem_name,
            title: data.crs_em_title === null || data.crs_em_title === "" ? data.em_title : data.crs_em_title,
            career: resetCareerForSearching(data.re_career_year !== null ? data.re_career_year : 0, data.re_careeradd_year !== null ? data.re_careeradd_year : 0),
            photo: data.re_photo,
            ufile: data.re_ufile,
            company: data.rec_company === null ? '' : data.rec_company,
            departPos: (data.rec_department === null ? '' : data.rec_department) + ' ' + (data.rec_position === null ? '' : data.rec_position),
            school: `${data?.reh_name === null ? '' : data.reh_name}  ${data?.reh_major === null ? '' : data.reh_major}`,
            state: data?.reh_state === 1 ? "졸업" : data?.reh_state === 2 ? "졸업예정" : data?.reh_state === 3 ? "재학중" : data?.reh_state === 4 ? "중퇴" : data?.reh_state === 5 ? "수료중" : data?.reh_state === 6 ? "휴학" : "",
            rdate: ChangeDateString(data.crs_rdate.substr(0, 10)),
            status: data.crs_status === 0 ? "제안거절" : data.crs_status === 1 ? "제안발송" : "제안수락",
            className: data.crs_status === 0 ? "txt_red" : data.crs_status === 2 ? "txt_blue" : "",
            tasks: data.crs_em_tasks === null || data.crs_em_tasks === "" ? data.em_tasks_info : data.crs_em_tasks,
            comment: data.crs_comment,
            mem_ufile: data.mem_ufile,
            mem_photo: data.mem_photo,
            certification_experience_year: data.certification_experience_year,
            certification_experience_date: data.certification_experience_date,
            additional_career_year: data.additional_career_year,
            additional_career_date: data.additional_career_date,
            crs_status : data?.crs_status,
            crs_up_date : data.crs_up_date ? ChangeDateString(data.crs_up_date.substr(0, 10)) : "-"
          }
        ));
        setPageRecommendList(data);
      }
    });
  }
  useEffect(() => {
    readRecommendList();
    mobileWrapSearchTalent(); //페이지 조정
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    readRecommendList();
  }, [varPageNum]);


  //필터링- 검색버튼 클릭시
  const filteringRecommendData = () => {
    setRecommendList([]); //원본 - 전체
    readRecommendList();
  }

  //POPUP 창
  const [varPopup, setPopup] = useState(0);
  const [varPopDatas, setPopDatas] = useState({});
  //팝업창 열기
  const onOpenPopup = (e, inum, user_mem_idx, resume_idx) => {
    //e.preventDefault();
    const copyData = varPageRecommendList.filter(element => element.index === inum);
    console.log(copyData);
    setPopDatas({ ...copyData[0] });
    setPopup(1);
    //챗방 관련 정보
    setChatRoom({ user_mem_idx: Number(user_mem_idx), resume_idx: Number(resume_idx) });
  }
  //팝업창 닫기
  const onClosePopup = (e) => {
    //e.preventDefault();
    setPopup(0);
  }

  //1:1 채팅
  const onCallChating = async (e) => {
    try {
      const params = new URLSearchParams();
      params.append('mem_idx', varChatRoom.user_mem_idx); //메세지를 받을 상대방의 idx
      params.append('corp_mem_idx', localStorage.getItem('mem_idx')); //보내는 회원의 idx
      params.append('send_idx', localStorage.getItem('mem_idx')); //메시지 보내는 회원의 idx
      params.append('re_idx', varChatRoom.resume_idx);
      params.append('com_idx', localStorage.getItem('com_idx')); //기업회원의 회사명

      //채팅방 개설 api
      await axios.post(`${CONFIG.SERVER_HOST}/set_chatroom`, params)
        .then((res) => {
          // console.log(res);
          if (res.data.retvalue === 1) {
            navigate(`/Company/ComMessage/${res.data.room_no}`, {
              state: { roomIdx: res.data.room_no, proposeIdx: res.data.propose_idx, chat_idx: 0 }
            });
            //alert(res.data.message);
          } else {
            alert(res.data.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  console.log(varPageRecommendList)


  //2023-04-22 수락/거절 일자(crs_up_date) 추가 - KSR
  return (
    <>
      <CompanySuggestionSearch varSearchData={varSearchData} setSearchData={setSearchData} readRecommendList={readRecommendList} />
      <div className="support_list_area">
        <table className="table_list01">
          <colgroup><col width=""/><col width="15%"/><col width="20%"/><col width="15%"/><col width="15%"/></colgroup>
          <thead>
            <tr>
              <th>채용 제목</th>
              <th>후보자</th>
              <th>직무</th>
              <th>제안일자</th>
              <th>수락/거절 일자</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
          {varTotalCount > 0 ? varPageRecommendList?.map((suggest, i) => (
            <tr key={'suggest?_' + i} >
              <td className="td_title with_btn ellipsis">
                <b> {suggest?.title}</b>
              </td>
              <td className="td_info txt_blue ">
                <Link className="td_info txt_blue" to={`/Company/ComResource/SearchView/${suggest?.re_idx}`}>{suggest?.name}</Link>
              </td>
              <td className="td_info ">
                {suggest?.tasks}
              </td>
              <td className="td_info last">{suggest.rdate}</td>
              <td className="td_info last">{suggest.crs_up_date}</td>
              {suggest.crs_status === 2 ? 
                <td className={"row_state td_button " + suggest?.className} style={{ cursor: "pointer" }}>
                  <Link className="td_info txt_blue" to={`/Company/ComResource/SearchView/${suggest?.re_idx}`}>{suggest?.status}</Link>
                </td>
              :
                <td className={"row_state td_button " + suggest?.className} onClick={(e) => onOpenPopup(e, suggest?.index, suggest?.user_mem_idx, suggest?.re_idx)} style={{ cursor: "pointer" }}>
                  <span >{suggest?.status}</span>
                </td>
              }
            </tr>
          ))
            :
            <tr>
              <td colSpan={5} className="no_data">제안한 내역이 없습니다.</td>
            </tr>
          }
          </tbody>
        </table>
      </div>
      <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />

      {/** 목록에서 '상태' 클릭 시 - POP */}
      <div className={varPopup === 1 ? "popup active" : "popup"}>
        <div className="popup_wrap popup01">
          <section className="title_wrap">
            <h1>제안 내용</h1>
          </section>
          <section className="content_wrap">
            <ul className="view_area">
              <li className="profile_area">
                {varPopDatas?.mem_photo === 0 ? <div className="profile_img"></div> :
                  <div className="profile_img" style={{ backgroundImage: `url(${imageFoler + varPopDatas?.mem_ufile})` }}></div>
                }
                <article className="profile_info">
                  <p className="id">{varPopDatas?.name}</p>
                </article>
              </li>
              <li>
                <p className="title_field">채용제목</p>
                <p className="info_field">{varPopDatas?.title}</p>
              </li>
              <li>
                <p className="title_field">직무</p>
                <p className="info_field">{varPopDatas?.tasks}</p>
              </li>
              <li>
                <p className="title_field">상세내용</p>
                <p className="info_field">{varPopDatas?.comment}</p>
              </li>
              <li>
                <p className="title_field">상태</p>
                <p className="info_field ">{varPopDatas?.status}</p>
              </li>
            </ul>
          </section>
          <section className="button_wrap">
            {varPopDatas?.crs_status === 2 ?
              <div className="big_button_wrap one">
                <button className="btn" onClick={(e) => onCallChating(e)}>1:1 메시지 보내기</button>
              </div>
              : ''

            }

            <div className="close_button_wrap">
              <button onClick={(e) => onClosePopup(e)}>닫기 X</button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CompanyRecommendList;
