/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/*****************************************************************************
* 이용약관 컴포넌트 작성자 - KGY
* 작성일 2022-08-20
*****************************************************************************/
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CONFIG from '../../../configs/configs'
import { TalkWriteEditor } from "html/Talk/TalkWriteEditor";

function TePush(termType) { 

  const [varContent, setContent] = useState(''); 

  //이용약관 읽어오기
  useEffect(() => { 
    const params = new URLSearchParams();
    params.append("term_type", termType.termType);
    axios.post(`${CONFIG.SERVER_HOST}/_get_conditions_one/`, params).then(res => { 
      if(res.data.retvalue){
        setContent(res.data.results)
      }
    })
  }, []);
  //이용약관 입력
  const onContentHandler = ({value}) => {
    setContent(value);
  }

  //이용약관 저장
  const onSubmitHandler = async e => {
    e.preventDefault();
    if (varContent !== '') { 
      const params = new URLSearchParams();
      params.append("con_term",varContent);
      params.append("con_term_type",termType.termType);
      
      await axios.post(`${CONFIG.SERVER_HOST}/update_term`, params).then(res => {
          alert(res.data.message);
        })
    } else {
      alert('내용을 먼저 입력해주세요.')
    }
  } 

  return( 
    <>
      {/* <div className="main-box"> */}
        <div className="write-list">
          <div className="input_field">
            {/* <textarea value={varContent} onChange={(e)=>onContentHandler(e)} /> */}
            <TalkWriteEditor defaultValue={varContent || ''} handleOnChange={(text)=>onContentHandler({value: text})}/>
          </div> 
        </div>
      {/* </div> */}
      <div className="main-box2">
        <div className="btn-center"><button className="btn" onClick={(e)=>onSubmitHandler(e)}>저장</button></div>
      </div> 
    </>
  );
}
export default TePush