import React from "react";
import { Routes, Route } from "react-router-dom";

import CareerTop from 'components/Career/CareerTop'

import CareerCertification from '../Career/CareerCertification';

import AcquaintanceReputation from '../Career/AcquaintanceReputation';//지인평판 메인(내역 보기)
import AcquaintanceReputationFriend from '../Career/AcquaintanceReputationFriend'; //지인평판 메인(알림톡으로 접근한 경우)
import AcquaintanceReputationW from '../Career/AcquaintanceReputationW';//지인평판 작성

import CareerReputation from '../Career/CareerReputation';
//import CareerReputationPdf from '../Career/CareerReputationPdf';//동료평판 > PDF

//2023-02-18 ColleRepuW -> ColleRepuW2
//import ColleRepuW from "components/Career/ColleRepuW";
import ColleRepuW2 from "components/Career/ColleRepuW2";

import CareerIncomeView from '../Career/CareerIncomeView';
import Layout from '../Layout/Layout';

const MyCareer = () => {
	return (
		<>
			<Layout className='mycareer'>
				{/* <SubVisual PageTitle="커리어"  SubVisual_className={"type3"}/> */}
				<section id="page_mycareer">
					<CareerTop />
					<Routes>
						{/* 경력증명서 */}
						<Route path="/:type/:re_code" element={<CareerCertification />} />
						<Route path="/CareerCertification" element={<CareerCertification />} />
						<Route path="/CareerCertification/:re_code" element={<CareerCertification />} />

						{/* 지인 평판 */}
						<Route path="/acquaintance-reputation" element={<AcquaintanceReputation />} />
						<Route path="/acquaintance-reputation/friends" element={<AcquaintanceReputationFriend />} />
						<Route path="/acquaintance-reputation/submit" element={<AcquaintanceReputationW />} />
						<Route path="/acquaintance-reputation/submit/:repu_code" element={<AcquaintanceReputationW />} />

						{/* 동료 평판 */}
						<Route path="/career-reputation" element={<CareerReputation />} />
						<Route path="/career-reputation/:repuCode" element={<CareerReputation />} />
						<Route path="/career-reputation/submit" element={<ColleRepuW2 />} />

						{/* 소득 */}
						<Route path="/CareerIncome" element={<CareerIncomeView />} />
					</Routes>
				</section>
			</Layout>

		</>
	)
}

export default MyCareer
