/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../configs/configs";
import axios from "axios";

//component
import MyCareerHistory from "components/Member/MyCareerHistory";
import { resetCareerFromNumbers } from "../Common/Common";

import PopCertify from "components/Member/PopCertify";

const Common_Inquiry = () => {
  const navigate = useNavigate();
  const user_idx = localStorage.getItem('mem_idx');
  const resume_idx = 0;
  //팝업창 - 열기/닫기 변수
  const [experienceOpen, setExperienceOpen] = useState(false);
  const [careerOpen, setCareerOpen] = useState(false);
  const [popupType, setPopupType] = useState({
    nhis: false,
    hometax: false
  });

  //팝업창 열기/닫기 함수 --- 인증경력 불러오기 
  const onSelectExperience = (bmode) => {
    //e.preventDefault();
    setExperienceOpen(bmode);
  };
  //팝업창 열기/닫기 함수 --- 인증경력 불러오기 
  const onSelectCareer = (bmode) => {
    //e.preventDefault();
    setCareerOpen(bmode);
  };

  //팝업창 --- 인증경력 불러오기 
  const [totalWorkTerms, setTotalWorkTerms] = useState('');   //총경력
  const [myCareerList, setMyCareerList] = useState([]);       //경력배열
  const [saeobjaMyeongching, setSaeobjaMyeongching] = useState('');
  const [popAccessRestrictions, setPopAccessRestrictions] = useState("popup"); //열람 제한 기업 팝업

  //경력조합체
  const newCareerData = {
    rec_idx: 1,
    rec_company: '',
    rec_department: '',
    rec_position: '',
    rec_sdate: null,
    rec_edate: null,
    rec_state: 0,
    rec_certification: 1,
    rec_descript: [{ 'note': null, 'detail': null }, { 'note': null, 'detail': null }, { 'note': null, 'detail': null }, { 'note': null, 'detail': null }, { 'note': null, 'detail': null }]
  };

  //경력 읽어오기
  const setupCareerDataList = (array) => {
    var careerList = []; //경력저장배열 초기화
    let sumWorkDays = 0;  //총경력기간
    for (var i = 0; i < array.length; i++) {
      let copyData = { ...newCareerData };
      copyData.rec_idx = (i + 1);                            //일렬번호
      copyData.rec_company = array[i].SaeobjaMyeongching;     //회사명
      copyData.rec_sdate = array[i].Chwideugil.substr(0, 10);  //취업일자

      var sdt = new Date(array[i].Chwideugil.substr(0, 10));
      var edt = array[i].Sangsilil !== null ? new Date(array[i].Sangsilil.substr(0, 10)) : new Date(); //퇴사일자
      var dateDiff = Math.ceil((edt.getTime() - sdt.getTime()) / (1000 * 3600 * 24)); //근무일자
      sumWorkDays = sumWorkDays + dateDiff; //총근무일자

      if (array[i].Sangsilil !== null) {  //퇴사일자가 존재하면 -> 퇴사
        copyData.rec_edate = array[i].Sangsilil.substr(0, 10);
        copyData.rec_state = 0; //근무상태 : 퇴사
      } else {
        copyData.rec_state = 1; //퇴사일자가 없으면 -> 근무상태 : 근무중
      }
      careerList = careerList.concat(copyData); //경력저장배열에 추가
    }

    var numYear = 0;
    var numMonth = 0;
    var fterm = 0;
    if (sumWorkDays > 0) {
      numYear = Math.floor(sumWorkDays / 365);
      if (numYear > 0) {
        sumWorkDays = sumWorkDays - numYear * 365;
      }
      numMonth = Math.round(sumWorkDays / 30);
      fterm = Number(numYear.toString() + '.' + numMonth.toString());
    }

    //총경력
    var workTerms = resetCareerFromNumbers(fterm);
    setTotalWorkTerms(workTerms);

    //경력 배열 저장
    setMyCareerList(careerList);

    //경력 유무
    if (array.length === 0) {  //표시할 인증경력 내용이 없으면
      onSelectCareer(false);  //이력서 표시 팝업창 닫기
    }
    onSelectCareer(true);       //이력서 표시 팝업창 열기
  }
  //인증경력을 서버에 저장
  const onGetCareerCertification = async (e) => {
    const sendData = {
      'mec_idx': user_idx,
      'careers': myCareerList,
    }

    await axios.post(`${CONFIG.SERVER_HOST}/update_my_careers`, sendData).then((res) => {
      if (res.data.retvalue === 1) {

        // original code
        if(saeobjaMyeongching !== ''){

          console.log('0000000000000000000000');
          setCareerOpen(false);
          navigate(`/MyCareer/web/0`);
        }else{
          console.log('1111111111111111111111111111');
          setCareerOpen(false);
          alert(res.data.message)
          navigate(`/MyCareer/web/0`);
        }

       
      }
    });
  }

  const success = () => {
    navigate(`/MyResume/ResumeView/${resume_idx}`);
  }

  //setCareerOpen
  useEffect(() => {
    if (careerOpen === false && saeobjaMyeongching !== '') {
      setPopAccessRestrictions('popup active');
    }
  }, [saeobjaMyeongching, careerOpen]);

  const closePopup =() =>{
    setExperienceOpen(!experienceOpen);
  }
  return (
    <>
      {/* <article className="notice_area">
        <img src={OKIcon} alt="" />
        <p className="title_field"><span className="point">일반 회원</span> 가입을 완료했습니다.</p>
        <p className="text_field">Click 한번으로 쉽게 신뢰도 있는 이력서를 완성해 보세요 <br />간편인증으로 모든 경력이 자동 입력됩니다.</p>
      </article> */}
      <article className="bottom_btn_area">
        <button className="btn" onClick={() => onSelectExperience(true)}>지금 바로 내 모든 경력 불러오기</button>
        <Link to={"/main"} className="btn gray">메인으로</Link>
      </article>

      {experienceOpen &&
        <PopCertify
          popupType={popupType}
          setPopupType={setPopupType}
          setupCareerDataList={setupCareerDataList}
          onSelectExperience={onSelectExperience}
          setSaeobjaMyeongching={setSaeobjaMyeongching}
          success={success}
        />}
      {/* 팝업창 - 읽어온 인증경력 표시하기 */}
      {!popupType.hometax &&
        <section className={careerOpen ? "popup active" : "popup"}>
          <div className="popup_wrap popup01 myresume_write">
            <article className="title_wrap ">
              <h1>인증 경력</h1>
            </article>
            <article className="content_wrap">
              <div className="title_area">
                <p className="total_field">총 경력 : <span className="point">{totalWorkTerms}</span> </p>
              </div>
              <div className="wrap">
                {
                  myCareerList.map((career, index) => (
                    <MyCareerHistory key={'carrer_' + index}
                      index={index}
                      career={career}
                      myCareerList={myCareerList}
                      setMyCareerList={setMyCareerList}
                    />
                  ))
                }
              </div>
            </article>
            <article className="button_wrap">
              <div className="big_button_wrap one">
                <button className="btn line" onClick={(e) => onGetCareerCertification(e)}>인증경력 저장하기</button>
              </div>
              <div className="close_button_wrap">
                <button onClick={() => onSelectCareer(false)}>닫기 X</button>
              </div>
            </article>
          </div>
        </section>
      }
    </>
  );
};

export default Common_Inquiry;
