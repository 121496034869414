import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
     window.scrollTo(0, 0);
  //  window.scrollTo = jest.fn()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}