/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CONFIG from '../../configs/configs'

//components
import { changeMobileFormat,changeMobileNumber,checkIDString,checkEmailString,CertificationList,isPassword,CheckDuplicationObject,getIPData } from '../Common/Common'
import PopEditSubPwd from 'components/My/PopEditSubPwd';

/*마이페이지(기업회원)>서브계정추가 및 관리*/
function CompanySubIDWrite({ varSubAuthList,setSubAuthList,varMemberInformation,varSubMemberList,setSubMemberList,getSubCompanyMember }) {
    const com_idx = localStorage.getItem('com_idx')  //기업대표자 회원번호
    const mcm_idx = localStorage.getItem('mcm_idx')  //기업번호
    const mem_mode = localStorage.getItem('mem_mode')  //회원구분
    const mem_hunter_type = localStorage.getItem('mem_hunter_type')  //헤드헌터구분


    //접속IP주소 확인
    const [varIP,setIP] = useState('');
    //팝업창 - 비밀번호교체
    const [varPopup, setPopup] = useState(0);
    const [varMemberIndex, setMemberIndex] = useState(0);
    //팝업창 열기
    const onSetPopup = (e,mem_idx) => {
      //e.preventDefault();
      setMemberIndex(mem_idx);
      setPopup(true);
    }
    
    useEffect(() => {
      getIPData(varIP,setIP,'');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //추가서브회원 입력창 조정
    const [varVisibled,setVisibled] = useState(0);
    //서브회원 정보 객체
    const subMember = {
        mem_idx:0,
        mem_mode:mem_mode,
        mem_hunter_type:mem_hunter_type,
        mem_sub:com_idx,
        mem_id:'',
        mem_pwd:'',
        mem_name:'',
        mem_nickname:'',
        mem_mobile:'',
        mem_email:'',
        cmm_department:'',
        cmm_position:'',
        cmm_status:1,
        mem_certification:0
    }
    //신규 서브회원 입력 정보 저장 객체
    const [varAddMember,setAddMember] = useState(subMember);
    //중복확인 객체
    const [varAuth,setAuth] = useState({
      id:false,
      nickname:false,
      email:false,
      mobile:false
    })
    const [varMode, setMode] = useState(1); //eslint-disable-line no-unused-vars

    //서브계정추가 - 입력창 열기
    const onAddSubMember = (e) => {
        if(varVisibled === 1) {
            alert('현재 입력중인 추가 서브계정을 저장하시고 추가하십시오.');
            return;
        }
        setAddMember(subMember);    //정보 초기화
        setVisibled(1);             //새계정 입력부분 표시
    }

    //서브계정 정보 변경
    const onChangeSubMemberInfo = (e,mem_idx) => {
      const {name,value} = e.currentTarget;
      const findInx = varSubMemberList.findIndex(element => element.mem_idx === mem_idx);
      let copyData = [...varSubMemberList];
      let bmode = false;
      copyData[findInx]={...copyData[findInx],[name]:value};
      setSubMemberList(copyData);
      //중복검사 
      if(name === 'mem_email' || name ==='mem_nickname') {
        const copyAuth = [...varSubAuthList];
        const findAInx = copyAuth.findIndex(element => element.mem_idx === mem_idx);
        if(name === 'mem_email') {
          if(copyAuth[findAInx].email) bmode = false;
          if(copyAuth[findAInx].oldEmail === value) bmode = true;
          copyAuth[findAInx]={...copyAuth[findAInx],email:bmode};
          setSubAuthList(copyAuth);
           CheckDuplicationObject("email_"+mem_idx,bmode);
        } else if(name === 'mem_nickname') {
          if(copyAuth[findAInx].nickname) bmode = false;
          if(copyAuth[findAInx].oldNickname === value) bmode = true;
          copyAuth[findAInx]={...copyAuth[findAInx],nickname:bmode};
          setSubAuthList(copyAuth);
          CheckDuplicationObject("nick_"+mem_idx,bmode);
        } 
      }
    }
    //사용유무 정보 변경
    const onChangeUse = (e,iuse,mem_idx) => {   
      const findInx = varSubMemberList.findIndex(element => element.mem_idx === mem_idx);
      let copyData = [...varSubMemberList];
      copyData[findInx]={...copyData[findInx],cmm_status:iuse};
      setSubMemberList(copyData);
    }

    //저장되어 있는 서브계정 업데이트
    const onUpdateSubMember = async (e,mem_idx) => {
        let memberData = {}; 
        let memberAuth = {};
        let member_id = "";
        if(mem_idx === 0) {
          memberData = {...varAddMember};
          memberAuth = {...varAuth};
          member_id = varMemberInformation?.mem_id+'_'+memberData.mem_id;
        } else {
          memberData = varSubMemberList.filter(element => element.mem_idx === mem_idx)[0];
          memberAuth = varSubAuthList.filter(element => element.mem_idx === mem_idx)[0];
          member_id = memberData.mem_id;
        }
        if (mem_idx === 0) {
          if(!memberData.mem_id) {
            alert("아이디를 입력해주세요."); 
            return false;
          } else if (!memberAuth.id) {
            alert("아이디 중복확인을 해주세요."); 
            return false;
          } else if (!memberData.mem_pwd) {
            alert("비밀번호를 입력해주세요."); 
            return false;
          } else if (!isPassword(memberData.mem_pwd)) {
            alert("비밀번호 형식이 올바르지 않습니다. \n영문 + 숫자 + 특수문자 조합의 8~16 자리 비밀번호를 입력해주세요.");
            return false;
          }
        }

        if (!memberData.mem_email) {
            alert("이메일을 입력해주세요.");
        } else if (!memberAuth.email) {
            alert("이메일 중복확인을 해주세요.");
        } else if (!memberData.mem_name) {
              alert("이름을 입력해주세요.");
        } else if (memberData.mem_name.length <= 1) {
            alert("이름을 2자리 이상 입력해주세요");
        }  else if (!memberData.mem_mobile) {
            alert("핸드폰번호를 입력해주세요.");
        } else {
          try {
              console.log(memberData);
              const sendData = {
                'com_idx': com_idx,
                'mcm_idx': mcm_idx,
                'mem_id': member_id,
                'memberinfo': memberData,
                'mem_ip': varIP,
                'cmm_status': varMode
              }

              await axios.post(`${CONFIG.SERVER_HOST}/_save_submember_company`,sendData, CONFIG.header).then((res) => {
                  alert(res.data.message);   
                  if(res.data.retvalue === 1) {
                    if(mem_idx === 0) { //추가한 경우 -> 배열에 저장
                      var newMember = {...varAddMember};
                      newMember.mem_idx = res.data.mem_idx;
                      newMember.mem_id = res.data.mem_id;
                      //console.log(newMember);
                      let subList = [...varSubMemberList];
                      subList.unshift(newMember);
                      //console.log(subList);
                      setSubMemberList([...subList]);  //전체 배열에 추가
                      setAddMember(subMember);  //추가입력 모듈 초기화
                      setVisibled(0); //추가페이지 안보이게

                    }
                  }  
              });
          } catch (error) {
              console.log(error);
          } 
        }
    }
    //-------------------------------------
    //새서브계정 -  정보 변경
    const onChangeAddMemberInfo = (e) => {
      const {name,value} = e.currentTarget;
      if(name === "mem_mobile") {
        const values = changeMobileNumber(value);
        setAddMember({ ...varAddMember, [name]: values });
      } else {
        setAddMember({ ...varAddMember, [name]: value });
      }
    }
    // //새서브계정 - 사용유무 정보 변경
    // const onChangeAddMemberUse = (e,iuse) => {
    //   setAddMember({...varAddMember,cmm_status: iuse});
    // }

    // //서브계정 - 사용유무
    // const onModeChangeHandler = (e) => {
    //   setMode(e.target.value);
    // };

    //확인-------------------------------
    //아이디 점검
    const onCheckIDHandler = (e) => {
      e.preventDefault();
      
      const newID = varMemberInformation?.mem_id+'_'+varAddMember.mem_id;
      console.log(newID);
      if (!checkIDString(varMemberInformation?.mem_id+varAddMember.mem_id)) {
        alert("아이디 형식에 맞지 않습니다.");
        return false;
      }
      
      const params = new URLSearchParams();
      params.append("mem_id", newID);
      axios.post(`${CONFIG.SERVER_HOST}/checkId`, params).then((res) => {
        console.log(res.data);
        if (res.data.tf === true) {
          alert("사용가능한 ID입니다.");
          setAuth({...varAuth,id:true});
        } else {
          alert("다른 ID를 입력해주세요.");
          setAuth({...varAuth,id:false});
          setAddMember({...varAddMember, mem_id:''});
        }
      });
    };

    //이메일주소 적합성 확인
    const onCheckEmailHandler = (e,mem_idx) => {
      e.preventDefault();
      let mem_email = "",findDInx = 0, findAInx = 0, findData = [], findAuth = [];
      if(mem_idx > 0) { //이미 등록된 서브회원
        //권한
        findAInx = varSubAuthList.findIndex(element => element.mem_idx === mem_idx);
        findAuth = [...varSubAuthList];
        //정보
        findDInx = varSubMemberList.findIndex(element => element.mem_idx === mem_idx);
        findData = [...varSubMemberList];        
        mem_email = findData[findDInx].mem_email;
      } else { //신규 서브회원
        mem_email = varAddMember.mem_email;
      }
      if(mem_email === "") {
        alert("이메일주소를 먼저 입력해주세요");
        return false;
      } else if (!checkEmailString(mem_email)) {
        alert("이메일 형식에 맞지않습니다.");
        if(mem_idx === 0) {
          setAddMember({...varAddMember, mem_email:""});
        } else {
          findData[findDInx]={...findData[findDInx],mem_email:''};
          setSubMemberList(findData);
        }
        return false;
      }
      const params = new URLSearchParams();
      params.append("mem_email", mem_email);
      axios.post(`${CONFIG.SERVER_HOST}/_checkEmail`, params).then((res) => {
        alert(res.data.message);
        if (res.data.retvalue === 1) {
          if(mem_idx === 0) {
            setAuth({...varAuth,email:true});
          } else {
            findAuth[findAInx]={...findAuth[findAInx],email:true};
            setSubAuthList(findAuth);
          }
        } else {
          if(mem_idx === 0) {
            setAuth({...varAuth,email:false});
            setAddMember({...varAddMember, mem_email:''});
          } else {
            findAuth[findAInx]={...findAuth[findAInx],email:false};
            setSubAuthList(findAuth);
            findData[findDInx]={...findData[findDInx],mem_email:''};
            setSubMemberList(findData);
          }
        }
      });
    };

    //이미 등록된 서브회원의 경우------
    const onCertificationList = (e,mem_idx) => {
      e.preventDefault();
      const memberData = varSubMemberList.filter(element => element.mem_idx === mem_idx)[0];
      if (memberData.mem_certification) {
        alert("이미 휴대폰 인증이 완료되었습니다.");
      } else {
        CertificationList(varSubMemberList,setSubMemberList,varSubAuthList,setSubAuthList,mem_idx );
      }
    }

    //서브계정 삭제
    const onDeleteSubMember = async (e,mem_idx) => {
      if (!window.confirm('삭제하시겠습니까?')) {
        return false;
      }
      try {
          const sendData = {
            'mem_idx': mem_idx,
          }

          await axios.post(`${CONFIG.SERVER_HOST}/_delete_submember_company`,sendData, CONFIG.header).then((res) => {
            if(res.data.retvalue === 1) {
              // alert(res.data.message);   
            }else{
              alert(res.data.message);
            }
          });
          getSubCompanyMember();
      } catch (error) {
          console.log(error);
      } 
  }

  return (
    <>
        <div className="my_title_wrap">
            <h3 className="my_title">서브 계정</h3>
            <button type="button" className="btn line" onClick={(e)=>onAddSubMember(e)}><span className="icon_plus"></span>서브 계정 추가하기</button>          
        </div>
        <div id="add_submember" className={varVisibled?"":"disabled"}>
            <ul className="table table_view01 sub_account_area">
                <li className="tr col">
                    <div className="th">아이디<span style={{color:"red"}}>*</span></div>
                    <div className="td sub_id">
                        <span style={{marginRight:"5px"}}>{varMemberInformation?.mem_id}_</span>
                        <input type="text" name="mem_id" value={varAddMember?.mem_id} onChange={(e)=>onChangeAddMemberInfo(e)} style={{marginRight:"3px"}}/>
                        <button className="btn white" onClick={(e) => onCheckIDHandler(e)} style={{width:"14%"}}>중복확인</button>
                    </div>
                    
                </li>
                <li className="tr col2">
                    <div className="th">비밀번호<span style={{color:"red"}}>*</span></div>
                    <div className="td"><input type="password" name="mem_pwd" value={varAddMember?.mem_pwd} onChange={(e)=>onChangeAddMemberInfo(e)}/></div> 
                    <div className="th">이메일<span style={{color:"red"}}>*</span></div>
                    <div className="td">
                      <input type="text" name="mem_email" value={varAddMember?.mem_email} onChange={(e)=>onChangeAddMemberInfo(e)} style={{marginRight:"3px"}}/>
                      <button className="btn white" onClick={(e) => onCheckEmailHandler(e,0)}>중복확인</button>
                    </div>
                </li>
                <li className="tr col2">
                    <div className="th">이름<span style={{color:"red"}}>*</span></div>
                    <div className="td"><input type="text" name="mem_name" value={varAddMember?.mem_name} onChange={(e)=>onChangeAddMemberInfo(e)}/></div>
                    <div className="th">휴대폰<span style={{color:"red"}}>*</span></div>
                    <div className="td">
                      <input type="text" name="mem_mobile" value={changeMobileFormat(varAddMember?.mem_mobile)} onChange={(e)=>onChangeAddMemberInfo(e)}/>
                    </div>
                </li>
                <li className="tr col2">
                    <div className="th">부서</div>
                    <div className="td"><input type="text" name="cmm_department" value={varAddMember?.cmm_department} onChange={(e)=>onChangeAddMemberInfo(e)}/></div>
                    <div className="th">직책</div>
                    <div className="td"><input type="text" name="cmm_position" value={varAddMember?.cmm_position} onChange={(e)=>onChangeAddMemberInfo(e)}/></div>
                </li>
                <li className="sub_account_use_wrap">
                    {/* <div>
                        0:삭제, 1:사용함, 2:사용안함
                        <div className="radio_wrap">
                        <input type="radio" name="chk2" id="agree_chk3" defaultChecked={1} className="chk circle" onClick={(e)=>onModeChangeHandler(e)} value={1} /><label htmlFor="agree_chk3">사용함</label>
                        </div>
                        <div className="radio_wrap">
                        <input type="radio" name="chk2" id="agree_chk4" className="chk circle" onClick={(e)=>onModeChangeHandler(e)} value={2} style={{ backgroundColor: "#eee" }} disabled/><label htmlFor="agree_chk4">사용 안함</label>
                        </div>
                    </div> */}
                    <button type="button" className="btn line" onClick={(e)=>onUpdateSubMember(e,0)}>저장</button>
                </li>
            </ul>
        </div>
        {varSubMemberList?.map((member,index) => (
        <ul key={'submember_'+index} className="table table_view01 sub_account_area">
            <li className="tr col">
                <div className="th">아이디</div>
                <div className="td sub_id">
                  <span>{varMemberInformation?.mem_id}_</span>
                  <input type="text" name="mem_id" value={member.mem_id.substring(varMemberInformation?.mem_id.length+1)} readOnly/>
                </div>
            </li>
            <li className="tr col2">
                <div className="th">비밀번호</div>
                <div className="td">
                  <button type="button" className="btn line" onClick={(e)=>onSetPopup(e,member.mem_idx)}>비밀번호 변경</button>
                </div> 
                <div className="th">이메일</div>
                <div className="td">
                  <input type="text" name="mem_email" value={member?.mem_email} onChange={(e)=>onChangeSubMemberInfo(e,member?.mem_idx)}/>
                  <button className="btn white hide" id={"email_"+member?.mem_idx} onClick={(e) => onCheckEmailHandler(e,member?.mem_idx)}>중복확인</button>
                </div>
            </li>
            <li className="tr col2">
                <div className="th">이름</div>
                <div className="td"><input type="text" name="mem_name" value={member?.mem_name} onChange={(e)=>onChangeSubMemberInfo(e,member?.mem_idx)}/></div>
                <div className="th">휴대폰</div>
                <div className="td">
                  <input type="text" name="mem_mobile" value={changeMobileFormat(member?.mem_mobile)} readOnly/>
                  <button className="btn white" onClick={(e)=>onCertificationList(e,member?.mem_idx)}>번호변경</button>
                </div>
            </li>
            <li className="tr col2">
                <div className="th">부서</div>
                <div className="td"><input type="text" name="cmm_department" value={member?.cmm_department} onChange={(e)=>onChangeSubMemberInfo(e,member?.mem_idx)}/></div>
                <div className="th">직책</div>
                <div className="td"><input type="text" name="cmm_position" value={member?.cmm_position} onChange={(e)=>onChangeSubMemberInfo(e,member?.mem_idx)}/></div>
            </li>
            <li className="sub_account_use_wrap">
                <div>
                    <div className="radio_wrap">
                      <input type="radio" name={"chk1_"+index} id={"aggree_chk1_"+index} value={1} className="chk circle" onClick={(e)=>onChangeUse(e,1,member?.mem_idx)} defaultChecked={member?.cmm_status===1?true:false}/>
                      <label htmlFor={"aggree_chk1_"+index}>사용함</label>
                    </div>
                    <div className="radio_wrap">
                      <input type="radio" name={"chk1_"+index} id={"aggree_chk2_"+index} value={2} className="chk circle" onClick={(e)=>onChangeUse(e,2,member?.mem_idx)} defaultChecked={member?.cmm_status===2?true:false}/>
                      <label htmlFor={"aggree_chk2_"+index} checked>사용 안함</label>
                    </div>
                </div>
                <button type="button" className="btn line" onClick={(e)=>onUpdateSubMember(e,member?.mem_idx)} style={{marginRight:"2%"}}>저장</button>
                <button type="button" className="btn line" onClick={(e)=>onDeleteSubMember(e,member?.mem_idx)}>삭제</button>
            </li>
        </ul>
        ))}
        <PopEditSubPwd varPopup={varPopup} setPopup={setPopup} varMemberIndex={varMemberIndex} />
    </>
  );
}

export default CompanySubIDWrite;