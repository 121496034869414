//css

//components
import Header from '../../components/Layout/HeaderCompany';
import SubVisual from '../../components/Layout/SubVisual';
import Footer from '../../components/Layout/Footer';
import MyMenu from '../../components/Layout/MyMenuCompany';
import MyBookmarkTab from '../../components/Tab/MyBookmarkTab';
import MyBookmarkList from '../../components/Board/MyBookmarkList';
import BannerBottom from 'components/Banner/BannerBottom';
//이미지

/*마이페이지(기업회원)>관심 기업 관리*/
function CompanyBookmark() {
  return (
    <div className="LayoutMy">
        <Header />
        <SubVisual PageTitle="MyHEDING" />
        <div id="SubWrap">
            <div className="CenterWrap">
                <div className="container">
                    <MyMenu SelectedMy="관심 기업 관리" Menu4="Active"/>
                    <div className="MyContents SectionBox p_mybookmark">
                        <MyBookmarkTab Tab1="Active"/>
                        <MyBookmarkList/>
                    </div>
                </div>
            </div>
        </div>
        <BannerBottom />
        <Footer/>
    </div>
  );
}

export default CompanyBookmark;
