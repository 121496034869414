/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import CONFIG from "configs/configs";
import useFetch from "js/useFetch";
import useExternalScripts from "js/useExternalScripts"
import {isMobile} from "../Common/Common";
import $ from "jquery";

const clientId = CONFIG.GoogleClientId;

const Mobile = isMobile();
export default function GoogleSignin({auto_login, onGoogleLogin}) {

  const divRef = useRef(null);
  const { handleGoogle } = useFetch(
    `${CONFIG.SERVER_HOST}/googleToken`
  );

  const [GsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  const script = document.createElement("script");
  script.src ="https://accounts.google.com/gsi/client";
  script.async = true;
  document.head.appendChild(script);
  script.onload = function() {
    console.log("Script loaded and ready");
    setGsiScriptLoaded(true);
  };

  useEffect(() => {
    // const google = window.google
    /* global google */
    if (window.google) {
      console.log("Script loaded and ready2222");
      
      if (Mobile) {
        //모바일 기기 전용
        google.accounts.id.initialize({
          client_id: clientId,
          ux_mode: "redirect",
          login_uri: `${CONFIG.SERVER_HOST_REPUTATION}/auth/callback/google`,
          //callback: handleGoogle,
        });
      } else {
        //웹 전용
        google.accounts.id.initialize({
          client_id: clientId,
          callback: handleGoogle,
        });
      }


      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        text: 'continue_with',
        shape: 'rectangular',
        width: 250,
        logo_alignment: "left",
      });


      //google.accounts.id.prompt()
    }
  }, [GsiScriptLoaded]);

  useEffect(()=>{
      localStorage.setItem('google_auto', auto_login);

  }, [auto_login]);

  const onClickGooglelogin = () => {
      document.querySelector(`[aria-labelledby='button-label']`).click();
    
  }


  return <>
    <div id="signUpDiv" data-text="signup_with" style={{ display: Mobile ? '' : 'none', border: '1px', backgroundColor: '#1a73e8', height: '55px', marginBottom: '5px', verticalAlign: 'middle', paddingTop: '6px'}} ref={divRef}></div>
    <button button className="btn google" style={{ display: Mobile ? 'none' : '' }} onClick={() => onClickGooglelogin()}>구글 이용하기</button> 
  </>

}