import React from "react";
import { Link } from "react-router-dom";
//css
//components
//이미지
import BannerNewImg from "img/sub/banner_new.png";
import BannerNewImg_m from "img/sub/banner_new_m.jpg";

/*하단배너*/
function BannerBottom() {
  return (
      <Link to={"/AboutUs"} className="sub_bottom_banner center_wrap">
        <img src={BannerNewImg} className='view_pc' alt="view_pc" />
        <img src={BannerNewImg_m} className='view_m' alt="view_m" />
      </Link>
  );
}

export default BannerBottom;
