import React from "react";
import { Routes, Route } from "react-router-dom";
//css

import "css/ResumeStyle.css";

//components

// import HeaderNone from '../../components/Layout/HeaderNone';
import ResumePdfView from '../Resume/ResumePdfView';


function MyResume() {

  return (
    <div className="p_resume">
        {/* <HeaderNone /> */}
        {/* <SubVisual PageTitle="HEDING 이력서" SubVisual_className="type2"/> */}
        <div>
          <Routes>
            <Route path="/:re_code/:type" element={<ResumePdfView />} /> 
          </Routes>
        </div>
    </div>
  );
}

export default MyResume;
