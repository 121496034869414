/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import CONFIG from "../../configs/configs";
import Paging from "../Layout/Paging";
import WonLogoIcon_pc from "img/main/won.png";
import WonLogoIcon_m from "img/main/won_m.png";
import { ChangeMoneyUnit } from "../Common/Common";
import CertificationCareerView from "../Resources/CertificationCareer";
import AddCareerView from "../Resources/AddCareer";

const SupportViewC = ({ em_idx }) => {
  const navigator = useNavigate();
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;
  const [varEmployIndex] = useState(em_idx);
  const [varAnnouncement, setAnnouncement] = useState({}); //채용정보
  const [varApplyCount, setApplyCount] = useState({}); //공고 지원상황
  const [varApplyEmploy, setApplyEmploy] = useState([]); //지원자 현황
  const [varApplyStatus, setApplyStatus] = useState([]); //지원자 상태
  const [applyRecommend, setApplyRecommend] = useState([]); //추천받아서 지원한 지원자들
  const [varTabEmployStatus, setTabEmployStatus] = useState(100); //채용진행상태
  const [varFilterApplyEmploy, setFilterApplyEmploy] = useState([]); //지원자 현황
  const [varPageApplyEmploy, setPageApplyEmploy] = useState([]); //지원자 현황
  const [currentHedingMoney, setCurrentHedingMoney] = useState([]); //지원자들 보유 헤딩머니
  const today = new Date().toISOString().split("T")[0];

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  // 심사상태 변경 
  const [ReviewStatus, setReviewStatus] = useState(false);

  const getEmploy = async () => {
    if (varEmployIndex > 0) {
      const params = new URLSearchParams();
      params.append("em_idx", varEmployIndex);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_employ_support_view`, params)
        .then((res) => {
          //채용정보
          if (res.data.employInfo.length > 0) {
            setAnnouncement(res.data.employInfo[0]);
          }
          //공고 지원상황
          if (res.data.employCount.length > 0) {
            setApplyCount(res.data.employCount[0]);
          }
          //지원자 현황
          if (res.data.employApply.length > 0) {
            setApplyEmploy(res.data.employApply);
            setFilterApplyEmploy(res.data.employApply);
            setTotalCount(res.data.employApply.length);
            setPageNum(1);
          }
          //지원자 상태
          if (res.data.employStatus.length > 0) {
            setApplyStatus(res.data.employStatus);
          }
          //추천받아 지원한 지원자들
          if (res.data?.applyRecommend?.length > 0) {
            setApplyRecommend(res.data.applyRecommend)
          }
        });
      setReviewStatus(false);
    }
  }

  useEffect(async () => {
    getEmploy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReviewStatus]);

  const ResetFilteredEmploy = () => {
    let copyLists = [...varApplyEmploy];
    if (varTabEmployStatus !== 100) {
      copyLists = copyLists.filter(
        (element) => element.ea_checking === varTabEmployStatus
      );
    }
    setFilterApplyEmploy(copyLists);
    setTotalCount(copyLists.length);
    setPageNum(1);
    //필터링된 배열이 없으면 하부의 페이지필터링이 작동되지 않습니다.
    if (copyLists.length === 0) {
      setPageApplyEmploy([]);
    }
  };

  useEffect(() => {
    ResetFilteredEmploy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabEmployStatus, varApplyEmploy]);

  //표시할 페이지가 바뀌면
  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varFilterApplyEmploy.length === 0) return;
    let copyData = [...varFilterApplyEmploy];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    setPageApplyEmploy(copyData);
  };
  useEffect(() => {
    getDataPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varFilterApplyEmploy]);

  //채용공고별 지원자 상태 변경시 - 목록변화
  const onFilteringHandler = (e, ino) => {
    e.preventDefault();
    setTabEmployStatus(ino);
  };

  //채용공고 지원자별 상태 변경시 -
  const onCheckingProcessHandler = async (e, ea_idx) => {
    const value = e.target.value;

    //지원자 배열에서 해당 지원자 찾기
    const finsIndex = varApplyEmploy.findIndex(
      (element) => element.ea_idx === ea_idx
    );

    //이전 심사상태 가져오기
    const applyEaChecking = varPageApplyEmploy.filter(el => (
      el.ea_checking === varPageApplyEmploy[finsIndex]?.ea_checking
    ))

    //보유한 헤딩머니랑 지원자랑 매칭
    const applyHedingMoney = currentHedingMoney.filter(el => (
      el.mem_idx === varPageApplyEmploy[finsIndex]?.ea_mem_idx
    ))

    //지원자가 추천받아서 지원한 건지, 그냥 지원한건지 
    const applyRecommendMember = applyRecommend.filter((el, i) => (
      el.ea_idx === ea_idx
    ))

    //지원자의 진행상태읽기
    const eaChecking = varApplyEmploy[finsIndex]?.ea_checking;
    //지원자 진행상태 변경하기
    let copyData = [...varApplyEmploy];
    copyData[finsIndex] = { ...copyData[finsIndex], ea_checking: value };
    setApplyEmploy(copyData);

    //변경전 카운팅에서 빼기
    const beforeCount =
      "_count" + (eaChecking >= 0 ? eaChecking : 5 - eaChecking);
    setApplyCount((prevState) => ({
      ...prevState,
      [beforeCount]: prevState[beforeCount] - 1,
    }));
    //변경후 카운팅에서 더하기
    const afterCount = "_count" + (value >= 0 ? value : 5 - value);
    setApplyCount((prevState) => ({
      ...prevState,
      [afterCount]: prevState[afterCount] + 1,
    }));
    //데이타베이스에 반영하기
    const params = new URLSearchParams();
    params.append("ea_idx", ea_idx);
    params.append("prev_ea_checking", applyEaChecking[0]?.ea_checking); //이전 심사상태
    params.append("ea_checking", value);
    params.append("mem_name", varPageApplyEmploy[finsIndex]?.mem_name); // 공고 지원자 이름
    params.append("com_name", varAnnouncement.com_name); // 해당 공고의 회사이름
    params.append("em_title", varAnnouncement.em_title); // 해당 공고의 공고제목 (알림톡 작업할 때 postion은 해당 공고의 업무로 진행. 하지만 고객사 요청으로 position은 공고 제목으로 변경)
    params.append("ea_em_idx", varPageApplyEmploy[finsIndex]?.ea_em_idx); // 해당 공고의 공고 idx
    params.append("ea_mem_idx", varPageApplyEmploy[finsIndex]?.ea_mem_idx); // 해당 공고의 지원자 idx
    params.append("em_reward_applicant", varAnnouncement.em_type === 4 ? varAnnouncement.emh_reward_applicant : varAnnouncement.em_reward_applicant); //합격자 보상금
    params.append("em_reward_recommend", varAnnouncement.em_type === 4 ? (varAnnouncement.emh_reward_recommend - varAnnouncement.emh_reward_applicant) : varAnnouncement.em_reward_applicant); //추천인 보상금
    params.append("mcm_idx", localStorage.getItem("mcm_idx"));//해당 공고의 기업 idx
    params.append("mh_balance", applyHedingMoney[0]?.mh_balance === undefined || applyHedingMoney[0]?.mh_balance === 0 ? varAnnouncement.em_reward_applicant : (varApplyEmploy[finsIndex].ea_checking === 5 && value !== 5) ? applyHedingMoney[0]?.mh_balance - varAnnouncement.em_reward_applicant : applyHedingMoney[0]?.mh_balance + varAnnouncement.em_reward_applicant);//보유중인 헤딩머니가 없으면 합격자 보상금, 있으면 (이전에 입사로 받은 이력이 있으면 보유중인 헤딩머니 - 합격자 보상금 아니면 보유중인 헤딩머니 + 합격자 보상금)
    params.append("applyRecommendMember", applyRecommendMember.length > 0 ? true : false);//추천받은 지원자인지 확인여부
    params.append("er_recommend_idx", applyRecommendMember[0]?.er_recommend_idx);//추천인 idx
    params.append("mh_idx", applyHedingMoney[0]?.mh_idx);//member_hm_history테이블 idx
    params.append("url", `${CONFIG.URL}`);
    params.append("prev_yn", varPageApplyEmploy[finsIndex]?.ea_idate);

    await axios
      .post(`${CONFIG.SERVER_HOST}/_change_employ_status`, params)
      .then((res) => {
        if (res.data.retvalue === 1) {//성공
          // alert("심사상태가 변경되었습니다.");
          alert(res.data.message);
          setPageApplyEmploy(
            varPageApplyEmploy.map(em =>
              em.ea_idx === ea_idx ? {
                ...em,
                ea_checking: Number(value)
              } : em
            ));

          setApplyEmploy(
            varApplyEmploy.map(em =>
              em.ea_idx === ea_idx ? {
                ...em,
                ea_checking: Number(value)
              } : em
            )
          );

          setFilterApplyEmploy(
            varFilterApplyEmploy.map(em =>
              em.ea_idx === ea_idx ? {
                ...em,
                ea_checking: Number(value)
              } : em
            )
          )

          // setResumeAddCareerList(
          //   resumeAddCareerList.map(resume =>
          //     resume.idx === career.idx ? {
          //       ...resume,
          //       rac_descript: copyArray
          //     } : resume
          //   ));
        } else {//실패
          alert("심사상태 변경과정에서 오류가 발행하였습니다.\n오류가 계속되면 관리자 에게 문의 하세요.");
          window.location.reload();
        }
      });
    setReviewStatus(true);
  };

  //공고조기마감
  const onCloseAnnouncementHandler = async (e, em_idx) => {
    e.preventDefault();
    if (window.confirm("공고를 마감하시겠습니까?")) {
      //공고 조기 마감 처리
      const params = new URLSearchParams();
      params.append("em_idx", em_idx);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_finish_announcement`, params)
        .then((res) => {
          alert(res.data.message);
          navigator("/Company/comAnnouncement/SupportList");
        });
    }
  };
  //공고삭제
  const onDeleteAnnoucementHandler = async (e, em_idx) => {
    e.preventDefault();
    if (window.confirm("공고를 삭제하시겠습니까?")) {
      const params = new URLSearchParams();
      params.append("em_idx", em_idx);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_delete_announcement`, params)
        .then((res) => {
          alert(res.data.message);
          navigator("/Company/comAnnouncement/SupportList");
        });
    }
  };
  // //엑셀파일로 다운로드
  // const onDownloadExcel = (e) => {
  //   e.preventDefault();
  //   alert("코딩필요...");
  // };

  const joinDateHandler = async (e, ea_idx) => {
    const { value } = e.target;
    // setJoinDate(value);


    //ea_idate
    // setPageApplyEmploy(
    //   varPageApplyEmploy.map((data) => {
    //     return data.ea_idx === ea_idx // 전달한 id값이랑 data에 있는 id값이랑 일치하는 id는 '?' 실행
    //       ? { ...data, ea_idate: value } // id가 일치하면 수정대상이니 수정한 내용으로 업데이트
    //       : data // 수정 대상이 아니면 그냥 원래 있던값 리턴
    //   })
    // );

    // setSaveDate(value);
    // setSave_eaidx(ea_idx);

    //여러 지원자들중 지원자 index찾기
    const finsIndex = varApplyEmploy.findIndex(
      (element) => element.ea_idx === ea_idx
    );

    //지원자가 추천받아서 지원한 건지, 그냥 지원한건지 
    const applyRecommendMember = applyRecommend.filter((el, i) => (
      el.ea_idx === ea_idx
    ))

    //보유한 헤딩머니랑 지원자랑 매칭
    const applyHedingMoney = currentHedingMoney.filter(el => (
      el.mem_idx === varPageApplyEmploy[finsIndex]?.ea_mem_idx
    ))

    const params = new URLSearchParams();
    params.append("ea_idx", ea_idx);
    params.append("ea_idate", value);
    params.append("mem_idx", varPageApplyEmploy[finsIndex]?.ea_mem_idx);//지원자 idx
    params.append("recommend_idx", applyRecommendMember[0]?.er_recommend_idx);//추천인 idx

    params.append("mem_name", varPageApplyEmploy[finsIndex]?.mem_name); // 공고 지원자 이름
    params.append("com_name", varAnnouncement.com_name); // 해당 공고의 회사이름
    params.append("em_title", varAnnouncement.em_title); // 해당 공고의 공고제목 (알림톡 작업할 때 postion은 해당 공고의 업무로 진행. 하지만 고객사 요청으로 position은 공고 제목으로 변경)
    params.append("ea_em_idx", varPageApplyEmploy[finsIndex]?.ea_em_idx); // 해당 공고의 공고 idx
    params.append("ea_mem_idx", varPageApplyEmploy[finsIndex]?.ea_mem_idx); // 해당 공고의 지원자 idx
    params.append("em_reward_applicant", varAnnouncement.em_type === 4 ? varAnnouncement.emh_reward_applicant : varAnnouncement.em_reward_applicant); //합격자 보상금
    params.append("em_reward_recommend", varAnnouncement.em_type === 4 ? (varAnnouncement.emh_reward_recommend - varAnnouncement.emh_reward_applicant) : varAnnouncement.em_reward_applicant); //추천인 보상금
    params.append("mcm_idx", localStorage.getItem("mcm_idx"));//해당 공고의 기업 idx
    params.append("mh_balance", applyHedingMoney[0]?.mh_balance === undefined || applyHedingMoney[0]?.mh_balance === 0 ? varAnnouncement.em_reward_applicant : (varApplyEmploy[finsIndex].ea_checking === 5 && value !== 5) ? applyHedingMoney[0]?.mh_balance - varAnnouncement.em_reward_applicant : applyHedingMoney[0]?.mh_balance + varAnnouncement.em_reward_applicant);//보유중인 헤딩머니가 없으면 합격자 보상금, 있으면 (이전에 입사로 받은 이력이 있으면 보유중인 헤딩머니 - 합격자 보상금 아니면 보유중인 헤딩머니 + 합격자 보상금)
    params.append("applyRecommendMember", applyRecommendMember.length > 0 ? true : false);//추천받은 지원자인지 확인여부
    params.append("er_recommend_idx", applyRecommendMember[0]?.er_recommend_idx);//추천인 idx
    params.append("mh_idx", applyHedingMoney[0]?.mh_idx);//member_hm_history테이블 idx
    params.append("prev_yn", varPageApplyEmploy[finsIndex]?.ea_idate);

    await axios
      .post(`${CONFIG.SERVER_HOST}/_change_employ_idate`, params)
      .then((res) => {
        if (res.data.retvalue === 1) {
          alert(res.data.message)
        } else {
          alert(res.data.message)
        }
      })
    getEmploy();
  }

  //보유중인 헤딩머니 가져오기
  useEffect(async () => {
    const ea_mem_idx = varApplyEmploy.map((el, i) => (
      el.ea_mem_idx
    ))

    const sendData = {
      "mem_idx": ea_mem_idx
    }

    if (varApplyEmploy.length > 0) {
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_applicants_heding_money`, sendData, CONFIG.header)
        .then((res) => {
          if (res.data.retvalue === 1) {
            setCurrentHedingMoney(res.data.results)
          }
        });
    }
  }, [varApplyEmploy])

  return varAnnouncement === null ||
    varAnnouncement.em_title === null ||
    varAnnouncement.em_title === "" ? null : (
    <>
      <section className="support_view_area">
        <div className="recruit_dashboard">
          <section className="sec1">
            <div className="left_wrap">
              <h1>
                {varAnnouncement.com_name}
                <span>ㅣ</span>
                {varAnnouncement.vr_name}
                <span>ㅣ</span>
                {varAnnouncement.em_careers === 0
                  ? "경력무관"
                  : varAnnouncement.em_careers === 1
                    ? "신입"
                    : "경력"}
                {varAnnouncement.em_careers === 2 && varAnnouncement.em_career_year !== 0
                  ? "(" + varAnnouncement.em_career_year + "년)"
                  : ""}
              </h1>

              <h2>
                <span>
                  {varAnnouncement.em_status === 0
                    ? "대기중"
                    : varAnnouncement.em_status === 1
                      ? "진행중"
                      : "마감"}
                </span>
                {varAnnouncement.em_title}
              </h2>
              <h3>
                지원기간 : {varAnnouncement.em_sdate} ~{" "}
                {varAnnouncement.em_deadline === 0
                  ? varAnnouncement.em_edate
                  : "채용까지"}
                ㆍ 등록일 : {varAnnouncement.em_rdate} ㆍ 조회수 :
                {varAnnouncement.em_hits}
              </h3>
            </div>
            <div className="right_wrap">
              <div className="button_area">
                {/* <button onClick={(e) => onDownloadExcel(e)}>
                  엑셀 다운로드
                  <img src={DownloadIcon} alt="" />
                </button> */}
                <Link
                  to={`/Company/ComAnnouncement/AnnouncementModify/${varAnnouncement.em_idx}`}
                // state={{ em_idx: varAnnouncement.em_idx }}
                >
                  수정
                </Link>
                <Link
                  to={`/Company/ComAnnouncement/AnnouncementCopy/${varAnnouncement.em_idx}`}
                // state={{ em_idx: varAnnouncement.em_idx }}
                >
                  복사
                </Link>
                <button
                  onClick={(e) =>
                    onCloseAnnouncementHandler(e, varAnnouncement.em_idx)
                  }
                >
                  조기마감
                </button>
                <button
                  onClick={(e) =>
                    onDeleteAnnoucementHandler(e, varAnnouncement.em_idx)
                  }
                >
                  삭제
                </button>
              </div>
            </div>
            <article className="writer">
              {varAnnouncement.mcm_image === 0 || varAnnouncement.mcm_image === "" ?
                <figure className="bg_profile_img"></figure> :
                <figure className="bg_profile_img" style={{ backgroundImage: `url(${CONFIG.SERVER_HOST}/mcm_image/${varAnnouncement?.mcm_ufile})` }}></figure>}
              <h1>
                {varAnnouncement.mem_id} ({varAnnouncement.mem_name})
              </h1>
            </article>
          </section>
          {varAnnouncement.em_type === 3 ? null :
            <section className="sec2">
              {/* <div>
              보상금 지급 조건 :&nbsp;
              <span className="txt_blue">{varAnnouncement.vrp_name}</span>
            </div> */}
              <div>
                <img className="view_pc" src={WonLogoIcon_pc} alt="" />
                <img className="view_m" src={WonLogoIcon_m} alt="" />
                추천인 보상금:&nbsp;
                <b className="view_pc">
                  {varAnnouncement.em_type === 4 ? ChangeMoneyUnit(varAnnouncement.emh_reward_recommend / 10000 - varAnnouncement.emh_reward_applicant / 10000) : ChangeMoneyUnit(varAnnouncement.em_reward_recommend / 10000 - varAnnouncement.em_reward_applicant / 10000)}
                </b>
                <b className="view_m">
                  {varAnnouncement.em_type === 4 ? ChangeMoneyUnit(varAnnouncement.emh_reward_recommend / 10000 - varAnnouncement.emh_reward_applicant / 10000) : ChangeMoneyUnit(varAnnouncement.em_reward_recommend / 10000 - varAnnouncement.em_reward_applicant / 10000)}
                </b>
              </div>

              <div>
                <img className="view_pc" src={WonLogoIcon_pc} alt="" />
                <img className="view_m" src={WonLogoIcon_m} alt="" />
                합격자 보상금:&nbsp;
                <b className="view_pc">
                  {varAnnouncement.em_type === 4 ? ChangeMoneyUnit(varAnnouncement.emh_reward_applicant / 10000) : ChangeMoneyUnit(varAnnouncement.em_reward_applicant / 10000)}
                </b>
                <b className="view_m">
                  {varAnnouncement.em_type === 4 ? ChangeMoneyUnit(varAnnouncement.emh_reward_applicant / 10000) : ChangeMoneyUnit(varAnnouncement.em_reward_applicant / 10000)}
                </b>
              </div>
            </section>
          }
          <section className="sec3">
            <ul>
              <li>
                <p onClick={(e) => onFilteringHandler(e, 100)} style={{ cursor: "pointer" }}>
                  총 지원자<b>{varApplyCount ? varApplyCount._total : 0}</b>
                </p>
                <p onClick={(e) => onFilteringHandler(e, 0)} style={{ cursor: "pointer" }}>
                  미열람
                  <b className="txt_red">
                    {varApplyCount ? varApplyCount._count0 : 0}
                  </b>
                </p>

                <p onClick={(e) => onFilteringHandler(e, 1)} style={{ cursor: "pointer" }}>
                  검토중 <b>{varApplyCount ? varApplyCount._count1 : 0}</b>
                </p>
              </li>

              <li>
                <p onClick={(e) => onFilteringHandler(e, 2)} style={{ cursor: "pointer" }}>
                  서류합격<b>{varApplyCount ? varApplyCount._count2 : 0}</b>
                </p>
                <p onClick={(e) => onFilteringHandler(e, 3)} style={{ cursor: "pointer" }}>
                  면접합격 <b>{varApplyCount ? varApplyCount._count3 : 0}</b>
                </p>
                <p onClick={(e) => onFilteringHandler(e, 4)} style={{ cursor: "pointer" }}>
                  최종합격<b>{varApplyCount ? varApplyCount._count4 : 0}</b>
                </p>
              </li>
              <li>
                <p onClick={(e) => onFilteringHandler(e, -2)} style={{ cursor: "pointer" }}>
                  불합격
                  <b className="txt_blue">
                    {varApplyCount ? varApplyCount._count7 : 0}
                  </b>
                </p>

                <p onClick={(e) => onFilteringHandler(e, 5)} style={{ cursor: "pointer" }}>
                  입사<b>{varApplyCount ? varApplyCount._count5 : 0}</b>
                </p>

                <p onClick={(e) => onFilteringHandler(e, -3)} style={{ cursor: "pointer" }}>
                  보증기간 내 퇴사
                  <b>{varApplyCount ? varApplyCount._count8 : 0}</b>
                </p>
              </li>
            </ul>
          </section>
        </div>
      </section>
      <table className="table_list01">
        <colgroup><col width="8%"/><col width=""/><col width=""/><col width=""/><col width=""/><col width=""/><col width=""/><col width=""/></colgroup>
        <thead>
          <tr>
            <th>번호</th>
            <th>지원자</th>
            <th>총 경력</th>
            <th>재직회사</th>
            <th>학력</th>
            <th>심사상태</th>
            <th>지원일자</th>
            <th>입사일자</th>
          </tr>
        </thead>
        <tbody>
          {varPageApplyEmploy.length
            ? varPageApplyEmploy.map((apply, i) => (
          <tr  key={"apply_" + i}>
            <td className="td_num">{varTotalCount - (varPageNum - 1) * varPageSize - i}</td>
            <td className="td_title">
              {/* <Link to={`${PageFolder}ResumeDetail/${resumes.index}/web`} className="button ml mr">미리보기</Link> */}
              {apply.ea_checking === -1 ? <article className="writer"><figure className="bg_profile_img" style={{ backgroundImage: `url(${imageFoler + apply.mem_ufile})` }}></figure><h1>{apply.mem_name}</h1></article> : (
                  <Link
                    to={"/Company/comAnnouncement/ResumeView"}
                    state={{ re_idx: apply.re_idx, em_idx: em_idx }}
                    className="BtnLine"
                  >
                    <article className="writer">
                      {(apply?.mem_photo === 0 || apply?.mem_ufile === undefined ? <figure className="bg_profile_img"></figure>
                        : <figure className="bg_profile_img" style={{ backgroundImage: `url(${imageFoler + apply.mem_ufile})` }}></figure>)}
                      <h1>{apply.mem_name}</h1>
                    </article>
                  </Link>
                )}
            </td>
            <td className="td_info">
            <span className="m_th">총 경력 :&nbsp;</span>
                {/* {CalculateCareers(
                  apply.re_career_year,
                  apply.re_careeradd_year
                )} */}
                {
                  !apply?.certification_experience_year && !apply?.certification_experience_year ?
                    <AddCareerView highLookup={apply} />
                    :
                    <CertificationCareerView highLookup={apply} />
                }
            </td>
            <td className="td_info">
              <span className="m_th">재직회사 :&nbsp; </span>
              {apply?.rec_company}
            </td>
            <td className="td_info">
              <span className="m_th">학력 :&nbsp; </span>
              {apply?.reh_name}
            </td>
            <td className="td_info">
            <span className="m_th">심사상태 :&nbsp; </span>
                {apply.ea_checking === -1 ?
                  <select
                    className="style_select"
                    value={apply.ea_checking}
                    onChange={(e) => onCheckingProcessHandler(e, apply.ea_idx)}
                    disabled
                  >
                    {varApplyStatus.map((checking, i) => (
                      checking.ems_idx === -1 ?
                        <option key={"checkingm_" + i} value={checking.ems_idx}>
                          {checking.ems_name}
                        </option>
                        :
                        <option key={"checkingm_" + i} value={checking.ems_idx}>
                          {checking.ems_name}
                        </option>
                    ))}
                  </select>
                  :
                  <select
                    className="style_select"
                    value={apply.ea_checking}
                    onChange={(e) => onCheckingProcessHandler(e, apply.ea_idx)}
                  >
                    {varApplyStatus.map((checking, i) => (
                      checking.ems_idx === -1 ?
                        // <option key={"checkingm_" + i} value={checking.ems_idx} disabled>
                        //   {checking.ems_name}
                        // </option>
                        ""
                        :
                        <option key={"checkingm_" + i} value={checking.ems_idx}>
                          {checking.ems_name}
                        </option>
                    ))}
                  </select>
                }
            </td>
            <td className="td_info">
              <span className="m_th">지원일자 :&nbsp; </span>
              {apply.ea_rdate.substr(0, 10)}
            </td>
            <td className="td_info last">
              <span className="m_th">입사일자 :&nbsp;</span>
              {/* {apply.ea_checking !== 5 ? "" : apply.ea_idate !== null ? apply?.ea_idate :
                <input type="date" className="datePicker" value={apply.ea_idate ? apply.ea_idate : ""} onChange={(e) => joinDateHandler(e, apply.ea_idx)} />
              } */}
              {apply.ea_checking !== 5 ? "" : 
                <>
                  <input type="date" className="datePicker" value={apply.ea_idate ? apply.ea_idate : ""} onChange={(e) => joinDateHandler(e, apply.ea_idx)} max={today}/>
                </>
              }
            </td>
            {/* <td className="td_info last">
              <span className="m_th">입사일자 관리 :&nbsp;</span>
              {apply.ea_checking !== 5 ? "" : 
                <>
                  <button onClick={() => DateUpdate()} className="btn"> 수정 </button>
                </>
              }
            </td> */}
          </tr>
           ))
           : ""}
        </tbody>
      </table>
      <div className="list_bottom">
        <Paging
          totalCount={varTotalCount}
          pageSize={varPageSize}
          curPage={varPageNum}
          setPage={setPageNum}
        />
        <div className="right_button_wrap">
          <Link to={"/Company/ComAnnouncement/SupportList"} className="btn_list_go">
            <button type="button" className="btn line">
              목록으로
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SupportViewC;
