/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import {onDownloadFile} from 'components/Common/Common';

//image
import deleteImage1 from "../../img/sub/btn_delete01.png";

function PortfolioHistory({ index, portfolio, resumePortfolioList, setResumePortfolioList }) {

  //포트폴리오 삭제 ---> 저장된 파일목록에서 삭제표시로 지정
  const onRemoveHandler = (e,rcp_idx) => {
    e.preventDefault();
    if(!window.confirm('선택한 포트폴리오를 삭제합니까?')) return;
    //const finsIndex = resumePortfolioList.findIndex(element => element.rcp_idx === rcp_idx);
    //console.log(finsIndex);
    //array state 수정시 복사하는 이유?
    // let copyData = [...resumePortfolioList];
    // copyData[finsIndex]={...copyData[finsIndex],rcp_status:0};  //삭제표시
    // console.log(copyData);
    // setResumePortfolioList(copyData);
    // 스테이트 변환 로직 (받아온 rcp_idx 파라미터와 같은 값을 찾고 해당 스테이트의 rcp_status 의 value 를 0으로 변경);
    setResumePortfolioList(
      resumePortfolioList.map(
        portfolio => 
        portfolio.rcp_idx === rcp_idx
        ? {...portfolio, 
          rcp_status: 0,
          rcp_state : 0}
        : portfolio
      )
    );
  }
  // const onDownloadFile = async () => {
  //   const res = await fetch(`${CONFIG.SERVER_HOST}/resume_file_down?path=port_folio&file_name=${portfolio.rcp_ufile}`);
  //   const blob = await res.blob();
  //   download(blob, portfolio.rcp_ufile);
  // }

  console.log(portfolio);
  //삭제하기로 지정한 포트폴리오 파일을 화면에서 빼고 보여줌
  if (portfolio.rcp_status === 1) {
    return (
      <div className="portfolio_file" key={'pf'+index}>
        <button onClick={()=>onDownloadFile('port_folio', portfolio.rcp_ufile)} style={{fontSize:'15px'}}  download>{portfolio.rcp_ufile}</button>
        <button className="btn_del" onClick={(e)=>{onRemoveHandler(e,portfolio.rcp_idx)}}>
          <img src={deleteImage1} alt=""/>
        </button>
      </div>
    );
  } else {
    return <></>
  }
}

export default PortfolioHistory;