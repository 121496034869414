/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

/*마이페이지>정보관리(개인)*/
function Main() {
 // const navigator = useNavigate();

  useEffect(() => {
    window.location.href='/main';
  }, []);

  return (
    <></>
  );
}

export default Main;
