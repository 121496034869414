/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import CONFIG from 'configs/configs';


function ConsultingWrite({varInput, onCheckedHandler, varPopupResumeList, onSelectedResumeHandler, onChangeHandler}) {
  const resumePer = (per) => {
    if(per === 0){
      return "step0";
    }else if(per === 25){
      return "step1";
    }else if(per === 50){
      return "step2";
    }else if(per === 75){
      return "step3";
    }else{
      return "step4";
    }
  }


  return (
    <div className="box_content">
        <h3 className="my_title">컨설팅 신청하기</h3>
        <p className="my_subtitle">컨설팅 받기를 원하시는 항목을 선택해주세요. (다중선택 가능합니다.)</p>
        <table className="table_view01">
          <tbody>
            <tr>
                <td>
                <p><input type="checkbox" className="chk" id="list1" name="consult_heding" checked={varInput.consult_heding} onChange={e=>onCheckedHandler(e)}/><label htmlFor="list1"> 헤딩 이직 & 이력서 컨설팅</label></p>
                <p><input type="checkbox" className="chk" id="list2" name="consult_mba" checked={varInput.consult_mba} onChange={e=>onCheckedHandler(e)}/><label htmlFor="list2"> 국내 MBA 컨설팅</label></p>
                </td>
            </tr>
          </tbody>
        </table>
        {/* 이력서 목록 */}
        <h3 className="my_title">이력서 선택하기</h3>
        <section className="resume_box_area">
            {varPopupResumeList?.map((resumes, i) => (
            <article className="radio_wrap" key={"resume_"+i}>
                <input type="radio" id={"radio"+resumes?.re_idx} name="radio" onChange={() => onSelectedResumeHandler(resumes?.re_idx)} checked={Number(varInput.re_idx) === Number(resumes.re_idx) ? true : false}/>
                <label htmlFor={"radio"+resumes?.re_idx} className="resume_box">
                <section className="v_box_wrap">
                    <i className="v_box"></i>
                </section>
                <section className="resume_box_content">
                    <div className="resume_box_top">
                    <p className="title_field">{resumes.re_title === '' || resumes?.title === null ? CONFIG.temporary_title : resumes.re_title }</p>
                        <span className="table_notice_badge">{resumes?.re_fix===1?<div className="icon_tit upper_right" style={{float:'right'}}>대표</div>:null}</span>
                    </div>
                    <div className={"resume_box_bottom step " + resumePer(resumes?.re_perfection)}>
                    <p className="title_field">이력서 완성 &nbsp; <b>{resumes?.re_perfection + "%"}</b></p>
                    <article className="resume_progress">
                        <div className="resume_progress_bar"></div>
                    </article>
                    </div>
                </section>
                </label>
            </article>
            ))}
        </section>
        <article className="agree_area">
            <div className="left_wrap">
                <p>헤딩에서 컨설팅을 진행하면서 연락처가 공개되는 것에 동의합니다.</p>
                <p className="tip_field">※ 미동의시 컨설팅 진행이 어렵습니다.</p>
            </div>
            <div className="right_wrap">
                <input type="radio" id="agree_radio1" name="agree_radio" className="chk square" checked={varInput.agree ? true : false} onChange={() => onChangeHandler(true)} />
                <label htmlFor="agree_radio1" className="radio">예</label>
                <input type="radio" id="agree_radio2" name="agree_radio" className="chk square" checked={!varInput.agree ? true : false} onChange={() => onChangeHandler(false)}/>
                <label htmlFor="agree_radio2">아니요</label>
            </div>
        </article>
    </div>
  )
}

export default ConsultingWrite
