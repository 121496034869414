import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';

//css
import "css/Board.css";

//컴포넌트
import MyMoneyTop from 'components/HedingMoney/MyMoneyTop';
import MyMoneyList from 'components/HedingMoney/MyMoneyList';
import { resetNavSubMenu } from "components/Common/Common";

function MyHeding7({ getSubItem }) {
  const user_idx = localStorage.getItem('mem_idx');
  const [varHMoneyBalance, setHMoneyBalance] = useState(0);
  //서브회원번호
  const [varSubMemberIndex, setSubMemberIndex] = useState(user_idx);
  const mem_mode = Number(localStorage.getItem('mem_mode'));

  const getHedingMoneyBalance = async () => {
    try {
      const params = new URLSearchParams();
      params.append("user_idx", varSubMemberIndex);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_balance2`, params).then((res) => {
        if (res.data.retvalue === 1) {
          if (res.data.results.length > 0) {
            setHMoneyBalance(res.data.results[0].mh_paid);
          }
        } else {
          setHMoneyBalance(0);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    getSubItem(7);
    if(mem_mode=== 0){
      resetNavSubMenu(4,7);
    }else{
      resetNavSubMenu(2,7);
    }
    getHedingMoneyBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //서브회원이 선택되면
  useEffect(() => {
    getHedingMoneyBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSubMemberIndex]);


  return (
    <div className="container page_money">
      <MyMoneyTop varHMoneyBalance={varHMoneyBalance} setSubMemberIndex={setSubMemberIndex} user_idx={user_idx}/>
      <MyMoneyList varSubMemberIndex={varSubMemberIndex} />
    </div>
  );
}

export default MyHeding7;