import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
//css
import "css/Common.css";
import "css/Sub.css";
import "css/My.css";
//components
import { resetNavMenu } from "../../components/Common/Common";
import Header from "components/Layout/Header";
// import SubVisual from "components/Layout/SubVisual";
import Footer from "components/Layout/Footer";
import MyMenu from "components/Layout/MyMenu";
import MyHeding1 from "components/My/MyHeding1";
import MyHeding2 from "components/My/MyHeding2";
import MyHeding3 from "components/My/MyHeding3";
import MyHeding4 from "components/My/MyHeding4";
import MyHeding5 from "components/My/MyHeding5";
import MyHeding6 from "components/My/MyHeding6";
import MyHeding7 from "components/My/MyHeding7";
import MyHeding8 from "components/My/MyHeding8";
// import MyHeding9 from "components/My/MyHeding9";
import MyHeding10 from "components/My/MyHeding10";
import MyHeding11 from "components/My/MyHeding11";
import MyHeding12 from "components/My/MyHeding12";
// import MyHeding13 from "components/My/MyHeding13";
import NoticeView from "components/Board/NoticeView";
import MyMoneyChangeWrite from "components/HedingMoney/MyMoneyChangeWrite";
import MyMoneyChangeView from "components/HedingMoney/MyMoneyChangeView";
import MyMoneyView from "components/HedingMoney/MyMoneyView";
import BannerBottom from "components/Banner/BannerBottom";

import CompanyMenu from 'components/Layout//CompanyMenuOfUser';
import CMyHeding1 from 'components/Company/MyHeding1';
import CMyHeding1sub from 'components/Company/MyHeding1sub';
import CMyHeding2 from 'components/Company/MyHeding2';
import CMyHeding3 from 'components/Company/MyHeding3';
import CMyHeding4 from 'components/Company/MyHeding4';
import CMyHeding5 from 'components/Company/MyHeding5';
import CMyHeding6 from 'components/Company/MyHeding6';
import CMyHeding7 from 'components/Company/MyHeding7';
import CMyHeding8 from 'components/Company/MyHeding8';
import CMyHeding9 from 'components/Company/MyHeding9';
import CMyHeding10 from 'components/Company/MyHeding10';
import CMyHeding11 from 'components/Company/MyHeding11';
import CMyHeding12 from "components/Company/MyHeding12";
import CMyHeding13 from "components/Company/MyHeding13";
import CMyMoneyChangeWrite from "components/HedingMoney/MyMoneyChangeWrite";
import CMyMoneyChangeView from "components/HedingMoney/MyMoneyChangeView";
import CMyMoneyView from "components/HedingMoney/MyMoneyView";

function MyHeding() {
  const user_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const navigator = useNavigate();
  const memlogin = localStorage.getItem('memlogin');
  //   const mem_hunter_type = Number(localStorage.getItem('mem_hunter_type'));
  //   console.log(mem_hunter_type)

  useEffect(() => {
    if (user_idx === undefined || user_idx === null || user_idx === 0 || memlogin === undefined || memlogin === null || memlogin === false) {
      alert("로그인 후 이용 가능합니다.");
      navigator("/Login");
    }
    // resetNavMenu(5);
    // resetNavMenu(4);
    if(mem_mode=== 0){
      resetNavMenu(4);
    }else{
      resetNavMenu(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_idx, navigator]);

  const [varSubItems, setSubItems] = useState(1);

  const [NumberReset, setNumberReset] = useState(false);

  const getSubItem = (varSubItems) => {
    setSubItems(varSubItems);
  };

  const NumReset = () =>{
    setNumberReset(!NumberReset);
  };

  console.log('NumberReset :', NumberReset);
  return (
    <>
      <Header NumberReset={NumberReset} setNumberReset={setNumberReset} />
      {/* <SubVisual PageTitle={"MyHEDING"} SubVisual_className="type6"/> */}
      {/*전달하는 문자+백이미지로 상부에 표시*/}
      {/*전체 페이지*/}
      <div id="sub_wrap" className="center_wrap layout_my">
        <h2 className="sub_content_title">My HEDING</h2>
        {mem_mode === 0 ?

          <>
            <MyMenu varSubItems={varSubItems} getSubItem={getSubItem} />
            {/*좌측메뉴*/}
            <Routes>
              {/* <Route path="/" element={<MyHeding1 getSubItem={getSubItem} />} /> */}
              <Route path="MyHeding1" element={<MyHeding1 getSubItem={getSubItem} />} />
              <Route path="MyHeding2" element={<MyHeding2 getSubItem={getSubItem} />} />
              <Route path="MyHeding3" element={<MyHeding3 getSubItem={getSubItem} />} />
              <Route path="MyHeding3/:crsIdx" element={<MyHeding3 getSubItem={getSubItem} />} />
              <Route path="MyHeding4" element={<MyHeding4 getSubItem={getSubItem} />} />
              <Route path="MyHeding4/:roomIdx" element={<MyHeding4 getSubItem={getSubItem} />} />
              <Route path="MyHeding5" element={<MyHeding5 getSubItem={getSubItem} />} />
              <Route path="MyHeding6" element={<MyHeding6 getSubItem={getSubItem} />} />
              <Route path="MyHeding7" element={<MyHeding7 getSubItem={getSubItem} />} />
              <Route path="MyHeding12" element={<MyHeding12 getSubItem={getSubItem} />} />
              <Route path="MyHeding8" element={<MyHeding8 getSubItem={getSubItem} NumReset={NumReset} />} />
              {/* <Route path="MyHeding9" element={<MyHeding9 getSubItem={getSubItem} />} /> */} 
              <Route path="MyHeding9" element={<MyHeding10 getSubItem={getSubItem} />} />
              <Route path="MyHeding10" element={<MyHeding11 getSubItem={getSubItem} />} />
              {/* <Route path="MyHeding13" element={<MyHeding13 getSubItem={getSubItem} />} /> */}
              <Route path="MyHeding13/NoticeView/:board_idx" element={<NoticeView getSubItem={getSubItem} />} />
              <Route path="MyMoneyChangeWrite" element={<MyMoneyChangeWrite getSubItem={getSubItem} backPage={7} />} />
              <Route path="MyMoneyChangeView" element={<MyMoneyChangeView getSubItem={getSubItem} backPage={7} />} />
              <Route path="MyMoneyView" element={<MyMoneyView getSubItem={getSubItem} backPage={7} />} />
            </Routes>
          </>
          :
          <>
            <CompanyMenu varSubItems={varSubItems} getSubItem={getSubItem} />
            {/*좌측메뉴*/}
            <Routes>
              <Route path="/" element={<CMyHeding1 getSubItem={getSubItem} />} />
              <Route path="MyHeding1" element={<CMyHeding1 getSubItem={getSubItem} />} />
              <Route path="MyHeding1sub" element={<CMyHeding1sub getSubItem={getSubItem} />} />
              <Route path="MyHeding2" element={<CMyHeding2 getSubItem={getSubItem} />} />
              <Route path="MyHeding3" element={<CMyHeding3 getSubItem={getSubItem} />} />
              <Route path="MyHeding4" element={<CMyHeding4 getSubItem={getSubItem} />} />
              <Route path="MyHeding5" element={<CMyHeding5 getSubItem={getSubItem} />} />
              <Route path="MyHeding6" element={<CMyHeding6 getSubItem={getSubItem} />} />
              <Route path="MyHeding7" element={<CMyHeding7 getSubItem={getSubItem} NumReset={NumReset} />} />
              <Route path="MyHeding8" element={<CMyHeding8 getSubItem={getSubItem} />} />
              <Route path="MyHeding9" element={<CMyHeding9 getSubItem={getSubItem} />} />
              <Route path="MyHeding10" element={<CMyHeding10 getSubItem={getSubItem} />} />
              <Route path="MyHeding11" element={<CMyHeding11 getSubItem={getSubItem} />} />
              <Route path="MyHeding12" element={<CMyHeding12 getSubItem={getSubItem} />} />
              <Route path="MyHeding13" element={<CMyHeding13 getSubItem={getSubItem} />} />
              <Route path="MyHeding13/NoticeView/:board_idx" element={<NoticeView getSubItem={getSubItem} />} />
              <Route path="MyMoneyChangeWrite" element={<CMyMoneyChangeWrite getSubItem={getSubItem} backPage={6} />} />
              <Route path="MyMoneyChangeView" element={<CMyMoneyChangeView getSubItem={getSubItem} backPage={6} />} />
              <Route path="MyMoneyView" element={<CMyMoneyView getSubItem={getSubItem} backPage={6} />} />
            </Routes>
          </>

        }

      </div>
      <BannerBottom />
      <Footer />
    </>
  );
}

export default MyHeding;
