/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CONFIG from '../../configs/configs'
// import { Link } from 'react-router-dom'

import Paging from '../../components/Layout/Paging'
import { ChangeDateString, resetNavSubMenu, em_titleLength } from '../Common/Common'
import CertificationCareerView from "./CertificationCareer";
import AddCareerView from "./AddCareer";

const CandidateC = ({ setTabPage, varUsingPass, setShowPopupSuggest, onViewResumePage, onCallChating, ResetUsingPass, setResumeIndex, setSugEmIndex }) => {
  //const mem_idx = localStorage.getItem('mem_idx');
  const mcm_idx = localStorage.getItem('mcm_idx');
  const mem_idx = localStorage.getItem('mem_idx'); // 기업번호
  const com_idx = localStorage.getItem('com_idx'); // 기업 대표 회원번호
  //loading 유무
  const [varLoadingFinshed, setLoadingFinshed] = useState(1);
  //서버의 저장폴더
  const imageFoler = `${CONFIG.SERVER_HOST}/user_photo/`;

  const [varEmployLists, setEmployLists] = useState([]);
  const [varSortedEmployLists, setSortedEmployLists] = useState([]);
  const [varResumeLists, setResumeLists] = useState([]);
  const [varSortedResumeLists, setSortedResumeLists] = useState([]);

  const [varEmployStatusOption, setEmployStatusOption] = useState('진행중');
  const [varSortOptionEmploy, setSortOptionEmploy] = useState(0);
  /* const [varSortOptionResume,setSortOptionResume] = useState(0); */

  //우측부분
  const [varCompanyMembers, setCompanyMembers] = useState([]);
  const [varEmployIndex, setEmployIndex] = useState(0);
  const [varCompanyMemberIndex, setCompanyMemberIndex] = useState(0);  //제안보낸 직원번호
  const [varEmployTitle, setEmployTitle] = useState('');

  //페이지
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  //최초 모든 이력서 가져오기
  const getEmployLists = async () => {
    try {
      // console.log('mcm_idx',mcm_idx);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      params.append("com_idx", com_idx);
      //params.append("mem_idx", mem_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_employ`, params).then((res) => {
        // console.log(res.data);
        if (res.data.retvalue === 1) {
          console.log(res.data.employList);
          //변수값 - 촤측 채용 목록
          setEmployLists(res.data.employList);
          //목록에 뿌려지는 데이터
          setSortedEmployLists(res.data.employList.filter(element => element.em_status === 1));
          //전체 이력서 - 후보자

          const list = res.data.resumeList.map((e) => {
            const rec_company_name = e.rec_company ? e.rec_company : e.rac_company ? e.rac_company : '';
            const rec_department_name = e.rec_department ? e.rec_department : e.rac_department ? e.rac_department : '';
            const rec_position_name = e.rec_position ? e.rec_position : e.rac_position ? e.rac_position : '';
            return {
              ...e,
              rec_company_name: rec_company_name.split(',')[0],
              rec_department_name: rec_department_name.split(',')[0],
              rec_position_name: rec_position_name.split(',')[0]

            };
          });
          setResumeLists(list);
          //기업내 직원들
          setCompanyMembers(res.data.members);
          //채용공고가 있으면 --> 첫번째 채용에 대한 이력서 호출
          if (res.data.employList > 0) {
            const employDatas = res.data.employList;
            const firstEmploy = employDatas[0].em_idx;
            setSortedResumeLists(employDatas.filter(element => element.em_idx === firstEmploy));
          }
        } else {
          setEmployLists([]);
          setResumeLists([]);
        }
        setLoadingFinshed(0);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    setTabPage(1);
    resetNavSubMenu(2, 1);
    document.getElementById('sub_wrap').className = 'page_candidate_management';
    getEmployLists();
    ResetUsingPass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //채용목록 - 메뉴좌측-----
  //정렬변경
  const onSortingEmployList = (e) => {
    const isort = Number(e.currentTarget.value);
    const sortData = [...varSortedEmployLists]; //리액트에서는 데이타를 복사해서 해야 함
    sortData.sort(function (a, b) {
      if (isort === 0) {  //최신순
        const sdate = new Date(a.em_rdate);
        const edate = new Date(b.em_rdate);
        return edate - sdate;
      } else {  //후보자수
        return b.tcount - a.tcount;
      }
    });
    setSortOptionEmploy(isort);
    setSortedEmployLists(sortData);
  }
  //진행=1/마감=2 변경시
  const onFilteringEmployList = (e, imode) => {
    e.preventDefault();
    const employOptionBlock = document.querySelectorAll('.state_tab > li > a');
    console.log(employOptionBlock[0].parentNode);

    if (imode === 1) {
      setEmployStatusOption('진행중');
      if (!employOptionBlock[0].parentNode.classList.contains('active')) employOptionBlock[0].parentNode.classList.add('active');
      if (employOptionBlock[1].parentNode.classList.contains('active')) employOptionBlock[1].parentNode.classList.remove('active');
    } else {
      setEmployStatusOption('마감');
      if (employOptionBlock[0].parentNode.classList.contains('active')) employOptionBlock[0].parentNode.classList.remove('active');
      if (!employOptionBlock[1].parentNode.classList.contains('active')) employOptionBlock[1].parentNode.classList.add('active');
    }
    console.log(imode);
    const sortData = varEmployLists.filter(element => element.em_status === imode);
    sortData.sort(function (a, b) {
      if (varSortOptionEmploy === 0) {  //최신순
        const sdate = new Date(a.em_rdate);
        const edate = new Date(b.em_rdate);
        return edate - sdate;
      } else {  //후보자수
        return b.tcount - a.tcount;
      }
    });
    setSortedEmployLists(sortData);
    //우측 이력서 목록 초기화
    setEmployTitle('');

    setSortedResumeLists([]);
  }
  //채용공고 선택시 --> 우측부 표시
  const onShowingResumeList = (e, em_idx) => {
    e.preventDefault();

    //우측 목록에 공고 제목
    const employData = varEmployLists.filter(element => element.em_idx === em_idx);
    setEmployTitle(employData[0].em_title);

    setEmployIndex(em_idx);
    const sortData = varResumeLists.filter(element => element.em_idx === em_idx);
    console.log("sortData", sortData);

    setTotalCount(sortData.length);
    setSortedResumeLists(sortData);
  }

  //직원선택시 -- 우측부 이력서 재표시
  const onChangeCompanyMember = (e) => {
    const com_idx = Number(e.currentTarget.value);
    setCompanyMemberIndex(com_idx);
    //console.log('com_idx',com_idx);
  }

  const filteringResumes = () => {
    //채용공고별
    var sortData = varResumeLists.filter(element => element.em_idx === varEmployIndex);
    //제안한 직원별
    //console.log('com_idx2',varCompanyMemberIndex);
    if (varCompanyMemberIndex > 0) {
      sortData = sortData.filter(element => element.com_idx === varCompanyMemberIndex);
    }
    setTotalCount(sortData.length);
    //페이지
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    sortData = sortData.slice(startNum, endNum);
    setSortedResumeLists(sortData);
  }
  useEffect(() => {
    filteringResumes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varCompanyMemberIndex, varPageSize]);


  //제안보내기 
  const onSendingSuggestion = (e, re_idx, em_idx) => {
    if (varUsingPass.mcc_sending <= 0) {
      window.alert('보유한 제안발송건수가 없습니다. 제안발송을 하시려면 이용권을 먼저 구입하십시오.'); return;
    }
    setResumeIndex(re_idx); //이력서 번호 저장
    setSugEmIndex(em_idx);//채용 번호 저장
    setShowPopupSuggest('popup active');
  }

  //후보자 체크
  const onCheckHandler = (e, emc_idx) => {
    const bmode = Number(e.currentTarget.checked);
    var indexNum = varSortedResumeLists.findIndex(element => element.emc_idx === emc_idx);
    const copyData = [...varSortedResumeLists]
    copyData[indexNum] = { ...copyData[indexNum], chkItem: bmode };
    setSortedResumeLists(copyData);
  }

  //후보자 선택 제외
  const onRemoveCandicate = (e) => {
    let candidateArray = [];
    console.log(varSortedResumeLists);
    const ArrayCount = varSortedResumeLists.length;
    let removeCount = 0;
    for (var i = 0; i < ArrayCount; i++) {
      if (varSortedResumeLists[i].chkItem) {
        removeCount++;
        candidateArray.push(varSortedResumeLists[i].emc_idx);
      }
    }
    const params = new URLSearchParams();
    params.append("emc_idx", JSON.stringify(candidateArray));
    axios.post(`${CONFIG.SERVER_HOST}/_remove_candidate`, params).then(res => {
      if (res.data.retvalue === 1) {
        //우측부에서 삭제
        let copyData1 = [...varSortedResumeLists];
        copyData1 = copyData1.filter(el => el.chkItem === 0);
        setSortedResumeLists(copyData1);

        //좌측부 삭제
        let copyData2 = [...varSortedEmployLists];
        const findIndex = copyData2.findIndex(el => el.em_idx === varEmployIndex);
        let ncount = copyData2[findIndex].tcount - removeCount;
        if (ncount === 0) {
          copyData2 = copyData2.filter(el => el.em_idx !== varEmployIndex);
        } else {
          copyData2[findIndex] = { ...copyData2[findIndex], tcount: ncount };
        }
        setSortedEmployLists(copyData2);
      } else {
        alert(res.data.message);
      }
    })
  }

  return (
    varLoadingFinshed === 1 ? null :
      <>
        <aside className="aside">
          <article className="top">
            <ul className="state_tab">
              <li className="active"><a onClick={(e) => onFilteringEmployList(e, 1)}>진행중</a></li>
              <li><a onClick={(e) => onFilteringEmployList(e, 2)}>마감</a></li>
            </ul>
            <select className="style_select" value={varSortOptionEmploy} onChange={(e) => onSortingEmployList(e)}>
              <option value="0">최신순</option>
              <option value="1">후보자 수</option>
            </select>
          </article>
          <article className="content_sec scroll">
            <ul className="list">
              {varSortedEmployLists.length === 0 ? <li><p className="title_field">후보자가 추천된 채용공고가 없습니다.</p></li>
                : varSortedEmployLists.map((employs, index) => (
                  <li key={'cadlist_' + index} style={{ cursor: "pointer" }} onClick={(e) => onShowingResumeList(e, employs.em_idx)}>
                    {/* {console.log(employs.em_careers)} */}
                    <p className="title_field">{employs.em_careers === 0 ? "[경력 무관] " : employs.em_careers === 1 ? "[신입] " : "[경력] "}{employs.em_title}</p>
                    <p className="num_field">{employs.tcount} 명</p>
                  </li>
                ))}
            </ul>
          </article>
        </aside>
        <section className="container">
          <section className="candidate_top">
            <article className="title_field">
              <span className="state_field">{varEmployStatusOption}</span>&nbsp;&nbsp;{em_titleLength(varEmployTitle, 30, '...')}
            </article>
            <article className="input_wrap">
              담당자
              <select defaultValue={'0'} onChange={(e) => onChangeCompanyMember(e)}>
                <option value="0">전체</option>
                {varCompanyMembers.map((members, index) => (
                  <option key={'memb_' + index} value={members.cmm_mem_idx}>{members.mem_id}</option>
                ))}
              </select>
            </article>
          </section>
          <section className="table_wrap">
            <table className="table_list01 with_chk">
              <thead>
                <tr>
                  <th className="th_chk"></th>
                  <th className="th_candidate">후보자명</th>
                  <th>담당자</th>
                  <th>제안 상태</th>
                  <th>이력서 업데이트</th>
                  <th className="th_button">관리</th>
                </tr>
              </thead>
              <tbody>
                {varSortedResumeLists.map((resume, index) => (
                  <tr key={"resume_" + index} >
                    <td className="td_chk"><input type="checkbox" className="chk" id={`list${index + 1}`} onChange={(e) => onCheckHandler(e, resume.emc_idx)} /><label htmlFor={`list${index + 1}`}></label></td>
                    <td className="td_candidate">
                      <div className="profile">
                        {resume.mem_photo === 0 ? <div className="img"></div>
                          : <div className="img" style={{ backgroundImage: `url(${imageFoler + resume.mem_ufile})` }}></div>
                        }
                        {/* <Link to="/Company/ComResource/SearchView" state={{re_idx:resume.re_idx}}> */}
                        <div className="candidate_area">
                          <p className="name">{resume.mem_name}</p>
                          <p className="area">{resume.re_place_hope}</p>
                          <p className="career">

                            {/* {resetCareerForSearching(resume.re_career_year)} */}
                            {
                              !resume?.certification_experience_year && !resume?.certification_experience_year ?
                                <AddCareerView highLookup={resume} />
                                :
                                <CertificationCareerView highLookup={resume} />
                            }
                          </p>
                          {resume.rec_company_name ? <p className="company">{resume.rec_company_name}</p> : ``}
                          <p className="grade">{resume?.rec_department_name ? resume?.rec_department_name : ""}{resume?.rec_position_name ? ` ${resume?.rec_position_name}` : ""}</p>
                          
                          {/* {resume.rec_company === null ? null :
                            <p className="company">{resume.rec_company}</p>
                          } */}
                          <p className="shcool">{resume.reh_name}&nbsp;{resume.reh_major}&nbsp;
                            {resume.reh_state === 1 ? '졸업' : resume.reh_state === 2 ? '졸업예정' : resume.reh_state === 3 ? '재학중' : resume.reh_state === 4 ? '중퇴' : resume.reh_state === 5 ? '수료' : resume.reh_state === 6 ? '휴학' : ""}  </p>
                        </div>
                        {/* </Link> */}
                      </div>
                    </td>
                    <td className="td_info staff"><span className="field_tit">담당자 : </span>{resume.mem_id === null ? "--" : resume.mem_id}</td>
                    <td className="td_info"><span className="field_tit">제안상태 : </span><span className={resume.crs_status === 1 ? "state1" : resume.crs_status === 2 ? "state1" : "state2"}>{resume.crs_status == null ? "제안 미발송" : resume.crs_status === 1 ? "제안 발송" : resume.crs_status === 2 ? "제안 수락" : "제안 거절"}</span></td>
                    <td className="td_info last"><span className="field_tit">이력서 업데이트 : </span>{resume.re_udate === null ? ChangeDateString(resume.re_rdate.substr(0, 10)) : ChangeDateString(resume.re_udate.substr(0, 10))}</td>
                    <td className="td_button">
                      {resume.crs_status !== null ? null : <button className="btn white" onClick={(e) => onSendingSuggestion(e, resume.re_idx, resume.em_idx)}>제안보내기</button>}
                      <button className="btn basics" onClick={(e) => onViewResumePage(e, resume.re_idx, 1)}>이력서 보기</button>
                      <button className="btn" onClick={() => onCallChating(resume.mem_idx, resume.re_idx)}>1:1 채팅</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <div className="board_bottom_btn"><button className="btn white" onClick={(e) => onRemoveCandicate(e)}>선택 후보자 제외</button></div>
          <div className="paging">
            <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
        </section>
      </>
  )
}

export default CandidateC