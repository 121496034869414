import React, { useEffect, useState }from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

//components
import CONFIG from 'configs/configs';
//이미지

/*공고 공유 현황*/
function MyShare() {
    const user_idx = localStorage.getItem('mem_idx')

    const [varRecommendData, setRecommendData] = useState({
        send_recommend_num: 0,
        send_recommend_apply: 0,
        rece_recommend_num: 0,
        rece_recommend_apply: 0
    });

    useEffect(() => {
        console.log('user_idx',user_idx);
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_share`,params).then((res) => {
            if(res.data.retvalue === 1) {
                setRecommendData({
                    send_recommend_num: res.data.tcount,
                    send_recommend_apply: res.data.scount,
                    rece_recommend_num: res.data.rcount,
                    rece_recommend_apply: res.data.pcount
                });
            } else {
                window.alert('검색된 정보가 없습니다.');
            }
        });  
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

  return (
    <section className="my_recommend">
        <h3 className="title">추천현황</h3>
        <ul className="state_wrap">
            <li className="state_box">
                <h4 className="title">추천자 수</h4>
                <Link to={"/MyHeding/MyHeding5"} state={{status:0}}><p className="num point2">{varRecommendData?.send_recommend_num}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">지원자 수</h4>
                <Link to={"/MyHeding/MyHeding5"} state={{status:1}}><p className="num point2">{varRecommendData?.send_recommend_apply}</p></Link>
            </li>
        </ul>
    </section>
  );
}

export default MyShare;
