/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';

//css
import "css/Board.css";

//컴포넌트
import {changeMobileFormat, ChangeMemberTypeString, addThousandPoint,/*,ChangeDateString*/include_num, onlyKorean} from '../Common/Common';

function MyMoneyChangeWrite({ getSubItem, backPage }) {
    let navigate = useNavigate();
    const location = useLocation();
    let {varHMoneyBalance} = location?.state?.varHMoneyBalance ? location?.state?.varHMoneyBalance : 0;
    let {varHMoneyExchange} = location?.state?.varHMoneyExchange ? location?.state?.varHMoneyExchange : 0;
    //정보읽기
    const user_idx = localStorage.getItem('mem_idx');
    //변수설정
    const [varExchangeMoney, setExchangeMoney] = useState(0);
    const [varCommissionMoney, setCommissionMoney] = useState(0);
    const [varTaxMoney, setTaxMoney] = useState(0);
    const [varRealExchangeMoney,setRealExchangeMoney] = useState(0);
    const [varUserHmoney, setUserHmoney] = useState([]);

    //은행관련 정보
    const [varBanks, setBanks] = useState([]);
    const [varCurrentBanks, setCurrentBanks] = useState(0);
    const [BankData, setBankData] = useState(false);
    const [varBankInfo, setBankInfo] = useState({
        bl_idx: 0,
        bank_name: '',
        bank_account: '',
        bank_owner: '',
        social_exist: 0,
        social_id1: '',
        social_id2: '',
        balance: 0,
        exchange: 0
    });
    //환정관련정보
    const [varExchangeInfo, setExchangeInfo] = useState({
        mem_idx: 0,
        mem_name: '',
        mem_nickname: '',
        mem_mobile: '',
        mem_modeString: 0,
        balance: 0,
        pdate: null,
        request_exchange: 0,
        commission: 0,
        tax: 0,
        pay_exchange: 0
    });

    //시작할 경우
    const getRequestHedingMoneyExchange = async () => {
        try {
            const params = new URLSearchParams();
            params.append("user_idx", user_idx);
            await axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_request`, params).then((res) => {
                if (res.data.retvalue === 1) {
                    var bankInfo = { ...varBankInfo };
                    var exchangeInfo = { ...varExchangeInfo };

                    if (res.data.bankinfos.length > 0) {
                        var bankInfos = res.data.bankinfos[0];
                        bankInfo.bl_idx = bankInfos.bl_idx;
                        bankInfo.bank_name = bankInfos.bl_name;
                        bankInfo.bank_account = bankInfos.mc_account;
                        bankInfo.bank_owner = bankInfos.mc_owner;
                        if (bankInfos.mem_nid !== null) {
                            const social = bankInfos.mem_nid.split('-');
                            bankInfo.social_id1 = social[0];
                            bankInfo.social_id2 = social[1];
                            bankInfo.social_exist = 1;
                        }
                        bankInfo.balance = bankInfos.mc_balance;
                        bankInfo.exchange = bankInfos.mc_exchange;

                        exchangeInfo.mem_idx = bankInfos.mem_idx;
                        exchangeInfo.mem_name = bankInfos.mem_name;
                        exchangeInfo.mem_nickname = bankInfos.mem_nickname;
                        exchangeInfo.mem_mobile = changeMobileFormat(bankInfos.mem_mobile);
                        exchangeInfo.mem_modeString = ChangeMemberTypeString(bankInfos.mem_mode, bankInfos.mem_hunter_type);
                        exchangeInfo.balance = bankInfos.mh_balance;
                        exchangeInfo.pdate = bankInfos.mh_pdate;
                        exchangeInfo.request_exchange = 0;
                        exchangeInfo.commission = 0;
                        exchangeInfo.tax = 0;
                        exchangeInfo.pay_exchange = 0;

                        if (bankInfo) {
                            setBankData(true);
                        }

                        setBankInfo(bankInfo);
                        setExchangeInfo(exchangeInfo);
                        setExchangeMoney(0);
                        setCommissionMoney(0);
                        setTaxMoney(0);
                        //setRealExchangeMoney(0);
                        setCurrentBanks(bankInfos.bl_idx);
                    }
                        //회원 헤딩머니
                        if (res.data.hedingmoneys.length > 0) {
                          setUserHmoney(res.data.hedingmoneys);
                        }

                    if (res.data.banks.length > 0) {
                        setBanks(res.data.banks);
                    }

                } else {
                    setBankInfo({});
                    setExchangeInfo({});
                    setBanks([]);
                    setBankData(false);
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getSubItem(backPage);
        getRequestHedingMoneyExchange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //은행선택
    const onSelectBank = (e) => {
        const bankInx = e.currentTarget.value;
        if (bankInx === 0) {
            setBankInfo({ ...varBankInfo, bl_idx: 0, bank_name: '' });
        } else {
            const bankName = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            setCurrentBanks(bankInx);
            setBankInfo({ ...varBankInfo, bl_idx: bankInx, bank_name: bankName });
        }
    }
    //계좌번호,계좌주,주민등록번호1,2
    const onChangeHandler = (e) => {
        const { name,value } = e.target;
        setBankInfo({ ...varBankInfo, [name]: value });
    }
    //환전금 입력
    const onChangeRequestExchange = (e) => {
        const requestExchange = e.currentTarget.value.replace(/,/g, '');
        if (include_num(requestExchange)) {
            alert('숫자만 입력해주세요');
            setExchangeMoney(0);
            return;
        }
        if(Number(requestExchange) > varHMoneyExchange) {
            alert('환전가능금액을 초과했습니다.');
            e.currentTarget.value = addThousandPoint(requestExchange.substr(0,requestExchange.length-1));
            return;
        }
        const requestExchangeString = addThousandPoint(requestExchange);
        const requestExchangeNumber = Number(requestExchange);

        //계산하기
        const commission = requestExchangeNumber * 0.1;
        const tax = Math.floor((requestExchangeNumber-commission) * 0.033);
        const pay_exchange = requestExchangeNumber - commission - tax;
        const balance = varBankInfo.balance - requestExchangeNumber;

        setExchangeMoney(requestExchangeString);
        setCommissionMoney(addThousandPoint(commission));
        setTaxMoney(addThousandPoint(tax));
        setRealExchangeMoney(addThousandPoint(pay_exchange));

        setExchangeInfo({ ...varExchangeInfo, request_exchange: requestExchangeNumber, commission: commission, tax: tax, pay_exchange: pay_exchange, balance: balance });

    }
    //계좌정보 저장
    const onUpdateAccount = async (e) => {
        e.preventDefault();
        if(varBankInfo.bl_idx === null || varBankInfo.bl_idx === "0"){
          alert('은행을 선택해주세요.');
          return;
        }
        if(varBankInfo.bank_account === null){
          alert('계좌번호를 입력해주세요.');
          return;
        }
        if(include_num(varBankInfo.bank_account)){
          alert('계좌번호 입력란에 숫자만 입력해주세요.');
          return;
        }
        if(varBankInfo.bank_account.length < 11){
          alert('계좌번호를 최소 11자리 이상으로 입력해주세요.');
          return;
        }
        if(varBankInfo.bank_owner === null || varBankInfo.bank_owner === ""){
          alert('예금주를 입력해주세요.');
          return;
        }
        if(varBankInfo.bank_owner){
          if(!onlyKorean(varBankInfo.bank_owner)){
            alert('한글만 입력해주세요.');
            return;
          }
        }
        if(varBankInfo.social_id1 === null || varBankInfo.social_id1 === ""){
          alert('주민등록번호 앞자리를 입력해주세요.');
          return;
        }
        if(varBankInfo.social_id1){
          if (include_num(varBankInfo.social_id1)) {
            alert('숫자만 입력해주세요.');
            return;
          }
          if(varBankInfo.social_id1.length !== 6){
            alert('생년월일 6자리를 입력해주세요.')
            return;
          }
        }
        if(varBankInfo.social_id2 === null || varBankInfo.social_id2 === ""){
          alert('주민등록번호 뒷자리를 입력해주세요.');
          return;
        }
        if(varBankInfo.social_id2){
          if (include_num(varBankInfo.social_id2)) {
            alert('숫자만 입력해주세요.');
            return;
          }
          if(varBankInfo.social_id2.length !== 7){
            alert('주민등록번호 뒷자리 7자리를 입력해주세요.')
            return;
          }
        }
        try {
            const params = new URLSearchParams();
            params.append("user_idx", user_idx);
            params.append("bankinfo", JSON.stringify(varBankInfo));
            await axios.post(`${CONFIG.SERVER_HOST}/_update_bankinfo_hedingmoney`, params).then((res) => {
                if (res.data.retvalue === 1) {
                  alert(res.data.message)
                  setBankData(true);
                } else {
                    alert(res.data.message);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    //환전요청하기
    const onRequestExchange = async (e) => {
        if(BankData === false){
            alert('계좌정보를 먼저 등록해주세요.');
            return;
        } else
        if(varBankInfo?.bank_account === null){
          alert('계좌정보를 먼저 등록해주세요.');
            return;
        } else
        if( varBankInfo.bl_idx === null){
          alert('은행을 선택해주세요.');
            return;
        } else
        if( varBankInfo.bank_owner === null){
          alert('예금주를 입력해주세요.');
            return;
        } else
        if( varBankInfo.social_id1 === ""){
          alert('주민번호 앞자리를 입력해주세요.');
            return;
        } else
        if( varBankInfo.social_id2 === ""){
          alert('주민번호 뒷자리를 입력해주세요.');
            return;
        } else
        if (varExchangeMoney === 0) {
            alert('환전금액을 입력하십시오.');
            return;
        } else if (varExchangeMoney?.replace(/,/g, '') < 100000) {
            alert('환전은 최소 10만원이상부터 가능합니다.');
            setExchangeMoney(0)
            return;
        } else if (varExchangeInfo.request_exchange > varHMoneyExchange) {
            alert('환전가능금액을 초과했습니다.');
            setExchangeMoney(0)
            return;
        } else
        if (!window.confirm('환전을 신청하시겠습니까?')) {
            return;
        }
        try {
          const sendData = {
            "mem_idx": user_idx,
            "mh_reason": 0,
            "mh_paid": varExchangeMoney?.replace(/,/g, ''), 
            "mh_balance": varUserHmoney[0]?.mh_balance === undefined ? varExchangeMoney?.replace(/,/g, '') : Number(varHMoneyBalance)-Number(varExchangeMoney?.replace(/,/g, '')),
            // "mh_balance": varUserHmoney[0]?.mh_balance,
            "bl_idx": varBankInfo?.bl_idx === undefined ? "" : varBankInfo.bl_idx,
            "mh_account": varBankInfo?.bank_account === undefined ? "" : varBankInfo?.bank_account,
            "mh_owner": varBankInfo?.bank_owner === undefined ? "" : varBankInfo?.bank_owner,
            "exchangeinfo": varExchangeInfo,
            "user_name": localStorage.getItem('mem_name'),
            "varCommissionMoney":varCommissionMoney?.replace(/,/g, ''),
            "varTaxMoney":varTaxMoney?.replace(/,/g, ''),
            "varRealExchangeMoney": varRealExchangeMoney?.replace(/,/g, ''),
            "mem_nid": `${varBankInfo.social_id1}-${varBankInfo.social_id2}`
          }
            await axios.post(`${CONFIG.SERVER_HOST}/_request_hedingmoney_exchange`, sendData, CONFIG.header).then((res) => {
                alert(res.data.message);
                navigate("/Company/ComHeding/MyHeding6");
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="container page_money_write">
            <section className="box box_content">
                <h3 className="my_title">환전계좌정보<button className="btn" onClick={(e) => onUpdateAccount(e)}>계좌정보 저장</button></h3>
                <ul className="table_view02">
                    <li className="full">
                        <div className="title_field">은행명</div>
                        <div className="info_field">
                            <select onChange={(e) => onSelectBank(e)} value={varCurrentBanks || ""}>
                                <option value="0">은행선택</option>
                                {varBanks.map((banks, index) => (
                                    <option key={"bank_" + index} value={banks.bl_idx}>{banks.bl_name}</option>
                                ))}
                            </select>
                            <p className="point_text"> ※ 회원가입 명의 계좌가 아니면 환전이 불가능합니다.</p>
                        </div>
                    </li>
                    <li>
                        <div className="title_field">계좌번호</div>
                        <div className="info_field">
                            <input type="text" name="bank_account" value={varBankInfo.bank_account || ""} placeholder="-는 제외하고 입력해주세요." onChange={(e) => onChangeHandler(e)} />
                        </div>
                    </li>
                    <li>
                        <div className="title_field">예금주</div>
                        <div className="info_field">
                            <input type="text" name="bank_owner" value={varBankInfo.bank_owner || ""} placeholder="회원명과 예금주가 동일해야합니다." onChange={(e) => onChangeHandler(e)} />
                        </div>
                    </li>
                    <li className="full">
                        <div className="title_field">주민등록번호</div>
                        <div className="info_field">
                            <div className="input_wrap">
                                <input type="text" name="social_id1" className="input_type1" maxLength="6" style={{ textAlign: "center" }} value={varBankInfo.social_id1} onChange={(e) => onChangeHandler(e)} />
                                <span className="hyphen">-</span>
                                <input type="text" name="social_id2" className="input_type1" maxLength="7" style={{ textAlign: "center" }} value={varBankInfo.social_id2} onChange={(e) => onChangeHandler(e)} />
                            </div>
                            <p className="point_text">※ 세무 처리 위해 주민등록 번호를 받고 있습니다.</p>
                        </div>
                    </li>
                </ul>
                <h3 className="my_title">환전신청정보</h3>
                <ul className="table_view02">
                    <li className="full">
                        <div className="title_field">신청자</div>
                        <div className="info_field">{varExchangeInfo.mem_name}</div>
                    </li>
                    {/* <li className="full">
                        <div className="title_field">닉네임</div>
                        <div className="info_field">{varExchangeInfo.mem_nickname}</div>
                    </li> */}
                    <li className="full">
                        <div className="title_field">연락처</div>
                        <div className="info_field">{varExchangeInfo.mem_mobile}</div>
                    </li>
                    <li className="full">
                        <div className="title_field">회원구분</div>
                        <div className="info_field">{varExchangeInfo.mem_modeString}</div>
                    </li>
                    <li className="full">
                        <div className="title_field">보유헤딩머니</div>
                        <div className="info_field">
                            <p className="point_text_before">{addThousandPoint(varHMoneyBalance) + " 원 (환전가능금액 : " + addThousandPoint(varHMoneyExchange) + " 원)"}</p>
                            <p className="point_text"> ※ 100,000원 이상 적립부터 환전 가능합니다.</p>
                        </div>

                    </li>
                    <li className="full">
                        <div className="title_field">환전요청금액</div>
                        <div className="info_field">
                            <div className="input_wrap">
                                <input type="text" className="input_type1" style={{ textAlign: "right" }} name="exchange" value={varExchangeMoney} onChange={(e) => onChangeRequestExchange(e)} />
                                <span className="won">원</span>
                            </div>
                        </div>
                    </li>
                    {/* <li className="full">
                    <div className="title_field">환전 예정일</div>
                    <div className="info_field">{ChangeDateString(varExchangeInfo.pdate)}</div>
                </li> */}
                    <li className="full">
                        <div className="title_field">환전 수수료</div>
                        <div className="info_field">{varCommissionMoney + " 원"}<span className="point_text">(10%)</span></div>
                    </li>
                    <li className="full">
                        <div className="title_field">소득세/주민세</div>
                        <div className="info_field">{varTaxMoney + " 원"}<span className="point_text">(3.3%)</span></div>
                    </li>
                    <li className="full">
                      <div className="title_field">실 환전금액</div>
                      <div className="info_field"><span className="MoneyText">{varRealExchangeMoney}</span> 원</div>
                    </li>
                </ul>
            </section>
            <div className="btn_bottom_area">
                <Link to={"../MyHeding" + backPage} className="btn white">목록으로</Link>
                <button className="btn" onClick={(e) => onRequestExchange(e)}>환전요청하기</button>
            </div>
        </div>
    );
}

export default MyMoneyChangeWrite;
