//components
import React, { useEffect, useState } from "react";
import CONFIG from "../../configs/configs";
import axios from "axios";
//이미지
// import companyImg from "../../img/sub/@thumnail.png";
import cameraImage from "../../img/main/btn_file_camera.png";
//css

/*메인(기업)>회사명*/
function CompanyName({ varCompanyInfo, setCompanyInfo }) {
  const serverpath = `${CONFIG.SERVER_HOST}/mcm_image/`; //이미지가 저장된 서버의 경로
  const [varCompanyLogo, setCompanyLogo] = useState("");

  console.log(varCompanyInfo);

  useEffect(() => {
    if (
      varCompanyInfo.mcm_name !== undefined &&
      varCompanyInfo.mcm_name !== null &&
      varCompanyInfo.mcm_image === 1
    ) {
      setCompanyLogo(serverpath + varCompanyInfo.mcm_ufile);
    } else {
      setCompanyLogo(serverpath + "default_company.png");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCompanyInfo]);

  //로고교체
  const onSelectCompanyLogo = (e) => {
    const logoFile = e.currentTarget.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(logoFile);
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setCompanyLogo(base64.toString());
        //서버에 저장하는 로직
        uploadCompanyLogo(logoFile);
      }
    };
  };
  const uploadCompanyLogo = async (logoFile) => {
    const formData = new FormData();
    formData.append("mcm_image", logoFile);
    await axios
      .post(`${CONFIG.SERVER_HOST}/mcm_image`, formData)
      .then((res) => {
        console.log(res.data.message); //성공 or 실패에 대한 메시지 방출
        const newFile = res.data.fileName;
        if (newFile !== null) {
          setCompanyInfo({
            ...varCompanyInfo,
            mcm_image: 1,
            mcm_ufile: newFile,
          });
          const param = new URLSearchParams();
          param.append("mcm_idx", varCompanyInfo.mcm_idx);
          param.append("filename", newFile);
          axios
            .post(`${CONFIG.SERVER_HOST}/company_img_update`, param)
            .then((res) => {
              if(res.data.retvalue === 1){
                console.log(res.data.message);
              }else{
                alert(res.data.message);
              }
            });
        }
      })
      .catch((error) => {
        alert("파일 저장 실패!");
        // 예외 처리
      });
  };
  console.log(varCompanyInfo);

  return varCompanyInfo.mcm_name === undefined ||
    varCompanyInfo.mcm_name === null ? null : (
    <li>
      <div className="company_logo_image_wrap">
        <figure className="company_logo_image">
          <img src={varCompanyLogo} alt="" />
        </figure>{" "}
        {/* 회사 로고 */}
        <input
          type="file"
          id="company_logo"
          accept="image/*"
          className="hide"
          onChange={(e) => onSelectCompanyLogo(e)}
        />
        <label htmlFor="company_logo" className="button">
          <img src={cameraImage} alt="기업 로고 등록" />
        </label>{" "}
        {/* 로고교체 */}
      </div>
      <h1>{varCompanyInfo.mcm_name}</h1>
    </li>
  );
}

export default CompanyName;
