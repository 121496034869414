/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CONFIG from '../../configs/configs'
import QRCode from 'qrcode'
//import makePDF from '../Common/makePDF';
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from 'moment';

//2022-11-23 PDF 변환 외부 API - KSR
import ConvertApi from 'convertapi-js';

//컨퍼넌트
import { changeMobileFormat, ChangeDateString, randomString, careerBetweenDays, onDownloadFilePDF, onDownloadUrl } from '../Common/Common'
//이미지
import qrImage from '../../img/sub/qr.png';
import pdfDownloadImage from '../../img/sub/pdf_download.png';
import hedingImage from '../../img/common/logo.png';
import Loading from 'components/Common/Loading';

const file_path = `/pdf/${moment(new Date()).format('YYYYMM')}`;

const CareerC = ({ re_code, type }) => {
  const navigator = useNavigate();
  const mem_idx = Number(localStorage.getItem('mem_idx'));
  const [varMyPersonality, setMyPersonality] = useState({}); //개인정보
  const [varMyCareerList, setMyCareerList] = useState([]); //인증경력리스트
  const [varIssueCode, setIssueCode] = useState(''); //발급번호
  const [rec_rdate, setRec_rdate] = useState(''); //발급일
  const [varQRCode, setQRCode] = useState(qrImage);
  const [Load, setLoad] = useState(false);
  const [LoadText, setLoadText] = useState('다운로드 준비중입니다.');
  const [LasrUpdateDate, setLasrUpdateDate] = useState('');

  //const pdf = makePDF();

  const toDay = moment(new Date()).format('YYYY_MM_DD');
  const mem_name = localStorage.getItem('mem_name');

  //나의 검증 경력 가져오기
  const getMyCareer = async () => {
    try {

      if (!mem_idx && type === 'web') {
        alert("로그인 후 이용 가능합니다.");
        navigator('/Login');
        return;
      };
      if (re_code === '0' && type === 'qr') {
        alert("로그인 후 이용 가능합니다.");
        navigator('/Login');
        return;
      };

      if (!re_code) {
        if (!mem_idx) {
          alert("로그인 후 이용 가능합니다.");
          navigator('/Login');
          return;

        }
      }
      
      const params = new URLSearchParams();
      params.append("user_idx", mem_idx);
      params.append("re_code", re_code);
      params.append("type", type);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_my_career_list`, params).then((res) => {
        if (res.data.retvalue === 1) {
          setMyPersonality(res.data.members);
          if (res.data.careers.length > 0) {
            setMyCareerList(res.data.careers);
            const arr = [];
            res.data.careers.map((el) => {
              if (el?.rec_rdate) {
                arr.push(moment(el?.rec_rdate).format('YYYY-MM-DD'));
              }
            });

            const LUD = arr.reduce((prev, curr) => {
              // 이전것과 비교해 더 큰 것을 리턴
              return new Date(prev).getTime() <= new Date(curr).getTime() ? curr : prev;
            });

            setLasrUpdateDate(LUD);
            //발급번호 생성 8자리 생성
            setIssueCode(randomString(8));
            //QR코드 생성 --> 대표 이력서 번호
            MakeQRCode(mem_idx);
            setRec_rdate(res.data.rec_rdate);
          } else {
            setMyCareerList([]);
            alert('등록된 인증 경력이 없습니다. 인증경력을 조회 후 확인해주세요.');
            navigator('/CareerCertificationInquiry');
          }

        } else {
          setMyPersonality({});
          setMyCareerList([]);
          alert('등록된 인증 경력이 없습니다. 인증경력을 조회 후 확인해주세요.');
          navigator('/CareerCertificationInquiry');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //QR코드 만들기
  const MakeQRCode = (mem_idx) => {
    QRCode.toDataURL(`${CONFIG.PdfBaseUri2}/${mem_idx}/qr`).then((data) => {
      setQRCode(data);
    });
  }

  useEffect(() => {
    getMyCareer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //PDF 생성 및 다운로드
  const onDownLoadPDF = async (e) => {
    e.preventDefault();
    setLoad(true);

    const PDFcss = document.querySelectorAll(".certifi_box");
    PDFcss.forEach((item) => item.classList.add('PDF'))
    const paper = document.querySelector(".certifi_box");
    const canvas = await html2canvas(paper, {
      scale: 2,
      allowTaint: true,
      useCORS: true,
    });

    const leftMargin = 10;  //좌.우 각 여백
    const topMargin = 10;   //상.하 각 여백

    //A4 : 210 * 297(세로)
    const imageFile = canvas.toDataURL('image/png', 1.0);
    PDFcss.forEach((item) => item.classList.remove('PDF'));

    const doc = new jsPDF('p', 'mm', 'a4');
    const pageWidth = 210 - (leftMargin * 2);
    const pageHeight = 297 - (topMargin * 2);

    const widthRatio = pageWidth / canvas.width;
    const customHeight = canvas.height * widthRatio;

    //doc.addImage(MainLogoIcon, 'png', 170, 10, 29, 8);//상단 로고

    doc.addImage(imageFile, 'png', leftMargin, topMargin, pageWidth, customHeight);
    //하단 흰색 채우기
    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 255, 255);
    doc.rect(0, 297 - topMargin, 210, topMargin, "F");

    let heightLeft = customHeight;  //이미지의 남은 길이
    let pageY = -pageHeight + topMargin;

    while (heightLeft >= pageHeight) {
      doc.addPage();

      doc.addImage(imageFile, 'png', leftMargin, pageY, pageWidth, customHeight);
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);
      doc.rect(0, 0, 210, topMargin, "F");//상단 여백(백색 채우기)
      doc.rect(0, 297 - topMargin, 210, topMargin, "F");//하단 여백(백색 채우기)
      //doc.addImage(MainLogoIcon, 'png', 170, 10, 29, 8);//상단 로고

      heightLeft -= pageHeight;
      pageY -= pageHeight;
    }

    const fileName = `${toDay}_${mem_name}_경력증명서.pdf`;
    const formData = new FormData();
    const pdf = new File([doc.output("blob")], `${fileName}`, {
      type: "application/pdf",
    });

    formData.append("pdf", pdf);

    await axios
      .post(`${CONFIG.SERVER_HOST}/pdf`, formData)
      .then((res) => {
        const file_name = res.data.filename;
        if (!window.ReactNativeWebView) {
          onDownloadFilePDF('pdf', `${file_name}`, `${fileName}`);
          setLoad(false);
        } else {
          //앱으로 전달
          const params = {
            file_name,
            file_path
          };
          const paramsToString = JSON.stringify(params);
          window.ReactNativeWebView.postMessage(paramsToString);
          setLoad(false);
        }


      })
      .catch((error) => {
        alert("파일 저장 실패!");
        // 예외 처리
      });
  }
  //다운로드 로딩화면 문구 세팅 시작
  let count = 0;
  useEffect(() => {
    if (Load) {
      const tick = setInterval(() => {
        if (Load === true) {
          if (count === CONFIG.DownLoadTextArray.length) {
            count = 0;
          }
          setLoadText(CONFIG.DownLoadTextArray[count]);
          count++;
        }
      }, 2000);

      return () => clearInterval(tick);
    } else {
      count = 0;
    }
    return undefined;
  }, [Load]);
  //다운로드 로딩화면 문구 세팅 끝

  //2022-11-23 PDF 다운로드 외부 API 사용 - KSR
  const handleClickPdfDownload2 = async () => {

    const convertApi = ConvertApi.auth(CONFIG.PdfApiKey);
    const params = convertApi.createParams();
    const pdfUri = `${CONFIG.PdfBaseUri2}/${mem_idx}/web`;
    //const pdfUri = "https://www.heding.co.kr/PdfView2/1";
    params.add('url', pdfUri);
    params.add('ConversionDelay', '3');

    setLoad(true);
    convertApi
      .convert('web', 'pdf', params)
      .then((result) => {
        const url = result.files[0].Url;
        if (!window.ReactNativeWebView) {
          onDownloadUrl(url, `경력증명서_${mem_name}_${toDay}.pdf`);
        } else {
          //앱으로 전달
          const file_name = `경력증명서_${mem_name}_${toDay}.pdf`;
          const file_url = url;
          const params = {
            file_name,
            file_url
          };
          const paramsToString = JSON.stringify(params);
          window.ReactNativeWebView.postMessage(paramsToString);
        }

      })
      .catch((err) => {
        alert("PDF 다운로드 과정에서 오류가 발생하였습니다.\n오류가 계속되면 관리자에게 문의하세요.");
        //console.log("PDF error", err);
        setLoad(false);
      })
      .finally(() => {
        setLoad(false);
        //document.documentElement.style.cursor = 'default'
      })
  }

  return varMyPersonality === null || varMyPersonality === undefined ? null : (
    <>
      {Load === true ? <Loading text={LoadText} /> : null}
      <section className="mycareer_box center_wrap">
        <article className="career_top_area">
          <div className="name_qr_wrap">
            <h1>{varMyPersonality.mem_name}<span>(발급번호 : {varIssueCode})</span></h1>
            {/* <p className="qr"><img className="qr-image" src={varQRCode} alt=""/></p> */}
          </div>
          <div className='warring'>※ 인증경력 최신 업데이트는 이력서 작성 페이지에서 진행 가능합니다.</div>
          <div className="pdf_download_wrap">
            <button onClick={(e) => handleClickPdfDownload2(e)}>PDF 다운로드<img src={pdfDownloadImage} alt="" /></button>
          </div>
        </article>
        <article className="career_cont_area">
          <section className="certifi_box">
            <article className="certificate">
              <div className="certifi_top_img_wrap">
                <img className="logo" src={hedingImage} alt="" />
                <img className="qr-image" src={varQRCode} alt="" />
              </div>
              <div className="certifi_top_wrap">
                <h1 className="certifi_title">경&nbsp;&nbsp;&nbsp;력&nbsp;&nbsp;&nbsp;증&nbsp;&nbsp;&nbsp;명&nbsp;&nbsp;&nbsp;서</h1>
                <table className="table_career">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{varMyPersonality.mem_name}</td>
                      <th>생년월일</th>
                      <td>{ChangeDateString(varMyPersonality.mem_birthday)}</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>{changeMobileFormat(varMyPersonality.mem_mobile)}</td>
                      <th>이메일</th>
                      <td>{varMyPersonality.mem_email}</td>
                    </tr>
                  </tbody>
                </table>
                <h2 className="caeer_detail_title">상세경력</h2>
                <table className="table_career detail">
                  <thead>
                    <tr>
                      <th>사업장명칭</th><th>기간</th><th>경력</th>
                    </tr>
                  </thead>
                  <tbody>
                    {varMyCareerList.length > 0 && varMyCareerList.filter((e) => e.rec_company && e.rec_sdate).map((career, index) => {
                      const sDate = career.rec_sdate ? ChangeDateString(career.rec_sdate.substr(0, 7)) : '';
                      const eDate = career.rec_edate ? ChangeDateString(career.rec_edate.substr(0, 7)) : '';
                      return (
                        <tr key={"career_" + index}>
                          <td>{career.rec_company}</td>
                          <td>{sDate}&nbsp;&nbsp;~&nbsp;&nbsp; {career.rec_state === 1 ? "현재" : eDate}
                          </td>
                          <td>{careerBetweenDays(career.rec_edate, career.rec_sdate)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="certifi_bottom_wrap">
                <h1>위와 같은 건강 보험 자격득실확인내역을 증명합니다.</h1>
                <h2>(주)더라이징스타헤딩<span className="stamp">(인)<i></i></span></h2>
                <p><h1>{LasrUpdateDate ? moment(LasrUpdateDate).format(CONFIG.formatStrOfKR) : ''}</h1></p>
                <p>이 증명서는 더라이징스타헤딩(www.heding.co.kr)홈페이지에서 발급된 문서입니다.<br />발급 문서의 진위여부 확인은 상단 QR Code 스캔을 통해 확인 할 수 있습니다.</p>
              </div>
            </article>
          </section>
        </article>
      </section>
    </>
  )
}

export default CareerC
