import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CONFIG from "configs/configs";
// import moment from "moment";

//css
import "css/Board.css";

//components
import Paging from 'components/Layout/Paging'
//import { ChangeDateStringOnlyDate } from "../Common/Common";
import NoticeSearch from './NoticeSearch';

function ComNoticeList() {
	//검색부-------------------------------------------------------------------------------------------
  // const [startDate, setStartDate] = useState(""); //date picker 날짜
  // const [endDate, setEndDate] = useState(""); //date picker 날짜
  // const [search, setSearch] = useState("all");
  // const [searchInput, setSearchInput] = useState("");
  const [varSearchData, setSearchData] = useState({
    sdate: null,
    edate: null,
    searchoption: "all",
    searchstring: "",
  });


  const [totalCount, setTotalCount] = useState(0);
  const [pageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);

  const [mode, setVarMode] = useState(0); //eslint-disable-line no-unused-vars

  //목록표시부-------------------------------------------------------------------------------------------
  const [boardList, setBoardList] = useState([]);
  // const [checkedList, setCheckedList] = useState([]);

  const readBoardList = useCallback((iMode, cPage) => {
    let sendData = {
      "mode" : iMode, 
      "currentPage" : cPage,
      "pageSize" : pageSize,
    }

    // if (iMode) {
    //   sendData = {
    //     "mode" : iMode, 
    //     "currentPage" : cPage,
    //     "pageSize" : pageSize,
    //     "sdate" : moment(startDate).format(CONFIG.formatStr),
    //     "edate" : moment(endDate).format(CONFIG.formatStr),
    //     "search_option" : search,
    //     "search_string" : searchInput
    //   }
    // }
  
    axios.post(`${CONFIG.SERVER_HOST}/boardlist`, sendData, CONFIG.header).then((res) => {
      console.log(res.data)
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const pmax = res.data.totalcount - (pageNum - 1) * pageSize;
        const datas = res.data.results.map((data, i) => ({
          board_idx: data.board_idx,
          order: Number(data.board_read) === 1 ? "[필독]" : pmax - i,
          board_title: data.board_title,
          mem_idx: data.mem_idx,
          mem_name: data.mem_name,
          board_rdate: data.board_rdate,
          board_hits: data.board_hits,
        }));
        setBoardList(datas);
      } else {
        setTotalCount(0);
        setBoardList([]);
        window.alert("검색된 정보가 없습니다.");
      }
    });
  
  },[pageNum, pageSize])


    //페이지가 바뀌면
    useEffect(() => {
      readBoardList(mode, pageNum);   // eslint-disable-next-line
    }, [mode, pageNum]); 


    // useEffect(() => {
    //     getPageLists();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[varPageNum,varPageSize,noticeList]); 

    const onSearchOptionHandler = () => {
      const sendData = {
        "pageNum" : pageNum,
        "pageSize" : pageSize,
        "sdate" : varSearchData.sdate,
        "edate" : varSearchData.edate,
        "searchoption" : varSearchData.searchoption,
        "searchstring" : varSearchData.searchstring
      }
      axios.post(`${CONFIG.SERVER_HOST}/search_notice`, sendData, CONFIG.header)
        .then((res) => {
          console.log(res.data)

          if(res.data.retvalue === 1){
            const pmax = res.data.totalcount - (pageNum - 1) * pageSize;
            const datas = res.data.results.map((data, i) => ({
              board_idx: data.board_idx,
              order: Number(data.board_read) === 1 ? "[필독]" : pmax - i,
              board_title: data.board_title,
              board_rdate: data.board_rdate,
              board_hits: data.board_hits,
            }));
            setBoardList(datas);
          } else {
            setTotalCount(0);
            setBoardList([]);
            window.alert("검색된 정보가 없습니다.");
          }
        })
    }

    return (
      <>
        <NoticeSearch varSearchData={varSearchData} setSearchData={setSearchData} onSearchOptionHandler={onSearchOptionHandler}/>
        <table className="table_list01 table_notice">
          <colgroup>
            <col style={{width:"10%"}} />
            <col style={{width:"60%"}} />
            <col style={{width:"20%"}} />
            <col style={{width:"10%"}} />
          </colgroup>
          <thead>
            <tr>
              <th className="th_num">번호</th>
              <th className="th_title">제목</th>
              <th className="th_date">작성일</th>
              <th className="th_hit">조회수</th>
            </tr>
          </thead>
          <tbody>
            {boardList.map((board, i) => (
              <tr key={i}>
                <td className="td_num">{board.order}</td>
                <td className="td_title"><Link to={`/Company/ComHeding/MyHeding13/NoticeView/${board.board_idx}`}
                className="ellipsis">{board.board_title}</Link></td>
                <td className="td_info">{board.board_rdate}</td>
                <td className="td_hit last">{board.board_hits}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Paging totalCount={totalCount} pageSize={pageSize} curPage={pageNum} setPage={setPageNum} />
      </>
    );
  }


export default ComNoticeList;
