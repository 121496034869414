import React from 'react'

//이미지
import icon_pw from "img/sub/icon_pw.png";
import { useLocation, useNavigate } from 'react-router-dom';

const PopColleRepuLogin = () => {
	const navigator = useNavigate();
	const location = useLocation();

	return (
		<div className={'popup active'}>
			<div className="popup_wrap popup01">
				<section className="title_wrap">
					<h1>동료평판하기</h1>
				</section>
				<section className="content_wrap">
					<article className="notice_area">
						<div className="icon"><img src={icon_pw} alt="icon_pw" /></div>
						<p className="title_field">동료 평판을 진행하기 위해<br />HEDING에 로그인 해주세요</p>
            <p className="txt_field">(동료 평판은 HEDING 로그인과 경력 인증 조회가 필수입니다.)</p>
						{/* <span className="point">비밀번호를 다시 한 번 확인</span>합니다.</p> */}
						{/* <p className="text_field">비밀번호가 타인에게 노출되지 않도록 항상 주의해주세요.</p> */}
					</article>
					{/* <div className="field">
							<label className="label">비밀번호</label>
							<input type="password" placeholder="비밀번호를 입력해주세요" name="pwd"/>
						</div> */}
				</section>
				<section className="button_wrap">
					<div className="big_button_wrap one">
						<button className='btn' onClick={() => {
							localStorage.setItem('re_url', location.pathname);
							localStorage.setItem('redirect_career_reputation', 'Y'); // 회원가입 전용
							navigator('../../Login', { state: { reputation: true } });
						}}>
							HEDING 로그인
						</button>
					</div>
					{/* <div className="close_button_wrap">
							<a>닫기 X</a>
						</div> */}
				</section>
			</div>
		</div>
	)
}

export default PopColleRepuLogin