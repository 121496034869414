/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import arr from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";
import CONFIG from "../../../configs/configs";
import axios from "axios";
import Paging from "../../../components/Layout/Paging";

import { TalkWriteEditor } from 'html/Talk/TalkWriteEditor';
import "admin/css/board.css";
import "../../../css/Common.css";


const boardInit = {
  "mwt_idx": 0,
  "mwt_mem_idx": 0,
  "mwt_title": "",
  "mwt_comment": "",
  "mwt_rdate": "",
  "mwt_hits": 0,
  "mwt_status": 0,
  "mwt_read": 0,
  "mwt_hits_udate": null,
  "mem_idx": 0,
  "mem_mode": 0,
  "mem_hunter_type": 0,
  "mem_type": 0,
  "mem_id": "",
  "mem_pwd": "",
  "mem_nickname": "",
  "mem_name": "",
  "mem_gender": 0,
  "mem_birthday": "",
  "mem_mobile": "",
  "mem_certification": 1,
  "mem_email": "",
  "mem_nid": null,
  "mem_zipcode": null,
  "mem_address1": null,
  "mem_address2": null,
  "mem_photo": 1,
  "mem_ufile": "",
  "mem_realfile": null,
  "mem_resume": 1,
  "mem_condition": 1,
  "mem_privacy": 1,
  "mem_selfagree": 1,
  "mem_expiration": 0,
  "mem_rdate": "",
  "mem_ip": "",
  "mem_ldate": null,
  "mem_sub": 0,
  "mem_edate": null,
  "mem_approval": 1,
  "mem_status": 1,
  "mem_reason": null,
  "chat_online_YN": "N",
  "room_no": null,
  "chat_stat_dtm": null,
  "recommend": 0
}

function TalkListDetail() {
  const { board_idx } = useParams();
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [boardInfo, setBoardInfo] = useState(boardInit);
  const [replysInfo, setReplysInfo] = useState([]);
  const [varModifyReplyIDX, setModifyReplyIDX] = useState(0); 
  const [varModifyReplyBool, setModifyReplyBool] = useState(false);  
  const [varModifyReply, setModifyReply] = useState(""); 
  const [varTalkReplys, setTalkReplys] = useState([]);
  const [varTalkReplyComment, setTalkReplyComment] = useState("");
  const [modal, setModal] = useState(false)
  const [mwtrIdx, setMwtrIdx] = useState(0);
  //페이지
  const [varTotalCount, setTotalCount] = useState(0); //eslint-disable-line no-unused-vars
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);
  const [varPageList, setPageList] = useState([]); //eslint-disable-line no-unused-vars

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorOnChangeHandler = (text) => {
    setBoardInfo((prev) => {
      return {
        ...prev,
        mwt_comment: text
      };
    });
  }

  const titleOnchangeHandler = (text) => {
    setBoardInfo((prev) => {
      return {
        ...prev,
        mwt_title: text
      };
    });
  }

  const onclickSaveButton = async () => {
    const params = new URLSearchParams();
    params.append("mwt_idx", board_idx);
    params.append("mwt_title", boardInfo.mwt_title);
    params.append("mwt_comment", boardInfo.mwt_comment);
    axios.post(`${CONFIG.SERVER_HOST}/_update_talk_comment`, params).then((res) => {
      //alert('수정 되었습니다.');
      navigate("/admin/talklist");
    })
    .catch((err) => {
      alert('처리중 문제가 발생했습니다.');
      console.debug(err);
    });
  }

  const onCLickReplyDeleteButton = (num) => {
    if(!window.confirm("삭제하시겠습니까?")) {
      return;
    }
    const params = new URLSearchParams();
    params.append("mwtr_idx", num);
    axios
      .post(`${CONFIG.SERVER_HOST}/_delete_talk_replys`, params)
      .then((res) => {
        const replysParams = new URLSearchParams();
        replysParams.append("mwt_idx", board_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_talk_replys`, replysParams).then((res) => {
          setReplysInfo(res.data.results);
        });
      }).catch((err) => {
        alert('처리중 문제가 발생했습니다.');
        console.debug(err);
      });
  }

  useEffect(() => {
    if (Number(board_idx) > 0) {
      const params = new URLSearchParams();
      params.append("mwt_idx", board_idx);
      axios.post(`${CONFIG.SERVER_HOST}/_get_talk_information`, params).then((res) => {
        setBoardInfo(res.data.results[0]);
      });

      const replysParams = new URLSearchParams();
      replysParams.append("mwt_idx", board_idx);
      axios.post(`${CONFIG.SERVER_HOST}/_get_talk_replys`, replysParams).then((res) => {
        setReplysInfo(res.data.results);
      });
    }
  }, [board_idx, varTalkReplys]);


  // 댓글 수정창 오픈
  const onChangeReplyCommentHandler = (e, mwtr_idx) => {
    e.preventDefault();
    if (varModifyReplyBool !== true) {
      const finsIndex = replysInfo.filter(
        (element) => element.mwtr_idx === mwtr_idx
      );
      if(finsIndex[0].mwtr_idx === mwtr_idx){
        setMwtrIdx(finsIndex[0].mwtr_idx)
        setModal(true)
      }else{
        setModal(false)
      }
      if (varModifyReply === "") {
          setModifyReplyBool(true);
          setModifyReplyIDX(mwtr_idx);
          setModifyReply(finsIndex[0].mwtr_comment);
        }
      } else {
        //alert("열려있는 수정창을 닫아주세요");
      }
    };


  //댓글 저장
  const onUpdateReplyCommentHandler = (mwtr_idx, num) => {
    if((num === 0 && varTalkReplyComment === "") || (num === 1 && varModifyReply==="" )){
        alert("댓글을 입력해주세요.")
        return false;
    }
    let comments = "";
    if (mwtr_idx === 0) {
      comments = varTalkReplyComment;
    } else {
      const finsIndex = replysInfo.findIndex(
        (element) => element.mwtr_idx === mwtr_idx
      );
      comments = replysInfo[finsIndex].mwtr_comment;
    }
    const params = new URLSearchParams();
    params.append("user_idx", CONFIG.AdminIdx);//댓글 작성자 idx
    params.append("mwt_idx", board_idx);
    params.append(
      "mwtr_idx",
      varModifyReplyIDX === 0 ? mwtr_idx : varModifyReplyIDX
    );
    params.append(
      "mwtr_comment",
      varModifyReply === "" ? comments : varModifyReply
    );

    axios
      .post(`${CONFIG.SERVER_HOST}/_update_talk_replys`, params)
      .then((res) => {
        if (res.data.retvalue === 1) {
          if (mwtr_idx === 0) {
            const talkReply = res.data.results[0];
            let copyData = varTalkReplys.concat(talkReply);
            copyData.sort(function (a, b) {
              return new Date(b.mwtr_rdate) - new Date(a.mwtr_rdate);
            });
            setTalkReplys(copyData);
            setTalkReplyComment("");
            setModifyReplyBool(false);
          } else {
            let copyData = [...varTalkReplys];
            copyData.sort(function (a, b) {
              return new Date(b.mwtr_rdate) - new Date(a.mwtr_rdate);
            });
            setTalkReplys(copyData);
            setTalkReplyComment("");
            setModifyReplyBool(false);
          }
          setReplysInfo(replysInfo)
        }
        setTalkReplyComment("")
        setModal(false)
      });
  };

 //댓글 수정 핸들러
  const onModifyCommentHandler = (e) => {
    const { value } = e.target;
    setModifyReply(value);
  };


  //페이지 설정
  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if (varPageNum === 0 || varTalkReplys.length === 0) {
      setPageList([]);
      return;
    }
    var copyData = [...varTalkReplys];
    const startNum = (varPageNum - 1) * varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum, endNum);
    setPageList(copyData);
  };
  //표시할 페이지가 바뀌면
  useEffect(() => {
    getDataPerPage();
  }, [varPageNum, varTalkReplys, varModifyReply]);

  //댓글 달기
  const onAddCommentHandler = (e) => {
    setTalkReplyComment(e.target.value);
  };

    //댓글 수정창 취소
  const onCancelReplyHandler = (e, mwtr_idx) => {
    setModal(!modal)
    setModifyReply("");
    setModifyReplyIDX(0);
    setModifyReplyBool(false);
  };

  return (
    <>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="talklistdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          이직/구인톡 관리 등록/수정
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arr} alt="" />
            게시판 관리
            <img src={arr} alt="" />
            이직/구인톡 관리 등록/수정
          </div>
        </div>
        <div className="content" >
        <p style={{ marginTop: "20px" }} className="table_title">- 작성자 정보</p>
          <div className="main-box" >
            <table className="write">
              <tbody>
                <tr>
                  <th>작성자</th>
                  <td style={{ textAlign: "left", width: "300px" }}>
                    {boardInfo.mem_type === 0 ? boardInfo.mem_id : boardInfo.mem_nickname}
                  </td>
                  <th>작성일</th>
                  <td>
                   {boardInfo.mwt_rdate}
                  </td>
                </tr>
                <tr>
                  <th>조회수</th>
                  <td style={{ textAlign: "left", width: "300px" }}>
                    {boardInfo.mwt_hits}
                  </td>
                  <th>추천수</th>
                  <td>
                   {boardInfo.recommend}
                  </td>
                </tr>
                <tr>
                  <th>제목</th>
                  <td colSpan={3} style={{ textAlign: "left" }}>
                    <input
                      type="text"
                      value={boardInfo.mwt_title}
                      onChange={(e) => titleOnchangeHandler(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>내용</th>
                  <td colSpan={3}>
                    <TalkWriteEditor
                      defaultValue={boardInfo.mwt_comment}
                      handleOnChange={(text) => editorOnChangeHandler(text)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/talklist" className="btn line"> 취소 </Link>
              <button onClick={() => onclickSaveButton()} className="btn"> 수정 </button>
            </div>
          </div>
          <p className="table_title">- 댓글 달기</p>
          <div className="board_view">
            <div className="main-box">
            <table className="write">
              <th>
                <section className="comment">
                  <article className="write_area">
                    <textarea
                      placeholder="댓글을 남겨보세요.(최대 2000자까지 입력이 가능합니다.)"
                      value={varTalkReplyComment}
                      onChange={onAddCommentHandler}
                    ></textarea>
                    <button className="button" onClick={() => onUpdateReplyCommentHandler(0, 0)} style={{color:"#fff", height: "75px", lineHeight:"50px"} }>
                      등록하기
                    </button>
                  </article>
                  <div className="paging">
                    <Paging
                      totalCount={varTotalCount}
                      pageSize={varPageSize}
                      curPage={varPageNum}
                      setPage={setPageNum}
                    />
                  </div>
                </section>
              </th>
            </table>
            </div>
          </div>
          {replysInfo.length > 0 ? <p className="table_title">- 댓글 정보</p> : "" }
          {replysInfo && replysInfo.map((el, i) => {
              return (
                <div key={i}>
                  <div className="main-box">
                    <table className="write">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th>작성자</th>
                          <td style={{ textAlign: "left", width: "300px" }}>
                            {el.admin_name === null ? (el.mem_type === 0 ? el.mem_id : el.mem_nickname) : el.admin_name }
                          </td>
                          <th>작성일</th>
                          <td>
                          {el.mwtr_rdate}
                          </td>
                        </tr>
                        <tr>
                          <th>내용</th>
                          <td colSpan={3} style={{ textAlign: "left" }}>
                            {el.mwtr_comment}
                              {el.admin_name === null ? "" : 
                                <>
                                  {modal === true && mwtrIdx === el.mwtr_idx ? null : <>
                                    <button className="btn" style={{marginLeft:"7%"}}
                                      onClick={(e) => onChangeReplyCommentHandler(e, el.mwtr_idx)}>수정</button>
                                    <button onClick={() => onCLickReplyDeleteButton(el.mwtr_idx)} className="btn"> 삭제 </button>   
                                  </>
                                  }
                                </>
                              }
                          {modal === true && el.admin_name !== null && mwtrIdx === el.mwtr_idx ? 
                            <div
                              className="edit_talk_reply hide"
                              id={"modify_" + el.mwtr_idx}
                            >
                              <textarea
                                placeholder="댓글을 남겨보세요.(최대 2000자까지 입력이 가능합니다.)"
                                name="mwtr_comment"
                                value={varModifyReply}
                                onChange={(e)=> onModifyCommentHandler(e)}
                              ></textarea>
                              <button
                                className="button"
                                onClick={() => onUpdateReplyCommentHandler(el.mwtr_idx, 1)}
                              >
                                변경등록하기
                              </button>
                              <button
                                className="button"
                                onClick={() => onCancelReplyHandler(el.mwtr_idx, 1)}
                              >
                                취소
                              </button>
                            </div>
                          : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default TalkListDetail;
