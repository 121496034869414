import React, { useEffect } from "react";

//components
import { resetNavMenu,resetNavSubMenu } from "../../components/Common/Common";
import HeaderCompany from '../../components/Layout/HeaderCompany';
/* import SubVisual from '../../components/Layout/SubVisual'; */
import Footer from '../../components/Layout/Footer';
import Message from '../../components/My/Message';
import BannerBottom from "components/Banner/BannerBottom";
import { useParams, useNavigate } from "react-router-dom";

/*마이페이지>정보관리(개인)*/
function ComMessage() {
  const uNavigate = useNavigate();
  const {roomIdx} = useParams();
  const mem_idx = Number(localStorage.getItem('mem_idx'));

  useEffect(() => {
    if (!mem_idx || Number(mem_idx)===0) {
      alert("로그인 후 이용 가능합니다.");
      uNavigate("/Login");
    }
    resetNavMenu(3);
    resetNavSubMenu(3,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
        <div>
            <HeaderCompany />  {/*아이콘+메뉴Nav*/}
            <div  id="sub_wrap" className="center_wrap com_message">
              <div className="container page_message"> {/*전체 페이지*/}
                  <h2 className="sub_content_title indent">메시지</h2>
                  <Message roomIdx={roomIdx}/>
              </div>
            </div>
        </div>
        <BannerBottom />
        <Footer/> {/*푸터공통*/}
    </>
  );
}

export default ComMessage;
