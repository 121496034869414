/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
//image
import deleteSchoolImage2 from "../../img/sub/btn_delete02.png";
//datepicker
import { getYear, getMonth } from "date-fns"; // getYear, getMonth 
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import "react-datepicker/dist/react-datepicker.css";
import { ChangeSearchingDateFormat } from '../Common/Common'
registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');


function AwardsHistory({
  key,
  index,
  award,
  awardList,
  setAwardsList
}) {

  const [startDate, setStartDate] = useState(award.ra_gdate === null || award.ra_gdate === "" ? null : new Date(award.ra_gdate));
  const years = _.range(1990, getYear(new Date()) + 1, 1); // 수정
  const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
  // //입력값 저장
  const onChangeHandler = (e) => {


    var { value, name } = e.target;
    const Array = awardList;
    const findIndex = Array.findIndex(element => element.idx === award.idx);
    let copyArray = [...Array];
    if (findIndex !== -1) {
      copyArray[findIndex] = { ...copyArray[findIndex], [name]: value };
      setAwardsList(copyArray);
    }
  };

  //날짜변경
  const onChangeDate = (date) => {
    const ndate = ChangeSearchingDateFormat(date); //날짜 변경----configs.js
    setStartDate(date);
    setAwardsList(
      awardList.map(award =>
        award.idx === award?.idx ? {
          ...award,
          ra_gdate: ndate
        } : award
      ));
  }

  //수상이력 삭제
  const onRemoveHandler = (e) => {
    e.preventDefault();
    if (!window.confirm("선택한 수상이력을 삭제합니까?")) return;
    const copy = awardList.filter((item)=>item.idx !== index);
    const copy2 = copy.map((item, i)=>{
        return {
          idx: i+1,
          ra_comment: item.ra_comment,
          ra_gdate: item.ra_gdate,
          ra_issuer: item.ra_issuer
        };
    });
    setAwardsList(copy2);
  };

  return (
    <div className="input_area" key={"resch_" + index}>
      <button className="btn_del" onClick={(e) => onRemoveHandler(e)} style={{cursor:"pointer"}}>
        <img src={deleteSchoolImage2} alt="" />
      </button>
      <input
        type="text"
        className={"input_part"+(award.ra_comment ? ' bg_on' : '')}
        name="ra_comment"
        placeholder="수상명"
        value={award.ra_comment}
        onChange={onChangeHandler}
      />
      
       <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        className="datepicker" locale={ko} dateFormat={"yyy.MM.dd"} id={'ra_gdate'} selected={startDate} selectStart onChange={(date) => onChangeDate(date)}
      />
      <input
        type="text"
        className={"input_part4"+(award.ra_issuer ? ' bg_on' : '')}
        placeholder="수여기관"
        name="ra_issuer"
        value={award.ra_issuer}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default AwardsHistory;
