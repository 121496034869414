import React from "react";
import { Routes, Route } from "react-router-dom";
import ResumePdfView2 from '../Resume/ResumePdfView2';
function PdfView2() {

  return (
    <div className="p_resume pdf">
        <div>
              <Routes>
                <Route path="/:mem_idx/:type" element={<ResumePdfView2 />} /> 
              </Routes>
        </div>
    </div>
  );
}

export default PdfView2;
