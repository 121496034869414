import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CONFIG from "../../configs/configs";
import { em_titleLength } from "../Common/Common";

/*메인(기업)>진행중인 공고*/
function Announcement() {
  const mcm_idx = localStorage.getItem("mcm_idx");
  const mem_idx = localStorage.getItem("mem_idx");
  let mem_mode = Number(localStorage.getItem("mem_mode"));
  let mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));

  let SearchForm = 0;
  if (mem_mode === 2 && mem_hunter_type >= 1) {
    SearchForm = 1;
  }
  const [varCompanyAnnounce, setCompanyAnnounce] = useState([]);
  const [varTotalAnnounce, setTotalAnnounce] = useState(0);
  //진행중인 공고
  const getCompanyAnnounce = async () => {
    try {
      console.log(mcm_idx);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mem_idx", mem_idx);
      params.append("SearchForm", SearchForm);
      await axios
        .post(`${CONFIG.SERVER_HOST}/_get_company_announce`, params)
        .then((res) => {
          console.log(res.data);
          if (res.data.retvalue === 1) {
            const datas = res.data.results;
            console.log(datas);
            setCompanyAnnounce(datas);
            setTotalAnnounce(datas.length);
          } else {
            setCompanyAnnounce([]);
            setTotalAnnounce(0);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    //console.log('mcm_idx',mcm_idx);
    getCompanyAnnounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <article>
      <hgroup className="sub_content_title_wrap spacebetween">
        <h1 className="sub_content_title indent">
          진행중 공고<span className="txt_blue">{varTotalAnnounce} 건</span>
        </h1>
        <Link
          to={"/Company/ComAnnouncement/AnnouncementList"}
          className="btn line gray"
        >
          전체보기<span className="nanumGothic">&gt;</span>
        </Link>
      </hgroup>
      <div className="list_box">
        {varCompanyAnnounce.length === 0 ? null :
          <ul className="announ_list">
            {varCompanyAnnounce && varCompanyAnnounce.map((jobPosting, index) => (
              <li className="ellipsis" key={"jobhosting" + index}>
                <Link className="no_inline_block"
                  to={`/Company/ComRecruit/MainView/${jobPosting.em_idx}/${jobPosting.em_headhunter}`}
                  style={{ fontSize: "15px" }}
                >
                  {em_titleLength(jobPosting.em_title, '50', '...')}
                </Link>
              </li>
            ))}
          </ul>
        }
      </div>
    </article>
  );
}

export default Announcement;
