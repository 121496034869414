/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';
//components
import CareerHistory from './CareerHistory';

//이미지
import updateImage from '../../img/sub/icon_update.png';

import moment from "moment";
import {monthDiff2, getMaxLength} from '../Common/Common';
import { max } from "lodash";

function Career({ varSaveMode, curResumeIndex, varInputs, setInputs, varPosition, resumeCareerList, setResumeCareerList, varCheckNoWork, setCheckNoWork, setPerfection, varPerfection, openfc, careerTipList }) {

  const user_idx = localStorage.getItem('mem_idx');
  const [varTotalWork, setTotalWork] = useState('');
  const [DataProof, setDataProof] = useState(false);
  const [varTotalAddWork, setTotalAddWork] = useState('');

  const getMyCareer = () => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    params.append("re_mode", varSaveMode);
    params.append("re_idx", curResumeIndex);
    params.append("rec_certification", 1);
    axios.post(`${CONFIG.SERVER_HOST}/_get_my_careers`, params).then((res) => {
      if (res.data.retvalue === 1) {
        const data = res.data.results.map((data, i) => (
          {
            idx: i + 1,
            rec_idx: data.rec_idx === undefined ? data.trec_idx : data.rec_idx,
            rec_company: data.rec_company,
            rec_department: data.rec_department,
            rec_position: data.rec_position,
            rec_sdate: data.rec_sdate,
            rec_edate: (data.rec_edate === null) ? null : data.rec_edate,
            rec_state: data.rec_state,
            rec_certification: 1,
            rec_descript: [
              { id: 0, 'note': data.rec_note1, 'detail': data.rec_detail1 },
              { id: 1, 'note': data.rec_note2, 'detail': data.rec_detail2 },
              { id: 2, 'note': data.rec_note3, 'detail': data.rec_detail3 },
              { id: 3, 'note': data.rec_note4, 'detail': data.rec_detail4 },
              { id: 4, 'note': data.rec_note5, 'detail': data.rec_detail5 }]
          }
        ));

        setResumeCareerList(data);
        if (data.length === 0) {
          setCheckNoWork(false);
          setDataProof(false);
        } else {
          setCheckNoWork(false);
          setDataProof(true);
        }

      } else {
        setResumeCareerList([]);
        setCheckNoWork(false);
      }
    });
  }

  useEffect(() => {
    if (curResumeIndex > 0) {
      getMyCareer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curResumeIndex]);

  //경력변경----
  useEffect(() => {
    if (resumeCareerList.length > 0) {
      monthDiff(resumeCareerList);

      if (resumeCareerList.length > 0) {
        if (varPerfection.career !== 1) {
          const completed = varPerfection.completed + 25;
          const slasses = 'diagram step' + parseInt(completed / 25);

          setPerfection({
            ...varPerfection,
            career: 1,
            classes: slasses,
            completed: completed
          })
        }

      } else {
        if (varPerfection.career === 1) {
          const completed = varPerfection.completed - 25;
          const slasses = 'diagram step' + parseInt(completed / 25);

          setPerfection({
            ...varPerfection,
            career: 0,
            classes: slasses,
            completed: completed
          })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeCareerList]);

  //신입 - 경력없음
  const onNoWorkHandler = e => {
    if (resumeCareerList.length <= 0) {
      if (e.target.checked) {
        if (resumeCareerList.length > 0) {
          if (!window.confirm('아래의 경력을 모두 삭제합니까?')) {
            e.currentTarget.checked = false;
            return;
          }
        }
        setResumeCareerList([]);
        setTotalWork('');
        setInputs({ ...varInputs, career_year: 0.0 });
      } else {
        if (DataProof) {
          getMyCareer();
        } else {
          if (resumeCareerList.length === 0) {
            alert('등록된 인증 경력이 없습니다.');
            //e.target.checked = true;
          }
        }
      }
      setCheckNoWork(e.target.checked);
    }

  }

  //인증 경력 가져오기 - 회원가입시 저장된 내역
  const onGetCareerCertification = async (e) => {
    e.preventDefault();
    if (!window.confirm('인증경력을 업데이트 하시겠습니까?')) {
      return false;
    }
    openfc(0);
  }

  const monthDiff = (arr) => {

    // console.log(arr);
    // let monthArr = [];
    // let total = 0;
    // arr.map(item => {
    //   if (item.rec_sdate !== null) {
    //     const data1 = item.rec_sdate.split('-');
    //     const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
    //     const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));
    //     total += t1;
    //   }
    // });
    // // const t2 = total / 12;
    // // const t3 = t2.toFixed(1).split('.');

    // // let strCareer = ``;
    // // if (Number(t3[0]) > 0) {
    // //   strCareer = `${t3[0]}년`;
    // // };
    // // if (Number(t3[1]) > 0) {
    // //   strCareer += ` ${t3[1]}개월`;
    // // };
    // // setInputs({
    // //   ...varInputs,
    // //   career_year: Number(t2.toFixed(1))
    // // });
    // // setTotalWork(strCareer);
    // const quotient = parseInt(total/12); //몫
		// const remainder = total %12; //나머지
    // monthArr.push(quotient,remainder)

		// let strCareer = ``;
		// if( Number(monthArr[0]) > 0) {
		// 	strCareer = `${monthArr[0]}년`;
		// };
    // if( Number(monthArr[1]) > 0) {
		// 	strCareer += ` ${monthArr[1]}개월`;
		// };
    // console.log(strCareer);
		// setInputs({
		// 	...varInputs,
		// 	career_year: Number((quotient+"."+remainder))
		// });
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rac_sdate !== null){
				const data1 = item.rec_sdate.split('-');
				const data2 = item.rec_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rec_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));

        console.log('t1 : ', item.rec_sdate, item.rec_edate, t1);
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

    console.log(quotient+"."+remainder);
		setInputs({
			...varInputs,
			career_year: quotient+"."+remainder,
      certification_experience_year: quotient,
      certification_experience_date: remainder
		});
		setTotalWork(strCareer);
  };

  //인증경력 작성 TIP
  let copyData = [...careerTipList]
  const tipData = copyData.filter((el) => el.career_order === 1)

  console.log(tipData[0]?.career_tip_content.split(/(?:\r\n|\r|\n)/g));
  
  // useEffect(()=>{

  //   const arr =tipData[0]?.career_tip_content.split(/(?:\r\n|\r|\n)/g);
  //   const leng = find_longest_word(arr);
  //   const maxValue = Math.max.apply(null, leng);
  //   console.log(maxValue);
    
  // }, [tipData]);

  // const getMaxLength = (arr) =>{
    
  //   const arrData =arr?.career_tip_content.split(/(?:\r\n|\r|\n)/g);
  //   const leng = find_longest_word(arrData);
  //   const maxValue = Math.max.apply(null, leng);
  //   console.log(maxValue);

  //   return maxValue +365;
  // }

  // const find_longest_word = (arr)=>{

  //   let wordLength = []
  //   for (let i=0; i<arr?.length;i++){ 
  //       wordLength.push(arr[i].split("")?.length)
  //   }
  //   return wordLength
  
  // }


  return (
    <>
    <section className="section">
      <article className="title_area has_guide_secret">
        <h3 className="title">인증 경력</h3>
        <input type="checkbox" id="career" className="chk" onChange={(e) => { onNoWorkHandler(e) }} checked={varCheckNoWork === true ? true : false} />
        <label htmlFor="career">신입 (경력사항 없음)</label>
        {/*작성팁*/}
        <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
        <p className="total_field">총 경력 : <span className="point">{varTotalWork}</span> </p>
      </article>
      <article>
        {resumeCareerList.length > 0 ?
          resumeCareerList.map((career, index) => (
            <CareerHistory key={'carrer_' + index}
              index={index} 
              career={career}
              varPosition={varPosition}
              resumeCareerList={resumeCareerList}
              setResumeCareerList={setResumeCareerList}
            />
          )) : null
        }
      </article>
      <div className="btn_bottom_area">
        <button className="btn line" onClick={(e) => onGetCareerCertification(e)}><img src={updateImage} alt="" />인증경력 업데이트</button>
      </div>
    </section>
    </>
  );
}

export default Career;

