/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React from "react";

//component
import AwardsHistory from "./AwardsHistory";
//image
import addSchoolImage from "../../img/sub/btn_plus_blue.png";

function Awards({awardList, setAwardsList, careerTipList}) {

	// const [addNum, setAddNum] = useState(awardList.length);
	const onHandlerAddHistory = (e) => {
		e.preventDefault();
		// if (!window.confirm("수상이력을 추가합니까?")) return;

		const awards = {
			idx: awardList.length+1,
			ra_comment: '',
			ra_gdate: '',
			ra_issuer: ''
		};

    setAwardsList([...awardList, awards]);

	};

  //추가경력 작성 TIP
  let copyData = [...careerTipList]
  const tipData = copyData.filter((el) => el.career_order === 5)

  return (
    <section className="section">
      <div className="title_area has_guide_secret">
        <h3 className="title">수상이력</h3>
        <button
          className="btn txt"
          onClick={(e) => onHandlerAddHistory(e)}
        >
          <img src={addSchoolImage} alt="" />
          추가
        </button>
        {/*작성팁*/}
        <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
      </div>
      {awardList
        ? awardList.map(( award, index) => (
          <AwardsHistory
            key={"scharray_" + index}
            index={award.idx}
            award={award}
            awardList={awardList}
            setAwardsList={setAwardsList}
          />
        ))
        : null}
    </section>
  );
}

export default Awards;
