/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../../configs/configs";
import axios from "axios";
import moment from "moment";
import { changeMobileFormat } from "../../../components/Common/Common";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import Paging from "../../../components/Layout/Paging";

import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";

import "admin/css/board.css";

function HrIntroList() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  //검색부-------------------------------------------------------------------------------------------
  const [startDate, setStartDate] = useState(""); //date picker 날짜
  const [endDate, setEndDate] = useState(""); //date picker 날짜
  const [varSearch, setSearch] = useState("none");
  const [varSearchInput, setSearchInput] = useState("");

  const [varTotalCount, setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum, setPageNum] = useState(1);

  const [varMode, setVarMode] = useState(0);

  //목록표시부-------------------------------------------------------------------------------------------
  const [varBoardList, setBoardList] = useState([]);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function readBoardList(iMode, cPage) {

    const sendData = {
      'mode': iMode,
      'currentPage': cPage,
      'pageSize': varPageSize,
      'sdate': moment(startDate).format(CONFIG.formatStr),
      'edate': moment(endDate).format(CONFIG.formatStr),
      'search_option':varSearch,
      'search_string':varSearchInput
    }

    axios.post(`${CONFIG.SERVER_HOST}/hrintrolist`, sendData, CONFIG.header).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        setTotalCount(res.data.totalcount);
        const pmax = res.data.totalcount - (varPageNum - 1) * varPageSize;
        const data = res.data.results.map((data, i) => ({
          board_idx: data.board_idx,
          order: pmax - i,
          mem_id: data.mem_id,
          mem_idx: data.mem_idx,
          board_name: data.board_name,
          board_company: data.board_company,
          board_mobile:
            data.board_mobile === ""
              ? ""
              : changeMobileFormat(data.board_mobile),
          board_rdate: data.board_rdate.substr(0, 10),
          cs_name: data.cs_name,
          board_state:data.board_state
        }));
        setBoardList(data);
      } else {
        setTotalCount(0);
        setBoardList([]);
        window.alert("검색된 정보가 없습니다.");
      }
    });
  }

  //페이지가 바뀌면
  useEffect(() => {
    readBoardList(varMode, varPageNum);
  }, [varPageNum]);

  //검색-------------------------------------------------------------------------------------------
  const handleType = (e) => {
    setSearch(e.target.value);
  };
  const handleInput = (e) => {
    setSearchInput(e.target.value);
  };
  // const onChangeOpenDate = () => {
  //     setIsOpenDate(!isOpenDate);
  // };
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    console.log("startDate", startDate);
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };
  //초기화
  const handleSearchClear = () => {
    setStartDate("");
    setEndDate("");
    setSearch("none");
    setSearchInput("");

    setVarMode(0);
    setPageNum(1);
    readBoardList(0, 1);
  };
  //검색
  const handleSearchBoardList = () => {
    setVarMode(1);
    setPageNum(1);
    readBoardList(1, 1);
  };

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="hrintrolist" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          HR 담당자 소개 관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            게시판 관리
            <img src={arrImage} alt="" />
            HR 담당자 소개 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <div className="search-box">
              <div className="day-box">
                <p>신청일</p>
                <input
                  type="date"
                  name="startdate"
                  value={startDate}
                  onChange={handleStartDate}
                  id="datePicker"
                />
                ~
                <input
                  type="date"
                  name="enddate"
                  value={endDate}
                  onChange={handleEndDate}
                  id="datePicker2"
                />
              </div>
              <div className="f-right">
                <select
                  style={{ width: "120px", marginRight: "5px" }}
                  name="search"
                  value={varSearch}
                  onChange={handleType}
                >
                  <option value="none">전체</option>
                  <option value="mem_id">아이디</option>
                  <option value="board_name">이름</option>
                  <option value="board_company">회사명</option>
                  <option value="board_mobile">연락처</option>
                </select>
                <input
                  type="text"
                  name="strsearch"
                  value={varSearchInput}
                  onChange={handleInput}
                  style={{ width: "270px", marginRight: "5px" }}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleSearchBoardList(e)}}
                />
                <button onClick={handleSearchBoardList} className="btn">
                  검색
                </button>
                <button onClick={handleSearchClear} className="btn black">
                  초기화
                </button>
              </div>
            </div>
          </div>
          <div className="main-box ">
            <table className="list">
              <colgroup>
                <col style={{width:"8%"}} />
                <col style={{width:"18%"}} />
                <col style={{width:"12%"}} />
                <col style={{width:"18%"}}/>
                <col style={{width:"14%"}} />
                <col style={{width:"12%"}}/>
                <col style={{width:"10%"}}/>
                <col style={{width:"8%"}}/>
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>이름</th>
                  <th>회사명</th>
                  <th>연락처</th>
                  <th>신청일자</th>
                  <th>진행상태</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {varBoardList.map((board, i) => (
                  <tr key={board.board_idx}>
                    <td>
                      <p>{board.order}</p>
                    </td>
                    <td className="ellipsis">
                      <p>{board.mem_id}</p>
                    </td>
                    <td className="ellipsis">
                      <p>{board.board_name}</p>
                    </td>
                    <td className="ellipsis">
                      <p>{board.board_company}</p>
                    </td>
                    <td>
                      <p>{board.board_mobile}</p>
                    </td>
                    <td>
                      <p>{board.board_rdate.substr(0, 10)}</p>
                    </td>
                    <td>
                      <p>{board.board_state === 1 ? "미확인":"확인"}</p>
                    </td>
                    <td>
                      <Link
                        to={`/admin/hrintrodetail/${board.board_idx}`}
                        className="btn line black"
                      >
                        상세
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Paging
              totalCount={varTotalCount}
              pageSize={varPageSize}
              curPage={varPageNum}
              setPage={setPageNum}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default HrIntroList;
