/* eslint-disable react-hooks/exhaustive-deps */
/***********************************************************************
 * 2022-08-21 : 제안 시 채용공고 없이 직접 입력 '논리오류', 보상금 등 걸려 있음
 *            직접입력 제거 - KSR
 ***********************************************************************/
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';
import { em_titleLength, unescapeHtml } from '../Common/Common';


function PopupSuggest({ varUsingPass, setUsingPass, varResumeIndex, varShowPopupSuggest, setShowPopupSuggest, setSuggestionAllowed, varSugEmIndex, setPopup }) {
  const user_idx = localStorage.getItem('mem_idx'); //회원 번호
  const mcm_idx = localStorage.getItem('mcm_idx');  //기업 번호
  const com_idx = localStorage.getItem('com_idx');  //기업대표 회원번호
  //let mem_mode = Number(localStorage.getItem("mem_mode"));
  //let mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));
  const userName = localStorage.getItem('mem_name');
  const mcm_name = localStorage.getItem('mcm_name'); // 기업명

  //헤드헌터 - 써치폼 확인
  let SearchForm = 1;
  /* 2022-11-12 KSR
  if (mem_mode === 2 && mem_hunter_type === 1) {
    SearchForm = 1;
  }
  */

  //채용공고목록
  const [varCompanyEmploys, setCompanyEmploys] = useState([]);
  //제안을 보낼 채용공고 번호 - 선택한 경우
  const [varEmployIndex, setEmployIndex] = useState(1);

  //채용 제안 변수 - 직접입력의 경우
  const [varEmployTitle, setEmployTitle] = useState('');
  const [varEmployTasks, setEmployTasks] = useState('');
  const [varEmployComment, setEmployComment] = useState('');
  const [emContext, setEmContext] = useState('');
  const [MemName, setMemName] = useState('');

  const companyName = useRef('');

  //기업채용공고 가져오기
  const getCompanyEmploys = async () => {
    /* 2022-11-14 기존 주석 - KSR
    try {
      console.log('PopupSuggest', mcm_idx, SearchForm);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("SearchForm", SearchForm);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_employ_lists_company`, params).then((res) => {
        console.log('/_get_employ_lists_company', res.data);
        if (res.data.retvalue === 1) {
          setCompanyEmploys(res.data.results);
        }
      });
    } catch (error) {
      console.log(error);
    }
    */

    try {
      console.log('PopupSuggest', mcm_idx, SearchForm);
      const params = new URLSearchParams();
      params.append("mem_idx", user_idx);
      params.append("mcm_idx", mcm_idx);
      params.append("com_idx", com_idx);
      params.append("re_idx", varResumeIndex);

      await axios.post(`${CONFIG.SERVER_HOST}/_get_employ_lists_company2`, params).then((res) => {
        console.log('/_get_employ_lists_company', res.data);
        if (res.data.retvalue === 1) {
          console.log(res.data.results);
          setCompanyEmploys(res.data.results);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (varSugEmIndex !== 0) {
      setEmployIndex(varSugEmIndex);
      // 후보자 관리 => 제안 보내기 시 공고제목, 공고 직무 불러오기
      setEmployTitle(varCompanyEmploys.filter((element) => element.em_idx === varSugEmIndex)[0]?.em_title)
      setEmployTasks(varCompanyEmploys.filter((element) => element.em_idx === varSugEmIndex)[0]?.tasks)
      companyName.current = varCompanyEmploys.filter((element) => element.em_idx === varSugEmIndex)[0]?.com_name;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSugEmIndex]);

  useEffect(() => {
    getCompanyEmploys();
  }, []);


  useEffect(() => {
    if (varEmployIndex > 0) {
      window.scrollTo(0, 0);
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("em_idx", varEmployIndex);
      console.log("em_idx", varEmployIndex, user_idx);
      axios
        //2022-08-20 비회원도  상세화면 접근 가능 - KSR
        .post(`${CONFIG.SERVER_HOST}/_get_employ_information`, params)
        //.post(`${CONFIG.SERVER_HOST}/_get_employ_information2`, params)
        .then(async (res) => {
          console.log("/_get_employ_information::results", res.data.results);
          if (res.data.retvalue === 1) {
            console.log("/_get_employ_information::simEmploys", res.data);
            const params2 = new URLSearchParams();
            params2.append("user_idx", user_idx);
            axios.post(`${CONFIG.SERVER_HOST}/_get_member_profile`,params2).then((res) => {
                if(res.data.retvalue === 1) {
                  console.log(res.data.results[0].mem_name);
                  setMemName(res.data.results[0]?.mem_name);
                } 
            });  
            setEmContext(res.data.results?.em_main_task);
          }
        });
    }
  }, [varEmployIndex]);

  //등록된 공고내역 sel 선택(변경)
  const onChangeEmployTitle = (e) => {
    const items = e.target.value.split('|');
    setEmployTitle(items[0])
    setEmployIndex(items[1])
    setEmployTasks(items[2])
    companyName.current = items[3];
    if (items[0] === "0") {
      setEmployTitle("")
      setEmployIndex(0)
      setEmployTasks("")
      companyName.current = mcm_name
    }
    // 기본 채용공고 선택 X, 다른 셀렉트박스 선택했다가 다시 돌아간 경우
    if (items[0] === "1") {
      setEmployTitle("");
      setEmployIndex(1);
      setEmployTasks("")
    }
  }
  //직종
  const onInputEmployTasks = (e) => {
    setEmployTasks(e.currentTarget.value);
  }
  //채용제목 직접입력
  const onInputEmployTitle = (e) => {
    setEmployTitle(e.currentTarget.value);
  }
  //제안내용
  const onInputEmployComment = (e) => {
    setEmployComment(e.currentTarget.value);
  }

  //제안발송------
  const onSendingPopupSuggestion = (e) => {
    if (varEmployIndex === 1) {
      alert('제안하실 채용공고를 선택하세요.');
      return;
    }
    if (varEmployTitle === "") {
      alert("채용 공고명을 입력해주세요.")
      return
    }
    if (varEmployTasks === "") {
      alert("모집하는 직종을 입력해주세요.")
      return
    }
    if (varEmployComment === '') {
      alert('제안 하실 상세 내용을 작성해주세요.');
      return;
    }
    try {
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("re_idx", varResumeIndex);
      params.append("mcm_idx", mcm_idx);
      params.append("em_idx", varEmployIndex);
      params.append("em_title", varEmployTitle);
      params.append("em_tasks", varEmployTasks);
      params.append("crs_comment", varEmployComment);
      params.append("user_name", userName);
      params.append("com_name", companyName.current);
      //2023-03-04 제안하기 URL 변경(제안받은 내역으로....), 백엔드에서 제안 insert 후 url에 idx 추가
      //params.append("url", `${CONFIG.URL}/MyRecruit/MainView/${varEmployIndex}/0`);
      params.append("url", `${CONFIG.URL}/MyHeding/MyHeding3/`);
      axios.post(`${CONFIG.SERVER_HOST}/_update_sending_suggest`, params).then((res) => {
        window.alert(res.data.message);
        if (res.data.retvalue === 1) {
          //제안발송 ---> 이용권에서 제안서 발송시 company_voucher에서 mcc_sending에서 차감.
          //2022-10-30 통합 테이블(company_voucher) 사용안함, 주문서(voucher_orders) 단위로 차감
          const copyData = { ...varUsingPass };
          copyData.mcc_sending = copyData.mcc_sending - 1;
          setUsingPass(copyData);
          //console.log(copyData);
          setEmployComment("")
          //팝업창 닫기
          setShowPopupSuggest('popup');

          setSuggestionAllowed(1);
          setPopup(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /* 2022-08-21 주석 - KSR
  const onSelectEmployTitle = (e) => {
    let value = Number(e.currentTarget.value);
    let selTitle = document.getElementById('sel_title');
    let inpTitle = document.getElementById('inp_title');
    let inpTasks = document.getElementById('inp_tasks');
    let txtTitle = document.getElementById('txt_title');
    if(value === 0) {
      selTitle.style.display = "none";
      inpTitle.style.display = "block";
      inpTasks.style.display = "block";
      txtTitle.style.display = "block";
    } else {
      selTitle.style.display = "block";
      inpTitle.style.display = "none";
      txtTitle.style.display = "none";
      inpTasks.style.display = "none";
    }
  }
  */

  return (
    <div id="popupSuggest" className={varShowPopupSuggest}>
      <div className="popup_wrap popup01">
        <section className="title_wrap">
          <h1>제안 보내기</h1>
        </section>
        <form className="content_wrap">
          <fieldset className="only_input">
            {/* 2022-08-21 직접입력 제거 - KSR
            <div className="field">
              <select defaultValue={1} onChange={(e)=>onSelectEmployTitle(e)}>
                <option value="1" >기업의 진행중인 채용공고 내역</option>
                <option value="0">직접입력</option>
              </select>
            </div>
            */}
            {/* <!-- 공고내역 select_box로 선택시--
            2022-11-14 인재DB > 후보자관리 에서 왔을 경우 채용 번호가 있으므로 고정(변경불가)
            -------------------------------------------------------------> */}
            <div className="field" id="sel_title">
              <select /* defaultValue={"1|"} */ onChange={(e) => onChangeEmployTitle(e)} value={varCompanyEmploys.em_idx}>
                <option value="1|">채용공고선택</option>
                <option value="0|">직접입력</option>
                {varCompanyEmploys.map((employItem, index) => (
                  <option key={"pemp_" + index} value={`${employItem.em_title}|${employItem.em_idx}|${employItem.tasks}|${employItem.com_name}`} selected={varSugEmIndex === employItem.em_idx ? true : false} >{employItem.em_title === null ? "" : em_titleLength(`${employItem.com_name} - ${employItem.em_title}`, 34, '...')}</option>
                ))}
              </select>
            </div>
            {/* <!-- 공고내역 직접입력 선택시--> 
            2022-12-29 직접 입력 선택 시 입력창 표시 */}
            {varEmployIndex === 0 &&
              <>
                <div className="field" id="inp_title" /* style={{ display: "none" }} */>
                  <input type="text" value={varEmployTitle} placeholder="채용 공고명을 입력해주세요." onChange={(e) => onInputEmployTitle(e)} />
                </div>

                <div className="field" id="inp_tasks" /* style={{ display: "none" }} */>
                  <input type="text" value={varEmployTasks} placeholder="모집하는 직종을 입력해주세요." onChange={(e) => onInputEmployTasks(e)} />
                </div>
              </>
            }
            {/* <!-- //공고내역 직접입력 선택시--> */}
            <div className="field" id="txt_title" /* style={{display:"none"}} */>
              <textarea className="textarea_h100" placeholder="제안 하실 상세 내용을 작성해주세요." rows="6" value={varEmployComment} onChange={(e) => onInputEmployComment(e)}></textarea>
            </div>
            {emContext ?
              <div className="field" id="txt_title">
                <h2>업무 내용</h2>
                <ul>
                  <li className="text-lines">{unescapeHtml(emContext)}</li>
                </ul>
              </div>
              :
              null
            }

            {MemName ?

              <div className="field" id="txt_title">
                <h2>담당자</h2>
                <ul>
                  <li>{unescapeHtml(MemName)}</li>
                </ul>
              </div>
              :
              null
            }

          </fieldset>
          <ul className="list_dot basics">
            <li>제안보내기를 통해 인재의 이름 및 연락처 공개 여부를 묻습니다. </li>
            <li>제안 발송 시 이용권 1건이 차감됩니다.</li>
            <li>인재가 제안 수락 시 이력서의 블라인드 정보가 공개됩니다. </li>
          </ul>
        </form>
        <div className="button_wrap">
          <div className="big_button_wrap two">
            <button className="btn white BtnClose" onClick={() => setShowPopupSuggest('popup')}>취소</button>
            <button className="btn" onClick={(e) => onSendingPopupSuggestion(e)}>제안 보내기</button>
          </div>
          <div className="close_button_wrap">
            <button onClick={() => { setShowPopupSuggest('popup'); setPopup(false); }}>닫기 X</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupSuggest;