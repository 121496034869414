/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CONFIG from '../../configs/configs'
import QRCode from 'qrcode'
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

//컨퍼넌트
import { changeMobileFormat, ChangeDateString, randomString, careerBetweenDays } from 'components/Common/Common'
//이미지
import qrImage from '../../img/sub/qr.png';
import hedingImage from '../../img/common/logo.png';

const ResumePdfView2 = () => {
  const navigator = useNavigate();
	const {mem_idx, type} = useParams();
  const [varMyPersonality, setMyPersonality] = useState({}); //개인정보
  const [varMyCareerList, setMyCareerList] = useState([]); //인증경력리스트
  const [rec_rdate, setRec_rdate] = useState(''); //발급일
  const [varQRCode, setQRCode] = useState(qrImage);
  const [LasrUpdateDate, setLasrUpdateDate] = useState('');

  //나의 검증 경력 가져오기
  const getMyCareer = async () => {
    try {

      const params = new URLSearchParams();
      params.append("user_idx", mem_idx);
      params.append("re_code", '');
      params.append("type", 'web');
      await axios.post(`${CONFIG.SERVER_HOST}/_get_my_career_list`, params).then((res) => {
        console.log(res.data);
        if (res.data.retvalue === 1) {
          console.log(res.data.members);
          setMyPersonality(res.data.members);
          const arr = [];
            res.data.careers.map((el) => {
              if (el?.rec_rdate) {
                arr.push(moment(el?.rec_rdate).format('YYYY-MM-DD'));
              }
            });

            const LUD = arr.reduce((prev, curr) => {
              // 이전것과 비교해 더 큰 것을 리턴
              return new Date(prev).getTime() <= new Date(curr).getTime() ? curr : prev;
            });

            setLasrUpdateDate(LUD);
            
          if (res.data.careers.length > 0) {
            setMyCareerList(res.data.careers);
            
            //QR코드 생성 --> 대표 이력서 번호
            MakeQRCode(mem_idx);
            setRec_rdate(res.data.rec_rdate);
          } else {
            setMyCareerList([]);
            alert('등록된 인증 경력이 없습니다. 인증경력을 조회 후 확인해주세요.');
            navigator('/CareerCertificationInquiry');
          }

        } else {
          setMyPersonality({});
          setMyCareerList([]);
          alert('등록된 인증 경력이 없습니다. 인증경력을 조회 후 확인해주세요.');
          navigator('/CareerCertificationInquiry');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  //QR코드 만들기
  const MakeQRCode = (mem_idx) => {
    console.log('re_code :', mem_idx);
    QRCode.toDataURL(`${CONFIG.PdfBaseUri2}/${mem_idx}/qr`).then((data) => {
      setQRCode(data);
    });
  }

  useEffect(() => {
    getMyCareer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
        <article className="career_cont_area">
          <section className="certifi_box PDF">
            <article className="certificate">
              <div className="certifi_top_img_wrap">
                <img className="logo" src={hedingImage} alt="" />
                {type === 'web' ?
                  <img className="qr-image" src={varQRCode} alt="" />
                  :null
                }
                
              </div>
              <div className="certifi_top_wrap">
                <h1 className="certifi_title">경&nbsp;&nbsp;&nbsp;력&nbsp;&nbsp;&nbsp;증&nbsp;&nbsp;&nbsp;명&nbsp;&nbsp;&nbsp;서</h1>
                <table className="table_career">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{varMyPersonality.mem_name}</td>
                      <th>생년월일</th>
                      <td>{ChangeDateString(varMyPersonality.mem_birthday)}</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>{changeMobileFormat(varMyPersonality.mem_mobile)}</td>
                      <th>이메일</th>
                      <td>{varMyPersonality.mem_email}</td>
                    </tr>
                  </tbody>
                </table>
                <h2 className="caeer_detail_title">상세경력</h2>
                <table className="table_career detail">
                  <thead>
                    <tr>
                      <th>사업장명칭</th><th>기간</th><th>경력</th>
                    </tr>
                  </thead>
                  <tbody>
                    {varMyCareerList.length > 0 && varMyCareerList.filter((e) => e.rec_company && e.rec_sdate).map((career, index) => {
                      const sDate = career.rec_sdate ? ChangeDateString(career.rec_sdate.substr(0, 7)) : '';
                      const eDate = career.rec_edate ? ChangeDateString(career.rec_edate.substr(0, 7)) : '';
                      return (
                        <tr key={"career_" + index}>
                          <td>{career.rec_company}</td>
                          <td>{sDate}&nbsp;&nbsp;~&nbsp;&nbsp; {career.rec_state === 1 ? "현재" : eDate}
                          </td>
                          <td>{careerBetweenDays(career.rec_edate, career.rec_sdate)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="certifi_bottom_wrap">
                <h1>위와 같은 건강 보험 자격득실확인내역을 증명합니다.</h1>
                <h2>(주)더라이징스타헤딩<span className="stamp">(인)<i></i></span></h2>
                <p><h1>{LasrUpdateDate ? moment(LasrUpdateDate).format(CONFIG.formatStrOfKR) : ''}</h1></p>
                <p>이 증명서는 더라이징스타헤딩(www.heding.co.kr)홈페이지에서 발급된 문서입니다.<br />발급 문서의 진위여부 확인은 상단 QR Code 스캔을 통해 확인 할 수 있습니다.</p>
              </div>
            </article>
          </section>
        </article>
    </>
  )
}

export default ResumePdfView2;
