import React from "react";
import { Link } from 'react-router-dom';

function Detail_Menu({ mode, user_idx, mem_idx, varCategory, setCategory }) {
    return (
      <React.Fragment>
        <div className="menu user_manage">
          {Number(mode) === 0 ?
          <>
            <Link className={varCategory===1 ? "active" : ""} to={`/admin/userinfo/${user_idx}`} onClick={()=>setCategory(1)}>회원 정보</Link>
            <Link className={varCategory===2 ? "active" : ""} to={`/admin/userinfo/${user_idx}/resume`} onClick={()=>setCategory(2)}>이력서 정보</Link>
            <Link className={varCategory===3 ? "active" : ""} to={`/admin/userinfo/${user_idx}/recruitment_support`} onClick={()=>setCategory(3)}>채용 지원 현황</Link>
            <Link className={varCategory===4 ? "active" : ""} to={`/admin/userinfo/${user_idx}/proposal_received`} onClick={()=>setCategory(4)}>받은 제안 현황</Link>
            <Link className={varCategory===5 ? "active" : ""} to={`/admin/userinfo/${user_idx}/recommend_acquaintance`} onClick={()=>setCategory(5)}>지인 추천 현황</Link>
          </>
          :Number(mode) === 1 ?
          <>
            <Link className={varCategory===1 ? "active" : ""} to={`/admin/corporate_member_info/${user_idx}/${mem_idx}`} onClick={()=>setCategory(1)}>회원 정보</Link>
            <Link className={varCategory===2 ? "active" : ""} to={`/admin/corporate_member_info/${user_idx}/${mem_idx}/voucher_list`} onClick={()=>setCategory(2)}>이용권 정보</Link>
            <Link className={varCategory===3 ? "active" : ""} to={`/admin/corporate_member_info/${user_idx}/${mem_idx}/sub_account`} onClick={()=>setCategory(3)}>서브계정 정보</Link>
            <Link className={varCategory===4 ? "active" : ""} to={`/admin/corporate_member_info/${user_idx}/${mem_idx}/notification_application`} onClick={()=>setCategory(4)}>공고 신청 관리</Link>
          </>
          :Number(mode) === 2 ?
          <>
            <Link className={varCategory===1 ? "active" : ""} to={`/admin/headhunter_free_info/${user_idx}/${mem_idx}/${mode}`} onClick={()=>setCategory(1)}>회원 정보</Link>
            <Link className={varCategory===2 ? "active" : ""} to={`/admin/headhunter_free_info/${user_idx}/${mem_idx}/${mode}/headhuntet_recruitment_support`} onClick={()=>setCategory(2)}>채용 추천 현황</Link>
          </>
          :
          <>
            <Link className={varCategory===1 ? "active" : ""} to={`/admin/headhunter_free_info/${user_idx}/${mem_idx}/${mode}`} onClick={()=>setCategory(1)}>회원 정보</Link>
            <Link className={varCategory===2 ? "active" : ""} to={`/admin/headhunter_free_info/${user_idx}/${mem_idx}/${mode}/headhunter_sub_account`} onClick={()=>setCategory(2)}>서브계정 정보</Link>
            <Link className={varCategory===3 ? "active" : ""} to={`/admin/headhunter_free_info/${user_idx}/${mem_idx}/${mode}/headhunter_application_management`} onClick={()=>setCategory(3)}>공고 신청관리</Link>
            <Link className={varCategory===4 ? "active" : ""} to={`/admin/headhunter_free_info/${user_idx}/${mem_idx}/${mode}/headhuntet_recruitment_support`} onClick={()=>setCategory(4)}>채용 추천 현황</Link>
          </>
          }
        </div>
        </React.Fragment>
    )
}

export default Detail_Menu;