import React, { useState, useEffect } from "react";
import axios from 'axios';
import CONFIG from 'configs/configs';
import { Link } from "react-router-dom";
//css

//components
import Paging from '../Layout/Paging'
import { remainDays } from '../Common/Common'
//이미지
import LogoCompany from 'img/sub/@thumnail.png';
import IconNew from 'img/sub/icon_new.png';
import IconBg from 'img/sub/icon_gray_img01.png';

/*채용 지원 현황*/
function CompanyBookmarkList({ varTabs,varEmployList,setEmployList }) {
  const user_idx = localStorage.getItem('mem_idx');
  const mcm_idx = Number(localStorage.getItem("mcm_idx"));
  const imgCompanyFolder = `${CONFIG.SERVER_HOST}/mcm_image/`;

  //페이지
  const [varTotalCount,setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum,setPageNum] = useState(1);

  //지원목록   
  const [varPageEmployList, setPageEmployList] = useState([]);
  //const [varEmployIndex,setEmployIndex] = useState(0); 

  const [varCompanyMembers,setCompanyMembers] = useState([]); 
  const [varCompanyMemberIndex,setCompanyMemberIndex] = useState(0);  //제안보낸 직원번호
  
  const readEmployList =() => {
      console.log(varTabs,mcm_idx);
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("mode", varTabs);
      axios.post(`${CONFIG.SERVER_HOST}/_get_company_interest_employ`,params).then((res) => {
          if(res.data.retvalue === 1) {
              //관심기업목록
              setTotalCount(res.data.interests.length);
              console.log(res.data.interests);
              setEmployList(res.data.interests); //원본 - 전체
              setPageEmployList(res.data.interests); //필터링 배열
              //기업직원목록
              setCompanyMembers(res.data.members);
          }
      });
  }
  useEffect(() => {
      readEmployList();
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    readEmployList();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[varTabs]);
  
  const filteringResumes = () => {
    //채용공고별
    var sortData = [...varEmployList];
    //제안한 직원별
    if(varCompanyMemberIndex > 0) {
      sortData = sortData.filter(element=>element.mem_idx === varCompanyMemberIndex);
    }
    setTotalCount(sortData.length);
    //페이지
    const startNum = (varPageNum-1)*varPageSize;
    const endNum = startNum + varPageSize;
    //console.log(startNum,endNum);
    sortData = sortData.slice(startNum,endNum);
    setPageEmployList(sortData);
  }
  useEffect(() => {
    filteringResumes();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum,varCompanyMemberIndex,varEmployList,varPageSize]);

  //관심채용공고등록
  const onSelectEmployInterestHandler = (e,em_idx) => {
      const ichecked = Number(e.currentTarget.checked);
      const params = new URLSearchParams();
      params.append("user_idx", user_idx);
      params.append("em_idx", em_idx);
      params.append("checked", ichecked);
      axios.post(`${CONFIG.SERVER_HOST}/_update_my_interest_employ`,params).then((res) => {
          window.alert(res.data.message);
          if(res.data.retvalue === 1) {
            //원본배열
            console.log(varEmployList);
            if(varTabs === 0 && !ichecked) { //마이헤딩-관심채용공고관리-관심채용을 빼면
                const copyData = varEmployList.filter(element => element.em_idx !== em_idx);
                setEmployList(copyData);
                console.log(copyData);
            } else {
                const finsIndex = varEmployList.findIndex(element => element.em_idx === em_idx);
                console.log(finsIndex);
                let copyData = [...varEmployList];
                if(res.data.insertMode === 1) {
                  copyData[finsIndex]={...copyData[finsIndex],mie_idx:res.data.insert_id,mie_status:ichecked};
                } else {
                  copyData[finsIndex]={...copyData[finsIndex],mie_status:ichecked};
                }
                setEmployList(copyData);
                console.log(copyData);
            }
          }

      });

  }
  //직원선택시 -- 우측부 이력서 재표시
  const onChangeCompanyMember = (e) => {
    const com_idx = e.currentTarget.value;
    setCompanyMemberIndex(com_idx);
  }

  return (
      <div className="recruitmanlist">
          <div className="RightSelect">
            직원 : <select style={{width:'150px'}} onChange={(e)=>onChangeCompanyMember(e)}>
              <option value="0" selected>전체</option> 
              {varCompanyMembers?.map((members,index) => (
                <option key={"emp_"+index} value={members?.cmm_mem_idx}>{members?.mem_nickname}</option>
              ))}
            </select>
          </div>
          <ul style={{marginTop:"10px"}}>
              {varPageEmployList?.map((jobPosting,index) => (
              <li key={"posting_"+index}>
                  <Link to={`/MyRecruit/MainView/${jobPosting.em_idx}/${jobPosting.em_headhunter}`}>
                      <div className="logobox">
                          <img src={jobPosting?.mcm_image === 0?LogoCompany:imgCompanyFolder+jobPosting?.mcm_ufile} style={{borderRadius:"25px"}} alt="" />
                      </div>
                      {/* 10일까지만 new 표시 */}
                      {varTabs===0 && remainDays(jobPosting?.rdate) < 10?
                          <div className="newicon"><img src={IconNew} alt="" /></div>:null
                      }
                      <div className="righticon">
                          <img src={IconBg} className="imgtop" alt="" />
                          <div className="imgcons">{jobPosting?.em_type===1?"공개채용":jobPosting?.em_type===2?"비공개채용":"실시간 채용관"}</div>
                      </div>
                      <div className="cons">
                          <div className="companyname">{jobPosting?.mcm_name}</div>
                          <div className="tit">{jobPosting?.em_title}</div>
                          <div className="binfo">
                              <div className="area"><span>{jobPosting?.vr_name}</span><span>{jobPosting?.em_careers===0?"경력무관":jobPosting?.em_careers===1?"신입":"경력"}</span></div>
                              <div className="won">총 보상금 <b>{jobPosting?.em_reward_applicant/10000} 만원</b></div>
                          </div>
                      </div>
                  </Link>			
                  <div className="btnstar">
                      <input
                          type="checkbox"
                          name="chk1"
                          id={"agreechk"+index}
                          className="chk1"
                          onChange={(e)=>{onSelectEmployInterestHandler(e,jobPosting?.em_idx)}}
                          checked={jobPosting?.mie_status === 1?true:false}
                      />
                      <i></i>
                      <label htmlFor={"agreechk"+index}></label>
                  </div>
              </li>
              ))}
          </ul>
          <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
          </div>
      </div>
          
  );
}

export default CompanyBookmarkList;
