/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";

import RecruitTopSearch2 from "../../components/Recruit/RecruitTopSearch2";
import RecruitMainList2 from "../../components/Recruit/RecruitMainList2";

//import { resetNavSubMenu } from "../../components/Common/Common";
import axios from "axios";
import CONFIG from "configs/configs";

import Paging2 from "components/Layout/Paging2";

const MainList2 = ({ PageFolder, resetNavSubMenu }) => {
  //const location = useLocation();
  const navigate = useNavigate();
  const user_idx = localStorage.getItem("mem_idx");

  const { cate, page, pagesize, sort } = useParams();//채용구분,페이지,검색조건

  //검색조건
  const [varWorkingList, setWorkingList] = useState([]);  //직종
  const [varRegionList, setRegionList] = useState([]);    //지역
  const [varCareerList, setCareerList] = useState([]);    //경력
  const [varCareerDirectList, setCareerDirectList] = useState([]);
  const [totalList, setTotalList] = useState(false);

  //검색문자
  const [varSearchOption, setSearchOption] = useState("");
  const [varSearchString, setSearchString] = useState("");

  //상세검색 보이기
  const [varSearchDetail, setSearchDetail] = useState("");
  const [varPageSize, setPageSize] = useState(pagesize ? pagesize : 20);    //페이지당 레코드 수

  //검색실행
  const [varExecuteSearch, setExecuteSearch] = useState(0);
  const [employSearchLists, setEmploySearchLists] = useState([]); //검색 결과
  const [employSearchListsTotalCount, setEmploySearchListsTotalCount] = useState(0);  //검색 결과 수

  const [searchParams] = useSearchParams();  //Uri 쿼리스트링

  const baseUri = "/MyRecruit/MainList2";

  const getAxio = useCallback(async () => {
    if (cate === 0) {
      return;
    }

    const job = JSON.parse(searchParams.get('job'));
    const rgion = JSON.parse(searchParams.get('rgion'));
    const carrer = JSON.parse(searchParams.get('carrer'));
    const carr_add = JSON.parse(searchParams.get('carr_add'));
    const shString = searchParams.get('shString');//검색어

    const params = {
      user_idx: user_idx,
      em_headhunter: 0,//hunter,
      employType: cate,
      pageNum: page,
      pageSize: pagesize, //varPageSize,
      sortMode: sort,
      searchString: shString,//varSearchString,
      //workingList: varWorkingList,
      workingList: job,
      //regionList: varRegionList,
      regionList: rgion,
      careerList: carrer, //varCareerList,
      varCareerDirectList: carr_add, //varCareerDirectList,
    }

    await axios
      .post(`${CONFIG.SERVER_HOST}/_get_employ_search_lists`, params)
      .then((res) => {
        console.log(res.data)
        const resultList = res.data.list;
        setEmploySearchLists(resultList);
        setEmploySearchListsTotalCount(res.data.totalCount);
      }).catch((err) => {
        console.debug(err);
      });

  });

  //URL 이동
  useEffect(() => {
    // console.log("navigate~~~");
    // searchParams.get('job') && setWorkingList(JSON.parse(searchParams.get('job')));
    searchParams.get('job') && setWorkingList(JSON.parse(searchParams.get('job').replace(/Rn/g, "R&")));
    searchParams.get('rgion') && setRegionList(JSON.parse(searchParams.get('rgion')));
    searchParams.get('carrer') && setCareerList(JSON.parse(searchParams.get('carrer')));
    searchParams.get('carr_add') && setCareerDirectList(JSON.parse(searchParams.get('carr_add')));//경력 직접입력
    searchParams.get('shString') && setSearchString(searchParams.get('shString'));//검색어
    searchParams.get('shDetail') && setSearchDetail(searchParams.get('shDetail'));//상세검색 펼치기

    if (Number(cate) > 0) {
      console.log("서브메뉴~", cate - 1);
      resetNavSubMenu(0, Number(cate) - 1);//서브메뉴
    }

    getAxio();
  }, [navigate, searchParams])
  //검색버튼 클릭
  const onSearchingHandler = async (typ, data) => {
    console.log("검색 클릭");
    let pSize = pagesize;
    let sortMode = sort; // 0: 최신등록순 , 1:보상금순 
    let jsonJob = JSON.stringify(varWorkingList).replace(/&/g, "n");//직종
    let jsonRgion = JSON.stringify(varRegionList);//지역
    let jsonCarrer = JSON.stringify(varCareerList);//경력
    let jsonCarr_add = JSON.stringify(varCareerDirectList);//경력
    let shString = varSearchString;//검색어
    let cateMode = window.location.pathname.split("/")[3]; //2023-01-02 초기화 시 cate

    if (typ === "pagesize") {
      pSize = data;
    }
    if (typ === "sort") {
      sortMode = data;
    }
    if (typ === "job") {
      jsonJob = JSON.stringify(data).replace(/&/g, "n")
    }
    if (typ === "Rgion") {
      jsonRgion = JSON.stringify(data);
    }
    if (typ === "Carrer") {
      jsonCarrer = JSON.stringify(data);
    }
    if (typ === "CarrDD") {
      jsonCarr_add = JSON.stringify(data);
    }

    let parmas = "";
    if (typ === "clear") {
      pSize = 20;
      sortMode = 0;
      parmas = "";
    } else {
      parmas = `job=${jsonJob}&rgion=${jsonRgion}&carrer=${jsonCarrer}&carr_add=${jsonCarr_add}&shString=${shString}&shDetail=Y`;
    }
    navigate(`${baseUri}/${cateMode}/1/${pSize}/${sortMode}?${parmas}`);
  };

  //페이지 이동
  const goPage = (gPage) => {
    let jsonJob = JSON.stringify(varWorkingList);//직종
    let jsonRgion = JSON.stringify(varRegionList);//지역
    let jsonCarrer = JSON.stringify(varCareerList);//경력
    let jsonCarr_add = JSON.stringify(varCareerDirectList);//경력 직접입력
    let shString = varSearchString;//검색어

    const parmas = `job=${jsonJob}&rgion=${jsonRgion}&carrer=${jsonCarrer}&carr_add=${jsonCarr_add}&shString=${shString}&shDetail=${varSearchDetail}`;

    navigate(`${baseUri}/${cate}/${gPage}/${pagesize}/${sort}?${parmas}`);
  }

  return (
    <div className="center_wrap myrecruit">
      <section className="page_myrecruit">
        {/*채용정보 탭*/}
        <ul className="tab01 blue col4">
          {/* "전체" 선택 시 모든 li에 "active"클래스제거해주세요*/}
          <li className={cate === "1" ? "active" : ""}><Link to={`/MyRecruit/MainList2/1/1/20/0`}>공개채용</Link></li>{/*해당 탭 선택 시 li에 클래스 "active" 추가해주세요*/}
          <li className={cate === "2" ? "active" : ""}><Link to={`/MyRecruit/MainList2/2/1/20/0`}>비공개채용</Link></li>
          <li className={cate === "3" ? "active" : ""}><Link to={`/MyRecruit/MainList2/3/1/20/0`}>실시간 채용관</Link></li>
          <li className={cate === "4" ? "active" : ""}><Link to={`/MyRecruit/MainList2/4/1/20/0`}>헤드헌터 전용관</Link></li>
        </ul>
        <RecruitTopSearch2
          varWorkingList={varWorkingList} setWorkingList={setWorkingList}
          varRegionList={varRegionList} setRegionList={setRegionList}
          varCareerList={varCareerList} setCareerList={setCareerList}
          varCareerDirectList={varCareerDirectList} setCareerDirectList={setCareerDirectList}
          varSearchOption={varSearchOption} setSearchOption={setSearchOption}
          varSearchString={varSearchString} setSearchString={setSearchString}
          cate={cate}
          varSortMode={sort}
          varPageSize={varPageSize} setPageSize={setPageSize}
          onSearchingHandler={onSearchingHandler}
          page={page}
          varSearchDetail={varSearchDetail} setSearchDetail={setSearchDetail}
        />
        <RecruitMainList2
          PageFolder={PageFolder}
          varWorkingList={varWorkingList}
          varRegionList={varRegionList}
          varCareerList={varCareerList}
          varSearchOption={varSearchOption}
          varSearchString={varSearchString}
          cate={cate}
          varSortMode={sort}
          varPageSize={varPageSize}
          varExecuteSearch={varExecuteSearch}
          setExecuteSearch={setExecuteSearch}
          totalList={totalList}
          setTotalList={setTotalList}
          employSearchLists={employSearchLists}
          employSearchListsTotalCount={employSearchListsTotalCount}
          page={page}
          goPage={goPage}
          getAxio={getAxio}
        />
      </section>
      <Paging2
        totalCount={employSearchListsTotalCount}
        pageSize={varPageSize}
        curPage={page}
        goPage={goPage}
      />
    </div>
  );
};

export default MainList2;