/* eslint-disable array-callback-return */
import React, { useEffect, useState }from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//css
//components
import CONFIG from 'configs/configs';
//이미지

/*채용 지원 현황*/
function MyApply() {
    // const user_idx = localStorage.getItem('mem_idx')
    // const [varTotalApply, setTotalApply] = useState(0);
    // const [varDcoumentApply, setDcoumentApply] = useState(0);
    // const [varDocumentPass, setDocumentPass] = useState(0);
    // const [varLastSucess, setLastSucess] = useState(0);
    // const [varCancelApply, setCancelApply] = useState(0);
    // const [varFailedApply, setFailedApply] = useState(0);

    // useEffect(() => {
    //     console.log('user_idx',user_idx);
    //     const param = new URLSearchParams();
    //     param.append('user_idx', user_idx);
    //     axios.post(`${CONFIG.SERVER_HOST}/_get_my_apply`,param).then((res) => {
    //         if(res.data.retvalue === 1) {
    //             console.log(res.data);
    //             res.data.results.map(data => {   
    //                 console.log('count',data.ea_idx,data.ea_checking);             
    //                 if(data.ea_checking === 0 || data.ea_checking === 1) setDcoumentApply(prevCount => prevCount+1);
    //                 else if(data.ea_checking === 2 || data.ea_checking === 3) setDocumentPass(prevCount => prevCount+1);
    //                 else if(data.ea_checking === 4 || data.ea_checking === 5) setLastSucess(prevCount => prevCount+1);
    //                 else if(data.ea_checking === -1) setCancelApply(prevCount => prevCount+1);
    //                 else if(data.ea_checking === -2) setFailedApply(prevCount => prevCount+1);
    //                 setTotalApply(prevCount => prevCount+1);
    //             });
    //         } else {
    //             window.alert('검색된 정보가 없습니다.');
    //         }
    //     });
    //      // eslint-disable-next-line react-hooks/exhaustive-deps  
    // },[]); 

    const user_idx = localStorage.getItem('mem_idx');

    const [varBeforeViewing, setBeforeViewing] = useState(0);
    const [varDcoumentChecking, setDcoumentChecking] = useState(0);
    const [varDocumentPass, setDocumentPass] = useState(0);
    const [varMeetingPass, setMeetingPass] = useState(0);
    const [varLastSucess, setLastSucess] = useState(0);
    const [varFailedApply, setFailedApply] = useState(0);
    const [varEmployed, setEmployed] = useState(0);
    const [varRetiredEarly, setRetiredEarly] = useState(0);

    useEffect(() => {
        const params = new URLSearchParams();
        params.append("user_idx", user_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_apply`,params).then((res) => {
            if(res.data.retvalue === 1) {
                //console.log(res.data);
                res.data.results.map(data => {   
                    //console.log('count',data.ea_idx,data.ea_checking);             
                    if(data.ea_checking === 0) setBeforeViewing(prevCount => prevCount+1);
                    else if(data.ea_checking === 1) setDcoumentChecking(prevCount => prevCount+1);
                    else if(data.ea_checking === 2) setDocumentPass(prevCount => prevCount+1);
                    else if(data.ea_checking === 3) setMeetingPass(prevCount => prevCount+1);
                    else if(data.ea_checking === 4) setLastSucess(prevCount => prevCount+1);
                    else if(data.ea_checking === 5) setEmployed(prevCount => prevCount+1);
                    else if(data.ea_checking === -2) setFailedApply(prevCount => prevCount+1);
                    else if(data.ea_checking === -3) setRetiredEarly(prevCount => prevCount+1);
                });
            } else {
                window.alert('검색된 정보가 없습니다.');
            }
        });  
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

  return (
    <section className="my_apply">
        <h3 className="title">채용 지원 현황</h3>
        <ul className="state_wrap">
            <li className="state_box">
                <h4 className="title">미열람</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:0}}><p className="num point1">{varBeforeViewing}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">검토중</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:1}}><p className="num">{varDcoumentChecking}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">서류합격</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:2}}><p className="num">{varDocumentPass}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">면접합격</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:3}}><p className="num">{varMeetingPass}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">최종합격</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:4}}><p className="num point2">{varLastSucess}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">불합격</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:-2}}><p className="num">{varFailedApply}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">입사</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:5}}><p className="num">{varEmployed}</p></Link>
            </li>
            <li className="state_box">
                <h4 className="title">보증기간 내 퇴사</h4>
                <Link to={"/MyHeding/MyHeding2"} state={{status:-3}}><p className="num">{varRetiredEarly}</p></Link>
            </li>
        </ul>
    </section>
  );
}

export default MyApply;
