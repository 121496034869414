/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import JoinNormalC from "../../components/Member/JoinNormalC";
import JoinCompanyC from "../../components/Member/JoinCompanyC";
import JoinHeadHunter from "../../html/Member/JoinHeadHunter";
// css
import "css/Common.css";
import "css/Sub.css";
import "css/JoinUs.css";
import "css/Announcement.css";
import "css/Board.css";
import "css/Company.css";
import "css/MyCareer.css";
import "css/Davehan.css";
import BannerBottom from "components/Banner/BannerBottom";

const JoinNormal = () => {
  //페이지 분류
  const location = useLocation();
  const [varJoin, setJoin] = useState('User');

  //메뉴 조정하기
  useEffect(() => {
    const headObject = document.getElementById("head");
    const depth1Boxes = document.querySelectorAll(".depth1");

    for (let i = 0; i < depth1Boxes.length; i++) {
      depth1Boxes[i].addEventListener("mouseenter", () => {
        if (!headObject.classList.contains("on"))
          headObject.classList.add("on");
      });
      depth1Boxes[i].addEventListener("mouseleave", () => {
        if (headObject.classList.contains("on"))
          headObject.classList.remove("on");
      });
    }
  }, []);

  //페이지가 변경되면 - 탭을 위해
  useEffect(() => {
    const pathName = location.pathname.split('/');
    const pageMode = pathName[2] === undefined ? "User" : pathName[2];
    setJoin(pageMode);
  }, [location]);

  return (
    <>
      <Header />
      <main id="sub_wrap" className="center_wrap_member page_join">
        <h2 className="sub_content_title">회원가입</h2>
        <div className="tab01_wrap">
          <ul className="tab01">
            <li className={varJoin === "User" || varJoin === "" ? "active" : ""}>
              <Link to={"/JoinUs/User"}>일반 회원</Link>
            </li>
            <li className={varJoin === "Company" ? "active" : ""}>
              {localStorage.getItem('new') === 'true' ?
                <a onClick={() => { alert('SNS 회원 가입은 일반회원만 가능합니다.') }}>기업회원</a>
                :
                <Link to={"/JoinUs/Company"}>기업회원</Link>

              }
            </li>
            <li className={varJoin === "HeadHunter" || varJoin === "Freelancer" || varJoin === "SearchForm" ? "active" : ""}>
              {localStorage.getItem('new') === 'true' ?
                <a onClick={() => { alert('SNS 회원 가입은 일반회원만 가능합니다.') }}>헤드헌터 회원</a>
                :
                <Link to={"/JoinUs/HeadHunter"}>헤드헌터 회원</Link>
              }

            </li>
          </ul>
          <Routes>
            <Route path="/" element={<JoinNormalC />} />
            <Route path="User" element={<JoinNormalC />} />
            <Route path="Company" element={<JoinCompanyC />} />
            <Route path="HeadHunter/*" element={<JoinHeadHunter />} />
          </Routes>
        </div>
      </main>
      <BannerBottom />
      <Footer />
    </>
  );
};

export default JoinNormal;
