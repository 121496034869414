import React, { useState,useEffect }from "react";
import { useLocation, Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from 'configs/configs';

//컴포넌트
import { /*changeMobileFormat,*/ChangeMemberTypeString,addThousandPoint/*,ChangeDateString*/ } from '../Common/Common';

/*채용 지원 현황*/
function MyMoneyView({ getSubItem,backPage }) {   
    const location = useLocation();
    const [varMHInformation,setMHInformation] = useState({});
    var mh_idx = 0;
    if(location !== null && location.state !== null) {
        mh_idx = location.state.mh_idx;
    }
    //최초 - 헤딩머니 입출금내역 가져오기
    const getHedingMoneyInformation = async () => {
        try {
            const params = new URLSearchParams();
            params.append('mh_idx', mh_idx);
            await axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_information`,params).then((res) => {
                if(res.data.retvalue === 1) {
                    console.log(res.data.results);
                    if(res.data.results.length > 0) {
                        setMHInformation(res.data.results[0]);
                    }
                } else {
                    setMHInformation({});
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
      getSubItem(backPage);
      getHedingMoneyInformation();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

  return (
    <div className="container">
        <section className="box box_content">
            <h3 className="my_title">{varMHInformation.mh_type === 3 ? "상세 내역" : "적립 상세 내역"}</h3>
            <ul className="table_view02">
                <li className="full">
                    <div className="title_field">회원구분</div>
                    <div className="info_field">{ChangeMemberTypeString(varMHInformation.mem_mode,varMHInformation.mem_hunter_type)}</div>
                </li>
                <li className="full">
                    <div className="title_field">회사명</div>
                    <div className="info_field">{varMHInformation.mcm_name === null ? "-" : varMHInformation.mcm_name }</div>
                </li>
                <li>
                    <div className="title_field">{varMHInformation.mh_type === 3 ? "회수금액" : "보상금액"}</div>
                    <div className="info_field">{addThousandPoint(varMHInformation.mh_paid)+" 원"}</div>
                </li>
                <li>
                    <div className="title_field">구분</div>
                    <div className="info_field">{varMHInformation.vhs_name}</div>
                </li>
                <li>
                    <div className="title_field">후보자</div>
                    <div className="info_field">{varMHInformation.candidate_name===null?"":varMHInformation.candidate_name}</div>
                </li>
                <li>
                    <div className="title_field">닉네임</div>
                    <div className="info_field">{varMHInformation.mem_nickname===null?"":varMHInformation.mem_nickname}</div>
                </li>
                <li>
                    <div className="title_field">입사일</div>
                    <div className="info_field">{varMHInformation.mh_type === 3 ? "-" : varMHInformation.mh_rdate===null?"-":varMHInformation.mh_rdate?.substring(0,10)}</div>
                </li>
                <li>
                    <div className="title_field">환급 신청 가능 일자</div>
                    <div className="info_field">{varMHInformation.mh_edate==null?"-":varMHInformation.mh_edate}</div>
                </li>
            </ul>
        </section>
        <div className="box_out_button_area one"><Link to={"../MyHeding"+backPage} className="btn white">목록으로</Link></div>
    </div>
  );
}

export default MyMoneyView;
