/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';
import Paging from '../../../components/Layout/Paging'
import {ChangeMoneyUnit} from "../../../components/Common/Common";
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';

function SuggestionList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜

    const [varSearch, setSearch] = useState("none");
    const [varSearchInput, setSearchInput] = useState("");

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize, setPageSize] = useState(10);
    const [selectChange, setSelectChange] = useState(false);
    const [varPageNum,setPageNum] = useState(1);

    const [varUserlist, setUserlist] = useState([]);
    const [startPageNum,setStartPageNum] = useState(1);
    const [endPageNum,setEndPageNum] = useState(10);
    const [varReset, setReset] = useState(false);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      RecommendationManagementList();
    },[]);

    const RecommendationManagementList = async() =>{
      const sendData = {
        'startDate':startDate,
        'endDate':endDate,
        'varSearch' : varSearch,
        'varSearchInput':varSearchInput,
        'pageSize':varPageSize,
        'startPageNum':startPageNum === 1 ? 0 : startPageNum,
        'endPageNum':endPageNum
      }
      await axios.post(`${CONFIG.SERVER_HOST}/_get_admin_employ_recommend_list`, sendData, CONFIG.header).then((res) => {
        // console.log(res.data.results)
          if(res.data.retvalue === 1){
            const datas = res.data.results;
            setTotalCount(res.data.totalcount);
            setUserlist(datas);
            setReset(false);
            if(selectChange === true){
              setSelectChange(false);
            }
          }else{
            alert(res.data.message);
          }
      })
    };

    const handleType = (e) =>{
        setSearch(e.currentTarget.value);
    };
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setSearch("");
        setSearchInput("");
        setPageNum(1);
        setReset(true);
    }

  useEffect(()=>{
    if(varReset){
      RecommendationManagementList();
    }
  }, [varReset]);
    //검색
    const handleSearchExecute = () => {
      setSelectChange(true);
    }
    
    const handlestartdate =(e)=>{
        setStartDate(e.currentTarget.value);
    }
    const handleenddate =(e)=>{
        setEndDate(e.currentTarget.value);
    }


    useEffect(() => {
      if(selectChange){
        RecommendationManagementList();
        setPageNum(1);
      }
    }, [selectChange]);
    
    useEffect(() => {
      RecommendationManagementList();
    }, [startPageNum]);
  
    useEffect(() => {
      console.log(selectChange);
      if(selectChange === false){
        setStartPageNum((varPageNum-1)*varPageSize === 1 ? 10 : varPageSize* (varPageNum-1));
        setEndPageNum((varPageNum-1)*varPageSize === 1 ? varPageSize : varPageSize*varPageNum);
      }
    },[varPageNum]);
    
  const settingPageSize = (e) =>{
    const {value} = e.target;
    setPageSize(Number(value));
    setSelectChange(true);
  };

    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="announcement"/>
        <Menu menuType="announcement" menu="suggestionlist"/>
        <div id="contents" className="ad_contents">
        <div className="tit">추천 관리
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>공고 관리<img src={arrImage} alt=""/>추천 관리 
            </div>
        </div>
        <div className="content">
            <div className="main-box">		
                <div className="search-box">
                    <div className="day-box">
                      <p>추천일</p>
                      <input type="date" name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                      ~
                      <input type="date" name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                    </div> 
                    <div className="f-right">		 
                      <select style={{width:"120px"}} name="search" value={varSearch} onChange={handleType}> 
                          <option value="none">전체</option>
                          <option value="em_title">채용제목</option>
                          <option value="mem_id">추천인 아이디</option>
                          <option value="er_name">후보자 명</option>
                      </select>
                      <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') handleSearchExecute(e)}} />
                      <button onClick={handleSearchExecute} className="btn">검색</button>
                      <button onClick={handleSearchClear} className="btn black">초기화</button>	
                    </div>		 
                </div>
            </div>
            <div className="list_top">
              <p>검색건수 : {varTotalCount}
                <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                    <option value="10">10개</option>
                    <option value="30">30개</option>
                    <option value="50">50개</option>
                    <option value="100">100개</option>
                </select>
              </p>
              <ExcelButton 
              startDate={startDate}
              endDate={endDate}
              search={varSearch}
              varSearchInput={varSearchInput}
              type={9}
              />
            </div>
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                      <col style={{width:"8%"}} />
                      <col style={{width:"18%"}} />
                      <col style={{width:"8%"}} />
                      <col style={{width:"12%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"15%"}}/>
                      <col style={{width:"8%"}}/>
                      <col style={{width:"16%"}}/>
                      <col style={{width:"7%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>채용제목</th>
                            <th>총 보상금</th>
                            <th>추천인 닉네임</th>
                            <th>후보자명</th>
                            <th>추천일</th>
                            <th>지원 여부</th>
                            <th>지원일</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                    {varTotalCount !== 0 ? varUserlist.map((user,i) => (
                      <tr key={i}>
                        <td>{user.ROWNUM}</td>
                        <td className="ellipsis">{user.em_title}</td>
                        <td className="ellipsis">{ChangeMoneyUnit(user.em_reward_recommend/10000)}</td>
                        <td className="ellipsis"><Link to={`/admin/userinfo/${user.mem_idx}/resume`} >{user.mem_nickname}</Link></td>
                        <td className="ellipsis">{user.er_name}</td>
                        <td className="ellipsis">{user.er_rdate}</td>
                        <td className="ellipsis">{user.ea_rdate === null ? "미지원" : "지원"}</td>
                        <td className="ellipsis">{user.ea_rdate === null ? "-" : user.ea_rdate }</td>
                        <td><Link to={`/admin/suggestioninfo/${user.mem_idx}/${user.em_idx}`} className="btn line black">상세</Link></td>
                      </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={8} className="ellipsis">불러올 데이터가 없습니다.</td>
                      </tr> 
                    }
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>
    </React.Fragment>

    )

}

export default SuggestionList;