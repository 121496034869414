import React, {useState} from 'react'

import '../../css/Reputation.css';
//이미지
import img_repu_main from "img/sub/repu_main.png";
import img_acq_preview from "img/sub/acq_preview.png";
import img_acq_preview_m from "img/sub/acq_preview_m.png";

// import PopAcqRepuLogin from "components/Career/PopAcqRepuLogin";
import { Link } from 'react-router-dom';
import PopAcqRepuRequest from "components/Career/PopAcqRepuRequest"; //POP '친구가 보는 나' 요청하기
import PopAcqRepuRequest2 from "components/Career/PopAcqRepuRequest2"; //POP '친구가 보는 나' 요청하기

const AcqRepuC = () => {

	const [enablePop, setEnablePop] = useState(false); //친구가 보는 나 1 팝업사용 여부
	const [enablePop2, setEnablePop2] = useState(false); //친구가 보는 나 2 팝업사용 여부
	const repuMyYN = localStorage.getItem('repuMy_YN'); //내가 보는 나 작성 여부


	return (
		<>
			{
				repuMyYN === 'Y' ?
			
			enablePop && <PopAcqRepuRequest setEnablePop={setEnablePop} />
			:
			enablePop2 && <PopAcqRepuRequest2 setEnablePop2={setEnablePop2} />
			}
			<section className="com_reputation com_repu_main p_acq_repu com_repu_gray_container center_wrap">
				<article className="repu_top_info_wrap">
					<img src={img_repu_main} alt="평판 이미지" />
					<p className="requtation_txt black">친구들이 보는 나는?<br />내가 보는 나와 얼마나 다른지 알 수 있습니다.</p>
					<h1 className="requtation_txt blue bold">친구들에게 테스트를 요청해보세요</h1>
					<div className="repu_go_btn_wrap">
						{/* <Link to={'../acquaintance-reputation/submit'} className="btn">내가 보는 나 시작하기</Link> */}

						<Link to={'../acquaintance-reputation/submit'} className="btn">내가 보는 나 시작하기</Link>
						<button className="btn navy" onClick={() => { 
							repuMyYN === 'Y' ? 
							setEnablePop(true) : setEnablePop2(true)
						
						}}>친구가 보는 나 요청하기</button>

						{/* 이 페이지는 친구가 보는 나 요청하기 버튼 필요 없음(내가 보는 나를 안 했기 때문) */}
						{/* <button className="btn navy">친구가 보는 나 요청하기</button>  */}
					</div>
				</article>
				<article className="repu_preview_wrap">
					<img src={img_acq_preview} className="view_pc" alt="평판 미리보기 이미지" />
					<img src={img_acq_preview_m} className="view_m" alt="평판 미리보기 이미지" />
					<div className="bg_wrap">
						<h1>HEDING 평판 미리보기입니다.<br />친구가 보는나, 내가 보는 나를 비교해보세요~</h1>
					</div>
				</article>
			</section>
			{/* <PopAcqRepuLogin /> */}
		</>
	)
}

export default AcqRepuC