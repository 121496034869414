/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useRef, useState } from "react";
import { ChangeSearchingDateFormat,addThousandPoint } from "../Common/Common";
//css
import '../../css/Davehan.css';

//datepicker
import DatePicker, { registerLocale } from "react-datepicker";
import { getYear, getMonth } from "date-fns"; // getYear, getMonth
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko'; // 한국어적용


registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');

function Personal({ varPhotoFile, setPhotoFile, varInputs, setInputs, setUploadFile, onChangeHandler, openfc }) {
  const [varLastYear, setLastYear] = useState(varInputs?.income_lyear === null || varInputs?.income_lyear === "" ? null : new Date(varInputs?.income_lyear + '-01-01'));

  //생일선택을 위한 년도와 월 지정
  const years = _.range(1950, getYear(new Date()) + 1, 1); // 수정 
  const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

  //회원사진 선택-----
  const hiddenFileInput = useRef(null);   //버튼+파일선택
  const onClickSelectFileHandler = () => { //버튼 클릭시 --> 파일선택
    hiddenFileInput.current.click();
  }
  const onSelectPhotoHandler = (e) => { //파일이 선택되면 --> 화면에 표시 --> 부모컴퍼넌트로 지정
    const uploadFile = e.target.files[0];

    //파일 이미지 변수에 저장 --> 화면에 표시
    setPhotoFile(URL.createObjectURL(uploadFile));

    //파일 변화 감지
    //setFileChangeValue(true);
    //업로드할 파일 저장
    setUploadFile(e.target.files[0]);
  };

  //생일 저장
  const onChangeDate = (date, sname) => {
    if(date === '' || date === null ){
      setInputs({ ...varInputs, [sname]:null });
    }else{
      let ndate = ChangeSearchingDateFormat(date); //날짜 변경
      if (sname === "income_lyear" && date !== null) {  //전년연봉
        ndate = date.getFullYear();
        setLastYear(date);
      }
      setInputs({ ...varInputs, [sname]: ndate });
    }
  };

  //메일서버선택
  const onChangeMailServerHandler = (e) => {
    let eserver = e.currentTarget.value;
    let serverAddress = document.getElementById('email2');
    if (eserver === "") {
      serverAddress.value = "";
      serverAddress.focus();
    } else {
      setInputs({ ...varInputs, 'email2': eserver });
    }
  }

  //인증 경력 가져오기 - 회원가입시 저장된 내역
  const openPopup = async(e) => {
    e.preventDefault();
    if(!window.confirm('근로소득을 업데이트 하시겠습니까?')) {
      return false;
    }
    openfc(1);
  };

  useEffect(()=>{
    if(varInputs?.birthday){
      const input_birthdate = document.getElementsByClassName('input_birthdate');
      // input_birthdate?.setAttribute('style', 'background-color:#f2f2f2;');
    }
  }, [varInputs?.birthday]);


  return (
    <>
      <input type="text" name="title" className="resume_title" id="title" placeholder="이력서 제목을 입력해주세요" value={varInputs?.title} onChange={onChangeHandler} />
      <h3 className="title_area">인적 사항</h3>
      <section className="profile_area">
        <div style={{display:"block"}}>
          <article className="profile_img">
            <div className="img">
              <img src={varPhotoFile} onClick={() => onClickSelectFileHandler()} alt="" />
              {/* <span>가로 157px  세로 200px</span> */}
            </div>
            <input type="file" className="add_file" id="add_img" ref={hiddenFileInput} accdept="image/*" onChange={(e) => onSelectPhotoHandler(e)} />
            <label htmlFor="add_img">이력서 사진 불러오기</label>
          </article>
          <p className="tip_field" style={{fontSize: '15px'}}>※ 이미지 크기 : 157 x 200px <span className="txt">(jpg, png만 가능)</span></p>
        </div>
        <ul className="info_area">
          <li>
            <span className="title_field">이름</span>
            <input type="text" readOnly name="name" className={varInputs?.name ? 'bg_on' : ''} placeholder="이름을 입력해주세요." onChange={e => onChangeHandler(e)} value={varInputs?.name} />
          </li>
          <li>
            <span className="title_field">성별</span>
              <input type="radio" name="gender" id="man" className="chk" onChange={e=>onChangeHandler(e)} value={0} checked={varInputs?.gender === 0} />
              <label htmlFor="man">남성</label>
              <input type="radio" name="gender" id="woman" className="chk" onChange={e=>onChangeHandler(e)} value={1} checked={varInputs?.gender === 1} />
              <label htmlFor="woman">여성</label>
          </li>
          <li className="date-birthday">
            <span className="title_field">생년월일</span>
            <DatePicker readOnly className="input_birthdate datepicker"
              renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
                  <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} className="date-year">
                    {years.map(option => (<option key={'bdy_' + option} value={option}>{option}</option>))}
                  </select>
                  <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} className="date-month" >
                    {months.map(option => (<option key={'bdm' + option} value={option}>{option}</option>))}
                  </select>
                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
                </div>
              )}
              locale={ko} name="birthday" dateFormat="yyyy.MM.dd" selected={new Date(varInputs?.birthday === null ? null :varInputs?.birthday)} onChange={(date) => onChangeDate(date, 'birthday')}
            
            />
          </li>
          <li>
            <span className="title_field">연락처</span>
            <input type="text" readOnly className={varInputs?.mobile ? 'bg_on' : ''} name="mobile" placeholder="- 를 미포함한 번호 입력" onChange={e => onChangeHandler(e)} value={varInputs?.mobile} />
          </li>
          <li className="date-income">
            <span className="title_field">근로소득<br />
              {/* <DatePicker showYearPicker dateFormat="yyyy" className="input_income_lyear"
                locale={ko} name="income_lyear" selected={varLastYear} onChange={(date) => onChangeDate(date, 'income_lyear')}
              /> */}
            </span>
            <input type="text" className="color_readOnly" name="income_last" onClick={e => openPopup(e)} style={{ textAlign: 'right' }} value={addThousandPoint(varInputs?.income_last)} />
            <span className="unit">원</span>
          </li>
          <li>
            <span className="title_field">현재연봉</span>
            <input type="text" className={varInputs?.income_this ? 'bg_on' : ''} name="income_this" onChange={e => onChangeHandler(e)} style={{ textAlign: 'right' }} value={addThousandPoint(varInputs?.income_this)} />
            <span className="unit">원</span>
          </li>
          <li className="tip_field full">※ 근로소득은 국세청 원천징수영수증 기준으로 인증된 금액입니다.</li>
          <li className="full email_field">
            <span className="title_field">이메일</span>
            <input type="text" readOnly className={varInputs?.email1 ? 'bg_on' : ''} name="email1" id="email1" onChange={e => onChangeHandler(e)} value={`${varInputs?.email1}@${varInputs?.email2}`} />
            {/* <span className="text">@</span>
            <input type="text" readOnly name="email2" className={varInputs?.email2 ? 'bg_on' : ''} id="email2" onChange={e => onChangeHandler(e)} value={varInputs?.email2} /> */}
            {/* <select className="style_select" name="select_email" onChange={e => onChangeMailServerHandler(e)}>
              <option value={""}>직접입력</option>
              <option value={"hotmail.com"}>hotmail.com</option>
              <option value={"hanmail.net"}>hanmail.net</option>
              <option value={"icloud.com"}>icloud.com</option>
              <option value={"naver.com"}>naver.com</option>
              <option value={"gmail.com"}>gmail.com</option>  
            </select> */}
          </li>
        </ul>
      </section>
    </>
  );
}

export default Personal;


