import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CONFIG from "configs/configs";

import { resetNavSubMenuForHeding } from "../Common/Common";

import CompanySubIDView from "./CompanySubIDView";
import CompanySubIDWrite from "./CompanySubIDWrite";

function MyHeding2({ getSubItem }) {
  //const user_idx = localStorage.getItem("mem_idx"); //현재 로그인한 기업회원(서브회원 포함)
  const com_idx = Number(localStorage.getItem("com_idx")); //기업대표자 회원번호
  const mcm_idx = Number(localStorage.getItem("mcm_idx")); //기업번호
  const mem_mode = localStorage.getItem("mem_mode"); //1:기업, 2:헤드헌팅
  const mem_hunter_type = localStorage.getItem("mem_hunter_type"); //mem_mode==2 --> 0:프리랜서/1:서치폼

  const [varMemberInformation, setMemberInformation] = useState({});
  const [varSubMemberList, setSubMemberList] = useState([]);
  const [varSubAuthList, setSubAuthList] = useState([]);
  const varSubAuth = { mem_idx:0,oldNickname:'',oldEmail:'', id:true, nickname:true, email:true, mobile:true };

  // const uNavigate = useNavigate();
  // const uri_path = window.location.pathname;
  // useEffect(() => {
  //   if (!mcm_idx || Number(mcm_idx)===0) {
  //     localStorage.setItem("re_url", uri_path);
  //     alert("로그인 후 이용 가능합니다.");
  //     uNavigate("/Login");
  //   }
  // },[mcm_idx, uri_path, uNavigate]);

  //최초 실행부
  const getSubCompanyMember = async () => {
    try {
      console.log("mcm_idx", mcm_idx);
      const params = new URLSearchParams();
      params.append("com_idx", com_idx);
      params.append("mcm_idx", mcm_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_submembers_company`, params).then((res) => {
          console.log(res.data.member[0]);
          if (res.data.member.length > 0) {
            setMemberInformation(res.data.member[0]);
          }
          //console.log("!!!!!!!!!!!",res.data.submembers);
          if (res.data.submembers.length > 0) {
            const datas = res.data.submembers.map((element, i) => ({
              mem_idx: element.mem_idx,
              mem_mode:mem_mode,
              mem_hunter_type:mem_hunter_type,
              mem_sub:com_idx,
              mem_id: element.mem_id,
              mem_pwd: "",
              mem_name: element.mem_name,
              mem_nickname: element.mem_nickname,
              mem_mobile: element.mem_mobile,
              mem_email: element.mem_email,
              cmm_status: element.cmm_status,
              cmm_department: element.cmm_department,
              cmm_position: element.cmm_position,
              mem_certification:element.mem_certification
            }));
            console.log(datas);
            setSubMemberList(datas);
            const authList = [];
            for(var i=0; i < datas.length; i++) {
              let copyData = {...varSubAuth};
              copyData.mem_idx = datas[i].mem_idx;
              copyData.oldNickname = datas[i].mem_nickname;
              copyData.oldEmail = datas[i].mem_email;
              authList.push(copyData);
            }
            setSubAuthList(authList);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubItem(2);
    resetNavSubMenuForHeding(2);
    getSubCompanyMember();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_com_myheding01">
        <div>
            <section className="box box_content">
                <CompanySubIDView varMemberInformation={varMemberInformation} />
                <CompanySubIDWrite
                    varSubAuthList={varSubAuthList} setSubAuthList={setSubAuthList}
                    varMemberInformation={varMemberInformation}
                    varSubMemberList={varSubMemberList} setSubMemberList={setSubMemberList}
                    getSubCompanyMember={getSubCompanyMember}
                />
            </section>
        </div>
    </div>
  );
}

export default MyHeding2;
