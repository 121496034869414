import React, { useState, useEffect } from "react";

const RecruitViewTit = ({ varEmployInfo }) => {
  const [varExpiredDate, setExpiredDate] = useState("");

  const calculateExpiredDays = () => {
    var dDayString = "";
    if (varEmployInfo.em_edate === null || varEmployInfo.em_deadliine === 1) {
      dDayString = "채용시 마감";
    } else {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const lastdayArray = varEmployInfo.em_edate === undefined ? "" : varEmployInfo.em_edate.split("-");
      const lastday = new Date(
        Number(lastdayArray[0]),
        Number(lastdayArray[1]),
        Number(lastdayArray[2])
      );
      const nowday = new Date(year, month, day);

      const btMS = lastday.getTime() - nowday.getTime();
      const btDay = Math.floor(btMS / (1000 * 60 * 60 * 24));
      btDay < 0 ? dDayString = "마감" : dDayString = "D - " + btDay;
    }
    setExpiredDate(dDayString);
  };

  //마감일 계산
  useEffect(() => {
    if (
      varEmployInfo !== undefined &&
      varEmployInfo !== null &&
      Object.keys(varEmployInfo).length > 0
    ) {
      calculateExpiredDays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varEmployInfo]);

  return varEmployInfo === undefined ||
    varEmployInfo === null ||
    Object.keys(varEmployInfo).length === 0 ? null : (
    <div className="left_wrap">
      <h2 className="title_field">{varEmployInfo.em_title}</h2>
      <ul>
        <li>
          {varEmployInfo.em_type === 2 ? varEmployInfo.mcm_name : varEmployInfo.com_name}
          <span className="table_notice_badge">
            {varEmployInfo.em_type === 1 ? "공개채용" : ""}
            {varEmployInfo.em_type === 2 ? "비공개채용" : ""}
            {varEmployInfo.em_type === 3 ? "실시간 채용관" : ""}
            {varEmployInfo.em_type === 4 ? "헤드헌터 전용관" : ""}
          </span>
        </li>
        <li>{varEmployInfo.vr_name}</li>
        <li>
          {varEmployInfo.em_careers === 0 ? "경력 무관" :
            varEmployInfo.em_careers === 1 ? "신입" :
              `경력 ${varEmployInfo.em_career_year}년`}
        </li>
        <li>{varExpiredDate}</li>
        {/* 채용마감시 체크 일때는 마감일자 (em_edate) 미노출 */}
        {varEmployInfo.em_deadline === 1
          ?
          ""
          :
          <li>{varEmployInfo.em_edate} 까지</li>
        }

        <li className="view_pc">
          <span className="table_notice_badge">
            {varEmployInfo.em_type === 1 ? "공개채용" : ""}
            {varEmployInfo.em_type === 2 ? "비공개채용" : ""}
            {varEmployInfo.em_type === 3 ? "실시간 채용관" : ""}
            {varEmployInfo.em_type === 4 ? "헤드헌터 전용관" : ""}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default RecruitViewTit;
