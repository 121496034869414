import React, { useEffect, useState } from 'react';
import CONFIG from '../../configs/configs';
import axios from 'axios';

//컴포넌트
import Paging from '../../components/Layout/Paging';
import FAQTab from '../../components/Tab/FAQTab';
// image
import SearchIcon_pc from "img/sub/search.png";
import SearchIcon_m from "img/sub/search_m.png";


function FAQList() {
  // const user_idx = localStorage.getItem('mem_idx')
  const [varInitData, setInitData] = useState([]);
  const [varFilteredData, setFilteredData] = useState([]);
  const [varPageApplyList, setPageApplyList] = useState([]);

  const [varCurrentTab, setCurrentTab] = useState(0);
  const [varOpenMenu, setOpenMenu] = useState([]);

  //페이지
  const [varTotalCount,setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum,setPageNum] = useState(1);

  //검색
  const [searchStr, setSearchStr] = useState('');

  //최초
  const getFaqBoard = async () => {
    try {
      await axios.post(`${CONFIG.SERVER_HOST}/userFaq`).then(res =>{
        //console.log(res.data.results)
        if(res.data.retvalue === 1) {
            setInitData(res.data.results);
            // setTotalCount(res.data.results.length)
        } else {
          setInitData([]);
        }
      });        
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getFaqBoard();
  }, [])

  useEffect(() => {
    const filterData = varInitData.filter((data)=> {
      // 전체일 경우
      if (varCurrentTab === 0) {
        return varInitData
      // 각 FAQ 탭 선택시
      } else {
        return data.vfs_idx === varCurrentTab
      }
    })
    setFilteredData(filterData);
    setTotalCount(filterData.length)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varCurrentTab,varInitData])

  const onSetChangeHandler = (e,idx) => {
    e.preventDefault();
    setCurrentTab(idx);
    setPageNum(1)
  }


  //답변보여주기
  const onOpenMenuHandler = (e, idx) => {
    if (varOpenMenu.includes(idx)) {
      setOpenMenu(varOpenMenu.filter(el => el !== idx))
    } else if (!varOpenMenu.includes(idx)) {
      setOpenMenu([...varOpenMenu, idx])
    }
  }
  
  //검색어 필터링
  const handleChangeSearchInput = async (e) => {
    const searchValue = e.target.value;
    setSearchStr(searchValue)
  }

  //검색어 post요청&응답
  const handleSearchInput = async (e) => {
    // if(!searchStr){
    //   alert('검색어를 입력해 주세요.');
    //   return false;
    // }
    const params = new URLSearchParams();
    params.append("searchStr", searchStr);

    await axios.post(`${CONFIG.SERVER_HOST}/search_faq`, params).then((res) => {
      if(res.data.retvalue === 1){
        setInitData(res.data.results);
      }else{
        setInitData([]);
      }
    })
  }
  
  //페이지 변경
  const getDataPerPage = () => {
    //선택된 페이지안의 데이타 표시
    if(varPageNum === 0 || varFilteredData.length === 0){
      setPageApplyList([]);
      return;
    } 
    var copyData = [...varFilteredData];
    const startNum = (varPageNum-1)*varPageSize;
    const endNum = startNum + varPageSize;
    copyData = copyData.slice(startNum,endNum);
    console.log(copyData);
    setPageApplyList(copyData);
  }
  //표시할 페이지가 바뀌면
  useEffect(() => {
      getDataPerPage();
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[varPageNum,varFilteredData]); 

  return (
    <>
        <FAQTab varCurrentTab={varCurrentTab} onSetChangeHandler={onSetChangeHandler}/>
        <section className="faq">
          <div>
            <article className="search_input_wrap">
                <input type="text" placeholder="검색어를 입력해주세요." name="searchStr" value={searchStr} onChange={(e) => handleChangeSearchInput(e)} onKeyDown={(e) => {if (e.key === 'Enter') handleSearchInput(e)}}
                />
            <button className="btn_search" type="button" onClick={(e) => handleSearchInput(e)}>
              <img className="view_pc" src={SearchIcon_pc} alt="" />
              <img className="view_m" src={SearchIcon_m} alt="" />
            </button>
						</article>
          </div>
            {varPageApplyList?.map((data, i) => (
            <article className={varOpenMenu.includes(data?.board_idx) ? 'item open' : 'item'}
                key={data?.board_idx} onClick={e=>onOpenMenuHandler(e, data.board_idx)}>
                <div className="q">{data.board_question}</div>
                <div className="a">{data.board_answer}</div>
            </article>
            ))}
        </section>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
    </>
  )
}

export default FAQList
