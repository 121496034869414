/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css"
import { ChangeSearchingDateFormat } from '../Common/Common'
import CustomDatePicker from "components/Common/CustomDatePicker";

function CompanyVoucherSearch({ varBuySearchOptions, setBuySearchOptions, onSearchHandler }) {
  //문자형 날짜를 date형으로 변경
  const [varStartDate, setStartDate] = useState(varBuySearchOptions.sdate === null ? null : new Date(varBuySearchOptions.sdate));
  const [varEndDate, setEndDate] = useState(varBuySearchOptions.edate === null ? null : new Date(varBuySearchOptions.edate));

  //검색일자 변경
  const onChangeDate = (ino, date) => {
    let dbDate = ChangeSearchingDateFormat(date);
    if (ino === 0) {
      setStartDate(date);
      setBuySearchOptions({ ...varBuySearchOptions, sdate: dbDate });
    } else {
      setEndDate(date);
      setBuySearchOptions({ ...varBuySearchOptions, edate: dbDate });
    }
  }

  return (
    <div className="list_top">
      <form className="search_wrap">
        <div className="wrap">
          <p className="title_field">구매일자</p>
          <CustomDatePicker
            data={varBuySearchOptions}
            varStartDate={varStartDate}
            varEndDate={varEndDate}
            onChangeDateHandler={onChangeDate}
          />
          <button type="button" className="btn" onClick={(e) => onSearchHandler(e)}>조회</button>
        </div>
      </form>
    </div>
  );
}

export default CompanyVoucherSearch;
