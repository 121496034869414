import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from "../../configs/configs";

function MyMenu({ varSubItems, getSubItem }) {

  const navigator = useNavigate();

  const mem_mode = Number(localStorage.getItem('mem_mode'));
  const mem_idx = Number(localStorage.getItem('mem_idx'));

  const [varItems, setItems] = useState([
    { id: 1, menu: '정보 관리', active: false },
    { id: 2, menu: '채용 지원 현황', active: false },
    { id: 3, menu: '받은 제안 현황', active: false },
    { id: 4, menu: '메시지', active: false },
    { id: 5, menu: '추천 현황', active: false },
    { id: 6, menu: '관심 채용 관리', active: false },
    { id: 12, menu: '이직/구인톡 관리', active: false },
    { id: 7, menu: '헤딩 머니', active: false },
    { id: 8, menu: '알림내역', active: false },
    // { id: 9, menu: 'FAQ', active: false },
    // { id: 13, menu: "공지사항", active: false },
    { id: 9, menu: '헤딩 컨설팅 서비스', active: false },
    { id: 10, menu: 'HR 담당자 소개', active: false }
  ])

  function setMenus(ids) {
    setItems(varItems =>
      varItems.map(item => {
        if (item.id === ids) {
          return { ...item, active: true }
        } else {
          return { ...item, active: false }
        }
      })
    )
  }

  const onSelectMyMenuHandler = (e, id) => {

    setMenus(id) //객체변경
    getSubItem(id) //부모에 메뉴값 전달 
  }

  const moveChat = () => {

    let sendData = {
      "user_idx": mem_idx,
      "type": mem_mode,
    }

    axios.post(`${CONFIG.SERVER_HOST}/_getChatLastIdx`, sendData, CONFIG.header).then((res) => {
      console.log(res.data.results)
      const { results, retvalue } = res.data;
      if (retvalue === 1) {
        if (results) {
          navigator(`/MyHeding/MyHeding4/${results}`);
        } else {
          navigator(`/MyHeding/MyHeding4`);
        }
      }
    });
  };

  useEffect(() => {
    setMenus(varSubItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varSubItems])

  return (
    <>
      <aside id="my_lnb">
        <p className="selected"></p>
        <ul className="menu">
          {varItems.map((lMenus, i) => (
            <li key={lMenus.id}>

              {lMenus.id === 4 ?
                <Link className={lMenus.active === true ? 'active' : ''} onClick={(e) => {moveChat(); onSelectMyMenuHandler(e, lMenus.id);}}>{lMenus.menu}</Link>
                :
                <Link
                  to={`/MyHeding/MyHeding${lMenus.id}`}
                  className={lMenus.active === true ? 'active' : ''}
                  onClick={e => { onSelectMyMenuHandler(e, lMenus.id) }}
                >{lMenus.menu}</Link>
              }

            </li>
          ))}
        </ul>
      </aside>
    </>
  )
}

export default MyMenu
