/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import CONFIG from '../../../configs/configs'

import iconhome from '../../img/icon-home.png'
import arrImage from '../../img/arr.png'

import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'

import moment from 'moment'

function MainSlide() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [initData, setInitData] = useState([])
  const imageFolder = `${CONFIG.SERVER_HOST}/images/`;

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/slide`).then(res => {
      console.log(res)
      const data = res.data.map((data, i) => ({
        order: i + 1,
        slide_idx: data.slide_idx,
        slide_index: data.slide_index,
        slide_title: data.slide_title,
        slide_link: data.slide_link,
        slide_ufile: data.slide_ufile,
        slide_rdate: data.slide_rdate,
      }))
      setInitData([...data].reverse())
    })
  }, [])

  const deleteHandler = (idx, image) => {
    console.log(idx)
    if (!window.confirm('삭제하시겠습니까?')) {
      return false;
    }
    try {
        const params = new URLSearchParams();
        params.append("name", image);
        axios.post(`${CONFIG.SERVER_HOST}/slide_delete?slide_idx=${idx}`,params).then(res => {
          console.log('delete :: result :: ', res.data);
          alert('삭제되었습니다.');
          window.location.reload();
        })
    } catch (error) {
      console.error();
    }
  }

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="slide" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          메인 슬라이드 관리
          <div className="path">
            <img src={iconhome} alt=""/>HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt=""/>디자인 관리<img src={arrImage} alt=""/>메인 슬라이드 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">메인 슬라이드 관리</div> */}
          <div className="main-box2">
            <div className="btn-right">
              <button className="btn-danger">
                <Link to={`/admin/main_slide/detail/${0}`} style={{ color: '#fff', width: '100%' }}>등록</Link>
              </button>
            </div>
          </div>
          <div className="main-box">
              <table className="list">
                <colgroup>
                  <col width="8%"></col>
                  <col width="30%"></col>
                  <col width="31%"></col>
                  <col width="8%"></col>
                  <col width="10%"></col>
                  <col width="13%"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>이미지</th>
                    <th>제목</th>
                    <th>순서</th>
                    <th>등록일</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {initData.map((data, i) => (
                    <tr key={data.slide_idx}>
                      <td>{data.order}</td>
                      <td>
                        <a href={data.slide_link}>
                          <img src={`${imageFolder}${data.slide_ufile}`} style={{ height: '100px' }} alt=""/>
                        </a>
                      </td>
                      <td className="ellipsis">{data.slide_title}</td>
                      <td>{data.slide_index}</td>
                      <td>{moment(data.slide_rdate).format(CONFIG.formatStr)}</td>
                      <td>
                        <Link to={`/admin/main_slide/detail/${data.slide_idx}`} className="btn line black">수정</Link>
                        <button className="btn line black" onClick={()=>deleteHandler(data.slide_idx, data.slide_ufile)}>삭제</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MainSlide
