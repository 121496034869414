/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
//css
import "css/Footer.css";
//component
import Logo from "./Logo";
import CONFIG from "../../configs/configs";
// import BannerBottom from "components/Banner/BannerBottom";
//이미지
import youtubeButtonImg from "img/common/btn_youtube.png";
import instgramButtonImg from "img/common/btn_insta.png";
import facebookButtonImg from "img/common/btn_face.png";
import blogButtonImg from "img/common/sns_blog.png";
import chButtonImg from "img/common/sns_ch.png";
import inButtonImg from "img/common/sns_in.png";
import cafeButtonImg from "img/common/btn_cafe.png";
import LogoImgName from "img/common/logo_bottom.png";
import nav_bottom1 from "img/common/nav_bottom1.png";
import nav_bottom2 from "img/common/nav_bottom2.png";
import nav_bottom3 from "img/common/nav_bottom3.png";
import nav_bottom4 from "img/common/nav_bottom4.png";
import nav_bottom5 from "img/common/nav_bottom5.png";

const imgArr = [{id: 1, url : youtubeButtonImg}, {id: 2, url : instgramButtonImg}, {id: 3, url : facebookButtonImg}, {id: 4, url : cafeButtonImg}, {id: 5, url : blogButtonImg}, {id: 6, url : chButtonImg}, {id: 7, url : inButtonImg}];

function Footer() {
  //
  const [varISLogin, setIsLogin] = useState(false);
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  const [linkData, setLinkData] = useState([]);

  const changeBodyClassName = () => {
    const address = window.location.href;
    const path = address.split("/");
    const realPath = path[path.length - 1];
    const bodyItem = document.querySelector("body");

    if (
      realPath === "Agreement" ||
      realPath === "Personal" ||
      realPath === "AboutUs" ||
      realPath === "Guide"||
      realPath === "GuideCompany"||
      realPath === "GuideHeadHunter"||
      realPath === "Email"
    ) {
      bodyItem.classList.add("no_depth_body");
    } else {
      bodyItem.classList.remove("no_depth_body");
    }
  };

  useEffect(() => {
    changeBodyClassName();
  }, []);
  //
  useEffect(() => {
    if (
      localStorage.getItem("memlogin") &&
      localStorage.getItem("mem_idx") > 0
    ) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("memlogin")]);


  //url 리스트 가져오기
  useEffect(() => {
    axios.get(`${CONFIG.SERVER_HOST}/geturllink`).then((res) => {
      //console.log(res.data)
      if(res.data.retvalue === 1){
        const results = res.data.results;
        // console.log("zzz ",results)
        const data = results.map((data, i) => {
          const a = imgArr.filter((el) => el.id === data.link_num);
          
          return {order: i + 1,
          link_idx: data.link_idx,
          link_num: data.link_num,
          link_rdate: data.link_rdate,
          link_url: data.link_url,
          link_status: data.link_status,
          img: imgArr[0],
          url : a[0]?.url
        }});
        setLinkData([...data]);
        // setLinkData(results)
      }else{
        setLinkData([])
      }
    });
  },[])


  return (
    <>
      <footer id="footer">
          <section className ="center_wrap">
          <div className="logo"><Logo LogoImg={LogoImgName} /></div>
              <article className="link">
                <Link to="https://trsheding.co.kr/" target="_blank">회사소개</Link>
                <Link to="/Personal">개인정보 취급방침</Link>
                <Link to="/Agreement">이용약관</Link>
                <a href={CONFIG.inquiryUrl} target="_blank" rel="noopener noreferrer">기업 문의 및 서비스 신청</a>
                <Link to={"/Guide"}>사용자 가이드</Link>
                {mem_mode === 0 ? <Link to={"/Faq"}>FAQ</Link> : <Link to={"/Company/Faq/"}>FAQ</Link>}
                {mem_mode === 0 ? <Link to={"/Notice"}>공지사항</Link> : <Link to={"/Company/Notice/"}>공지사항</Link>}
              </article>
              {/*sns 링크*/}
              <article className="sns_link">
              {linkData.map((el, i) => { 
                if(el.link_status=== 1){
                  return <a key={i} href={el.link_url}><img src={el.url} alt="" /></a>
                }
              })}
              </article>
              <address className="company_info">
                  <p>㈜더라이징스타헤딩</p>
                  <p>대표이사 : 박규태</p>
                  <p>주소 : 서울시 강남구 논현로 340 정도빌딩 4층 헤딩</p>
                  <p>사업자 등록번호 : 646-88-00522   </p>
                  <p>유료직업소개사업등록번호 : (국내) 제2016-3220163-14-5-00040호 &nbsp;&nbsp; (국외) F1200020230005호</p>
                  <p>Tel : 02-567-9526 </p>
                  <p>통신판매업 신고번호 : 2018-서울강남-00490 </p>
                  <p className="copy">ⓒ HEDING Corporation</p>
              </address>
              <div className="notice_area">헤딩은 통신판매중개자이며, 통신판매의 당사자가 아닙니다.<br />상품, 상품정보, 거래에 관한 의무와 책임은 판매회원에게 있습니다.</div>
          </section>
      </footer>
      <aside className="nav_bottom">
        <Link to={"/main"}>
          <img src={nav_bottom1} alt="" />
          <span className="text">홈</span>
        </Link>
        {/* 2022-11-28 KSR
        <Link to={"/MyRecruit"}
          state={{ em_headhunter: 0, sString: "", em_type: 1 }}>
          <img src={nav_bottom2} alt="" />
          <span className="text">공고</span>
        </Link>
        */}
        <Link to={"/MyRecruit/MainList2/-1/1/20/0"}>
          <img src={nav_bottom2} alt="" />
          <span className="text">공고</span>
        </Link>
        <Link to={varISLogin ? "/MyCareer/web/0" : "/Login"}>
          <img src={nav_bottom3} alt="" />
          <span className="text">커리어</span>
        </Link>
        <Link to={"/MyTalk"}>
          <img src={nav_bottom4} alt="" />
          <span className="text">톡</span>
        </Link>
        <Link to={varISLogin ? "/MyHeding/MyHeding1" : "/Login"}>
          <img src={nav_bottom5} alt="" />
          <span className="text">MyHEDING</span>
        </Link>
      </aside>

      {/* //* 예시용 레이어 팝업 START - Popup.css 25line 에서 스타일 조정 / 현재 max-width:90vw; */}
      {/* <div className="popup active">
        <div className="popup_wrap popup_h500">
          빈창
        </div>
      </div> */}
      {/* //* 예시용 레이어 팝업 END */}
    </>
  );
}

export default Footer;
