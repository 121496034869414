/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import CONFIG from '../../../configs/configs'

import iconhome from '../../img/icon-home.png'
import Titlebar from '../inc/Titlebar'
import Tabbar from '../inc/Tabbar'
import Menu from '../inc/Menu'
import arrImage from '../../img/arr.png'
// import { tr } from 'date-fns/locale'

function MainSlideDetail() {
  const navigate = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [varIsIdx, setIsIdx] = useState(0);
  const { slide_idx } = useParams();
  const today = new Date().toISOString().substr(0, 10).replace('T', '');

  const [varName, setName] = useState('');
  const [varLink, setLink] = useState('');
  const [varOrder, setOrder] = useState(0);
  const [varNewChkFile, setNewChkFile] = useState(false);
  const [varFile, setFile] = useState(null);

  const [varNewChkMFile, setNewChkMFile] = useState(false);
  const [varMFile, setMFile] = useState(null);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigate("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onNameHandler = e => {
    setName(e.currentTarget.value);
  }

  const onOrderHandler = e => {
    setOrder(e.currentTarget.value);
  }
  const onLinkHandler = e => {

    setLink(e.currentTarget.value);
  }

  const onFileHandler = e => {
    setNewChkFile(true);
    console.log(e.target.files[0]);
    //const file = e.target.files[0];
    setFile(e.target.files[0]);
  }

  const onMFileHandler = e => {
    setNewChkMFile(true);
    console.log(e.currentTarget?.files[0]);
    const file = e.target?.files[0];
    setMFile(file);
  }


  const onSubmitHandler = async(e) => {
    e.preventDefault()
    if (!varFile) {
      alert('이미지 파일을 선택해주세요.');
    } else if (!varMFile) {
      alert('모바일 이미지 파일을 선택해주세요.');
    } else if (!varName) {
      alert('슬라이드 제목을 입력해주세요.');
    } else if (!varOrder) {
      alert('순서를 입력해주세요.');
    } else {
      let fileNameChk = false;
      let fileMNameChk = false;
      const uploadFile = varFile;
      const uploadMFile = varMFile;
      const formData = new FormData();
      formData.append('images', uploadFile);
      const formData2 = new FormData();
      formData2.append('images', uploadMFile);
      console.log(uploadFile);

      const fileName = await axios.post(`${CONFIG.SERVER_HOST}/upload`, formData).then(res => {
        console.log(res.data);
        fileNameChk = true;
        return res.data.fileName;
      });

      const fileMName = await axios.post(`${CONFIG.SERVER_HOST}/uploadM`, formData2).then(res => {
        console.log(res.data);
        fileMNameChk= true;
        console.log(fileMNameChk);
        return res.data.fileName;
      });
      console.log(fileMNameChk);

      if( fileNameChk === true && fileMNameChk === true){
        console.log(fileName);
        console.log(fileMName);
        const param = new URLSearchParams();
        param.append('slide_index', varOrder);
        param.append('slide_title', varName);
        param.append('slide_link', varLink);
        param.append('slide_ufile', fileName);
        param.append('slide_mfile', fileMName);
        param.append('slide_rdate', today);

        axios.post(`${CONFIG.SERVER_HOST}/slide_create`, param).then(res => {
          console.log(res);
          if(res.data.retvalue === 1) {
            alert(res.data.message);
            navigate('/admin/main_slide');
          }else{
            alert(res.data.message);
          }
        })
      }


      
    }
  }

  const onUpdateHandler = e => {
    e.preventDefault()
    const uploadFile = varFile;
    const formData = new FormData();
    formData.append('images', uploadFile);

    if (varNewChkFile === true) {

      axios.post(`${CONFIG.SERVER_HOST}/upload`, formData).then(res => {
        const param = new URLSearchParams();
        param.append('slide_index', varOrder);
        param.append('slide_title', varName);
        param.append('slide_link', varLink);
        param.append('slide_ufile', res.data.fileName);
        param.append('slide_idx', varIsIdx);

        axios.post(`${CONFIG.SERVER_HOST}/slide_update`, param).then(res => {
          console.log(res);
          if (res.status === 200) {
            alert('수정에 성공하였습니다.');
            navigate('/admin/main_slide');
          }
        })
      })
        .catch(err => {
          console.error(err);
          alert('수정에 실패하였습니다.');
        });
    } else {
      const param = new URLSearchParams();
      param.append('slide_index', varOrder);
      param.append('slide_title', varName);
      param.append('slide_link', varLink);
      param.append('slide_ufile', varFile);
      param.append('slide_idx', varIsIdx);

      axios.post(`${CONFIG.SERVER_HOST}/slide_update`, param).then(res => {
        console.log(res);
        if (res.status === 200) {
          alert('수정에 성공하였습니다.');
          navigate('/admin/main_slide');
        }
      })
        .catch(err => {
          console.error(err);
          alert('수정에 실패하였습니다.');
        });
    }

  }

  useEffect(() => {
    setIsIdx(slide_idx)
    if (slide_idx > 0) {
      const param = new URLSearchParams();
      param.append('slide_idx', slide_idx);
      axios.post(`${CONFIG.SERVER_HOST}/slide_r?idx=${slide_idx}`, param).then(res => {
        setFile(res.data[0].slide_ufile);
        setMFile(res.data[0].slide_mfile);
        setName(res.data[0].slide_title);
        setLink(res.data[0].slide_link);
        setOrder(res.data[0].slide_index);
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="slide" />
      <div id="contents" className="ad_contents" encType="multipart/form-data">
        <div className="tit">
          메인 슬라이드 관리
          <div className="path">
            <img src={iconhome} alt="" />HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />디자인 관리<img src={arrImage} alt="" />메인 슬라이드 관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">메인 슬라이드 관리</div> */}
          <div className="main-box">
            <table className="write">
              <tr>
                <th>이미지</th>
                <td>
                  {varNewChkFile === false ? varFile : ''} &nbsp;
                  <input type='file' onChange={(e) => onFileHandler(e)} accept={'image/*'} />
                  <b class="size_guide">(1260 x 340 px 사이즈로 등록해주세요.)</b>
                </td>
              </tr>
              <tr>
                <th>모바일 이미지</th>
                <td>
                  {varNewChkMFile === false ? varMFile : ''} &nbsp;
                  <input type='file' onChange={(e) => onMFileHandler(e)} accept={'image/*'} />
                  <b class="size_guide">(720 x 400 px 사이즈로 등록해주세요.)</b>
                </td>
              </tr>
              <tr>
                <th>슬라이드 제목</th>
                <td>
                  <input type="text" value={varName} onChange={(e) => onNameHandler(e)} />
                </td>
              </tr>
              <tr>
                <th>링크URL</th>
                <td>
                  <input type="text" value={varLink} onChange={(e) => onLinkHandler(e)} placeholder="https://" />
                </td>
              </tr>
              <tr>
                <th>순서</th>
                <td>
                  <input type="number" style={{ width: '120px' }} value={varOrder} onChange={(e) => onOrderHandler(e)} />
                </td>
              </tr>
            </table>
          </div>
          <div className="btn-center">
          <Link className="btn line" to="/admin/main_slide" style={{ color: '#fff' }}>목록</Link>
            {slide_idx === '0' ? (
              <button className="btn" onClick={(e) => onSubmitHandler(e)}>등록</button>
            ) : (
              <button className="btn" onClick={(e) => onUpdateHandler(e)}>수정</button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MainSlideDetail
