/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

//이미지
// import search from "img/sub/search.png";
import { ChangeSearchingDateFormat } from '../Common/Common';
import CustomDatePicker from "components/Common/CustomDatePicker";
//css

/*채용 지원 현황*/
function MyTalkSearch({ varSearchData, setSearchData, filteringWriteData }) {
  const [varStartDate, setStartDate] = useState(
    varSearchData.sdate === null ? null : varSearchData.sdate
  );
  const [varEndDate, setEndDate] = useState(
    varSearchData.edate === null ? null : varSearchData.edate
  );
  const [varSearchOption, setSearchOption] = useState(
    varSearchData.searchoption
  );
  const [varSearchString, setSearchString] = useState(
    varSearchData.searchstring
  );

  const onSearchOptionHandler = (e) => {
    setSearchOption(e.currentTarget.value);
  };
  const onSearchStringHandler = (e) => {
    setSearchString(e.currentTarget.value);
  };
  const onExecuteSearchingHandler = (e) => {
    e.preventDefault();
    setSearchData({
      sdate: ChangeSearchingDateFormat(varStartDate),
      edate: ChangeSearchingDateFormat(varEndDate),
      searchoption: varSearchOption,
      searchstring: varSearchString,
    });
    console.log(varSearchData, "asdf")
    filteringWriteData();
  };
  const onChangeDateHandler = (ino, date) => {
    if (date === null || date === "") {
      if (ino === 0) {
        setStartDate(null);
      } else {
        setEndDate(null);
      }
      return;
    }
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    const curDate = year + "-" + month + "-" + day;
    const ndate = new Date(curDate);
    //console.log(ndate);
    if (ino === 0) {
      setStartDate(ndate);
    } else {
      setEndDate(ndate);
    }
  };

  return (
    <>
      <div className="list_top">
        <div className="search_wrap">
          {/*권유일자*/}
          <div className="wrap">
            <p className="title_field">작성일</p>
            <CustomDatePicker
              varStartDate={varStartDate}
              varEndDate={varEndDate}
              onChangeDateHandler={onChangeDateHandler}
            />
          </div>
          {/*검색*/}
          <fieldset className="list_search_area">
            <select
              className="style_select"
              value={varSearchOption}
              onChange={(e) => onSearchOptionHandler(e)}
            >
              <option value={"all"}>전체</option>
              <option value={"mwt_title"}>제목</option>
              <option value={"mwt_comment"}>내용</option>
            </select>
            <div className="search">
              <input
                type="text"
                value={varSearchString}
                onChange={(e) => onSearchStringHandler(e)}
                placeholder="검색어를 입력해주세요"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onExecuteSearchingHandler(e)
                  }
                }}
              />
              {/* <a href={() => false} className="btn_search">
                <img src={search} alt="" />
              </a> */}
            </div>
            <button onClick={(e) => onExecuteSearchingHandler(e)} className="btn">
              조회
            </button>
          </fieldset>
        </div>
      </div>
    </>
  );
}

export default MyTalkSearch;
