/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import Paging from '../../../components/Layout/Paging'

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';


function TalkList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    //이직톡 리스트
    const [talkList, setTalkList] = useState([]);
    const [varCheckedList, setCheckedList] = useState([]);

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const BoardList = async () => {
      try {
        const params = new URLSearchParams();
        params.append("PageSize", varPageSize);
        params.append("PageNum", varPageNum);
        await axios
          .post(`${CONFIG.SERVER_HOST}/_get_talk_lists`, params)
          .then((res) => {
            const datas = res.data.results.map((data, i) => ({
              order: i + 1,
              mwt_idx: data.mwt_idx,
              mem_idx: data.mwt_mem_idx,
              mem_id: data.mem_id,
              mem_nickname: data.mem_nickname,
              mem_type: data.mem_type,
              mwt_title: data.mwt_title,
              mwt_rdate: data.mwt_rdate,
              mwt_read: data.mwt_read,
              mwt_hits: data.mwt_hits,
              replys: data.replys,
            }));
            setTotalCount(res.data.totalCount);
            setTalkList(datas)
            // setPageList(datas);
          });
      } catch (error) {
        console.log(error);
      }
    }

    //페이지가 바뀌면
    useEffect(() => {
      BoardList(varPageNum);
    },[varPageNum]);

    //전체 선택
    const onCheckedAll = (e) => {
        if (e.currentTarget.checked) {
            const checkedListArray = [];
            talkList.forEach((board) => checkedListArray.push(board));
            setCheckedList(checkedListArray);
        } else {
            setCheckedList([]);
        }
    }
    //개별선택
    const onCheckedElement = (e, board) => {
        if (e.currentTarget.checked) {
            setCheckedList([...varCheckedList, board]);
        } else {
            setCheckedList(varCheckedList.filter((el) => el !== board));
        }
    }

    //선택 항목 삭제
    const deleteFaqList = (e) =>{
        const delitems = varCheckedList.length;
        if(delitems === 0) {
            window.alert('삭제할 정보가 선택되어 있지 않습니다.'); return;
        }
        if(!window.confirm(delitems+'개의 선택된 정보를 삭제합니까?')) return;
        const mwt_idx = varCheckedList.map((e) => e.mwt_idx );
        const params = new URLSearchParams();
        params.append("mwt_idx", mwt_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_admin_delete_talk`,params, CONFIG.header).then((res) => {
            if(res.data.retvalue === 1) {
              BoardList(varPageNum);
            } else {
                window.alert('오류가 발생했습니다.');
            }
        });
    }


    return(
        <>
        <Titlebar/>
        <Tabbar tabType="board"/>
        <Menu menuType="board" menu="talklist"/>
        <div id="contents" className="ad_contents">
            <div className="tit">이직/구인톡 관리
                <div className="path">
                    <img src={iconhome} alt=""/>
                    HOME<img src={arrImage} alt=""/>이직/구인톡 관리<img src={arrImage} alt=""/>이직/구인톡 관리
                </div>
            </div>
            <div className="content">
                <div className="main-box2">
                    <div className="btn-right">
                        <button onClick={deleteFaqList} className="btn">삭제</button>
                    </div>
                </div>
                <div className="main-box ">
                    <table className="list">
                        <colgroup>
                            <col style={{width:"4%"}} />
                            <col style={{width:"8%"}} />
                            <col style={{width:"45%"}} />
                            <col style={{width:"17.5%"}}/>
                            <col style={{width:"17.5%"}}/>
                            <col style={{width:"8%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="board-list">
                                <input type="checkbox" onChange={(e)=>onCheckedAll(e)} id="deleteItems"
                                checked={varCheckedList.length===0?false:varCheckedList.length===talkList.length?true:false} />
                                </th>
                                <th>번호</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>작성일</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {talkList.map((board,i) => (
                                <tr key={board.order}>
                                    <td className="board-list">
                                        <input type="checkbox"
                                        id={"deleteItem"+i}
                                        onChange={(e)=>onCheckedElement(e,board)}
                                        checked={varCheckedList.includes(board)?true:false}/>
                                    </td>
                                    <td><p>{ varTotalCount - (varPageNum - 1) * varPageSize - i}</p></td>
                                    <td className="ellipsis text_left"><p>{board.mwt_title}</p></td>
                                    <td><p>{board.mem_type === 0 ? board.mem_id : board.mem_nickname}</p></td>
                                    <td><p>{board.mwt_rdate}</p></td>
                                    <td><Link to={`/admin/talklistdetail/${board.mwt_idx}`} state={{mwt_idx:board.mwt_idx}} className="btn line black">상세</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                </div>
            </div>
        </div>
        </>
    )
}

export default TalkList;