/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';
//css
//이미지
import plusblueImage from "../../img/sub/btn_plus_blue.png"
//components
import AddCareerHistory from './AddCareerHistory';
import moment from "moment";
import {getMaxLength} from '../Common/Common';

function AddCareer({ varSaveMode, curResumeIndex, varInputs, setInputs, varPosition, resumeAddCareerList, setResumeAddCareerList, varCheckNoAddWork, setCheckNoAddWork, careerTipList }) {

	const user_idx = localStorage.getItem('mem_idx');
	const [varNumber, setNumber] = useState(1);
	const [varTotalAddWork, setTotalAddWork] = useState('');

	useEffect(() => {
		if (curResumeIndex > 0) {
				const params = new URLSearchParams();
				params.append("user_idx", user_idx);
				params.append("re_mode", varSaveMode);
				params.append("re_idx", curResumeIndex);
				params.append("rac_certification", 0);
				axios.post(`${CONFIG.SERVER_HOST}/_get_my_addcareers`, params).then((res) => {
					console.log(res.data);
					if (res.data?.retvalue === 1) {
						//경력목록
						const data = res.data?.results.map((data, i) => (
							{
								idx: i + 1,
								rac_idx: data?.rac_idx,
								rac_company: data?.rac_company,
								rac_department: data?.rac_department,
								rac_position: data?.rac_position,
								rac_sdate: data?.rac_sdate,
								rac_edate: (data?.rac_edate === null) ? null : data?.rac_edate,
								rac_state: data?.rac_state,
								rac_certification: 0,
								rac_descript: [
									{id:0, 'note': data?.rac_note1, 'detail': data?.rac_detail1 }
								, {id:1, 'note': data?.rac_note2, 'detail': data?.rac_detail2 }
								, {id:2, 'note': data?.rac_note3, 'detail': data?.rac_detail3 }
								, {id:3, 'note': data?.rac_note4, 'detail': data?.rac_detail4 }
								, {id:4, 'note': data?.rac_note5, 'detail': data?.rac_detail5 }]
							}
						));
						console.log(data);
						setResumeAddCareerList(data);
						//ResetTotalCareer(data,varInputs,setInputs,setTotalAddWork)
						if (data?.length === 0) {
							setCheckNoAddWork(true);
						} else {
							setCheckNoAddWork(false);
						}
						setNumber(data?.length + 1);

					} else {
						setResumeAddCareerList([]);
						setCheckNoAddWork(true);
					}
				});
		}
	}, [curResumeIndex]);

	//경력 계산
	useEffect(() => {
		if(resumeAddCareerList.length > 0){
			console.log('경력 추가!!!');
			monthDiff(resumeAddCareerList);
		}
	}, [resumeAddCareerList]);

	//신입 - 경력없음
	const onNoAddWorkHandler = e => {
		if (e.currentTarget.checked) {  //신입이면
			if (resumeAddCareerList.length > 0) {
				if (!window.confirm('아래의 경력을 모두 삭제합니까?')) {
					e.currentTarget.checked = false;
					return;
				}
			}
			//초기화
			setResumeAddCareerList([]);
			setTotalAddWork('');
			setInputs({ ...varInputs, careeradd_year: 0.0 });
		} else {
			if (resumeAddCareerList.length === 0) {
				alert('등록된 인증 경력이 없습니다.');
				e.currentTarget.checked = true;
			}
		}
		setCheckNoAddWork(e.currentTarget.checked);
	}
	//경력에 대한 업무 배열 만들기
	var descArray = [];
	var descobj = {};
	for (var i = 0; i < 5; i++) {
			descobj = {id:i, 'note': null, 'detail': null };

		descArray.push(descobj);
	}
	//경력조합 객체
	const newCareerAddData = {
		idx: Number(resumeAddCareerList[resumeAddCareerList.length - 1])+1,
		rac_idx:0,
		rac_company: '',
		rac_department: '',
		rac_position: '',
		rac_sdate: null,
		rac_edate: null,
		rac_state: 0,
		rac_certification: 0,
		rac_descript: descArray,
		rac_status : 1
	};

	//경력추가
	const onNewAddCareerHistoryHandler = e => {
		e.preventDefault();
		// if (!window.confirm('경력을 추가합니까?')) return;
		let copyData = { ...newCareerAddData };
		copyData.idx = varNumber;
		setNumber(prevNumber => prevNumber + 1);
		setResumeAddCareerList(resumeAddCareerList.concat(copyData));
		setCheckNoAddWork(false);
	}

	const monthDiff =(arr) => {
    let monthArr = [];
		let total = 0;
		arr.map(item => {
			if(item.rac_sdate !== null && item.rac_edate !== null){
				const data1 = item.rac_sdate.split('-');
				const data2 = item.rac_edate === null ? moment(new Date()).format('YYYY-MM-DD').split('-') : item.rac_edate.split('-');
				const t1 = monthDiff2(new Date(data1[0], data1[1]), new Date(data2[0], data2[1]));
				total += t1;
			}

		});
    const quotient = parseInt(total/12); //몫
		const remainder = total %12; //나머지
    const t2 = total / 12;
		// const t3 = t2.toFixed(1).split('.');
    monthArr.push(quotient,remainder)

		let strCareer = ``;
		if( Number(monthArr[0]) > 0) {
			strCareer = `${monthArr[0]}년`;
		};
    if( Number(monthArr[1]) > 0) {
			strCareer += ` ${monthArr[1]}개월`;
		};

		setInputs({
			...varInputs,
			careeradd_year: quotient+"."+remainder,
			additional_career_year: quotient,
			additional_career_date: remainder
		});
		setTotalAddWork(strCareer);
};

const monthDiff2 = (dateFrom, dateTo) => {
	return dateTo.getMonth() - dateFrom.getMonth() +
		(12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
 }

//추가경력 작성 TIP
 let copyData = [...careerTipList]
 const tipData = copyData.filter((el) => el.career_order === 2)

	return (
		<section className="section">
			<article className="title_area has_guide_secret">
				<h3 className="title">추가 경력</h3>
        <button className="btn txt" onClick={(e) => onNewAddCareerHistoryHandler(e)}><img src={plusblueImage} alt="" />추가</button>
				{/* <input type="checkbox" id="add_career" className="chk" onChange={(e) => { onNoAddWorkHandler(e) }} checked={varCheckNoAddWork} />
				<label htmlFor="add_career">신입 (경력사항 없음)</label> */}
        {/*작성팁*/}
        <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
				<p className="total_field">총 경력 : <span className="point">{varTotalAddWork}</span></p>
			</article>
			<article>
				{resumeAddCareerList ?
					resumeAddCareerList.map((career, index) => (
						<AddCareerHistory key={'addcareer_' + index}
							index={career.idx} career={career} 
							varInputs={varInputs}  setInputs={setInputs}
							varPosition={varPosition} 
							setTotalAddWork={setTotalAddWork}
							setCheckNoAddWork={setCheckNoAddWork}
							resumeAddCareerList={resumeAddCareerList}
							setResumeAddCareerList={setResumeAddCareerList}
						/>
					)) : null
				}
			</article>
		</section>
	);
}

export default AddCareer;
