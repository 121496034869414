/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect}from "react";
import { Link, useNavigate } from 'react-router-dom';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import moment from 'moment'; 

import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';

import Paging from '../../../components/Layout/Paging'
import { ChangeMoneyUnit, /*changeEmMode*/ } from '../../../components/Common/Common'
import ExcelButton from 'admin/pages/board/ExcelDownloadButton';

function AccumulateList(){
    const navigator = useNavigate();
    const adminlogin = localStorage.getItem('adminlogin');
    const [startDate, setStartDate] = useState(""); //date picker 날짜
    const [endDate, setEndDate] = useState(""); //date picker 날짜
    const [varSearch, setSearch] = useState("none");
    const [varSearchInput, setSearchInput] = useState("");

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageNum,setPageNum] = useState(1);
    const [varPageSize, setPageSize] = useState(10);
    const [selectChange, setSelectChange] = useState(false);

    // const [varUserlist, setUserlist] = useState([]);
    const [varFilterlist, setFilterlist] = useState([]);  
    const [varPagelist, setPagelist] = useState([]); //eslint-disable-line no-unused-vars

    useEffect(() => {
      if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
        alert("로그인 후 이용 가능합니다.");
        navigator("/admin/login");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getExchangeMoneyList = async (iMode) => {
      let sendData;
      if(iMode !== 1){
        sendData = {
          'currentPage': varPageNum,
          'pageSize': varPageSize,
          'sdate': startDate ? moment(startDate).format(CONFIG.formatStr) : "",
          'edate':startDate ? moment(endDate).format(CONFIG.formatStr) : "",
          'search_option': varSearch,
          'search_string': varSearchInput
        }
      }else{
        sendData = {
          'currentPage': varPageNum,
          'pageSize': varPageSize,
          'sdate': "",
          'edate': "",
          'search_option': "none",
          'search_string': ""
        }
      }

      axios.post(`${CONFIG.SERVER_HOST}/AdminExchangeList`,sendData, CONFIG.header).then((res) => {
          setTotalCount(res.data.totalcount); 
          // setUserlist(res.data.results);
          setFilterlist(res.data.results);
          setPagelist(res.data.results);
          if(selectChange){
            setSelectChange(false);
            setPageNum(1);
          }
      });
    };

    useEffect(() => {
      getExchangeMoneyList();
    },[varPageNum]);

    useEffect(() => {
      getExchangeMoneyList();
    },[varPageSize]);

    const handleType = (e) =>{
        setSearch(e.currentTarget.value);
    };
    const handleInput = (e) =>{
        setSearchInput(e.currentTarget.value);
    };

    //검색-초기화
    const handleSearchClear =()=>{
        setStartDate("");
        setEndDate("");
        setSearch("none");
        setSearchInput("");
        setPageNum(1);
        getExchangeMoneyList(1);
    }

    // const changeMhStatus = (element) => {
    //   if(element.mh_status === 1 ) element.mh_status = "환전신청";
    //   if(element.mh_status === 2 ) element.mh_status = "승인완료";
    //   if(element.mh_status === 3 ) element.mh_status = "환전완료";
    //   if(element.mh_status === -1 ) element.mh_status = "환전취소";
    // }

    // //검색
    // const handleSearchExecute = () => {
    //   let copyData = [...varPagelist];
    //   if(startDate !== "") {
    //     copyData = copyData.filter(element=>moment(element.mh_rdate).format(CONFIG.formatStr)>=moment(startDate).format(CONFIG.formatStr));
    //   }
    //   if(endDate !== "") {
    //     copyData = copyData.filter(element=>moment(element.mh_rdate).format(CONFIG.formatStr)<=moment(endDate).format(CONFIG.formatStr));
    //   }
    //   if(varSearchInput !== ""){
    //     if(varSearch === "mem_id"){
    //       copyData = copyData.filter(element=>element.mem_id?.toUpperCase().includes(varSearchInput.toUpperCase()));
    //     } else if(varSearch === "mem_name"){
    //       copyData = copyData.filter(element=>element.mem_name?.toUpperCase().includes(varSearchInput.toUpperCase()));
    //     }  else if(varSearch === "mcm_name"){
    //       copyData = copyData.filter(element=>element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase()));
    //     } else if(varSearch === "mem_mode"){
    //       copyData = copyData.filter((element) => {
    //       changeEmMode(element);
    //       element.mem_mode?.includes(varSearchInput)
    //       return element.mem_mode?.includes(varSearchInput)
    //     })
    //     } else if(varSearch === "mh_status"){
    //       copyData = copyData.filter((element) => {
    //       changeMhStatus(element)
    //       return element.mh_status.includes(varSearchInput);
    //       })
    //     } else {
    //       copyData = copyData.filter(element => {
    //         changeEmMode(element);
    //         changeMhStatus(element);
    //         element.mem_id?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
    //         element.mem_name?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
    //         element.mem_mode?.includes(varSearchInput) ||
    //         element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
    //         element.mh_status?.includes(varSearchInput)

    //         return (
    //           element.mem_id?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
    //           element.mem_name?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
    //           element.mem_mode?.includes(varSearchInput) ||
    //           element.mcm_name?.toUpperCase().includes(varSearchInput.toUpperCase()) ||
    //           element.mh_status?.includes(varSearchInput)
    //         )
    //       })
    //     }
    //   }
    //   setTotalCount(copyData.length);
    //   setFilterlist(copyData);
    //   setPageNum(1);
    // }

    // //페이지별 표시
    // const getPageLists = () => {
    //   const startNum = (varPageNum-1)*varPageSize;
    //   const endNum = startNum + varPageSize;
    //   const copyData = varFilterlist.slice(startNum,endNum);
    //   setPagelist(copyData);
    // }

    // useEffect(() => {
    //   getPageLists();
    // },[varPageNum,varFilterlist]);
    
    const handlestartdate =(e)=>{
        setStartDate(e.currentTarget.value);
    }
    const handleenddate =(e)=>{
        setEndDate(e.currentTarget.value);
    }

    const settingPageSize = (e) =>{
      const {value} = e.target;
      setPageSize(Number(value));
      setSelectChange(true);
    };

    return(
        <React.Fragment>
        <Titlebar/>
        <Tabbar tabType="users"/>
        <Menu menuType="users" menu="exchangeList"/>
        <div id="contents" className="ad_contents">
        <div className="tit">환전관리
            <div className="path">
                <img src={iconhome} alt=""/>
                HOME<img src={arrImage} alt=""/>회원관리<img src={arrImage} alt=""/>헤딩머니<img src={arrImage} alt=""/>환전관리
            </div>
        </div>
        <div className="content">
            <div className="main-box">		
                <div className="search-box">
                    <div className="f-right">		
                      <div className="day-box">
                          <p>환전일자</p>
                          <input type="date"  name="startdate" value={startDate} onChange={handlestartdate} id="datePicker" />
                          ~ 
                          <input type="date"  name="enddate" value={endDate} onChange={handleenddate} id="datePicker2" />
                      </div>	
                    </div> 
                    <div className="wd100">								
                        <select style={{width:"120px"}} name="search" value={varSearch} onChange={handleType}> 
                            <option value="none">전체</option>
                            <option value="mem_id">아이디</option>
                            <option value="mem_name">이름</option>
                            <option value="mcm_name">회사명</option>
                            {/* <option value="mem_mode">회원유형</option> */}
                            {/* <option value="mh_status">상태</option> */}
                        </select>
                        <input type="text" name="strsearch" value={varSearchInput} onChange={handleInput} style={{width:"270px"}} onKeyDown={(e) => { if (e.key === 'Enter') getExchangeMoneyList(e)}}/>
                        <button onClick={getExchangeMoneyList} className="btn">검색</button>
                        <button onClick={handleSearchClear} className="btn black">초기화</button>				
                    </div>
                </div>
            </div>
            <div className="list_top">
              <p>검색건수 : {varTotalCount}
                <select style={{ width: "120px", marginLeft: '15px' }} name="option" value={varPageSize} onChange={settingPageSize}>
                    <option value="10">10개</option>
                    <option value="30">30개</option>
                    <option value="50">50개</option>
                    <option value="100">100개</option>
                </select>
              </p>
              <ExcelButton 
                startDate={startDate}
                endDate={endDate}
                option={varSearch}
                varSearchInput={varSearchInput}
                type={7}
                />
            </div>
            <div className="main-box ">
                <table className="list">
                    <colgroup>
                      <col style={{width:"8%"}} />
                      <col style={{width:"12%"}} />
                      <col style={{width:"12%"}} />
                      <col style={{width:"18%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"12%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"10%"}}/>
                      <col style={{width:"8%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>아이디</th>
                            <th>이름</th>
                            <th>회사명</th>
                            <th>회원유형</th>
                            <th>환전 요청금액</th>
                            <th>환전일자</th>
                            <th>상태</th>
                            <th>관리</th>
                        </tr>
                    </thead>
                    <tbody>
                    {varFilterlist?.map((item,i) => (
                      <tr key={i}>
                        {/* <td>{varTotalCount-(varPageNum-1)*varPageSize -i}</td> */}
                        <td>{item.ROWNUM}</td>
                        <td className="ellipsis">{item.mem_id}</td> 
                        <td className="ellipsis">{item.mem_name}</td> 
                        <td className="ellipsis">{item.mcm_name}</td> 
                        <td> 
                        {isNaN(item.mem_mode) ? item.mem_mode : item.mem_mode === 0 ? "일반" : item.mem_mode === 1 ? "기업" : item.mem_mode === 2 ? "헤드헌터" : "-"}
                          {item.mem_mode === 1 && item.mem_hunter_type === 0 ? "(스탠다드)" : ""}
                          {item.mem_mode === 1 && item.mem_hunter_type === 1 ? "(프리미엄)" : ""}
                          {/* {item.mem_mode === 2 && item.mem_hunter_type === 0 ? "(프리랜서)" : ""} */}
                          {item.mem_mode === 2 && item.mem_hunter_type === 1 ? "(서치폼)" : ""}
                        </td> 
                        <td className="ellipsis">{ChangeMoneyUnit(Math.abs(item.mh_paid / 10000))}</td> 
                        <td className="ellipsis">{item.mh_rdate===null?"":item.mh_rdate.substr(0,10)}</td>  
                        <td>
                        {isNaN(item.mh_status) ? item.mh_status : item.mh_status === 1 ? "환전신청" : item.mh_status === 2 ? "승인완료" : item.mh_status === 3 ? "환전완료" : item.mh_status === -1 ? "환전취소" : "-"}
                        </td> 
                        <td><Link className="btn line black" to={`/admin/exchangeinfo/${item.mem_idx}`} state={item.mh_idx}>상세</Link></td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div>
              <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
            </div>
        </div>
    </div>
    </React.Fragment>

    )

}

export default AccumulateList;