/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';
//css
/* import '../../css/board/List.css'; */
//components
//이미지

{/*이직Talk 게시판 목록*/ }
function ResumeLists({ setShowResume, PageFolder, setUserName }) {

  const user_idx = localStorage.getItem('mem_idx');

  //이력서목록
  const [varResumeList, setResumeList] = useState([]);

  const getResumeLists = async () => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_myresume_list`, params).then((res) => {
      if (res.data.retvalue === 1) {
        if (res.data.results.length > 0) {
          const resumeList = res.data.results;
          setUserName(res.data.username[0].mem_name);

          let itotal = resumeList.length;
          let representative_resume = resumeList.filter(element => element.re_fix === 1); //대표이력서 추출
          let general_resume = resumeList.filter(element => element.re_fix === 0); //대표이력서외 이력서
          //두배열 합치기
          const newArr = [
            ...representative_resume, //대표이력서 배열
            ...general_resume.sort((a, b) => new Date(b.re_rdate) - new Date(a.re_rdate)) //날짜 내림차순으로 정렬
          ];

          let data = newArr.map((data) => (
            {
              num: itotal--,
              selected: false,
              index: data.re_idx,
              show: data.re_show,
              fixs: data.re_fix,
              title: data.re_title,
              code: data.re_code,
              rdate: data.re_rdate.substr(0, 10),
              complete: Number(data.re_perfection),
              lookup: data.re_lookup,
              mode: data.re_mode,  //0:실저장의 경우,1:임시저장의 경우
              suggest: data.suggest,
              udate : data.re_udate
            }
          ));
          setResumeList(data);
          //대표이력서 노출 유무 표시
          const fixData = data.filter(element => element.fixs === 1);
          if (fixData.length > 0) {
            setShowResume(Boolean(fixData[0].show));
          }
        }
      } else {
        window.alert('검색된 정보가 없습니다.');
      }
    });
  }

  useEffect(async () => {
    if (varResumeList?.length === 0) {
      getResumeLists();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varResumeList]);

  // //토글에 따라 대표이력서 지정 & 해제
  // useEffect(() => {
  //   getResumeLists();
  // },[varShowResume])

  //이력서 삭제
  const onDeleteResumeHandler = (e) => {
    e.preventDefault();
    //한개이상
    let copyData = varResumeList.filter(element => element.selected === true);
    if (copyData.length === 0) {
      alert('삭제할 이력서가 선택되어 있지 않습니다.'); return;
    }

    if (!window.confirm('삭제하시겠습니까?')) {
      return false;
    }

    //데이타베이스 적용
    const params = new URLSearchParams();
    params.append("elements", JSON.stringify(copyData));
    axios.post(`${CONFIG.SERVER_HOST}/_delete_checked_resumes`, params).then((res) => {
      alert(res.data.message);
      //성공--리스트 체크박스 초기화
      let copyData2 = varResumeList.filter(element => element.selected === false);
      let itotal = copyData2.length;
      let data = copyData2.map((data) => (
        {
          num: itotal--,
          selected: false,
          index: data.index,
          show: data.show,
          fixs: data.fixs,
          title: data.title,
          code: data.code,
          rdate: data.rdate,
          complete: data.complete,
          lookup: data.lookup,
          mode: data.mode,  //0:실저장의 경우,1:임시저장의 경우
          suggest: data.suggest,
          udate : data.re_udate
        }
      ));

      setResumeList(data);
    });
  }
  //대표 이력서로 지장
  const onsetRepResumeHandler = () => {

    //한개여야만 함
    let copyData = varResumeList.filter(element => element.selected === true);

    if (copyData.length === 0) {
      alert('대표이력서로 지정할 이력서를 1개를 선택해주세요.'); return;
    } else if (copyData.length > 1) {
      alert('대표이력서는 1개만 지정할 수 있습니다.'); return;
    }

    //배열에서 대표 이력서 변경
    let repNewIndex = copyData[0]?.index;
    const finsIndex1 = varResumeList.findIndex(element => element.index === repNewIndex); //새로운 대표 이력서
    const finsIndex2 = varResumeList.findIndex(element => element.fixs === 1); //현재 대표 이력서 찾기

    let repOldIndex = varResumeList[finsIndex2]?.index;

    //변경할 배열을 재구성
    let copyData2 = [...varResumeList];
    copyData2[finsIndex2] = { ...copyData2[finsIndex2], selected: false, fixs: 0 };
    copyData2[finsIndex1] = { ...copyData2[finsIndex1], selected: false, fixs: 1 };
    //이력서 순서 변경
    let itotal = copyData2.length;

    let representative_resume = copyData2.filter(element => element.fixs === 1); //대표이력서 추출
    let general_resume = copyData2.filter(element => element.fixs === 0); //대표이력서외 이력서
    //두배열 합치기
    const newArr = [
      ...representative_resume, //대표이력서 배열
      ...general_resume.sort((a, b) => new Date(b.rdate) - new Date(a.rdate)) //날짜 내림차순으로 정렬
    ];

    let data = newArr.map((data) => (
      {
        num: itotal--,
        selected: false,
        index: data.index,
        show: data.show,
        fixs: data.fixs,
        title: data.title,
        code: data.code,
        rdate: data.rdate,
        complete: data.complete,
        lookup: data.lookup,
        mode: data.mode,  //0:실저장의 경우,1:임시저장의 경우
        suggest: data.suggest,
        udate : data.re_udate
      }
    ));
    

    //체크박스 체크해제
    document.getElementById('list_' + finsIndex1).checked = false;

    //데이타베이스 적용
    const params = new URLSearchParams();
    params.append("re_idx1", repOldIndex);  //이전 대표 이력서
    params.append("re_idx2", repNewIndex);  //새로운 대표 이력서
    axios.post(`${CONFIG.SERVER_HOST}/_set_representative_resumes`, params).then((res) => {

      if (res.data.retvalue === 1) { //수정 성공시 스테이트 변경
        setResumeList(data);
      } else {
        alert(res.data.message);
      }

    });
  }
  //이력서 체크박스 선택시
  const onSelectedResumeHandler = (e, re_idx) => {
    //e.preventDefault();
    let bchecked = e.currentTarget.checked;
    const finsIndex = varResumeList.findIndex(element => element.index === re_idx);

    let copyData = [...varResumeList];
    copyData[finsIndex] = { ...copyData[finsIndex], selected: bchecked };
    setResumeList(copyData);
  }

  const onDeleteRepresentativeResume =()=>{
    if (window.confirm('대표이력서를 해제하시겠습니까?')) {
      const sendData = { 'mem_idx': user_idx};
      //changeRefix 대표이력서 수정 api
      axios.post(`${CONFIG.SERVER_HOST}/DeleteRepresentativeResume`, sendData, CONFIG.header).then((res) => {
        if (res.data.retvalue === 1) { //대표이력서 수정완료
  
          const finsIndex = varResumeList.findIndex(element => element.fixs === 1);
          let copyData = [...varResumeList];
          copyData[finsIndex] = { ...copyData[finsIndex], selected: false, fixs: 0 };
          const reversedOrderedDate  = copyData.sort((a, b) => new Date(b.rdate) - new Date(a.rdate));
          setResumeList(reversedOrderedDate);
  
        } else {//대표이력서 변경 실패
          alert(res.data.message);
        }
  
      });
    }



  };

  return (
    <>
      <section className="list_top">
        <h2 className="sub_content_title">이력서 관리</h2>
        <a className="btn line gray" onClick={(e) => onDeleteResumeHandler(e)}>선택삭제</a>
        <a className="btn line" onClick={() => onsetRepResumeHandler()}>대표 이력서 지정</a>
        <a className="btn line gray" onClick={() => onDeleteRepresentativeResume()}>대표 이력서 해제</a>
      </section>
      <table className="table_list01 with_chk">
        <thead>
          <tr>
            <th className="th_chk">선택</th>
            <th className="th_num">번호</th>
            <th className="th_title">이력서 제목</th>
            <th>발급번호</th>
            <th className="th_date">작성일</th>
            <th className="th_date">수정일</th>
            <th>완성도</th>
            <th>이력서 조회수</th>
            <th>제안받은 수</th>
            <th>상세</th>
          </tr>
        </thead>
        <tbody>
          {varResumeList.map((resumes, i) => (
            <tr key={"resume_" + i} className={resumes.fixs === 1 ? "notice_area" : ""}>
              {resumes.fixs === 1 ?
                <>
                  <td className="view_pc"></td>
                  <td className="td_num"><span className="table_notice_badge default_cursor">대표</span></td>
                  <td className="td_title default_cursor"><span className="table_notice_badge default_cursor">대표</span>
                    {!resumes?.title ?
                      CONFIG.temporary_title
                      :
                      resumes?.title
                    }
                  </td>
                </>
                :
                <>
                  <td className="td_chk">
                    <input type="checkbox" className="chk" id={"list_" + i} checked={resumes.selected === true ? true : false} onChange={(e) => onSelectedResumeHandler(e, resumes.index)} />
                    <label htmlFor={"list_" + i}></label>
                  </td>
                  <td className="td_num default_cursor">{resumes.num}</td>
                  <td className="td_title default_cursor">
                    {!resumes?.title ?
                      CONFIG.temporary_title
                      :
                      resumes?.title
                    }
                  </td>
                </>
              }
              <td className="td_info default_cursor"><span className="field_tit">발급번호 : </span>{resumes.code}</td>
              <td className="td_info default_cursor"><span className="field_tit">작성일 : </span>{resumes.rdate}</td>
              <td className="td_info default_cursor"><span className="field_tit">수정일 : </span>{resumes.udate}</td>
              <td className={resumes.complete < 75 ? "td_info td_red default_cursor" : "td_info default_cursor"}><span className="field_tit">완성도 : </span>{resumes.complete}%</td>
              <td className="td_info default_cursor"><span className="field_tit">이력서 조회수 : </span>{resumes.lookup}</td>
              <td className="td_info last default_cursor"><span className="field_tit">제안받은 수 : </span>{resumes.suggest}</td>
              <td className="td_button">
                <div className="td_button_wrap">
                  <Link to={`${PageFolder}ResumeView/${resumes.index}`} className="button off mr">수정</Link>
                  |
                  <Link to={`${PageFolder}ResumeDetail/${resumes.index}/web`} className="button ml mr">미리보기</Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default ResumeLists;
