/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import iconhome from '../../img/icon-home.png';
import deleteImage01 from 'img/sub/btn_delete01.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arr from '../../img/arr.png';
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import Modal from 'react-modal';
import { ChangeMoneyUnit, escapeHtml } from "components/Common/Common";
import Paging from "components/Layout/Paging";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '442px',
        height: '46vh'
    },
};
function Announcement_Register() {
    const photoInput = useRef();
    const [Announcement, set_Announcement] = useState({
        em_title: "",
        em_status: 0,
        data3: 0,
        com_homepage: "",
        em_sdate: "",
        em_edate: "",
        em_deadline: 1,
        em_tasks: 0,
        em_tasks_detail: 0,
        data9: 0,
        em_places: -1,
        em_careers: 0,
        em_career_year: "",
        em_career_end: "",
        data13: 0,
        data14: 0,
        em_type: 0,
        data16: 0,
        em_reward_recommend: 0,
        em_reward_applicant: 0,
        em_headhunter: 0,
        data20: 0,
        emh_reward_recommend: 0,
        emh_reward_applicant: 0,
        em_reward_payment: 0,
        em_intro: "",
        em_main_task: "",
        em_qualifications: "",
        em_preference: "",
        em_welfare: "",
        em_others: "",
        em_address1: "",
        em_address2: "",
        com_name: "",
        em_position: 0,
        em_ufile: ""
    });


    const [WC_list, setWC_list] = useState([]);
    const [CD_list, setCD_list] = useState([]);
    const [RE_list, setRE_list] = useState([]);
    const [RR_list, setRR_list] = useState([]);
    const [Modal_status, setModal_status] = useState(Boolean);
    // const [CM_list, setCM_list] = useState([]);
    const [varEmtasks, setVarEmtask] = useState({
        value: '',
        text: '',
    });

    const [varEmtasksDetail, setVarEmtasksDetail] = useState({
        value: '',
        text: '',
    });
    const [varEmtasksDetailList, setVarEmtasksDetailList] = useState([]);
    // 기업 로고 
    const [curUploadFile, setUploadFile] = useState("");
    const [logoFile, setLogoFile] = useState("");

    //기업 - 검색문자 & 검색된 기업정보
    const [varSearchString, setSearchString] = useState("");
    const [varSearchingCompanyData, setSearchingCompanyData] = useState([]); //검색된 회사목록
    const [varSelectedString, setSelectedString] = useState("");
    const [varSelectedCompany, setSelectedCompany] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);
    const [varTotalCount, setTotalCount] = useState(0);
    const [varPageSize] = useState(5);
    const [varPageNum, setPageNum] = useState(1);
    const [varChkSearch, setChkSearch] = useState(false);

    const openModal = async () => {
        setModal_status(true);
        // await axios.post(`${CONFIG.SERVER_HOST}/company`).then((res) => {
        //     console.log(res);
        //     setCM_list(res.data);
        // });
    }
    const closeModal = () => {
        setModal_status(false);
        setChkSearch(false);
        setSearchString("")
        setSearchingCompanyData([]);
    }

    // const handleChangeModal = async (e) => {
    //     const { name, value } = e.target;
    //     await setModal_data(prevState => ({ ...prevState, [name]: value }));
    // };



    // const setModalData = async () => {
    //     if (Number(Modal_data.mcm_idx) > 0) {
    //         await CM_list.filter((company) => {
    //             if (company.mcm_idx === Number(Modal_data.mcm_idx)) {
    //                 console.log('2222');
    //                 set_Announcement(
    //                     prevState => ({
    //                         ...prevState,
    //                         com_name: company.mcm_name
    //                     })
    //                 );
    //                 setModal_status(false);
    //             }
    //         })
    //     }
    // }
    console.log(varSearchingCompanyData)
    const onSelectCompanyHandler = (comp) => {
        const { mcm_name, mcm_idx } = comp;
        setSelectedString(mcm_name);
        setSelectedCompany(mcm_idx);
    };

    useEffect(() => {
        if (varChkSearch) {
            onSearchingCompanyHandler();
        }
    }, [varChkSearch])

    // 기업 검색
    const onSearchingCompanyHandler = async (e) => {
        e?.preventDefault();
        if (varSearchString === "") {
            alert("검색할 문자를 먼저 입력하십시오.");
            return false;
        }
        setSearchingCompanyData([]);
        setSearchLoading(true);

        const param = new URLSearchParams();
        param.append("varSearchString", varSearchString)
        await axios.post(`${CONFIG.SERVER_HOST}/company`, param).then((res) => {
            const result = res.data
            setSearchingCompanyData(result);
            setTotalCount(result.length);
            if (result.length === 0) {
                setPageNum(0)
            }
        });
        setSearchLoading(false);
        setChkSearch(false);

    }


    //기업이름 검색문자 입력
    const onSearchStringHandler = (e) => {
        setSearchString(e.currentTarget.value);
    };
    //회사이름 지정
    const onSelectedCompanyHandler = (e) => {
        setModal_status(false);
        set_Announcement(
            prevState => ({
                ...prevState,
                com_name: varSelectedString
            })
        );
        setSearchingCompanyData([]);
        setSearchString("")
    };
    // 회사이름 직접입력
    // const onDirectCompanyHandler = () => {
    //     setModal_status(false);
    //     set_Announcement(
    //         prevState => ({
    //             ...prevState,
    //             com_name: varSearchString
    //         })
    //     )
    // }

    const onSearchingString = (e) => {
        if (varSearchString) {
            setPageNum(1);
            //   setSPAGE(1);
            //   setEPAGE(10);
            setChkSearch(true);
        } else {
            alert('검색어를 입력해주세요.');
        }

    }


    useEffect(async () => {
        await axios.post(`${CONFIG.SERVER_HOST}/get_work_category`).then((res) => {
            console.log(res.data.length);
            if (res.data.length > 0) {
                setWC_list(res.data);
            }
        });

        await axios.post(`${CONFIG.SERVER_HOST}/get_reward_employed`).then((res) => {
            console.log(res.data.length);
            if (res.data.length > 0) {
                setRE_list(res.data);
            }
        });

        await axios.post(`${CONFIG.SERVER_HOST}/get_reward_recommend`).then((res) => {
            console.log(res.data.length);
            if (res.data.length > 0) {
                setRR_list(res.data);
            }
        });
    }, []);

    const handleChange = async (e) => {
        const { name, value } = e.target;

        if (name === 'em_deadline') {
            set_Announcement(prevState => ({
                ...prevState,
                [name]: e.target.checked === true ? 1 : 0, em_edate: ""
            }));

        } else if (name === 'em_tasks') {
            const category = Number(value);
            if (category > 0) {
                const param = new URLSearchParams();
                param.append("vwc_idx", category);
                await axios.post(`${CONFIG.SERVER_HOST}/get_category_detail`, param).then((res) => {
                    console.log(res);
                    setCD_list(res.data);
                });
            }
            set_Announcement(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (name === 'emh_reward_recommend' || name === 'emh_reward_applicant') {
            if (Announcement.em_headhunter <= 0) {
                alert('헤드헌터 전용관 사용여부가 사용안함으로 되어있습니다.');
            } else {
                set_Announcement(prevState => ({ ...prevState, [name]: value }));
            }
        } else {
            set_Announcement(prevState => ({ ...prevState, [name]: value }));
        }
    };
    // 기업 로고 등록
    const onSelectEmployLogo = (e) => {
        photoInput.current.click();

    }

    const onUploadEmployLogo = async (e) => {
        const upLoadFile = e.target.files[0]
        // 파일 이미지 변수에 저장
        if (e.target.length !== 0) {
            setLogoFile(URL.createObjectURL(upLoadFile));
            // 업로드할 파일 저장
            // setUploadFile(e.target.files[0]);
            const formData = new FormData();
            formData.append("em_image", upLoadFile)
            await axios.post(`${CONFIG.SERVER_HOST}/em_image`, formData)
                .then((res) => {
                    const fileName = res.data.fileName;
                    setUploadFile(fileName);
                    // return fileName;
                })
        }
    }

    useEffect(() => {
        if (Announcement !== undefined && Announcement.em_image === 1 && Announcement.em_ufile !== "" && Announcement.em_ufile !== undefined) {
            setLogoFile(`${CONFIG.SERVER_HOST}/em_image/${Announcement.em_ufile}`)
        }
    }, [Announcement])

    const onSubmitHandler = () => {
        if (Announcement.em_title === "") {
            alert('공고명을 입력해주세요.');
            document.getElementById('em_title').focus();
            return false;
        }
        if (Announcement.em_status === 0) {
            alert('공고상태를 선택해주세요.');
            document.getElementById('em_status').focus();
            return false;
        }
        if (Announcement.com_name === "") {
            alert('회사명을 선택해주세요.');
            document.getElementById('com_name').focus();
            return false;
        }
        if (Announcement.em_sdate === "") {
            alert('지원기간을 입력해주세요.');
            document.getElementById('em_sdate').focus();
            return false;
        }
        if (!Announcement.em_deadline && Announcement.em_edate === "") {
            alert('지원기간을 입력해주세요.');
            document.getElementById('em_edate').focus();
            return false;
        }
        if (Announcement.em_places === -1) {
            alert('지역을 선택해주세요.');
            document.getElementById('em_places').focus();
            return false;
        }
        if (Number(Announcement.em_careers) === 2) {
            console.log('111111');
            if (Announcement.em_career_year === "") {
                alert('경력 시작년도 입력란에 숫자를 입력해주세요.');
                document.getElementById('em_career_year').focus();
                return false;
            } else if (Number(Announcement.em_career_year) <= 0) {
                alert("경력 시작년도 입력란에 1 이상의 수를 입력해 주세요.");
                document.getElementById('em_career_year').focus();
                return false;
            } else if (Announcement.em_career_end === "") {
                alert("경력 마감년도 입력란에 숫자를 입력해주세요.");
                document.getElementById("em_career_end").focus();
                return false;
            } else if (Number(Announcement.em_career_end) <= Number(Announcement.em_career_year)) {
                console.log(Number(Announcement.em_career_end), Number(Announcement.em_career_year))
                alert("경력 시작년도에 입력한 수 보다 큰 수를 입력해주세요.");
                document.getElementById("em_career_end").focus();
                return false;
            }
        }

        if (Announcement.em_type === 0) {
            alert('채용구분을 입력해주세요.');
            document.getElementById('em_type').focus();
            return false;
        }
        if (Number(Announcement.em_type > 3 && Number(Announcement.em_reward_recommend) === 0)) {
            alert("총 보상금을 선택해주세요.");
            document.getElementById("em_reward_recommend").focus();
            return false;
        } else if (Number(Announcement.em_type) !== 3 && Number(Announcement.em_reward_applicant) === 0) {
            alert("합격자 보상금을 선택해주세요.");
            document.getElementById("em_reward_applicant").focus();
            return false;
        }
        if (Announcement.em_intro === "") {
            alert('회사소개를 입력해주세요.');
            document.getElementById('em_intro').focus();
            return false;
        }
        if (Announcement.em_main_task === "") {
            alert('주요업무를 입력해주세요.');
            document.getElementById('em_main_task').focus();
            return false;
        }
        if (Announcement.em_qualifications === "") {
            alert('자격요건을 입력해주세요.');
            document.getElementById('em_qualifications').focus();
            return false;
        }
        if (Announcement.em_preference === "") {
            alert('우대사항을 입력해주세요.');
            document.getElementById('em_preference').focus();
            return false;
        }
        if (Announcement.em_welfare === "") {
            alert('혜택 및 복지를 입력해주세요.');
            document.getElementById('em_welfare').focus();
            return false;
        }
        if (Announcement.em_address1 === "") {
            alert('회사주소를 입력해주세요.');
            document.getElementById('em_address').focus();
            return false;
        }

        if (window.confirm("저장하시겠습니까?")) {
            const param = new URLSearchParams();
            param.append("mem_idx", 1);
            param.append("hsm_idx", 0)
            param.append("em_title", Announcement.em_title);
            param.append("em_status", Announcement.em_status);
            param.append("com_name", Announcement.com_name);
            param.append("com_homepage", Announcement.com_homepage);
            param.append("em_sdate", Announcement.em_sdate);
            param.append("em_edate", Announcement.em_edate);
            param.append("em_deadline", Announcement.em_deadline);
            param.append("em_task", JSON.stringify(varEmtasksDetailList));
            param.append("em_tasks", Announcement.em_tasks);
            param.append("em_tasks_detail", Announcement.em_tasks_detail);
            param.append("em_position", Announcement.em_position);
            param.append("em_places", Announcement.em_places);
            param.append("em_careers", Announcement.em_careers);
            param.append("em_career_year", Announcement.em_career_year);
            param.append("em_career_end", Announcement.em_career_end);
            param.append("em_type", Announcement.em_type);
            param.append("em_reward_recommend", Number(Announcement.em_type) === 4 ? Announcement.em_reward_recommend : Number(Announcement.em_type) === 3 ? 0 : CONFIG.totalReward);
            param.append("em_reward_applicant", Number(Announcement.em_type) === 3 ? 0 : RE_list[0].vre_price);
            param.append("em_headhunter", Number(Announcement.em_type) === 4 ? 1 : 0);
            param.append("emh_reward_recommend", Number(Announcement.em_type) === 4 ? Announcement.em_reward_recommend : Number(Announcement.em_type) === 3 ? 0 : CONFIG.totalReward);
            param.append("emh_reward_applicant", Number(Announcement.em_type) === 3 ? 0 : RE_list[0].vre_price);
            param.append("em_reward_payment", Announcement.em_reward_payment);
            param.append("em_intro", escapeHtml(Announcement.em_intro));
            param.append("em_main_task", escapeHtml(Announcement.em_main_task));
            param.append("em_qualifications", escapeHtml(Announcement.em_qualifications));
            param.append("em_preference", escapeHtml(Announcement.em_preference));
            param.append("em_welfare", escapeHtml(Announcement.em_welfare));
            param.append("em_others", escapeHtml(Announcement.em_others));
            param.append("em_address1", Announcement.em_address1);
            param.append("em_address2", Announcement.em_address2);
            param.append("mcm_idx", varSelectedCompany);
            param.append("em_ufile", curUploadFile === "" ? Announcement.em_ufile : curUploadFile);

            axios.post(`${CONFIG.SERVER_HOST}/employ_insert_admin`, param).then((res) => {
                console.log(res.data.message);
                const data = res.data;
                if (data.retvalue === 1) {
                    alert('저장 되었습니다.');
                    window.location.href = "/admin/announcement_list";
                } else {
                    alert('오류가 발생하여 저장에 실패했습니다.');
                }
            });
        } else {
        }
    }



    // const Companylist = CM_list.filter((Company) => {
    //     if (Announcement.com_name !== "") {
    //         return Company.mcm_name.includes(Announcement.com_name);
    //     } else {
    //         return Company;
    //     }
    // });
    // 직무 추가
    const handleOnClickEmtaskDetail = () => {
        if (Number(varEmtasks.value) === 0) {
            alert('직무를 선택해주세요.');
            document.getElementById('em_tasks').focus();
            return false;
        }
        if (Number(varEmtasksDetail.value) === 0) {
            alert('세부직무를 선택해주세요.');
            document.getElementById('em_tasks_detail').focus();
            return false;
        }
        if (varEmtasksDetailList.findIndex(el => el.sub_value === Number(varEmtasksDetail.value)) >= 0) {
            return false;
        }

        if (varEmtasksDetailList.length >= CONFIG.selectMaxCount) {
            alert(`직무 선택은 ${CONFIG.selectMaxCount}개 까지만 가능합니다.`);
            return false;
        }

        if (varEmtasksDetailList.findIndex(el => el.sub_value === Number(varEmtasksDetail.value)) < 0) {
            setVarEmtasksDetailList(prevState => [...prevState, {
                "value": varEmtasks.value,
                "text": varEmtasks.text,
                "sub_value": varEmtasksDetail.value,
                "sub_text": varEmtasksDetail.text
            }]);
        }
    }

    // 세부 직무 선택
    const onChangeEmTaskDetail = (e) => {
        setVarEmtasksDetail({
            "value": e.target.value,
            "text": e.target.selectedOptions[0].text
        });
        if (varEmtasksDetailList.findIndex(el => el.sub_value === Number(e.target.value)) >= 0) {
            return false;
        }

        if (varEmtasksDetailList.length >= CONFIG.selectMaxCount) {
            alert(`직무 선택은 ${CONFIG.selectMaxCount}개 까지만 가능합니다.`);
            return false;
        }

        if (varEmtasksDetailList.findIndex(el => el.sub_value === Number(e.target.value)) < 0) {
            setVarEmtasksDetailList(prevState => [...prevState, {
                "value": varEmtasks.value,
                "text": varEmtasks.text,
                "sub_value": Number(e.target.value),
                "sub_text": e.target.selectedOptions[0].text
            }]);
        }
        setVarEmtask({
            value: '',
            text: '',
        })
        setVarEmtasksDetail({
            value: '',
            text: '',
        })
    }
    return (
        <React.Fragment>
            <Titlebar />
            <Tabbar tabType="announcement" />
            <Menu menuType="announcement" menu="announcement_list" />

            <div id="contents" className="ad_contents">
                <div className="tit">공고관리
                    <div className="path">
                        <img src={iconhome} alt="" />
                        HOME<img src={arr} alt="" />공고관리<img src={arr} alt="" />채용 공고 상세
                    </div>
                </div>
                <div className="content">

                    <div className="table_title">- 공고 신청 관리</div>
                    <div className="main-box">
                        <table className="write">
                            <tr>
                                <th>공고제목<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <input type="text" maxLength="30" name="em_title" id="em_title" value={Announcement.em_title} onChange={handleChange} />
                                </td>

                            </tr>
                            <tr>
                                <th>공고상태<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5} style={{ color: "red" }}>
                                    <select className="short_select" name="em_status" id="em_status" value={Announcement.em_status} onChange={handleChange} style={{ marginRight: "100px" }}>
                                        <option value="-1">삭제</option>
                                        <option value="0">대기중</option>
                                        <option value="1">진행중</option>
                                        <option value="2">마감</option>
                                    </select>
                                    ※ 공고의 승인은 공고상태를 '진행중'으로 변경 후 저장
                                </td>
                            </tr>
                            <tr>
                                <th>회사명<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <div>
                                        {Announcement.com_name}  <button className="btn-danger" id="com_name" onClick={openModal} >회사명 선택</button>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <th>기업로고</th>
                                <td colSpan={5}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={logoFile} alt="" style={{ width: "100px", height: "100px", display: !logoFile ? "none" : "block", objectFit: "contain" }} />
                                        <input type="file" accept="image/*" ref={photoInput} style={{ display: "none" }} onChange={(e) => onUploadEmployLogo(e)} />
                                        <button className="btn line" id="em_image" onClick={(e) => onSelectEmployLogo(e)}>로고 등록</button>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <th>홈페이지</th>
                                <td colSpan={5}>
                                    <input type="text" name="com_homepage" id="com_homepage" value={Announcement.com_homepage} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>지원기간<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={4} className="write-radio">
                                    <input type="date" name="em_sdate" id="em_sdate" value={Announcement.em_sdate} onChange={handleChange} /> ~ <input type="date" name="em_edate" id="em_edate" value={Announcement.em_deadline ? "" : Announcement.em_edate} onChange={handleChange} disabled={Announcement.em_deadline} />
                                    <label style={{ marginLeft: "10px" }}><input type="checkbox" name="em_deadline" value={Announcement.em_deadline} onChange={handleChange} checked={Announcement.em_deadline === 1 ? true : false} /> 채용시 마감</label>
                                </td>
                            </tr>
                            <tr>
                                <th>직무<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <select className="short_select" name="em_tasks" id="em_tasks" value={varEmtasks.value} onChange={(e) => {
                                        setVarEmtask({
                                            value: e.target.value,
                                            text: e.target.selectedOptions[0].text
                                        });
                                        handleChange(e);
                                    }}>
                                        <option value={0}>선택</option>
                                        {WC_list.map((category, i) => (
                                            <option value={category.vwc_idx}>{category.vwc_name}</option>
                                        ))}
                                    </select>
                                    &nbsp;&nbsp;&nbsp;

                                    <select className="short_select" name="em_tasks_detail" id="em_tasks_detail" value={varEmtasksDetail.value} onChange={(e) => onChangeEmTaskDetail(e)} >
                                        <option value={0}>선택</option>
                                        {CD_list.map((detail, i) => (
                                            <option key={"vwd_" + i} value={detail.vwd_idx}>{detail.vwd_name}</option>
                                        ))}
                                    </select>
                                    <button type="button" className="btn line" onClick={handleOnClickEmtaskDetail}>직무 추가</button>
                                    <ul className="selected" style={{ display: "flex" }}>
                                        {varEmtasksDetailList.map((n, i) => {
                                            return <li key={i} style={{ paddingRight: "12px" }}>{`${n.text} > ${n.sub_text}`} <button className="btn_del" onClick={() => {
                                                setVarEmtasksDetailList(varEmtasksDetailList.filter((e) => e.sub_value !== n.sub_value));
                                            }}><img src={deleteImage01} alt="" /></button></li>
                                        })}
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th>지역<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <select className="short_select" name="em_places" id="em_places" value={Announcement.em_places} onChange={handleChange}>
                                        <option value={-1}>선택</option>
                                        <option value={0}>전체</option>
                                        <option value={11}>서울</option>
                                        <option value={26}>부산</option>
                                        <option value={28}>인천</option>
                                        <option value={30}>대전</option>
                                        <option value={27}>대구</option>
                                        <option value={29}>광주</option>
                                        <option value={31}>울산</option>
                                        <option value={36}>세종</option>
                                        <option value={41}>경기</option>
                                        <option value={42}>강원</option>
                                        <option value={43}>충북</option>
                                        <option value={44}>충남</option>
                                        <option value={45}>전북</option>
                                        <option value={46}>전남</option>
                                        <option value={47}>경북</option>
                                        <option value={48}>경남</option>
                                        <option value={50}>제주</option>
                                        <option value={99}>해외</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>경력<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <div className="write-radio">
                                        <input
                                            type="radio"
                                            name="em_careers"
                                            id="em_careers"
                                            onChange={handleChange}
                                            value={2}
                                            checked={Number(Announcement.em_careers) === 2 ? true : false}
                                        />
                                        <label htmlFor="em_careers" className="mgr0">경력자&nbsp;</label>(
                                        <input
                                            type="number"
                                            maxLength="2"
                                            style={{ width: '80px' }}
                                            id="em_career_year"
                                            name="em_career_year"
                                            value={Announcement.em_career_year}
                                            className={Number(Announcement.em_careers) === 2 ? "" : "none"}
                                            onChange={handleChange}
                                            placeholder="0"

                                        />  년 이상
                                        <input
                                            type="number"
                                            name="em_career_end"
                                            id="em_career_end"
                                            style={{ width: '80px' }}
                                            value={Announcement.em_career_end}
                                            className={Number(Announcement.em_careers) === 2 ? "" : "none"}
                                            onChange={handleChange}
                                            placeholder="0"
                                        /> &nbsp;년 이하)
                                        <input
                                            type="radio"
                                            name="em_careers"
                                            value={1}
                                            onChange={handleChange}
                                            checked={Number(Announcement.em_careers) === 1 ? true : false}
                                        />
                                        <label htmlFor="em_careers">신입</label>
                                        <input
                                            type="radio"
                                            name="em_careers"
                                            value={0}
                                            onChange={handleChange}
                                            checked={Number(Announcement.em_careers) === 0 ? true : false}
                                        />
                                        <label htmlFor="em_careers">경력무관</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>채용구분<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <div className="write-radio">
                                        <input type="radio" name="em_type" id="em_type1" value={1} onChange={handleChange} checked={Number(Announcement.em_type) === 1 ? true : false} /><label for="em_type1"> 공개 </label>
                                        <input type="radio" name="em_type" id="em_type2" value={2} onChange={handleChange} checked={Number(Announcement.em_type) === 2 ? true : false} /> <label for="em_type2">비공개</label>
                                        <input type="radio" name="em_type" id="em_type3" value={3} onChange={handleChange} checked={Number(Announcement.em_type) === 3 ? true : false} /> <label for="em_type3">보상금 없는 채용</label>
                                        <input type="radio" name="em_type" id="em_type4" value={4} onChange={handleChange} checked={Number(Announcement.em_type) === 4 ? true : false} /> <label for="em_type4">헤드헌터 전용관</label>
                                    </div>
                                </td>
                            </tr>
                            {Number(Announcement.em_type) === 3 ? null :
                                <tr>
                                    <th>총보상금<span style={{ color: "red" }}>*</span></th>
                                    <td>
                                        {Number(Announcement.em_type) === 4 ?
                                            <select className="short_select" name="em_reward_recommend" id="em_reward_recommend" value={Announcement.em_reward_recommend} onChange={handleChange}>
                                                <option value={0}>선택</option>
                                                {RR_list.map((detail, i) => (
                                                    <option value={detail.vrr_price}>{ChangeMoneyUnit(detail.vrr_price / 10000)}</option>
                                                ))}
                                            </select>
                                            :
                                            ChangeMoneyUnit(CONFIG.totalReward / 10000)
                                        }
                                    </td>
                                    <th>추천인 보상금</th>
                                    {Number(Announcement.em_type) === 4 ?
                                        <td>
                                            {ChangeMoneyUnit(Announcement.em_reward_recommend / 10000 - Announcement.em_reward_applicant / 10000)}
                                        </td>
                                        :
                                        <td>
                                            {ChangeMoneyUnit(CONFIG.totalReward / 10000 - Announcement.em_reward_applicant / 10000)}
                                        </td>
                                    }
                                    <th>합격자 보상금<span style={{ color: "red" }}>*</span></th>
                                    <td>
                                        {RE_list.length ?
                                            <select className="short_select" name="em_reward_applicant" id="em_reward_applicant" value={Announcement.em_reward_applicant} onChange={handleChange}>
                                                <option value={0}>선택</option>
                                                {RE_list.map((detail, i) => (
                                                    <option value={detail.vre_price}>{ChangeMoneyUnit(detail.vre_price / 10000)}</option>
                                                ))}
                                            </select>
                                            : ""
                                        }
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>회사소개<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <textarea name="em_intro" id="em_intro" value={Announcement.em_intro} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>주요업무<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <textarea name="em_main_task" id="em_main_task" value={Announcement.em_main_task} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>자격요건<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <textarea name="em_qualifications" id="em_qualifications" value={Announcement.em_qualifications} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>우대사항<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <textarea name="em_preference" id="em_preference" value={Announcement.em_preference} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>혜택 및 복지<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <textarea name="em_welfare" id="em_welfare" value={Announcement.em_welfare} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>기타 안내사항</th>
                                <td colSpan={5}>
                                    <textarea name="em_others" id="em_others" value={Announcement.em_others} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>회사주소<span style={{ color: "red" }}>*</span></th>
                                <td colSpan={5}>
                                    <input type="text" maxLength="30" name="em_address1" id="em_address1" value={Announcement.em_address1} onChange={handleChange} />
                                </td>
                            </tr>
                            <tr>
                                <th>회사상세주소</th>
                                <td colSpan={5}>
                                    <input type="text" maxLength="30" name="em_address2" id="em_address2" value={Announcement.em_address2} onChange={handleChange} />
                                </td>
                            </tr>



                        </table>
                        <div className="btn-center">
                            <button className="btn-danger" onClick={onSubmitHandler} >등록</button>
                            <button className="btn-danger" ><Link to="/admin/userlist" style={{ color: "#fff" }}>목록</Link></button>
                        </div>
                    </div>


                </div>
            </div>

            <Modal isOpen={Modal_status}
                style={customStyles}
                ariaHideApp={false}
            >
                {/* <div>
                    <>

                        <div className="btn-center" style={{ textAlign: "left" }}>
                            <input type="text" name="com_name" value={Announcement.com_name || ''} onChange={handleChange} />
                            <button className="btn-danger" >조회</button>
                        </div>
                    </>
                    <table className="select-list" >
                        <tr className="select-items">
                            <td>
                                <select size="10" name="mcm_idx" value={Modal_data.mcm_idx || ''} onChange={handleChangeModal}>
                                    {Companylist.map((company, i) => (
                                        <option key={i} value={company.mcm_idx}>{company.mcm_name}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>

                    </table>

                    <div className="btn-center">
                        <button className="btn-danger" onClick={closeModal}>닫기</button>
                        <button className="btn-danger" onClick={setModalData}>선택하기</button>
                    </div>
                </div> */}
                <div
                    className="popup"
                    style={{ display: "block" }}
                >
                    <article className="popup_wrap popup01">
                        <div className="title_wrap">
                            <h1>기업검색</h1>
                        </div>
                        <div className="content_wrap company_search">
                            <section
                                className="search_area"
                                style={{ display: "flex", justifyContent: "space_between" }}
                            >
                                <input
                                    type="text"
                                    name="search_name"
                                    value={varSearchString}
                                    onChange={(e) => onSearchStringHandler(e)}
                                    placeholder="회사명을 입력해주세요."
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearchingString(e)
                                        }
                                    }}
                                />
                                <button
                                    className="btn"
                                    onClick={(e) => onSearchingString(e)}
                                    style={{ width: "90px" }}
                                >
                                    {searchLoading ? `조회중` : `조회`}
                                </button>
                            </section>
                            <section className="company_list_area style_scroll">
                                {varSearchingCompanyData?.length === 0 ? (
                                    <div className="nodata">{varChkSearch === false ? '검색된 회사가 없습니다.' : '회사를 조회중입니다.'} </div>
                                ) : (
                                    varSearchingCompanyData?.map((company, i) => (
                                        <div key={"com_" + i}>
                                            <input
                                                type="radio"
                                                name="radio_company"
                                                id={`radio_company${i + 1}`}
                                                onClick={() => onSelectCompanyHandler(company)}
                                                disabled={searchLoading}
                                            />
                                            <label htmlFor={`radio_company${i + 1}`}>
                                                {company.mcm_name}
                                            </label>
                                        </div>
                                    ))
                                )}
                            </section>
                            <div className="paging">
                                <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
                            </div>
                        </div>
                        <div className="button_wrap">
                            <div className="big_button_wrap one">
                                <button className="btn" onClick={(e) => onSelectedCompanyHandler(e)}>
                                    선택하기
                                </button>
                            </div>
                            <div className="close_button_wrap">
                                <button onClick={closeModal}>
                                    닫기 X
                                </button>
                            </div>
                        </div>
                    </article>
                </div>
            </Modal>

        </React.Fragment>
    )

}

export default Announcement_Register;