/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/configs';
import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import Modal from 'react-modal';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '46vh'
  },
};

function Push_personal() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [linkurl, setLinkurl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [PushTransmit, setPushTransmit] = useState([]);
  const [selectUser, setSelectUser] = useState(0);
  const [UserData, setUserData] = useState('');
  const [option, setOption] = useState("all");
  const [varSearchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (adminlogin === undefined || adminlogin === null || adminlogin === "false") {
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBody = (e) => {
    setBody(e.target.value);
  };

  const handleLinkurl = (e) => {
    setLinkurl(e.target.value);
  }

  const sendPush = () => {
    //sendPushPersonal

    if (selectUser === 0) {
      alert('회원을 선택해 주세요.');
      return false;
    }
    const sendData = {
      'mem_idx': selectUser,
      'title': title,
      'body': body,
      'linkurl': linkurl
    }

    axios.post(`${CONFIG.SERVER_HOST}/sendPushPersonal`, sendData, CONFIG.header).then((res) => {
      // console.log(res); 
      if(res){
        if(res.data.retvalue === 1){ 
          alert('전송되었습니다.');
        }else{
          alert('전송에 실패했습니다.');
        }
      }
    });

  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  }

  const showModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (modalOpen) {
      getPushTransmittable();
    }
  }, [modalOpen]);

  const getPushTransmittable = () => {

    const sendData = {
      'option': option,
      'searchinput': varSearchInput
    };

    axios.post(`${CONFIG.SERVER_HOST}/getPushTransmittable`, sendData, CONFIG.header).then((res) => {
      const data = res.data.result.map((data, i) => ({
        order: i + 1,
        mem_idx: data.mem_idx,
        mem_id: data.mem_id,
        mem_name: data.mem_name,
        checked: 'N',
      }));
      setPushTransmit(data)
    });
  }
  const setUser = (e) => {
    const { value } = e.target;

    if (selectUser === Number(value)) {
      setSelectUser(0);
    } else {
      setSelectUser(Number(value));
    }
  };

  const settingData = () => {

    if (selectUser === 0) {
      alert('회원을 선택해 주세요.');
      return false;
    }
    const info = PushTransmit.filter((el) => el.mem_idx === selectUser);
    setModalOpen(!modalOpen);
    setUserData(info[0]['mem_name'])
  };


  const handleInput = (e) => {
    setSearchInput(e.currentTarget.value);
  };

  const handleOption = (e) => {
    const { name } = e.target;
    setOption(name);
  };

  return (
    <React.Fragment>
        <Titlebar />
        <Tabbar tabType="pref" />
        <Menu menuType="pref" menu="personal" />
        <div id="contents" className="ad_contents">
          <div className="tit">개인 PUSH관리
            <div className="path">
              <img src={iconhome} alt="" />
              HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />PUSH 관리<img src={arrImage} alt="" />개인 PUSH관리
            </div>
          </div>
          <div className="content">
            {/* <div className="tit">PUSH</div> */}
            <div className="main-box">
              <table className="write">
                <tr>
                  <th>발송대상</th>
                  <td colspan="3">
                    <div className="write-radio">
                      <input type="text" readOnly value={UserData} />
                      <button className="btn" onClick={() => showModal()}>발송대상조회</button>
                    </div>

                  </td>
                </tr>
                <tr>
                  <th>link</th>
                  <td colspan="3"><input type="text" name="linkurl" value={linkurl} onChange={handleLinkurl} /></td>
                </tr>
                <tr>
                  <th>PUSH 제목</th>
                  <td colspan="3"><input type="text" name="title" value={title} onChange={handleTitle} /></td>
                </tr>
                <tr>
                  <th>PUSH 내용</th>
                  <td colspan="3"><textarea type="text" name="body" value={body} onChange={handleBody} /></td>
                </tr>
              </table>
            </div>
            <div className="btn-center">
              <button className="btn" onClick={() => sendPush()}>전송</button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          style={customStyles}
          ariaHideApp={false}
        >
          <>
            <div className="Popup pop_admin_push_personal">
              <div className="Wrap">
                <div className="Box">
                  <div className="Top">
                    <h3 className="Title">발송대상조회</h3>
                  </div>
                  <div className="PopContents">
                    <div className="list_top">
                      <select style={{ width: "100px" }} name="option" value={option} onChange={handleOption} >
                        <option value="all">전체</option>
                        <option value="mem_id">아이디</option>
                        <option value="mem_name">이름</option>
                      </select>
                      <input type="text" className="input" name="strsearch" value={varSearchInput} onChange={handleInput} onKeyDown={(e) => { if (e.key === 'Enter') getPushTransmittable(e) }} />
                      <button className="btn" onClick={() => getPushTransmittable()}>검색</button>
                    </div>
                    <table className="table_list01">
                      <colgroup>
                        <col width="8%"></col>
                        <col width="42%"></col>
                        <col width="50%"></col>
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th>아이디</th>
                          <th>이름</th>
                        </tr>
                      </thead>
                      <tbody>
                        {PushTransmit.map((data, i) => (
                          <tr key={data.mem_idx}>
                            <td className="td_chk"><input type="checkbox" className="admin_chk" name="type1" value={data.mem_idx} checked={data.mem_idx === selectUser ? true : false} onClick={setUser} /></td>
                            <td className="ellipsis">{data.mem_id}</td>
                            <td>{data.mem_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="PopBottom">
                    <button className="btn" onClick={() => settingData()}>등록</button>
                      <button className="btn line" onClick={() => showModal()}>취소</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal>
    </React.Fragment>
  )
}

export default Push_personal;