/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "configs/configs";

//components
import { changeMobileFormat, changeMobileNumber, Certification, AppTopicSetting } from "../Common/Common";

//이미지
import icon_pw from "img/sub/icon_pw.png";

/*프로필*/
function PopEditProfile({ varPopup, onSetPopup, varMemberData, setMemberData, setPopup }) {
  const user_idx = Number(localStorage.getItem("mem_idx"));

  //회원정보
  const [varInputs, setInputs] = useState({});
  //인증
  const [varAuth, setAuth] = useState({});

  //푸시알림
  const [PushNotifycation, setPushNotifycation] = useState(varInputs?.mobile === 'N' ? false : true);

  //비밀번호 검사
  const checkPwdHandler = async (e) => {
    const param = new URLSearchParams();
    console.log(varInputs)
    param.append("mem_oldPwd", varInputs.pwd);
    param.append("mem_idx", user_idx);
    await axios.post(`${CONFIG.SERVER_HOST}/checkPwd`, param).then((res) => {
      if (res.data.tf === true) {
        onSetPopup(e, 1)
      } else {
        alert('비밀번호가 일치하지 않습니다.')
      }
    });
    setInputs({ ...varInputs, pwd: "" });
  }

  //입력창 변경시
  const onChangeHandler = (e) => {
    const { value, name } = e.currentTarget;
    console.log({ value, name });
    if (name === "email1") {
      setAuth({ ...varAuth, email: false });
      setInputs({ ...varInputs, email1: value });
    } else if (name === "email2") {
      setAuth({ ...varAuth, email: false });
      setInputs({ ...varInputs, email2: value });
    } else if (name === "nickname") {
      setAuth({ ...varAuth, nickname: false });
      setInputs({ ...varInputs, nickname: value });
    } else if (name === "mobile") {
      const mobileString = changeMobileNumber(e.currentTarget.value);
      const mobileResult = changeMobileFormat(mobileString);
      e.currentTarget.value = mobileResult;
      setAuth({ ...varAuth, mobile: false });
      setInputs({ ...varInputs, mobile: mobileResult });
    } else {
      setInputs({ ...varInputs, [name]: value });
    }
  };


  //닉네임 중복검사
  const checkNicknameHandler = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("mem_nickname", varInputs.nickname);
    axios.post(`${CONFIG.SERVER_HOST}/checkNick`, params).then((res) => {
      console.log(res.data);
      if (res.data.tf === true) {
        alert("사용가능한 닉네임입니다.");
        setAuth({ ...varAuth, nickname: true });
      } else {
        alert("사용중인 닉네임 입니다. \n다른 닉네임을 입력해주세요.");
      }
    });
  };


  //이메일 중복 검사
  const onCheckEmailHandler = (e) => {
    e.preventDefault();
    var regExp =
      /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!regExp.test(varInputs.email1 + "@" + varInputs.email2)) {
      alert("이메일 형식에 맞지않습니다.");
      return;
    }
    //const data = {mem_email: varInputs.email};
    const params = new URLSearchParams();
    params.append("mem_email", varInputs.email1 + "@" + varInputs.email2);
    params.append("user_idx", user_idx);
    axios.post(`${CONFIG.SERVER_HOST}/member_check_email`, params).then((res) => {
      if (res.data.tf === true) {
        alert("사용가능한 이메일입니다.");
        setAuth({ ...varAuth, email: true });
      } else {
        alert("이미 사용중인 이메일입니다. 다른 이메일을 입력해주세요.");
      }
    });
  };

  //회원정보 재저장
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    try {
      if (!varInputs.nickname) {
        alert("닉네임을 입력해주세요.");
      } else if (!varInputs.email1 || !varInputs.email2) {
        alert("이메일을 입력해주세요.");
      } else if (!varInputs.mobile) {
        alert("휴대폰번호를 입력해주세요.");
      } else if (varAuth.nickname !== true && varAuth.oldNickname !== varInputs.nickname) {
        alert("닉네임 중복확인을 해주세요.");
      } else if (varAuth.email !== true && varAuth.oldEmail !== varInputs.email1 + "@" + varInputs.email2) {
        alert("이메일 중복확인을 해주세요.");
      } else if (varAuth.mobile !== true && varAuth.oldMobile !== varInputs.mobile) {
        alert("휴대폰 인증을 해주세요.");
      } else {
        const param = new URLSearchParams();
        console.log(varInputs)
        param.append("mem_nickname", varInputs.nickname);
        param.append("mem_name", varInputs.name);
        param.append("mem_birthDay", varInputs.birthDay);
        param.append("mem_gender", varInputs.gender);
        param.append("mem_email", varInputs.email1 + "@" + varInputs.email2);
        param.append("mem_mobile", varInputs.mobile);
        param.append("mem_idx", user_idx);
        param.append("push_YN", PushNotifycation ? 'Y' : 'N');

        await axios.post(`${CONFIG.SERVER_HOST}/memberInfoUpdate`, param).then((res) => {
          if (res.data.retvalue === 1) {
            document.getElementById("headerNickname").innerText = varInputs.nickname;
            localStorage.setItem("mem_nickname", varInputs.nickname);
            setMemberData({
              ...varMemberData,
              nickname: varInputs.mem_nickname,
              mem_name: varInputs.name,
              mem_birthday: varInputs.birthDay,
              mem_gender: varInputs.gender,
              mem_mobile: varInputs.mobile,
              mem_email: varInputs.email1 + "@" + varInputs.email2,
            });
            AppTopicSetting(PushNotifycation ? 'Y' : 'N');
          }
          alert(res.data.message);
          setPopup(0);
        });
      }
    } catch (error) {
      alert("등록 실패");
      console.log(error);
    }
  };

  //핸드폰 인증
  const onMobileCertification = (e) => {
    Certification(varInputs, setInputs, varAuth, setAuth, 0);
  }

  useEffect(() => {
    setInputs({
      nickname: varMemberData.mem_nickname,
      name: varMemberData.mem_name,
      gender: varMemberData.mem_gender,
      birthDay: varMemberData.mem_birthday,
      email1: varMemberData.mem_email?.split("@")[0],
      email2: varMemberData.mem_email?.split("@")[1],
      mobile: varMemberData.mem_mobile,
      certification: false,
      pwd: ""
    });
    setAuth({
      oldNickname: varMemberData.mem_nickname,
      oldEmail: varMemberData.mem_email,
      oldMobile: varMemberData.mem_mobile,
      nickname: true,
      email1: true,
      email2: true,
      mobile: true,
    });
    setPushNotifycation(varMemberData.push_YN === 'Y' ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPopup]);

  //메일서버선택
  const onChangeMailServerHandler = (e) => {
    let eserver = e.currentTarget.value;
    let serverAddress = document.getElementById("email2");
    if (eserver === "") {
      serverAddress.value = "";
      serverAddress.focus();
    } else {
      setInputs({ ...varInputs, email2: eserver });
    }
  };

  //푸시알림 핸들러
  const onPushNotifycationHandler = (e) => {
    const ichecked = e.target.checked;
    setPushNotifycation(ichecked);

  }

  console.debug('varMemberData  ', varMemberData);

  return (
    <>
      {varPopup === 3 ? (
        <div className={"popup active"}>
          <div className="popup_wrap popup01">
            <section className="title_wrap">
              <h1>비밀번호 인증</h1>
            </section>
            <section className="content_wrap">
              <article className="notice_area">
                <div className="icon"><img src={icon_pw} alt="icon_pw" /></div>
                <p className="title_field">정보를 안전하게 보호하기 위해<br />
                  <span className="point">비밀번호를 다시 한 번 확인</span>합니다.</p>
                <p className="text_field">비밀번호가 타인에게 노출되지 않도록 항상 주의해주세요.</p>
              </article>
              <div className="field">
                <label className="label">비밀번호</label>
                <input type="password" placeholder="비밀번호를 입력해주세요" name="pwd" value={varInputs?.pwd} onChange={(e) => onChangeHandler(e)} onKeyDown={(e) => { if (e.key === 'Enter') checkPwdHandler(e) }} />
              </div>
            </section>
            <section className="button_wrap">
              <div className="big_button_wrap two">
                <button className="btn white" onClick={() => onSetPopup(0)}>취소</button>
                <button className="btn" onClick={(e) => checkPwdHandler(e)}>확인</button>
              </div>
              <div className="close_button_wrap">
                <button onClick={() => onSetPopup(0)}>닫기 X</button>
              </div>
            </section>
          </div>
        </div>
      ) : varPopup === 1 ? (
        <div className="popup active">
          <div className="popup_wrap popup01">
            <section className="title_wrap">
              <h1>프로필 수정하기</h1>
            </section>
            <div className="content_wrap">
              <fieldset>
                <div className="field">
                  <label className="label">닉네임</label>
                  <div className="input_button">
                    <input type="text" name="nickname" value={varInputs?.nickname} onChange={(e) => onChangeHandler(e)} />
                    <button className="btn white" onClick={(e) => checkNicknameHandler(e)}>중복확인</button>
                  </div>
                </div>
                <div className="field col">
                  <p className="label">이름</p>
                  <p className="text">{varInputs?.name}</p>
                </div>
                <div className="field">
                  <label className="label">이메일</label>
                  <div className="input_button email_field">
                    <input type="text" name="email1" id="email1" value={varInputs?.email1} onChange={(e) => onChangeHandler(e)} />
                    <span className="text">@</span>
                    <input type="text" name="email2" id="email2" value={varInputs?.email2} onChange={(e) => onChangeHandler(e)} />
                    <select className="style_select" onChange={(e) => onChangeMailServerHandler(e)}>
                      <option value={""}>직접입력</option>
                      <option value={"naver.com"}>naver.com</option>
                      <option value={"gmail.com"}>gmail.com</option>
                      <option value={"hanmail.net"}>hanmail.net</option>
                      <option value={"hotmail.com"}>hotmail.com</option>
                      <option value={"icloud.com"}>icloud.com</option>
                    </select>
                    <button className="btn white" onClick={(e) => onCheckEmailHandler(e)}>중복확인</button>
                  </div>
                </div>
                <div className="field">
                  <label className="label">휴대폰</label>
                  <div className="input_button">
                    <input type="text" name="mobile" value={changeMobileFormat(varInputs?.mobile)} readOnly />
                    <button className="btn white" onClick={(e) => onMobileCertification(e)}>번호 변경</button>
                  </div>
                </div>
                <div className="field">
                  <label className="label">푸시알림</label>
                </div>
                <div className="setting">
                  <input type="checkbox" id="btnToggle1" checked={PushNotifycation} onChange={(e) => onPushNotifycationHandler(e)} />
                </div>
              </fieldset>
              <section className="button_wrap">
                <div className="big_button_wrap two">
                  <button className="btn white" onClick={() => onSetPopup(0)}>취소</button>
                  <button className="btn" id="upateInformation" onClick={(e) => onUpdateHandler(e)}>수정 완료</button>
                </div>
                <div className="close_button_wrap">
                  <button onClick={() => onSetPopup(0)}>닫기 X</button>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : null
      }
    </>
  );
}

export default PopEditProfile;
