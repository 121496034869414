/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import iconhome from "../../img/icon-home.png";
import arrImage from "../../img/arr.png";
import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";
import axios from "axios";
import CONFIG from "../../../configs/configs";
import moment from "moment";

function Banner() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [initData, setInitData] = useState([]);
  const imageFolder = `${CONFIG.SERVER_HOST}/banner/`;

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    axios.post(`${CONFIG.SERVER_HOST}/banner`).then((res) => {
      const data = res.data.map((data, i) => ({
        order: i + 1,
        banner_idx: data.banner_idx,
        banner_title: data.banner_title,
        banner_ufile: data.banner_ufile,
        banner_rdate: data.banner_rdate,
        banner_mode: data.banner_mode,
      }));
      setInitData([...data].reverse());
    });
  }, []);

  const deleteHandler = (idx, image) => {
    const confirm = window.confirm("삭제하시겠습니까?");
    try {
      if (confirm === true) {
        const params = new URLSearchParams();
        params.append("name", image);
        axios.post(`${CONFIG.SERVER_HOST}/banner_delete?banner_idx=${idx}`, params).then((res) => {
            console.log("delete :: result :: ", res.data);
            alert("삭제되었습니다.");
            window.location.reload();
          });
      }
    } catch (error) {
      console.error();
    }
  };

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="banner" />
      <div id="contents" className="ad_contents">
        <div className="tit">
        배너 관리
          <div className="path"><img src={iconhome} alt=""/>HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />디자인 관리<img src={arrImage} alt="" />배너 관리</div>
        </div>
        <div className="content">
          {/* <div className="tit">배너 관리</div> */}
          <div className="main-box2">
            <div className="btn-right">
              <Link className="btn" to={`/admin/banner/detail/${0}`} style={{ color: "#fff", width: "100%" }} >등록</Link>
            </div>
          </div>
          <div className="main-box">
              <table className="list">
                <colgroup>
                  <col width="8%"></col>
                  <col width="30%"></col>
                  <col width="31%"></col>
                  <col width="8%"></col>
                  <col width="10%"></col>
                  <col width="13%"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>이미지</th>
                    <th>제목</th>
                    <th>모드</th>
                    <th>등록일</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {initData.map((data, i) => (
                    <tr key={data.banner_idx}>
                      <td><p>{data.order}</p></td>
                      <td><img src={`${imageFolder}${data.banner_ufile}`} style={{ height: "100px" }} alt=""/></td>
                      <td className="ellipsis"><p>{data.banner_title}</p></td>
                      <td><p>{data.banner_mode === 0 ? "일반" : "기업"}</p></td>
                      <td><p>{moment(data.banner_rdate).format(CONFIG.formatStr)}</p></td>
                      <td>
                        <Link to={`/admin/banner/detail/${data.banner_idx}`} className="btn line black">수정</Link>
                        <button className="btn line black" onClick={()=>deleteHandler(data.banner_idx,data.banner_ufile)}>삭제</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Banner;
