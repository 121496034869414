/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../../configs/configs';

import Paging from '../../../components/Layout/Paging'

function UserResume({ user_idx, setCategory }) {

    const [varShowResume,setShowResume] = useState(0);
    const [varFixedResumeIndex,setFixedResumeIndex] = useState(0);

    const [varResumelist, setResumelist] = useState([]);
    const [varPagelist, setPagelist] = useState([]);

    //페이징
    const [varTotalCount,setTotalCount] = useState(0);
    const [varPageSize] = useState(10);
    const [varPageNum,setPageNum] = useState(1);

    useEffect(async () => {
        setCategory(2);
        const params = new URLSearchParams();
        params.append('user_idx', user_idx);
        await axios.post(`${CONFIG.SERVER_HOST}/_get_mysavedresume_list`, params).then((res) => {
          console.log(res.data)
            const datas = res.data.results;
            setTotalCount(datas.length);
            setResumelist(datas);

            //대표이력서의 검색노출유무 확인
            const refixData = datas.filter(element => element.re_fix === 1);
            if(refixData.length > 0) {
              setShowResume(refixData[0].re_show);
              setFixedResumeIndex(refixData[0].re_idx);
            }
        });   
    }, []);

    //페이지별 표시
    const getPageLists = () => {
      const startNum = (varPageNum-1)*varPageSize;
      const endNum = startNum + varPageSize;
      const copyData = varResumelist.slice(startNum,endNum);
      setPagelist(copyData);
    }

    useEffect(() => {
      getPageLists();
    },[varPageNum,varResumelist]);

    //대표이력서 - 인재검색에 노출 유무 지정
    const handleChangeShow = (e) => {
      const iselect = Number(e.currentTarget.value);
      setShowResume(iselect);
      setChangeShowStatus(iselect);
    }
    //대표이력서 - 인재검색에 노출 유무 지정 --> 데이타베이스 지정
    const setChangeShowStatus = async (iselect) => {
      const params = new URLSearchParams();
      params.append('re_idx', varFixedResumeIndex);
      params.append('re_show', iselect);
      await axios.post(`${CONFIG.SERVER_HOST}/setResumeShow`,params).then((res) => {
        alert(res.data.message);
      });
    }

    return (
        <React.Fragment>
        <p style={{marginTop:"20px"}} className="table_title">- 이력서 정보</p>
                <div className="main-box ">
                  <table className="write">
                    <thead></thead>
                    <tbody >
                        <tr>
                          <th>인재 검색하기 노출</th>
                          <td style={{textAlign: "left"}}><div className="write-radio">
                            <input type="radio" id="show1" name="re_show" value="1"
                                checked={varShowResume===1?true:false}
                                onChange={(e)=>handleChangeShow(e)}/>
                            <label htmlFor="show1">Y</label>
                            <input type="radio" id="show0" name="re_show" value="0"
                                checked={varShowResume===0?true:false}
                                onChange={(e)=>handleChangeShow(e)}/>
                            <label htmlFor="show0">N</label>
                          </div></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                <div className="main-box ">
                    <table className="list">
                        <thead>
                          <tr>
                            <th>번호</th>
                            <th>이력서 제목</th>
                            <th>발급번호</th>
                            <th>작성일</th>
                            <th>이력서 완성도</th>
                            <th>관리</th>
                          </tr>
                        </thead>
                        <tbody>
                          {varPagelist.map((resume, i) => (
                            <tr key={i}>
                                <td>{resume.re_fix===1?"[대표]":varTotalCount-(varPageNum-1)*varPageSize-i}</td>
                                <td>{resume.re_title === "" ? "제목없음" : resume.re_title}</td>
                                <td>{resume.re_code}</td>
                                <td>{resume.re_rdate.substr(0,10)}</td>
                                <td>{resume.re_perfection}%</td>
                                <td><Link to={`/admin/userinfo/${user_idx}/resume_Detail/${resume.re_idx}`}  className="btn line black">상세</Link></td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
                <div>
                  <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />  
                </div>
        </React.Fragment>
    )

}

export default UserResume;