import React from 'react';
import CONFIG from 'configs/configs';
import axios from 'axios';


const PopRepuDel = ({delPopup, setDelPopup, answer_no, name, setNewSelectedFriend, friends, setSelectedFriend, setFriendGraph, setActive}) => {

	const close =() =>{
		setDelPopup(false);
	}

	//_del_reputation_fridnds_anwser

	const del_answer_no = async () => {
		try {
			console.log('answer_no :', answer_no);
      const params = {answer_no: answer_no}
			console.log('params :', params);
      await axios.post(`${CONFIG.SERVER_HOST}/_del_reputation_fridnds_anwser`, params, CONFIG.header).then((res) => {

        if (res) {
					console.log(res);
					if(res.data.retvalue){
						//friends.filter(el => console.log(el.anwser_no));
					
						let copy = [...friends]
						const result = copy.filter(el => el.anwser_no !== answer_no);
						let newArr = [...result]
						console.log(newArr);
						setSelectedFriend(prev => { return { ...prev, answerNo: newArr[0].anwser_no, name: newArr[0].anonymous ? newArr[0].anonymous : newArr[0].anwser_writer } });
						setFriendGraph(newArr[0].anwser_no);
						setActive("who_see_area_options");
						setNewSelectedFriend(newArr);
						
						setDelPopup(false);
					}
        } else {
        }
      });
    } catch (error) {
      console.log(error);
    }
	}
	return (
		<div className={`popup ${delPopup ? `active`:``}`}>{/*활성화 시 active*/}
			<div className="popup_wrap popup01">
				<section className="title_wrap">
					<h1>친구가 보는 나 삭제하기</h1>
				</section>
				<section className="content_wrap">
					<article className="notice_area">
						<p className="title_field">{name} 님이 작성한 내용을 삭제하시겠습니까?</p>
					</article>
				</section>
				<section className="button_wrap">
					<div className="big_button_wrap two">
					  <button className="btn white" onClick={()=> close()}>취소</button>
						<button className="btn" onClick={() => del_answer_no()}>삭제하기</button>
					</div>
					{/* <div className="close_button_wrap">
							<a>닫기 X</a>
						</div> */}
				</section>
			</div>
		</div>
	)
}

export default PopRepuDel