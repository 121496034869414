/* eslint-disable jsx-a11y/anchor-is-valid */
//css
import axios from "axios";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../../configs/configs";

function NavMenu({ varIsLogin, onCloseMenuUser, isMobile }) {
  const navigator = useNavigate();

  const headObject = document.getElementById("head");
  const depth1Boxes = document.querySelectorAll(".depth1");
  const mem_mode = Number(localStorage.getItem('mem_mode'));
  const mem_idx = Number(localStorage.getItem('mem_idx'));
  const mem_sub = Number(localStorage.getItem('mem_sub'));
  const siteMode = Number(localStorage.getItem('siteMode'));

  // console.log('mem_mode!!!', mem_mode);
  // console.log('siteMode!!!', siteMode);

  useEffect(() => {
    (() => {
      for (var i = 0; i < depth1Boxes.length; i++) {
        depth1Boxes[i].addEventListener("mouseenter", () => {
          if (!headObject.classList.contains("on"))
            headObject.classList.add("on");
        });
        depth1Boxes[i].addEventListener("mouseleave", () => {
          if (headObject.classList.contains("on"))
            headObject.classList.remove("on");
        });
      }
    })();
    return () => { };
  });

  const moveChat = () => {

    let sendData = {
      "user_idx": mem_idx,
      "type": mem_mode,
    }

    axios.post(`${CONFIG.SERVER_HOST}/_getChatLastIdx`, sendData, CONFIG.header).then((res) => {
      const { results, retvalue } = res.data;
      if (retvalue === 1) {
        if (results) {
          navigator(`/MyHeding/MyHeding4/${results}`);
        } else {
          navigator(`/MyHeding/MyHeding4`);
        }
      }
    });
  };

  return (
    <ul className="nav">
      <li className="depth1">
        {/*해당페이지 활성화 시 li에 클래스 Active 추가*/}
        {isMobile ? (
          <button className="menu">채용정보</button>
        ) : (
          <Link
            to={"/MyRecruit/MainList2/1/1/20/0"}
            className="menu"
          >
            채용정보
          </Link>
        )}
        <ul className="depth2">
          <li>
            <Link
              to={"/MyRecruit/MainList2/1/1/20/0"}
              onClick={() => onCloseMenuUser()}
            >
              공개채용
            </Link>
          </li>
          <li>
            <Link
              to={"/MyRecruit/MainList2/2/1/20/0"}
              onClick={() => onCloseMenuUser()}
            >
              비공개채용
            </Link>
          </li>
          <li>
            <Link
              to={"/MyRecruit/MainList2/3/1/20/0"}
              onClick={() => onCloseMenuUser()}
            >
              실시간 채용관
            </Link>
          </li>
          <li>
            <Link
              to={"/MyRecruit/MainList2/4/1/20/0"}
              onClick={() => onCloseMenuUser()}
            >
              헤드헌터 전용관
            </Link>
          </li>
        </ul>
      </li>
      {siteMode === 0 ?
        <li className="depth1">
          {isMobile ? (
            <button className="menu"> HEDING 이력서</button>
          ) : (
            <Link to={varIsLogin ? "/MyResume" : "/Login"} className="menu">
              HEDING 이력서
            </Link>
          )}
          <ul className="depth2">
            <li>
              <Link
                to={varIsLogin ? "/MyResume" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                이력서 관리
              </Link>
            </li>
            {/*해당페이지 활성화 시 li에 클래스 Active 추가*/}
            <li>
              <Link
                to={varIsLogin ? `/MyResume/ResumeView/${0}` : "/Login"}
                state={varIsLogin ? { re_idx: 0 } : "user"}
                onClick={() => onCloseMenuUser()}
              >
                헤딩 이력서 작성
              </Link>
            </li>
          </ul>
        </li>
        : null
      }
      {siteMode === 0 ?
        <li className="depth1">
          {isMobile ? (
            <button className="menu">커리어</button>
          ) : (
            <Link to={varIsLogin ? "/MyCareer/web/0" : "/Login"} className="menu">
              커리어
            </Link>
          )}
          <ul className="depth2">
            <li>
              <Link to={varIsLogin ? "/MyCareer/web/0" : "/Login"} onClick={() => onCloseMenuUser()}>경력증명서</Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyCareer/acquaintance-reputation" : "/Login"}
                // state={{ cp_mode: 0 }}
                onClick={() => onCloseMenuUser()}
              >
                지인 평판
              </Link>
            </li>
            <li>
              <Link
                // to={varIsLogin ? "/MyCareer/career-reputation" : "/Login"}
                to={'/MyCareer/career-reputation'}
                // state={{ cp_mode: 1 }}
                onClick={() => onCloseMenuUser()}
              >
                동료 평판
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyCareer/CareerIncome" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                소득
              </Link>
            </li>
          </ul>
        </li>
        : null
      }
      {/*로그인전*/}
      <li className="depth1">
        {isMobile ? (
          <button className="menu"> 이직/구인 톡</button>
        ) : (
          <Link to={"/MyTalk"} className="menu">
            이직/구인 톡
          </Link>
        )}

        <ul className="depth2">
          <li>
            <Link to={"/MyTalk"} onClick={() => onCloseMenuUser()}>
              이직/구인 톡
            </Link>
          </li>
        </ul>
      </li>
      {/*로그인후*/}

      <li className="depth1 my">
        {isMobile ? (
          <button className="menu">My HEDING</button>
        ) : (
          <Link
            to={varIsLogin ? "/MyHeding/MyHeding1" : "/Login"}
            className="menu"
          >
            My HEDING
          </Link>
        )}


        {/* mem_mode */}
        {mem_mode === 0 ?
          <ul className="depth2">
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding1" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                정보 관리
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding2" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                채용 지원 현황
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding3" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                받은 제안 현황
              </Link>
            </li>
            <li>
              <Link onClick={() => moveChat()}>메시지</Link>
              {/* <Link
                to={varIsLogin ? "/MyHeding/MyHeding4" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                메시지
              </Link> */}
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding5" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                추천 현황
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding6" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                관심 채용 관리
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding12" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                이직/구인톡 관리
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding7" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                헤딩 머니
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding8" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                알림내역
              </Link>
            </li>
            {/* <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding9" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding13" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                공지사항
              </Link>
            </li> */}
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding9" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                헤딩 컨설팅 서비스
              </Link>
            </li>
            <li>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding10" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                HR 담당자 소개
              </Link>
            </li>
          </ul>

          : //기업(헤드헌터)

          <ul className="depth2">
            <li id={`heding_1`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding1" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                정보 관리
              </Link>
            </li>
            {mem_idx === mem_sub ?
              <li id={`heding_2`}>
                <Link
                  to={varIsLogin ? "/MyHeding/MyHeding2" : "/Login"}
                  onClick={() => onCloseMenuUser()}
                >
                  서브 계정 관리
                </Link>
              </li> : ""
            }
            <li id={`heding_3`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding3" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                제안 현황
              </Link>
            </li>
            <li id={`heding_5`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding5" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                이용권 관리
              </Link>
            </li>
            <li id={`heding_12`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding12" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                이직/구인톡 관리
              </Link>
            </li>
            <li id={`heding_6`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding6" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                헤딩 머니
              </Link>
            </li>
            <li id={`heding_7`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding7" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                알림내역
              </Link>
            </li>
            {/* <li id={`heding_8`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding8" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                FAQ
              </Link>
            </li>
            <li id={`heding_13`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding13" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                공지사항
              </Link>
            </li> */}
            <li id={`heding_9`}>
              <Link
                to={varIsLogin ? "/MyHeding/MyHeding9" : "/Login"}
                onClick={() => onCloseMenuUser()}
              >
                HR 담당자 소개
              </Link>
            </li>
          </ul>
        }

      </li>
    </ul>
  );
}

export default NavMenu;
