/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

//components
//이미지
import React, { useState, useEffect } from "react";
import iconKakao from '../../img/sub/icon_kakao.jpg';
import iconPayco from '../../img/sub/icon_payco.jpg';
import iconSamsungPass from '../../img/sub/icon_samsungpass.jpg';
import iconPass from '../../img/sub/icon_pass.jpg';
import iconNpay from '../../img/sub/icon_npay.jpg';
import btnClose from '../../img/sub/btn_close2.png';
import axios from 'axios';
import CONFIG from '../../configs/configs';
import Loading from 'components/Common/Loading';
import updateCateImg1 from '../../img/sub/update_cate1.png';
import updateCateImg2 from '../../img/sub/update_cate2.png';
import { common_select_function } from 'components/Common/Common';

import "css/Popup.css";

function PopCertify({ popupType, setPopupType, setupCareerDataList, onSelectExperience, setSaeobja, success }) {
    const mem_idx = Number(localStorage.getItem('mem_idx'));
    const [varTitle, setTitle] = useState('');

    const [varType, setType] = useState({
        certify_chk: -1,
        name: "",
        phone: "",
        BirthDate: ""
    });

    const [varChecked, setChecked] = useState({ agreement: false });
    const [varSuccess, setSuccess] = useState(false);
    const [varSuccessData, setSuccessData] = useState({
        CxId: "",
        ReqTxId: "",
        PrivateAuthType: "",
        UserName: "",
        BirthDate: "",
        UserCellphoneNumber: "",
        Token: "",
        TxId: ""
    });
    const [varSuccessData2, setSuccessData2] = useState({
        CxId: "",
        ReqTxId: "",
        PrivateAuthType: "",
        UserName: "",
        BirthDate: "",
        UserCellphoneNumber: "",
        Token: "",
        TxId: ""
    });


    const [message, setMessage] = useState('');
    const [varLink, setLink] = useState('');
    const [varLink2, setLink2] = useState('');
    const [varLink3, setLink3] = useState('');
    const [varNewIcon, setNewIcon] = useState('');
    const [varAppText, setAppText] = useState('');

    const [loadingTag, setLoadingTag] = useState(false);
    // const massage = popupType === 0 ? '건강보험 이력 데이터를 조회중입니다.' : '근로소득 데이터를 조회중입니다.';
    useEffect(async () => {

        if (varType.name === "") {
            const column = ` mem_name, mem_mobile, mem_birthday `;
            const table = ` member `;
            const where = ` mem_idx = ${Number(localStorage.getItem('mem_idx'))}`;
            const option = ` ORDER BY mem_idx desc`;
            await common_select_function(column, table, where, option).then(function (res) {

                // const List = res.reverse();
                console.log(res);
                setType({
                    ...varType,
                    name: res[0]?.mem_name,
                    phone: res[0]?.mem_mobile,
                    BirthDate: res[0]?.mem_birthday.replaceAll('-', '')
                });


            }).catch(function (err) {
                console.error(err); // Error 출력  
            });
        }

    }, [varType]);

    useEffect(() => {
        if (loadingTag) {
            if (popupType.nhis) {
                HealthInsuranceData();
            }
            if (popupType.hometax) {
                EarnedIncomeData();
            }
        }
    }, [loadingTag]);

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    const onChangeType = (e) => {
        const { name, value } = e.target;
        setType(prevState => ({ ...prevState, [name]: value }));
    }

    const onChangePopupType = (e) => {
        const { name } = e.target;
        setPopupType(prev => ({ ...prev, [name]: name === 'nhis' ? !prev.nhis : !prev.hometax }));
    }

    const onChangeChecked = (e) => {
        const { checked } = e.target;
        setChecked({ agreement: checked });
    }

    const onGetCareerData = async () => {

        if (varType.certify_chk === -1) {
            alert('간편인증을 진행할 어플을 선택해 주세요');
            return;
        } else if (varType.name === "") {
            alert('이름을 입력해 주세요.');
            return;
        } else if (varType.BirthDate === "") {
            alert('생년월일을 입력해주세요.');
            return;
        } else if (varType.phone === "") {
            alert('휴대폰번호를 입력해주세요.');
            return;
        } else if (varChecked.agreement === false) {
            alert("제3자 정보제공을 확인하고 동의 합니다.");
            return false;
        }
        /* 인증종류 - 0: 카카오톡, 1: 페이코, 2: 국민은행모바일, 3: 삼성패스, 4: 통신사Pass, 5: 신한, 6: 네이버 */
        const param = new URLSearchParams();
        param.append("PrivateAuthType", varType.certify_chk);
        param.append("UserName", varType.name);
        param.append("BirthDate", varType.BirthDate);
        param.append("UserCellphoneNumber", varType.phone);

        await axios.post(`${CONFIG.SERVER_HOST}/get_tilko1`, param).then((res) => {
            console.log(res);
            if (res.data.retvalue === 1) {

                const data = res.data.ResultData;
                setSuccessData({
                    CxId: data.CxId,
                    ReqTxId: data.ReqTxId,
                    PrivateAuthType: data.PrivateAuthType,
                    UserName: data.UserName,
                    BirthDate: data.BirthDate,
                    UserCellphoneNumber: data.UserCellphoneNumber,
                    Token: data.Token,
                    TxId: data.TxId
                });
                console.log(varType.certify_chk);
                setNewIcon(setIcon(Number(varType.certify_chk)));
                setAppText(setText(Number(varType.certify_chk)));
                setSuccess(true);

            } else if (res.data.retvalue === 0) {
                setTitle('건강보험 간편인증 실패');
                setMessage(res.data.Message);
                console.log(res.data.Link);
                // intent://kakao.talk/#Intent;package=com.kakao.talk;scheme=https;end
                // kakaotalk://
                const certify_chk = Number(varType.certify_chk);

                //안드로이드
                if (varUA.indexOf("android") > -1) {

                    console.log(certify_chk);

                    if (certify_chk === 0) {
                        setLink('intent://kakao.talk/#Intent;package=com.kakao.talk;scheme=https;end');
                    } else if (certify_chk === 1) {
                        setLink('intent://nhnent.payapp/#Intent;package=com.nhnent.payapp;scheme=https;end');
                    } else if (certify_chk === 3) {
                        setLink('intent://samsung.android.spay/#Intent;package=com.samsung.android.spay;scheme=https;end');
                    } else if (certify_chk === 4) {
                        setLink('intent://sktelecom.tauth/#Intent;package=com.sktelecom.tauth;scheme=https;end');
                        setLink2('intent://kt.ktauth/#Intent;package=com.kt.ktauth;scheme=https;end');
                        setLink3('intent://lguplus.smartotp/#Intent;package=com.lguplus.smartotp;scheme=https;end');
                    } else if (certify_chk === 6) {
                        setLink('intent://nhn.android.search/#Intent;package=com.nhn.android.search;scheme=https;end');
                    }


                } else if (
                    varUA.indexOf("iphone") > -1 ||
                    varUA.indexOf("ipad") > -1 ||
                    varUA.indexOf("ipod") > -1
                ) {
                    if (certify_chk === 0) {
                        setLink('kakaotalk://');
                    } else if (certify_chk === 4) {
                        setLink('tauthlink://');
                        setLink2('ktauthexternalcall://');
                        setLink3('upluscorporation://');
                    }
                }

                //Intent://details#Intent;scheme=market;package=com.kakao.talk;end
                openReturnMsgLayer();
                // alert(res.data.Message);
            }
        });
    }

    const setIcon = (certifyNum) => {
        console.log(certifyNum);
        /* 인증종류 - 0: 카카오톡, 1: 페이코, 2: 국민은행모바일, 3: 삼성패스, 4: 통신사Pass, 5: 신한, 6: 네이버 */
        if (certifyNum === 0) {
            return iconKakao;
        } else if (certifyNum === 1) {
            return iconPayco;
        } else if (certifyNum === 3) {
            return iconSamsungPass;
        } else if (certifyNum === 4) {
            return iconPass;
        } else if (certifyNum === 6) {
            return iconNpay;
        }
    };

    const setText = (certifyNum) => {
        console.log(certifyNum);
        /* 인증종류 - 0: 카카오톡, 1: 페이코, 2: 국민은행모바일, 3: 삼성패스, 4: 통신사Pass, 5: 신한, 6: 네이버 */
        if (certifyNum === 0) {
            return '카카오톡';
        } else if (certifyNum === 1) {
            return '페이코';
        } else if (certifyNum === 3) {
            return '삼성패스';
        } else if (certifyNum === 4) {
            return 'pass(통신사)';
        } else if (certifyNum === 6) {
            return '네이버';
        }
    };


    const HealthInsuranceData = async () => {
        const param = new URLSearchParams();
        param.append("CxId", varSuccessData.CxId);
        param.append("ReqTxId", varSuccessData.ReqTxId);
        param.append("PrivateAuthType", varSuccessData.PrivateAuthType);
        param.append("UserName", varSuccessData.UserName);
        param.append("BirthDate", varSuccessData.BirthDate);
        param.append("UserCellphoneNumber", varSuccessData.UserCellphoneNumber);
        param.append("Token", varSuccessData.Token);
        param.append("TxId", varSuccessData.TxId);
        param.append("mem_idx", mem_idx);

        axios.post(`${CONFIG.SERVER_HOST}/get_tilko3`, param).then((res) => {
            if (res.data.retvalue === 1) {
                const array = res.data.ResultList;
                setupCareerDataList(array);
                setSaeobja(res.data?.SaeobjaMyeongching);
                if (!popupType.hometax) {
                    setLoadingTag(false);
                    onSelectExperience(false);
                }
            } else {
                setLoadingTag(popupType.hometax);
                setTitle('건강보험 정보조회 실패');
                openReturnMsgLayer();
                if (res.data.retvalue === 0) {
                    setMessage(res.data.Message);
                } else {
                    setMessage(res.data.Message);
                }
            }
        });
    }

    const onGetCareerData2 = async () => {

        if (varType.certify_chk === -1) {
            alert('간편인증을 진행할 어플을 선택해 주세요');
            return;
        } else if (varType.name === "") {
            alert('이름을 입력해 주세요.');
            return;
        } else if (varType.BirthDate === "") {
            alert('생년월일을 입력해주세요.');
            return;
        } else if (varType.phone === "") {
            alert('휴대폰번호를 입력해주세요.');
            return;
        } else if (varChecked.agreement === false) {
            alert("제3자 정보제공을 확인하고 동의 합니다.");
            return false;
        }
        /* 인증종류 - 0: 카카오톡, 1: 페이코, 2: 국민은행모바일, 3: 삼성패스, 4: 통신사Pass, 5: 신한, 6: 네이버 */
        const param = new URLSearchParams();
        param.append("PrivateAuthType", varType.certify_chk);
        param.append("UserName", varType.name);
        param.append("BirthDate", varType.BirthDate);
        param.append("UserCellphoneNumber", varType.phone);

        axios.post(`${CONFIG.SERVER_HOST}/get_tilko4`, param).then((res) => {
            console.log(res);
            if (res.data.retvalue === 1) {

                const data = res.data.ResultData;
                setSuccessData2({
                    CxId: data.CxId,
                    ReqTxId: data.ReqTxId,
                    PrivateAuthType: data.PrivateAuthType,
                    UserName: data.UserName,
                    BirthDate: data.BirthDate,
                    UserCellphoneNumber: data.UserCellphoneNumber,
                    Token: data.Token,
                    TxId: data.TxId
                });
                setNewIcon(setIcon(Number(varType.certify_chk)));
                setAppText(setText(Number(varType.certify_chk)));
                setSuccess(true);
            } else if (res.data.retvalue === 0) {
                // setMessage(res.data.Message.replaceAll('|', '\n'));
                setTitle('홈택스 간편인증 실패');
                setMessage(res.data.Message);
                openReturnMsgLayer();
            }
        });
    }

    const EarnedIncomeData = async () => {
        const param = new URLSearchParams();
        param.append("CxId", varSuccessData2.CxId);
        param.append("ReqTxId", varSuccessData2.ReqTxId);
        param.append("PrivateAuthType", varSuccessData2.PrivateAuthType);
        param.append("UserName", varSuccessData2.UserName);
        param.append("BirthDate", varSuccessData2.BirthDate);
        param.append("UserCellphoneNumber", varSuccessData2.UserCellphoneNumber);
        param.append("Token", varSuccessData2.Token);
        param.append("TxId", varSuccessData2.TxId);
        param.append("mem_idx", mem_idx);
        // param.append("varLastYear", moment(varLastYear).format('YYYY'));

        axios.post(`${CONFIG.SERVER_HOST}/get_tilko5`, param).then((res) => {
            //ResultList
            if (res.data.retvalue === 1) {
                setPopupType({
                    nhis: false,
                    hometax: false
                });
                setLoadingTag(false);
                onSelectExperience(false);
                if (!popupType.nhis) {
                    alert('근로소득을 불러오는데 성공했습니다.');
                    success();
                }

            } else {
                setTitle('홈택스 정보조회 실패');
                setMessage(res.data.Message);
                openReturnMsgLayer();
                setLoadingTag(false);
            }
        });
    }

    // 리턴 메세지 레이어 팝업
    const openReturnMsgLayer = (e) => {
        setReturnMsgLayer(true);
    }
    const [returnMsgLayer, setReturnMsgLayer] = useState(false);
    const closeReturnMsgLayer = () => {
        setMessage('');
        setReturnMsgLayer(false);
    }

    const SimpleAuthentication = () => {
        if (popupType.nhis) {
            onGetCareerData();
        }
        if (popupType.hometax) {
            onGetCareerData2();
        }
    }

    const [LoadText, setLoadText] = useState(popupType === 0 ? '건강보험 이력 데이터를 조회중입니다.' : '근로소득 데이터를 조회중입니다.');



    //다운로드 로딩화면 문구 세팅 시작
    let count = 0;
    useEffect(() => {
        if (loadingTag) {
            const tick = setInterval(() => {
                if (loadingTag === true) {
                    if (count === CONFIG.QueryingTextArray.length) {
                        count = 0;
                    }
                    setLoadText(CONFIG.QueryingTextArray[count]);
                    count++;
                }
            }, 2000);

            return () => clearInterval(tick);
        } else {
            count = 0;
        }
        return undefined;
    }, [loadingTag]);
    //다운로드 로딩화면 문구 세팅 끝
    return (loadingTag ? <Loading text={LoadText} /> : <>
        <div className="Popup Certify pop_career_update" style={{ display: 'block', overflow: 'scroll !important' }}>
            <div className="Wrap">
                <div className="Box">
                    <div className="Top">
                        <h3 className="Title">간편 인증 요청</h3>
                        <button className="btn_close" onClick={() => onSelectExperience(false)}><img src={btnClose} alt="" /></button>

                        {/* <h3 className="Title">
                        <ul className="Top_ul">
                            <li className="Top_li">
                                <input type="checkbox" name="nhis" id="TopRadio1" className="TopRadio chk" onChange={onChangePopupType} value={0} checked={popupType.nhis} />
                                <label for="TopRadio1" className="Top_li_label" ><span className="icon">건강보험</span></label>
                            </li>
                            <li className="Top_li">
                                <input type="checkbox" name="hometax" id="TopRadio2" className="TopRadio chk" onChange={onChangePopupType} value={1} checked={popupType.hometax} />
                                <label for="TopRadio2" className="Top_li_label" ><span className="icon">근로소득</span></label>
                            </li>

                        </ul>

                    </h3> */}
                    </div>
                    <div className="PopContents">
                        {
                            varSuccess === false ?
                                <>
                                    <div className="Section">
                                        <ul className="selectCertify">
                                            <li>
                                                <div className="termschkwon">
                                                    <input type="radio" name="certify_chk" id="certify_chk1" className="chk1" onChange={onChangeType} value={0} /><i></i><label htmlFor="certify_chk1"><span className="icon"><img src={iconKakao} alt="" /></span></label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="termschkwon">
                                                    <input type="radio" name="certify_chk" id="certify_chk2" className="chk1" onChange={onChangeType} value={1} /><i></i><label htmlFor="certify_chk2"><span className="icon"><img src={iconPayco} alt="" /></span></label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="termschkwon">
                                                    <input type="radio" name="certify_chk" id="certify_chk3" className="chk1" onChange={onChangeType} value={3} /><i></i><label htmlFor="certify_chk3"><span className="icon"><img src={iconSamsungPass} alt="" /></span></label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="termschkwon">
                                                    <input type="radio" name="certify_chk" id="certify_chk4" className="chk1" onChange={onChangeType} value={4} /><i></i><label htmlFor="certify_chk4"><span className="icon"><img src={iconPass} alt="" /></span></label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="termschkwon">
                                                    <input type="radio" name="certify_chk" id="certify_chk5" className="chk1" onChange={onChangeType} value={6} /><i></i><label htmlFor="certify_chk5"><span className="icon"><img src={iconNpay} alt="" /></span></label>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="BoardWrite">
                                            <ul className="Write">
                                                <li>
                                                    <div className="FieldTitle">이름</div>
                                                    <div className="FieldInfo">
                                                        <ul className="InputWrap">
                                                            <li><input type="text" className="color_readOnly" name='name' readOnly value={varType.name} onChange={onChangeType} /></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="FieldTitle">생년월일</div>
                                                    <div className="FieldInfo">
                                                        <input type="text" name='BirthDate' className="color_readOnly" readOnly value={varType.BirthDate} onChange={onChangeType} placeholder={'19000101'} />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="FieldTitle">휴대폰번호</div>
                                                    <div className="FieldInfo">
                                                        <ul className="InputWrap">
                                                            <li><input type="text" name='phone' value={varType.phone} onChange={onChangeType} /></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="FieldInfo">
                                                        <div className="termschk">
                                                            <input type="checkbox" id="chk" className="chk" name='agreement' value={varChecked.agreement} onChange={onChangeChecked} /><label htmlFor="chk">제3자 정보제공을 확인하고 동의합니다.</label>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="update_choice_continaer">
                                        <div className="update_cate_area"><input type="checkbox" name="nhis" id="TopRadio1" className="TopRadio chk" onChange={onChangePopupType} value={0} checked={popupType.nhis} />
                                            <label htmlFor="TopRadio1" className="Top_li_label" ><span className="icon"><img src={updateCateImg1} alt="국민건강보험" />건강보험</span></label></div>
                                        <div className="update_cate_area"><input type="checkbox" name="hometax" id="TopRadio2" className="TopRadio chk" onChange={onChangePopupType} value={1} checked={popupType.hometax} />
                                            <label htmlFor="TopRadio2" className="Top_li_label" ><span className="icon"><img src={updateCateImg2} alt="국세청" />근로소득</span></label></div>
                                    </div>
                                </>
                                :
                                <div className="Section">
                                    <div style={{ justifyContent: 'center' }}>
                                        <div>
                                            <img src={varNewIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="BoardWrite">
                                        <ul className="Write">
                                            <li>
                                                <div className="FieldTitle">1. {varAppText} 앱에서 인증을 진행해주세요.</div>
                                            </li>
                                            <li>
                                                <div className="FieldTitle">2. 그 다음 아래 "정보불러오기"를 선택해주세요.</div>
                                            </li>
                                            <li>
                                                <div className="FieldTitle warring">※ 건강보험, 근로소득 둘다 체크 시 각각 인증을 진행해주세요</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                        }

                        <div className="ButtonBottom">
                            {varSuccess === false ?
                                <button onClick={() => SimpleAuthentication()} className="btn">간편 인증 요청하기</button>
                                :
                                <button onClick={() => setLoadingTag(true)} className="btn">정보불러오기</button>
                            }
                        </div>
                    </div>
                    <div className="PopBottom">
                        <button onClick={() => onSelectExperience(false)} className="Button">닫기 X</button>
                    </div>
                </div>

                {/* div 레이어 팝업 KGY */}
                {returnMsgLayer &&
                    <div className="popup return-msg-box">
                        <article className="popup_wrap popup01">
                            <section className="title_wrap"><h1>{varTitle}</h1></section>
                            <div className="content_wrap">
                                {/* <a target="_blank" href={varLink}>SKT 바로가기</a> */}
                                {varType.certify_chk === '4' ?
                                    <>
                                        {varLink !== '' ? <a href={varLink}>SKT 바로가기</a> : ''}
                                        {varLink2 !== '' ? <a href={varLink2}>KT 바로가기</a> : ''}
                                        {varLink3 !== '' ? <a href={varLink3}>LG 바로가기</a> : ''}
                                    </>
                                    :
                                    <>
                                        {varLink !== '' ? <a href={varLink}>어플 바로가기</a> : ''}
                                    </>
                                }
                                <div className="ReturnMsg msg-box" dangerouslySetInnerHTML={{ __html: message }}></div>
                            </div>
                            <div className="button_wrap">
                                <div className="close_button_wrap">
                                    <button onClick={() => closeReturnMsgLayer()} className="Button">닫기 X</button>
                                </div>
                            </div>
                        </article>
                    </div>}
                {/* div 레이어 팝업 KGY */}
            </div>
        </div>
        {message && <div>{message}</div>}
    </>);
}

export default PopCertify;
