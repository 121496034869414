import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import MyBookmarkTab from "components/Tab/MyBookmarkTab";
import CompanyBookmarkList from "components/Company/CompanyBookmarkList";
import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding4({ getSubItem }) {
  useEffect(() => {
    getSubItem(4);
    resetNavSubMenuForHeding(4);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const [varTabs, setTabs] = useState(0);
  const [varEmployList, setEmployList] = useState([]);
  useEffect(() => {
    setEmployList([]);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTabs]);

  useEffect(() => {
    console.log(location);
    if (location !== null && location.state !== null) {
      const pmode = location.state.pmode;
      setTabs(pmode);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  console.log(varTabs);
  return (
    <div className="MyContents SectionBox p_mybookmark">
      <MyBookmarkTab varTabs={varTabs} setTabs={setTabs} />
      <CompanyBookmarkList
        varTabs={varTabs}
        varEmployList={varEmployList}
        setEmployList={setEmployList}
      />
    </div>
  );
}

export default MyHeding4;
