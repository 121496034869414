/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';
import arrImage from '../../img/arr.png';
import iconhome from '../../img/icon-home.png';
import CONFIG from '../../../configs/configs';
import axios from 'axios';
import './UsingPass.css';

function PaymentDetail() {
  const { or_idx } = useParams();
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');

  //심사상태--------------------------------------------------------------------------------------------
  const [varPaymentDetailIdx, setPaymentDetailIdx] = useState(or_idx); //eslint-disable-line no-unused-vars
  const [varPaymentDetailStatus, setVarPaymentDetailStatus] = useState(0);
  const [paymentDetailValues, setPaymentDetailValues] = useState([]);
  const [varOrderStatus, setVarOrderStatus] = useState([]);

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  //최초에 실행
  useEffect(() => {
    //결제상태변수값
    axios.post(`${CONFIG.SERVER_HOST}/admin_variable_order_status`).then((res) => {
      setVarOrderStatus(res.data);
    });

    if (or_idx > 0) {
      // console.log('상세@@@@@', or_idx);
      const params = new URLSearchParams();
      params.append('or_idx', or_idx);
      axios.post(`${CONFIG.SERVER_HOST}/payment_detail`, params).then((res) => {
        console.log(res.data);
        console.log(res.data?.results);
        if (res.data?.retvalue === 1) {

          const data = res.data?.results.map((data, i) => (
            {
              or_idx: data?.or_idx,
              mem_id: data?.mem_id,
              mem_name: data?.mem_name,
              mcm_name: data?.mcm_name,
              mem_mobile: data?.mem_mobile?.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3"),
              vo_name: data?.vo_name,
              or_amount: data?.or_amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' 원',
              ops_name: data?.ops_name,
              ops_idx: data?.ops_idx,
              or_account: data.bank_account === null || data?.bank_name === null ? "-" : data?.bank_account + ' (' + data?.bank_name + ')',
              or_sender: data.or_sender === "" || data.or_sender === null ? "-" : data?.or_sender,
              vrs_name: data?.vrs_name,
              or_receipt: data?.or_receipt,
              or_deduction_val: data?.or_deduction_val,
              orr_name: data?.orr_name,
              ors_name: data?.ors_name,
              or_status: data?.or_status,
              or_pdate: data?.or_pdate === null ? "-" : data.or_pdate?.substr(0, 10)
            }
          ))
          console.log(data);
          setPaymentDetailValues(data);
          setVarPaymentDetailStatus(data[0].or_status);
        }
      });
    }
  }, []);

  //저장하기
  const updatePaymentDetail = () => {
    const params = new URLSearchParams();
    params.append('or_idx', varPaymentDetailIdx);
    params.append('or_status', varPaymentDetailStatus);
    axios.post(`${CONFIG.SERVER_HOST}/payment_update`, params).then((res) => {
      if (res.data?.retvalue === 1) document.location.href = "/admin/paymentlist";
      else window.alert('정보 저장중에 오류가 발생했습니다.');
    });
  }

  //결제상태변경
  const handlePaymentDetailStatus = (e) => {
    setVarPaymentDetailStatus(e.target.value);
  };

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="using_pass" />
      <Menu menuType="using_pass" menu="paymentdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">결제관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />이용권 구매관리<img src={arrImage} alt="" />결제관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <thead>
              </thead>
              <tbody>
                {paymentDetailValues[0]?.mem_id ?
                  <>
                    <tr>
                      <th>아이디</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.mem_id}</td>
                    </tr>
                    <tr>
                      <th>담당자명</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.mem_name}</td>
                    </tr>
                    <tr>
                      <th>회사명</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.mcm_name}</td>
                    </tr>
                    <tr>
                      <th>휴대폰</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.mem_mobile}</td>
                    </tr>
                    <tr>
                      <th>이용권 종류</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.vo_name}</td>
                    </tr>
                    <tr>
                      <th>결제금액</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.or_amount}</td>
                    </tr>
                    <tr>
                      <th>결제방식</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.ops_name}</td>
                    </tr>
                    {paymentDetailValues[0]?.ops_idx === 0 && (
                      <>
                        <tr>
                          <th>입금계좌</th>
                          <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.or_account}</td>
                        </tr>
                        <tr>
                          <th>입금자명</th>
                          <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.or_sender}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <th>현금영수증</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.vrs_name}</td>
                    </tr>
                    {paymentDetailValues[0]?.or_receipt > 0 && (
                      <>
                        <tr>
                          <th>소득공제방식</th>
                          <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.orr_name}</td>
                        </tr>
                        <tr>
                          <th>소득공제입력값</th>
                          <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.or_deduction_val}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <th>결제상태</th>
                      <td>
                        <select className="short_select" onChange={handlePaymentDetailStatus} value={varPaymentDetailStatus}>
                          {varOrderStatus.length > 0 ? varOrderStatus.slice(0, 4).map((data, i) => (
                            <option key={i} value={data?.ors_idx}>{data?.ors_name}</option>
                          )) : null}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>결제일자</th>
                      <td className="write-input">{or_idx === "0" ? "" : paymentDetailValues[0]?.or_pdate}</td>
                    </tr>
                  </>
                  : ""}
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/paymentlist" className="btn line">목록</Link>
              <button onClick={updatePaymentDetail} className="btn">등록</button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaymentDetail;