import React from "react";

//이미지
import icon_repu_pop_write from "img/sub/repu_pop_write.png";
// import { Link } from 'react-router-dom';

const PopAcqRepuStart = ({
  writer,
  setWriter,
  setEnablePop,
  setAnonymousPop,
}) => {
  //이름 입력
  const onChange = (e) => {
    setWriter(e.target.value);
  };

  //시작하기 버튼
  const btnStart = (e) => {
    if (writer.length < 2) {
      alert("이름을 입력하세요");
      return;
    }
    setEnablePop(false);
  };
  //익명 시작하기 버튼
  const AnonymousBtnStart = (e) => {
    if (writer.length < 2) {
      alert("이름을 입력하세요");
      return;
    }
    setEnablePop(false);
	setAnonymousPop(true);
  };

  return (
    <div className={"popup active"}>
      <div className="popup_wrap popup01 pop_repu">
        <section className="title_wrap">
          <h1>'친구가 보는 나' 시작하기</h1>
        </section>
        <section className="content_wrap">
          <article className="notice_area">
            <div className="icon">
              <img src={icon_repu_pop_write} alt="icon_pw" />
            </div>
            <p className="title_field">
              HEDING 지인 평판을 진행하기 위해
              <br />
              이름을 알려주세요.
            </p>
          </article>
          <div className="field">
            <input type="text" onChange={onChange} />
          </div>
        </section>
        <section className="button_wrap">
          <div className="big_button_wrap two">
            {/* <Link to={"/main"} className="btn white">취소</Link> */}
            <button className="btn" onClick={AnonymousBtnStart}>
              익명으로 시작하기
            </button>
            <button className="btn" onClick={btnStart}>
              시작하기
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PopAcqRepuStart;
