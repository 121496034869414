import React, { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import CONFIG from 'configs/configs'

import MyMoneyTop from "components/HedingMoney/MyMoneyTop";
import MyMoneyList from "components/HedingMoney/MyMoneyList";
import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding6({ getSubItem }) {
  const user_idx = localStorage.getItem('mem_idx');
  const user_id = localStorage.getItem('mem_id');
  const [varHMoneyBalance, setHMoneyBalance] = useState(0);
  //서브회원번호
  const [varSubMemberId, setSubMemberId] = useState(user_id);
  const [varSubMemberIndex, setSubMemberIndex] = useState(user_idx);

  useEffect(()=>{
    const params = new URLSearchParams();
    params.append("user_idx", varSubMemberIndex);
    axios.post(`${CONFIG.SERVER_HOST}/_get_hedingmoney_balance2`, params).then((res) => {
      if (res.data.retvalue === 1) {
        if (res.data.results?.length > 0) {
          const data = res.data.results[0];
          setHMoneyBalance(data.mh_paid);
          setSubMemberId(data.mem_id)
        }
      }
    });
  },[varSubMemberIndex])


  useEffect(() => {
    getSubItem(6);
    resetNavSubMenuForHeding(6); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_money">
      <MyMoneyTop varHMoneyBalance={varHMoneyBalance} setSubMemberIndex={setSubMemberIndex} varSubMemberIndex={varSubMemberIndex} varSubMemberId={varSubMemberId} user_idx={user_idx}/>
      <MyMoneyList varSubMemberIndex={varSubMemberIndex} />
    </div>
  );
}

export default MyHeding6;
