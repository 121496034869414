import React from "react";
import JoinNormalOKC from "../../components/Member/JoinNormalOKC";

const JoinNormalOK = () => {
  return (
    <>
      <main id="sub_wrap" className="center_wrap_member page_join_ok">
        <h2 className="sub_content_title">회원가입완료</h2>
        <section className="box member_form">
          <JoinNormalOKC />
        </section>
      </main>
    </>
  );
};

export default JoinNormalOK;
