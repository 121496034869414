import React, { useEffect, useState } from "react";
//css
//components
import axios from 'axios';
import CONFIG from 'configs/configs';

function CompanyRecommendTop() {
    const user_idx = localStorage.getItem('mem_idx');
    const mcm_idx = localStorage.getItem('mcm_idx');
    const mem_sub = localStorage.getItem('mem_sub');
    //카운팅 저장 객체
    const [varRecommendData, setRecommendData] = useState({
        send_recommend_num: 0,  //제안 발송 수
        send_recommend_deny: 0, //제안 거절수
        send_recommend_apply: 0 //제안 승락한 수
    });

    //제안현황 카운팅 
    useEffect(() => {
        const params = new URLSearchParams();
        params.append("mcm_idx", mcm_idx);
        params.append("mem_idx", user_idx);
        params.append("mem_sub", mem_sub);
        axios.post(`${CONFIG.SERVER_HOST}/_get_company_suggestion`, params).then((res) => {
            // console.log(res.data);
            if (res.data.retvalue === 1) {
                console.log(res.data);
                setRecommendData({
                    send_recommend_num: res.data.tcount,
                    send_recommend_deny: res.data.scount,
                    send_recommend_apply: res.data.acount
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="box box_content">
            <h3 className="my_title">제안 현황</h3>
            <ul className="state_wrap">
                <li className="state_box" style={{ width: "49%" }}>
                    <h4 className="title">제안 발송 수</h4>
                    <div className="num">{varRecommendData?.send_recommend_num}</div>
                </li>
                <li className="state_box" style={{ width: "49%" }}>
                    <h4 className="title">제안 수락 수</h4>
                    <div className="num">{varRecommendData?.send_recommend_apply}</div>
                </li>
                <li className="state_box" style={{ width: "49%" }}>
                    <h4 className="title">제안 거절 수</h4>
                    <div className="num">{varRecommendData?.send_recommend_deny}</div>
                </li>
            </ul>
        </section>
    );
}

export default CompanyRecommendTop;
