/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';

//components
import PopRefund from "./PopRefund";
import CompanyVoucherSearch from "./CompanyVoucherSearch";
import Paging from '../Layout/Paging'
import { addThousandPoint, ChangeDateString, remainDays } from '../Common/Common'

function CompanyVoucherList({ varVoucherHaving }) {
  const mcm_idx = Number(localStorage.getItem('mcm_idx'));
  const mem_idx = Number(localStorage.getItem('mem_idx'));
  const mem_sub = Number(localStorage.getItem('mem_sub'));

  //팝업창
  const [varShowModal,setShowModal] = useState(false);
  //환불할 주문고유번호
  const [varOrderIndex, setOrderIndex] = useState(0);

  //페이지
  const [varTotalCount,setTotalCount] = useState(0);
  const [varPageSize] = useState(10);
  const [varPageNum,setPageNum] = useState(1);

  //검색일자
  const [varBuySearchOptions,setBuySearchOptions] = useState({
      sdate:null,
      edate:null
  });

  //표시목록 - 이용권구매기록
  const [varPageVoucherList,setPageVoucherList] = useState([]);

  //서버로부터 이용권구매기록 읽어오기
  const getVoucherPaymentList = async() => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      params.append("pageSize", varPageSize);
      params.append("pageNum", varPageNum);
      params.append('sdate', varBuySearchOptions.sdate === '' || varBuySearchOptions.sdate === null ? '' : varBuySearchOptions.sdate);
      params.append('edate', varBuySearchOptions.edate === '' || varBuySearchOptions.edate === null ? '' :varBuySearchOptions.edate);
      await axios.post(`${CONFIG.SERVER_HOST}/voucherPaymentList`, params).then((res) => {
        if (res.data?.retvalue === 1) {
          let datas = res.data.results;
          //전체 구매 기록
          setTotalCount(res.data.count);
          //환불요청 가능성 체크
          let expdates = remainDays(varVoucherHaving.mcc_edate);
          let sendings = varVoucherHaving.mcc_sending;
          let canRefund = false;
          const newData = datas?.map((el,index)=>{
            canRefund = false;
            if(varPageNum===1 && el.or_status===1 && el.vo_sending <= sendings/* && el.vo_terms <= expdates*/) {
              el.or_status = 2;
              canRefund = true;
              sendings = sendings - el.vo_sending;
              expdates = expdates - el.vo_terms;
            }
            const newObject = Object.assign(el,{canRefund:canRefund});
            return newObject;
          });
          console.log(newData);
          setPageVoucherList(newData);  //구매목록
        } else {
          alert(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVoucherPaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPageNum, varVoucherHaving]);

  //검색하기
  const onSearchHandler =() =>{
    setPageNum(1);
    setPageVoucherList([]);
    getVoucherPaymentList();
  }

  //환불팝업창 열기
  const onRefundMoneyHandler = (or_idx) => {
    console.log('onRefundMoneyHandler',or_idx);
    setOrderIndex(or_idx);
    setShowModal(true);
  }

  return (
      <>
        <CompanyVoucherSearch varBuySearchOptions={varBuySearchOptions} setBuySearchOptions={setBuySearchOptions} onSearchHandler={onSearchHandler}/>
        <section className="support_list_area">
            <ul className="table table_list01">
                <li className="tr">
                    <p className="th row_type">이용권 종류</p>
                    <p className="th row_date">구매일자</p>
                    <p className="th row_price" >가격</p>
                    <p className="th row_payment">결제방식</p>
                    <p className="th row_state">상태</p>
                    {mem_idx === mem_sub ? <p className="th row_return">환불요청</p> : ""}
                    {/* <p className="th row_state">환불요청</p> */}
                </li>
                {varTotalCount !== 0 ? varPageVoucherList?.map((data,index) => (
                <li key={'buyvoucher_'+index} className="tr">
                    <p className="td row_type">{data?.vo_name}</p>
                    <p className={"td row_date " + (data?.or_pdate===null?"non_date":"-")}>{(data?.or_pdate===null?"":<span className="m_th">구매일자 :&nbsp;</span>)}{ChangeDateString(data?.or_pdate===null?data?.or_rdate:data?.or_pdate)}</p>
                    <p className="td row_price" ><span className="m_th">가격 :&nbsp;</span>{addThousandPoint(data?.or_amount)}원</p>
                    <p className="td row_payment">{data?.or_pay_method === 0 ? "무통장 입금" : "신용카드"}</p>
                    <div className={"td row_state " + (data?.or_status === "사용중" ? "txt_blue" : "")}>
                    {data?.or_status === 0 ? "결제대기"
                    :data?.or_status === 1 ? "결제완료"
                    :data?.or_status === 2 ? "사용중"
                    :data?.or_status === 3 ? "환불요청"
                    :data?.or_status === 4 ? "환불완료"
                    :data?.or_status === -2 ? "결제취소"
                    : "사용완료"
                    }
                    </div>
                    {mem_idx === mem_sub ?  (<div className={"td row_return " + (data?.or_status === 1 ? "txt_blue" : "")}>
                    {data?.or_status === 2 ? <>{data?.canRefund?<button className="button" onClick={()=>onRefundMoneyHandler(data?.or_idx)}>환불요청</button>:<></>}</>:<></>}
                    </div> ) : ""}
                </li>
                ))
                :
                <li className="tr">
                  <p className="td no_data" >이용권 결제내역이없습니다</p>
                </li>
              }
            </ul>
        </section>
        <Paging totalCount={varTotalCount} pageSize={varPageSize} curPage={varPageNum} setPage={setPageNum} />
        {varShowModal && (<PopRefund varOrderIndex={varOrderIndex} setShowModal={setShowModal} 
                        varPageVoucherList={varPageVoucherList} setPageVoucherList={setPageVoucherList} />)}
      </>
  );
}

export default CompanyVoucherList;
