/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import CONFIG from "../../configs/configs";
import Postcode from '../Member/Postcode';
import deleteImage01 from '../../img/sub/btn_delete01.png';
import CustomDatePicker from "components/Common/CustomDatePicker";
import PopCompanySelection from 'components/PopUp/PopCompanySelection';

import { ChangeSearchingDateFormat, ChangeMoneyUnit, escapeHtml, unescapeHtml, isNumeric } from "../Common/Common";


//공고등록
const AnnouncementWriteC = () => {
  const navigator = useNavigate();
  const photoInput = useRef();
  //로그인정보 읽기
  let mem_idx = localStorage.getItem("mem_idx");
  let mcm_idx = localStorage.getItem("mcm_idx");
  let mem_mode = Number(localStorage.getItem("mem_mode"));
  let mem_hunter_type = Number(localStorage.getItem("mem_hunter_type"));
  let mem_name = localStorage.getItem("mem_name"); // 공고 등록자 이름

  // 헤드헌터 - 써치폼 확인 => 헤드헌터 확인(mem_mode =2, mem_hunter_type = 1(일반),2(프리미엄))

  let SearchForm = 0;
  if (mem_mode === 2 && mem_hunter_type >= 1) {
    SearchForm = 1;
  }
  // 지원기간 기본값 (오늘날짜)
  const today = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0];
  //개인정보
  const [varUserData, setUserData] = useState({});
  //기업정보
  const [varCompanyData, setCompanyData] = useState({});
  //채용공고 변수값 - 초기화
  const AnnounceBasicInfo = {
    em_idx: 0,
    mcm_idx: mcm_idx,
    hsm_idx: 0,
    em_title: "",
    em_places: 0,
    em_careers: 0,
    em_career_year: "",
    em_career_end: "",
    em_task: [],
    em_tasks: 0,
    em_tasks_detail: 0,
    // em_position: 0,
    em_type: 1,
    em_reward_recommend: 0,
    em_reward_applicant: 0,
    em_reward_total: 0,
    // em_reward_payment: 0,
    em_headhunter: 0,
    emh_reward_recommend: 0,
    emh_reward_applicant: 0,
    emh_reward_total: 0,
    em_sdate: today,
    em_edate: null,
    em_rdate: null,
    em_mem_idx: mem_idx,
    em_status: 1,
    em_zipcode: "",
    em_address1: "",
    em_address2: "",
    em_location: "",
    em_intro: "",
    em_main_task: "",
    em_qualifications: "",
    em_preference: "",
    em_welfare: "",
    em_others: "",
    com_name: "",
    com_homepage: "",
    em_deadline: 1,
    em_mem_name: mem_name, // 등록자 이름
    em_ufile: "",
  };

  //채용정보
  const [varAnnouncement, setAnnouncement] = useState(AnnounceBasicInfo);
  // console.log("================== ", varAnnouncement)
  //기업목록
  const [varCompanyList, setCompanyList] = useState([]);
  //기업정보불러오기
  const [varCallCompanyInfo, setCallCompanyInfo] = useState(false);

  const [curUploadFile, setUploadFile] = useState("");
  const [logoFile, setLogoFile] = useState("");

  //선택을 위한 변수값
  const [varRewardEmploy, setRewardEmploy] = useState([]);
  const [varRewardRecommend, setRewardRecommend] = useState([]);
  const [varWorkCategory, setWorkCategory] = useState([]);
  const [varWorkCategoryDetail, setWorkCategoryDetail] = useState([]);
  const [varWorkCategoryShown, setWorkCategoryShown] = useState([]);
  // const [varWorkPosition, setWorkPosition] = useState([]);
  const [varWorkRegion, setWorkRegion] = useState([]);
  // const [varRewardPayment, setRewardPayment] = useState([]);
  const [varEmtasks, setVarEmtask] = useState({
    value: '',
    text: '',
  });

  const [varEmtasksDetail, setVarEmtasksDetail] = useState({
    value: '',
    text: '',
  });

  const [varEmtasksDetailList, setVarEmtasksDetailList] = useState([]);

  //팝업창 조정 - true:팝업창 열림
  const [varPopupSearchCompany, setPopupSearchCompany] = useState(false);
  const [varIsOpenPost, setIsOpenPost] = useState(false);

  //선택된 기업 고유번호 - 써치폼의 경우 사용
  const [varSelectedCompanyIndex, setSelectedCompanyIndex] = useState(Number(mcm_idx));

  //지원기간 일자

  const [varStartDate, setStartDate] = useState(new Date());
  const [varEndDate, setEndDate] = useState(null);

  //기업 - 검색문자
  const [varSearchString, setSearchString] = useState("");
  //검색된 기업정보
  const [varSearchingCompanyData, setSearchingCompanyData] = useState([]);

  const [varInputs2, setInputs2] = useState({
    taxId: '',
    comName: ''
  });

  // 마우스 오버
  // const [mouseOver, setMouseOver] = useState("");

  const [recrutype4Ck, setRecrutype4Ck] = useState(false); // 헤드 헌터 전용관 권한 체크.

  //공고등록작업을 위한 정보들 읽어오기
  useEffect(async () => {
    try {
      const params = new URLSearchParams();
      params.append("mem_idx", mem_idx);
      params.append("mcm_idx", mcm_idx)
      await axios.post(`${CONFIG.SERVER_HOST}/_get_announcement_information`, params).then((res) => {
        if (res.data.retvalue === 1) {
          //개인정보
          const memberInfos = res.data.memberInfo;

          if (memberInfos.length > 0) {
            setUserData(memberInfos[0]);
          }
          //기업정보
          const companyEmploy = res.data.companyEmploy;
          const resCompanyInfo = res.data.companyinfo;
          if (companyEmploy.length > 0) {
            //가업목록
            setCompanyList(companyEmploy);
            // if (SearchForm === 0) {
            //수정하지 말것!!!!!!!!
            const comInfo = companyEmploy.filter(element => element.mcm_idx === varSelectedCompanyIndex);
            //선택한 기업의 정보를 기본값으로 설정
            if (comInfo.length > 0) {
              setCompanyData(comInfo[0]); //기본 기업정보로 저장
              //채용공고 정보 초기화
              let newAnnouncement = {}
              if (SearchForm === 0) {
                newAnnouncement = {
                  ...varAnnouncement,
                  mcm_idx: mcm_idx,
                  com_name: comInfo[0].mcm_name,
                };
              } else {
                newAnnouncement = {
                  ...varAnnouncement,
                  mcm_idx: mcm_idx,
                  hsm_idx: mcm_idx,
                  com_name: comInfo[0].mcm_name,
                }
              }
              newAnnouncement = {
                ...newAnnouncement,
                com_homepage: resCompanyInfo?.homepage ? resCompanyInfo.homepage : comInfo[0].mcm_site,
                em_intro: resCompanyInfo?.company_intro ? resCompanyInfo.company_intro : '',
                em_welfare: resCompanyInfo?.company_welfare ? resCompanyInfo.company_welfare : '',
                em_zipcode: resCompanyInfo?.mcm_zipcode ? resCompanyInfo.mcm_zipcode : '',
                em_address1: resCompanyInfo?.mcm_address1 ? resCompanyInfo.mcm_address1 : '',
                em_address2: resCompanyInfo?.mcm_address2 ? resCompanyInfo.mcm_address2 : '',
              };
              setAnnouncement(newAnnouncement);
              // }
            }
          }
          //고용보상금
          const rewardEmployed = res.data.rewardEmployed;
          if (rewardEmployed.length > 0) {
            setRewardEmploy(rewardEmployed);
          }
          //추천인보상금 => 총보상금으로 사용
          const rewardRecommend = res.data.rewardRecommend;
          if (rewardRecommend.length > 0) {
            setRewardRecommend(rewardRecommend);
          }
          //직무
          const workCategory = res.data.workCategory;
          if (workCategory.length > 0) {
            setWorkCategory(workCategory);
          }
          //직무세부
          const workCategoryDetail = res.data.workCategoryDetail;
          if (workCategoryDetail.length > 0) {
            setWorkCategoryDetail(workCategoryDetail);
          }
          //직급
          // const workPosition = res.data.workPosition;
          // if (workPosition.length > 0) {
          //   setWorkPosition(workPosition);
          // }
          //지역
          const workRegion = res.data.workRegion;
          if (workRegion.length > 0) {
            setWorkRegion(workRegion);
          }
          //지불방식
          // const rewardPayment = res.data.rewardPayment;
          // if (rewardPayment.length > 0) {
          //   setRewardPayment(rewardPayment);
          // }
        }
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //팝업 - 기업검색 -- 써치폼
  const onSearchCompaniesHandler = async () => {
    setSelectedCompanyIndex(0);
    setPopupSearchCompany(true);
  };
  //기업 클릭
  const onSelectCompanyHandler = (mcm_idx) => {
    setSelectedCompanyIndex(mcm_idx);
  };
  //기업선택 - 써치폼
  const onSelectedCompanyHandler = (e) => {
    e.preventDefault();
    if (varSelectedCompanyIndex === 0) {
      alert("기업을 먼저 선택하십시오.");
      return;
    }
    setSearchingCompanyData([]); // 회사 검색 결과 초기화
    setSearchString(""); // 회사 검색어 초기화

    //기업정보 재조정 - varSelectedCompanyIndex:기업번호
    const compData = varCompanyList.filter(element => element.mcm_idx === varSelectedCompanyIndex);

    //기업정보를 기업객체에 저장
    setCompanyData(compData[0]);
    const newAnnouncement2 = {
      ...varAnnouncement,
      mcm_idx: varSelectedCompanyIndex, com_name: compData[0].mcm_name, com_homepage: compData[0].mcm_site === null ? "" : compData[0].mcm_site
    };
    setAnnouncement(newAnnouncement2);


    //창닫기
    setPopupSearchCompany(false);
  };

  //기업검색 및 선택 팝업창 닫기
  const onSetPopupSearchCompany = (e) => {
    e.preventDefault();
    setPopupSearchCompany(false);
    // setSelectedCompanyIndex("");
    setSearchingCompanyData([]); // 회사 검색 결과 초기화
    setSearchString(""); // 회사 검색어 초기화
  };

  //기업정보 가져오기
  const onCallCompanyInfoHandler = (e) => {
    if (e.currentTarget.checked && varCompanyData !== undefined && varCompanyData !== null) {
      setCallCompanyInfo(true);
      const newAnnouncement3 = {
        ...varAnnouncement,
        em_intro: unescapeHtml(varCompanyData.mce_intro),
        em_main_task: unescapeHtml(varCompanyData.mce_main_task),
        em_qualifications: unescapeHtml(varCompanyData.mce_qualifications),
        em_preference: unescapeHtml(varCompanyData.mce_preference),
        em_welfare: unescapeHtml(varCompanyData.mce_welfare),
        em_others: unescapeHtml(varCompanyData.mce_others),
      };

      setAnnouncement(newAnnouncement3);
    } else {
      setCallCompanyInfo(false);
      const newAnnouncement4 = {
        ...varAnnouncement,
        em_intro: "",
        em_main_task: "",
        em_qualifications: "",
        em_preference: "",
        em_welfare: "",
        em_others: "",
      };
      setAnnouncement(newAnnouncement4);
    }
  };

  //날짜 변경
  const onChangeDateHandler = (ino, date) => {
    console.log(date)
    const dbdate = ChangeSearchingDateFormat(date); //날짜 변경
    if (ino === 0) {
      setStartDate(date);
      setAnnouncement({ ...varAnnouncement, em_sdate: dbdate });
    } else {
      setEndDate(date);
      setAnnouncement({ ...varAnnouncement, em_edate: dbdate });
    }
  };
  console.log(varAnnouncement)

  //정보변경
  const onChangeHandler = (e) => {
    const { name, value } = e.currentTarget;

    // 헤드 헌터 전용관 권한 체크.
    if (name === 'em_type' && value === '4' && recrutype4Ck === false) {
      alert('헤드헌터 전용관을 이용하려면 프리미엄 승인이 필요합니다.');
      setAnnouncement({ ...varAnnouncement, em_type: Number(varAnnouncement.em_type) });
      return false;
    }

    if (name === "em_title") {
      setAnnouncement({ ...varAnnouncement, [name]: value });
    } else if (name === "em_deadline") { //채용시 마감
      const ichecked = Number(e.currentTarget.checked);
      setAnnouncement({ ...varAnnouncement, [name]: ichecked, em_edate: null });
      setEndDate(null);
    } else if (name === "em_careers") {  //경력
      const careerYear = document.getElementById("em_career_year");
      const careerEnd = document.getElementById("em_career_end");
      if (value < 2) {  //경력자
        if (!careerYear.classList.contains("none")) careerYear.classList.add("none");
        if (!careerEnd.classList.contains("none")) careerEnd.classList.add("none");
        setAnnouncement({ ...varAnnouncement, em_careers: value, em_career_year: "", em_career_end: "" });
      } else {
        if (careerYear.classList.contains("none")) careerYear.classList.remove("none");
        if (careerEnd.classList.contains("none")) careerEnd.classList.remove("none");
        setAnnouncement({ ...varAnnouncement, em_careers: value });
      }
    } else if (name === "em_career_year") {
      setAnnouncement({ ...varAnnouncement, em_career_year: value });
    } else if (name === "em_career_end") {
      setAnnouncement({ ...varAnnouncement, em_career_end: value });
    } else if (name === "em_headhunter") { //0: 사용안함
      const recommend_block = document.getElementById("emh_reward_recommend");
      const applicant_block = document.getElementById("emh_reward_applicant");
      if (Number(value) === 0) {
        setAnnouncement({ ...varAnnouncement, em_headhunter: 0, emh_reward_recommend: 0, emh_reward_applicant: 0, emh_reward_total: 0 });
        if (!recommend_block.classList.contains("hide")) recommend_block.classList.add("hide")
        if (!applicant_block.classList.contains("hide")) applicant_block.classList.add("hide")
      } else {
        setAnnouncement({ ...varAnnouncement, em_headhunter: 1 });
        if (recommend_block.classList.contains("hide")) recommend_block.classList.remove("hide")
        if (applicant_block.classList.contains("hide")) applicant_block.classList.remove("hide")
      }
    } else if (name === "em_tasks") {  //직무
      const vwc_idx = Number(e.target.value);
      const newArray = varWorkCategoryDetail.filter(element => element.vwc_idx === vwc_idx);
      setWorkCategoryShown(newArray);
    } else {
      setAnnouncement({ ...varAnnouncement, [name]: value });
    }

  };

  const onChangeEmTaskDetail = (e) => {
    setVarEmtasksDetail({
      "value": e.target.value,
      "text": e.target.selectedOptions[0].text
    });
    if (varEmtasksDetailList.findIndex(el => el.sub_value === e.target.value) >= 0) {
      return false;
    }

    if (varEmtasksDetailList.length >= CONFIG.selectMaxCount) {
      alert(`직무 선택은 ${CONFIG.selectMaxCount}개 까지만 가능합니다.`);
      return false;
    }

    if (varEmtasksDetailList.findIndex(el => el.sub_value === e.target.value) < 0) {
      setVarEmtasksDetailList(prevState => [...prevState, {
        "value": varEmtasks.value,
        "text": varEmtasks.text,
        "sub_value": e.target.value,
        "sub_text": e.target.selectedOptions[0].text
      }]);
    }
    // 직무 초기화
    setVarEmtask({
      value: '',
      text: '',
    })
    // 세부 직무 초기화
    setVarEmtasksDetail({
      value: '',
      text: '',
    })
    // 세부 직무 리스트 초기화 2022-12-19
    setWorkCategoryShown([])
  }


  const handleOnClickEmtaskDetail = () => {
    if (Number(varEmtasks.value) === 0) {
      alert('직무를 선택해 주세요.');
      return false;
    }

    if (Number(varEmtasksDetail.value) === 0) {
      alert('세부직무를 선택해 주세요.');
      return false;
    }

    if (varEmtasksDetailList.findIndex(el => el.sub_value === varEmtasksDetail.value) >= 0) {
      return false;
    }

    if (varEmtasksDetailList.length >= CONFIG.selectMaxCount) {
      alert(`직무 선택은 ${CONFIG.selectMaxCount}개 까지만 가능합니다.`);
      return false;
    }

    if (varEmtasksDetailList.findIndex(el => el.sub_value === varEmtasksDetail.value) < 0) {
      setVarEmtasksDetailList(prevState => [...prevState, {
        "value": varEmtasks.value,
        "text": varEmtasks.text,
        "sub_value": varEmtasksDetail.value,
        "sub_text": varEmtasksDetail.text
      }]);
    }
  }

  //총보상금 계산 -
  useEffect(() => {
    const total_reward =
      Number(varAnnouncement.em_reward_recommend) +
      Number(varAnnouncement.em_reward_applicant);
    setAnnouncement({ ...varAnnouncement, em_reward_total: total_reward });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    varAnnouncement.em_reward_recommend,
    varAnnouncement.em_reward_applicant,
  ]);
  //총보상금 계산 - 헤드헌터용
  useEffect(() => {
    const totalh_reward =
      Number(varAnnouncement.emh_reward_recommend) +
      Number(varAnnouncement.emh_reward_applicant);
    setAnnouncement({ ...varAnnouncement, emh_reward_total: totalh_reward });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    varAnnouncement.emh_reward_recommend,
    varAnnouncement.emh_reward_applicant,
  ]);
  // 보상금 초기화
  useEffect(() => {
    setAnnouncement({ ...varAnnouncement, em_reward_recommend: 0 });
  }, [varAnnouncement.em_type])

  //기업이름 검색문자 입력
  const onSearchStringHandler = (e) => {
    setSearchString(e.currentTarget.value);
  };
  //기업 검색
  const onSearchingCompany = (e) => {
    e.preventDefault();
    const searchingDatas = varCompanyList.filter(element => element.mcm_name.includes(varSearchString));
    setSearchingCompanyData(searchingDatas);
  };

  // 기업 로고 등록
  const onSelectEmployLogo = (e) => {
    photoInput.current.click();

  }



  const onUploadEmployLogo = async (e) => {
    const upLoadFile = e.target.files[0]
    // 파일 이미지 변수에 저장
    if (e.target.length !== 0) {
      setLogoFile(URL.createObjectURL(upLoadFile));
      console.log(upLoadFile)
      // 업로드할 파일 저장
      // setUploadFile(e.target.files[0]);
      const formData = new FormData();
      formData.append("em_image", upLoadFile)
      await axios.post(`${CONFIG.SERVER_HOST}/em_image`, formData)
        .then((res) => {
          const fileName = res.data.fileName;
          setUploadFile(fileName);
          // return fileName;
        })
    }
  }

  //우편번호/주소 검색 팝업창 열기/닫기
  const onSetIsOpenPost = (e, bts) => {
    e.preventDefault();
    setIsOpenPost(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode, address1) => {
    setAnnouncement((prevState) => ({ ...prevState, em_zipcode: zipcode, em_address1: address1 }));
  };
  //주소가 선택되면
  const onCompletePostHandler = (data) => {
    let fullAddr = data.address;
    let extraAddr = "";

    //특수주소 재정리
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    ChangeAddress(data.zonecode, fullAddr);
    //상세주소입력창으로 이동
    document.getElementById("em_address2").focus();
    //우편번호 창닫기
    setIsOpenPost(false);
  };

  //공고 저장----
  const onSaveAnnounceHandler = async (e) => {
    e.preventDefault();
    if (varAnnouncement.em_title === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_title").focus();
      return false;
    } else if (varAnnouncement.com_name === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("com_name").focus();
      return false;
    } else if (varStartDate === "" || varStartDate === null) {
      alert("모든 항목을 채워주세요.");
      document.getElementById("startDate").focus();
      return false;
    } else if (!varAnnouncement.em_deadline) {
      if (varEndDate === "" || varEndDate === null) {
        if (!varAnnouncement.em_deadline) {
          alert("모든 항목을 채워주세요.");
          document.getElementById("endDate").focus();
          return false;
        }
      }
    }

    //직무 파트 유효성 체크
    if (varEmtasksDetailList.length === 0) {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_tasks").focus();
      return false;
    } else if (varAnnouncement.em_places === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_places").focus();
      return false;
    } else if (Number(varAnnouncement.em_careers) === 2) {
      if (varAnnouncement.em_career_year === "") {
        alert("경력 시작년 입력란에 숫자를 입력해주세요.");
        document.getElementById("em_career_year").focus();
        return false;
      } else if (!isNumeric(varAnnouncement.em_career_year)) {
        alert("경력 시작년 입력란에 숫자가 아닌 것이 있습니다. \n숫자를 입력해주세요.");
        document.getElementById("em_career_year").focus();
        return false;
      } else if (Number(varAnnouncement.em_career_year) <= 0) {
        alert("경력 시작년도 입력란에 1 이상의 수를 입력해주세요.");
        document.getElementById("em_career_year").focus();
        return false;
        /* 2022-11-19 경력은 n년 이상의 형태로도 등록 하므로 n년 이하 부분은 불필요 - KSR
        } else if (varAnnouncement.em_career_end === "") {
          alert("경력 마감년도 입력란에 숫자를 입력해주세요.");
          document.getElementById("em_career_end").focus();
          return false;
        } else if (!isNumeric(varAnnouncement.em_career_end)) {
          alert("경력 마감년도 입력란에 숫자가 아닌 것이 있습니다. \n숫자를 입력해주세요.");
          document.getElementById("em_career_end").focus();
          return false;
        } else if (Number(varAnnouncement.em_career_end) <= Number(varAnnouncement.em_career_year)) {
          // console.log(Number(varAnnouncement.em_career_end), Number(varAnnouncement.em_career_year))
          alert("경력 시작년도에 입력한 수 보다 큰 수를 입력해주세요.");
          document.getElementById("em_career_end").focus();
          return false;
        */
      }
    }

    //채용구분 유효성 체크
    // em_reward_recommend 총 보상금으로 사용
    if (Number(varAnnouncement.em_reward_recommend) === 0 && varAnnouncement.em_type > 3) {
      alert("총 보상금을 선택해 주세요");
      document.getElementById("em_reward_recommend").focus();
      return false;
      // } else if (Number(varAnnouncement.em_reward_applicant) === 0) {
      //   alert("합격자 보상금을 선택해 주세요");
      //   document.getElementById("em_reward_applicant").focus();
      //   return false;
      // } else if (Number(varAnnouncement.em_headhunter) === 1) { //헤드헌터 전용관 0: 사용안함, 1:사용
      //   if (Number(varAnnouncement.emh_reward_recommend) === 0) {
      //     alert("헤드헌터 전용관 추천인 보상금을 선택해 주세요");
      //     document.getElementById("emh_reward_recommend").focus();
      //     return false;
      //   } else if (Number(varAnnouncement.emh_reward_applicant) === 0) {
      //     alert("헤드헌터 전용관 합격자 보상금을 선택해 주세요");
      //     document.getElementById("emh_reward_applicant").focus();
      //     return false;
      //   }
    }



    if (varAnnouncement.em_intro === "" || varAnnouncement.em_intro === null) {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_intro").focus();
      return false;
    } else if (varAnnouncement.em_main_task === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_main_task").focus();
      return false;
    } else if (varAnnouncement.em_qualifications === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_qualifications").focus();
      return false;
    } else if (varAnnouncement.em_preference === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_preference").focus();
      return false;
    } else if (varAnnouncement.em_welfare === "") {
      alert("모든 항목을 채워주세요.");
      document.getElementById("em_welfare").focus();
      return false;
    } else if (varAnnouncement.em_address1 === "") {
      alert("모든 항목을 채워주세요.");
      return false;
      /*     } else if (varAnnouncement.em_address2 === "") {
            alert("회사 상세주소를 입력해주세요.");
            document.getElementById("em_address2").focus();
            return false; */
    }

    if (window.confirm("저장하시겠습니까?")) {

      const sendData = {
        'mem_idx': mem_idx,
        'em_idx': varAnnouncement.em_idx,
        'mcm_idx': varAnnouncement.mcm_idx,
        'hsm_idx': varAnnouncement.hsm_idx,
        'em_title': varAnnouncement.em_title,
        'em_status': varAnnouncement.em_status,
        'com_name': varAnnouncement.com_name,
        'com_homepage': varAnnouncement.com_homepage,
        'em_sdate': varAnnouncement.em_sdate,
        'em_edate': varAnnouncement.em_edate,
        'em_deadline': varAnnouncement.em_deadline,
        'em_task': varEmtasksDetailList,
        'em_tasks': varAnnouncement.em_tasks,
        'em_tasks_detail': varAnnouncement.em_tasks_detail,
        // 'em_position' :varAnnouncement.em_position,
        'em_places': varAnnouncement.em_places,
        'em_careers': varAnnouncement.em_careers,
        'em_career_year': Number(varAnnouncement.em_careers) === 2 ? varAnnouncement.em_career_year : 0,
        'em_career_end': Number(varAnnouncement.em_careers) === 2 ? varAnnouncement.em_career_end : 0,
        'em_type': varAnnouncement.em_type,
        // 'em_reward_recommend' : Number(varAnnouncement.em_type) === 4 ? 0 : varAnnouncement.em_reward_recommend,
        'em_reward_recommend': Number(varAnnouncement.em_type) === 4 ? varAnnouncement.em_reward_recommend : Number(varAnnouncement.em_type) === 3 ? 0 : CONFIG.totalReward,
        'em_reward_applicant': Number(varAnnouncement.em_type) === 3 ? 0 : varRewardEmploy[0].vre_price,
        'em_headhunter': Number(varAnnouncement.em_type) === 4 ? 1 : 0,
        'emh_reward_recommend': Number(varAnnouncement.em_type) === 4 ? varAnnouncement.em_reward_recommend : Number(varAnnouncement.em_type) === 3 ? 0 : CONFIG.totalReward,
        'emh_reward_applicant': Number(varAnnouncement.em_type) === 3 ? 0 : varRewardEmploy[0].vre_price,
        // 'em_reward_payment' :varAnnouncement.em_reward_payment,
        'em_intro': escapeHtml(varAnnouncement.em_intro),
        'em_main_task': escapeHtml(varAnnouncement.em_main_task),
        'em_qualifications': escapeHtml(varAnnouncement.em_qualifications),
        'em_preference': escapeHtml(varAnnouncement.em_preference),
        'em_welfare': escapeHtml(varAnnouncement.em_welfare),
        'em_others': escapeHtml(varAnnouncement.em_others),
        'em_address1': varAnnouncement.em_address1,
        'em_address2': varAnnouncement.em_address2,
        'em_zipcode': varAnnouncement.em_zipcode,
        'em_mem_name': varAnnouncement.em_mem_name,
        'em_ufile': curUploadFile
      };

      axios.post(`${CONFIG.SERVER_HOST}/_save_announcement`, sendData, CONFIG.header).then((res) => {
        // console.log("sendData ", sendData)
        if (res.data.retvalue === 1) {
          alert(res.data.message);
          navigator("/Company/ComAnnouncement/AnnouncementList", { state: { varTabEmployStatus: 0 } });
        }
      });

    }
  };

  useEffect(() => {
    setAnnouncement({ ...varAnnouncement, em_career_year: "", em_career_end: "" });
  }, [varAnnouncement.em_careers])

  // 헤드 헌터 전용관 권한 체크
  useEffect(() => {
    if (varUserData.mem_mode && varUserData.mem_hunter_type) {
      const memMode = varUserData.mem_mode;
      const memHunterType = varUserData.mem_hunter_type;

      if (memMode === 1 && memHunterType === 1) {
        setRecrutype4Ck(true);
        return;
      }

      if (memMode === 2 && memHunterType === 2) {
        setRecrutype4Ck(true);
        return;
      }
    }
  }, [varUserData]);

  //기업검색 후 기업명 클릭 시 이벤트 처리 시작
  useEffect(() => {
    if (varInputs2.comName !== '') {
      setSearchingCompanyData([]); // 회사 검색 결과 초기화
      setSearchString(""); // 회사 검색어 초기화
      const newAnnouncement2 = {
        ...varAnnouncement,
        mcm_idx: mcm_idx,
        com_name: varInputs2?.comName,
      };
      setAnnouncement(newAnnouncement2);
      //창닫기
      setPopupSearchCompany(false);
    }

  }, [varInputs2]);
  //기업검색 후 기업명 클릭 시 이벤트 처리 끝

  return varWorkRegion.length === 0 || varAnnouncement.em_deadline === undefined || varAnnouncement.em_deadline === null ? null : (
    <>
      <div className="center_wrap company">
        <section className="page_announcement write">
          <h2 className="sub_content_title indent">공고등록</h2>
          <div className="form_area">
            <h2 className="sub_content_title indent">어떤 인재를 찾고 있으신가요?</h2>
            <ul className="table table_view01">
              <li className="tr">
                <div className="th">공고제목<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <input type="text" name="em_title" id="em_title" value={varAnnouncement?.em_title || ""} onChange={(e) => onChangeHandler(e)} />
                </div>
              </li>
              <li className="tr">
                <div className="th">회사명<span style={{ color: "red" }}>*</span></div>
                <div className="td td_company_name">
                  {SearchForm === 1 ?
                    <>
                      <input type="text" name="com_name" id="com_name" value={varAnnouncement?.com_name || ""} onChange={(e) => onChangeHandler(e)} style={{ textAlign: "left" }} />
                      {/* 2024-11-26 기업검색(쿠콘) 삭제
                      <button type="button" className="btn line" onClick={(e) => onSearchCompaniesHandler(e)}>기업검색</button>
                      */}
                    </>
                    : <p name="com_name" id="com_name">{varAnnouncement?.com_name || ""}</p>
                  }
                </div>
              </li>
              {SearchForm === 1 ?
                <li className="tr">
                  <div className="th">기업로고</div>
                  <div className="td td_logo">
                    <img src={logoFile} alt="" style={{ width: "100px", height: "100px", display: !logoFile ? "none" : "block", objectFit: "contain" }} />
                    <input type="file" accept="image/*" ref={photoInput} style={{ display: "none" }} onChange={(e) => onUploadEmployLogo(e)}></input>
                    <button className="btn line" id="em_image" onClick={(e) => onSelectEmployLogo(e)}>로고 등록</button>
                  </div>
                </li>
                : null}
              <li className="tr">
                <div className="th">홈페이지</div>
                <div className="td">
                  <input type="text" name="com_homepage" id="com_homepage" value={varAnnouncement?.com_homepage === "null" || varAnnouncement?.com_homepage === null ? "" : varAnnouncement?.com_homepage}
                    onChange={(e) => onChangeHandler(e)} placeholder={"https://"}
                  />
                </div>
              </li>
              <li className="tr">
                <div className="th">지원기간<span style={{ color: "red" }}>*</span></div>
                <div className="td td_date">
                  {/* <div className="datepicker_area">
                  <DatePicker id="startDate" className="datepicker" locale={ko} dateFormat="yyyy.MM.dd"
                      selected={varStartDate} selectStart
                      onChange={(date) => onChangeDateHandler(0, date)}
                    />
                  ~
                  <DatePicker id="endDate" className="datepicker" locale={ko} dateFormat="yyyy.MM.dd"
                      selected={varEndDate} selectEnd minDate={varStartDate}
                      onChange={(date) => onChangeDateHandler(1, date)} disabled={varAnnouncement?.em_deadline}
                    />
                  </div> */}
                  <div className="datepicker_area">
                    <CustomDatePicker
                      Data={varAnnouncement}
                      varStartDate={varStartDate}
                      varEndDate={varEndDate}
                      onChangeDateHandler={onChangeDateHandler}
                    />
                  </div>
                  <input type="checkbox" name="em_deadline" id="em_deadline" className="chk"
                    defaultChecked={varAnnouncement?.em_deadline} onChange={(e) => onChangeHandler(e)} />
                  <label htmlFor="em_deadline">채용시 마감</label>
                </div>
              </li>
              <li className="tr">
                <div className="th">직무<span style={{ color: "red" }}>*</span></div>
                <div className="td workcategory">
                  <select className="style_select" name="em_tasks" id="em_tasks" value={varEmtasks.value} onChange={(e) => {
                    setVarEmtask({
                      value: e.target.value,
                      text: e.target.selectedOptions[0].text
                    });
                    onChangeHandler(e);
                  }}>
                    <option value={0}>직무 선택</option>
                    {varWorkCategory.map((category, i) => (
                      <option key={"vwc_" + i} value={category?.vwc_idx}>
                        {category?.vwc_name}
                      </option>
                    ))}
                  </select>
                  <select className="style_select" name="em_tasks_detail" id="em_tasks_detail" value={varEmtasksDetail.value} onChange={(e) => onChangeEmTaskDetail(e)}>
                    <option value={0}>세부 직무 선택</option>
                    {varWorkCategoryShown.map((details, i) => (
                      <option key={"vwd_" + i} value={details?.vwd_idx}>
                        {details?.vwd_name}
                      </option>
                    ))}
                  </select>
                  <button type="button" className="btn line" onClick={handleOnClickEmtaskDetail}>직무 추가</button>
                  <ul className="selected">
                    {varEmtasksDetailList.map((n, i) => {
                      return <li key={i}>{`${n.text} > ${n.sub_text}`} <a className="btn_del" onClick={() => {
                        setVarEmtasksDetailList(varEmtasksDetailList.filter((e) => e.sub_value !== n.sub_value));
                      }}><img src={deleteImage01} alt="" /></a></li>
                    })}
                  </ul>
                </div>
                {/* <div>
                    {varEmtasksDetail.map((n,i) => {
                      return <div key={i}>{`${n.text} > ${n.sub_text}`} <button className="btn_del" onClick={() => {
                        setVarEmtasksDetail(varEmtasksDetail.filter((e) => e.sub_value !== n.sub_value));
                      }}><img src={deleteImage01} alt=""/></button></div>
                    })}
                  </div> */}
              </li>

              {/* <li className="tr">
                <div className="th">직책</div>
                <div className="td">
                  <select className="style_select" name="em_position" id="em_position" value={varAnnouncement.em_position}
                    onChange={(e) => onChangeHandler(e)}>
                    <option value={0}>직책 선택</option>
                    {varWorkPosition.map((position, i) => (
                      <option key={"vps_" + i} value={position.vps_idx}>
                        {position.vps_name}
                      </option>
                    ))}
                  </select>
                </div>
              </li> */}
              <li className="tr">
                <div className="th">지역별<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <select className="style_select" name="em_places" id="em_places" value={varAnnouncement.em_places}
                    onChange={(e) => onChangeHandler(e)}>
                    {varWorkRegion.map((region, i) => (
                      <option key={"vwr_" + i} value={region.vr_code}>{region.vr_name}</option>
                    ))}
                  </select>
                </div>
              </li>
              {/* 라디오 버튼  ==> 체크박스로 변경*/}
              <li className="tr">
                <div className="th">경력<span style={{ color: "red" }}>*</span></div>
                <div className="td td_career">
                  <div className="experienced">
                    <input type="radio" name="em_careers" id="agree2" className="chk" value={2}
                      defaultChecked={varAnnouncement.em_careers === 2 ? true : false}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <label htmlFor="agree2">경력자</label>
                    &nbsp; (&nbsp;
                    <input type="text" name="em_career_year" id="em_career_year" value={varAnnouncement.em_career_year || ""}
                      className={varAnnouncement.em_careers === 2 ? "" : "none"}
                      onChange={(e) => onChangeHandler(e)}
                      placeholder="0"
                    />
                    &nbsp;년 이상 &nbsp;~&nbsp;
                    <input type="text" name="em_career_end" id="em_career_end" value={varAnnouncement.em_career_end || ""}
                      className={varAnnouncement.em_careers === 2 ? "" : "none"}
                      onChange={(e) => onChangeHandler(e)}
                      placeholder="0"
                    />
                    &nbsp;년 이하)
                  </div>
                  <input type="radio" name="em_careers" id="agree1" className="chk" value={1}
                    defaultChecked={varAnnouncement.em_careers === 1 ? true : false}
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <label htmlFor="agree1">신입</label>
                  <input type="radio" name="em_careers" id="agree0" className="chk" value={0}
                    defaultChecked={varAnnouncement.em_careers === 0 ? true : false}
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <label htmlFor="agree0">경력무관</label>
                </div>
              </li>
            </ul>

            <h2 className="sub_content_title indent">채용 구분과 보상금은 얼마로 설정하시겠어요?</h2>
            <div className="guide_text">
              <p>
                {/* <span className="black">
                  ※ 전문 헤드헌터의 인재 추천을 받을 수 있는 채널로 추천자의
                  직무적합도와 합격률을 높일 수 있습니다. 공개 채용관에 비해
                  높은 보상금을 책정하며 프리미엄 기업회원을 대상으로 제공
                  됩니다.
                </span> */}
              </p>
            </div>
            <ul className="table table_view01">
              {/* 라디오 버튼 */}
              <li className="tr">
                <div className="th">채용구분<span style={{ color: "red" }}>*</span></div>
                <div className="td td_recrutype" style={{ position: "relative" }}>
                  <div className="radio_wrap" >
                    <input type="radio" name="em_type" id="select_recrutype1" className="chk circle" value={1}
                      // defaultChecked={ varAnnouncement.em_type === 1 ? true : false}
                      checked={Number(varAnnouncement.em_type) === 1 ? true : false}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <label htmlFor="select_recrutype1">공개</label>
                  </div>
                  {SearchForm === 1 &&
                    <div className="radio_wrap has_guide_secret">
                      <input type="radio" name="em_type" id="select_recrutype2" className="chk circle" value={2}
                        // defaultChecked={ varAnnouncement.em_type === 2 ? true : false }
                        checked={Number(varAnnouncement.em_type) === 2 ? true : false}
                        onChange={(e) => onChangeHandler(e)}
                      />
                      <label htmlFor="select_recrutype2"
                        id="mouse_secret">비공개</label>
                      <div className="guide_secret">
                        ※ 기업명이 직접 노출되지 않으며 인재 추천 이후 공개 됩니다.
                      </div>
                    </div>
                  }
                  <div className="radio_wrap">
                    <input type="radio" name="em_type" id="select_recrutype3" className="chk circle" value={3}
                      // defaultChecked={ varAnnouncement.em_type === 3 ? true : false }
                      checked={Number(varAnnouncement.em_type) === 3 ? true : false}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <label htmlFor="select_recrutype3">보상금 없는 채용</label>
                  </div>
                  <div className="radio_wrap has_guide_secret">
                    <input type="radio" name="em_type" id="select_recrutype4" className="chk circle" value={4}
                      // defaultChecked={ varAnnouncement.em_type === 4 ? true : false }
                      checked={Number(varAnnouncement.em_type) === 4 ? true : false}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <label htmlFor="select_recrutype4" id="mouse_secret">헤드헌터 전용관</label>
                    <div className="guide_secret">
                      ※ 전문 헤드헌터의 인재 추천을 받을 수 있는 채널로 추천자의
                      직무적합도와 합격률을 높일 수 있습니다. 공개 채용관에 비해
                      높은 보상금을 책정하며 프리미엄 기업회원을 대상으로 제공
                      됩니다.
                    </div>
                  </div>
                </div>
              </li>
              {Number(varAnnouncement.em_type) === 3 ? null :
                <li className="tr">
                  <div className="th">총 보상금<span style={{ color: "red" }}>*</span></div>
                  {varAnnouncement.em_type < 3 ?
                    <div className="td td_total_money">
                      {ChangeMoneyUnit(CONFIG.totalReward / 10000)}
                    </div>
                    :
                    <div className="td">
                      <select name="em_reward_recommend" id="em_reward_recommend" className="select_style"
                        value={varAnnouncement.em_reward_recommend} onChange={(e) => onChangeHandler(e)}
                      >
                        <option value={0}>선택</option>
                        {varRewardRecommend.map((data, i) => (
                          <option key={"vrr_" + i} value={data.vrr_price}>
                            {ChangeMoneyUnit(data.vrr_price / 10000)}
                          </option>
                        ))}
                      </select>
                    </div>
                  }
                  {/* <div className="th">
                  합격자 보상금
                </div>
                <div className="td">
                  <select name="em_reward_applicant" id="em_reward_applicant" className="select_style"
                    value={varAnnouncement.em_reward_applicant} onChange={(e) => onChangeHandler(e)}>
                    <option value={0}>선택</option>
                    {varRewardEmploy.map((data, i) => (
                      <option key={"vre_" + i} value={data.vre_price}>
                        {addThousandPoint(data.vre_price) + " 원"}
                      </option>
                    ))}
                  </select>
                </div> */}
                  {/* <div className="th">총 보상금</div>
                <div className="td td_total_money">
                  {varAnnouncement.em_type < 3 ? addThousandPoint(CONFIG.totalReward) : varAnnouncement.em_type === 3 ? 0 : addThousandPoint(varAnnouncement.em_reward_total) } 원
                </div> */}
                </li>
              }
              {/* <li className="tr">
                <div className="th">
                  보상금 <br />
                  지급 조건
                </div>
                <div className="td">
                  <select className="select_style" name="em_reward_payment" id="em_reward_payment" value={varAnnouncement.em_reward_payment}
                    onChange={(e) => onChangeHandler(e)}>
                    <option value={0}>선택</option>
                    {varRewardPayment.map((data, i) => (
                      <option key={"vrp_" + i} value={data.vrp_idx}>
                        {data.vrp_name}
                      </option>
                    ))}
                  </select>
                </div>
              </li> */}
            </ul>
            <div className="guide_text">
              <p>
                <span className="black">※ 추천인 보상금</span> : 후보자 입사 시,
                후보자에게 공고를 추천한 추천인에게 지급되는 보상금입니다.
              </p>
              <p>
                <span className="black">※ 합격자 보상금</span> : 채용공고에
                추천받은 후보자가 입사 시 지급되는 보상금입니다.
              </p>
              <p className="blue">
                * 모든 보상금은 지급 조건 달성 시 헤딩머니로 지급됩니다.
              </p>
            </div>

            <div className="sub_content_title_wrap">
              <h2 className="sub_content_title indent">
                채용하시는 회사의 상세 정보를 작성해주세요.
              </h2>
              <div className={varCompanyData === undefined || varCompanyData === null ? "none input_area" : " input_area"}>
                <input type="checkbox" id="check_last_info" className="chk"
                  onChange={(e) => onCallCompanyInfoHandler(e)} defaultChecked={varCallCompanyInfo}
                />
                <label htmlFor="check_last_info">회사 상세 정보 마지막 입력 값 불러오기</label>
              </div>
            </div>
            <ul className="table table_view01">
              <li className="tr">
                <div className="th">회사소개<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <textarea name="em_intro" id="em_intro" className="big text-lines" value={varAnnouncement.em_intro === "null" ? "" : varAnnouncement.em_intro}
                    onChange={(e) => onChangeHandler(e)}>
                  </textarea>
                </div>
              </li>
              <li className="tr">
                <div className="th">주요업무<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <textarea name="em_main_task" id="em_main_task" className="text-lines" value={varAnnouncement.em_main_task || ""}
                    onChange={(e) => onChangeHandler(e)}>
                  </textarea>
                </div>
              </li>
              <li className="tr">
                <div className="th">자격요건<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <textarea name="em_qualifications" id="em_qualifications" className="text-lines" value={varAnnouncement.em_qualifications || ""}
                    onChange={(e) => onChangeHandler(e)}>
                  </textarea>
                </div>
              </li>
              <li className="tr">
                <div className="th">우대사항<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <textarea name="em_preference" id="em_preference" className="text-lines" value={varAnnouncement.em_preference || ""}
                    onChange={(e) => onChangeHandler(e)}>
                  </textarea>
                </div>
              </li>
              <li className="tr">
                <div className="th">혜택 및 복지<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <textarea name="em_welfare" id="em_welfare" className="text-lines" value={varAnnouncement.em_welfare === "null" ? "" : varAnnouncement.em_welfare}
                    onChange={(e) => onChangeHandler(e)}>
                  </textarea>
                </div>
              </li>

              <li className="tr">
                <div className="th">기타 안내 사항</div>
                <div className="td">
                  <textarea name="em_others" id="em_others" className="text-lines" value={varAnnouncement.em_others || ""}
                    onChange={(e) => onChangeHandler(e)}>
                  </textarea>
                </div>
              </li>
              <li className="tr">
                <div className="th">회사주소<span style={{ color: "red" }}>*</span></div>
                <div className="td">
                  <div className="address_area">
                    <div className="address_search">
                      <input type="text" id="em_zipcode" name="em_zipcode" value={varAnnouncement.em_zipcode || ""} readOnly />
                      <button type="button" className="btn line" onClick={(e) => onSetIsOpenPost(e, true)}>주소 검색</button>
                    </div>
                    <input type="text" id="em_address1" name="em_address1" placeholder="회사주소를 선택해주세요."
                      value={varAnnouncement.em_address1 || ""} readOnly
                    />
                    <input type="text" name="em_address2" id="em_address2" placeholder="상세 주소를 입력해주세요."
                      value={varAnnouncement.em_address2 || ""} onChange={(e) => onChangeHandler(e)}
                    />
                  </div>
                </div>
              </li>
            </ul>
            {/* 채용 관리인 정보 */}
            <div style={SearchForm === 1 ? { display: "block" } : { display: "none" }}>
              <h2 className="sub_content_title indent">채용 관리인 정보</h2>
              <ul className="table table_view01">
                <li className="tr">
                  <div className="th">등록자</div>
                  <div className="td">
                    <input type="text" value={varUserData.mem_name || ""} readOnly={true} />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <section className="page_bottom_button_area one">
            <button className="btn" onClick={(e) => onSaveAnnounceHandler(e)}>공고 등록</button>
          </section>

          {/* 기업 검색*/}
          {varPopupSearchCompany ?
            <PopCompanySelection
              varInputs2={varInputs2}
              setInputs2={setInputs2}
              setPopupSearchCompany={setPopupSearchCompany}
            />
            : null
          }

          {/* 우편번호/주소 검색*/}
          {varIsOpenPost ?
            <Postcode setIsOpenPost={setIsOpenPost} varIsOpenPost={varIsOpenPost} onCompletePostHandler={onCompletePostHandler} />
            : null
          }
        </section>
      </div>
    </>
  );
};
export default AnnouncementWriteC;
