import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import AcqRepuW from 'components/Career/AcqRepuW'

const AcquaintanceReputationW = () => {
	const { repu_code } = useParams();	//지인 평판 시 코드
	// const deCode = decodeURIComponent(repu_code); //URI 디코드
	const deCode = useRef(decodeURIComponent(repu_code || '')); //URI 디코드

	return (
		<div className="container">
			<AcqRepuW repu_code={deCode.current} />
		</div>
	);
}

export default AcquaintanceReputationW
