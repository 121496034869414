import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from 'configs/configs';

import ConsultingTop from 'components/Talk/ConsultingTop';
import { resetNavSubMenu } from "../../components/Common/Common";

function MyHeding10({ getSubItem }) {
  const mem_mode = Number(localStorage.getItem('mem_mode'));
  const user_idx = localStorage.getItem('mem_idx');
  const [varInput, setInputs] = useState({
    consult_heding: false,
    consult_mba: false,
    re_idx: 0,
    agree: true
  })
  const [varPopupResumeList, setPopupResumeList] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("user_idx", user_idx);
    axios.post(`${CONFIG.SERVER_HOST}/_get_myresume_list`, params).then((res) => {
      if (res.data.retvalue === 1) {
        const data = res.data.results;
        setPopupResumeList(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //컨설팅 선택항목
  const onCheckedHandler = (e) => {
    setInputs({
      ...varInput,
      [e.target.name]: e.currentTarget.checked
    })
  }

  //동의받기
  const onChangeHandler = value => {
    setInputs({
      ...varInput,
      agree: value
    })
  }
  //지원할 이력서 선택
  const onSelectedResumeHandler = (re_idx) => {
    setInputs({
      ...varInput,
      re_idx: re_idx
    })
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    try {
      if (!varInput.consult_heding && !varInput.consult_mba) {
        alert('컨설팅 받기를 원하시는 항목을 선택해주세요.')
        return;
      }
      if (!varInput.re_idx) {
        alert('컨설팅을 받을 이력서를 먼저 선택해주세요.')
        return;
      }
      if (!varInput.agree) {
        alert('동의해야 신청 할수 있습니다.')
        return;
      }

      const params = new URLSearchParams();
      params.append("mem_idx", user_idx);
      params.append("options", JSON.stringify({
        consult_heding: varInput.consult_heding ? 1 : 0,
        consult_mba: varInput.consult_mba ? 1 : 0,
        re_idx: varInput.re_idx,
        agree: varInput.agree ? 1 : 0
      }));
      params.append('mem_name', localStorage.getItem('mem_name'));
      params.append('url', `${CONFIG.URL}`);

      axios.post(`${CONFIG.SERVER_HOST}/consultingApply`, params).then((res) => {
        alert(res.data.message);

        if (Number(res.data.retvalue) === 1) {
          setInputs({
            consult_heding: false,
            consult_mba: false,
            re_idx: 0,
            agree: true
          })
        }
      }).catch(e => console.debug('Error  ', e));

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSubItem(9);
    if (mem_mode === 0) {
      resetNavSubMenu(4, 9);
    } else {
      resetNavSubMenu(2, 9);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="container page_my_consulting_service">
      <ConsultingTop varInput={varInput} onCheckedHandler={onCheckedHandler} varPopupResumeList={varPopupResumeList} onSelectedResumeHandler={onSelectedResumeHandler} onChangeHandler={onChangeHandler} />
      <section className="box_out_button_area one">
        <button className="btn" onClick={(e) => onSubmitHandler(e)}>신청하기</button>
      </section>
    </div>
  )
}

export default MyHeding10