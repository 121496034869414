import React, { useState,useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import CompanyRecommendTop from "components/Company/CompanyRecommendTop";
import CompanyRecommendList from "components/Company/CompanyRecommendList";
import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding3({ getSubItem }) {
  //화면표시정보 배열
  const [varRecommendList, setRecommendList] = useState([]);

  // const mcm_idx = localStorage.getItem("mcm_idx");
  // const uNavigate = useNavigate();
  // const uri_path = window.location.pathname;
  // useEffect(() => {
  //   if (!mcm_idx || Number(mcm_idx)===0) {
  //     localStorage.setItem("re_url", uri_path);
  //     alert("로그인 후 이용 가능합니다.");
  //     uNavigate("/Login");
  //   }
  // },[mcm_idx, uri_path, uNavigate]);

  //매뉴 설정
  useEffect(() => {
    getSubItem(3);
    resetNavSubMenuForHeding(3);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container page_com_myheding03">
      <CompanyRecommendTop />
      <CompanyRecommendList varRecommendList={varRecommendList} setRecommendList={setRecommendList}/>
    </div>
  );
}

export default MyHeding3;
 