/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
// import axios from "axios";
// import CONFIG from "../../configs/configs";
// import { bizCodeAutoHypen } from "components/Common/Common";
import Postcode from './Postcode'
import PopCompanySelection from 'components/PopUp/PopCompanySelection';

const JoinCompanyInfo = ({ varInputs2, setInputs2, setFile, varFile }) => {
  //팝업창 조정 - true:팝업창 열림
  const [varPopupSearchCompany, setPopupSearchCompany] = useState(false); //회사선택
  const [varIsOpenPost, setIsOpenPost] = useState(false); //주소선택창 활성화 유무

  // //기업 - 검색문자 & 검색된 기업정보
  // const [varSearchString, setSearchString] = useState("");
  // const [varSearchingCompanyData, setSearchingCompanyData] = useState([]); //검색된 회사목록
  // const [varSelectedString, setSelectedString] = useState("");

  // const [searchLoading, setSearchLoading] = useState(false);

  //입력정보저장 - 기업정보
  const onChangeHandler2 = (e) => {
    const { value, name } = e.currentTarget;
    setInputs2({ ...varInputs2, [name]: value });
  };
  //사업자등록증 업로드
  const onChangeThumbnailHandler = (e) => {
    // const reader = new FileReader();
    setFile(e.currentTarget.files[0]);
    // setInputs2({ ...varInputs2, taxUfile: e.currentTarget.files[0].name });
    // if (e.currentTarget.files[0]) {
    //   reader.readAsDataURL(e.currentTarget.files[0]);
    // }
  };

  //우편번호/주소 선택시 --> 팝업에서 객체로 저장
  const onCompletePostHandler = (data) => {
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    setInputs2((varInputs2) => ({
      ...varInputs2,
      zipcode: data.zonecode,
      address1: fullAddr,
    }));
    setIsOpenPost(false);
    document.getElementById("address2").focus();
  };

  //주소찾기창 열기
  const onChangeOpenPostHandler = (e) => {
    setIsOpenPost(true);
    document.getElementById("address1").focus();
  };

  //사업자등록증
  const photoInput = useRef();
  const onClickThumbnailHandler = () => {
    photoInput.current.click();
  };

  //팝업창---------
  //회사찾기 - 팝업창 호출
  /*2024-11-26 기업검색(쿠콘) 삭제
  const onChangeOpenCompanyHandler = (e) => {
    e.preventDefault();
    // setSearchString("");
    // setSearchingCompanyData([]);
    setPopupSearchCompany(!varPopupSearchCompany);
  };
  */
  //기업이름 검색문자 입력
  // const onSearchStringHandler = (e) => {
  //   setSearchString(e.currentTarget.value);
  // };

  // 기업 검색.
  // const onSearchingCompanyHandler = async (e) => {
  //   e.preventDefault();
  //   if (varSearchString === "") {
  //     alert("검색할 문자를 먼저 입력하십시오.");
  //     return false;
  //   }

  //   setSearchingCompanyData([]);
  //   setSearchLoading(true);
  //   await axios
  //     .get(`${CONFIG.SERVER_HOST}/search-company/${encodeURIComponent(varSearchString)}`)
  //     .then((r) => {
  //       console.log(r.data);
  //       const resData = r.data;
  //       setSearchingCompanyData(resData.map((company) => {
  //         return {
  //           corpName: company.TRCO_CMNM,
  //           bizNo: company.BIZ_NO,
  //           address: company.ADDRESS,
  //         }
  //       }));
  //       setSearchLoading(false);
  //     }).catch((e) => {
  //       const errData = e;
  //       setSearchingCompanyData([]);
  //       console.debug('error ', errData);
  //       setSearchLoading(false);
  //     });
  // };
  //회사선택
  // const onSelectCompanyHandler = (comp) => {
  //   const { address, corpName, bizNo } = comp;
  //   setSelectedString(corpName);
  //   setInputs2({
  //     ...varInputs2,
  //     taxId: bizCodeAutoHypen(bizNo),
  //     comName: corpName,
  //     address1: address
  //   });
  // };

  //회사이름 지정
  // const onSelectedCompanyHandler = (e) => {
  //   setInputs2({ ...varInputs2, comName: varSelectedString });
  //   setPopupSearchCompany(false);
  // };

  return (
    <div className="member_form">
      <p className="title_field">회사명</p>
      <div className="input_wrap">
        <input
          type="text"
          name="comName"
          value={varInputs2.comName}
          placeholder="회사명을 입력해주세요."
          onChange={(e) => onChangeHandler2(e)}
        />
        {/*
        <a
          className="white btn"
          onClick={(e) => onChangeOpenCompanyHandler(e)}
          style={{ cursor: "pointer" }}
        >
          회사찾기
        </a>
        */}
      </div>
      <p className="title_field">사업자등록번호</p>
      <input
        type="text"
        name="taxId"
        value={varInputs2.taxId}
        placeholder="사업자등록번호를 입력 (-제외)"
        onChange={(e) => onChangeHandler2(e)}
      />
      <p className="title_field">대표자명</p>
      <input
        type="text"
        name="ceoName"
        value={varInputs2.ceoName}
        placeholder="대표자명을 입력해주세요."
        onChange={(e) => onChangeHandler2(e)}
      />
      <p className="title_field">회사주소</p>
      <div className="input_wrap">
        <input
          type="text"
          id="address1"
          name="address1"
          style={{ pointerEvents: "none", backgroundColor: "#eee" }}
          defaultValue={
            varInputs2.zipcode === null || varInputs2.zipcode === ""
              ? ""
              : `(${varInputs2.zipcode}) ${varInputs2.address1}`
          }
          readOnly
        />
        <a
          className="white btn"
          onClick={(e) => onChangeOpenPostHandler(e)}
          style={{ cursor: "pointer" }}
        >
          주소찾기
        </a>
      </div>
      <input
        id="address2"
        name="address2"
        type="text"
        value={varInputs2.address2}
        placeholder={"상세주소를 입력해주세요."}
        onChange={(e) => onChangeHandler2(e)}
      />
      <p className="title_field">사업자등록증 첨부</p>
      <div className="input_wrap">
        <input
          type="text"
          name="taxUfile"
          placeholder="사업자등록증을 첨부해주세요."
          // value={varInputs2.taxUfile}
          value={varFile ? varFile.name : ""}
          style={{ pointerEvents: "none", backgroundColor: "#eee" }}

        />
        <a
          onClick={(e) => onClickThumbnailHandler(e)}
          className="white btn"
          style={{ cursor: "pointer" }}
        >
          파일찾기
        </a>
      </div>
      <p className="tip_field">※ Jpg png pdf 만 Upload 가능합니다.</p>
      <input
        type="file"
        className="Button"
        accept={".gif,.png,.jpg,.pdf"}
        ref={photoInput}
        style={{ display: "none" }}
        onChange={(e) => onChangeThumbnailHandler(e)}
      />

      {/* 기업 검색*/}
      {/* <div
        className="popup"
        style={
          varPopupSearchCompany ? { display: "block" } : { display: "none" }
        }
      >
        <article className="popup_wrap popup01">
          <div className="title_wrap">
            <h1>기업검색</h1>
          </div>
          <div className="content_wrap company_search">
            <section
              className="search_area"
              style={{ display: "flex", justifyContent: "space_between" }}
            >
              <input
                type="text"
                name="search_name"
                value={varSearchString}
                onChange={(e) => onSearchStringHandler(e)}
                placeholder="회사명을 입력해주세요."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchingCompanyHandler(e)
                  }
                }}
              />
              <button
                className="btn"
                onClick={(e) => onSearchingCompanyHandler(e)}
                style={{ width: "90px" }}
              >
                {searchLoading ? `조회중` : `조회`}
              </button>
            </section>
            <section className="company_list_area style_scroll">
              {varSearchingCompanyData?.length === 0 ? (
                <div className="nodata">검색된 회사가 없습니다.</div>
              ) : (
                varSearchingCompanyData.map((company, i) => (
                  <div key={"cmo_" + i}>
                    <input
                      type="radio"
                      name="radio_company"
                      id={`radio_company${i + 1}`}
                      onClick={() => onSelectCompanyHandler(company)}
                      disabled={searchLoading}
                    />
                    <label htmlFor={`radio_company${i + 1}`}>
                      {`${company.corpName} - ${company.address}`}
                    </label>
                  </div>
                ))
              )}
            </section>
          </div>
          <div className="button_wrap">
            <div className="big_button_wrap one">
              <a className="btn" onClick={(e) => onSelectedCompanyHandler(e)}>
                선택하기
              </a>
            </div>
            <div className="close_button_wrap">
              <a onClick={(e) => onChangeOpenCompanyHandler(e, false)}>
                닫기 X
              </a>
            </div>
          </div>
        </article>
      </div> */}
      {varPopupSearchCompany}
      {varPopupSearchCompany ?
        <PopCompanySelection varInputs2={varInputs2}
          setInputs2={setInputs2} setPopupSearchCompany={setPopupSearchCompany} />
        :
        null
      }
      {varIsOpenPost ?
        <Postcode setIsOpenPost={setIsOpenPost} varIsOpenPost={varIsOpenPost} onCompletePostHandler={onCompletePostHandler} />
        : null
      }
    </div>
  );
};

export default JoinCompanyInfo;
