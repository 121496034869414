/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { common_select_function } from 'components/Common/Common';

/*프로필*/
function PopLanguage({
  varPopLanguage,
  setPopLanguage,
  languageStudyList,
  setLanguageStudyList
}) {
  const [varLanguageName, setLanguageName] = useState("");
  const [varLanguageCode, setLanguageCode] = useState();
  const [varLanguageList, setLanguageList] = useState([]);

  useEffect(() => {
    const getLanguageList = async () => {
      if (varLanguageList.length === 0) {
        const column = ` vlc_code, vlc_name `;
        const table = ` var_lauguage_code `;
        const where = ` 1=1`;
        const option = ``;
        await common_select_function(column, table, where, option).then(function (res) {
          setLanguageList(res);

        }).catch(function (err) {
          console.error(err); // Error 출력  
        });
      }
    };

    getLanguageList();


  }, [varLanguageList]);


  //언어선택시
  const onSelectHandler = (vlc_code) => {
    if (vlc_code === varLanguageCode) {
      setLanguageCode();
      setLanguageName('');
      return false;
    } else {
      const Language = varLanguageList.find(x => x.vlc_code === vlc_code);
      setLanguageCode(Language.vlc_code);
      setLanguageName(Language.vlc_name);
    }
  };

  //팝업창 닫기
  const onClosePopSchoolHandler = (e) => {
    e.preventDefault();
    setPopLanguage("popup");
  };

  //언어 배열에 저장한 후 창닫기
  const onSelectedAddHandler = (e) => {
    e.preventDefault();

    const LanguageChk = languageStudyList.find(x => x.language_code === varLanguageCode);

    if(!LanguageChk){
      const language = {
        language_code: varLanguageCode,
        language_name: varLanguageName,
        language_level: 0
      };
      setLanguageStudyList([...languageStudyList, language]);
      setLanguageCode();
      setLanguageName('');
  
      setPopLanguage("popup"); //팝업닫기
    }else{
      alert('동일한 어학이 추가되어있습니다.');
    }

  };

  return (
    <section className={varPopLanguage}>
      <article className="popup_wrap popup01">
        <div className="title_wrap">
          <h1>언어 선택</h1>
        </div>
        <div className="content_wrap">

          <div className="select_box">
            <div className="wrap scroll">
              {varLanguageList && varLanguageList?.map((language, index) => (
                <button
                  key={"psch" + index}
                  className={`button ${varLanguageCode === language.vlc_code ? `active` : ``}`}
                  onClick={() => onSelectHandler(language.vlc_code)}
                >
                  {language.vlc_name}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="button_wrap">
          <div className="big_button_wrap one">
            <button onClick={(e) => onSelectedAddHandler(e)} className="btn">
              추가
            </button>
          </div>
          <div className="close_button_wrap">
            <button onClick={(e) => onClosePopSchoolHandler(e)}>닫기 X</button>
          </div>
        </div>
      </article>
    </section>
  );
}

export default PopLanguage;
