/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CONFIG from '../../configs/configs';

import PortfolioHistory from './PortfolioHistory';
import PortfolioTmpHistory from './PortfolioTmpHistory';
import PortfolioFileHistory from './PortfolioFileHistory';
//image
import addportImage from '../../img/sub/btn_plus_blue.png';

function Portfolio({ curResumeIndex, varInputs, onChangeHandler, resumePortfolioList, setResumePortfolioList,
   resumeTmpPortfolioList, setResumeTmpPortfolioList, resumePortfolioFiles, setResumePortfolioFiles, changePortfolioFiles }) {

   const [varNumber, setNumber] = useState(1);
  const [NewList, setNewList] = useState([]);

  //이미 저장되어 등록된 포트폴리오의 목록을 가져오기
  useEffect(() => {
    if (curResumeIndex > 0) {
        const params = new URLSearchParams();
        params.append("re_idx", curResumeIndex);
        params.append("user_idx", Number(localStorage.getItem('mem_idx')));
        
        axios.post(`${CONFIG.SERVER_HOST}/_get_my_portfolios`, params).then((res) => {
          if (res.data.retvalue === 1) {
            //임시
            if (res.data.results?.length > 0) {
              const data2 = res.data?.results.map((data, i) => (
                {
                  rcp_idx: data.rcp_idx,
                  rcp_ufile: data.rcp_ufile,   //파일명
                  rcp_rdate: data.rcp_rdate,
                  rcp_state: 1,
                  rcp_status: 1                //1:유지, 0:삭제
                }
              ));
              setResumePortfolioList(data2);
            }
          } else {
            setResumePortfolioList([]);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curResumeIndex]);

   //버튼 클릭시 --> 포트폴리오 추가를 위한 새로운 입력 Line 추가 
   const onAddFileHandler = e => {
      e.preventDefault();
      //if (!window.confirm('포트폴리오를 추가합니까?')) return;

      // //배열에 추가할 파일객체 만들기
      // var newPortFile = new FormData();
      // newPortFile.append('idx', varNumber);
      // newPortFile.append('filename', '');
      // newPortFile.append('selectFile', null);
    // const newItem = {newPortFile};

    setNumber(prevNumber => prevNumber + 1);
    // //배열에 파일객체추가
    const PortFile = {id:varNumber, file:null, filename:null, show:true};
    setResumePortfolioFiles([...resumePortfolioFiles, PortFile]);
  }

  useEffect(()=>{
    if(NewList.length > 0){
      setResumePortfolioFiles(NewList);
    }
  }, [NewList]);

  
  const showList = resumePortfolioFiles && resumePortfolioFiles.map((el, i) => {
      return (
        <PortfolioFileHistory 
            key={i}
            index={i}
            portfolioFile={el}
            resumePortfolioFiles={resumePortfolioFiles}
            setResumePortfolioFiles={setResumePortfolioFiles}
            setNewList={setNewList}
          />
      )});

  // NewList.map((item)=>{
  //   console.log(item.id, item.filename,  item.show);
  // });

  return (
    <section className="section">
      <div className="title_area">
        <h3 className="title">포트폴리오</h3>
        <button className="btn txt" onClick={(e) => onAddFileHandler(e)}>
          <img src={addportImage} alt="" />추가
        </button>
      </div>
      <article className="input_area">
        <input type="text" className={varInputs?.site ? ' bg_on' : ''} name="site" value={varInputs?.site || ""} placeholder={"http://"} onChange={onChangeHandler} />
        <p>첨부 가능한 확장자 ({CONFIG.fileExt})</p>
        <p>첨부 파일 용량 : {CONFIG.fileShowSize}MB</p>
        <br/>
        {/* 저장되어 있는 포트폴리오 목록 표시 */}
        {resumePortfolioList.map((portfolio, index) => (
          <PortfolioHistory 
            key={index}
            index={index}
            portfolio={portfolio}
            resumePortfolioList={resumePortfolioList}
            setResumePortfolioList={setResumePortfolioList}
          />
        ))}
        {/* 저장되어 있는 포트폴리오 목록 표시 */}
        {resumeTmpPortfolioList.map((portfolio, index) => (
          <PortfolioTmpHistory 
            key={index}
            index={index}
            portfolio={portfolio}
            resumeTmpPortfolioList={resumeTmpPortfolioList}
            setResumeTmpPortfolioList={setResumeTmpPortfolioList}
          />
        ))}
        {/* 추가할 포트폴리오 목록 표시 */}
        {showList}
      </article>
    </section>
  );
}

export default Portfolio;