import React from "react";
import { TalkWriteEditor } from "html/Talk/TalkWriteEditor";

function CareerDescription({resumeCareerDescription, setResumeCareerDescription, careerTipList}) {
    const varStrLength = resumeCareerDescription?.length;
    //자기소개서 입력분---
    const onChangeHandler = ({value}) => {
      setResumeCareerDescription(value);
    }

  //경력기술서 경력 작성 TIP
  let copyData = [...careerTipList]
  const tipData = copyData.filter((el) => el.career_order === 6)

  return (
    <section className="section">
      <div className="title_area has_guide_secret">
        <h3 className="title">경력기술서</h3>
        {/*작성팁*/}
        <div className="guide">
          <p className="guide_btn"><strong className="tit">작성 TIP</strong><span className="icon" ></span></p>
          <div className="guide_secret">
            <p className="guide_secret_tit">{tipData[0]?.career_tip_title}</p>
            <div className="guide_secret_txt text-lines">{tipData[0]?.career_tip_content}</div>
          </div>
        </div>
      </div>
      <div className="input_area">
      {/* <textarea rows="10" className={`textarea_h400${resumeCareerDescription ? ` bg_on` : ``}`} onChange={(e)=>{onChangeHandler(e)}} value={resumeCareerDescription} placeholder="경력기술서를 입력해주세요."></textarea> */}
        <TalkWriteEditor
          defaultValue={resumeCareerDescription || ""}
          handleOnChange={(text) => onChangeHandler({value: text})}
        />
          <p className="textarea_tip">※ 5000 자 이내로 작성 해주세요.</p>
          <p className="textarea_tip type2">({varStrLength} / 5000)</p>
      </div>
  </section>
  );
}

export default CareerDescription;
