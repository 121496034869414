import React, { useState } from 'react'

//이미지
import icon_repu_pop_sad from "img/sub/repu_pop_sad.png";

const PopColleRepuFail = () => {
	const [className, setClassName] = useState('popup active');
	return (
		<div className={className}>
			<div className="popup_wrap popup01">
				<section className="title_wrap">
					<h1>동료평판하기</h1>
				</section>
				<section className="content_wrap">
					<article className="notice_area">
						<div className="icon red"><img src={icon_repu_pop_sad} alt="icon_pw" /></div>
						<p className="title_field">회원님과 동료분이 <span className="point">일치하는 <br />직장이 없음</span>으로 동료 평판 대상자가 아닙니다.</p>
					</article>
				</section>
				<section className="button_wrap">
					<div className="big_button_wrap one">
						<button className="btn" onClick={() => {
							setClassName(prev => prev = 'popup');
						}}>확인</button>
					</div>
					{/* <div className="close_button_wrap">
						<a>닫기 X</a>
					</div> */}
				</section>
			</div>
		</div>
	)
}

export default PopColleRepuFail