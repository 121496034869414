/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect} from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/configs';

//css
import '../../css/Voucher.css';
import '../../css/Common.css';
import '../../css/Board.css';
import '../../css/Sub.css';
import '../../css/Company.css';

import { resetNavSubMenu,mobileWrap,isPhone,numHyphen,checkCorporateRegiNumber } from "../../components/Common/Common";
//components
import VoucherPayList from '../../components/Voucher/VoucherPayList';
import VoucherPayCard from '../../components/Voucher/PayType';
import Agree from '../../components/Voucher/Agree';
//이미지

/*이용권*/
function VoucherPay({ varCompanyVoucher,varConditionData, setConditionData, varVoucherList, setVoucherList }) {
  //var clientKey = 'test_ck_D4yKeq5bgrpgb6QGdlxVGX0lzW6Y'; 
  var clientKey = CONFIG.tossClientKey; //2022-11-16 KSR
  var tossPayments = window.TossPayments(clientKey);
  const mcm_idx = localStorage.getItem('mcm_idx');

  const location = useLocation();
  const navigator = useNavigate();
  //console.log(location);

  //구매할 이용권 배열 번호
  const [varVoucherIndex,setVoucherIndex] = useState(-1);
  const [varSelectedVoucher,setSelectedVoucher] = useState({});
  const [varBankList,setBankList] = useState([]);
  const [varPaymentInfo,setPaymentInfo] = useState({
    bank_idx:0,
    or_sender:'',
    or_pay_method:0,
    or_receipt:0,
    or_deduction:0,
    or_deduction_val1:'',
    or_deduction_val2:'',
    or_deduction_val3:'',
    or_price:0,
    or_num:1,
    or_amount:0,
    or_agree_info:0,
    or_agree_allow:0,
    agree_chk1:0,
    agree_chk2:0
  });

  useEffect(() => {
    if(location !== null && location.state !== null) {
      const vo_idx = location.state.vo_idx;
      setVoucherIndex(vo_idx);
    }
    getCompanyAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[mcm_idx]);

  useEffect(() => {
    resetNavSubMenu(4,0);
    mobileWrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //회사게좌정보 읽기
  const getCompanyAccount = async () => {
    try {
      const params = new URLSearchParams();
      params.append("mcm_idx", mcm_idx);
      await axios.post(`${CONFIG.SERVER_HOST}/_get_company_banks`,params).then((res) => {
          if(res.data.retvalue === 1) {
            //console.log(res.data.results);
            setBankList(res.data.results);
          } else {
            setBankList([]);
          }
      });
    } catch (error) {
        console.log(error);
    }
  }

  //선택된 이용권 정보 획득
  useEffect(() => {
    if(varVoucherIndex > 0) {
      if(varVoucherList.length === 0) { //이용권 목록이 없는 경우 다시 읽어오기
        const params = new URLSearchParams();
        params.append("mcm_idx", mcm_idx);
        axios.post(`${CONFIG.SERVER_HOST}/_get_company_voucher2`,params).then((res) => {
          if(res.data.retvalue === 1) {
            setVoucherList(res.data.vouchers);
            setConditionData(res.data.conditions);
            const copyData1 = varVoucherList.filter(element=>element.vo_idx === varVoucherIndex);
            if(copyData1.length > 0) {
              setSelectedVoucher({...copyData1[0]});
              setProductPriceInfo({...copyData1[0]});
            }
          }
        });
      } else {
        const copyData2 = varVoucherList.filter(element=>element.vo_idx === varVoucherIndex);
        if(copyData2.length > 0) {
          setSelectedVoucher({...copyData2[0]});
          setProductPriceInfo({...copyData2[0]});
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line no-use-before-define
  },[mcm_idx, setProductPriceInfo, setVoucherList,setConditionData, varVoucherIndex, varVoucherList]);

  //구매할 이용권 상품 배열 저장 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setProductPriceInfo = (products) => {
    const or_price = products.vo_amount;
    const or_num = 1;
    const or_amount = or_price * or_num;
    setPaymentInfo({...varPaymentInfo,or_price:or_price,or_num:or_num,or_amount:or_amount});
  }

  //카드/은행결제
  const onPaymentHandler = (e) => {
    e.preventDefault();
    // 입금자 입력 확인
    if(varPaymentInfo.or_pay_method === 0 && varPaymentInfo.or_sender === "") {
      alert('입금자명을 입력해주세요.');
      return;
    }
    const deduction_name = document.getElementById('or_deduction_val1').name;
    const deduction_value = document.getElementById('or_deduction_val1').value;

    if(varPaymentInfo.or_receipt === 1){
      if(deduction_name === 'or_deduction_val1' && !deduction_value){
        alert('휴대폰번호를 입력해주세요.');
        return;
      } else if(deduction_name === 'or_deduction_val2' && !deduction_value){
        alert('현금영수증 카드번호를 입력해주세요.');
        return;
      } else if(!numHyphen(deduction_value)){
        alert('숫자만 입력해주세요.');
        setPaymentInfo({...varPaymentInfo, or_deduction_val1:"", or_deduction_val2:""})
        return;
      } else if(deduction_name === 'or_deduction_val1' && deduction_value.length <13){
          alert('핸드폰 번호 형식에 맞지 않습니다.');
          setPaymentInfo({...varPaymentInfo, or_deduction_val1:"", or_deduction_val2:""})
          return;
      }  else if(deduction_name === 'or_deduction_val1' && !isPhone(deduction_value)){
          alert('핸드폰 번호 형식에 맞지 않습니다.');
          setPaymentInfo({...varPaymentInfo, or_deduction_val1:"", or_deduction_val2:""})
          return;
      } else if(deduction_name === 'or_deduction_val2' && deduction_value.length <19){
        alert('카드번호 형식에 맞지 않습니다.');
        setPaymentInfo({...varPaymentInfo, or_deduction_val1:"", or_deduction_val2:""})
        return;
      } 
    }else if(varPaymentInfo.or_receipt === 2){
      if(!varPaymentInfo.or_deduction_val3){
        alert('사업자등록번호를 입력해주세요.');
        return;
      } else if(!numHyphen(varPaymentInfo.or_deduction_val3)){
        alert('숫자만 입력해주세요.');
        setPaymentInfo({...varPaymentInfo, or_deduction_val3:""})
        return;
      }  else if(!numHyphen(varPaymentInfo.or_deduction_val3)){
        alert('숫자만 입력해주세요.');
        setPaymentInfo({...varPaymentInfo, or_deduction_val3:""})
        return;
      } else if (checkCorporateRegiNumber(varPaymentInfo.or_deduction_val3) === false) {
        alert("사업자등록번호 형식에 맞지 않습니다.");
        setPaymentInfo({...varPaymentInfo, or_deduction_val3:""})
        return;
      }
    }

    if(varPaymentInfo.agree_chk1 === 0 || varPaymentInfo.agree_chk2 === 0) {
      alert('이용약관과 개인정보처리방침에 먼저 동의하셔야 결제가 가능합니다.');
      return;
    }
    
    if(!window.confirm('결제를 진행하시겠습니까?')) return;
    //데이타베이스로 저장
      registerPayment();
    
  }

  //결제사항 데이타베이스에 저장 --> 카드결제의 경우 --> TossPay 호출
  const registerPayment = async () =>{
    const params = new URLSearchParams();
    params.append("mcm_idx", mcm_idx);
    params.append("vo_idx", varVoucherIndex);
    params.append("payments",JSON.stringify(varPaymentInfo));
    await axios.post(`${CONFIG.SERVER_HOST}/_update_payment_voucher`,params).then((res) => {
      if(res.data.retvalue === 0) {
        alert(res.data.message);
      } else {
        //카드결제가 완료되면
        if(varPaymentInfo.or_pay_method === 1) {
          const resData = res.data;
            console.log(resData);
            console.log('카드팝업메뉴 호출');
            tossPayments.requestPayment('카드', { 
              amount: resData.or_amount,
              orderId: resData.or_code, //'a4CWyWY5m89PNh7xJwhk1',
              orderName: resData.vo_name, //'토스 티셔츠 외 2건',
              //successUrl: `https://${window.location.hostname}/TossPaySuccess`,
              //failUrl: `https://${window.location.hostname}/TossPayFailed`
              successUrl: CONFIG.successUrl,
              failUrl: CONFIG.failUrl
            })
            .catch(function (error) {
              if (error.code === 'USER_CANCEL') {
                alert("카드결제를 취소하셨습니다."); 
              }
            }); 
        }else if(varPaymentInfo.or_pay_method === 0){//2022-07-30 KSR 무통장. 다른결제방식 추가 시 비교구문 추가 할것
          window.alert('결제가 완료되었습니다!');
          navigator('/Company/ComHeding/MyHeding5');
        }
      }
    });
  }

  return (
    <div className="center_wrap company">
      <section className="page_voucher pay">
        <h2 className="sub_content_title indent">결제정보</h2>
        <VoucherPayList varSelectedVoucher={varSelectedVoucher}/>
        <h2 className="sub_content_title indent">결제수단</h2>
        <VoucherPayCard varBankList={varBankList} varPaymentInfo={varPaymentInfo} setPaymentInfo={setPaymentInfo} />
        <Agree varConditionData={varConditionData} varPaymentInfo={varPaymentInfo} setPaymentInfo={setPaymentInfo}/>
        <section className="page_bottom_button_area two">
          <Link to={"/Company/ComVoucher"} className="btn line">취소</Link>
          <a className="btn" onClick={(e)=>onPaymentHandler(e)}>결제하기</a>
        </section>
    </section>
  </div>
  );
}

export default VoucherPay;
