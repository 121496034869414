import React, {  useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import CompanyVoucherTop from "./CompanyVoucherTop";
import CompanyVoucherList from "./CompanyVoucherList";
import CompanyVoucherUsedList from "./CompanyVoucherUsedList";
import VoucherTab from "../Tab/VoucherTab";

import { resetNavSubMenuForHeding } from '../Common/Common';

function MyHeding5({ getSubItem }) {
  // const mcm_idx = localStorage.getItem("mcm_idx");

  //페이지 설정
  const [varMode, setMode] = useState(0);

  //보유현황
  const [varVoucherHaving, setVoucherHaving] = useState({mcc_sending:0,mcc_edate:null,remaindays:0});

  // const uNavigate = useNavigate();
  // const uri_path = window.location.pathname;
  // useEffect(() => {
  //   if (!mcm_idx || Number(mcm_idx)===0) {
  //     localStorage.setItem("re_url", uri_path);
  //     alert("로그인 후 이용 가능합니다.");
  //     uNavigate("/Login");
  //   }
  // },[mcm_idx, uri_path, uNavigate]);

  //메뉴설정
  useEffect(() => {
    getSubItem(5);
    resetNavSubMenuForHeding(5);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //탭설정
  const onSetMode = (e,index) => {
    let conBox = document.querySelector(".page_com_myheding04")
    if(index === 0){
        //paylist 가 있어야 함
        if(!conBox.classList.contains("paylist")){
            conBox.classList.add("paylist")
            conBox.classList.remove("uselist")
        }
        if(!conBox.classList.contains("uselist")){
            conBox.classList.remove("uselist")
        }
    }else{
        //uselist 가 있어야함
        if(conBox.classList.contains("paylist")){
            conBox.classList.remove("paylist")
        }
        if(!conBox.classList.contains("uselist")){
            conBox.classList.add("uselist")
        }
    }
    e.preventDefault();
    setMode(index);
  }

  return (
    <div className="container page_com_myheding04 paylist">
        <CompanyVoucherTop varVoucherHaving={varVoucherHaving} setVoucherHaving={setVoucherHaving}/>
        <VoucherTab varMode={varMode} setMode={setMode} onSetMode={onSetMode} />
        {varMode === 0 ? (
            <CompanyVoucherList varVoucherHaving={varVoucherHaving}/>
        ) : (
            <CompanyVoucherUsedList />
        )}
        
    </div>
  );
}

export default MyHeding5;
