import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/configs';

import iconhome from '../../img/icon-home.png';
import arrImage from '../../img/arr.png';
import Titlebar from '../inc/Titlebar';
import Tabbar from '../inc/Tabbar';
import Menu from '../inc/Menu';

// import {send_push} from '../../../components/Common/Common';

function Push() {
  const navigator = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [linkurl, setLinkurl] = useState("");
  const [membership, setMembership] = useState(0);
  const [Write, setWrite] = useState(0);

  useEffect(() => {
    if (adminlogin === undefined || adminlogin === null || adminlogin === "false") {
      alert("로그인 후 이용 가능합니다.");
      navigator("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBody = (e) => {
    setBody(e.target.value);
  };

  const handleMembership = (e) => {
    console.log(typeof e.target.value, e.target.value);
    setMembership(Number(e.target.value));
    if(Number(e.target.value) !== 1){
      setWrite(0);
    }
  };

  const handleLinkurl = (e) => {
    setLinkurl(e.target.value);
  }

  const sendPush = () => {

    //   alert('전송');
    if (membership === 1 && Write > 0) {
      //pushWriter
      const sendData = {
        'Write': Write,
        'title': title,
        'body': body,
        'linkurl': linkurl
      }

      axios.post(`${CONFIG.SERVER_HOST}/pushWriter`, sendData, CONFIG.header).then((res) => {
        console.log(res); 
        if(res){
          if(res.data.retvalue === 1){ 
            alert('전송되었습니다.');
          }else{
            alert('전송에 실패했습니다.');
          }
        }
      });

    } else {
      const sendData = {
        'topic': membership,
        'title': '전체 발송',
        'body': body,
        'linkurl': linkurl
      }

      axios.post(`${CONFIG.SERVER_HOST}/pushTopic`, sendData, CONFIG.header).then((res) => {
        console.log(res); 
        if(res){
          if(res.data.retvalue === 1){ 
            alert('전송되었습니다.');
          }else{
            alert('전송에 실패했습니다.');
          }
        }
      });
    }

  };

  const handleWrite = (e) => {
    if(membership === 1){
      setWrite(Number(e.target.value));
    }
    
  };

  const handleTitle = (e)=>{
    setTitle(e.target.value);
  }

  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="pref" />
      <Menu menuType="pref" menu="manual" />
      <div id="contents" className="ad_contents">
        <div className="tit">수동 PUSH관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME<img src={arrImage} alt="" />환경설정<img src={arrImage} alt="" />PUSH 관리<img src={arrImage} alt="" />수동 PUSH관리
          </div>
        </div>
        <div className="content">
          {/* <div className="tit">PUSH</div> */}
          <div className="main-box">
            <table className="write">
              <tr>
                <th>발송대상</th>
                <td colspan="3">
                  <div className="write-radio">
                    <input type="radio" name="type" id="all" value={0} checked={membership === 0 ? true : false} onChange={handleMembership} /> <label htmlFor="all">전체회원</label>
                    <input type="radio" name="type" id="user" value={1} checked={membership === 1 ? true : false} onChange={handleMembership} /> <label htmlFor="user">일반회원</label>
                    <input type="radio" name="type" id="enterprise" value={2} checked={membership === 2 ? true : false} onChange={handleMembership} /> <label htmlFor="enterprise">기업회원</label>
                    <input type="radio" name="type" id="headhunter" value={3} checked={membership === 3 ? true : false} onChange={handleMembership} /> <label htmlFor="headhunter">헤드헌터회원</label>
                  </div>

                </td>
              </tr>
              <tr>
                <th>작성여부</th>
                <td colSpan="3">
                  <div className="write-radio">
                    <input type="radio" name="type1" id="all" value={0} checked={Write === 0 ? true : false} onChange={handleWrite} /> <label htmlFor="all">전체</label>
                    <input type="radio" name="type1" id="resume" value={1} checked={Write === 1 ? true : false} onChange={handleWrite} /> <label htmlFor="resume">이력서 미등록자</label>
                    <input type="radio" name="type1" id="picture" value={2} checked={Write === 2 ? true : false} onChange={handleWrite} /> <label htmlFor="picture">프로필 사진 미등록자</label>
                    <input type="radio" name="type1" id="reputation" value={3} checked={Write === 3 ? true : false} onChange={handleWrite} /> <label htmlFor="reputation">동료평판 미등록자</label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>link</th>
                <td colspan="3"><input type="text" name="linkurl" value={linkurl} onChange={handleLinkurl} /></td>
              </tr>
              <tr>
                <th>PUSH 제목</th>
                <td colspan="3"><input type="text" name="title" value={title} onChange={handleTitle} /></td>
              </tr>
              <tr>
                <th>PUSH 내용</th>
                <td colspan="3"><textarea type="text" name="body" value={body} onChange={handleBody} /></td>
              </tr>
            </table>
          </div>
          <div className="btn-center">
            <button className="btn line">취소</button>
            <button className="btn" onClick={() => sendPush()}>저장</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Push;