/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
import React, {useState} from "react";
//image
import deleteSchoolImage2 from "../../img/sub/btn_delete02.png";
//datepicker
import { getYear, getMonth } from "date-fns"; // getYear, getMonth 
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import "react-datepicker/dist/react-datepicker.css";
import { ChangeSearchingDateFormat } from '../Common/Common'
registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');


function SchoolHistory({
  key,
  index,
  certificate,
  certificateList,
  setCertificateList
}) {

  const [startDate, setStartDate] = useState(certificate.rc_gdate===null||certificate.rc_gdate===""?null:new Date(certificate.rc_gdate));
  const years = _.range(1990, getYear(new Date()) + 1, 1); // 수정
  const months = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월']; 
  // //입력값 저장
  const onChangeHandler = (e) => {

    
    var { value, name } = e.target;
    const Array =certificateList;
    const findIndex = Array.findIndex(element => element.idx === certificate.idx);
    let copyArray = [...Array];
    if(findIndex !== -1) {
      copyArray[findIndex] = {...copyArray[findIndex], [name]: value};
      setCertificateList(copyArray);
    }
  };

    //날짜변경
    const onChangeDate = (date) => {
      const ndate = ChangeSearchingDateFormat(date); //날짜 변경----configs.js

      console.log(ndate);
      // const Array =certificateList;
      // const findIndex = Array.findIndex(element => element.idx === certificate.idx);
      // let copyArray = [...Array];
      // if(findIndex !== -1) {
      //   copyArray[findIndex] = {...copyArray[findIndex], rc_gdate: date};
      //   setCertificateList(copyArray);
      // }
        setStartDate(date);
        setCertificateList(
          certificateList.map(certifi =>
            certifi.idx === certificate?.idx ? { ...certifi, 
              rc_gdate:ndate } : certifi
          ));
    }

  //자격증삭제
  const onRemoveHandler = (e) => {
    e.preventDefault();
    if (!window.confirm("선택한 자격증을 삭제합니까?")) return;

    // idx
    // rc_name
    // rc_gdate
    // rc_issuer
    setCertificateList(
      certificateList.filter(
        (element) => element.idx !== certificate.idx
      )
    );
  };

  return (
    <div className="input_area" key={"resch_" + index}>
      <button className="btn_del" onClick={(e) => onRemoveHandler(e)} style={{cursor:"pointer"}}>
        <img src={deleteSchoolImage2} alt="" />
      </button>
      <input
        type="text"
        className={"input_part"+(certificate.rc_name ? ' bg_on' : '')}
        placeholder="자격증명"
        name="rc_name"
        value={certificate.rc_name}
        onChange={onChangeHandler}
      />

      <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      className="datepicker" locale={ko} dateFormat={"yyy.MM.dd"} id={'rc_gdate'} selected={startDate} selectStart onChange={(date) => onChangeDate(date)} 
      />
      <input
        type="text"
        className={"input_part4"+(certificate.rc_issuer ? ' bg_on' : '')}
        name="rc_issuer"
        placeholder="취득기관"
        value={certificate.rc_issuer}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default SchoolHistory;
