import React, { useEffect } from "react";

import { resetNavMenu, resetNavSubMenu } from "../../components/Common/Common";
import AnnouncementWriteC from "../../components/Announcement/AnnouncementWriteC";

const AnnouncementWrite = () => {
  //상단 메뉴 - 항목 선택 표시
  useEffect(() => {
    resetNavMenu(0);
    resetNavSubMenu(0,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnnouncementWriteC />
  );
};

export default AnnouncementWrite;
