/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import CONFIG from "../../../configs/configs";
import axios from "axios";

import Titlebar from "../inc/Titlebar";
import Tabbar from "../inc/Tabbar";
import Menu from "../inc/Menu";

import { changeMobileFormat } from "../../../components/Common/Common";

import arrImage from "../../img/arr.png";
import iconhome from "../../img/icon-home.png";

import "admin/css/board.css";

function ConsultDetail() {
  const { board_idx } = useParams();
  const navigation = useNavigate();
  const adminlogin = localStorage.getItem('adminlogin');
  // const user_idx = localStorage.getItem("mem_idx");

  //심사상태--------------------------------------------------------------------------------------------
  const [varConsultDetailIdx, setVarConsultDetailIdx] = useState(0);
  const [varConsultDetailVCA, setVarConsultDetailVCA] = useState(1);
  const [varConsultDetailName, setVarConsultDetailName] = useState("");
  const [varConsultDetailMobile, setVarConsultDetailMobile] = useState("");
  const [varConsultDetailEmail, setVarConsultDetailEmail] = useState("");
  const [varConsultDetailDate, setVarConsultDetailDate] = useState("");
  const [varConsultDetailComment, setVarConsultDetailComment] = useState("");
  const [varConsultDetailResume, setVarConsultDetailResume] = useState(0);
  const [varConsultDetailMemIdx, setVarConsultDetailMemIdx] = useState("");
  const [varConsultStatus, setConsultStatus] = useState(1);
  const [varConsultApp, setVarConsultApp] = useState([]);

  //내용입력시
  const handleConsultDetailComment = (e) => {
    setVarConsultDetailComment(e.currentTarget.value);
  };

  useEffect(() => {
    if(adminlogin === undefined || adminlogin === null || adminlogin === "false" ){
      alert("로그인 후 이용 가능합니다.");
      navigation("/admin/login");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //최초에 실행
  useEffect(() => {
    console.log(board_idx);
    setVarConsultDetailIdx(board_idx);
    const params = new URLSearchParams();
    params.append("board_idx", board_idx);
    axios.post(`${CONFIG.SERVER_HOST}/consult_detail`, params).then((res) => {
      //컨설팅 상세
      const datas = res.data.boards[0];
      setVarConsultDetailVCA(datas.vca_idx);
      setVarConsultDetailName(datas.mem_name);
      setVarConsultDetailMobile(
        datas.mem_mobile === "" ? "" : changeMobileFormat(datas.mem_mobile)
      );
      setVarConsultDetailEmail(datas.mem_email);
      setVarConsultDetailDate(datas.board_rdate.substr(0, 10));
      setVarConsultDetailComment(
        datas.board_comment === null ? "" : datas.board_comment
      );
      setVarConsultDetailResume(datas.cons_re_idx);
      setVarConsultDetailMemIdx(datas.mem_idx);
      //컨설팅 신청 항목
      const vcas = res.data.consults.map((vca, i) => ({
        vca_idx: vca.vca_idx,
        vca_name: vca.vca_name,
      }));
      setConsultStatus(datas.board_state)
      setVarConsultApp(vcas);
    });
  }, []);

  //저장하기
  const updateConsultDetail = () => {
    const params = new URLSearchParams();
    params.append("board_idx", varConsultDetailIdx);
    params.append("board_comment", varConsultDetailComment);
    params.append("user_idx", varConsultDetailMemIdx);
    params.append("board_state", varConsultStatus);
    axios.post(`${CONFIG.SERVER_HOST}/consult_update`, params).then((res) => {
      if (res.data.retvalue === 1) {
        alert(res.data.message);
        navigation('/admin/consultlist')
      }
    });
  };

  //진행상태 변경
  const consultingStatusChange = (e) => {
    const { value } = e.target;
    setConsultStatus(value);
  };

  //표시-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Titlebar />
      <Tabbar tabType="board" />
      <Menu menuType="board" menu="consultdetail" />
      <div id="contents" className="ad_contents">
        <div className="tit">
          헤딩 컨설팅 관리
          <div className="path">
            <img src={iconhome} alt="" />
            HOME
            <img src={arrImage} alt="" />
            게시판 관리
            <img src={arrImage} alt="" />
            헤딩 컨설팅 관리
          </div>
        </div>
        <div className="content">
          <div className="main-box">
            <table className="write">
              <thead></thead>
              <tbody>
                <tr>
                  <th>컨설팅 신청 항목</th>
                  <td className="write-radio">
                    {varConsultApp.map((consultapp, i) => (
                      <label key={consultapp.vca_idx}>
                        <input
                          type="radio"
                          name="vca_idx"
                          value={consultapp.vca_idx}
                          style={{ backgroundColor: "#eee" }}
                          checked={
                            varConsultDetailVCA === consultapp.vca_idx
                              ? true
                              : false
                          }
                          readOnly
                        />
                        &nbsp;{consultapp.vca_name}&nbsp;&nbsp;
                      </label>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td
                    className={
                      board_idx === 0 ? "write-input" : "write-date-readonly"
                    }
                  >
                    <input
                      type="text"
                      value={varConsultDetailName || ""}
                      style={{ width: "200px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td
                    className={
                      board_idx === 0 ? "write-input" : "write-date-readonly"
                    }
                  >
                    <input
                      type="text"
                      value={varConsultDetailMobile || ""}
                      style={{ width: "250px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td
                    className={
                      board_idx === 0 ? "write-input" : "write-date-readonly"
                    }
                  >
                    <input type="text" value={varConsultDetailEmail || ""} />
                  </td>
                </tr>
                <tr>
                  <th>컨설팅 요청 이력서</th>
                  <td>
                    <Link
                      to={`/admin/userinfo/${varConsultDetailMemIdx}/resume_Detail/${varConsultDetailResume}`}
                      className="btn"
                    >
                      이력서 보기
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>작성일자</th>
                  <td
                    className={
                      board_idx === 0 ? "write-input" : "write-date-readonly"
                    }
                  >
                    <input
                      type="text"
                      defaultValue={varConsultDetailDate || ""}
                      style={{ width: "150px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>진행상태</th>
                  <td colSpan={3} style={{ textAlign: "left" }}>
                    <select
                      value={varConsultStatus}
                      onChange={consultingStatusChange}
                      className="short_select"
                    >
                      <option value={"1"}>상담 대기</option>
                      <option value={"2"}>상담중</option>
                      <option value={"3"}>상담 완료</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>메모</th>
                  <td className="write-textarea">
                    <textarea
                      value={varConsultDetailComment || ""}
                      onChange={handleConsultDetailComment}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="main-box2">
            <div className="btn-center">
              <Link to="/admin/consultlist" className="btn line">
                취소
              </Link>
              <button onClick={updateConsultDetail} className="btn">
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsultDetail;
